/**
 * @module Forms
 */

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.css']
})
export class InputComponent implements OnInit {

  @Input() value: string;
  @Input() maxLength: number;
  @Input() minLength: number;
  @Input() label: string;
  @Input() color: string;
  @Input() marginTop: boolean = true;
  @Input() marginBottom: boolean = true;
  @Output() sendValue: EventEmitter<string>;

  isValid: boolean;

  constructor() {
    this.sendValue = new EventEmitter<string>();
  }

  ngOnInit() {
    this.setValid();
  }

  onInput(input: string) {
    this.setValid();
    if(this.isValid || this.isValid === undefined) this.sendValue.emit(input);
  }

  setValid() {
    if(this.maxLength !== undefined && this.minLength !== undefined) this.isValid = this.value.length >= this.minLength && this.value.length <= this.maxLength;
    else if(this.maxLength !== undefined) this.isValid = this.value.length <= this.maxLength;
    else if(this.minLength !== undefined) this.isValid = this.value.length >= this.minLength;
    else this.isValid = undefined;
  }

}
