/**
 * @module User
 */

import { Component, Input } from '@angular/core';
import { UserClass } from '@class/user.class';
import { LanguagesLabels } from '@functions/language.functions';
import { LanguageType } from '@models/language.model';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.css']
})
export class LanguageComponent {

  @Input() user: UserClass;

  languages = LanguagesLabels();

  constructor() {}

  onLanguage(language: LanguageType) {
    this.user.change({ language: language });
  }

}
