/**
 * @module Role
 */

import { RoleType } from '@models/role.model';
import { ItemType } from '@models/item.model';
import { ItemLabel, ItemIcon, ItemColor } from '@functions/item.functions';

export function RolesCodes(): Readonly<RoleType[]> {
  return ['d', 'c', 'w', 'i', 'u', 'r', 's', 't', 'h'];
};

export function RolesItems(): Readonly<{ [key in RoleType]: ItemType[] }> {
  return {
    d: ['dashboards'],
    c: ['comments'],
    w: ['widgets'],
    i: ['indicators', 'pivots', 'dates'],
    u: ['users'],
    r: ['rights'],
    s: ['servers'],
    t: ['tags'],
    h: ['themes']
  };
}

export function RolesItemsColors(): Readonly<{ [key in RoleType]: string[] }> {
  return RolesItemsAttributes('colors');
}

export function RolesItemsLabels(): Readonly<{ [key in RoleType]: string[] }> {
  return RolesItemsAttributes('labels');
}

export function RolesItemsIcons(): Readonly<{ [key in RoleType]: string[] }> {
  return RolesItemsAttributes('icons');
}

type RoleItemAttribute = 'icons' | 'labels' | 'colors';

function RolesItemsAttributes(attribute: RoleItemAttribute): Readonly<{ [key in RoleType]: string[] }> {
  let list = {} as { [key in RoleType]: string[] };
  const items = RolesItems()
  for(const role in items) {
    list[role] = items[role].map((item: ItemType) => {
      switch(attribute) {
        case 'icons': return ItemIcon(item);
        case 'labels': return ItemLabel(item);
        case 'colors': return ItemColor(item);
      }
    });
  }
  return list;
}