<app-browser-frame #frameModal (sendCreate)="receiveCreate()" (sendSearch)="receiveSearch($event)" [color]="color" [icon]="icon" [label]="label">
  
  <div app-browser-frame-content-options>

  </div>

  <app-empty app-browser-frame-content-list [type]="'tags'" (sendAction)="receiveCreate()" *ngIf="tags != undefined && tags.length == 0"></app-empty>
  <app-loading app-browser-frame-content-list *ngIf="tags == undefined"></app-loading>
  
  <div app-browser-frame-content-list *ngFor="let tag of tags | searchLabels:searchLabel:language ; trackBy: trackByUid">
    <app-item-strip
    [labels]="tag.labels"
    [type]="'tags'"
    [uid]="tag.uid"
    [icon]="tag.icon.code"
    [color]="tag.color.hexa"
    [isModified]="tag.modifications$.value.isModified"
    (sendEdit)="receiveEdit($event)"
    ></app-item-strip>
  </div>
</app-browser-frame>

<app-editor #editor (sendRestore)="receiveRestore()" (sendDelete)="receiveDelete()" (sendSave)="receiveSave()"
  [title]="current.labels.value(language)" [uid]="current.uid.value" [icon]="current.icon.code" [color]="color" [iconColor]="current.color.hexa" [isRegistered]="current.uid.isRegistered" [isValid]="current.isValid()" [isModified]="current.isModified">
  <div app-editor-content-menu>

    <a class="dropdown-item z-depth-0" (click)="editorIndex = 0">{{ 'SETTINGS' | translate }}</a>
    <a class="dropdown-item z-depth-0" (click)="editorIndex = 1">{{ 'ICON' | translate }}</a>
    <a class="dropdown-item z-depth-0" (click)="editorIndex = 2">{{ 'COLOR' | translate }}</a>

  </div>
  <div app-editor-content-body *ngIf="editor.isShown">
    <div [ngSwitch]="editorIndex">
      <div *ngSwitchCase="0">
        <app-editor-section-title [title]="'SETTINGS'" (sendNext)="receiveNext($event)"></app-editor-section-title>
        <app-labels [labels]="current.labels" [color]="color"></app-labels>
      </div>
      <div *ngSwitchCase="1">
        <app-editor-section-title [title]="'ICON'" (sendNext)="receiveNext($event)"></app-editor-section-title>
        <app-icons [icon]="current.icon" [color]="current.color"></app-icons>
      </div>
      <div *ngSwitchCase="2">
        <app-editor-section-title [title]="'COLOR'" (sendNext)="receiveNext($event)"></app-editor-section-title>
        <app-colors [color]="current.color"></app-colors>
      </div>
      <div *ngSwitchDefault>
        <app-delete [code]="current.uid.code" (sendDelete)="receiveDeleteConfirmation($event)"></app-delete>
      </div>
    </div>
  </div>

</app-editor>