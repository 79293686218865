<app-links-item *ngIf="right != undefined && display" [color]="color" [icon]="icon" [labels]="right.labels" [uid]="right.uid">
  <div app-links-item-content-description>
    <div *ngIf="!isReader && !isWriter" class="font-extra-small">{{ 'NO_RIGHT/TEXT' | translate }}</div>
    <div *ngIf="isReader" class="font-extra-small">{{ 'WITH_READER/TEXT' | translate }}</div>
    <div *ngIf="isWriter" class="font-extra-small">{{ 'WITH_WRITER/TEXT' | translate }}</div>
  </div>
  <div app-links-item-content-option>
    <app-links-item-option class="d-block mx-1" [symbol]="'glasses'" [selected]="isReader" (sendSelected)="receiveReader()"></app-links-item-option>
  </div>
  <div app-links-item-content-option>
    <app-links-item-option class="d-block ml-1" [symbol]="'pencil'" [selected]="isWriter" (sendSelected)="receiveWriter()"></app-links-item-option>
  </div>
</app-links-item>