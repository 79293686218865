/**
 * @module Auth
 */

import { Injectable } from '@angular/core';
import { FirebaseService } from '@services/firebase/firebase.service';
import { Router } from '@angular/router';
import { PresenceService } from '@services/presence/presence.service';

@Injectable({
  providedIn: 'root'
})
export class SignoutService {

  /**
   * @description Creates an instance of SignoutService
   * @param {FirebaseService} $firebase
   * @param {Router} $router
   * @param {PresenceService} $presence
   * @memberof SignoutService
   */
  constructor(
    private $firebase: FirebaseService,
    private $router: Router,
    private $presence: PresenceService,
  ) {}

  /**
   * @description Déconnecte l'utilisateur
   * @returns {Promise<void>}
   * @memberof SignoutService
   */
  async signOut(): Promise<void> { 
    return new Promise<void>(async (resolve, reject) => { 
      await this.$presence.signOut();
      await this.$firebase.angularFireAuth.auth.signOut();
      this.$firebase.unsubscribe();
      setTimeout(() => this.$router.navigate(['/auth']), 250 );
      resolve();
    });
  }
  
}
