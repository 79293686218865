<div [ngSwitch]="editorIndex">
  <div *ngSwitchCase="0">
    <app-editor-section-title [title]="'INFO_AND_PARAMS'" (sendNext)="receiveNext($event)"></app-editor-section-title>

    <app-download-file-type (sendFileType)="receiveFileType($event)" class="d-block mt-2"></app-download-file-type>
    <button *ngIf="seeChart" (click)="onSeeSpreadsheet()" type="button" block="true" mdbBtn color="light" class="mb-2 ml-0 waves-light z-depth-0 blue-grey-text d-flex align-items-center px-4" mdbWavesEffect>
      <div>{{ 'SPREADSHEET' | translate }}</div><i class="fas fa-table fa-fw ml-auto"></i>
    </button>
    <button *ngIf="!seeChart" (click)="onSeeChart()" type="button" block="true" mdbBtn color="light" class="mb-2 ml-0 waves-light z-depth-0 blue-grey-text d-flex align-items-center px-4" mdbWavesEffect>
      <div>{{ 'CHART' | translate }}</div><i class="fas fa-analytics fa-fw ml-auto"></i>
    </button>
    <button *ngIf="seeChart" (click)="onDownloadImage()" type="button" block="true" mdbBtn color="light" class="ml-0 waves-light z-depth-0 blue-grey-text d-flex align-items-center px-4" mdbWavesEffect>
      <div>{{ 'IMAGE' | translate }}</div><i class="fas fa-camera fa-fw ml-auto"></i>
    </button>
    <app-widget-table [widgetLayers]="widgetLayers" [params]="params"></app-widget-table>
  </div>
  <div *ngSwitchCase="1">
    <app-editor-section-title [title]="'COMMENTS'" (sendNext)="receiveNext($event)"></app-editor-section-title>
    <app-comments [color]="color" [uid]="widgetLayers.uid.value"></app-comments>
  </div>
  <div *ngSwitchCase="2">
    <app-editor-section-title [title]="'FILTERS_AND_DATES'" (sendNext)="receiveNext($event)"></app-editor-section-title>
    <app-widget-date-format [widgetLayers]="widgetLayers"></app-widget-date-format>
    <app-widget-dates-bounds [widgetLayers]="widgetLayers"></app-widget-dates-bounds>
    <app-widget-filters [widgetLayers]="widgetLayers"></app-widget-filters>
  </div>
  <div *ngSwitchCase="3">
    <app-editor-section-title [title]="'ANALYSIS_ORDER'" (sendNext)="receiveNext($event)"></app-editor-section-title>
    <app-widget-pivots-order [widgetLayers]="widgetLayers" class="d-block mt-2"></app-widget-pivots-order>
  </div>
  <div *ngSwitchCase="4">
    <app-editor-section-title [title]="'DESIGNER_FILTERS_AND_DATES'" (sendNext)="receiveNext($event)"></app-editor-section-title>
    <app-widget-designer-dates-bounds (sendGotoFiltersAndDates)="receiveGotoFiltersAndDates()" class="d-block mt-2" [widgetLayers]="widgetLayers"></app-widget-designer-dates-bounds>
    <hr>
    <app-widget-designer-filters [widgetLayers]="widgetLayers"></app-widget-designer-filters>
  </div>
</div>