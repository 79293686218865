/**
 * @module Dashboard
 */

import { Injectable, OnDestroy } from '@angular/core';
import { WidgetLayersClass } from '@class/widgetLayers.class';
import { UID } from '@models/uid.model';
import { BehaviorSubject } from 'rxjs';
import { WidgetIndicatorClass } from '@class/widgetIndicator.class';
import { ColorsService } from '@services/colors/colors.service';
import { WidgetChartParamsModel } from '@models/widgetLayers.model';
import { CommentsClass } from '@class/comment.class';
import { DashboardLayoutChartModel } from '@models/dashboardLayout.model';

@Injectable({
  providedIn: 'root'
})
export class DashboardDisplayService implements OnDestroy {
  
  private _widgetLayers: { 
    [key in UID]: WidgetLayersClass
  };
  
  private _widgetLayers$: { 
    [key in UID]: BehaviorSubject<WidgetLayersClass> 
  };

  private _charts: {
    [key in UID]: {
      [key in UID]: DashboardLayoutChartModel;
    }
  }

  constructor(
    private $colors: ColorsService
  ) {
    this._widgetLayers$ = {};
    this._widgetLayers = {};
    this._charts = {};
  }

  charts(dashboardUID: UID):DashboardLayoutChartModel[] {
    return Object.values(this._charts[dashboardUID] || {});
  }

  addChart(dashboardUID: UID, widgetUID: UID, image: string, params: WidgetChartParamsModel, comments: CommentsClass, match: { indicatorUID: UID, formulaUID: string, filters: any[] }[], widgetLayers: WidgetLayersClass) {
    if(this._charts[dashboardUID] === undefined) this._charts[dashboardUID] = {};
    if(image !== undefined) this._charts[dashboardUID][widgetUID] = { image: image, uid: widgetUID, params: params, comments: comments, match: match, widgetLayers: widgetLayers };
  }

  widgetLayers$(dashboardUID: UID): BehaviorSubject<WidgetLayersClass>  {
    if(this._widgetLayers$[dashboardUID] === undefined) {
      this._widgetLayers$[dashboardUID] = new BehaviorSubject<WidgetLayersClass>(this._widgetLayers[dashboardUID]);
    }
    return this._widgetLayers$[dashboardUID];
  }

  ngOnDestroy() {
    Object.values(this._widgetLayers$).forEach(bs => bs.unsubscribe());
  }

  add(dashboardUID: UID, widgetLayers: WidgetLayersClass) {
    if(this._widgetLayers[dashboardUID] === undefined) {
      this._widgetLayers[dashboardUID] = new WidgetLayersClass(widgetLayers.model, widgetLayers.themeOptions, this.$colors);
    }
    if(this._widgetLayers$[dashboardUID] === undefined) {
      this._widgetLayers$[dashboardUID] = new BehaviorSubject<WidgetLayersClass>(this._widgetLayers[dashboardUID]);
    }
    else {
      widgetLayers.indicators.forEach((indicator: WidgetIndicatorClass) => {
        let links = {} as { [key in UID]: 'pivots' | 'dates' };
        widgetLayers.pivots.forEach(p => links[p] = 'pivots');
        widgetLayers.dates.forEach(d => links[d] = 'dates');
        this._widgetLayers[dashboardUID].addIndicator(indicator.model, links);
      });
    }

    this._widgetLayers$[dashboardUID].next(this._widgetLayers[dashboardUID]);
  }
}