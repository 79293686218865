<app-widget-option [categories]="['INDICATORS']" [label]="'AXIS'" [limitedTo]="['bar']" [color]="color">
  <div app-widget-option-content>
    <div class="btn-group" mdbDropdown>
      <a mdbDropdownToggle type="button" mdbBtn size="sm" color="light" class="dropdown-toggle waves-light z-depth-0 px-3 dropdown-light" mdbWavesEffect>
        <span class="{{color}}-text font-weight-bolder">{{ (widgetLayers.indicators[current].axis + '_AXIS') | uppercase | translate }}</span>&nbsp;
      </a>
      <div class="dropdown-menu dropdown-dark dropdown-menu-right font-small app-drop-small-limit-5">
        <a *ngFor="let axis of axes" class="dropdown-item z-depth-0" (click)="onValue(axis.value)">
          {{ axis.label | translate }}
        </a>
      </div>
    </div>
  </div>
</app-widget-option>