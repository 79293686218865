/**
 * @module Import
 */

import { ImportFrameModel, ImportFrameTagModel, ImportFrameUploadModel } from '@models/importFrame.model';
import { IndicatorClass } from '@class/indicator.class';
import { PivotClass } from '@class/pivot.class';
import { DateClass } from '@class/date.class';
import { DataFrameClass } from '@class/dataFrame.class';
import { UID } from '@models/uid.model';
import { CreateRandomUid } from '@functions/uid.functions';
import { DocumentModel } from '@models/document.model';
import { DataFrameValueModel, DataFrameItemType } from '@models/dataFrame.model';
import { ParseDate } from '@functions/moment.functions';
import { BehaviorSubject, Subscription, Observable } from 'rxjs';
import { ItemColor, ItemIcon } from '@functions/item.functions';
import { OnDestroy } from '@angular/core';
import { utils, writeFile, BookType } from 'xlsx';
import { LanguageType } from '@models/language.model';
import { DeepCopy, DeepMerge } from '@functions/copy.functions';

export class ImportFrameClass implements OnDestroy {

  protected _importFrame: ImportFrameModel;
  protected _uid: UID;

  private changed$sub: Subscription

  private _uploadStatus: ImportFrameUploadModel;
  private _uploadStream$: BehaviorSubject<ImportFrameUploadModel>;
  
  tags$: BehaviorSubject<{ [key: string]: ImportFrameTagModel[] }>;

  constructor(importFrame: ImportFrameModel) {
    this._importFrame = importFrame;
    this._uid = CreateRandomUid();
    this.tags$ = new BehaviorSubject<{ [key: string]: ImportFrameTagModel[] }>(this.setTags(false));

    this._uploadStatus = {
      isUploading: false,
      isEnded: false,
      isErrors: false,
      progress: 0,
      errors: 0,
      uidsErrors: [],
      lines: {
        uploaded: 0,
        total: 0,
        errors: 0
      },
      chuncks: {
        uploaded: 0,
        total: 0
      }
    };
    this._uploadStream$ = new BehaviorSubject<ImportFrameUploadModel>(this._uploadStatus);

    this.changed$sub = this.dataFrame.valuesChanged$.subscribe({
      next: (changed: boolean) => {
        if(changed) {
          this.setTags();
        }
      }
    })
  }

  ngOnDestroy() {
    this.changed$sub.unsubscribe();
  }

  private typeForKey(key: string): Readonly<DataFrameItemType> {
    if(key === this.dataFrame.keyNumber) return 'indicators';
    else if(this.dataFrame.keysString.indexOf(key) !== -1) return 'pivots';
    else if(this.dataFrame.keysDate.indexOf(key) !== -1) return 'dates';
  }

  get uploadStream$(): Observable<ImportFrameUploadModel> {
    return this._uploadStream$;
  }

  getUpload(): Readonly<ImportFrameUploadModel> {
    return this._uploadStatus;
  }

  setUpload(upload: Partial<ImportFrameUploadModel>) {
    this._uploadStatus = DeepMerge<ImportFrameUploadModel>(this._uploadStatus, upload as ImportFrameUploadModel);
    this._uploadStream$.next(this._uploadStatus);
  }

  get uid(): Readonly<UID> {
    return this._uid;
  }

  item(by: { uid?: UID, key?: string }): IndicatorClass | PivotClass | DateClass {
    const type = this.dataFrame.types(by.key !== undefined ? by.key : this.dataFrame.keyForUid(by.uid)).item;
    switch(type) {
      case 'indicators': return this.indicator;
      case 'pivots': return this.pivot(by);
      case 'dates': return this.date(by);
    }
  }

  get indicator(): IndicatorClass {
    return this._importFrame.indicator;
  }

  set indicator(indicator: IndicatorClass) {
    this._importFrame.dataFrame.setUidForKey(this._importFrame.dataFrame.keyForUid(this._importFrame.indicator.uid.value), indicator.uid.value);
    this._importFrame.indicator = indicator;
    this.setTags();
  }

  get indexes(): UID[] {
    return this.pivots.map(p => p.uid.value).concat(this.dates.map(d => d.uid.value).filter(uid => uid !== this.indicator.mainDate));
  }

  get pivots(): Readonly<PivotClass[]> {
    return this._importFrame.pivots;
  }

  pivot(by: { uid?: UID, key?: string }): PivotClass {
    const pivots = this._importFrame.pivots.filter((pivot: PivotClass) => {
      return by.uid !== undefined ? pivot.uid.value === by.uid : pivot.uid.value === this._importFrame.dataFrame.uidForKey(by.key);
    });
    return pivots.length > 0 ? pivots[0] : undefined;
  }

  setPivot(uid: UID, pivot: PivotClass) {
    const index = this._importFrame.pivots.map((pivot: PivotClass) => { return pivot.uid.value; }).indexOf(uid);
    this._importFrame.dataFrame.setUidForKey(this._importFrame.dataFrame.keyForUid(this._importFrame.pivots[index].uid.value), pivot.uid.value);
    this._importFrame.pivots[index] = pivot;
    this.setTags();
  }

  get dates(): Readonly<DateClass[]> {
    return this._importFrame.dates;
  }

  setDate(uid: UID, date: DateClass) {
    const index = this._importFrame.dates.map((date: DateClass) => { return date.uid.value; }).indexOf(uid);
    if(this._importFrame.dates[index].uid.value === this.indicator.mainDate) this.indicator.change({ mainDate: date.uid.value });
    this._importFrame.dataFrame.setUidForKey(this._importFrame.dataFrame.keyForUid(this._importFrame.dates[index].uid.value), date.uid.value);
    this._importFrame.dates[index] = date;
    this.setTags();
  }

  date(by: { uid?: UID, key?: string }): DateClass {
    const dates = this._importFrame.dates.filter((date: DateClass) => {
      return by.uid !== undefined ? date.uid.value === by.uid : date.uid.value === this._importFrame.dataFrame.uidForKey(by.key);
    });
    return dates.length > 0 ? dates[0] : undefined;
  }

  get dataFrame(): DataFrameClass {
    return this._importFrame.dataFrame;
  }

  get isDifferentsLinks(): Readonly<boolean> {
    return this.indicator.uid.isRegistered && (this.isDifferentsDates || this.isDifferentsPivots);
  }

  get isRegisteredIndicator(): Readonly<boolean> {
    return this.indicator.uid.isRegistered;
  }

  get isDifferentsPivots(): Readonly<boolean> {
    const linkedPivots = this._importFrame.indicator.links.list('pivots');
    const framedPivots = this._importFrame.pivots.map((pivot: PivotClass) => { return pivot.uid.value; });
    if(linkedPivots.length !== framedPivots.length) return true;
    let isDifferent = false;
    linkedPivots.forEach((uid: UID) => {
      if(!isDifferent && framedPivots.indexOf(uid) === -1) isDifferent = true;
    });
    if(isDifferent) return true;
    else return false;
  }

  get missingPivots(): Readonly<UID[]> {
    const linkedPivots = this._importFrame.indicator.links.list('pivots');
    const framedPivots = this._importFrame.pivots.map((pivot: PivotClass) => { return pivot.uid.value; });
    return linkedPivots.filter((uid: UID) => {
      return framedPivots.indexOf(uid) === -1;
    });
  }

  get isDifferentsDates(): Readonly<boolean> {
    const linkedDates = this._importFrame.indicator.links.list('dates');
    const framedDates = this._importFrame.dates.map((date: DateClass) => { return date.uid.value; });
    if(linkedDates.length !== framedDates.length) return true;
    let isDifferent = false;
    linkedDates.forEach((uid: UID) => {
      if(!isDifferent && framedDates.indexOf(uid) === -1) isDifferent = true;
    });
    if(isDifferent) return true;
    else return false;
  }

  get missingDates(): Readonly<UID[]> {
    const linkedDates = this._importFrame.indicator.links.list('dates');
    const framedDates = this._importFrame.dates.map((date: DateClass) => { return date.uid.value; });
    return linkedDates.filter((uid: UID) => {
      return framedDates.indexOf(uid) === -1;
    });
  }

  private valuesForDowload(onlyErrors: boolean): any[] {
    let values = this.dataFrame.values.slice();
    if(onlyErrors) {
      values = values.filter(value => this._uploadStatus.uidsErrors.includes(value.$$uid));
    }
    values = values.map((value: any) => {
      let _value = DeepCopy(value);
      delete _value.$$uid;
      delete _value.$$errors;
      return _value;
    });
    return values;
  }

  download(language: LanguageType, bookType: BookType = 'xlsx', onlyErrors: boolean = false) {
    let workbook = utils.book_new();
    utils.book_append_sheet(workbook, utils.json_to_sheet(this.valuesForDowload(onlyErrors)), this.dataFrame.keyNumber);
    writeFile(workbook, `${this.indicator.labels.value(language)}.${bookType}`, { bookType: bookType });
  }

  rows(uidUser: UID, onlyErrors: boolean): DocumentModel[] {
    let values = this.dataFrame.values.slice();
    if(onlyErrors) {
      values = values.filter(value => this._uploadStatus.uidsErrors.includes(value.$$uid));
    }
    values = values.map((row: DataFrameValueModel) => {
      if(Object.keys(row.$$errors).length === 0) {
        let _row: DocumentModel = {
          uidRow: row.$$uid,
          value: row[this.dataFrame.keyNumber]
        };
        this.dataFrame.keysString.forEach((key: string) => {
          _row[this.dataFrame.uidForKey(key)] = row[key];
        });
        this.dataFrame.keysDate.forEach((key: string) => {
          _row[this.dataFrame.uidForKey(key)] = ParseDate(row[key], this.dataFrame.getFormat(key), true).parsed;
        });
        return _row;
      }
    });
    return values;
  }

  private ERRORS_TAG = { title: 'IMPORT_DATA_ERRORS', text: 'IMPORT_DATA_ERRORS/TEXT', icon: 'exclamation-triangle', color: 'danger-color' } as ImportFrameTagModel;
  private NEW_ONE_TAG = { title: 'IMPORT_DATA_NEW_ONE', text: 'IMPORT_DATA_NEW_ONE/TEXT', icon: 'plus', color: ItemColor('indicators') } as ImportFrameTagModel;
  private EXIST_TAG = { title: 'IMPORT_DATA_EXIST', text: 'IMPORT_DATA_EXIST/TEXT', icon: 'check', color: 'default-color' } as ImportFrameTagModel;
  private LINKED_TAG = { title: 'IMPORT_DATA_LINKED', text: 'IMPORT_DATA_LINKED/TEXT', icon: 'link', color: 'default-color' } as ImportFrameTagModel;
  private NOT_LINKED_TAG = { title: 'IMPORT_DATA_NOT_LINKED_TAG', text: 'IMPORT_DATA_NOT_LINKED_TAG/TEXT', icon: 'info', color: 'warning-color' } as ImportFrameTagModel;
  private MAIN_DATE_TAG = { title: 'IMPORT_DATA_MAIN_DATE', text: 'IMPORT_DATA_MAIN_DATE/TEXT', icon: 'calendar', color: 'secondary-color' } as ImportFrameTagModel;
  private MISSING_DATES_TAG = { title: 'IMPORT_DATA_MISSING_DATES', text: 'IMPORT_DATA_MISSING_DATES/TEXT', params: { count: 0 }, icon: ItemIcon('dates'), color: 'warning-color' } as ImportFrameTagModel;
  private MISSING_PIVOTS_TAG = { title: 'IMPORT_DATA_MISSING_PIVOTS', text: 'IMPORT_DATA_MISSING_PIVOTS/TEXT', params: { count: 0 }, icon: ItemIcon('pivots'), color: 'warning-color' } as ImportFrameTagModel;

  private setTags(emit: boolean = true): Readonly<{ [key: string]: ImportFrameTagModel[] }>  {
    let tags = {};
    this._importFrame.dataFrame.keys.forEach((key: string) => {
      tags[key] = [];
      if(this._importFrame.dataFrame.isErrorsForKey(key)) tags[key].push(this.ERRORS_TAG);
      const type = this.typeForKey(key);
      if(type === 'indicators') {
        if(!this.indicator.uid.isRegistered) tags[key].push(this.NEW_ONE_TAG);
        else tags[key].push(this.EXIST_TAG);
        const missingDates = this.missingDates.length;
        if(missingDates > 0) {
          this.MISSING_DATES_TAG.params.count = missingDates;
          tags[key].push(this.MISSING_DATES_TAG);
        }
        const missingPivots = this.missingPivots.length;
        if(missingPivots > 0) {
          this.MISSING_PIVOTS_TAG.params.count = missingPivots;
          tags[key].push(this.MISSING_PIVOTS_TAG);
        }
      }
      else if(type === 'pivots') {
        const pivot = this.pivot({ key: key });
        if(!this.indicator.links.has('pivots', pivot.uid.value) && this.indicator.uid.isRegistered) tags[key].push(this.NOT_LINKED_TAG);
        else if(this.indicator.links.has('pivots', pivot.uid.value)) tags[key].push(this.LINKED_TAG);
        if(!pivot.uid.isRegistered) tags[key].push(this.NEW_ONE_TAG);
        else tags[key].push(this.EXIST_TAG);
      }
      else if(type === 'dates') {
        const date = this.date({ key: key });
        if(this.indicator.mainDate === date.uid.value && this.dates.length > 1) tags[key].push(this.MAIN_DATE_TAG);
        if(!this.indicator.links.has('dates', date.uid.value) && this.indicator.uid.isRegistered) tags[key].push(this.NOT_LINKED_TAG);
        else if(this.indicator.links.has('dates', date.uid.value)) tags[key].push(this.LINKED_TAG);
        if(!date.uid.isRegistered) tags[key].push(this.NEW_ONE_TAG);
        else tags[key].push(this.EXIST_TAG);
      }
    });
    if(emit) this.tags$.next(tags);
    return tags;
  }

}