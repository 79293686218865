/**
 * @module Theme
 */

import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ItemColor } from '@functions/item.functions';
import { ThemeOptionsClass } from '@class/themeOptions.class';
import { ChartType } from '@models/chart.model';
import { ThemeOptionType } from '@models/themeStudio.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-theme-option-elements-hover-border-color-detail',
  templateUrl: './theme-option-elements-hover-border-color-detail.component.html',
  styleUrls: ['./theme-option-elements-hover-border-color-detail.component.css']
})
export class ThemeOptionElementsHoverBorderColorDetailComponent implements OnInit, OnDestroy {

  @Input() themeOptions: ThemeOptionsClass;
  @Input() serieIndex: number;
  @Input() type: ChartType;

  color = ItemColor('themes');
  opacity: number;
  hoverBorderColor: string;
  changedSeries$sub: Subscription;
  optionType: ThemeOptionType = 'elements_hoverBorderColor';

  constructor() {}

  ngOnInit() {
    this.setAttributes();

    this.changedSeries$sub = this.themeOptions.changedSeries$.subscribe({
      next:(option: ThemeOptionType) => {
        if(option === this.optionType) {
          this.setAttributes();
        }
      }
    });
  }

  ngOnDestroy() {
    this.changedSeries$sub.unsubscribe();
  }

  setAttributes() {
    this.hoverBorderColor = this.themeOptions.series(this.serieIndex, this.type).hoverBorderColor.toString().slice(0, 7);
    this.opacity = Math.round(parseInt(this.themeOptions.series(this.serieIndex, this.type).hoverBorderColor.toString().slice(7, 9), 16) * 100 / 255);
  }
  
  receiveValue(value: string) {
    this.hoverBorderColor = value;
    this.themeOptions.setSeries(this.optionType, value + Math.round(this.opacity * 255 / 100).toString(16), this.serieIndex, [this.type]);
  }

  receiveOpacity(value: number) {
    this.opacity = value;
    if(this.opacity > 0) this.themeOptions.setSeries(this.optionType, this.hoverBorderColor + Math.round(value * 255 / 100).toString(16), this.serieIndex, [this.type]);
    else this.themeOptions.setSeries(this.optionType, this.hoverBorderColor + '00', this.serieIndex, [this.type]);
  }

}
