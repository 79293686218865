/**
 * @module Presence
 */

import { Injectable, OnDestroy } from '@angular/core';
import { FirebaseService } from '@services/firebase/firebase.service';
import { map, switchMap, tap, first } from 'rxjs/operators';
import { of, Observable, Subscription, combineLatest } from 'rxjs';
import { PresenceModel, PresenceType } from '@models/presence.model';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { User } from 'firebase';
import { AuthService } from '@services/auth/auth.service';
import { Moment } from '@functions/moment.functions';

@Injectable({
  providedIn: 'root'
})
export class PresenceService implements OnDestroy {

  /**
   * @description Souscription à l'observateur de présence de l'utilisateur courant
   * @private
   * @type {Subscription}
   * @memberof PresenceService
   */
  private updateOnUser$sub: Subscription;

  /**
   * @description Souscription à l'observateur de déconnection de l'utilisateur courant
   * @private
   * @type {Subscription}
   * @memberof PresenceService
   */
  private updateOnDisconnect$sub: Subscription;

  /**
   * @description Souscription à l'observateur de la position du router
   * @private
   * @type {Subscription}
   * @memberof PresenceService
   */
  private router$sub: Subscription;

  /**
   * @description Creates an instance of PresenceService
   * @param {FirebaseService} $firebase
   * @param {Router} $router
   * @param {AuthService} $auth
   * @memberof PresenceService
   */
  constructor(
    private $firebase: FirebaseService,
    private $router: Router,
    private $auth: AuthService
  ) {
    this.updateOnUser$sub = this.updateOnUser().subscribe();
    this.updateOnDisconnect$sub = this.updateOnDisconnect().subscribe();
    this.updateOnAway();

    this.router$sub = this.$router.events.subscribe({
      next: (event: RouterEvent) => {
        if(event instanceof NavigationEnd) {
          this.setPresence('online');
        }
      }
    });
  }

  /**
   * @description Desctructeur du service
   * @memberof PresenceService
   */
  ngOnDestroy() {
    this.updateOnUser$sub.unsubscribe();
    this.updateOnDisconnect$sub.unsubscribe();
    this.router$sub.unsubscribe();
  }

  /**
   * @description Renvoi l'observateur sur la présence de l'utilisateur dont l'uid est passé en paramètre
   * @param {string} uid
   * @returns {Observable<PresenceModel>}
   * @memberof PresenceService
   */
  getPresence(uid: string): Observable<PresenceModel> {
    return (this.$firebase.angularFirebase.object(`status/${uid}`).valueChanges() as Observable<PresenceModel>)
    .pipe(
      map((presence: PresenceModel) => {
        if(presence.status === 'inactive' && this.getInactiveForLong(presence)) {
          presence.status = 'offline';
        };
        return presence;
      })
    );
  }

  /**
   * @description Renvoi l'observateur sur la présence de tous les utilisateurs connecté ('online' ou 'inactive')
   * @returns {Observable<PresenceModel[]>}
   * @memberof PresenceService
   */
  getGlobalsPresences(): Observable<PresenceModel[]> {
    return combineLatest([
      this.$firebase.angularFirebase.list('status', ref => ref.orderByChild('status').equalTo('online')).valueChanges() as Observable<PresenceModel[]>,
      this.$firebase.angularFirebase.list('status', ref => ref.orderByChild('status').equalTo('inactive')).valueChanges() as Observable<PresenceModel[]>
    ]).pipe(
      map((presences: PresenceModel[][]) => {
        return presences[0].concat(presences[1].filter((presence: PresenceModel) => {
          return !this.getInactiveForLong(presence);
        }));
      })
    );
  }

  /**
   * @description Renvoi l'observateur sur la présence de tous les utilisateurs connecté ('online' ou 'inactive') à l'url passée en paramètre
   * @param {string} url
   * @returns {Observable<PresenceModel[]>}
   * @memberof PresenceService
   */
  getLocalsPresences(url: string): Observable<PresenceModel[]> {
    return this.$firebase.angularFirebase.list(`status`, ref => ref.orderByChild('studio').equalTo(this.getStudio(url))).valueChanges()
    .pipe(
      map((presences: PresenceModel[]) => {
        return presences.filter((presence: PresenceModel) => {
          return presence.status === 'online' || (presence.status === 'inactive'
          && !this.getInactiveForLong(presence));
        });
      })
    ) as Observable<PresenceModel[]>;
  }

  /**
   * @description Enregistre la déconnection de l'utilisateur courant
   * @memberof PresenceService
   */
  async signOut() {
    await this.setPresence('offline');
  }

  /**
   * @description Renvoi true si l'utilisateur de la presence passée en paramètre a été inactif plus de 30 minutes
   * @private
   * @param {PresenceModel} presence
   * @returns {boolean}
   * @memberof PresenceService
   */
  private getInactiveForLong(presence: PresenceModel): boolean {
    return presence.status === 'inactive' && Moment()().subtract('30', 'minutes').isAfter(Moment()(presence.timestamp));
  }

  /**
   * @description Renvoi l'url actuelle de l'utilisateur courant
   * @private
   * @returns {Readonly<string>}
   * @memberof PresenceService
   */
  private getUrl(): Readonly<string> {
    return this.$router.parseUrl(this.$router.url).toString();
  }

  /**
   * @description Renvoi une promese avec l'utilisateur 'firebase' courant
   * @private
   * @returns {Promise<User>}
   * @memberof PresenceService
   */
  private getUser(): Promise<User> {
    return this.$firebase.angularFireAuth.authState.pipe(first()).toPromise();
  }

  /**
   * @description Met à jour le status de la présence pour l'utilisateur courant
   * @private
   * @param {PresenceType} status
   * @returns {Promise<void>}
   * @memberof PresenceService
   */
  private async setPresence(status: PresenceType): Promise<void> {
    const user = await this.getUser();
    if(user && this.$auth.user$.value != undefined) {
      return this.$firebase.angularFirebase.object<PresenceModel>(`status/${user.uid}`).update({ 
        status, 
        timestamp: this.$firebase.timeStamp,
        user_label: this.$auth.user$.value.id.fullname,
        user_uid: this.$auth.user$.value.uid.value,
        user_url: this.$auth.user$.value.avatar.url,
        studio: this.getStudio()
      });
    }
  }

  private getStudio(url?: string): string {
    if(url === undefined) url = this.getUrl();
    const studio = url.split('?')[0];
    const uid = url.split('uid')[1] || '';
    return studio.concat(uid);
  }

  /**
   * @description Renvoi un observateur sur la présence de l'utilisateur courant
   * @private
   * @returns {Observable<PresenceType>}
   * @memberof PresenceService
   */
  private updateOnUser(): Observable<PresenceType> {
    const connection = this.$firebase.angularFirebase.object('.info/connected').valueChanges().pipe(
      map(connected => connected ? 'online' : 'offline')
    );

    return this.$firebase.angularFireAuth.authState.pipe(
      switchMap(user =>  user ? connection : of('offline')),
      tap((status: PresenceType) => this.setPresence(status))
    );
  }

  /**
   * @description Renvoi un observateur sur la déconnection de l'utilisateur courant
   * @private
   * @returns {Observable<User>}
   * @memberof PresenceService
   */
  private updateOnDisconnect(): Observable<User> {
    return this.$firebase.angularFireAuth.authState.pipe(
      tap(user => {
        if(user && this.$auth.user$.value != undefined) {
          this.$firebase.angularFirebase.object<PresenceModel>(`status/${user.uid}`).query.ref.onDisconnect()
            .update({
              status: 'offline',
              timestamp: this.$firebase.timeStamp,
              user_label: this.$auth.user$.value.id.fullname,
              user_uid: this.$auth.user$.value.uid.value,
              user_url: this.$auth.user$.value.avatar.url,
              studio: this.getStudio()
          } as PresenceModel);
        }
      })
    );
  }

  /**
   * @description Met à jour le status de l'utilisateur courant en fonction de sa présence devant la page de l'application
   * @private
   * @memberof PresenceService
   */
  private updateOnAway() {
    document.onvisibilitychange = (e) => {

      if(document.visibilityState === 'hidden') {
        this.setPresence('inactive');
      } 
      else {
        this.setPresence('online');
      }
    };
  }

}