<div class="app-auth-logo-swapp animated slideInLeft"></div>

<div mdbModal #authModal="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="authModal" aria-hidden="true"
  [config]="{ignoreBackdropClick: true, keyboard: false, show: true}">
  <div class="modal-dialog modal-sm modal-dialog-centered modal-dialog-scrollable" role="document">
    <div class="modal-content">

      <div class="modal-body app-auth-body d-flex flex-column">
        <div class="px-3 pt-3 d-flex justify-content-center flex-column mb-auto">
          <img style="height: 140px; margin-left: 40px" class="animated fadeInUp" src="assets/images/logo.svg">

          <span class="mt-1">
            <mdb-card-title class="mb-0">
              <h3 class="text-elegant text-center">
                <strong>{{ company }}</strong>
              </h3>
            </mdb-card-title>
  
            <h6 class="mb-0 text-muted text-center">
              <strong>Swapp.Dash</strong>
            </h6>
          </span>
        </div>
        <div class="px-3 pb-2">

          <!-- AUTO LOGIN -->
          <div *ngIf="isWaitingAutoAuth == true" class="animated fadeIn">
            <div class="p-1 alert alert-info text-center mb-0 mt-2" role="alert">
              <i class="fas fa-circle-notch fa-spin"></i>
              <span class="ml-1 font-weight-bolder">{{ 'AUTO_LOGIN' | translate }}</span>
            </div>
          </div>
          <!-- ./AUTO LOGIN-->

          <!-- AUTO AUTH -->
          <div *ngIf="isAutoAuth == true && isUnhealthy == false" class="animated fadeIn">
            <div class="p-1 alert alert-success text-center mb-0 mt-2" role="alert">
              <i class="fas fa-circle-notch fa-spin"></i>
              <span class="ml-1 font-weight-bolder">{{ 'LOGIN_SUCCESS' | translate }}</span>
            </div>
          </div>
          <!-- ./AUTO AUTH-->

          <!-- UNHEALTHY -->
          <div *ngIf="isUnhealthy == true" class="animated fadeIn">
            <div class="p-1 alert alert-warning text-center mb-0 mt-2" role="alert">
              <i class="fas fa-circle-notch fa-spin"></i>
              <span class="ml-1 font-weight-bolder">{{ 'UNHEALTHY_CONNECTION' | translate }}</span>
            </div>
          </div>
          <!-- ./UNHEALTHY -->

          <!-- MANUAL LOGIN -->
          <div class="animated fadeIn">
            <div class="md-form md-outline my-2">
              <input type="email" placeholder="{{ 'YOUR_EMAIL' | translate }}" id="emailId" class="form-control" mdbInput [ngModel]="email" (ngModelChange)="onEmail($event)">
            </div>
        
            <div class="md-form md-outline my-2">
              <input type="password" placeholder="{{ 'YOUR_PASSWORD' | translate }}" id="passwordId" class="form-control" mdbInput [(ngModel)]="password" (ngModelChange)="onPassword($event)">
            </div>
        
            <button block="true" type="button" class="z-depth-0 mt-2 mb-0" mdbBtn color="primary" (click)="emailSignIn()" mdbWavesEffect>
              <i *ngIf="isWaitingForSignIn" class="fas fa-circle-notch fa-spin mr-1"></i>
              {{ (isWaitingForSignIn ? '' : 'LOGIN') | translate }}
            </button>
        
            <div class="font-very-small font-weight-bolder text-center mt-2 text-muted">
              <span class="app-cursor-pointer" (click)="onResetPassword()">{{ 'PASSWORD_FORGOTTEN/TEXT' | translate }}</span>
              <br *ngIf="!isCompany" >
              <span *ngIf="!isCompany" class="app-cursor-pointer" (click)="onCompany()">{{ 'COMPANY_CHANGE/TEXT' | translate }}</span>
            </div>
          </div>
          <!-- ./MANUAL LOGIN -->

        </div>
      </div>
      
    </div>
  </div>
</div>

<app-version></app-version>