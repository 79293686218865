<app-theme-option [categories]="themeOptions.optionsConfiguration[optionType].categories" [label]="themeOptions.optionsConfiguration[optionType].label" [limitedTo]="themeOptions.limitedTo(optionType)">
  <div app-theme-option-content>
    <app-show-details-option [showDetails]="showDetails" (sendShowDetails)="receiveShowDetails($event)">
      <div app-show-details-option-content>
        <div class="btn-group" mdbDropdown>
          <a mdbDropdownToggle type="button" mdbBtn size="sm" color="light" class="dropdown-toggle waves-light z-depth-0 px-3 dropdown-light" mdbWavesEffect>
            <span class="{{color}}-text font-weight-bolder">{{ values[themeOptions.series(serieIndex).fill.toString()].label | uppercase | translate }}</span>&nbsp;
          </a>
          <div class="dropdown-menu dropdown-dark dropdown-menu-right font-small app-drop-small-limit-5">
            <a *ngFor="let value of values | keyvalue" class="dropdown-item z-depth-0" (click)="onValue(value.value.style)">
              <i class="far fa-{{ value.value.icon }} fa-fw blue-grey-text"></i>
              {{ value.value.label | translate }}
            </a>
          </div>
        </div>
      </div>
    </app-show-details-option>
  </div>
</app-theme-option>

<div *ngIf="showDetails">
  <app-theme-option [categories]="themeOptions.optionsConfiguration[optionType].categoriesAndLabel" [label]="type | chart" [dark]="4" *ngFor="let type of themeOptions.limitedTo(optionType)">
    <div app-theme-option-content>
      <div class="btn-group" mdbDropdown>
        <a mdbDropdownToggle type="button" mdbBtn size="sm" color="light" class="dropdown-toggle waves-light z-depth-0 px-3 dropdown-light" mdbWavesEffect>
          <span class="{{color}}-text font-weight-bolder">{{ values[themeOptions.series(serieIndex, type).fill.toString()].label | uppercase | translate }}</span>&nbsp;
        </a>
        <div class="dropdown-menu dropdown-dark dropdown-menu-right font-small app-drop-small-limit-5">
          <a *ngFor="let value of values | keyvalue" class="dropdown-item z-depth-0" (click)="onValue(value.value.style, type)">
            <i class="far fa-{{ value.value.icon }} fa-fw blue-grey-text"></i>
            {{ value.value.label | translate }}
          </a>
        </div>
      </div>
    </div>
  </app-theme-option>
</div>