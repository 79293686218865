<app-browser-navbar [showFrames]="false" [showBack]="true">

  <li app-browser-navbar-content class="nav-item sm-ml-2 ml-1" (click)="setFrame('main')">
    <a class="nav-link waves-light" mdbWavesEffect><i class="fa-fw fa-window-maximize fad fa-rotate-270 {{ show.main ? color : '' }}-text"></i>
    <span class="ml-1 d-sm-none d-inline">{{ mainLabel | translate }}</span></a>
  </li>
  <li app-browser-navbar-content class="nav-item sm-ml-2 ml-1" (click)="setFrame('item')" *ngIf="isSmallWidth">
    <a class="nav-link waves-light" mdbWavesEffect><i class="fa-fw {{ icon }} {{ show.item ? color : '' }}-text"></i>
    <span class="ml-1 d-sm-none d-inline">{{ itemLabel | translate }}</span></a>
  </li>
  <li app-browser-navbar-content class="nav-item sm-ml-2 ml-1" (click)="setFrame('sub')">
    <a class="nav-link waves-light" mdbWavesEffect><i class="fa-fw fad fa-window-maximize fa-rotate-90 {{ show.sub ? color : '' }}-text"></i>
    <span class="ml-1 d-sm-none d-inline">{{ subLabel | translate }}</span></a>
  </li>

  <ng-content select="[app-studio-browser-navbar-content]"></ng-content>

</app-browser-navbar>
 
<div class="d-flex app-studio-frame-container">
  <div class="app-studio-frame-main p-3 white" *ngIf="show.main">
    <ng-content select="[app-studio-main-content]"></ng-content>
  </div>

  <div class="app-studio-frame-item white flex-grow-1" *ngIf="show.item">
    <ng-content select="[app-studio-item-content]"></ng-content>
  </div>

  <div class="app-studio-frame-sub p-3 white" *ngIf="show.sub">
    <ng-content select="[app-studio-sub-content]"></ng-content>
  </div>
</div>