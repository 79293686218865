/**
 * @module Browser
 */

import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { ModalDirective } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-browser-frame',
  templateUrl: './browser-frame.component.html',
  styleUrls: ['./browser-frame.component.css']
})
export class BrowserFrameComponent {

  @Input() isCreate: boolean = true;
  @Input() isSearch: boolean = true;
  @Input() label: string;
  @Input() color: string;
  @Input() icon: string; //symbol
  @Output() sendCreate: EventEmitter<void>;
  @Output() sendSearch: EventEmitter<string>;

  search: string = '';

  @ViewChild('frameModal', { static: true }) frameModal: ModalDirective;

  constructor() {
    this.sendCreate = new EventEmitter<void>();
    this.sendSearch = new EventEmitter<string>();
  }

  onCreate() {
    this.sendCreate.emit();
  }

  receiveTextValue(search: string) {
    this.search = search;
    this.sendSearch.emit(search);
  }

  public show() {
    this.frameModal.show();
  }

}
