/**
 * @module Theme
 */

import { Component, Input } from '@angular/core';
import { ItemColor } from '@functions/item.functions';
import { ThemeOptionsClass } from '@class/themeOptions.class';
import { ChartType } from '@models/chart.model';
import { ThemeOptionType } from '@models/themeStudio.model';

@Component({
  selector: 'app-theme-option-elements-point-radius',
  templateUrl: './theme-option-elements-point-radius.component.html',
  styleUrls: ['./theme-option-elements-point-radius.component.css']
})
export class ThemeOptionElementsPointRadiusComponent {

  @Input() themeOptions: ThemeOptionsClass;
  @Input() serieIndex: number;

  showDetails: boolean;
  color = ItemColor('themes');
  optionType: ThemeOptionType = 'elements_point_radius';

  constructor() {
    this.showDetails = false;
  }

  //bien que le composant "hitRadius" existe il n'est pas utilisé car en cas de rayon différent avec "radius" cela créé des artefacts visuels à l'affichage (scintillement)
  //on met donc à jour concomitamment les 2 attributs
  receiveValue(value: string, type?: ChartType) {
    this.themeOptions.setSeries(this.optionType, value, this.serieIndex, type ? [type] : undefined);
    this.themeOptions.setSeries('elements_point_hitRadius', value, this.serieIndex, type ? [type] : undefined);
  }

  receiveShowDetails(showDetails: boolean) {
    this.showDetails = showDetails;
  }

}
