/**
 * @module Image
 */

import { Observable } from 'rxjs';

export function CompressImage(file: File, size: number = 50): Observable<{ file: File, preview: string | ArrayBuffer }> {
  //source adaptée depuis : https://zocada.com/compress-resize-images-javascript-browser/
  return Observable.create(observer => {

    if(file.type.split('/')[0] !== 'image') { 
      observer.error('UNSUPPORTED_FILE_TYPE');
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = event => {
      const image = new Image();
      image.src = (event.target as any).result;
      (image.onload = () => {
        const isWithBigger = image.width > image.height;
        const element = document.createElement('canvas');
        const scaleFactor = size / (isWithBigger ? image.height : image.width);
        const move = ((isWithBigger ? image.width : image.height) - size / scaleFactor) / 2;
        element.width = size;
        element.height = size;
        const context2D = <CanvasRenderingContext2D>element.getContext('2d');
        context2D.drawImage(image, isWithBigger ? move : 0, isWithBigger ? 0 : move, size / scaleFactor, size / scaleFactor, 0, 0, size, size);
        context2D.canvas.toBlob(
          blob => {
            const compressed = new File([blob], file.name, {
              type: 'image/jpeg',
              lastModified: Date.now(),
            })
            let preview: string | ArrayBuffer;
            const reader = new FileReader();
            reader.readAsDataURL(compressed);
            reader.onload = () => {
              preview = reader.result;
              observer.next({
                preview: preview,
                file: compressed
              });
            };
          },
          'image/jpeg',
          .95,
        );
      }),
      (reader.onerror = error => observer.error(error));
    };
  });
}