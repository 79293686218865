<div class="btn-group" mdbDropdown *ngIf="widgetLayers.drillDepth > 1" style="height: 31px; width: 30px;">
  <button mdbDropdownToggle type="button" mdbBtn color="light" class="dropdown-toggle waves-light p-0 m-0 z-depth-0 app-widget-drill-down-after" mdbWavesEffect>
    <i class="fa-shovel blue-grey-text" [ngClass]="{ 'fas': widgetLayers.drillFunnel.length > 0, 'fal': widgetLayers.drillFunnel.length == 0 }"></i>
  </button>

  <div class="dropdown-menu app-drop-small-limit-10">
    
    <a class="dropdown-item" (click)="onOpenDrill()">{{ 'ANALYSIS_ORDER' | translate }}</a>
    <a class="dropdown-item" (click)="widgetLayers.drillTo(i)" *ngFor="let item of widgetLayers.drillFunnel ; let i = index">
      <i class="fad fa-fw blue-grey-text fa-times-square mr-1"></i>
      {{ item.value }} ({{ item.labels.value(language) }})
    </a>
    <div *ngIf="labels.length > 0 && widgetLayers.drillFunnel.length != widgetLayers.drillDepth - 1">
      <div class="dropdown-divider" *ngIf="widgetLayers.drill > 0"></div>
      <h6 class="dropdown-header">{{ 'DIG_INTO' | translate }}&hellip;</h6>
      <span>
        <a (click)="widgetLayers.drillDown(label, vanila[i])" class="dropdown-item" *ngFor="let label of labels ; let i = index"><i class="fad fa-shovel fa-fw blue-grey-text mr-1"></i>{{ label }}</a>
      </span>
    </div>

  </div>
</div>