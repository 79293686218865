/**
 * @module Widget
 */

import { Component, ViewChild, OnDestroy } from '@angular/core';
import { EditorComponent } from '@components/forms/editor/editor.component';
import { ItemColor } from '@functions/item.functions';
import { WidgetClass } from '@class/widget.class';
import { WidgetService } from '@services/widget/widget.service';
import { AuthService } from '@services/auth/auth.service';
import { LanguageType } from '@models/language.model';
import { Subscription } from 'rxjs';
import { UID } from '@models/uid.model';
import { ItemInfoComponent } from '@components/items/item-info/item-info.component';
import { Router } from '@angular/router';
import { BrowserFiltersComponent } from '@components/browser/browser-filters/browser-filters.component';
import { UserClass } from '@class/user.class';
import { OffsetDateModel } from '@models/widgetLayers.model';
import { FrequencyType } from '@models/moment.model';
import { FirebaseService } from '@services/firebase/firebase.service';
import { MessagesService } from '@services/messages/messages.service';
import { ModalDirective } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-widgets',
  templateUrl: './widgets.component.html',
  styleUrls: ['./widgets.component.css']
})
export class WidgetsComponent implements OnDestroy {

  @ViewChild('editor', { static: true }) editor: EditorComponent;
  @ViewChild('info', { static: true }) info: ItemInfoComponent; 
  @ViewChild('filtersModal', { static: true }) filtersModal: BrowserFiltersComponent; 
  @ViewChild('offsetDateModal', { static: true }) offsetDateModal: ModalDirective; 

  color = ItemColor('widgets');
  current: WidgetClass;
  language: LanguageType;
  language$sub: Subscription;
  user: UserClass;
  user$sub: Subscription;
  widgets: WidgetClass[];
  widgets$sub: Subscription;
  offsetDate$sub: Subscription;
  searchLabel: string;

  hasFilters: boolean;
  onlyBookmarks: boolean;
  bookmarks: UID[];
  tagsList: UID[];
  tags: UID[];
  offsetDate: OffsetDateModel;
  saving: boolean = false;

  constructor(
    private $widgets: WidgetService,
    private $auth: AuthService,
    private $firebase: FirebaseService,
    private $router: Router,
    private $messages: MessagesService
  ) {
    this.hasFilters = false;
    this.onlyBookmarks = false;
    this.bookmarks = [];
    this.tags = [];
    this.searchLabel = '';

    this.language = this.$auth.language$.value;
    this.language$sub = this.$auth.language$.subscribe({
      next: (language: LanguageType) => {
        this.language = language;
      }
    });

    this.widgets$sub = this.$widgets.list$().subscribe({
      next: (widgets: WidgetClass[]) => {
        if(!!widgets) {
          this.widgets = widgets.sort((a : WidgetClass, b: WidgetClass) => { return a.labels.value(this.language).localeCompare(b.labels.value(this.language)) });
          this.tagsList = widgets.length === 0 ? [] : widgets.map(w => w.tags.list as string[])
            .reduce((a, b) => [...a, ...b])
            .filter((item, pos, array) => {
              return array.indexOf(item) == pos;
            });
        }
      }
    });

    this.offsetDate$sub = this.$auth.offsetDate$.subscribe({
      next: (offsetDate: OffsetDateModel) => {
        this.offsetDate = offsetDate;
      }
    });

    this.user$sub = this.$auth.user$.subscribe({
      next: (user: UserClass) => {
        this.user = user;
      }
    });

    this.current = this.$widgets.create();
  }

  setOffsetAmount(amount: number) {
    this.offsetDate.amount = amount;
  }

  setOffsetFrequency(frequency: FrequencyType) {
    this.offsetDate.frequency = frequency;
  }

  setOffsetDate() {
    this.saving = true;
    this.$firebase.angularFirebase.object<OffsetDateModel>(`offsetDate`).set(this.offsetDate)
    .then(_ => {
      this.saving = false;
      this.$messages.success({
          title: { code: 'DATE_OFFSET' }, 
          text: { code: 'SUCCESSFULLY_SAVED/TEXT' } 
      });
      this.offsetDateModal.hide();
    })
    .catch((error) => {
      this.saving = false;
      this.$messages.error({ 
        title: { code: 'DATE_OFFSET' }, 
        text: { 
          code: 'SAVE_FAILED/TEXT',
          params: { error: error.code }
        } 
      });
    });
  }

  ngOnDestroy() {
    this.widgets$sub.unsubscribe();
    this.language$sub.unsubscribe();
    this.user$sub.unsubscribe();
    this.offsetDate$sub.unsubscribe();
  }

  trackByUid(index: number, widget: WidgetClass) {
    return widget.uid.value;
  }

  receiveCreate() {
    if(this.current.uid.isRegistered) this.current = this.$widgets.create();
    this.editor.show();
  }

  receiveEdit(uid: UID) {
    this.current = this.widgets.filter((widget: WidgetClass) => {
      return widget.uid.value === uid;
    })[0];
    this.editor.show();
  }

  receiveInfo(uid: UID) {
    this.current = this.widgets.filter((widget: WidgetClass) => {
      return widget.uid.value === uid;
    })[0];
    this.info.show();
  }

  receiveSudio(uid: UID) {
    const panel = this.$router.parseUrl(this.$router.url).queryParams.panel;
    this.$router.navigate([`/widget-studio`], { queryParams: { from: 'browser', panel: panel, uid: uid } });
  }

  receiveSearch() {
    this.filtersModal.show();
  }

  receiveBookmark(onlyBookmarks?: boolean) {
    this.onlyBookmarks = onlyBookmarks === undefined ? !this.onlyBookmarks : onlyBookmarks;
    if(this.onlyBookmarks) {
      this.bookmarks = this.user.bookmarks.list('widgets') as UID[];
    }
    else {
      this.bookmarks = [];
    }
    this.setHasFilters();
  }

  receiveTags(tags: UID[]) {
    this.tags = tags;
    this.setHasFilters();
  }

  receiveSearchLabel(searchLabel: string) {
    this.searchLabel = searchLabel;
    this.setHasFilters();
  }

  onRemoveFilters() {
    this.filtersModal.removeFilters();
    this.bookmarks = [];
    this.onlyBookmarks = false;
    this.tags = [];
    this.searchLabel = '';
    this.setHasFilters();
  }

  setHasFilters() {
    this.hasFilters = this.searchLabel.length > 0
    || this.tags.length > 0
    || this.onlyBookmarks;
  }

}