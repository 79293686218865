/**
 * @module Widget
 */

import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { LayerIcon } from '@functions/widget.functions';
import { ItemColor } from '@functions/item.functions';
import { WidgetLayersClass } from '@class/widgetLayers.class';
import { ThemeOptionsClass } from '@class/themeOptions.class';
import { UID } from '@models/uid.model';
import { LanguageType } from '@models/language.model';
import { Subscription } from 'rxjs';
import { AuthService } from '@services/auth/auth.service';
import { MessagesService } from '@services/messages/messages.service';

@Component({
  selector: 'app-widget-studio-sub-layers',
  templateUrl: './widget-studio-sub-layers.component.html',
  styleUrls: ['./widget-studio-sub-layers.component.css']
})
export class WidgetStudioSubLayersComponent implements OnInit, OnChanges {

  @Input() widgetLayers: WidgetLayersClass;
  @Input() themeOptions: ThemeOptionsClass;
  @Input() current: UID;

  editorIndex: number;
  editorIndexMax: number;

  language: LanguageType;
  language$sub: Subscription;

  duplicable: boolean = false;
  
  icon = LayerIcon();
  color = ItemColor('widgets');

  constructor(
    private $auth: AuthService,
    private $messages: MessagesService
  ) {

    this.language$sub = this.$auth.language$.subscribe({
      next: (language: LanguageType) => {
        this.language = language;
      }
    });

    this.editorIndex = 0;
    this.editorIndexMax = 4;
  }

  ngOnInit() {
    this.editorIndexMax = this.widgetLayers.layer(this.current).layerType === 'dates' ? 5 : 4;
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.current !== undefined && !changes.current.isFirstChange) {
      this.editorIndexMax = this.widgetLayers.layer(this.current).layerType === 'dates' ? 5 : 4;
    }
  }

  receiveNext(isNext: boolean) {
    this.editorIndex = this.editorIndex + (isNext ? 1 : -1);
    if(this.editorIndex < 0) this.editorIndex = this.editorIndexMax;
    if(this.editorIndex > this.editorIndexMax) this.editorIndex = 0;
  } 

  receiveDuplicable() {
    this.duplicable = !this.duplicable;
  }

  onDuplicate(option: string) {
    switch (option) {
      case 'sort':
        this.widgetLayers.duplicateLayersOptions({ 
          sort: this.widgetLayers.layer(this.current).sort,
          sortUID: this.widgetLayers.layer(this.current).sortUID
         }, this.current);
        break;
      case 'stickerCarousel':
        this.widgetLayers.duplicateLayersOptions({ stickerCarousel: this.widgetLayers.layer(this.current).stickerCarousel }, this.current);
        this.widgetLayers.duplicateLayersOptions({ stickerOrientation: this.widgetLayers.layer(this.current).stickerOrientation }, this.current);
        break;
      default:
        this.widgetLayers.duplicateLayersOptions({ [option]: this.widgetLayers.layer(this.current)[option] }, this.current);
      break;
    }
    this.$messages.success({ text: { code: 'OPTION_DUPLICATED_WITH_SUCCESS/TEXT' } });
  }

}
