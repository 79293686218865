/**
 * @module User
 */

import { Component, Input } from '@angular/core';
import { UserIdClass } from '@class/user.class';
import { ItemColor } from '@functions/item.functions';

@Component({
  selector: 'app-user-id',
  templateUrl: './user-id.component.html',
  styleUrls: ['./user-id.component.css']
})
export class UserIdComponent {

  @Input() userId: UserIdClass;
  @Input() color: string = ItemColor('users');

  constructor() {}

}
