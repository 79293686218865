/**
 * @module Widget
 */

import { Component, Input, Output, EventEmitter, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { PivotClass } from '@class/pivot.class';
import { ItemsColors, ItemIcon, ItemColor } from '@functions/item.functions';
import { UID } from '@models/uid.model';
import { BackendPivotsService } from '@services/backend/backendPivots/backend-pivots.service';
import { ServerResponseModel } from '@models/server.model';
import { PivotFiltersClass } from '@class/widgetFilters.class';
import { WidgetIndicatorClass } from '@class/widgetIndicator.class';
import { IndicatorClass } from '@class/indicator.class';
import { AuthService } from '@services/auth/auth.service';

@Component({
  selector: 'app-widget-indicators-filters-pivot',
  templateUrl: './widget-indicators-filters-pivot.component.html',
  styleUrls: ['./widget-indicators-filters-pivot.component.css']
})
export class WidgetIndicatorsFiltersPivotComponent implements OnInit, OnChanges {

  @Input() indicators: WidgetIndicatorClass[] | IndicatorClass[];
  @Input() filters: PivotFiltersClass;
  @Input() pivot: PivotClass;
  @Input() current: boolean;
  @Output() sendSelected: EventEmitter<UID>;

  colors = ItemsColors(true);
  color = ItemColor('pivots');
  icon = ItemIcon('pivots');
  values: string[];
  searchText: string;
  page: number;
  limit: number;
  userFilters: string[] = [];
  include: boolean;

  constructor(
    private $backendPivots: BackendPivotsService,
    private $auth: AuthService
  ) {
    this.sendSelected = new EventEmitter<UID>();
    this.searchText = '';
    this.limit = 20;
    this.page = 0;
  }

  ngOnInit() {
    const userFilters = this.$auth.user$.value.filters.filter(f => f.uid.value === this.pivot.uid.value);
    if(userFilters.length > 0) {
      this.userFilters = userFilters[0].values as string[];
      this.include = userFilters[0].include;
    }
    if(this.current) {
      this.getValues();
    }
    if(this.filters.values.length > 0) {
      setTimeout(() => this.filters.reload(), 50);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.current !== undefined && !changes.current.firstChange && changes.current.currentValue === true) this.getValues();
  }

  receiveSearchText(searchText: string) {
    this.searchText = searchText;
  }

  onPageChanged(page: number) {
    this.page = page;
  }

  getValues() {
    if(this.values === undefined) {
      this.values = [];
      this.indicators.forEach((indicator: WidgetIndicatorClass | IndicatorClass) => {
        this.$backendPivots.get(indicator.uid.value, indicator.serverLink, this.pivot.uid.value, true)
        .then((reponse: ServerResponseModel) => {
          this.values = this.values.concat(reponse.message.values !== undefined ? reponse.message.values : []);
          this.values = this.values.filter((value: string, index: number) => {
            return index === this.values.indexOf(value) && (this.include ?  this.userFilters.indexOf(value) !== -1 : this.userFilters.indexOf(value) === -1);
          });
        });
      });
    }
  }

  onSelect() {
    this.sendSelected.emit(this.pivot.uid.value);
  }

  receiveInclude(include: boolean) {
    this.filters.setInclude(include);
  }

}
