/**
 * @module Studio
 */

import { Component, Input, OnInit, HostListener } from '@angular/core';
import { ItemIcon, ItemColor } from '@functions/item.functions';
import { ItemType } from '@models/item.model';
import { StudioFrameType } from '@models/studio.model';
import { BehaviorSubject } from 'rxjs';

const FULL_STUDIO_WIDTH = 1366;
const MEDIUM_STUDIO_WIDTH = 768;

@Component({
  selector: 'app-studio',
  templateUrl: './studio.component.html',
  styleUrls: ['./studio.component.css']
})

export class StudioComponent implements OnInit {

  @Input() type: ItemType;
  @Input() mainLabel: string = 'STUDIO_MAIN_FRAME';
  @Input() itemLabel: string = 'STUDIO_ITEM_FRAME';
  @Input() subLabel: string = 'STUDIO_SUB_FRAME';
 
  icon: string;
  color: string;
  width: number;
  show: { [key in StudioFrameType]: boolean };
  framesChange$: BehaviorSubject<void>;

  constructor() {
    this.show = {
      main: true,
      item: false,
      sub: false
    };
    this.framesChange$ = new BehaviorSubject<void>(null);
  }

  @HostListener('window:resize', ['$event'])
  private onResize(event: { target: { innerWidth: number; }; }) {
    this.width = event.target.innerWidth;
    this.setFrames();
  }

  ngOnInit() {
    this.icon = ItemIcon(this.type, 'd');
    this.color = ItemColor(this.type);
    this.width = window.innerWidth;
    this.setFrames();
  }

  setFrames() {
    if(this.isFullWidth) {
      this.show = {
        main: true,
        item: true,
        sub: true
      };
    }
    else if(this.isMediumWidth) {
      this.show = {
        main: true,
        item: true,
        sub: false
      };
    }
    else {
      this.show = {
        main: true,
        item: false,
        sub: false
      };
    }
    this.framesChange$.next();
  }

  public get framesCount(): number {
    return Object.values(this.show).filter(s => s).length;
  }

  public get frameSize(): number {
    return 420;
  }

  public get isFullWidth(): boolean {
    return this.width >= FULL_STUDIO_WIDTH;
  }

  public get isMediumWidth(): boolean {
    return this.width >= MEDIUM_STUDIO_WIDTH && this.width < FULL_STUDIO_WIDTH;
  }

  public get isSmallWidth(): boolean {
    return this.width < MEDIUM_STUDIO_WIDTH;
  }

  public setFrame(frame: StudioFrameType, show?: boolean) {
    const _show = show !== undefined ? !show : this.show[frame];
    if(this.isSmallWidth) {
      this.show = {
        main: false,
        item: false,
        sub: false
      };
    }
    else if(this.isMediumWidth) {
      if(frame === 'main' && !_show) this.show.sub = false;
      else if(frame === 'sub' && !_show) this.show.main = false;
    }
    this.show[frame] = !_show;
    this.framesChange$.next();
  }

}
