/**
 * @module Widget
 */

import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { WidgetLayersClass } from '@class/widgetLayers.class';
import { PivotClass } from '@class/pivot.class';
import { UID } from '@models/uid.model';
import { ItemsColors, ItemsIcons } from '@functions/item.functions';
import { PivotService } from '@services/pivot/pivot.service';
import { take } from 'rxjs/operators';
import { LanguageType } from '@models/language.model';
import { Subscription } from 'rxjs';
import { AuthService } from '@services/auth/auth.service';

@Component({
  selector: 'app-widget-filters',
  templateUrl: './widget-filters.component.html',
  styleUrls: ['./widget-filters.component.css']
})
export class WidgetFiltersComponent implements OnInit, OnDestroy {

  @Input() widgetLayers: WidgetLayersClass;

  pivots: PivotClass[] = [];
  loading: boolean;
  current: UID;

  language: LanguageType;
  language$sub: Subscription;

  ngOnDestroy() {
    this.language$sub.unsubscribe();
  }

  colors = ItemsColors(true);
  icons = ItemsIcons();

  constructor(
    private $pivots: PivotService,
    private $auth: AuthService
  ) {
    this.loading = true;
    this.language$sub = this.$auth.language$.subscribe({
      next: (language: LanguageType) => {
        this.language = language;
      }
    });
  }

  ngOnInit() {
    this.widgetLayers.pivots.forEach((uid: UID) => {
      this.$pivots.getPartial$(uid).pipe(take(1)).subscribe((p: PivotClass) => {
        if(!!p) {
          this.pivots.push(p);
          this.loading = false;
          this.pivots.sort((a, b) => {
            if(a.labels.value(this.language) < b.labels.value(this.language)) { return -1; }
            if(a.labels.value(this.language) > b.labels.value(this.language)) { return 1; }
            return 0;
          });
        }
      });
    });
  }

  onEdit(uid: UID) {
    this.current = this.current === uid ? undefined : uid;
  }

  receiveSelect(uid: UID) {
    this.onEdit(uid);
  }

}