/**
 * @module Widget
 */

import { Component, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { ItemColor, ItemIcon } from '@functions/item.functions';
import { WidgetClass } from '@class/widget.class';
import { WidgetService } from '@services/widget/widget.service';
import { Subscription } from 'rxjs';
import { UID } from '@models/uid.model';
import { LanguageType } from '@models/language.model';
import { AuthService } from '@services/auth/auth.service';

@Component({
  selector: 'app-widgets-links-change',
  templateUrl: './widgets-links-change.component.html',
  styleUrls: ['./widgets-links-change.component.css']
})
export class WidgetsLinksChangeComponent implements OnDestroy {

  @Input() uidWidget: UID;
  @Output() sendSelected: EventEmitter<UID>;

  color = ItemColor('widgets');
  icon = ItemIcon('widgets');

  widgets$sub: Subscription;
  list: WidgetClass[];
  searchLabel: string;
  language: LanguageType;
  language$sub: Subscription;

  constructor(
    private $widgets: WidgetService,
    private $auth: AuthService
  ) {

    this.language = this.$auth.language$.value;
    this.language$sub = this.$auth.language$.subscribe({
      next: (language: LanguageType) => {
        this.language = language;
      }
    });

    this.sendSelected = new EventEmitter<UID>();
    this.widgets$sub = this.widgets$sub = this.$widgets.listPartials$().subscribe({
      next: (widgets: WidgetClass[]) => {
        if(!!widgets) this.list = widgets.sort((a : WidgetClass, b: WidgetClass) => { return a.labels.value(this.language).localeCompare(b.labels.value(this.language)) });;
      }
    });
  }

  receiveTextValue(searchLabel: string) {
    this.searchLabel = searchLabel;
  }

  ngOnDestroy() {
    this.widgets$sub.unsubscribe();
  }

  receiveSelected(item: { uid: UID, selected: boolean }) {
    if(item.uid !== this.uidWidget)  {
      this.sendSelected.emit(item.uid);
    }
  }

}
