/**
 * @module Widget
 */

import { Component, OnInit, ViewChild, ComponentFactoryResolver, Input } from '@angular/core';
import { ThemeOptionsDirective } from '@directives/themeOptions/theme-options.directive';
import { ThemeOptionsHost } from '@functions/themeHost.functions';
import { WidgetLayersClass } from '@class/widgetLayers.class';
import { UID } from '@models/uid.model';
import { WidgetLayersDefinedOptionModel } from '@models/widgetLayers.model';
import { ItemColor } from '@functions/item.functions';
import { MessagesService } from '@services/messages/messages.service'

@Component({
  selector: 'app-widget-layers-theme-option',
  templateUrl: './widget-layers-theme-option.component.html',
  styleUrls: ['./widget-layers-theme-option.component.css']
})
export class WidgetLayersThemeOptionComponent implements OnInit {

  @Input() widgetLayers: WidgetLayersClass;
  @Input() current: UID;
  @Input() definedOption: WidgetLayersDefinedOptionModel;
  @Input() deletable: boolean = false;
  @Input() duplicable: boolean = false;

  @ViewChild(ThemeOptionsDirective, { static: true }) optionsHost: ThemeOptionsDirective;

  color = ItemColor('themes');
  type: string;

  constructor(
    private $messages: MessagesService,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {}

  ngOnInit() {
    const viewContainerRef = this.optionsHost.viewContainerRef;
    viewContainerRef.clear();

    const host = ThemeOptionsHost(this.definedOption.option);
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(host.component);
    const componentRef = viewContainerRef.createComponent(componentFactory);
    (<any>componentRef.instance).themeOptions = this.widgetLayers.layer(this.current).themeOptions;
    if(this.definedOption.index !== undefined && this.definedOption.type === 'datalabels') {
      (<any>componentRef.instance).datalabelsIndex = this.definedOption.index;
      this.type = 'LABELS';
    }
    if(this.definedOption.index !== undefined && this.definedOption.type === 'series') {
      (<any>componentRef.instance).serieIndex = this.definedOption.index;
      this.type = 'SERIE';
    }
    if(this.definedOption.index !== undefined && this.definedOption.type === 'axes') {
      (<any>componentRef.instance).axesIndex = this.definedOption.index;
      this.type = 'AXIS';
    }
    if(this.definedOption.axesXY !== undefined) (<any>componentRef.instance).axesXY = this.definedOption.axesXY;

  }

  onDelete() {
    this.widgetLayers.layer(this.current).removeDefinedOption(this.definedOption);
  }

  onDuplicate() {
    this.widgetLayers.duplicateDefinedOption(this.definedOption, this.current);
    this.$messages.success({ text: { code: 'OPTION_DUPLICATED_WITH_SUCCESS/TEXT' } });
  }

}
