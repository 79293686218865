<div class="rounded blue-grey lighten-5 p-sm-3 p-2 mb-1 animated fadeInRight">
  <div style="min-height: 37px;">
    <div class="d-flex" *ngIf="author">
      <div class="mr-2">
        <img class="img-fluid rounded-circle z-depth-0 app-comment-avatar" [src]="author.avatar.url">
      </div>
      <div class="flex-grow-1">
        <div class="font-small"><strong>{{ author.id.fullname }}</strong></div>
        <div class="blue-grey-text font-extra-small app-opacity-75 font-weight-bolder">{{ comment.dateFromNow }}</div>
      </div>
      <div *ngIf="user.roles.has('c')">
        <button (click)="onDelete()" type="button" size="sm" mdbBtn color="light" class="waves-light z-depth-0 px-2 my-0 mx-1" mdbWavesEffect>
          <i class="text-warning fas fa-trash fa-fw"></i>
        </button>
        <button (click)="onEdit()" type="button" size="sm" mdbBtn color="light" class="waves-light z-depth-0 px-2 m-0" mdbWavesEffect>
          <i class="{{ color }}-text fas fa-pencil fa-fw"></i>
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="!isEditor ; else editor" class="text-elegant font-small mt-2" [innerHTML]="description"></div>
</div>

<ng-template #editor>
  <app-comment-editor class="d-block w-100" [color]="color" [comment]="model" (sendComment)="receiveComment($event)"></app-comment-editor>
</ng-template>