/**
 * @module Theme
 */

import { Component, Input } from '@angular/core';
import { ItemColor } from '@functions/item.functions';
import { ThemeOptionsClass } from '@class/themeOptions.class';
import { ChartType } from '@models/chart.model';
import { ThemeOptionType } from '@models/themeStudio.model';

@Component({
  selector: 'app-theme-option-elements-point-style',
  templateUrl: './theme-option-elements-point-style.component.html',
  styleUrls: ['./theme-option-elements-point-style.component.css']
})
export class ThemeOptionElementsPointStyleComponent {

  @Input() themeOptions: ThemeOptionsClass;
  @Input() serieIndex: number;

  showDetails: boolean;
  color = ItemColor('themes');
  optionType: ThemeOptionType = 'elements_point_style';

  constructor() {
    this.showDetails = false;
  }

  onValue(value: string, type?: ChartType) {
    this.themeOptions.setSeries(this.optionType, value, this.serieIndex, type ? [type] : undefined);
  }

  receiveShowDetails(showDetails: boolean) {
    this.showDetails = showDetails;
  }

  values = {
    circle: { icon: 'circle', style: 'circle', label: 'CIRCLE' },
    cross: { icon: 'plus', style: 'cross', label: 'CROSS' },
    crossRot: { icon: 'times', style: 'crossRot', label: 'ROTATED_CROSS' },
    dash: { icon: 'minus', style: 'dash', label: 'DASH' },
    line: { icon: 'minus', style: 'line', label: 'LINE' },
    rect: { icon: 'square', style: 'rect', label: 'RECTANGLE' },
    rectRounded: { icon: 'square', style: 'rectRounded', label: 'ROUNDED_RECTANGLE' },
    rectRot: { icon: 'diamond', style: 'rectRot', label: 'DIAMOND' },
    star: { icon: 'asterisk', style: 'star', label: 'ASTERISK' },
    triangle: { icon: 'triangle', style: 'triangle', label: 'TRIANGLE' }
  };

}
