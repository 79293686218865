<div *ngIf="slides">
  <div [ngStyle]="{ 'background-color': dashboardLayout.color }">
    <div class="row app-dashboard-layout-content-frame p-3" [ngStyle]="{ 'background-color': dashboard.color.hexa }" [ngClass]="{ 'app-dashboard-layout-hide-slide': -3 != current }">
      <div class="col-md-6 d-md-block d-none app-dashboard-layout-title-frame">
        <div class="app-dashboard-layout-background-frame"></div>
      </div>
      <div class="col-md-6 col-12 white">
        <div class="row no-gutters h-100 app-dashboard-layout-side-frame">
          <div class="col-12 app-dashboard-layout-comments-frame d-flex align-items-start flex-column justify-content-center p-5">

            <div><h1 class="display-3 font-weight-bold m-0" [ngStyle]="{ 'color': dashboard.color.hexa }">{{ dashboard?.labels.value(language) }}</h1></div>
            <div><h3 class="blue-grey-text">{{ company }}</h3></div>
            <div><h3 class="blue-grey-text">{{ author }}</h3></div>
            <div><h3 class="blue-grey-text">{{ date }}</h3></div>

          </div>
          <div class="col-12 d-flex rounded blue-grey lighten-3 mb-2">
            <button type="button" mdbBtn color="light" mdbWavesEffect class="z-depth-0 blue-grey-text" (click)="previous()"><i class="fas fa-chevron-left"></i></button>
            <button type="button" mdbBtn color="light" mdbWavesEffect class="z-depth-0 blue-grey-text ml-auto" (click)="next()"><i class="fas fa-chevron-right"></i></button>
          </div>
        </div>
      </div>
    </div>
    <div class="row app-dashboard-layout-content-frame p-3" [ngStyle]="{ 'background-color': dashboard.color.hexa }" [ngClass]="{ 'app-dashboard-layout-hide-slide': -2 != current }">
      <div class="col-md-2 d-md-block d-none app-dashboard-layout-title-frame">
        <div class="app-dashboard-layout-background-frame"></div>
      </div>
      <div class="col-md-10 col-12 white">
        <div class="row no-gutters h-100 app-dashboard-layout-side-frame">
          <div class="col-12 app-dashboard-layout-comments-frame d-flex align-items-top flex-column p-5">

            <div><h1 class="display-3 font-weight-bold" [ngStyle]="{ 'color': dashboard.color.hexa }">{{ 'DESCRIPTION' | translate }}</h1></div>
            <div><h3 class="blue-grey-text" [innerHTML]="bbCodeParser(dashboard?.descriptions.value(language))"></h3></div>

          </div>
          <div class="col-12 d-flex rounded blue-grey lighten-3 mb-2">
            <button type="button" mdbBtn color="light" mdbWavesEffect class="z-depth-0 blue-grey-text" (click)="previous()"><i class="fas fa-chevron-left"></i></button>
            <button type="button" mdbBtn color="light" mdbWavesEffect class="z-depth-0 blue-grey-text ml-auto" (click)="next()"><i class="fas fa-chevron-right"></i></button>
          </div>
        </div>
      </div>
    </div>
    <div class="row app-dashboard-layout-content-frame p-3" [ngStyle]="{ 'background-color': dashboard.color.hexa }" [ngClass]="{ 'app-dashboard-layout-hide-slide': -1 != current }">
      <div class="col-md-2 d-md-block d-none  app-dashboard-layout-title-frame">
        <div class="app-dashboard-layout-background-frame"></div>
      </div>
      <div class="col-md-10 col-12 white">
        <div class="row no-gutters h-100 app-dashboard-layout-side-frame">
          <div class="col-12 app-dashboard-layout-comments-frame d-flex align-items-top flex-column p-5">
            
            <div><h1 class="display-3 font-weight-bold" [ngStyle]="{ 'color': dashboard.color.hexa }">{{ 'SUMMARY' | translate }}</h1></div>
            <app-dashboard-layout-summary-line [uidWidget]="cell.uidWidget" [index]="i" (sendCurrent)="receiveCurrent($event)" *ngFor="let cell of dashboardLayout.cells | filters:filter ; let i = index" class="blue-grey-text app-cursor-pointer"></app-dashboard-layout-summary-line>

          </div>
          <div class="col-12 d-flex rounded blue-grey lighten-3 mb-2">
            <button type="button" mdbBtn color="light" mdbWavesEffect class="z-depth-0 blue-grey-text" (click)="previous()"><i class="fas fa-chevron-left"></i></button>
            <button type="button" mdbBtn color="light" mdbWavesEffect class="z-depth-0 blue-grey-text ml-auto" (click)="next()"><i class="fas fa-chevron-right"></i></button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngFor="let cell of dashboardLayout.cells | filters:filter ; let i = index" [ngStyle]="{ 'background-color': dashboardLayout.color }">
    <div class="row app-dashboard-layout-content-frame p-3" [ngClass]="{ 'app-dashboard-layout-hide-slide': i != current }">
      <div class="col-md-8 col-12 app-dashboard-layout-cell-frame">
        <app-dashboard-cell [cell]="cell" [dashboardLayout]="dashboardLayout" [fullWidth]="true"></app-dashboard-cell>
      </div>
      <div class="col-md-4 col-12">
        <div class="row no-gutters h-100 app-dashboard-layout-side-frame">
          <div class="col-12 app-dashboard-layout-comments-frame">
            <app-comments [uid]="cell.uidWidget" [color]="color"></app-comments>
          </div>
          <div class="col-12 d-flex rounded blue-grey lighten-3 mb-2">
            <button type="button" mdbBtn color="light" mdbWavesEffect class="z-depth-0 blue-grey-text" (click)="previous()"><i class="fas fa-chevron-left"></i></button>
            <button type="button" mdbBtn color="light" mdbWavesEffect class="z-depth-0 blue-grey-text mx-auto" (click)="setCurrent(-1)"><i class="fas fa-list-ol"></i></button>
            <button type="button" mdbBtn color="light" mdbWavesEffect class="z-depth-0 blue-grey-text" (click)="next()"><i class="fas fa-chevron-right"></i></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!slides" class="app-dashboard-layout-frame" [ngStyle]="{ 'background-color': dashboardLayout.color, 'width': dashboardLayout.widthPixel }">
  <div class="mx-auto" appGridLayout [maxColumns]="dashboardLayout.maxColumns" [gap]="dashboardLayout.gap" [padding]="dashboardLayout.padding" [size]="dashboardLayout.size">
    <div appGridSpan *ngFor="let cell of dashboardLayout.cells | filters:filter ; let i = index" [columns]="cell.columns" [maxColumns]="dashboardLayout.maxColumns" [rows]="cell.rows" class="app-dashboard-layout-cell-depth-{{dashboardLayout.depth}}">

      <app-dashboard-cell [dashboardLayout]="dashboardLayout" [cell]="cell"></app-dashboard-cell>

    </div>
  </div>
</div>