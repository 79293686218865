<app-browser-panel (sendCreate)="receiveCreate()" [role]="'i'" (sendBookmark)="receiveBookmark()" (sendSearch)="receiveSearch()" [color]="color" [loading]="indicators == undefined" [empty]="indicators?.length == 0"
[title]="'LETS_BEGIN'" [text]="'LETS_BEGIN_INDICATOR'" [color]="color" [action]="'IMPORT'" [icon]="'far fa-file-import'" (sendAction)="onImport()">
  
  <div app-browser-panel-content-list *ngFor="let indicator of indicators | bookmarks:bookmarks | tags:tags | searchLabels:searchLabel:language ; trackBy: trackByUid">
    <app-item-tile class="d-none d-sm-block"
    [labels]="indicator.labels"
    [rights]="indicator.rights"
    [tags]="indicator.tags"
    [type]="'indicators'"
    [uid]="indicator.uid"
    [icon]="indicator.icon.code"
    [color]="indicator.color.hexa"
    [isModified]="indicator.modifications$.value.isModified"
    [isInfo]="indicator.descriptions.value(language).length > 0 || indicator.tags.list.length > 0"
    (sendEdit)="receiveEdit($event)"
    (sendStudio)="receiveSudio($event)"
    (sendInfo)="receiveInfo($event)"
    [isStudio]="true"
    ></app-item-tile>

    <app-item-strip class="d-block d-sm-none"
    [showInfoAndBookmark]="true"
    [labels]="indicator.labels"
    [type]="'indicators'"
    [uid]="indicator.uid"
    [tags]="indicator.tags"
    [icon]="indicator.icon.code"
    [color]="indicator.color.hexa"
    [isModified]="indicator.modifications$.value.isModified"
    [isInfo]="indicator.descriptions.value(language).length > 0 || indicator.tags.list.length > 0"
    (sendEdit)="receiveEdit($event)"
    (sendStudio)="receiveSudio($event)"
    (sendInfo)="receiveInfo($event)"
    [isStudio]="true"
    ></app-item-strip>
  </div>

  <div app-browser-panel-content-fixed-button>
    <a (click)="onImport()" mdbBtn floating="true" color="default" class="waves-light" mdbWavesEffect><i class="fad fa-file-import"></i></a>
    <a *ngIf="hasFilters" (click)="onRemoveFilters()" mdbBtn floating="true" color="warning" class="waves-light" mdbWavesEffect><i class="fad fa-search-minus"></i></a>
  </div>
</app-browser-panel>

<app-indicator-editor #editor [current]="current"></app-indicator-editor>

<app-item-info #info
[title]="current.labels.value(language)"
[icon]="current.icon.code"
[color]="color"
[iconColor]="current.color.hexa" 
[descriptions]="current.descriptions"
[tags]="current.tags"
></app-item-info>

<app-browser-filters #filtersModal [tagsList]="tagsList" [onlyBookmarks]="onlyBookmarks" [searchLabel]="searchLabel" (sendSearchLabel)="receiveSearchLabel($event)" (sendBookmark)="receiveBookmark($event)" (sendTags)="receiveTags($event)"></app-browser-filters>