/**
 * @Module Shared
 */

import { Pipe, PipeTransform } from '@angular/core';

export type FiltersPipeComparaisonType = '<' | '>' | '==' | '>=' | '<=' | '!=' | 'in' | 'nin'; //'in' et 'nin' uniquement pour les références de type array avec un requirements en 'value'

export interface FiltersPipeModel {
  field: string;
  type: 'array' | 'object' | 'string' | 'number' | 'boolean'
  requirements: {
    value?: {
      comparaison: FiltersPipeComparaisonType;
      reference: any;
    };
    length?: {
      comparaison: FiltersPipeComparaisonType;
      reference: number;
    };
    exist?: boolean;
  }
}

@Pipe({
  name: 'filters',
  pure: false
})
export class FiltersPipe implements PipeTransform {

  transform(items: any[], args: { [key: string]: FiltersPipeModel }): any {
    if(items === undefined || items.length === 0) return [];
    else {
      if(args === undefined || Object.keys(args).length === 0) return items;
      else {
        return items.filter((item: any) => {
          let display = true;
          Object.keys(args).forEach((key: string) => {
            if(display) {
              const arg = args[key];
              if(arg.requirements.exist !== undefined && item[arg.field] === undefined) display = false;
              else if(arg.requirements.value !== undefined && item[arg.field] === undefined) display = false;
              else if(arg.requirements.value !== undefined) {
                let value: any;
                switch(arg.type) {
                  case 'number':
                  case 'boolean':
                  case 'string': 
                    value = item[arg.field];
                    break;
                  case 'array': 
                    value = item[arg.field].toString(); 
                    break;
                  case 'object':
                    value = JSON.stringify(item[arg.field]);
                    break;
                }
                switch(arg.requirements.value.comparaison) {
                  case '!=':
                    if(value === arg.requirements.value.reference) display = false;
                    break;
                  case '==':
                    if(value !== arg.requirements.value.reference) display = false;
                    break;
                  case '<':
                    if(value >= arg.requirements.value.reference) display = false;
                    break;
                  case '>':
                    if(value <= arg.requirements.value.reference) display = false;
                    break;
                  case '>=':
                    if(value < arg.requirements.value.reference) display = false;
                    break;
                  case '<=':
                    if(value > arg.requirements.value.reference) display = false;
                    break;
                  case 'in':
                    if((arg.requirements.value.reference as any[]).indexOf(value) === -1) display = false;
                    break;
                  case 'nin':
                    if((arg.requirements.value.reference as any[]).indexOf(value) !== -1) display = false;
                    break;
                }
              }
              else if(arg.requirements.length !== undefined && item[arg.field] === undefined) display = false;
              else if(arg.requirements.length !== undefined) {
                let length = 0;
                switch(arg.type) {
                  case 'string': 
                  case 'boolean':
                  case 'array': 
                    length = item[arg.field].length; 
                    break;
                  case 'object':
                    length = Object.keys(item[arg.field]).length;
                    break;
                  case 'number': 
                    length = item[arg.field].toString().length; 
                    break;
                }
                switch(arg.requirements.length.comparaison) {
                  case '!=': 
                    if(length === arg.requirements.length.reference) display = false;
                    break;
                  case '==': 
                    if(length !== arg.requirements.length.reference) display = false;
                    break;
                  case '<': 
                    if(length >= arg.requirements.length.reference) display = false;
                    break;
                  case '>': 
                    if(length <= arg.requirements.length.reference) display = false;
                    break;
                  case '>=': 
                    if(length < arg.requirements.length.reference) display = false;
                    break;
                  case '<=': 
                    if(length > arg.requirements.length.reference) display = false;
                    break;
                }
              }
            }
          });
          return display;
        });
      }
    }
  }

}
