import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-theme-option-elements-chart-gauge-shadow-vertical-offset',
  templateUrl: './theme-option-elements-chart-gauge-shadow-vertical-offset.component.html',
  styleUrls: ['./theme-option-elements-chart-gauge-shadow-vertical-offset.component.css']
})
export class ThemeOptionElementsChartGaugeShadowVerticalOffsetComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
