<app-theme-option [categories]="themeOptions.optionsConfiguration[optionType].categories" [label]="themeOptions.optionsConfiguration[optionType].label" [information]="'PIXELS'" [limitedTo]="themeOptions.limitedTo(optionType)">
  <div app-theme-option-content>
    <div class="d-flex ml-auto align-items-center">
      <!--button mdbBtn (click)="onDetails()" flat="true" type="button" class="px-2 z-depth-0 my-0 m-0">
        <i *ngIf="!showDetails" class="fad fa-caret-circle-right blue-grey-text fa-lg"></i>
        <i *ngIf="showDetails" class="fad fa-caret-circle-left blue-grey-text fa-lg"></i>
      </button-->
      <app-check [color]="color" [value]="themeOptions.series(serieIndex).spanGaps" (sendValue)="receiveValue($event)"></app-check>
    </div>
  </div>
</app-theme-option>

<!--div *ngIf="showDetails">
  <app-theme-option [categories]="themeOptions.optionsConfiguration[optionType].categoriesAndLabel" [label]="type | chart" [dark]="4" *ngFor="let type of themeOptions.limitedTo(optionType)">
    <div app-theme-option-content>
      <app-check [color]="color" [value]="themeOptions.series(serieIndex, type).spanGaps" (sendValue)="receiveValue($event, type)"></app-check>
    </div>
  </app-theme-option>
</div-->