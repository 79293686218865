/**
 * @module Widget
 */

import { Component, OnInit, Input } from '@angular/core';
import { WidgetLayersClass } from '@class/widgetLayers.class';
import { ItemColor } from '@functions/item.functions';
import { Subscription } from 'rxjs';
import { ModificationStateClass } from '@class/modification.class';

@Component({
  selector: 'app-widget-indicators-stack',
  templateUrl: './widget-indicators-stack.component.html',
  styleUrls: ['./widget-indicators-stack.component.css']
})
export class WidgetIndicatorsStackComponent implements OnInit {

  @Input() widgetLayers: WidgetLayersClass;
  @Input() current: number;

  color = ItemColor('indicators');
  values: string[];
  modifications$sub: Subscription;

  constructor() {}
 
  ngOnInit() {
    this.setAttributes();

    this.modifications$sub = this.widgetLayers.modifications$.subscribe({
      next:(modifications: ModificationStateClass) => {
        if(modifications.has('indicators')) {
          this.setAttributes();
        }
      }
    });
  }

  ngOnDestroy() {
    this.modifications$sub.unsubscribe();
  }

  setAttributes() {
    this.values = new Array(this.widgetLayers.indicators.length).fill('').map((value: any, index: number) => { return index.toString(); });
  }

  onValue(value?: string) {
    this.widgetLayers.indicators[this.current].change({ stack: value !== undefined ? value : null });
  }

}
