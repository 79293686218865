<div mdbModal #helperModal="mdbModal" class="modal fade" style="overflow-y: scroll" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header d-flex align-items-center flex-column">

        <i class="animated rotateIn text-info far fa-info-circle fa-4x d-none d-sm-inline"></i>
        <i class="animated rotateIn text-info far fa-info-circle fa-2x d-sm-none"></i>

        <h6 class="modal-title w-100 text-center">
          <div class="font-weight-bold text-elegant mt-1">{{ title | translate }}</div>
        </h6>

        <button type="button" size="sm" mdbBtn color="secondary" class="waves-light z-depth-0 px-2 m-0 app-close-modal" (click)="helperModal.hide()" mdbWavesEffect>
          <i class="fas fa-times fa-fw"></i>
        </button>

      </div>
      <div class="modal-body overflow-hidden" *ngIf="helperModal.isShown">
        
        <ng-content select="[app-helper-content]"></ng-content>

      </div>
    </div>
  </div>
</div>