/**
 * @module Indicator
 */

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IndicatorClass } from '@class/indicator.class';
import { ItemColor, ItemIcon } from '@functions/item.functions';

@Component({
  selector: 'app-indicators-select-item',
  templateUrl: './indicators-select-item.component.html',
  styleUrls: ['./indicators-select-item.component.css']
})
export class IndicatorsSelectItemComponent {

  @Input() indicator: IndicatorClass;
  @Input() isSelected: boolean;

  @Output() sendSelected: EventEmitter<IndicatorClass>;

  color = ItemColor('indicators')
  icon = ItemIcon('indicators')

  constructor() { 
    this.sendSelected = new EventEmitter<IndicatorClass>();
  }

  receiveSelected() {
    this.sendSelected.emit(this.indicator);
  }

}
