/**
 * @module Numbro
 */

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-numbro-option',
  templateUrl: './numbro-option.component.html',
  styleUrls: ['./numbro-option.component.css']
})
export class NumbroOptionComponent {

  @Input() label: string;
  @Input() sublabel: string;
  @Input() categories: string[];
  @Input() information: string;
  @Input() subinformation: string;
  @Input() dark: number = 5;
  @Input() color: string;

  constructor() {}

}
