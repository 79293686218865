/**
 * @module Dashboard
 */

import { Directive, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appGridSpan]'
})
export class GridSpanDirective implements OnChanges {

  @HostBinding('style.grid-column-end') colspan: string;
  @HostBinding('style.grid-row-end') rowspan: string;

  @Input() public maxColumns: number;
  @Input() public columns: number;
  @Input() public rows: number;

  constructor() {}

  ngOnChanges(changes: SimpleChanges){
    this.colspan = `span ${Math.min(this.columns, this.maxColumns)}`;
    this.rowspan = `span ${this.rows}`;
  }

}
