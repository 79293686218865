/**
 * @module Server
 */

import { Component, Input } from '@angular/core';
import { ServerClass } from '@class/server.class';
import { ItemColor } from '@functions/item.functions';

@Component({
  selector: 'app-url',
  templateUrl: './url.component.html',
  styleUrls: ['./url.component.css']
})
export class UrlComponent {

  @Input() server: ServerClass;
  
  color: string = ItemColor('servers');

  constructor() { }

  onUrl(url: string) {
    this.server.change({ url: url });
  }

}
