/**
 * @module Theme
 */

import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ItemColor } from '@functions/item.functions';
import { ThemeOptionsClass } from '@class/themeOptions.class';
import { ChartType } from '@models/chart.model';
import { Subscription } from 'rxjs';
import { ThemeOptionType } from '@models/themeStudio.model';

@Component({
  selector: 'app-theme-option-datalabels-align',
  templateUrl: './theme-option-datalabels-align.component.html',
  styleUrls: ['./theme-option-datalabels-align.component.css']
})
export class ThemeOptionDatalabelsAlignComponent implements OnInit, OnDestroy {

  @Input() themeOptions: ThemeOptionsClass;
  @Input() datalabelsIndex: number;

  color = ItemColor('themes');
  optionType: ThemeOptionType = 'datalabels_align';
  showDetails: boolean;
  value: any;
  values: { [key in ChartType]: any };
  changedSeries$sub: Subscription;

  constructor() {}
  
  ngOnInit() {
    this.setAttributes();

    this.changedSeries$sub = this.themeOptions.changedSeries$.subscribe({
      next:(option: ThemeOptionType) => {
        if(option === this.optionType) {
          this.setAttributes();
        }
      }
    });
  }

  ngOnDestroy() {
    this.changedSeries$sub.unsubscribe();
  }

  setAttributes() {
    this.values = {} as { [key in ChartType]: any };
    this.value = this.themeOptions.datalabels(this.datalabelsIndex).align === 'center' ? 0 : this.themeOptions.datalabels(this.datalabelsIndex).align;
    this.themeOptions.limitedTo(this.optionType).forEach((type: ChartType) => {
      this.values[type] = this.themeOptions.datalabels(this.datalabelsIndex, type).align === 'center' ? 0 : this.themeOptions.datalabels(this.datalabelsIndex, type).align;
    });
  }

  receiveShowDetails(showDetails: boolean) {
    this.showDetails = showDetails;
  }

  receiveValue(value: number, type?: ChartType) {
    this.themeOptions.setDatalabels(this.optionType, value === 0 ? 'center' : value, this.datalabelsIndex, type ? [type] : undefined);
  }

}
