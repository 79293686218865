<div class="btn-group mb-2 d-flex" mdbDropdown>
  <button mdbDropdownToggle type="button" mdbBtn color="light" class="flex-grow-1 blue-grey-text z-depth-0 dropdown-toggle waves-light d-flex align-items-center px-3" mdbWavesEffect>
    <i class="fas fa-arrow-alt-to-bottom fa-fw"></i>
    <span class="ml-1 mr-auto">{{ label | translate }}</span>&nbsp;
  </button>

  <div class="dropdown-menu dropdown-dark dropdown-menu-right app-drop-small-limit-5">

    <a *ngFor="let main of mains" class="dropdown-item z-depth-0 d-flex align-items-center" (click)="onType(main)">
      <span class="mr-1">{{ main.description }}</span><mdb-badge color="blue-grey" class="z-depth-0">{{ main.ext }}</mdb-badge>
    </a>
    <div class="divider dropdown-divider"></div>
    <a *ngFor="let type of types" class="dropdown-item z-depth-0 d-flex align-items-center" (click)="onType(type)">
      <span class="mr-1">{{ type.description }}</span><mdb-badge color="blue-grey" class="z-depth-0">{{ type.ext }}</mdb-badge>
    </a>

  </div>
</div>