/**
 * @module Theme
 */

import { Component, Input } from '@angular/core';
import { ItemColor } from '@functions/item.functions';
import { ThemeOptionsClass } from '@class/themeOptions.class';
import { ChartType } from '@models/chart.model';
import { ThemeOptionType } from '@models/themeStudio.model';

@Component({
  selector: 'app-theme-option-elements-border-join-style',
  templateUrl: './theme-option-elements-border-join-style.component.html',
  styleUrls: ['./theme-option-elements-border-join-style.component.css']
})
export class ThemeOptionElementsBorderJoinStyleComponent {

  @Input() themeOptions: ThemeOptionsClass;
  @Input() serieIndex: number;

  showDetails: boolean;
  color = ItemColor('themes');
  optionType: ThemeOptionType = 'elements_borderJoinStyle';

  constructor() {
    this.showDetails = false;
  }

  onValue(value: string, type?: ChartType) {
    this.themeOptions.setSeries(this.optionType, value === 'false' ? false : value, this.serieIndex, type ? [type] : undefined);
  }

  receiveShowDetails(showDetails: boolean) {
    this.showDetails = showDetails;
  }

  values = {
    miter: { style: 'miter', label: 'MITER' },
    round: { style: 'round', label: 'ROUND' },
    bevel: { style: 'bevel', label: 'BEVEL' }
  };

}
