/**
 * @module Browser
 */

import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { UID } from '@models/uid.model';
import { ModalDirective } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-browser-filters',
  templateUrl: './browser-filters.component.html',
  styleUrls: ['./browser-filters.component.css']
})
export class BrowserFiltersComponent {

  @Input() tagsList: UID[];
  @Input() onlyBookmarks: boolean;
  @Input() searchLabel: string;
  @Output() sendSearchLabel: EventEmitter<string>;;
  @Output() sendBookmark: EventEmitter<boolean>;
  @Output() sendTags: EventEmitter<UID[]>;
  
  @ViewChild('filtersModal', { static: true }) filtersModal: ModalDirective; 

  tags: UID[] = [];

  constructor() {
    this.sendSearchLabel = new EventEmitter<string>();
    this.sendBookmark = new EventEmitter<boolean>();
    this.sendTags = new EventEmitter<UID[]>();
  }

  receiveSelectedTag(uid: UID) {
    const index = this.tags.indexOf(uid);
    if(index === -1) this.tags.push(uid);
    else this.tags.splice(index, 1);
    this.sendTags.emit(this.tags);
  }

  isSelectedTags(uid: UID) {
    return this.tags.indexOf(uid) !== -1;
  }

  onBookmark() {
    this.sendBookmark.emit(!this.onlyBookmarks);
  }

  public show() {
    if(this.filtersModal.isShown) this.filtersModal.hide();
    else this.filtersModal.show();
  }

  public hide() {
    this.filtersModal.hide();
  }

  receiveTextValue(searchLabel: string) {
    this.sendSearchLabel.emit(searchLabel);
  }

  removeFilters() {
    this.searchLabel = '';
    this.tags = [];
    this.onlyBookmarks = false;
  }

  onRemoveFilters() {
    this.removeFilters();
    this.sendSearchLabel.emit('');
    this.sendTags.emit([])
    this.sendBookmark.emit(false);
  }

}
