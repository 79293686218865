/**
 * @module Empty
 */

import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ItemType } from '@models/item.model';
import { ItemColor } from '@functions/item.functions';

@Component({
  selector: 'app-empty',
  templateUrl: './empty.component.html',
  styleUrls: ['./empty.component.css']
})
export class EmptyComponent implements OnInit {

  @Input() actionText: string = 'NEW';
  @Input() actionIcon: string = 'fa-plus';
  @Input() description: string = 'EMPTY_LIST/TEXT';
  @Input() actionDescription: string = 'EMPTY_LIST_GET_STARTED/TEXT';
  @Input() type: ItemType;
  @Input() opaque: boolean = true;
  @Input() action: boolean = true;
  @Output() sendAction: EventEmitter<void>;

  color: string;

  constructor() {
    this.sendAction = new EventEmitter<void>();
  }

  ngOnInit() {
    this.color = ItemColor(this.type);
  }

  onAction() {
    this.sendAction.emit();
  }

}
