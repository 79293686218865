/**
 * @module User
 */

import { Component, ViewChild, OnDestroy } from '@angular/core';
import { EditorComponent } from '@components/forms/editor/editor.component';
import { ItemColor, ItemIcon, ItemLabel } from '@functions/item.functions';
import { UserClass } from '@class/user.class';
import { Subscription } from 'rxjs';
import { UserService } from '@services/user/user.service';
import { MessagesService } from '@services/messages/messages.service';
import { UID } from '@models/uid.model';
import { BrowserFrameComponent } from '@components/browser/browser-frame/browser-frame.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnDestroy {

  @ViewChild('editor', { static: true }) editor: EditorComponent;
  @ViewChild('frameModal', { static: true }) frameModal: BrowserFrameComponent;

  label = ItemLabel('users');
  color = ItemColor('users');
  icon = ItemIcon('users');
  current: UserClass;
  editorIndex: number;
  editorIndexMax: number;
  users: UserClass[];
  users$sub: Subscription;
  searchLabel: string

  constructor(
    private $users: UserService,
    private $messages: MessagesService
  ) {
    
    this.users$sub = this.$users.list$().subscribe({
      next: (users: UserClass[]) => {
        this.users = users.sort((a : UserClass, b: UserClass) => { return a.id.fullname.localeCompare(b.id.fullname); });
      }
    });

    this.current = this.$users.create();
    this.editorIndex = 0;
    this.editorIndexMax = 5;
  }

  ngOnDestroy() {
    this.users$sub.unsubscribe();
  }

  public show() {
    this.frameModal.show();
  }

  trackByUid(index: number, user: UserClass) {
    return user.uid.value;
  }

  receiveCreate() {
    if(this.current.uid.isRegistered) this.current = this.$users.create();
    this.editor.show();
  }

  receiveEdit(uid: UID) {
    this.current = this.users.filter((user: UserClass) => {
      return user.uid.value === uid;
    })[0];
    this.editor.show();
  }

  receiveSearch(searchLabel: string) {
    this.searchLabel = searchLabel;
  }

  receiveRestore() {
    this.current.reset();
  }

  receiveDelete() {
    this.editorIndex = NaN;
  }

  receiveDeleteConfirmation() {
    this.$users.remove(this.current.uid.value)
    .then(() => {
      this.editor.hide();
      this.editorIndex = 0;
      this.$messages.success({ 
        title: { plain: this.current.id.fullname }, 
        text: { code: 'SUCCESSFULLY_REMOVED/TEXT' } 
      });
      this.current = this.$users.create();
    })
    .catch((error) => {
      this.$messages.error({ 
        title: { plain: this.current.id.fullname }, 
        text: { 
          code: 'REMOVE_FAILED/TEXT',
          params: { error: error.code }
        } 
      });
    });
  }

  receiveNext(isNext: boolean) {
    this.editorIndex = this.editorIndex + (isNext ? 1 : -1);
    if(this.editorIndex < 0) this.editorIndex = this.editorIndexMax;
    if(this.editorIndex > this.editorIndexMax) this.editorIndex = 0;
  }  
  
  receiveSave() {
    this.$users.set(this.current)
    .then(() => {
      if(!this.current.uid.isRegistered) this.current = this.$users.create();
      this.editor.hide();
      this.$messages.success({ 
        title: { plain: this.current.id.fullname }, 
        text: { code: 'SUCCESSFULLY_SAVED/TEXT' } 
      });
    })
    .catch((error) => {
      console.log(error)
      this.$messages.error({ 
        title: { plain: this.current.id.fullname }, 
        text: { 
          code: 'SAVE_FAILED/TEXT',
          params: { error: error.code }
        } 
      });
    });
  }

}
