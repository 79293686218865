/**
 * @module Dashboard
 */

import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { WidgetLayersClass } from '@class/widgetLayers.class';
import { DashboardLayoutClass } from '@class/dashboardLayout.class';
import { Subscription } from 'rxjs';
import { DashboardDisplayService } from '@services/dashboardDisplay/dashboard-display.service';

@Component({
  selector: 'app-dashboard-dates-bounds',
  templateUrl: './dashboard-dates-bounds.component.html',
  styleUrls: ['./dashboard-dates-bounds.component.css']
})
export class DashboardDatesBoundsComponent implements OnInit, OnDestroy {

  @Input() dashboardLayout: DashboardLayoutClass;

  widgetLayers: WidgetLayersClass
  widgetsLayers$sub: Subscription;

  constructor(
    private $display: DashboardDisplayService
  ) {}

  ngOnInit() {
    this.widgetsLayers$sub = this.$display.widgetLayers$(this.dashboardLayout.uid.value).subscribe({
      next: (widgetLayers: WidgetLayersClass) => {
        this.widgetLayers = widgetLayers;
      }
    }); 
  }

  ngOnDestroy() {
    this.widgetsLayers$sub.unsubscribe();
  }

}
