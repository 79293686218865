<div class="mt-3">

  <app-input [marginTop]="false" [label]="'SEARCH'" [color]="color" [value]="searchLabel" (sendValue)="receiveTextValue($event)"></app-input>

  <app-indicators-select-item *ngFor="let indicator of list | searchLabels:searchLabel:language" 
  [indicator]="indicator" [isSelected]="isSelected(indicator.uid.value)"
  (sendSelected)="receiveSelected($event)"></app-indicators-select-item>

</div>

<app-empty app-browser-frame-content-list [action]="false" [type]="'indicators'" *ngIf="list != undefined && list.length == 0"></app-empty>
<app-loading app-browser-frame-content-list *ngIf="list == undefined"></app-loading>