/**
 * @module Widget
 */

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ThemeOptionConfigurationType } from '@models/themeOptions.model';
import { ItemColor } from '@functions/item.functions';
import { WidgetLayersDefinedOptionModel } from '@models/widgetLayers.model';
import { ThemeOptionType } from '@models/themeStudio.model';

@Component({
  selector: 'app-widget-layers-theme-options-item',
  templateUrl: './widget-layers-theme-options-item.component.html',
  styleUrls: ['./widget-layers-theme-options-item.component.css']
})
export class WidgetLayersThemeOptionsItemComponent {

  @Input() key: ThemeOptionType;
  @Input() option: ThemeOptionConfigurationType;
  @Output() sendSelect: EventEmitter<WidgetLayersDefinedOptionModel>;

  color = ItemColor('themes');

  constructor() {
    this.sendSelect = new EventEmitter<WidgetLayersDefinedOptionModel>();
  }

  onSelect() {
    this.sendSelect.emit({ option: this.key, type: this.option.type });
  }
 
}
