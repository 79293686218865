<app-editor #editor (sendRestore)="receiveRestore()" (sendDelete)="receiveDelete()" (sendSave)="receiveSave()"
[title]="current.labels.value(language)" [uid]="current.uid.value" [icon]="icon" [color]="color" [isRegistered]="current.uid.isRegistered" [isValid]="current.isValid()" [isModified]="current.isModified">
<div app-editor-content-menu>

  <a class="dropdown-item z-depth-0" (click)="editorIndex = 0">{{ 'SETTINGS' | translate }}</a>
  <a class="dropdown-item z-depth-0" (click)="editorIndex = 1">{{ 'TAGS' | translate }}</a>

</div>
<div app-editor-content-body *ngIf="editor.isShown">
  <div [ngSwitch]="editorIndex">
    <div *ngSwitchCase="0">
      <app-editor-section-title [title]="'SETTINGS'" (sendNext)="receiveNext($event)"></app-editor-section-title>
      <app-labels [labels]="current.labels" [color]="color"></app-labels>
      <app-descriptions [descriptions]="current.descriptions" [color]="color"></app-descriptions>
    </div>
    <div *ngSwitchCase="1">
      <app-editor-section-title [title]="'TAGS'" (sendNext)="receiveNext($event)"></app-editor-section-title>
      <app-tags-links-list [tags]="current.tags"></app-tags-links-list>
    </div>
    <div *ngSwitchDefault>
      <app-delete [code]="current.uid.code" (sendDelete)="receiveDeleteConfirmation($event)"></app-delete>
    </div>
  </div>
</div>

</app-editor>