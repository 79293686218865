/**
 * @module Dashboard
 */

import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { DashboardLayoutClass } from '@class/dashboardLayout.class';
import { StudioComponent } from '@components/forms/studio/studio.component';
import { DashboardLayoutCellClass } from '@class/dashboardLayoutCell.class';
import { UID } from '@models/uid.model';
import { SortableStopModel } from '@directives/sortable/sortable.directive';

@Component({
  selector: 'app-dashboard-studio-layout',
  templateUrl: './dashboard-studio-layout.component.html',
  styleUrls: ['./dashboard-studio-layout.component.css']
})
export class DashboardStudioLayoutComponent implements OnInit {

  @Input() dashboardLayout: DashboardLayoutClass;
  @Input() studio: StudioComponent;
  @Input() current: UID;
  @Input() isDraggable: boolean;
  @Output() sendSelected: EventEmitter<DashboardLayoutCellClass>;

  constructor() {
    this.sendSelected = new EventEmitter<DashboardLayoutCellClass>();
  }

  ngOnInit() {
    this.setWidth();
    this.studio.framesChange$.subscribe(() => this.setWidth());
  }

  setWidth() {
    let width = window.innerWidth - (this.studio.framesCount - 1) * this.studio.frameSize;
    this.dashboardLayout.setWidth(width);
  }

  receiveSelected(cell: DashboardLayoutCellClass) {
    this.current = cell.uid.value;
    this.sendSelected.emit(cell);
  }

  receiveSortableStop(event: SortableStopModel) {
    this.dashboardLayout.rankCell(event.sourceIndex, event.targetIndex);
  }

}
