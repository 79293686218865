/**
 * @module Theme
 */

import { Component, OnInit, Input } from '@angular/core';
import { ItemColor } from '@functions/item.functions';
import { ThemeOptionsClass } from '@class/themeOptions.class';
import { ThemeOptionType } from '@models/themeStudio.model';

@Component({
  selector: 'app-theme-option-datalabels-padding',
  templateUrl: './theme-option-datalabels-padding.component.html',
  styleUrls: ['./theme-option-datalabels-padding.component.css']
})
export class ThemeOptionDatalabelsPaddingComponent implements OnInit {

  @Input() themeOptions: ThemeOptionsClass;
  @Input() datalabelsIndex: number;

  color = ItemColor('themes');
  optionType: ThemeOptionType = 'datalabels_padding';
  all: number;
  showDetails: boolean;

  constructor() {}

  ngOnInit() {
    this.all = Math.max(...Object.values(this.themeOptions.datalabels(this.datalabelsIndex).padding));
  }

  receiveShowDetails(showDetails: boolean) {
    this.showDetails = showDetails;
  }

  receiveValue(value: number) {
    this.themeOptions.setDatalabels('datalabels_padding_top', value, this.datalabelsIndex);
    this.themeOptions.setDatalabels('datalabels_padding_left', value, this.datalabelsIndex);
    this.themeOptions.setDatalabels('datalabels_padding_right', value, this.datalabelsIndex);
    this.themeOptions.setDatalabels('datalabels_padding_bottom', value, this.datalabelsIndex);
  }

}
