<div class="mt-3">

  <app-rights-switch-full *ngIf="uids != undefined" class="d-block mb-1" (sendFull)="receiveFull($event)" [full]="false"></app-rights-switch-full>
  
  <app-input [marginTop]="false" [label]="'SEARCH'" [color]="color" [value]="searchLabel" (sendValue)="receiveTextValue($event)"></app-input>

  <app-rights-links-item *ngFor="let uid of uids" [searchLabel]="searchLabel"
  [uid]="uid" [isReader]="isReader(uid)" [isWriter]="isWriter(uid)"
  (sendReader)="receiveReader($event)" (sendWriter)="receiveWriter($event)"></app-rights-links-item>

</div>

<app-empty class="d-block mt-1" app-browser-frame-content-list [action]="false" [type]="'rights'" *ngIf="uids != undefined && uids.length == 0"></app-empty>
<app-loading app-browser-frame-content-list *ngIf="uids == undefined"></app-loading>