<div class="d-flex align-items-center justify-content-between mt-3" style="height: 31px;">
  <div class="blue-grey-text font-weight-bold d-flex align-items-center">
    <i class="fa-lg fa-fw far fa-pencil-paintbrush {{color}}-text mr-1"></i>
    <div class="mx-1">{{ 'LEGEND' | translate }}</div>
  </div>
  <div class="btn-group" mdbDropdown>
    <button mdbDropdownToggle type="button" size="sm" mdbBtn color="light" class="blue-grey-text z-depth-0 dropdown-toggle waves-light d-flex align-items-center px-3" mdbWavesEffect>
      <i class="far fa-bars"></i>&nbsp;
    </button>
  
    <div class="dropdown-menu dropdown-dark dropdown-menu-right">

      <a class="dropdown-item z-depth-0" (click)="editorIndex = 0">{{ 'SETTINGS' | translate }}</a>
      <a class="dropdown-item z-depth-0" (click)="editorIndex = 1">{{ 'FONT' | translate }}</a>

    </div>
  </div>
</div>

<hr>

<div [ngSwitch]="editorIndex">
  <div *ngSwitchCase="0">
    <app-editor-section-title [title]="'SETTINGS'" (sendNext)="receiveNext($event)"></app-editor-section-title>

    <app-theme-option-legend-display [themeOptions]="themeOptions"></app-theme-option-legend-display>
    <app-theme-option-legend-position [themeOptions]="themeOptions"></app-theme-option-legend-position>
    <app-theme-option-legend-padding [themeOptions]="themeOptions"></app-theme-option-legend-padding>
    <app-theme-option-legend-full-width [themeOptions]="themeOptions"></app-theme-option-legend-full-width>
    <app-theme-option-legend-reverse [themeOptions]="themeOptions"></app-theme-option-legend-reverse>
    <app-theme-option-legend-box-width [themeOptions]="themeOptions"></app-theme-option-legend-box-width>
    <app-theme-option-legend-use-point-style [themeOptions]="themeOptions"></app-theme-option-legend-use-point-style>
  
  </div>
  <div *ngSwitchCase="1">
    <app-editor-section-title [title]="'FONT'" (sendNext)="receiveNext($event)"></app-editor-section-title>

    <app-theme-option-legend-font-family [themeOptions]="themeOptions"></app-theme-option-legend-font-family>
    <app-theme-option-legend-font-size [themeOptions]="themeOptions"></app-theme-option-legend-font-size>
    <app-theme-option-legend-font-style [themeOptions]="themeOptions"></app-theme-option-legend-font-style>
    <app-theme-option-legend-font-color [themeOptions]="themeOptions"></app-theme-option-legend-font-color>
    
  </div>
</div>