/**
 * @module Color
 */

import { Injectable } from '@angular/core';
import * as colors from 'json/colors.json';
import { ColorStockModel } from '@models/color.model';

@Injectable({
  providedIn: 'root'
})
export class ColorsService {

  private _colors: ColorStockModel[];
  private _gradiants: string[][];

  constructor() {
    this._colors = colors.default;
    this._gradiants = Object.values(this._colors).map(c => c.list.map(h => h.hexa));
    this._gradiants.shift(); //la première serie n'est pas un dégradé
  }

  list(): ColorStockModel[] {
    return this._colors;
  }

  gradiantForColor(color: string, length?: number): string[] {
    let colors = this._gradiants.filter(l => l.indexOf(color) !== -1)[0];

    if(length !== undefined && colors.length < length) {
      //colors = colors.concat(colors.slice(0, length - colors.length));
      let newArr = new Array(length);
    
      for(let i = 0; i < length; i++) {
        newArr[i] = colors[i % colors.length];
      }
      return newArr;
    }
    else return colors;
  }
  
}
