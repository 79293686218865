/**
 * @module Auth
 */

import { Component, Input } from '@angular/core';
import { FirebaseService } from '@services/firebase/firebase.service';
import { Router } from '@angular/router';
import { MessagesService } from '@services/messages/messages.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent {

  @Input() email: string;
  @Input() actionCode: string;

  password: string;
  isWaiting: boolean;

  constructor(
    private $firebase: FirebaseService,
    private $router: Router,
    private $message: MessagesService
  ) {
    this.isWaiting = false;
    this.password = '';
  }

  setPassword() {
    this.isWaiting = true;
    this.$firebase.angularFireAuth.auth.confirmPasswordReset(this.actionCode, this.password).then(() => {
      this.isWaiting = false;
      this.$message.success({ text: { code: 'PASSWORD_RESET_SUCCESS' } });
      this.$router.navigate(['/']);
    }).catch((error) => {
      this.isWaiting = false;
      this.$message.warning({ text: { code: 'PASSWORD_RESET_FAILED' } });
    });
  }
}
