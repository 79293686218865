<div *ngIf="mergedOptions" class="d-flex justify-content-center align-items-center flex-column app-widget-studio-chart-sticker" 
  [ngStyle]="{ 'background-color': mergedOptions.layout.backgroundColor }">
  <mdb-carousel *ngIf="mergedOptions.carousel ; else flexbox" #carousel [isControls]="false" style="width: 100%;" class="carousel slide carousel-{{ mergedOptions.transitionAnimation }}" [animation]="mergedOptions.transitionAnimation" [interval]="'0'"
    [ngStyle]="{ 'background-color': mergedOptions.layout.backgroundColor }">
    <mdb-carousel-item  *ngFor="let dataset of styles.datasets ; let i = index" class="w-100">
      <div class="d-flex align-items-center justify-content-center p-3 m-2 rounded" style="width: calc(100% - 16px);" [ngStyle]="dataset.layout">
        
        <span data-html2canvas-ignore="true" *ngIf="styles.datasets.length > 1" class="py-2 pr-2 pl-3 app-cursor-pointer mr-auto" (click)="prev()">
          <i class="fas fa-angle-left" [ngClass]="{ 'white-text': !dataset.layout.isBright, 'text-black-50': dataset.layout.isBright }"></i>
        </span>
        
        <div *ngIf="mergedOptions.template == 1" [ngTemplateOutlet]="template1" [ngTemplateOutletContext]="{ dataset: dataset }"></div>
        <div *ngIf="mergedOptions.template == 2" class="d-flex flex-column align-items-start px-4 w-100"><div [ngTemplateOutlet]="template2" [ngTemplateOutletContext]="{ dataset: dataset }"></div></div>
        
        <span data-html2canvas-ignore="true" *ngIf="styles.datasets.length > 1" class="py-2 pl-2 pr-3 app-cursor-pointer ml-auto" (click)="next()">
          <i class="fas fa-angle-right" [ngClass]="{ 'white-text': !dataset.layout.isBright, 'text-black-50': dataset.layout.isBright }"></i>
        </span>

      </div>
    </mdb-carousel-item>
  </mdb-carousel>
</div>

<ng-template #flexbox>
  <div [ngSwitch]="mergedOptions.template">
    <div *ngSwitchCase="1" class="h-100 w-100 flex-wrap d-flex flex-column align-items-center justify-content-center">
      <div class="flex-wrap d-flex flex-{{ mergedOptions.orientation }}">
        
        <div *ngFor="let dataset of styles.datasets ; let i = index; let isLast = last" [ngStyle]="dataset.layout"
          style="flex: 1 1 0px" class="border d-flex align-items-center justify-content-start p-3 mx-2 mt-2 rounded" 
          [ngClass]="{ 'mb-2': isLast || mergedOptions.orientation == 'row' }">
          <div [ngTemplateOutlet]="template1" [ngTemplateOutletContext]="{ dataset: dataset }"></div>
        </div>

      </div>
    </div>
    <div *ngSwitchCase="2" class="h-100 w-100 flex-wrap d-flex flex-column align-items-center justify-content-center">
      <div class="flex-wrap d-flex flex-{{ mergedOptions.orientation }}">
        <div *ngFor="let dataset of styles.datasets ; let i = index; let isLast = last" style="flex: 1 1 0px" 
          class="border d-flex flex-column align-items-start justify-content-start p-3 mx-2 mt-2 rounded" 
          [ngClass]="{ 'mb-2': isLast || mergedOptions.orientation == 'row' }" [ngStyle]="dataset.layout">
          <div [ngTemplateOutlet]="template2" [ngTemplateOutletContext]="{ dataset: dataset }"></div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #template1 let-dataset="dataset">
  <div class="p-2 {{ dataset.icon.frame }} d-flex align-items-center mr-3 app-text-shadow-depth-1" style="height: 56px;" [ngStyle]="dataset.frame">
    <i class="{{ dataset.icon.code }} fa-fw fa-2x"></i>
  </div>
  <div>
    <div [ngStyle]="dataset.datalabels" style="white-space: nowrap;" [ngClass]="{ 'app-text-shadow-depth-1': mergedOptions.fullyColored }">{{ dataset.total }}</div>
    <div *ngIf="mergedOptions.legend.display" [ngStyle]="dataset.label">{{ dataset.label.label }}</div>
    <div *ngIf="mergedOptions.title.display" [ngStyle]="dataset.title">{{ mergedOptions.title.textInline }}</div>
  </div>
</ng-template>

<ng-template #template2 let-dataset="dataset">
  <div class="d-flex align-items-top justify-content-between w-100">
    <div>
      <div *ngIf="mergedOptions.legend.display" [ngStyle]="dataset.label">{{ dataset.label.label }}</div>
      <div *ngIf="mergedOptions.title.display" [ngStyle]="dataset.title">{{ mergedOptions.title.textInline }}</div>
    </div>
    <div class="p-2 {{ dataset.icon.frame }} d-flex align-items-center ml-5 app-text-shadow-depth-1" style="height: 36px;" [ngStyle]="dataset.frame">
      <i class="{{ dataset.icon.code }} fa-fw"></i>
    </div>
  </div>
  <div [ngStyle]="dataset.datalabels" style="white-space: nowrap;" class="mt-3" [ngClass]="{ 'app-text-shadow-depth-1': mergedOptions.fullyColored }">{{ dataset.total }}</div>
</ng-template>