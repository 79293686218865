<app-form-strip>
  <div app-form-strip-content-left>
    <i class="fad fa-fw fa-paper-plane fa-lg mt-1"></i>
  </div>

  <div app-form-strip-content-middle>
    {{ 'RESET_PASSWORD_EMAIL' | translate }}
  </div>

  <div app-form-strip-content-right>
    <button (click)="onReset()" mdbBtn size="sm" type="button" color="blue-grey" class="z-depth-0 px-3 m-0" mdbWavesEffect>{{ 'SEND' | translate }}</button>
  </div>
</app-form-strip>