/**
 * @module Dashboard
 */

import { Component, Input, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { WidgetLayersClass } from '@class/widgetLayers.class';
import { ItemColor } from '@functions/item.functions';
import { HelperDateFormatComponent } from '@components/helpers/helper-date-format/helper-date-format.component';
import { DashboardLayoutClass } from '@class/dashboardLayout.class';
import { Subscription } from 'rxjs';
import { DashboardDisplayService } from '@services/dashboardDisplay/dashboard-display.service';

@Component({
  selector: 'app-dashboard-date-format',
  templateUrl: './dashboard-date-format.component.html',
  styleUrls: ['./dashboard-date-format.component.css']
})
export class DashboardDateFormatComponent implements OnInit, OnDestroy {

  @Input() dashboardLayout: DashboardLayoutClass;
  @ViewChild('dateFormatHelper', { static: true }) dateFormatHelper: HelperDateFormatComponent;

  widgetLayers: WidgetLayersClass
  widgetsLayers$sub: Subscription;

  color = ItemColor('dashboards');
  timeout: any;

  constructor(
    private $display: DashboardDisplayService
  ) {}
  
  ngOnInit() {
    this.widgetsLayers$sub = this.$display.widgetLayers$(this.dashboardLayout.uid.value).subscribe({
      next: (widgetLayers: WidgetLayersClass) => {
        this.widgetLayers = widgetLayers;
      }
    });
  }

  ngOnDestroy() {
    this.widgetsLayers$sub.unsubscribe();
  }

  receiveValue(value: string) {
    if(this.timeout !== undefined) clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.widgetLayers.dateFormat = value;
    }, 250);
  }

}
