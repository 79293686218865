/**
 * @module Date
 */

import { Component, Input, OnInit, OnDestroy, EventEmitter, Output, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { DateClass } from '@class/date.class';
import { ItemColor, ItemIcon } from '@functions/item.functions';
import { DateService } from '@services/date/date.service';
import { UID } from '@models/uid.model';
import { Subscription } from 'rxjs';
import { DeepCopy } from '@functions/copy.functions';
import { LinksClass } from '@class/links.class';
import { LanguageType } from '@models/language.model';
import { AuthService } from '@services/auth/auth.service';

@Component({
  selector: 'app-dates-links-item',
  templateUrl: './dates-links-item.component.html',
  styleUrls: ['./dates-links-item.component.css']
})
export class DatesLinksItemComponent implements OnInit, OnDestroy, OnChanges {

  @Input() uid: UID;
  @Input() links: LinksClass;
  @Input() searchLabel: string;
  @Output() sendSelected: EventEmitter<UID>;
  @Output() sendLinkChange: EventEmitter<{ from: DateClass, to: DateClass }>;

  color: string = ItemColor('dates');
  icon: string = ItemIcon('dates');

  isChanged: boolean;
  exclude: UID[] = [];

  lastModification$sub: Subscription;
  partial$sub: Subscription;
  date: DateClass;
  display: boolean;
  language: LanguageType;
  language$sub: Subscription;

  constructor(
    private $dates: DateService,
    private $auth: AuthService
  ) { 
    this.isChanged = false;
    this.sendSelected = new EventEmitter<UID>();
    this.sendLinkChange = new EventEmitter<{ from: DateClass, to: DateClass }>();
    this.display = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.searchLabel != undefined && !changes.searchLabel.firstChange) {
      this.setDisplay();
    }
  }
  
  ngOnInit() {
    this.partial$sub = this.$dates.getPartial$(this.uid).subscribe({
      next: (date: DateClass) => {
 
        if(!!date) {
          this.date = date;
          this.setDisplay();
        }
        else {
          if(this.links.isNewItem('dates', this.uid)) {
            this.date = this.links.newItems('dates').filter(p => p['uid'].value === this.uid)[0] as DateClass;
          }
          else {
            this.date = this.$dates.create({ uid: this.uid });
          }
        }
      }
    });

    this.exclude = this.links.list('dates').slice();

    this.lastModification$sub = this.links.lastModification$.subscribe((attribute: string) => {
      if(attribute === 'links') this.exclude = this.links.list('dates').slice();
    });

    this.language$sub = this.$auth.language$.subscribe({
      next: (language: LanguageType) => {
        this.language = language;
      }
    });
  }

  setDisplay() {
    this.display = this.searchLabel == undefined || this.date.labels.value(this.language).toLowerCase().includes(this.searchLabel.toLowerCase());
  }

  ngOnDestroy() {
    this.partial$sub.unsubscribe();
  }

  receiveSelected() {
    this.sendSelected.emit(this.date.uid.value);
  }

  receiveLinkChange(date: DateClass) {
    if(!date.uid.isRegistered) {
      date.change({ labels: this.date.labels.model.labels });
    }
    this.sendLinkChange.emit({ from: this.date, to: date });
    this.date = new DateClass(DeepCopy(date.model), date.uid.isRegistered);
  }

  onChange() {
    this.isChanged = !this.isChanged;
  }

}
