/**
 * @module Chart
 */

import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Label, BaseChartDirective } from 'ng2-charts';
import { DeepMerge } from '@functions/copy.functions';
import { ThemeOptionsClass } from '@class/themeOptions.class';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ThemeStudioService } from '@services/themeStudio/theme-studio.service';
import { ChartType } from '@models/chart.model';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.css']
})
export class LineChartComponent implements OnInit, OnDestroy {

  public type: ChartType = 'line';

  @Input() options: ThemeOptionsClass;
  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

  public labels: Label[] = [];
  public data: ChartDataSets[] = [];
  public datasets: ChartDataSets[] = [];
  public mergedOptions: ChartOptions;
  public localOptions: ChartOptions = {
    title: {
      text: '',
    }
  };
  public plugins = [];

  changedOptions$sub: Subscription;
  changedSeries$sub: Subscription;
  data$sub: Subscription;

  constructor(
    private $translate: TranslateService,
    private $themeStudio: ThemeStudioService
  ) {
    this.localOptions.title.text = `${this.$translate.instant('TITLE')} - ${this.$translate.instant(this.type.toUpperCase() + '/CHART')}`;
  }

  ngOnDestroy() {
    this.changedOptions$sub.unsubscribe();
    this.changedSeries$sub.unsubscribe();
    this.data$sub.unsubscribe();
  }

  ngOnInit() {
    this.data$sub = this.$themeStudio.data$.subscribe({
      next:(data) => {
        if(!!data) {
          this.data = data.series;
          this.labels = data.labels;
      
          this.datasets = new Array(this.data.length).fill({});
          this.data.forEach((dst: ChartDataSets, index: number) => {
            this.datasets[index] = DeepMerge(dst, this.options.series(index, this.type));
          });
        }
      }
    });

    this.mergedOptions = DeepMerge(this.options.options(this.type), this.localOptions);

    this.changedOptions$sub = this.options.changedOptions$.subscribe({
      next:() => {
        this.mergedOptions = DeepMerge(this.options.options(this.type), this.localOptions);
      }
    });

    this.changedSeries$sub = this.options.changedSeries$.subscribe({
      next:() => {
        this.datasets = new Array(this.data.length).fill({});
        this.data.forEach((dst: ChartDataSets, index: number) => {
          this.datasets[index] = DeepMerge(dst, this.options.series(index, this.type));
        });
      }
    });
  }

}