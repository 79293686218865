/**
 * @module From
 */

import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-check',
  templateUrl: './check.component.html',
  styleUrls: ['./check.component.css']
})
export class CheckComponent {

  @Input() value: boolean;
  @Input() color: string;
  @Output() sendValue: EventEmitter<boolean>;

  constructor() {
    this.sendValue = new EventEmitter<boolean>();
  }

  onValue(value: boolean) {
    this.sendValue.emit(value);
  }

}
