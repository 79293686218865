/**
 * @module Import
 */

import { Component, Output, EventEmitter, Input, ViewChild, OnDestroy } from '@angular/core';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { ItemColor, ItemIcon, ItemsIcons, ItemsColors } from '@functions/item.functions';
import { UID } from '@models/uid.model';
import { DataFrameItemType, DataFrameType } from '@models/dataFrame.model';
import { AuthService } from '@services/auth/auth.service';
import { ServerUrlModel } from '@models/server.model';
import { Subscription } from 'rxjs';
import { ServerLinkClass } from '@class/server.class';
import { ModificationStateClass } from '@class/modification.class';
import { ImportDataFrameModel, ImportSeriesModel } from '@models/worker.model';

@Component({
  selector: 'app-import-scope',
  templateUrl: './import-scope.component.html',
  styleUrls: ['./import-scope.component.css']
})
export class ImportScopeComponent implements OnDestroy {

  @Input() dataFrames: { [key: string]: ImportDataFrameModel };
  @Output() sendDataFrames: EventEmitter<{ dataFrames: { [key: string]: ImportDataFrameModel }, server: ServerLinkClass }>;
  @ViewChild('scopeModal', { static: true }) scopeModal: ModalDirective;

  color = ItemColor('indicators');
  icon = ItemIcon('indicators');
  icons = ItemsIcons();
  colors = ItemsColors(true);
  editorIndex: number;
  editorIndexMax: number;
  server: ServerLinkClass;
  server$sub: Subscription;

  constructor(
    private $auth: AuthService,
  ) {
    this.sendDataFrames = new EventEmitter<{ dataFrames: { [key: string]: ImportDataFrameModel }, server: ServerLinkClass }>();
    this.editorIndex = 0;
    this.editorIndexMax = 0;
    this.server$sub = this.$auth.server$.subscribe({
      next: (server: ServerUrlModel) => {
        this.server = new ServerLinkClass({ serverLink: server }, new ModificationStateClass());
      }
    });
  }

  public show() {
    this.editorIndexMax = Object.keys(this.dataFrames || []).length - 1; //TOKNOW : le '-1' est présent car on masque temporairement la notion de serveur dans la V3
    this.scopeModal.show();
  }

  ngOnDestroy() {
    this.server$sub.unsubscribe();
  }

  onImport(isImported: boolean, dataFrame: string) {
    this.dataFrames[dataFrame].isImported = isImported;
  }

  receiveNext(isNext: boolean) {
    this.editorIndex = this.editorIndex + (isNext ? 1 : -1);
    if(this.editorIndex < 0) this.editorIndex = this.editorIndexMax;
    if(this.editorIndex > this.editorIndexMax) this.editorIndex = 0;
  }  

  receiveSelected(it: { uid: UID, selected: boolean }, dataFrame: string, item: DataFrameItemType, type: DataFrameType) {
    if(item !== undefined) {
      this.dataFrames[dataFrame].series[it.uid].selected = { item: item, type: type }
    }
    else {
      this.dataFrames[dataFrame].series[it.uid].isImported = !this.dataFrames[dataFrame].series[it.uid].isImported;
    }
    this.dataFrames[dataFrame].countIndicators = Object.values(this.dataFrames[dataFrame].series).filter((value: ImportSeriesModel) => {
      return value.isImported && value.selected.item === 'indicators';
    }).length;
  }

  onSelect() {
    this.scopeModal.hide();
    setTimeout(() => {
      let selection = {} as { [key: string]: ImportDataFrameModel };
      for(const dataFrame in this.dataFrames) {
        if(this.dataFrames[dataFrame].isImported && this.dataFrames[dataFrame].countIndicators > 0) {
          selection[dataFrame] = {
            isImported: true,
            countIndicators: this.dataFrames[dataFrame].countIndicators,
            series: {}
          } as ImportDataFrameModel;
          for(const serie in this.dataFrames[dataFrame].series) {
            if(this.dataFrames[dataFrame].series[serie].isImported) {
              selection[dataFrame].series[serie] = this.dataFrames[dataFrame].series[serie];
            }
          }
        }
      }
      this.sendDataFrames.emit({ dataFrames: selection, server: this.server });
    }, 250);
  }

}
