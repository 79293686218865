<div class="d-flex position-relative">
  <div class="flex-grow-1">
    <ng-template app-theme-options-host></ng-template>
  </div>
  <div class="d-flex blue-grey lighten-5 p-2 mb-1 ml-1 align-items-center" *ngIf="duplicable || deletable">
    <button *ngIf="duplicable" (click)="onDuplicate()" mdbBtn type="button" class="px-2 m-0 z-depth-0" color="default" size="sm" mdbWavesEffect><i class="fas fa-paint-roller fa-fw animated rotateIn"></i></button>
    <button *ngIf="deletable" (click)="onDelete()" mdbBtn type="button" class="px-2 m-0 z-depth-0" color="warning" size="sm" mdbWavesEffect><i class="fas fa-trash fa-fw animated rotateIn"></i></button>
  </div>
  <div class="d-flex position-absolute">
    <mdb-badge *ngIf="type" [color]="color" class="app-widget-layers-theme-option-badge-label z-depth-0 font-weight-light font-extra-small app-opacity-75">{{ type | translate }}</mdb-badge>
    <mdb-badge *ngIf="type" [color]="color" class="app-widget-layers-theme-option-badge-index z-depth-0 font-weight-light font-extra-small">{{ definedOption.axesXY }}{{ definedOption.index }}</mdb-badge>
  </div>
</div>