<div mdbModal #infoModal="mdbModal" class="modal fade" style="overflow-y: scroll" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header d-flex align-items-center flex-column">

        <i class="animated rotateIn {{iconColor ? '' : color}}-text {{icon}} fa-4x d-none d-sm-inline" [ngStyle]="{ 'color': iconColor }"></i>
        <i class="animated rotateIn {{iconColor ? '' : color}}-text {{icon}} fa-2x d-sm-none" [ngStyle]="{ 'color': iconColor }"></i>

        <h6 class="modal-title w-100 text-center">
          <div class="font-weight-bold text-elegant mt-1">&nbsp;{{title}}&nbsp;</div>
        </h6>

      </div>
      <div class="modal-header">

        <button type="button" size="sm" mdbBtn color="secondary" class="waves-light z-depth-0 px-2 m-0 app-close-modal" (click)="onClose()" mdbWavesEffect>
          <i class="fas fa-times fa-fw"></i>
        </button>
        
      </div>
      <div class="modal-body overflow-hidden">
        
        <div *ngIf="descriptions.value(language).length > 0" class="rounded blue-grey lighten-5 p-3 animated fadeIn blue-grey-text">
          {{ descriptions.value(language) }}
        </div>

        <app-tags-icons-list *ngIf="tags.list.length > 0" class="d-block mt-3" [tags]="tags" [limit]="14"></app-tags-icons-list>

      </div>
    </div>
  </div>
</div>