/**
 * @module Browser
 */

import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { UserClass } from '@class/user.class';
import { Subscription } from 'rxjs';
import { AuthService } from '@services/auth/auth.service';
import { Router } from '@angular/router';
import { ItemType } from '@models/item.model';
import { ItemsColors, ItemsRoles, ItemsLabels, ItemsSymbols, ItemsPanel } from '@functions/item.functions';

@Component({
  selector: 'app-browser-navbar-panels',
  templateUrl: './browser-navbar-panels.component.html',
  styleUrls: ['./browser-navbar-panels.component.css']
})
export class BrowserNavbarPanelsComponent implements OnInit, OnDestroy {
  
  @Output() sendPanel = new EventEmitter<ItemType>();

  current: ItemType;
  panels = ItemsPanel();
  roles = ItemsRoles();
  icons = ItemsSymbols();
  colors = ItemsColors();
  labels = ItemsLabels();

  user: UserClass;
  resources$sub: Subscription;

  constructor(
    private $auth: AuthService,
    private $router: Router
  ) {
    this.resources$sub = this.$auth.resources$.subscribe({
      next: (user: UserClass) => {
        if(!!user) {
          this.user = user;
          if(!this.user.roles.has(this.roles[this.current])) {
            this.current = 'dashboards';
            this.sendPanel.emit(this.current);
          }
        }
      }
    });
  }

  ngOnInit() {
    const panel = this.$router.parseUrl(this.$router.url).queryParams.panel;
    this.current = panel !== undefined ? panel : 'dashboards';
    this.$router.navigate(['/browser'], { queryParams: { panel: this.current } });
    this.sendPanel.emit(this.current);
  }

  ngOnDestroy() {
    this.resources$sub.unsubscribe();
  }

  onPanel(panel: ItemType) {
    this.current = panel;
    this.$router.navigate(['/browser'], { queryParams: { panel: this.current } });
    this.sendPanel.emit(panel);
  }
}
