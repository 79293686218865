/**
 * @module Dashboard
 */

import { DashboardLayoutModel, DashboardLayoutCellModel } from '@models/dashboardLayout.model';
import { UidModel, UID } from '@models/uid.model';
import { ModificationClass, ModificationStateClass } from '@class/modification.class';
import { DeepCopy } from '@functions/copy.functions';
import { UidClass } from '@class/uid.class';
import { DashboardLayoutCellClass } from '@class/dashboardLayoutCell.class';
import { Subscription } from 'rxjs';
import { CellDefault } from '@functions/dashboard.functions';
import { OnDestroy } from '@angular/core';

export class DashboardLayoutClass extends ModificationClass<DashboardLayoutModel> implements OnDestroy {

  private _uid: UidClass;
  private _cells: DashboardLayoutCellClass[];
  private _modifications$sub: Subscription[];
  private _lastModification$sub: Subscription;
  private _maxColumns: number;
  private _width: number;

  protected _parent: DashboardLayoutModel;
  protected _inital: DashboardLayoutModel;
  protected _attributes: string[];

  constructor(dashboardLayout: DashboardLayoutModel, width?: number) {

    super();

    this._parent = dashboardLayout;
    this._width = width;

    this._reset(this._parent);

    this._attributes = this.attributes.slice();
    this._attributes.push(...this._uid.attributes);

  }

  private _reset(dashboardLayout: DashboardLayoutModel, width?: number) {
    this._uid = new UidClass(dashboardLayout as UidModel, true);

    this.attributes.forEach((attribute: string) => {
      this._parent[attribute] = dashboardLayout[attribute];
    });

    this._inital = DeepCopy(dashboardLayout);

    if(this._modifications$sub !== undefined && this._modifications$sub.length > 0) this._modifications$sub.forEach((sub: Subscription) => sub.unsubscribe());
    this._modifications$sub = [];

    if(dashboardLayout.cells === undefined) dashboardLayout.cells = [];
    this._cells = [];
    dashboardLayout.cells.forEach((cell: DashboardLayoutCellModel, index: number) => {
      this._cells.push(new DashboardLayoutCellClass(cell, this._state));
      this._modifications$sub.push(this._cells[index].modifications$.subscribe({ next: m => this._emit(m) }));
    });

    this._lastModification$sub = this._lastModification$.subscribe((attribute: string) => {
      if(['padding', 'size', 'gap', 'columns', 'rows'].includes(attribute)) {
        this.setWidth(this._width);
      }
    });

    this._width = width;
    this.setCellsSizes();

    this._state.flush();
    this._modifications$.next(this._state);
  }

  reset(dashboardLayout?: DashboardLayoutModel, width?: number) {
    this._state.flush();
    this._reset(dashboardLayout !== undefined ? dashboardLayout : this._inital, width);
  }

  ngOnDestroy() {
    this._lastModification$sub.unsubscribe();
    if(this._modifications$sub !== undefined && this._modifications$sub.length > 0) this._modifications$sub.forEach((sub: Subscription) => sub.unsubscribe());
  }

  private _emit(modification: ModificationStateClass) {
    if(modification.isModified) this.emit(modification.list as string[]);
  }

  get uid(): UidClass {
    return this._uid;
  }

  get attributes(): Readonly<string[]> {
    return ['cells', 'depth', 'padding', 'size', 'gap', 'columns', 'color'];
  }

  get class(): Readonly<string> {
    return 'dashboardLayout';
  }

  get cells(): Readonly<DashboardLayoutCellClass[]> {
    return this._cells;
  }

  get depth(): Readonly<number> {
    return this._parent.depth;
  }

  get padding(): Readonly<number> {
    return this._parent.padding;
  }

  get size(): Readonly<number> {
    return this._parent.size;
  }

  get gap(): Readonly<number> {
    return this._parent.gap;
  }

  get columns(): Readonly<number> {
    return this._parent.columns;
  }

  get color(): Readonly<string> {
    return this._parent.color;
  }

  get colorRGBhexa(): Readonly<string> {
    return this._parent.color.slice(1, 7);
  }

  addCell() {
    const length = this._parent.cells.push(CellDefault(this._parent.cells.length));
    let c = new DashboardLayoutCellClass(this._parent.cells[length - 1], this._state);
    this.setCellSizes(c);
    this._modifications$sub.push(c.modifications$.subscribe({ next: m => this._emit(m) }));
    this._cells.push(c);
    this.emit(['cells']);
  }

  removeCell(uid: UID) {
    const index = this._parent.cells.map(c => c.uid).indexOf(uid);
    if(index !== -1) {
      this._parent.cells.splice(index, 1);
      this._cells.splice(index, 1);
      this.emit(['cells']);
    }
  }

  rankCell(from: number, to: number) {
    this._parent.cells.splice(to, 0, this._parent.cells.splice(from, 1)[0]);
    this._cells.splice(to, 0, this._cells.splice(from, 1)[0]);
    this.emit(['cells']);
  }

  get maxColumns(): Readonly<number> {
    return this._maxColumns;
  }

  get width(): Readonly<number> {
    return this._width;
  }

  get widthPixel(): Readonly<string> {
    return `${this._width}px`;
  }

  setWidth(width: number) {
    this._width = width;
    this._maxColumns = Math.min(this._parent.columns, Math.floor((this._width - this._parent.padding * 2 + this._parent.gap) / (this._parent.size + this._parent.gap)));
    this.setCellsSizes();
  }

  private setCellsSizes() {
    this._cells.forEach((cell: DashboardLayoutCellClass) => {
      this.setCellSizes(cell);
    });
  }

  private setCellSizes(cell: DashboardLayoutCellClass) {
    const columns = Math.min(cell.columns, this._maxColumns);
    cell.setSizes(
      cell.rows * this._parent.size + (cell.rows - 1) * this._parent.gap,
      columns * this._parent.size + (columns - 1) * this._parent.gap,
      Math.min(1, this._maxColumns / cell.columns)
    );
  }

}