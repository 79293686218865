/**
 * @module Label
 */

import { LabelsModel } from '@models/labels.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RequirementsType } from '@models/requirement.model';
import { LanguagesTypes } from '@functions/language.functions';
import { LanguageType } from '@models/language.model';
import { ModificationClass, ModificationStateClass } from '@class/modification.class';
import { DeepCopy } from '@functions/copy.functions';
import { SetLabels } from '@functions/labels.functions';

export class LabelsClass extends ModificationClass<LabelsModel> {

  protected _parent: LabelsModel;
  protected _inital: LabelsModel;
  protected _attributes: string[];
  protected _attributeLabel: string; //par défaut l'attribut est 'labels'. A changer si plusieurs 'labels' dans un même objet

  constructor(labels: LabelsModel, form: FormGroup, requirements: RequirementsType, state: ModificationStateClass, attributeLabel?: string) {
    
    super();

    this._parent = labels;
    this._requirements = requirements;
    this._attributes = this.attributes;
    this._form = form;
    this._state = state;
    this._attributeLabel = attributeLabel;

    if(this._parent.labels === undefined) {  
      this._parent.labels = SetLabels();
    }

    Object.assign(requirements, {
      [attributeLabel !== undefined ? attributeLabel : 'labels']: {
        maxLength: 75,
        minLength: 3
      }
    });

    LanguagesTypes().forEach((language: LanguageType) => {
      form.addControl(`${this.class}@${language}`, new FormControl(this._parent.labels[language], [ 
        Validators.required, 
        Validators.maxLength(requirements[this.class].maxLength), 
        Validators.minLength(requirements[this.class].minLength) 
      ]));
    });

    //validation: si au moins 1 des labels est valide on valide les autres dans le formulaire
    let values = {} as LabelsModel;
    const isValid = Object.keys(this._parent.labels).filter((language: LanguageType) => {
      values[language] = this._parent.labels[language];
      return this._parent.labels[language].length > 0;
    }).length > 0;

    if(isValid) {
      this.change({ labels: values } as unknown as LabelsModel, false);
    }
    //.validation

    this._inital = DeepCopy({ labels: this._parent.labels });

  }

  get attributeLabel(): string {
    return this._attributeLabel === undefined ? 'labels' : this._attributeLabel;
  }

  get attributes(): string[] {
    return [this._attributeLabel === undefined ? 'labels' : this._attributeLabel];
  }

  get class(): string {
    return this._attributeLabel === undefined ? 'labels' : this._attributeLabel;
  }

  value(language: LanguageType): Readonly<string> {
    let lg = language;
    if(this._parent.labels[language] === undefined || this._parent.labels[language].length === 0) {
      for(const l in this._parent.labels) {
        if(this._parent.labels[l] !== undefined && this._parent.labels[l].length > 0) {
          lg = l as LanguageType; 
          break;
        }
      }
    }
    return /*(lg !== language ? `[${lg.toUpperCase()}] ` : '') +*/ this._parent.labels[lg]; // suppression du prefix de la langue par défaut
  }

}