/**
 * @module Dashboard
 */

import { DashboardModel } from '@models/dashboard.model';
import { DescriptionsModel } from '@models/descriptions.model';
import { LabelsModel } from '@models/labels.model';
import { UidModel } from '@models/uid.model';
import { IconModel } from '@models/icon.model';
import { ColorModel } from '@models/color.model';
import { LinksModel } from '@models/links.model';
import { LogsModel } from '@models/logs.model';
import { ModificationClass } from '@class/modification.class';
import { UidClass } from '@class/uid.class';
import { LabelsClass } from '@class/labels.class';
import { DescriptionsClass } from '@class/descriptions.class';
import { IconClass } from '@class/icon.class';
import { ColorClass } from '@class/color.class';
import { LinksClass } from '@class/links.class';
import { LogsClass } from '@class/logs.class';
import { ShareModel } from '@models/share.model';
import { ShareClass } from '@class/share.class';
import { TagsModel } from '@models/tag.model';
import { TagsClass } from '@class/tag.class';
import { DeepCopy } from '@functions/copy.functions';
import { RightsModel } from '@models/right.model';
import { RightsClass } from '@class/right.class';

export class DashboardClass extends ModificationClass<DashboardModel> {
  
  private _uid: UidClass;
  private _labels: LabelsClass;
  private _descriptions: DescriptionsClass;
  private _icon: IconClass;
  private _color: ColorClass;
  private _links: LinksClass;
  private _logs: LogsClass;
  private _share: ShareClass;
  private _tags: TagsClass;
  private _rights: RightsClass;

  protected _parent: DashboardModel;
  protected _inital: DashboardModel;
  protected _attributes: string[];

  private _isRegistered: boolean;

  constructor(dashboard: DashboardModel, isRegistered: boolean = true) {

    super();

    this._parent = dashboard;
    this._isRegistered = isRegistered;

    this._reset(this._parent);

    this._attributes = this.attributes.slice();
    this._attributes.push(...this._labels.attributes);
    this._attributes.push(...this._descriptions.attributes);
    this._attributes.push(...this._uid.attributes);
    this._attributes.push(...this._icon.attributes);
    this._attributes.push(...this._color.attributes);
    this._attributes.push(...this._links.attributes);
    this._attributes.push(...this._logs.attributes);
    this._attributes.push(...this._share.attributes);
    this._attributes.push(...this._tags.attributes);
    this._attributes.push(...this._rights.attributes);

  }

  private _reset(dashboard: DashboardModel) {
    this._uid = new UidClass(dashboard as UidModel, this._isRegistered);
    this._labels = new LabelsClass(dashboard as LabelsModel, this._form, this._requirements, this._state);
    this._descriptions = new DescriptionsClass(dashboard as DescriptionsModel, this._form, this._requirements, this._state);
    this._icon = new IconClass(dashboard as IconModel, this._state);
    this._color = new ColorClass(dashboard as ColorModel, this._state);
    this._links = new LinksClass(dashboard as LinksModel, this._state);
    this._logs = new LogsClass(dashboard as LogsModel, this._state);
    this._share = new ShareClass(dashboard as ShareModel, this._state);
    this._tags = new TagsClass(dashboard as TagsModel, this._state);
    this._rights = new RightsClass(dashboard as RightsModel, this._state);

    this.attributes.forEach((attribute: string) => {
      this._parent[attribute] = dashboard[attribute];
    });

    this._inital = DeepCopy(dashboard);

    this._state.flush();
    this._modifications$.next(this._state);
  }

  reset() {
    this._state.flush();
    this._reset(this._inital);
  }

  get attributes(): Readonly<string[]> {
    return [];
  }

  get class(): string {
    return 'dashboard';
  }

  get uid(): UidClass {
    return this._uid;
  }

  get labels(): LabelsClass {
    return this._labels;
  }

  get descriptions(): DescriptionsClass {
    return this._descriptions;
  }

  get icon(): IconClass {
    return this._icon;
  }

  get color(): ColorClass {
    return this._color;
  }

  get links(): LinksClass {
    return this._links;
  }

  get logs(): LogsClass {
    return this._logs;
  }

  get share(): ShareClass {
    return this._share;
  }

  get tags(): TagsClass {
    return this._tags;
  }

  get rights(): RightsClass {
    return this._rights;
  }

}