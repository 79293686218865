<app-form-strip>
  <div app-form-strip-content-left>
    <div class="d-none rounded-circle mr-3 d-sm-block app-avatar-icon">
      <img class="rounded-circle img-fluid" [src]="url">
    </div>
  </div>

  <div app-form-strip-content-middle>
    {{ 'AVATAR' | translate }}
  </div>

  <div app-form-strip-content-right>
    <button (click)="onReset()" mdbBtn size="sm" type="button" color="blue-grey" class="z-depth-0 px-3 my-0" mdbWavesEffect>{{ 'RESET' | translate }}</button>
    <button (click)="onChange()" mdbBtn size="sm" type="button" color="blue-grey" class="z-depth-0 px-3 m-0" mdbWavesEffect>{{ 'NEW' | translate }}</button>
  </div>
</app-form-strip>

<input id="file" accept="image/*" 
  style="position: fixed; top: -100px; opacity:0;" type="file" (change)="onFile($event.target.files[0])" 
  onclick="this.value=null;" mdbFileSelect [options] ="{ concurrency: 1, maxUploads: 1 }">