/**
 * @module Shared
 */

import { Inject, OnDestroy } from '@angular/core';
import { QueryFn, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable, BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { ENVIRONMENT } from '@consts/params.const';
import { tap, retry, delay, catchError, map, retryWhen } from 'rxjs/operators';
import { FirebaseService } from '@services/firebase/firebase.service';
import { UserClass } from '@class/user.class';
import { AuthService } from '@services/auth/auth.service';
import { UID } from '@models/uid.model';
import { ItemType } from '@models/item.model';
import { ResourceType } from '@models/resources.model';

export abstract class FirestoreService<T> implements OnDestroy {

  /**
   * @description type de document manipulé par le service
   * @protected
   * @abstract
   * @type {ItemType}
   * @memberof FirestoreService
   */
  protected abstract _itemType: ItemType;
  
  /**
   * @description true si l'accès à ce type de document peut nécessiter des habilitations
   * @protected
   * @abstract
   * @type {boolean}
   * @memberof FirestoreService
   */
  protected abstract _restricted: boolean;

  /**
   *
   * @description Observateur de la liste de documents autorisés pour l'utilisateur courant
   * @private
   * @type {BehaviorSubject<T[]>}
   * @memberof FirestoreService
   */
  private _docs$: BehaviorSubject<T[]>;

  /**
   * @description Observateur de la liste de documents (données partielles) autorisés pour l'utilisateur courant
   * @private
   * @type {BehaviorSubject<T[]>}
   * @memberof FirestoreService
   */
  private _partials$: BehaviorSubject<T[]>;

  /**
   * @description Observateur de la liste complète des documents (non fonctionnel en cas d'accès restreint)
   * @private
   * @type {BehaviorSubject<T[]>}
   * @memberof FirestoreService
   */
  private _full$: BehaviorSubject<T[]>;

  /**
   * @description Observateur de la liste complète des documents (données partielles, non fonctionnel en cas d'accès restreint)
   * @private
   * @type {BehaviorSubject<T[]>}
   * @memberof FirestoreService
   */
  private _fullPartial$: BehaviorSubject<T[]>;

  /**
   * @description Souscription aux changements de ressources de l'utilisateur courant
   * @private
   * @type {Subscription}
   * @memberof FirestoreService
   */
  private _resources$sub: Subscription;

  /**
   * @description Souscription aux documents autorisés de l'utilisateur courant
   * @private
   * @type {Subscription}
   * @memberof FirestoreService
   */
  private _docs$sub: Subscription;

  /**
   * @description Souscription aux documents (données partielles) autorisés de l'utilisateur courant
   * @private
   * @type {Subscription}
   * @memberof FirestoreService
   */
  private _partials$sub: Subscription;

  /**
   * @description Souscription à la totalité des documents
   * @private
   * @type {Subscription}
   * @memberof FirestoreService
   */
  private _full$sub: Subscription;

  /**
   * @description Souscription à la totalité des documents (données partielles)
   * @private
   * @type {Subscription}
   * @memberof FirestoreService
   */
  private _fullPartial$sub: Subscription;

  /**
   * @description Creates an instance of FirestoreService
   * @param {FirebaseService} $firebase
   * @memberof FirestoreService
   */
  constructor(
    @Inject(FirebaseService) protected $firebase: FirebaseService,
    protected $auth: AuthService
  ) {}

  /**
   * @description Destructeur du service
   * @memberof FirestoreService
   */
  ngOnDestroy() {
    if(this._resources$sub) this._resources$sub.unsubscribe();
    if(this._docs$sub) this._docs$sub.unsubscribe();
    if(this._partials$sub) this._partials$sub.unsubscribe();
    if(this._full$sub) this._full$sub.unsubscribe();
    if(this._fullPartial$sub) this._fullPartial$sub.unsubscribe();
  }

  /**
   * @description Renvoi un observateur du document dont l'identifiant est passé en paramètre
   * @param {string} uid
   * @returns {Observable<T>}
   * @memberof FirestoreService
   */
  protected doc$(uid: string): Observable<T> {
    return this.$firebase.angularFirestore.doc<T>(`${this._itemType}/${uid}`).valueChanges().pipe(
      tap(doc => {
        if(ENVIRONMENT.type !== 'production') {
          console.groupCollapsed(`Firestore Streaming [${this._itemType}] [doc$] ${uid}`);
          console.log(doc);
          console.groupEnd();
        }
      }),
      catchError(() => {
        console.log(uid)
        return new Observable<T>(undefined);
      })
    );
  }

  protected docSub$(uid: string, subCollection: string): Observable<any> {
    return this.$firebase.angularFirestore.doc<T>(`${this._itemType}/${uid}`).collection(subCollection).doc(uid).valueChanges().pipe(
      tap(doc => {
        if(ENVIRONMENT.type !== 'production') {
          console.groupCollapsed(`Firestore Streaming [${this._itemType}] for sub [${subCollection}] [doc$] ${uid}`);
          console.log(doc);
          console.groupEnd();
        }
      }),
      catchError(() => {
        return new Observable<T>(undefined);
      })
    );
  }

  /**
   * @description Renvoi un observateur sur tous les documents de la collection correspondants à la requête passée en paramètre
   * @param {QueryFn} [queryFn]
   * @returns {Observable<T[]>}
   * @memberof FirestoreService
   */
  protected docsCollection$(queryFn?: QueryFn): Observable<T[]> {
    return this.$firebase.angularFirestore.collection<T>(`${this._itemType}`, queryFn).valueChanges().pipe(
      map((docs: T[]) => {
        return docs.filter((doc: T) => doc['deleted'] === undefined);
      }),
      tap((docs: T[]) => {
        if(ENVIRONMENT.type !== 'production') {
          console.groupCollapsed(`Firestore Streaming [${this._itemType}] [collection$]`);
          console.log(docs);
          console.groupEnd();
        }
      })
    );
  }

  /**
   * @description Initialise l'observateur des documents autorisés pour l'utilisateur courant en cas d'absence de restrictions
   * @private
   * @memberof FirestoreService
   */
  private setDocsCollection() {
    this._docs$ = new BehaviorSubject<T[]>([]);
    if(this._docs$sub) this._docs$sub.unsubscribe();
    this._docs$sub = this.docsCollection$().subscribe({
      next: (collection: T[]) => {
        this._docs$.next(collection);
      }
    });
  }

  /**
   * @description Renvoi l'observateur vers les documents autorisés pour l'utilisateur courant
   * @protected
   * @returns {BehaviorSubject<T[]>}
   * @memberof FirestoreService
   */
  protected docs$(): BehaviorSubject<T[]> {
    if(this._docs$ === undefined) {
      if(this._restricted) this.setDocs();
      else this.setDocsCollection();
    }
    return this._docs$;
  }

  /**
   * @description Initialise l'observateur de tous les documents (non fonctionnel en cas d'accès restreint)
   * @private
   * @memberof FirestoreService
   */
  private setFullDocsCollection() {
    this._full$ = new BehaviorSubject<T[]>([]);
    if(this._full$sub) this._full$sub.unsubscribe();
    this._full$sub = this.docsCollection$().subscribe({
      next: (collection: T[]) => {
        this._full$.next(collection);
      }
    });
  }

  /**
   * @description Renvoi l'observateur vers tous les documents (non fonctionnel en cas d'accès restreint)
   * @protected
   * @returns {BehaviorSubject<T[]>}
   * @memberof FirestoreService
   */
  protected full$(): BehaviorSubject<T[]> {
    if(this._full$ === undefined) {
      this.setFullDocsCollection();
    }
    return this._full$;
  }

  /**
   * @description met à jour un document dans la collection ou le créé s'il n'existe pas
   * @protected
   * @param {string} uid
   * @param {T} value
   * @param {boolean} [merge=true]
   * @returns {Promise<void>}
   * @memberof FirestoreService
   */
  protected async update(uid: string, value: T, merge: boolean = true): Promise<void> {
    return this.collection.doc(uid).set(Object.assign(value, { uidLog: this.$auth.user$.value.uid.value }), { merge: merge }).then(() => {
      if(ENVIRONMENT.type !== 'production') {
        console.groupCollapsed(`Firestore update [${this._itemType}] [success] ${uid}`);
        console.log(value);
        console.groupEnd();
      }
    }).catch(error => {
      if(ENVIRONMENT.type !== 'production') {
        console.groupCollapsed(`Firestore update [${this._itemType}] [error] ${uid}`);
        console.log(error);
        console.groupEnd();
      }
    });
  }

  /**
   * @description met à jour un document dans la sous-collection ou le créé s'il n'existe pas
   * @protected
   * @param {string} uid
   * @param {*} value
   * @param {string} subCollection
   * @param {boolean} [merge=true]
   * @returns {Promise<void>}
   * @memberof FirestoreService
   */
  protected async updateSub(uid: string, value: any, subCollection: string, merge: boolean = true): Promise<void> {
    return this.collection.doc(uid).collection(subCollection).doc(uid).set(Object.assign(value, { uidLog: this.$auth.user$.value.uid.value }), { merge: merge }).then(() => {
      if(ENVIRONMENT.type !== 'production') {
        console.groupCollapsed(`Firestore update [${this._itemType}] for sub [${subCollection}] [success] ${uid}`);
        console.log(value);
        console.groupEnd();
      }
    }).catch(error => {
      if(ENVIRONMENT.type !== 'production') {
        console.groupCollapsed(`Firestore update [${this._itemType}] for sub [${subCollection}] [error] ${uid}`);
        console.log(error);
        console.groupEnd();
      }
    });
  }

  /**
   * @description supprime le document de la collection
   * @param {string} uid
   * @returns
   * @memberof FirestoreService
   */
  protected async delete(uid: string): Promise<void> {
    return this.collection.doc(uid).update({ 
      uidLog: this.$auth.user$.value.uid.value,
      deleted: true
    }).then(() => {
      if (ENVIRONMENT.type !== 'production') {
        console.log(`Firestore delete [${this._itemType}] [success] ${uid}`);
      }
    }).catch(error => {
      if (ENVIRONMENT.type !== 'production') {
        console.groupCollapsed(`Firestore delete [${this._itemType}] [error] ${uid}`);
        console.log(error);
        console.groupEnd();
      }
    });
  }

  /**
   * @description Renvoi l'objet collection de Firestore pour la collection
   * @readonly
   * @private
   * @memberof FirestoreService
   */
  private get collection(): AngularFirestoreCollection<T> {
    return this.$firebase.angularFirestore.collection<T>(`${this._itemType}`);
  }

  /**
   * @description Initialise l'observateur vers les documents autorisés pour l'utilisateur courant
   * @private
   * @memberof FirestoreService
   */
  private setDocs() {
    this._docs$ = new BehaviorSubject<T[]>(undefined);

    this._resources$sub = this.$auth.resources$.subscribe({
      next: (user: UserClass) => {
        
        if(!!user) {
          if(this._docs$) this._docs$.next(undefined);

          if(this._docs$sub) this._docs$sub.unsubscribe();

          let docs$: Observable<T>[];
          if(this._itemType === 'rights') {
            docs$ = user.groups.list.map((uid: UID) => {
              return this.doc$(uid).pipe(
                retry(3),
                delay(500)
              );
            });
          }
          else {
            docs$ = user.resources.list(this._itemType as ResourceType).map((uid: UID) => {
              return this.doc$(uid).pipe(
                retry(3),
                delay(500)
              );
            });
          }

          if(docs$.length > 0) {
            this._docs$sub = combineLatest<T[]>(docs$)
              .pipe(
              tap((docs: T[]) => {
                //suppression des 'undefined' pour les cas de suppression avec droits pour lesquels
                //la cloud function de gestion des liens n'a pas encore été executée
                this._docs$.next(docs.filter((item: T) => { return item !== undefined && item['deleted'] === undefined; }));
              })
            ).subscribe();
          }
          else {
            this._docs$.next([]);
          }
        }
        else {
          if(this._docs$sub) this._docs$sub.unsubscribe();
        }
      }
    });
  }

  /**
   * @description Renvoi un observateur sur tous les documents (données partielles) de la collection correspondants à la requête passée en paramètre
   * @protected
   * @param {QueryFn} [queryFn]
   * @returns {Observable<T[]>}
   * @memberof FirestoreService
   */
  protected partialsCollection$(queryFn?: QueryFn): Observable<T[]> {
    return this.$firebase.angularFirebase.object<T[]>(`docs/${this._itemType}`).valueChanges().pipe(
      tap((partials: T[]) => {
        if(ENVIRONMENT.type !== 'production') {
          console.groupCollapsed(`Firebase Streaming [${this._itemType}] [collection$]`);
          console.log(partials);
          console.groupEnd();
        }
      })
    );
  }

  /**
   * @description Initialise l'observateur des documents (données partielles) autorisés pour l'utilisateur courant en cas d'absence de restrictions
   * @private
   * @memberof FirestoreService
   */
  private setPartialsCollection() {
    this._partials$ = new BehaviorSubject<T[]>([]);
    if(this._partials$sub) this._partials$sub.unsubscribe();
    this._partials$sub = this.partialsCollection$().subscribe({
      next: (collection: T[]) => {
        this._partials$.next(collection);
      }
    });
  }

  /**
   * @description Initialise l'observateur de tous les documents (données partielles, non fonctionnel en cas d'accès restreint)
   * @private
   * @memberof FirestoreService
   */
  private setFullPartialDocsCollection() {
    this._fullPartial$ = new BehaviorSubject<T[]>([]);
    if(this._fullPartial$sub) this._fullPartial$sub.unsubscribe();
    this._fullPartial$sub = this.partialsCollection$().subscribe({
      next: (collection: T[]) => {
        this._fullPartial$.next(collection ? Object.values(collection): []);
      }
    });
  }

  /**
   * @description Renvoi l'observateur vers tous les documents (données partielles, non fonctionnel en cas d'accès restreint)
   * @protected
   * @returns {BehaviorSubject<T[]>}
   * @memberof FirestoreService
   */
  protected fullPartial$(): BehaviorSubject<T[]> {
    if(this._fullPartial$ === undefined) {
      this.setFullPartialDocsCollection();
    }
    return this._fullPartial$;
  }

  /**
   * @description Renvoi l'observateur vers les documents (données partielles) autorisés pour l'utilisateur courant
   * @protected
   * @returns {BehaviorSubject<T[]>}
   * @memberof FirestoreService
   */
  protected partials$(): BehaviorSubject<T[]> {
    if(this._partials$ === undefined) {
      if(this._restricted) this.setPartials();
      else this.setPartialsCollection();
    }
    return this._partials$;
  }

  /**
   * @description Renvoi un observateur du document (données partielles) dont l'identifiant est passé en paramètre
   * @param {string} uid
   * @returns {Observable<T>}
   * @memberof FirestoreService
   */
  protected partial$(uid: string): Observable<T> {
    return this.$firebase.angularFirebase.object<T>(`docs/${this._itemType}/${uid}`).valueChanges();
  }

  /**
   * @description Initialise l'observateur vers les documents (données partielles) autorisés pour l'utilisateur courant
   * @private
   * @memberof FirestoreService
   */
  private setPartials() {
    this._partials$ = new BehaviorSubject<T[]>([]);

    this._resources$sub = this.$auth.resources$.subscribe({
      next: (user: UserClass) => {
        
        if(!!user) {
          if(this._partials$) this._partials$.next(undefined);

          if(this._partials$sub) this._partials$sub.unsubscribe();

          let partials$: Observable<T>[];
          if(this._itemType === 'rights') {
            partials$ = user.groups.list.map((uid: UID) => {
              return this.$firebase.angularFirebase.object<T>(`docs/${this._itemType}/${uid}`).valueChanges().pipe(
                retry(3),
                delay(500)
              );
            });
          }
          else {
            partials$ = user.resources.list(this._itemType as ResourceType).map((uid: UID) => {
              return this.$firebase.angularFirebase.object<T>(`docs/${this._itemType}/${uid}`).valueChanges().pipe(
                retry(3),
                delay(500)
              );
            });
          }

          if(partials$.length > 0) {
            this._partials$sub = combineLatest<T[]>(partials$)
              .pipe(
              tap((partials: T[]) => {
                //suppression des 'undefined' pour les cas de suppression avec droits pour lesquels
                //la cloud function de gestion des liens n'a pas encore été executée
                this._partials$.next(partials.filter((item: T) => { return item !== undefined; }));
              })
            ).subscribe();
          }
          else {
            this._partials$.next([]);
          }
        }
        else {
          if(this._partials$sub) this._partials$sub.unsubscribe();
        }
      }
    });
  }

  linkedColumns(label: string): Observable<{ [key: string]: any }> {
    return this.$firebase.angularFirebase.object<{ [key: string]: any }>(`linkedColumns/${this._itemType}/${label}`).valueChanges().pipe(
      retry(3),
      delay(500)
    );
  }

}