<div class="d-flex align-items-center"> 
  <button mdbBtn color="danger" class="waves-light z-depth-0 px-2" type="button" size="sm" (click)="onRemove()" mdbWavesEffect>
    <i class="fas fa-trash fa-fw"></i>
  </button>
  <div *ngIf="!isEdit" (click)="onEdit()" [ngClass]="{ 'blue-grey-text blue-grey lighten-5': isValid, 'danger-color white-text': !isValid }" class="app-import-detect-value flex-grow-1 p-2 m-1 d-flex align-items-center rounded font-small app-cursor-pointer">
    <div>{{ detect.value }}</div>
    <div class="ml-auto font-weight-bold">{{ detect.frequency | number:'1.1-1':language }}%</div>
  </div>
  <div *ngIf="isEdit" class="flex-grow-1 d-flex align-items-center p-0 my-2 mx-1">
    <div class="app-import-detect-value-input-frame md-form md-outline m-0 w-100">
      <textarea [ngModel]="modified" (ngModelChange)="onValue($event)" id="form-value" class="app-import-detect-value-input form-control textarea form-{{color}} mb-0" size="sm" rows="1" type="text" mdbInput></textarea>
      <label for="form-value">{{ detect.value.substring(0, 34) }}</label>
    </div>
    <i (click)="onRestore()" class="fad fa-lg py-1 pl-2 pr-1 fa-times-square animated fadeInRight text-secondary app-cursor-pointer"></i>
    <i (click)="onValid()" class="fad fa-lg p-1 fa-check-square animated fadeInRight text-default app-cursor-pointer"></i>
  </div>
  <app-links-item-option class="d-block ml-2 my-0 mr-0" [symbol]="'filter'" [uid]="detect.value" [selected]="selected" (sendSelected)="receiveSelected()"></app-links-item-option>
</div>