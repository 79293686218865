/**
 * @module Server
 */

export function CityCountry(city: string): string {
  return CitiesCountry()[city];
}

export function CitiesCountry(): { [key in string]: string } {
  return {
    'Frankfurt 1': 'GERMANY',
    'New York 1': 'USA',
    'New York 2': 'USA',
    'New York 3': 'USA',
    'Amsterdam 1': 'THE_NETHERLANDS',
    'Amsterdam 2': 'THE_NETHERLANDS',
    'Amsterdam 3': 'THE_NETHERLANDS',
    'San Francisco 1': 'USA',
    'San Francisco 2': 'USA',
    'Singapore 1': 'SINGAPORE',
    'London 1': 'UK',
    'Toronto 1': 'CANADA',
    'Bangalore 1': 'INDIA'
  };
} 