/**
 * @module Dashboard
 */

import { Component, Input } from '@angular/core';
import { DashboardLayoutCellClass } from '@class/dashboardLayoutCell.class';
import { ItemColor } from '@functions/item.functions';

@Component({
  selector: 'app-dashboard-cell-columns',
  templateUrl: './dashboard-cell-columns.component.html',
  styleUrls: ['./dashboard-cell-columns.component.css']
})
export class DashboardCellColumnsComponent {

  @Input() cell: DashboardLayoutCellClass;

  color = ItemColor('dashboards');
  
  constructor() {}

  receiveValue(columns: number) {
    this.cell.change({ columns: columns });
  }

}
