<app-theme-option [categories]="themeOptions.optionsConfiguration[optionType].categories" [label]="themeOptions.optionsConfiguration[optionType].label">
    <div app-theme-option-content>
      <app-show-details-option [showDetails]="showDetails" (sendShowDetails)="receiveShowDetails($event)">
        <div app-show-details-option-content>
          <app-number [value]="themeOptions.datalabels(datalabelsIndex).borderWidth" (sendValue)="receiveValue($event)" [color]="color" [min]="0" [max]="20" [step]="2"></app-number>
        </div>
      </app-show-details-option>
    </div>
  </app-theme-option>
  
  <div *ngIf="showDetails">
    <app-theme-option [categories]="themeOptions.optionsConfiguration[optionType].categoriesAndLabel" [label]="type | chart" [dark]="4" *ngFor="let type of themeOptions.limitedTo(optionType)">
      <div app-theme-option-content>
        <app-number [value]="themeOptions.datalabels(datalabelsIndex, type).borderWidth" (sendValue)="receiveValue($event, type)" [color]="color" [min]="0" [max]="20" [step]="2"></app-number>
      </div>
    </app-theme-option>
  </div>