<div class="d-flex flex-column mt-4">
  <div class="d-flex flex-wrap justify-content-around" [ngStyle]="{ 'color': color.hexa }">
    <i class="mb-4 animated fadeIn pb-1 fa-4x fas {{icon.symbol}} fa-lg fa-fw app-icons-icon app-cursor-pointer" (click)="onSelect('s', icon.symbol)"></i>
    <i class="mb-4 animated fadeIn pb-1 fa-4x far {{icon.symbol}} fa-lg fa-fw app-icons-icon app-cursor-pointer" (click)="onSelect('r', icon.symbol)"></i>
    <i class="mb-4 animated fadeIn pb-1 fa-4x fal {{icon.symbol}} fa-lg fa-fw app-icons-icon app-cursor-pointer" (click)="onSelect('l', icon.symbol)"></i>
    <i class="mb-4 animated fadeIn pb-1 fa-4x fad {{icon.symbol}} fa-lg fa-fw app-icons-icon app-cursor-pointer" (click)="onSelect('d', icon.symbol)"></i>
    <i class="mb-4 animated fadeIn pb-1 fa-4x fad fa-swap-opacity {{icon.symbol}} fa-lg fa-fw app-icons-icon app-cursor-pointer" (click)="onSelect('d', icon.symbol, ['swap-opacity'])"></i>
  </div>
  <div>
    <div class="p-3">
      <mdb-select class="form-blue-grey" [outline]="true" [visibleOptions]="8" [options]="selectOptions" [(ngModel)]="selectedValue" (ngModelChange)="getSelectedValue($event)"
      [filterEnabled]="true" [filterAutocomplete]="true" filterPlaceholder="{{ 'SEARCH' | translate }}"></mdb-select>
    </div>
    <div class="d-flex flex-wrap justify-content-center align-content-start">
      <div *ngFor="let symbol of icons" class="app-icons-frame p-3 app-cursor-pointer" (click)="onPreview(symbol)">
        <i class="far {{symbol}} fa-2x fa-fw grey-text animated fadeIn app-icons-icon"></i>
      </div>
    </div>
  </div>
</div>