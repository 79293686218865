/**
 * @module Shared
 */

import { Injectable, Inject } from '@angular/core';
import { FirebaseService } from '@services/firebase/firebase.service';

@Injectable({
  providedIn: 'root'
})
export class UidService {

  /**
   * @description Creates an instance of UidService.
   * @param {FirebaseService} $firebase
   * @memberof UidService
   */
  constructor(
    @Inject(FirebaseService) protected $firebase: FirebaseService
  ) {}

  /**
   * @description Renvoi un identifiant unique
   * @returns {string}
   * @memberof UidService
   */
  create(): string {
    return this.$firebase.angularFirestore.createId();
  }

}
