/**
 * @module Dashboard
 */

import { Component, OnInit, HostListener, Input, EventEmitter, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ItemIcon, ItemColor } from '@functions/item.functions';

const FULL_STUDIO_WIDTH = 1600;
const MEDIUM_STUDIO_WIDTH = 768;

@Component({
  selector: 'app-dashboard-display-frames',
  templateUrl: './dashboard-display-frames.component.html',
  styleUrls: ['./dashboard-display-frames.component.css']
})
export class DashboardDisplayFramesComponent implements OnInit {
 
  @Input() slides: boolean;
  @Output() sendSlides: EventEmitter<boolean>;

  icon: string;
  color: string;
  width: number;
  show: { [key: string]: boolean };
  framesChange$: BehaviorSubject<void>;

  constructor() {
    this.show = {
      dashboard: false,
      params: false
    };
    this.framesChange$ = new BehaviorSubject<void>(null);
    this.sendSlides = new EventEmitter<boolean>();
  }

  @HostListener('window:resize', ['$event'])
  private onResize(event: { target: { innerWidth: number; }; }) {
    this.width = event.target.innerWidth;
    this.setFrames();
  }

  ngOnInit() {
    this.icon = ItemIcon('dashboards', 'd');
    this.color = ItemColor('dashboards');
    this.width = window.innerWidth;
    this.setFrames();
  }

  setSlides() {
    this.slides = !this.slides;
    this.sendSlides.emit(this.slides);
  }

  setFrames() {
    if(this.isFullWidth) {
      this.show = {
        dashboard: true,
        params: true
      };
    }
    else if(this.isMediumWidth) {
      this.show = {
        dashboard: true,
        params: false
      };
    }
    else {
      this.show = {
        dashboard: true,
        params: false
      };
    }
    this.framesChange$.next();
  }

  public get framesCount(): number {
    return Object.values(this.show).filter(s => s).length;
  }

  public get frameSize(): number {
    return 420;
  }

  public get isFullWidth(): boolean {
    return this.width >= FULL_STUDIO_WIDTH;
  }

  public get isMediumWidth(): boolean {
    return this.width >= MEDIUM_STUDIO_WIDTH && this.width < FULL_STUDIO_WIDTH;
  }

  public get isSmallWidth(): boolean {
    return this.width < MEDIUM_STUDIO_WIDTH;
  }

  public setFrame(frame: string, show?: boolean) {
    const _show = show !== undefined ? !show : this.show[frame];
    if(this.isSmallWidth) {
      this.show = {
        dashboard: false,
        params: false
      };
    }
    this.show[frame] = !_show;
    this.framesChange$.next();
  }

}
