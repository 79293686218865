/**
 * @module Form
 */

import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.css']
})
export class DeleteComponent {

  @Input() code: string;
  @Output() sendDelete: EventEmitter<void>;

  confirm: string;

  constructor() {
    this.confirm = '';
    this.sendDelete = new EventEmitter<void>();
  }

  onDelete() {
    this.sendDelete.emit();
  }

}
