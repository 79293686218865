/**
 * @module Widget
 */

import { WidgetDatesBoundsModel, FilterBoundType, WidgetDatesBoundModel } from '@models/widgetLayers.model';
import { ModificationClass, ModificationStateClass } from '@class/modification.class';
import { DeepCopy } from '@functions/copy.functions';
import { BehaviorSubject } from 'rxjs';
import { UidClass } from '@class/uid.class';
import { UidModel } from '@models/uid.model';
import { ParseGenericDate } from '@functions/moment.functions';

export class WidgetDatesBoundsClass extends ModificationClass<WidgetDatesBoundsModel> {
  
  private _uid: UidClass;

  protected _parent: WidgetDatesBoundsModel;
  protected _inital: WidgetDatesBoundsModel;
  protected _attributes: string[];

  constructor(widgetDatesBounds: WidgetDatesBoundsModel, state: ModificationStateClass, modifications$: BehaviorSubject<ModificationStateClass>, lastModification$: BehaviorSubject<string>) {

    super();

    this._parent = widgetDatesBounds;
    this._state = state;
    this._modifications$ = modifications$;
    this._lastModification$ = lastModification$;

    this._reset(this._parent);

    this._attributes = this.attributes.slice();
    this._attributes.push(...this._uid.attributes);

  }

  private _reset(widgetDatesBounds: WidgetDatesBoundsModel) {
    this._uid = new UidClass(widgetDatesBounds as UidModel, true);
    
    this.attributes.forEach((attribute: string) => {
      this._parent[attribute] = widgetDatesBounds[attribute];
    });

    this._inital = DeepCopy(widgetDatesBounds);

    this._state.flush();
    this._modifications$.next(this._state);
  }

  reset() {
    this._state.flush();
    this._reset(this._inital);
  }

  get attributes(): Readonly<string[]> {
    return ['greater', 'less'];
  }

  get class(): string {
    return 'widgetDatesBounds';
  }

  get uid(): UidClass {
    return this._uid;
  }

  get greater(): Readonly<WidgetDatesBoundModel> {
    return this._parent.greater;
  }

  get greaterParsed(): Readonly<string> {
    return ParseGenericDate(this._parent.greater.value, true);
  }

  get less(): Readonly<WidgetDatesBoundModel> {
    return this._parent.less;
  }

  get lessParsed(): Readonly<string> {
    return ParseGenericDate(this._parent.less.value, false);
  }

  set(type: FilterBoundType, equivalent: boolean, value?: string) {
    this._parent[type].equivalent = equivalent;
    this._parent[type].value = value === undefined ? this._parent[type].value : value;
    this.emit(['reload', 'datesBounds']);
    this._lastModification$.next('reload');
    this._lastModification$.next('datesBounds');
  }

  has(type: FilterBoundType): Readonly<boolean> {
    return this._parent[type].value.length > 0;
  }

  get hasAny(): Readonly<boolean> {
    return this._parent.greater.value.length > 0 || this._parent.less.value.length > 0;
  }

  flush() {
    this._parent.greater.value = '';
    this._parent.less.value = '';
    this.emit(['reload', 'datesBounds']);
    this._lastModification$.next('reload');
    this._lastModification$.next('datesBounds');
  }

  get count(): Readonly<number> {
    return Object.values(this._parent).filter(b => b.value.length > 0).length;
  }

}