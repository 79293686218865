<span *ngFor="let language of languages">
  <div class="md-form md-outline mb-0 mt-3" *ngIf="current == language">
    <textarea [ngModel]="labels.value(language)" (ngModelChange)="onLabel($event, language)" id="form-label" class="form-control textarea form-{{color}}" rows="2" type="text" mdbInput style="resize:none;"></textarea>
    <label for="form-label">{{ label | translate }}</label>
    <small id="form-label-help" class="form-text text-muted d-flex align-items-center">
      <i *ngIf="labels.isValid('labels@' + language)" class="{{color}}-text fas fa-check-circle pr-1"></i>
      <i *ngIf="!labels.isValid('labels@' + language)" class="text-warning fas fa-times-circle pr-1"></i>
      <span class="mr-auto">{{labels.value(language).length}}/{{labels.requirement(labels.attributeLabel)['maxLength']}} ({{ 'MINIMUM' | translate }}: {{labels.requirement(labels.attributeLabel)['minLength']}})</span>
      <a *ngFor="let lg of languages">
        <mdb-badge (click)="current = lg" *ngIf="lg != language" color="grey" class="text-uppercase z-depth-0 px-1 mx-1">{{lg}}</mdb-badge>
        <mdb-badge *ngIf="lg == language" [color]="color" class="text-uppercase z-depth-0 px-1 mx-1">{{lg}}</mdb-badge>
      </a>
    </small>
  </div>
</span>