<div class="container-fluid mt-3">
  <div class="row">
    <div class="col-12 px-0">
      <div *ngFor="let c of colors" class="mb-3 app-colors-border px-2 pb-1 pt-3">
        <h6 class="px-1 white font-small app-colors-label text-elegant"><strong>{{c.label | translate}}</strong></h6>
        <mdb-badge class="p-1 mr-1 mb-1 z-depth-0 app-cursor-pointer" (click)="onSelect(shade.hexa)" *ngFor="let shade of c.list" [ngStyle]="{ 'background-color': shade.hexa, 'margin': '1px' }">
          <i class="fas fa-check-circle fa-lg fa-fw {{ brightness == 'dark' ? 'white-text' : 'text-elegant' }} ml-1 app-colors-color" [ngStyle]="{ 'opacity': shade.hexa == color.hexa ? '1' : '0' }"></i>
        </mdb-badge>
      </div>
    </div>
  </div>
</div>