/**
 * @module User
 */

import { Component, ViewChild, OnDestroy } from '@angular/core';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { Subscription } from 'rxjs';
import { UserClass } from '@class/user.class';
import { AuthService } from '@services/auth/auth.service';
import { UserService } from '@services/user/user.service';
import { MessagesService } from '@services/messages/messages.service';
import { ItemColor, ItemIcon } from '@functions/item.functions';
import { ModificationStateClass } from '@class/modification.class';
import { SignoutService } from '@services/signout/signout.service';

@Component({
  selector: 'app-profil',
  templateUrl: './profil.component.html',
  styleUrls: ['./profil.component.css']
})
export class ProfilComponent implements OnDestroy {

  @ViewChild('editor', { static: true }) editor: ModalDirective;

  color = 'blue';
  icon = 'fal fa-user-circle';
  current: UserClass;
  current$sub: Subscription;
  editorIndex: number;
  editorIndexMax: number;

  constructor(
    private $auth: AuthService,
    private $users: UserService,
    private $messages: MessagesService,
    private $signout: SignoutService
  ) {
    this.current$sub = this.$auth.user$.subscribe({
      next: (user: UserClass) => {
        this.current = user;
      }
    });
    this.editorIndex = 0;
    this.editorIndexMax = 1;
  }

  ngOnDestroy() {
    this.current$sub.unsubscribe();
  }

  public show() {
    this.editor.show();
  }

  public hide() {
    this.editor.hide();
  }

  receiveRestore() {
    this.current.reset();
  }

  receiveDelete() {
    this.editorIndex = NaN;
  }

  receiveDeleteConfirmation() {
    this.$users.remove(this.current.uid.value)
    .then(() => {
      this.editor.hide();
      this.editorIndex = 0;
      this.$messages.success({ 
        title: { plain: this.current.id.fullname }, 
        text: { code: 'SUCCESSFULLY_REMOVED/TEXT' } 
      });
      this.current = this.$users.create();
      this.$signout.signOut()
      .then(() => {
        this.$messages.info({ text: { code: 'LOGOUT_SUCCESS/TEXT' } });
      });
    })
    .catch((error) => {
      this.$messages.error({ 
        title: { plain: this.current.id.fullname }, 
        text: { 
          code: 'REMOVE_FAILED/TEXT',
          params: { error: error.code }
        } 
      });
    });
  }

  receiveNext(isNext: boolean) {
    this.editorIndex = this.editorIndex + (isNext ? 1 : -1);
    if(this.editorIndex < 0) this.editorIndex = this.editorIndexMax;
    if(this.editorIndex > this.editorIndexMax) this.editorIndex = 0;
  }  
  
  receiveSave() {
    this.$users.set(this.current)
    .then(() => {
      this.$messages.success({ 
        title: { plain: this.current.id.fullname }, 
        text: { code: 'SUCCESSFULLY_SAVED/TEXT' } 
      });
    })
    .catch((error) => {
      this.$messages.error({ 
        title: { plain: this.current.id.fullname }, 
        text: { 
          code: 'SAVE_FAILED/TEXT',
          params: { error: error.code }
        } 
      });
    });
  }

}
