/**
 * @module Theme
 */

import { Component, OnInit, Input } from '@angular/core';
import { ItemColor } from '@functions/item.functions';
import { ThemeOptionsClass } from '@class/themeOptions.class';
import { ThemeOptionType } from '@models/themeStudio.model';

@Component({
  selector: 'app-theme-option-layout-padding',
  templateUrl: './theme-option-layout-padding.component.html',
  styleUrls: ['./theme-option-layout-padding.component.css']
})
export class ThemeOptionLayoutPaddingComponent implements OnInit {

  @Input() themeOptions: ThemeOptionsClass;

  color = ItemColor('themes');
  optionType: ThemeOptionType = 'layout_padding';
  all: number;
  showDetails: boolean;

  constructor() {}

  ngOnInit() {
    this.all = Math.max(...Object.values(this.themeOptions.options().layout.padding));
  }

  receiveShowDetails(showDetails: boolean) {
    this.showDetails = showDetails;
  }

  receiveValue(value: number) {
    this.themeOptions.setOption('layout_padding_top', value);
    this.themeOptions.setOption('layout_padding_left', value);
    this.themeOptions.setOption('layout_padding_right', value);
    this.themeOptions.setOption('layout_padding_bottom', value);
  }

}
