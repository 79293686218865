<div *ngIf="widgetLayers.uid.value == current" class="alert alert-info animated slideInRight mt-3 mb-1 font-very-small" role="alert">
  <i class="fas fa-paint-roller mr-1"></i>
  {{ 'AUTO_DUPLICATE_OPTIONS/TEXT' | translate }}
</div>

<hr *ngIf="widgetLayers.uid.value == current">

<app-widget-option [categories]="['OPTIONS']" [label]="'PERSONALIZE'">
  <div app-widget-option-content>
    <div class=d-flex>
      <app-links-item-option placement="bottom" class="d-block mx-1" [symbol]="'trash'" [selected]="deletable" (sendSelected)="receiveDeletable()"></app-links-item-option>
      <app-links-item-option placement="bottom" *ngIf="widgetLayers.uid.value != current" class="d-block mx-1" [symbol]="'paint-roller'" [selected]="duplicable" (sendSelected)="receiveDuplicable()"></app-links-item-option>
      <button mdbBtn (click)="optionsModal.show()" type="button" color="light" size="sm" class="px-2 z-depth-0 my-0 mr-0 ml-1" mdbWavesEffect>
        <i class="fas fa-plus fa-fw {{ color }}-text"></i>
      </button>
    </div>
  </div>
</app-widget-option>

<hr>

<app-widget-layers-theme-option *ngFor="let definedOption of widgetLayers.layer(current).definedOptions"
  [widgetLayers]="widgetLayers" 
  [current]="current" 
  [definedOption]="definedOption"
  [duplicable]="duplicable"
  [deletable]="deletable"
></app-widget-layers-theme-option>

<div mdbModal #optionsModal="mdbModal" class="modal fade" style="overflow-y: scroll" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header d-flex align-items-center flex-column">

        <i class="animated rotateIn {{ color }}-text far fa-{{ icon }} fa-4x d-none d-sm-inline"></i>
        <i class="animated rotateIn {{ color }}-text far fa-{{ icon }} fa-2x d-sm-none"></i>

        <h6 class="modal-title w-100 text-center">
          <div class="font-weight-bold text-elegant mt-1">{{ 'OPTIONS' | translate }}</div>
        </h6>

      </div>
      <div class="modal-header">

        <button type="button" size="sm" mdbBtn color="secondary" class="waves-light z-depth-0 px-2 m-0 app-close-modal" (click)="optionsModal.hide()" mdbWavesEffect>
          <i class="fas fa-times fa-fw"></i>
        </button>

        <div class="btn-group" mdbDropdown>
          <button [disabled]="definedOptions.length == 0" type="button" mdbBtn color="default" class="waves-light z-depth-0" mdbWavesEffect (click)="onCancel();optionsModal.hide();">
            <i class="d-sm-none far fa-times fa-fw"></i>
            <i class="d-none d-sm-inline far fa-times"></i>
            <span class="d-none d-sm-inline ml-1">{{ 'CANCEL' | translate }}</span>
          </button>
          <button *ngIf="definedOptions.length > 0" type="button" mdbDropdownToggle mdbBtn color="default" class="dropdown-toggle dropdown-toggle-split px-3 waves-light z-depth-0" mdbWavesEffect></button>
          <div class="dropdown-menu" *ngIf="definedOptions.length > 0" >
            <a class="dropdown-item" *ngFor="let definedOption of definedOptions ; let i = index" (click)="onRemove(i)">
              <i class="far fa-times fa-fw"></i>
              <span class="ml-1 font-small" [ngClass]="{ 'font-weight-bold': isFirst }" *ngFor="let categorie of list[definedOption.option].categoriesAndLabel ; let isFirst = first">{{ categorie | translate }}</span>
            </a>
          </div>
        </div>

        <button type="button" mdbBtn [color]="color" class="waves-light z-depth-0 px-sm-4 px-3 my-0 ml-auto mr-0" (click)="onSelect();optionsModal.hide();" mdbWavesEffect>
          <i class="d-sm-none far fa-check fa-fw"></i>
          <i class="d-none d-sm-inline far fa-check"></i>
          <span class="d-none d-sm-inline mx-1">{{ 'SELECT' | translate }}</span>
          <mdb-badge color="grey" class="z-depth-0">{{definedOptions.length}}</mdb-badge>
        </button>

      </div>
      <div class="modal-body overflow-hidden" *ngIf="optionsModal.isShown">

        <app-widget-layers-theme-options-list [themeOptions]="themeOptions" (sendSelect)="receiveSelect($event)"></app-widget-layers-theme-options-list>

      </div>
    </div>
  </div>
</div>