<div class="mt-3">
  
  <app-input [marginTop]="false" [label]="'SEARCH'" [color]="color" [value]="searchLabel" (sendValue)="receiveTextValue($event)"></app-input>

  <app-tags-links-item *ngFor="let tag of list | searchLabels:searchLabel:language" 
  [tag]="tag" [isSelected]="isSelected(tag.uid.value)"
  (sendSelected)="receiveSelected($event)"></app-tags-links-item>

</div>

<app-empty app-browser-frame-content-list [action]="false" [type]="'tags'" *ngIf="list != undefined && list.length == 0"></app-empty>
<app-loading app-browser-frame-content-list *ngIf="list == undefined"></app-loading>