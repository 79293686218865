/**
 * @module Form
 */

import { Component } from '@angular/core';

@Component({
  selector: 'app-not-deletable',
  templateUrl: './not-deletable.component.html',
  styleUrls: ['./not-deletable.component.css']
})
export class NotDeletableComponent {

  constructor() {}

}
