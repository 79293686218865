/**
 * @module Indicator
 */

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UID } from '@models/uid.model';
import { PivotClass } from '@class/pivot.class';
import { ItemColor, ItemIcon } from '@functions/item.functions';

@Component({
  selector: 'app-indicator-studio-pivot',
  templateUrl: './indicator-studio-pivot.component.html',
  styleUrls: ['./indicator-studio-pivot.component.css']
})
export class IndicatorStudioPivotComponent {

  @Input() pivot: PivotClass;
  @Output() sendSelected: EventEmitter<UID>;

  selected: boolean;
  color = ItemColor('pivots', true);
  icon = ItemIcon('pivots');

  constructor() {
    this.sendSelected = new EventEmitter<UID>();
    this.selected = false;
  }

  receiveSelected() {
    this.selected = !this.selected;
    this.sendSelected.emit(this.pivot.uid.value);
  }

}
