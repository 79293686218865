/**
 * @module Widget
 */

import { Component, Input } from '@angular/core';
import { WidgetLayersClass } from '@class/widgetLayers.class';
import { ItemColor } from '@functions/item.functions';

@Component({
  selector: 'app-widget-indicator-cumulative',
  templateUrl: './widget-indicator-cumulative.component.html',
  styleUrls: ['./widget-indicator-cumulative.component.css']
})
export class WidgetIndicatorCumulativeComponent {

  @Input() widgetLayers: WidgetLayersClass;
  @Input() current: number;

  color = ItemColor('indicators');

  constructor() {}

  receiveCumulative(cumulative: boolean) {
    this.widgetLayers.indicators[this.current].change({ cumulative: cumulative });
  }

}
