/**
 * @module Theme
 */

import { ThemeOptionsModel, ThemeOptionsType, ThemeOptionsConfigurationType } from '@models/themeOptions.model';
import { ChartType } from '@models/chart.model';
import { ChartOptions, ChartDataSets, ChartXAxe, ChartYAxe } from 'chart.js';
import { DeepMerge } from '@functions/copy.functions';
import { ThemeOptionType } from '@models/themeStudio.model';
import { Subject, BehaviorSubject } from 'rxjs';
import { Options } from 'chartjs-plugin-datalabels/types/options';
import { Type } from '@angular/core';
import { ThemeOptionsConfiguration, CheckOptions } from '@functions/theme.functions';
import { GetPropertyValue } from '@functions/object.functions';
import { ChartsTypes } from '@functions/chart.functions';
import { WidgetLayersDefinedOptionModel } from '@models/widgetLayers.model';

export class ThemeOptionsHostClass {
  constructor(public component: Type<any>) {}
}

export class ThemeOptionsClass {

  private _themes: ThemeOptionsModel;
  private _optionsConfiguration: ThemeOptionsConfigurationType;
  
  changedDefinedOption$: Subject<WidgetLayersDefinedOptionModel>;
  changedOptions$: Subject<ThemeOptionType>;
  changedSeries$: Subject<ThemeOptionType>;
  changed$: BehaviorSubject<boolean>;

  constructor(themeOptions: ThemeOptionsModel) {
    this._themes = CheckOptions(themeOptions);
    this.changedDefinedOption$ = new Subject<any>();
    this.changedOptions$ = new Subject<ThemeOptionType>();
    this.changedSeries$ = new Subject<ThemeOptionType>();
    this.changed$ = new BehaviorSubject<boolean>(false);
  }

  get class(): Readonly<string> {
    return 'themeOptions';
  }

  get model(): ThemeOptionsModel {
    return this._themes;
  }

  set model(themeOptions: ThemeOptionsModel) {
    this._themes = themeOptions;
    this.changedDefinedOption$.next(undefined);
    this.changedOptions$.next(undefined);
    this.changedSeries$.next(undefined);
    this.changed$.next(true);
  }

  option(option: ThemeOptionType, index?: number, type?: ThemeOptionsType, axesXY?: 'x' | 'y', chartType?: ChartType, allChartTypes?: boolean): Readonly<any | { [key in ChartType]: any }> {
    const configuration = this.optionsConfiguration[option];

    if(!!allChartTypes) {
      let values = {} as { [key in ChartType]: any };
      switch(type) {
        case 'options':
          ChartsTypes().forEach(t => { if(!!this._themes.options.specific[t]) values[t] = GetPropertyValue(this._themes.options.specific[t], configuration.path); });
          return values;
        case 'series':
          ChartsTypes().forEach(t => { if(!!this._themes.series.specific[t]) values[t] = GetPropertyValue(this._themes.series.specific[t][index], configuration.path); });
          return values;
        case 'datalabels':
          ChartsTypes().forEach(t => { if(!!this._themes.datalabels.specific[t]) values[t] = GetPropertyValue(this._themes.datalabels.specific[t][index], configuration.path); });
          return values;
        case 'axes':
          if(axesXY === 'x') ChartsTypes().forEach(t => { if(!!this._themes.xAxes.specific[t]) values[t] = GetPropertyValue(this._themes.xAxes.specific[t][index], configuration.path); });
          else ChartsTypes().forEach(t => { if(!!this._themes.yAxes.specific[t]) values[t] = GetPropertyValue(this._themes.yAxes.specific[t][index], configuration.path); });
          return values;
      }
    }
    else {
      switch(type) {
        case 'options': return GetPropertyValue(chartType !== undefined ? this._themes.options.specific[chartType] : this._themes.options.common, configuration.path);
        case 'series': return GetPropertyValue(chartType !== undefined ? this._themes.series.specific[chartType][index] : this._themes.series.common[index], configuration.path);
        case 'datalabels': return GetPropertyValue(chartType !== undefined ? this._themes.datalabels.specific[chartType][index] : this._themes.datalabels.common[index], configuration.path);
        case 'axes':
          if(axesXY === 'x') return GetPropertyValue(chartType !== undefined ? this._themes.xAxes.specific[chartType][index] : this._themes.xAxes.common[index], configuration.path);
          else return GetPropertyValue(chartType !== undefined ? this._themes.yAxes.specific[chartType][index] : this._themes.yAxes.common[index], configuration.path);
      }
    }
  }

  options(type?: ChartType): Readonly<ChartOptions> {
    if(type !== undefined) {
      switch(type) {
        case 'bar':
        case 'bubble': 
        case 'sticker':
        case 'gauge':
        case 'horizontalBar':
        case 'line':
        case 'scatter':
          return DeepMerge(
            this._themes.options.common, 
            this._themes.options.specific[type] || {}, 
            { scales: { 
              xAxes: this.xAxes(type), 
              yAxes: this.yAxes(type) 
            } }
          );
        case 'radar':
        case 'polarArea':
          let scale = {};
          if(this.yAxes(type).length > 0) {
            scale = this.yAxes(type)[0];
          }
          if(this.xAxes(type).length > 0) {
            scale['position'] = 'bottom';
            scale['pointLabels'] = this.xAxes(type)[0].ticks;
            scale['angleLines'] = this.xAxes(type)[0].gridLines;
          }
          return DeepMerge(
            this._themes.options.common, 
            this._themes.options.specific[type] || {}, 
            { scale: scale } as any
          );
        case 'doughnut':
        case 'pie':
          return DeepMerge(
            this._themes.options.common, 
            this._themes.options.specific[type] || {}
          );
      }
    }
    else {
      return DeepMerge(
        this._themes.options.common,
        { scales: { 
          xAxes: this.xAxes(), 
          yAxes: this.yAxes()
        } }
      );
    }
  }

  series(index: number, type?: ChartType, datalabelsIndex?: number[]): Readonly<ChartDataSets> {
    //on applique la série de façon cyclique
    //exemple : si index "2" est demandé alors que l'on a 2 séries (ie: "0" et "1")
    //alors on renvoie la série "0" car on repart au début du tableau
    if(this._themes.series.common.length === 0) return {};
    if(this._themes.series.common.length === 1) index = 0;
    else if(index > this._themes.series.common.length - 1) {
      index = index % this._themes.series.common.length; 
    }

    let datalabels: { [key: string]: Options } = {};
    //si les datalabels ne sont pas renseignés on affecte le datalabels par défaut de la série
    if(datalabelsIndex !== undefined) {
      datalabelsIndex.forEach((i: number) => {
        datalabels[i] = this.datalabels(i, type);
      });
    }

    if(type !== undefined && this._themes.series.specific[type] !== undefined) {
      if(datalabelsIndex === undefined) {
        const dtls = this.datalabels(
        this._themes.series.specific[type][index]['datalabelsID'] !== undefined ? 
          this._themes.series.specific[type][index]['datalabelsID'] : 
          this._themes.series.common[index]['datalabelsID']
        , type)
        if(Object.values(dtls).length > 0) datalabels = { [index]: dtls };
      }
      return DeepMerge(this._themes.series.common[index], this._themes.series.specific[type][index], { datalabels: { labels: datalabels } } as unknown);
    }
    else {
      if(datalabelsIndex === undefined) {
        datalabels = { [index]: this.datalabels(this._themes.series.common[index]['datalabelsID'], type) };
      }
      return DeepMerge(this._themes.series.common[index], { datalabels: { labels: datalabels } } as unknown);
    }
  }

  datalabels(index: number, type?: ChartType): Readonly<Options> {
    //on applique le datalabels de façon cyclique
    //exemple : si index "2" est demandé alors que l'on a 2 datalabels (ie: "0" et "1")
    //alors on renvoie le datalabels "0" car on repart au début du tableau
    if(this._themes.datalabels.common.length === 0) return {};
    if(this._themes.datalabels.common.length === 1) index = 0;
    else if(index > this._themes.datalabels.common.length - 1) index = index % this._themes.datalabels.common.length; 
    if(type !== undefined && this._themes.datalabels.specific[type] !== undefined) return DeepMerge(this._themes.datalabels.common[index], this._themes.datalabels.specific[type][index]);
    else return DeepMerge(this._themes.datalabels.common[index]);
  }

  seriesCount(): Readonly<number> {
    return this._themes.series.common.length;
  }

  datalabelsCount(): Readonly<number> {
    return this._themes.datalabels.common.length;
  }

  xAxesCount(): Readonly<number> {
    return this._themes.xAxes.common.length;
  }

  yAxesCount(): Readonly<number> {
    return this._themes.yAxes.common.length;
  }

  axes(axesXY: 'x' | 'y', index: number, type?: ChartType): Readonly<ChartXAxe | ChartYAxe> {
    if(axesXY === 'x') return this.xAxes(type)[index];
    if(axesXY === 'y') return this.yAxes(type)[index];
  }

  private xAxes(type?: ChartType): ChartXAxe[] {
    let xAxes = [] as ChartXAxe[];
    if(type !== undefined && this._themes.xAxes.specific[type] !== undefined) {
      this._themes.xAxes.common.forEach((axis: ChartXAxe, index: number) => {
        xAxes.push(DeepMerge(this._themes.xAxes.common[index] || {}, this._themes.xAxes.specific[type][index] || {}));
      });
    }
    else {
      this._themes.xAxes.common.forEach((axis: ChartXAxe, index: number) => {
        xAxes.push(DeepMerge(this._themes.xAxes.common[index] || {}));
      });
    }
    return xAxes;
  }

  private yAxes(type?: ChartType): ChartYAxe[] {
    let yAxes = [] as ChartYAxe[];
    if(type !== undefined && this._themes.yAxes.specific[type] !== undefined) {
      this._themes.yAxes.common.forEach((axis: ChartYAxe, index: number) => {
        yAxes.push(DeepMerge(this._themes.yAxes.common[index] || {}, this._themes.yAxes.specific[type][index] || {}));
      });
    }
    else {
      this._themes.yAxes.common.forEach((axis: ChartYAxe, index: number) => {
        yAxes.push(DeepMerge(this._themes.yAxes.common[index] || {}));
      });
    }
    return yAxes;
  }

  specifics(): Readonly<{ [key in ChartType]: ChartOptions }> {
    return this._themes.options.specific || {} as { [key in ChartType]: ChartOptions };
  }

  addSerie(fromSerie: number) {
    this._themes.series.common.push(DeepMerge({} as ChartDataSets, this._themes.series.common[fromSerie]));
    for(const type in this._themes.series.specific) {
      this._themes.series.specific[type].push(DeepMerge({} as ChartDataSets, this._themes.series.specific[type][fromSerie]));
    }
    this.changedSeries$.next(undefined);
    this.changed$.next(true);
  }

  addDatalabel(fromDatalabel: number) {
    this._themes.datalabels.common.push(DeepMerge({} as Options, this._themes.datalabels.common[fromDatalabel]));
    for(const type in this._themes.datalabels.specific) {
      this._themes.datalabels.specific[type].push(DeepMerge({} as Options, this._themes.datalabels.specific[type][fromDatalabel]));
    }
    this.changedSeries$.next(undefined); //on signale dans series le changement car c'est à la série d'aller chercher sont/ses datalabels
    this.changed$.next(true);
  }

  removeSerie(index: number): boolean {
    if(this._themes.series.common.length > 1) {
      this._themes.series.common.splice(index, 1);
      for(const type in this._themes.series.specific) {
        this._themes.series.specific[type].splice(index, 1);
      }
      this.changedSeries$.next(undefined);
      this.changed$.next(true);
      return true;
    }
    else return false;
  }

  removeDatalabel(index: number): boolean {
    if(this._themes.datalabels.common.length > 1) {
      this._themes.datalabels.common.splice(index, 1);
      for(const type in this._themes.datalabels.specific) {
        this._themes.datalabels.specific[type].splice(index, 1);
      }
      this._themes.series.common.forEach((dataset: ChartDataSets, i: number) => {
        if(this._themes.series.common[i]['datalabelsID'] === index) dataset['datalabelsID'] = '0'; //en cas de suppression du datalabels par défaut de la serie on affecte le premier datalabels de la liste
      });
      for(const type in this._themes.series.specific) {
        this._themes.series.specific[type].forEach((dataset: ChartDataSets, i: number) => {
          if(this._themes.series.common[i]['datalabelsID'] === index) dataset['datalabelsID'] = '0'; //en cas de suppression du datalabels par défaut de la serie on affecte le premier datalabels de la liste
        });
      }
      this.changedSeries$.next(undefined); //on signale dans series le changement car c'est à la série d'aller chercher sont/ses datalabels
      this.changed$.next(true);
      return true;
    }
    else return false;
  }

  setSerieIndex(fromIndex: number, toIndex: number) {
    if(fromIndex !== toIndex) {
      let element = this._themes.series.common[fromIndex];
      this._themes.series.common.splice(fromIndex, 1);
      this._themes.series.common.splice(toIndex, 0, element);

      for(let type in this._themes.series.specific) {
        let element = this._themes.series.specific[type][fromIndex];
        this._themes.series.specific[type].splice(fromIndex, 1);
        this._themes.series.specific[type].splice(toIndex, 0, element);
      }
      this.changedOptions$.next(undefined);
      this.changedSeries$.next(undefined);
      this.changed$.next(true);
    }
  }

  setOption(option: ThemeOptionType, value: any, types?: ChartType[]) {
    this.setTheme(option, value, 'options', undefined, types, undefined);
  }

  setSeries(option: ThemeOptionType, value: any, index?: number, types?: ChartType[]) {
    this.setTheme(option, value, 'series', index, types, undefined);
  }

  setDatalabels(option: ThemeOptionType, value: any, index?: number, types?: ChartType[]) {
    this.setTheme(option, value, 'datalabels', index, types, undefined);
  }

  setAxes(option: ThemeOptionType, value: any, axesXY: 'x' | 'y', index?: number, types?: ChartType[]) {
    this.setTheme(option, value, 'axes', index, types, axesXY);
  }

  private setTheme(option: ThemeOptionType, value: any, optionType: ThemeOptionsType, index: number, types: ChartType[], axesXY: 'x' | 'y') {

    let path = {}
    const _path = this.optionsConfiguration[option].path.slice();
    _path.reduce((parent: string, child: string, index: number) => { 
      parent[child] = index === _path.length - 1 ? value : {}; 
      return parent[child];
    }, path);

    if(types !== undefined) {
      types.forEach((type: ChartType) => {
        if(optionType === 'options') {
          if(this._themes.options.specific[type] === undefined) this._themes.options.specific[type] = {};
          this._themes.options.specific[type] = DeepMerge(this._themes.options.specific[type], path);
        }
        else if(optionType === 'series') {
          if(this._themes.series.specific[type] === undefined) this._themes.series.specific[type] = new Array(this._themes.series.common.length).fill({});
          if(option === 'elements_borderDash') this._themes.series.specific[type][index].borderDash = []; //sinon merge avec l'ancien tableau
          this._themes.series.specific[type][index] = DeepMerge(this._themes.series.specific[type][index], path);
        }
        else if(optionType === 'datalabels') {
          if(this._themes.datalabels.specific[type] === undefined) this._themes.datalabels.specific[type] = new Array(this._themes.datalabels.common.length).fill({});
          this._themes.datalabels.specific[type][index] = DeepMerge(this._themes.datalabels.specific[type][index], path);
        }
        else if(optionType === 'axes' && axesXY === 'x') {
          if(this._themes.xAxes.specific[type] === undefined) this._themes.xAxes.specific[type] = new Array(this._themes.xAxes.common.length).fill({});
          if(option === 'axes_gridLines_borderDash') {
            if(this._themes.xAxes.specific[type][index].gridLines === undefined) this._themes.xAxes.specific[type][index].gridLines = {};
            this._themes.xAxes.specific[type][index].gridLines.borderDash = []; //sinon merge avec l'ancien tableau
          }
          this._themes.xAxes.specific[type][index] = DeepMerge(this._themes.xAxes.specific[type][index], path);
        }
        else if(optionType === 'axes' && axesXY === 'y') {
          if(this._themes.yAxes.specific[type] === undefined) this._themes.yAxes.specific[type] = new Array(this._themes.yAxes.common.length).fill({});
          if(option === 'axes_gridLines_borderDash') {
            if(this._themes.yAxes.specific[type][index].gridLines === undefined) this._themes.yAxes.specific[type][index].gridLines = {};
            this._themes.yAxes.specific[type][index].gridLines.borderDash = []; //sinon merge avec l'ancien tableau
          }
          this._themes.yAxes.specific[type][index] = DeepMerge(this._themes.yAxes.specific[type][index], path);
        }
      });

      this.changedDefinedOption$.next({
        option: option,
        type: optionType,
        axesXY: axesXY,
        index: index
      });
      
    }
    else {
      if(optionType === 'options') {
        this._themes.options.common = DeepMerge(this._themes.options.common, path);
      }
      else if(optionType === 'series') {
        if(option === 'elements_borderDash') this._themes.series.common[index].borderDash = []; //sinon merge avec l'ancien tableau
        this._themes.series.common[index] = DeepMerge(this._themes.series.common[index] || {}, path);
      }
      else if(optionType === 'datalabels') {
        this._themes.datalabels.common[index] = DeepMerge(this._themes.datalabels.common[index] || {}, path);
      }
      else if(optionType === 'axes' && axesXY === 'x') {
        if(option === 'axes_gridLines_borderDash') {
          if(this._themes.xAxes.common[index].gridLines === undefined) this._themes.xAxes.common[index].gridLines = {};
          this._themes.xAxes.common[index].gridLines.borderDash = []; //sinon merge avec l'ancien tableau
        }
        this._themes.xAxes.common[index] = DeepMerge(this._themes.xAxes.common[index] || {}, path);
      }
      else if(optionType === 'axes' && axesXY === 'y') {
        if(option === 'axes_gridLines_borderDash') {
          if(this._themes.yAxes.common[index].gridLines === undefined) this._themes.yAxes.common[index].gridLines = {};
          this._themes.yAxes.common[index].gridLines.borderDash = []; //sinon merge avec l'ancien tableau
        }
        this._themes.yAxes.common[index] = DeepMerge(this._themes.yAxes.common[index] || {}, path);
      }
      //on force pour tous les types la modification effectuée dans le common
      this.setTheme(option, value, optionType, index, this.optionsConfiguration[option].limitedTo, axesXY);
      return; //on return pour ne pas émettre un next (sera fait par l'appel en récursif à setTheme)
    }

    if(optionType === 'options' || optionType === 'axes') this.changedOptions$.next(option);
    else this.changedSeries$.next(option); //on signale dans series le changement sur datalabels car c'est à la série d'aller chercher ses datalabels
    this.changed$.next(true);
  }

  limitedTo(option: ThemeOptionType): ChartType[] {
    return this.optionsConfiguration[option].limitedTo;
  }

  download(label: string) {
    let theme = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(this._themes));
    let downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute('href', theme);
    downloadAnchorNode.setAttribute('download', label + '.theme');
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  }

  get optionsConfiguration(): ThemeOptionsConfigurationType {
    if(this._optionsConfiguration === undefined) this._optionsConfiguration = ThemeOptionsConfiguration();
    return this._optionsConfiguration;
  }

}