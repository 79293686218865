/**
 * @module Dashboard
 */

import { Component, Input } from '@angular/core';
import { DashboardLayoutClass } from '@class/dashboardLayout.class';
import { ItemColor } from '@functions/item.functions';

@Component({
  selector: 'app-dashboard-layout-depth',
  templateUrl: './dashboard-layout-depth.component.html',
  styleUrls: ['./dashboard-layout-depth.component.css']
})
export class DashboardLayoutDepthComponent {

  @Input() dashboardLayout: DashboardLayoutClass;

  color = ItemColor('dashboards');
  depths = [0, 1, 2, 3];
  
  constructor() {}

  onValue(depth: number) {
    this.dashboardLayout.change({ depth: depth });
  }

}
