<div class="d-flex align-items-center justify-content-between mt-3" style="height: 31px;">
  <div class="blue-grey-text font-weight-bold d-flex align-items-center">
    <i class="fa-lg fa-fw far fa-chart-area {{color}}-text mr-1"></i>
    <div class="mx-1">{{ 'AXIS' | translate }}</div>
    <mdb-badge info="true" class="z-depth-0">{{ axesXY.toUpperCase() }}&nbsp;{{ current.index }}</mdb-badge>
  </div>
  <div class="btn-group" mdbDropdown>
    <button mdbDropdownToggle type="button" size="sm" mdbBtn color="light" class="blue-grey-text z-depth-0 dropdown-toggle waves-light d-flex align-items-center px-3" mdbWavesEffect>
      <i class="far fa-bars"></i>&nbsp;
    </button>
  
    <div class="dropdown-menu dropdown-dark dropdown-menu-right">

      <a class="dropdown-item z-depth-0" (click)="editorIndex = 0">{{ 'AXIS' | translate }}</a>
      <a class="dropdown-item z-depth-0" (click)="editorIndex = 1">{{ 'GRID' | translate }}</a>
      <a class="dropdown-item z-depth-0" (click)="editorIndex = 2">{{ 'TICKS' | translate }}</a>
      <a class="dropdown-item z-depth-0" (click)="editorIndex = 3">{{ 'TITLE' | translate }}</a>

    </div>
  </div>
</div>

<hr>

<div [ngSwitch]="editorIndex">
  <div *ngSwitchCase="0">
    <app-editor-section-title [title]="'AXIS'" (sendNext)="receiveNext($event)"></app-editor-section-title>

    <app-theme-option-axes-display [themeOptions]="themeOptions" [axesIndex]="current.index" [axesXY]="axesXY"></app-theme-option-axes-display>
    <app-theme-option-axes-bar-percentage [themeOptions]="themeOptions" [axesIndex]="current.index" [axesXY]="axesXY"></app-theme-option-axes-bar-percentage>
    <app-theme-option-axes-category-percentage [themeOptions]="themeOptions" [axesIndex]="current.index" [axesXY]="axesXY"></app-theme-option-axes-category-percentage>
    <app-theme-option-axes-position [themeOptions]="themeOptions" [axesIndex]="current.index" [axesXY]="axesXY"></app-theme-option-axes-position>
    <!-- MARCHE PAS : app-theme-option-axes-min-bar-length *ngIf="axesXY == 'x'" [themeOptions]="themeOptions" [axesIndex]="current.index" [axesXY]="axesXY"></app-theme-option-axes-min-bar-length-->

  </div>
  <div *ngSwitchCase="1">
    <app-editor-section-title [title]="'GRID'" (sendNext)="receiveNext($event)"></app-editor-section-title>

    <app-theme-option-axes-grid-lines-display [themeOptions]="themeOptions" [axesIndex]="current.index" [axesXY]="axesXY"></app-theme-option-axes-grid-lines-display>
    <app-theme-option-axes-grid-lines-draw-border [themeOptions]="themeOptions" [axesIndex]="current.index" [axesXY]="axesXY"></app-theme-option-axes-grid-lines-draw-border>
    <app-theme-option-axes-grid-lines-draw-ticks [themeOptions]="themeOptions" [axesIndex]="current.index" [axesXY]="axesXY"></app-theme-option-axes-grid-lines-draw-ticks>
    <app-theme-option-axes-grid-lines-line-width [themeOptions]="themeOptions" [axesIndex]="current.index" [axesXY]="axesXY"></app-theme-option-axes-grid-lines-line-width>
    <app-theme-option-axes-grid-lines-tick-mark-length [themeOptions]="themeOptions" [axesIndex]="current.index" [axesXY]="axesXY"></app-theme-option-axes-grid-lines-tick-mark-length>
    <app-theme-option-axes-grid-lines-border-dash [themeOptions]="themeOptions" [axesIndex]="current.index" [axesXY]="axesXY"></app-theme-option-axes-grid-lines-border-dash>
    <app-theme-option-axes-grid-lines-color [themeOptions]="themeOptions" [axesIndex]="current.index" [axesXY]="axesXY"></app-theme-option-axes-grid-lines-color>
    <app-theme-option-axes-grid-lines-circular *ngIf="axesXY == 'y' && current.index == 0" [themeOptions]="themeOptions" [axesIndex]="current.index" [axesXY]="axesXY"></app-theme-option-axes-grid-lines-circular>

  </div>
  <div *ngSwitchCase="2">
    <app-editor-section-title [title]="'TICKS'" (sendNext)="receiveNext($event)"></app-editor-section-title>

    <app-theme-option-axes-ticks-display [themeOptions]="themeOptions" [axesIndex]="current.index" [axesXY]="axesXY"></app-theme-option-axes-ticks-display>
    <app-theme-option-axes-ticks-font-family [themeOptions]="themeOptions" [axesIndex]="current.index" [axesXY]="axesXY"></app-theme-option-axes-ticks-font-family>
    <app-theme-option-axes-ticks-font-size [themeOptions]="themeOptions" [axesIndex]="current.index" [axesXY]="axesXY"></app-theme-option-axes-ticks-font-size>
    <app-theme-option-axes-ticks-font-style [themeOptions]="themeOptions" [axesIndex]="current.index" [axesXY]="axesXY"></app-theme-option-axes-ticks-font-style>
    <app-theme-option-axes-ticks-font-color [themeOptions]="themeOptions" [axesIndex]="current.index" [axesXY]="axesXY"></app-theme-option-axes-ticks-font-color>
    <app-theme-option-axes-ticks-line-height [themeOptions]="themeOptions" [axesIndex]="current.index" [axesXY]="axesXY"></app-theme-option-axes-ticks-line-height>
    <app-theme-option-axes-ticks-padding [themeOptions]="themeOptions" [axesIndex]="current.index" [axesXY]="axesXY"></app-theme-option-axes-ticks-padding>
    <app-theme-option-axes-ticks-begin-at-zero [themeOptions]="themeOptions" [axesIndex]="current.index" [axesXY]="axesXY"></app-theme-option-axes-ticks-begin-at-zero>

  </div>
  <div *ngSwitchCase="3">
    <app-editor-section-title [title]="'TITLE'" (sendNext)="receiveNext($event)"></app-editor-section-title>

    <app-theme-option-axes-title-display [themeOptions]="themeOptions" [axesIndex]="current.index" [axesXY]="axesXY"></app-theme-option-axes-title-display>
    <app-theme-option-axes-title-font-family [themeOptions]="themeOptions" [axesIndex]="current.index" [axesXY]="axesXY"></app-theme-option-axes-title-font-family>
    <app-theme-option-axes-title-font-size [themeOptions]="themeOptions" [axesIndex]="current.index" [axesXY]="axesXY"></app-theme-option-axes-title-font-size>
    <app-theme-option-axes-title-font-style [themeOptions]="themeOptions" [axesIndex]="current.index" [axesXY]="axesXY"></app-theme-option-axes-title-font-style>
    <app-theme-option-axes-title-font-color [themeOptions]="themeOptions" [axesIndex]="current.index" [axesXY]="axesXY"></app-theme-option-axes-title-font-color>
    <app-theme-option-axes-title-line-height [themeOptions]="themeOptions" [axesIndex]="current.index" [axesXY]="axesXY"></app-theme-option-axes-title-line-height>
    <app-theme-option-axes-title-padding [themeOptions]="themeOptions" [axesIndex]="current.index" [axesXY]="axesXY"></app-theme-option-axes-title-padding>

  </div>
</div>