/**
 * @module Dashboard
 */

import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { PivotClass } from '@class/pivot.class';
import { UID } from '@models/uid.model';
import { ItemsColors, ItemsIcons } from '@functions/item.functions';
import { PivotService } from '@services/pivot/pivot.service';
import { take } from 'rxjs/operators';
import { WidgetLayersClass } from '@class/widgetLayers.class';
import { WidgetIndicatorClass } from '@class/widgetIndicator.class';
import { DashboardDisplayService } from '@services/dashboardDisplay/dashboard-display.service';
import { Subscription } from 'rxjs';
import { DashboardLayoutClass } from '@class/dashboardLayout.class';
import { AuthService } from '@services/auth/auth.service';
import { LanguageType } from '@models/language.model';

@Component({
  selector: 'app-dashboard-filters',
  templateUrl: './dashboard-filters.component.html',
  styleUrls: ['./dashboard-filters.component.css']
})
export class DashboardFiltersComponent implements OnInit, OnDestroy {

  @Input() dashboardLayout: DashboardLayoutClass;

  widgetLayers: WidgetLayersClass
  pivots: PivotClass[] = [];
  indicators: WidgetIndicatorClass[] = [];
  loading: boolean;
  current: UID;
  widgetsLayers$sub: Subscription;
  partials$sub: Subscription[];

  language: LanguageType;
  language$sub: Subscription;

  ngOnDestroy() {
    this.language$sub.unsubscribe();
  }

  colors = ItemsColors(true);
  icons = ItemsIcons();

  constructor(
    private $pivots: PivotService,
    private $display: DashboardDisplayService,
    private $auth: AuthService
  ) {
    this.loading = true;
    this.partials$sub = [];

    this.language$sub = this.$auth.language$.subscribe({
      next: (language: LanguageType) => {
        this.language = language;
      }
    });
  }

  ngOnInit() {
    this.widgetsLayers$sub = this.$display.widgetLayers$(this.dashboardLayout.uid.value).subscribe({
      next: (widgetLayers: WidgetLayersClass) => {

        if(!!widgetLayers) {
          this.partials$sub.forEach(p => p.unsubscribe());
          this.partials$sub = [];
          this.widgetLayers = widgetLayers;
          this.pivots = [];
          this.indicators = [];

          widgetLayers.indicators.forEach((indicator: WidgetIndicatorClass) => {
            if(this.indicators.map(i => i.uid.value).indexOf(indicator.uid.value) === -1) {
              this.indicators.push(indicator);
            }
          });
          widgetLayers.pivots.forEach((uid: UID) => {
            let uids = [];
            if(uids.indexOf(uid) === -1) {
              uids.push(uid);
              this.partials$sub.push(this.$pivots.getPartial$(uid).pipe(take(1)).subscribe((p: PivotClass) => {
                if(!!p) {
                  this.pivots.push(p);
                  this.loading = false;
                  this.pivots.sort((a, b) => {
                    if(a.labels.value(this.language) < b.labels.value(this.language)) { return -1; }
                    if(a.labels.value(this.language) > b.labels.value(this.language)) { return 1; }
                    return 0;
                  });
                }
              }));
            }
          });
        }
      }
    });
  }

  onEdit(uid: UID) {
    this.current = this.current === uid ? undefined : uid;
  }

  receiveSelect(uid: UID) {
    this.onEdit(uid);
  }

}