<app-theme-option [categories]="themeOptions.optionsConfiguration[optionType].categories" [label]="themeOptions.optionsConfiguration[optionType].label" [limitedTo]="themeOptions.limitedTo(optionType)">
  <div app-theme-option-content>
    <app-show-details-option [showDetails]="showDetails" (sendShowDetails)="receiveShowDetails($event)">
      <div app-show-details-option-content>
        <app-theme-option-font-style [fontStyle]="themeOptions.axes(axesXY, axesIndex).ticks.fontStyle" (sendFontStyle)="receiveValue($event)"></app-theme-option-font-style>
      </div>
    </app-show-details-option>
  </div>
</app-theme-option>

<div *ngIf="showDetails">
  <app-theme-option [categories]="themeOptions.optionsConfiguration[optionType].categoriesAndLabel" [label]="type | chart" [dark]="4" *ngFor="let type of themeOptions.limitedTo(optionType)">
    <div app-theme-option-content>
      <app-theme-option-font-style [fontStyle]="themeOptions.axes(axesXY, axesIndex, type).ticks.fontStyle" (sendFontStyle)="receiveValue($event, type)"></app-theme-option-font-style>
    </div>
  </app-theme-option>
</div>