<div mdbModal #filtersModal="mdbModal" class="modal fade left" tabindex="-1" role="dialog"
[config]="{ backdrop: false, ignoreBackdropClick: true }" style="overflow-y: auto; max-width: 420px;">
  <div class="modal-dialog modal-left modal-full-height" role="document">
    <div class="modal-content" *ngIf="filtersModal.isShown">
      <div class="modal-header">

        <button type="button" size="sm" mdbBtn color="secondary" class="waves-light z-depth-0 px-2 m-0 app-close-modal" (click)="filtersModal.hide()" mdbWavesEffect>
          <i class="fas fa-times fa-fw"></i>
        </button>

        <h5 class="font-weight-bolder blue-grey-text modal-title w-100">{{ 'FILTERS' | translate }}</h5>
        
      </div>
      <div class="modal-body">

        <button (click)="onRemoveFilters()" type="button" block="true" mdbBtn color="light" class="z-depth-0 blue-grey-text mt-1 d-flex px-4 align-items-center" mdbWavesEffect>
          <div>{{ 'CLEAR_SELECTION' | translate }}</div><i class="ml-auto fas fa-eraser text-warning"></i>
        </button>

        <button (click)="onBookmark()" type="button" block="true" mdbBtn color="light" class="z-depth-0 blue-grey-text mt-1 d-flex px-4 align-items-center" mdbWavesEffect>
          <div>{{ 'FAVORITES' | translate }}</div><i class="ml-auto {{ onlyBookmarks ? 'fas purple-text' : 'fal blue-grey-text' }} fa-bookmark"></i>
        </button>

        <hr>

        <app-input [label]="'LABEL'" [color]="'blue-grey'" [value]="searchLabel" (sendValue)="receiveTextValue($event)"></app-input>

        <hr>

        <app-tags-links-item [uid]="uid" *ngFor="let uid of tagsList" [isSelected]="isSelectedTags(uid)" (sendSelected)="receiveSelectedTag($event)"></app-tags-links-item>

      </div>
    </div>
  </div>
</div>