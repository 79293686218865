/**
 * @module Widget
 */

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { WidgetLayersClass } from '@class/widgetLayers.class';
import { ItemColor } from '@functions/item.functions';

@Component({
  selector: 'app-widget-indicators-remove',
  templateUrl: './widget-indicators-remove.component.html',
  styleUrls: ['./widget-indicators-remove.component.css']
})
export class WidgetIndicatorsRemoveComponent {
  
  @Input() widgetLayers: WidgetLayersClass;
  @Input() current: number;
  @Output() sendRemove: EventEmitter<void>;

  color = ItemColor('indicators');

  constructor() {
    this.sendRemove = new EventEmitter<void>();
  }

  onRemove() {
    this.widgetLayers.removeIndicator(this.current);
    this.sendRemove.emit();
  }

}
