/**
 * @module Indicator
 */

import { Injectable, EventEmitter } from '@angular/core';
import { BackendGroupbyService } from '@services/backend/backendGroupby/backend-groupby.service';
import { UID } from '@models/uid.model';
import { IndicatorClass } from '@class/indicator.class';
import { PivotFiltersClass } from '@class/widgetFilters.class';
import { WidgetDatesBoundsClass } from '@class/widgetDatesBounds.class';
import { DateToIso } from '@functions/moment.functions';
import { ServerResponseModel } from '@models/server.model';

@Injectable({
  providedIn: 'root'
})
export class IndicatorQueryService {

  private indicator: IndicatorClass;
  private result: EventEmitter<{ groups: { [key: string]: string }, values: number, count: number }[]>;

  constructor(
    private $groupby: BackendGroupbyService,
  ) {
    this.result = new EventEmitter<{ groups: { [key: string]: string }, values: number, count: number }[]>();
  }

  queryResult$(indicator: IndicatorClass): EventEmitter<{ groups: { [key: string]: string }, values: number, count: number }[]> {
    this.indicator = indicator;
    return this.result;
  };

  update(groups: UID[], filters: PivotFiltersClass[], bounds: WidgetDatesBoundsClass[]) {
      
    let match = [];
    let _groups = {};

    groups.forEach((uid: UID) => {
      _groups[uid] = `$${uid}`;
    });

    filters.forEach((_filters: PivotFiltersClass) => {
      if(_filters.values.length > 0) {
        if(_filters.include) {
          match.push({ [_filters.uid.value]: { $in : _filters.values } });
        }
        else {
          match.push({ [_filters.uid.value]: { $not: { $in : _filters.values } } });
        }
      }
    });

    bounds.forEach((_bounds: WidgetDatesBoundsClass) => {
      if(_bounds.has('greater')) {
        const bound = _bounds.greater;
        match.push({ [_bounds.uid.value]: { [bound.equivalent ? '$gte' : '$gt']: DateToIso(bound.value) } });
      }
      if(_bounds.has('less')) {
        const bound = _bounds.less;
        match.push({ [_bounds.uid.value]: { [bound.equivalent ? '$lte' : '$lt']: DateToIso(bound.value) } });
      }
    });

    this.$groupby.get(this.indicator.uid.value, this.indicator.serverLink, _groups, match).then((reponse: ServerResponseModel) => {
      this.result.emit(reponse.message.values);
    });
  }

  delete(filters: PivotFiltersClass[], bounds: WidgetDatesBoundsClass[]): Promise<ServerResponseModel> {
      
    let match = {};

    filters.forEach((_filters: PivotFiltersClass) => {
      if(_filters.values.length > 0) {
        if(_filters.include) {
          match[_filters.uid.value] = { $in : _filters.values };
        }
        else {
          match[_filters.uid.value] = { $not: { $in : _filters.values } };
        }
      }
    });

    bounds.forEach((_bounds: WidgetDatesBoundsClass) => {
      if(_bounds.has('greater')) {
        const bound = _bounds.greater;
        match[_bounds.uid.value] = { [bound.equivalent ? '$gte' : '$gt']: DateToIso(bound.value) };
      }
      if(_bounds.has('less')) {
        const bound = _bounds.less;
        match[_bounds.uid.value] = { [bound.equivalent ? '$lte' : '$lt']: DateToIso(bound.value) };
      }
    });

    return this.$groupby.delete(this.indicator.uid.value, this.indicator.serverLink, match);
  }
}
