/**
 * @module Widget
 */

import { Component, Input, OnDestroy, Output, EventEmitter, ElementRef } from '@angular/core';
import { WidgetLayersClass } from '@class/widgetLayers.class';
import { ChartDataSets } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { WidgetChartParamsModel } from '@models/widgetLayers.model';
import { utils, writeFile, BookType } from 'xlsx';
import { TranslateService } from '@ngx-translate/core';
import { LanguageType } from '@models/language.model';
import { Subscription } from 'rxjs';
import { AuthService } from '@services/auth/auth.service';
import html2canvas from 'html2canvas';
import { ItemColor } from '@functions/item.functions';

@Component({
  selector: 'app-widget-options-menu',
  templateUrl: './widget-options-menu.component.html',
  styleUrls: ['./widget-options-menu.component.css']
})
export class WidgetOptionsMenuComponent implements OnDestroy {

  @Input() widgetLayers: WidgetLayersClass;
  @Input() editorIndex: number;
  @Input() chart: BaseChartDirective;
  @Input() params: WidgetChartParamsModel;
  @Input() capture: ElementRef;
  @Output() sendEditorIndex: EventEmitter<number>;
  @Output() seeChartChange: EventEmitter<boolean>;
  @Input() seeChart: boolean;
  @Input() valuesTab: any;

  editorIndexMax: number;
  language: LanguageType;
  language$sub: Subscription;
  color = ItemColor('widgets');

  constructor(
    private $translate: TranslateService,
    private $auth: AuthService
  ) {
    this.language$sub = this.$auth.language$.subscribe({
      next: (language: LanguageType) => {
        this.language = language;
      }
    });
    this.editorIndex = 0;
    this.editorIndexMax = 4;
    this.sendEditorIndex = new EventEmitter<number>();
    this.seeChartChange = new EventEmitter<boolean>();
  }

  ngOnDestroy() {
    this.language$sub.unsubscribe();
  }

  receiveNext(isNext: boolean) {
    this.editorIndex = this.editorIndex + (isNext ? 1 : -1);
    if(this.editorIndex < 0) this.editorIndex = this.editorIndexMax;
    if(this.editorIndex > this.editorIndexMax) this.editorIndex = 0;
    this.sendEditorIndex.emit(this.editorIndex);
  }  

  onSeeSpreadsheet() {
    this.seeChartChange.emit(false);
  }

  onSeeChart() {
    this.seeChartChange.emit(true);
  }

  receiveGotoFiltersAndDates() {
    this.editorIndex = 2;
  }

  onDownloadImage() {
    if(this.widgetLayers.layer(this.widgetLayers.drillUID).chartType !== 'sticker' && this.widgetLayers.layer(this.widgetLayers.drillUID).chartType !== 'gauge') {
      let link = document.createElement('a');

      link.href = this.chart.toBase64Image();
      link.setAttribute('visibility','hidden');
      link.download = `${this.params.options.title.textInline}_${this.widgetLayers.uid.value}.png`;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    else {
      html2canvas(this.capture.nativeElement, { allowTaint: true }).then((canvas: HTMLCanvasElement) => {
        var link = document.createElement('a');
        let uri = canvas.toDataURL();

        if (typeof link.download === 'string') {
          link.href = uri;
          link.download = this.params.options.title.textInline;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } 
        else {
          window.open(uri);
        }
      });
    }
  }

  receiveFileType(fileType: BookType) {
    let workbook = utils.book_new();
    let rows = [];
    if(this.seeChart) {
      this.params.datasets.forEach((dataset: ChartDataSets) => {
        let row = {};
        dataset.labels.forEach((label: string, index: number) => {
          rows.push({
            [this.$translate.instant('INDICATOR')]: dataset.label,
            [this.widgetLayers.layer(this.widgetLayers.drillUID).layerLabel.value(this.language)]: label,
            valeur: dataset.data[index]
          });
        });
      });
    }
    else {
      rows = this.valuesTab;
    }
   
    utils.book_append_sheet(workbook, utils.json_to_sheet(rows), this.params.options.title.textInline.substring(0, 29));
    writeFile(workbook, `${this.params.options.title.textInline.substring(0, 29)}.${fileType}`, { bookType: fileType });
    
  }

}
