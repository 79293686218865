/**
 * @module Tag
 */

import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { TagService } from '@services/tag/tag.service';
import { UID } from '@models/uid.model';
import { Subscription } from 'rxjs';
import { TagClass } from '@class/tag.class';
import { AuthService } from '@services/auth/auth.service';
import { LanguageType } from '@models/language.model';

@Component({
  selector: 'app-tags-icons-item',
  templateUrl: './tags-icons-item.component.html',
  styleUrls: ['./tags-icons-item.component.css']
})
export class TagsIconsItemComponent implements OnInit, OnDestroy {

  @Input() uid: UID;
  @Input() isFirst: boolean = false;

  tag: TagClass;
  tag$sub: Subscription;
  language: LanguageType;
  language$sub: Subscription;

  constructor(
    private $tags: TagService,
    private $auth: AuthService
  ) {
    this.language$sub = this.$auth.language$.subscribe({
      next: (language: LanguageType) => {
        this.language = language;
      }
    });
  }

  ngOnInit() {
    this.tag$sub = this.$tags.getPartial$(this.uid).subscribe({
      next: (tag: TagClass) => {
        this.tag = tag;
      }
    });
  }

  ngOnDestroy() {
    this.tag$sub.unsubscribe();
    this.language$sub.unsubscribe();
  }

}
