/**
 * @module Theme
 */

import { Component, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { ItemColor, ItemIcon } from '@functions/item.functions';
import { ThemeClass } from '@class/theme.class';
import { ThemeService } from '@services/theme/theme.service';
import { Subscription } from 'rxjs';
import { UID } from '@models/uid.model';
import { LanguageType } from '@models/language.model';
import { AuthService } from '@services/auth/auth.service';

@Component({
  selector: 'app-themes-links-change',
  templateUrl: './themes-links-change.component.html',
  styleUrls: ['./themes-links-change.component.css']
})
export class ThemesLinksChangeComponent implements OnDestroy {

  @Input() uidTheme: UID;
  @Output() sendSelected: EventEmitter<UID>;

  color = ItemColor('themes');
  icon = ItemIcon('themes');

  themes$sub: Subscription;
  list: ThemeClass[];
  searchLabel: string;
  language: LanguageType;
  language$sub: Subscription;

  constructor(
    private $themes: ThemeService,
    private $auth: AuthService
  ) {
    this.sendSelected = new EventEmitter<UID>();
    this.themes$sub = this.themes$sub = this.$themes.listPartials$().subscribe({
      next: (themes: ThemeClass[]) => {
        this.list = themes;
      }
    });

    this.language$sub = this.$auth.language$.subscribe({
      next: (language: LanguageType) => {
        this.language = language;
      }
    });
  }

  receiveTextValue(searchLabel: string) {
    this.searchLabel = searchLabel;
  }

  ngOnDestroy() {
    this.themes$sub.unsubscribe();
  }

  receiveSelected(item: { uid: UID, selected: boolean }) {
    if(item.uid !== this.uidTheme)  {
      this.sendSelected.emit(item.uid);
    }
  }

}
