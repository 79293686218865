/**
 * @module Pivot
 */

import { Component, ViewChild, OnDestroy } from '@angular/core';
import { ItemColor, ItemIcon, ItemLabel } from '@functions/item.functions';
import { PivotClass } from '@class/pivot.class';
import { LanguageType } from '@models/language.model';
import { Subscription } from 'rxjs';
import { PivotService } from '@services/pivot/pivot.service';
import { AuthService } from '@services/auth/auth.service';
import { UID } from '@models/uid.model';
import { BrowserFrameComponent } from '@components/browser/browser-frame/browser-frame.component';
import { Router } from '@angular/router';
import { PivotEditorComponent } from '@components/pivots/pivot-editor/pivot-editor.component';

@Component({
  selector: 'app-pivots',
  templateUrl: './pivots.component.html',
  styleUrls: ['./pivots.component.css']
})
export class PivotsComponent implements OnDestroy {

  @ViewChild('editor', { static: true }) editor: PivotEditorComponent;
  @ViewChild('frameModal', { static: true }) frameModal: BrowserFrameComponent;

  label = ItemLabel('pivots');
  color = ItemColor('pivots');
  icon = ItemIcon('pivots');
  current: PivotClass;
  language: LanguageType;
  language$sub: Subscription;
  pivots: PivotClass[];
  pivots$sub: Subscription;
  searchLabel: string;

  constructor(
    private $pivots: PivotService,
    private $auth: AuthService,
    private $router: Router
  ) {

    this.language = this.$auth.language$.value;
    this.language$sub = this.$auth.language$.subscribe({
      next: (language: LanguageType) => {
        this.language = language;
      }
    });
    
    this.pivots$sub = this.$pivots.list$().subscribe({
      next: (pivots: PivotClass[]) => {
        this.pivots = pivots.sort((a : PivotClass, b: PivotClass) => { return a.labels.value(this.language).localeCompare(b.labels.value(this.language)) });
      }
    });

    this.current = this.$pivots.create();

  }

  ngOnDestroy() {
    this.pivots$sub.unsubscribe();
    this.language$sub.unsubscribe();
  }

  public show() {
    this.frameModal.show();
  }

  trackByUid(index: number, pivot: PivotClass) {
    return pivot.uid.value;
  }

  receiveImport() {
    this.$router.navigate([`/import-studio`]);
  }

  /*receiveCreate() {
    if(this.current.uid.isRegistered) this.current = this.$pivots.create();
    this.editor.show();
  }*/

  receiveEdit(uid: UID) {
    this.current = this.pivots.filter((pivot: PivotClass) => {
      return pivot.uid.value === uid;
    })[0];
    this.editor.show();
  }

  receiveSearch(searchLabel: string) {
    this.searchLabel = searchLabel;
  }

}
