/**
 * @module Widget
 */

import { Component, Input } from '@angular/core';
import { WidgetLayersClass } from '@class/widgetLayers.class';
import { ItemColor } from '@functions/item.functions';
import { ChartsMixedTypes } from '@functions/chart.functions';
import { ChartMixedType } from '@models/chart.model';

@Component({
  selector: 'app-widget-indicators-type',
  templateUrl: './widget-indicators-type.component.html',
  styleUrls: ['./widget-indicators-type.component.css']
})
export class WidgetIndicatorsTypeComponent {

  @Input() widgetLayers: WidgetLayersClass;
  @Input() current: number;

  color = ItemColor('indicators');
  values: ChartMixedType[];

  constructor() {
    this.values = ChartsMixedTypes();
  }

  onValue(value: ChartMixedType) {
    this.widgetLayers.indicators[this.current].change({ type: value });
  }

}
