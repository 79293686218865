<app-editor #editor [isSaveable]="current?.uid.isRegistered" (sendRestore)="receiveRestore()" (sendDelete)="receiveDelete()" (sendSave)="receiveSave()"
  [title]="current?.labels.value(language)" [uid]="current?.uid.value" [icon]="icon" [color]="color" [isRegistered]="current?.uid.isRegistered" [isValid]="current?.isValid()" [isModified]="current?.isModified">
  <div app-editor-content-menu>

    <a class="dropdown-item z-depth-0" (click)="editorIndex = 0">{{ 'SETTINGS' | translate }}</a>
    <a class="dropdown-item z-depth-0" (click)="editorIndex = 1">{{ 'LINKED_COLUMNS' | translate }}</a>
    <a class="dropdown-item z-depth-0" (click)="editorIndex = 2">{{ 'DATE_FORMATS' | translate }}</a>
    <a class="dropdown-item z-depth-0" (click)="editorIndex = 3">{{ 'TAGS' | translate }}</a>

  </div>
  <div app-editor-content-body *ngIf="editor.isShown && current != undefined">
    <div class="alert alert-info font-small text-center" role="alert" *ngIf="!current.uid.isRegistered">
      {{ 'RECORDED_SAME_TIME/TEXT' | translate }}
    </div>
    <div [ngSwitch]="editorIndex">
      <div *ngSwitchCase="0">
        <app-editor-section-title [title]="'SETTINGS'" (sendNext)="receiveNext($event)"></app-editor-section-title>
        <app-labels [labels]="current.labels" [color]="color"></app-labels>
        <app-descriptions [descriptions]="current.descriptions" [color]="color"></app-descriptions>
      </div>
      <div *ngSwitchCase="1">
        <app-editor-section-title [title]="'LINKED_COLUMNS'" (sendNext)="receiveNext($event)"></app-editor-section-title>
        <app-linked-columns [linkedColumns]="current.linkedColumns"></app-linked-columns>
      </div>
      <div *ngSwitchCase="2">
        <app-editor-section-title [title]="'DATE_FORMATS'" (sendNext)="receiveNext($event)"></app-editor-section-title>
        <app-date-formats [dateFormats]="current.dateFormats"></app-date-formats>
      </div>
      <div *ngSwitchCase="3">
        <app-editor-section-title [title]="'TAGS'" (sendNext)="receiveNext($event)"></app-editor-section-title>
        <app-tags-links-list [tags]="current.tags"></app-tags-links-list>
      </div>
      <div *ngSwitchDefault>
        <app-not-deletable></app-not-deletable>
      </div>
    </div>
  </div>

</app-editor>