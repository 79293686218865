/**
 * @module Color
 */

import { BrightnessType, ColorStockModel } from '@models/color.model';

export function Brightness(color: string): BrightnessType {
  //source: https://awik.io/determine-color-bright-dark-using-javascript/

  // Variables for red, green, blue values
  let r: any, g: any, b: any, hsp: any;
  
  // Check the format of the color, HEX or RGB?
  if(color.match(/^rgb/)) {
    // If HEX --> store the red, green, blue values in separate variables
    const matchs = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
    
    r = matchs[1];
    g = matchs[2];
    b = matchs[3];
  } 
  else {
    // If RGB --> Convert it to HEX: http://gist.github.com/983661
    const hexa = +("0x" + color.slice(1).replace( 
    color.length < 5 && /./g, '$&$&'));

    r = hexa >> 16;
    g = hexa >> 8 & 255;
    b = hexa & 255;
  }
  
  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  hsp = Math.sqrt(
    0.299 * (r * r) +
    0.587 * (g * g) +
    0.114 * (b * b)
  );

  // Using the HSP value, determine whether the color is light or dark
  if(hsp > 175) {
    return 'light';
  } 
  else {
    return 'dark';
  }
}