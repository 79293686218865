<div class="d-flex flex-column align-items-center rounded blue-grey lighten-{{ dark }} p-3 mb-1">
  
  <div class="d-flex align-items-center w-100">

    <div class="flex-grow-1 blue-grey-text font-weight-bolder font-small d-flex flex-column">
      <div>{{ label | uppercase | translate }}</div>
      <div class="font-extra-small app-import-option-category">
        <span *ngFor="let category of categories ; let isLast = last">{{ category | translate | lowercase }}<span *ngIf="!isLast">&nbsp;-&nbsp;</span></span>
        <span *ngIf="information">&nbsp;-&nbsp;{{ information | translate | lowercase }}</span>
      </div>
    </div>

    <!-- CONTENT -->
    <ng-content select="[app-import-option-content]"></ng-content>
    <!-- ./CONTENT -->

  </div>

  <div *ngIf="sublabel != undefined" class="font-very-small font-weight-bolder rounded d-flex align-items-center w-100 mt-2 px-2 app-import-option-subcontent">
    <div class="blue-grey-text mr-auto">{{ sublabel | translate }}<span *ngIf="subinformation">&nbsp;-&nbsp;{{ subinformation | translate | lowercase }}</span></div>
    <!-- SUBCONTENT -->
    <ng-content select="[app-import-option-subcontent]" class="d-block w-100"></ng-content>
    <!-- ./SUBCONTENT -->
  </div>

</div>