/**
 * @module Widget
 */

import { Component, Input, OnInit } from '@angular/core';
import { WidgetStudioService } from '@services/widgetStudio/widget-studio.service';
import { UID } from '@models/uid.model';
import { ItemColor } from '@functions/item.functions';

@Component({
  selector: 'app-widget-indicators-duplicate',
  templateUrl: './widget-indicators-duplicate.component.html',
  styleUrls: ['./widget-indicators-duplicate.component.css']
})
export class WidgetIndicatorsDuplicateComponent implements OnInit {

  @Input() widgetUID: UID;

  autoDuplicate: boolean;
  color = ItemColor('indicators');

  constructor(
    private $studio: WidgetStudioService
  ) {}

  ngOnInit() {
    this.autoDuplicate = this.$studio.autoDuplicate(this.widgetUID);
  }

  receiveValue(autoDuplicate: boolean) {
    this.autoDuplicate = this.$studio.autoDuplicate(this.widgetUID, autoDuplicate);
  }

}
