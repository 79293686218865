/**
 * @module User
 */

import { Pipe, PipeTransform } from '@angular/core';
import { UserIdClass } from '@class/user.class';

@Pipe({
  name: 'searchUser'
})
export class SearchUserPipe implements PipeTransform {

  transform(items: { id: UserIdClass }[], searchLabel: string): any {
    if(!items) {
      return [];
    }
    if(!searchLabel || searchLabel.length === 0) {
      return items;
    }
    const _searchLabel = searchLabel.toLowerCase();
    return items.filter((item: { id: UserIdClass }) => {
      return item.id.firstname.toLowerCase().includes(_searchLabel)
      || item.id.lastname.toLowerCase().includes(_searchLabel);
    });
  }

}