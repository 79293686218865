/**
 * @module Theme
 */

import { Component, Input, OnInit } from '@angular/core';
import { ItemColor } from '@functions/item.functions';
import { ThemeOptionsClass } from '@class/themeOptions.class';
import { ThemeOptionType } from '@models/themeStudio.model';

@Component({
  selector: 'app-theme-option-elements-point-hover-border-color',
  templateUrl: './theme-option-elements-point-hover-border-color.component.html',
  styleUrls: ['./theme-option-elements-point-hover-border-color.component.css']
})
export class ThemeOptionElementsPointHoverBorderColorComponent implements OnInit {

  @Input() themeOptions: ThemeOptionsClass;
  @Input() serieIndex: number;

  showDetails: boolean;
  color = ItemColor('themes');
  opacity: number;
  hoverBorderColor: string;
  optionType: ThemeOptionType = 'elements_point_hoverBorderColor';

  constructor() {
    this.showDetails = false;
  }

  receiveShowDetails(showDetails: boolean) {
    this.showDetails = showDetails;
  }

  ngOnInit() {
    this.hoverBorderColor = this.themeOptions.series(this.serieIndex).pointHoverBorderColor.toString().slice(0, 7);
    this.opacity = Math.round(parseInt(this.themeOptions.series(this.serieIndex).pointHoverBorderColor.toString().slice(7, 9), 16) * 100 / 255);
  }
  
  receiveValue(value: string) {
    this.hoverBorderColor = value;
    this.themeOptions.setSeries(this.optionType, value + Math.round(this.opacity * 255 / 100).toString(16), this.serieIndex);
  }

  receiveOpacity(value: number) {
    this.opacity = value;
    if(this.opacity > 0) {
      this.themeOptions.setSeries(this.optionType, this.hoverBorderColor + Math.round(value * 255 / 100).toString(16), this.serieIndex);
    }
    else {
      this.themeOptions.setSeries(this.optionType, this.hoverBorderColor + '00', this.serieIndex);
    }
  }

}
