/**
 * @module Tag
 */

import { Component, ViewChild, OnDestroy } from '@angular/core';
import { EditorComponent } from '@components/forms/editor/editor.component';
import { ItemColor, ItemIcon, ItemLabel } from '@functions/item.functions';
import { TagClass } from '@class/tag.class';
import { LanguageType } from '@models/language.model';
import { Subscription } from 'rxjs';
import { TagService } from '@services/tag/tag.service';
import { AuthService } from '@services/auth/auth.service';
import { MessagesService } from '@services/messages/messages.service';
import { UID } from '@models/uid.model';
import { BrowserFrameComponent } from '@components/browser/browser-frame/browser-frame.component';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.css']
})
export class TagsComponent implements OnDestroy {

  @ViewChild('editor', { static: true }) editor: EditorComponent;
  @ViewChild('frameModal', { static: true }) frameModal: BrowserFrameComponent;

  label = ItemLabel('tags');
  color = ItemColor('tags');
  icon = ItemIcon('tags');
  current: TagClass;
  language: LanguageType;
  language$sub: Subscription;
  editorIndex: number;
  editorIndexMax: number;
  tags: TagClass[];
  tags$sub: Subscription;
  searchLabel: string;

  constructor(
    private $tags: TagService,
    private $auth: AuthService,
    private $messages: MessagesService
  ) {

    this.language = this.$auth.language$.value;
    this.language$sub = this.$auth.language$.subscribe({
      next: (language: LanguageType) => {
        this.language = language;
      }
    });
    
    if(this.tags$sub) this.tags$sub.unsubscribe();
    this.tags$sub = this.$tags.list$().subscribe({
      next: (tags: TagClass[]) => {
        this.tags = tags.sort((a : TagClass, b: TagClass) => { return a.labels.value(this.language).localeCompare(b.labels.value(this.language)) });
      }
    });

    this.current = this.$tags.create();
    this.editorIndex = 0;
    this.editorIndexMax = 2;

  }

  ngOnDestroy() {
    this.language$sub.unsubscribe();
    this.tags$sub.unsubscribe();
  }

  public show() {
    this.frameModal.show();
  }

  trackByUid(index: number, tag: TagClass) {
    return tag.uid.value;
  }

  receiveCreate() {
    if(this.current.uid.isRegistered) this.current = this.$tags.create();
    this.editor.show();
  }

  receiveEdit(uid: UID) {
    this.current = this.tags.filter((tag: TagClass) => {
      return tag.uid.value === uid;
    })[0];
    this.editor.show();
  }

  receiveSearch(searchLabel: string) {
    this.searchLabel = searchLabel;
  }

  receiveRestore() {
    this.current.reset();
  }

  receiveDelete() {
    this.editorIndex = NaN;
  }

  receiveDeleteConfirmation() {
    this.$tags.remove(this.current.uid.value)
    .then(() => {
      this.editor.hide();
      this.editorIndex = 0;
      this.$messages.success({ 
        title: { plain: this.current.labels.value(this.language) }, 
        text: { code: 'SUCCESSFULLY_REMOVED/TEXT' } 
      });
    })
    .catch((error) => {
      this.$messages.error({ 
        title: { plain: this.current.labels.value(this.language) }, 
        text: { 
          code: 'REMOVE_FAILED/TEXT',
          params: { error: error.code }
        } 
      });
    });
  }

  receiveNext(isNext: boolean) {
    this.editorIndex = this.editorIndex + (isNext ? 1 : -1);
    if(this.editorIndex < 0) this.editorIndex = this.editorIndexMax;
    if(this.editorIndex > this.editorIndexMax) this.editorIndex = 0;
  }  
  
  receiveSave() {
    this.$tags.set(this.current)
    .then(() => {
      if(!this.current.uid.isRegistered) this.current = this.$tags.create();
      this.editor.hide();
      this.$messages.success({ 
        title: { plain: this.current.labels.value(this.language) }, 
        text: { code: 'SUCCESSFULLY_SAVED/TEXT' } 
      });
    })
    .catch((error) => {
      this.$messages.error({ 
        title: { plain: this.current.labels.value(this.language) }, 
        text: { 
          code: 'SAVE_FAILED/TEXT',
          params: { error: error.code }
        } 
      });
    });
  }

}
