/**
 * @module Right
 */

import { Injectable } from '@angular/core';
import { RightClass } from '@class/right.class';
import { Observable, BehaviorSubject } from 'rxjs';
import { RightModel } from '@models/right.model';
import { map} from 'rxjs/operators';
import { FirestoreService } from '@services/firestore/firestore.service';
import { AuthService } from '@services/auth/auth.service';
import { FirebaseService } from '@services/firebase/firebase.service';
import { UidService } from '@services/uid/uid.service';
import { ItemType } from '@models/item.model';
import { LinksModelConstructor } from '@functions/link.functions';

@Injectable({
  providedIn: 'root'
})
export class RightService extends FirestoreService<RightModel> {

  /**
   * @description type de document manipulé par le service
   * @protected
   * @type {ItemType}
   * @memberof RightService
   */
  protected _itemType: ItemType = 'rights';

  /**
   * @description true si l'accès à ce type de document peut nécessiter des habilitations
   * @protected
   * @type {boolean}
   * @memberof RightService
   */
  protected _restricted: boolean = true;

  /**
   * @description Creates an instance of RightService
   * @param {UidService} $uid
   * @param {AuthService} $auth
   * @param {FirebaseService} $firebase
   * @memberof RightService
   */
  constructor(
    private $uid: UidService,
    protected $auth: AuthService,
    protected $firebase: FirebaseService
  ) {
    super($firebase, $auth);
  }

  /**
   * @description Observateur de la liste des objects autorisés pour l'utilisateur courant
   * @param {boolean} [forceFullCollection=false]
   * @returns {BehaviorSubject<RightClass[]>}
   * @memberof RightService
   */
  list$(forceFullCollection: boolean = false): BehaviorSubject<RightClass[]> {
    if(forceFullCollection) {
      return super.full$().pipe(
        map((rights: RightModel[]) => {
          if(!!rights) {
            let ds = rights.map<RightClass>((right: RightModel) => {
              return new RightClass(right, true);
            });
            return ds;
          }
        })
      ) as BehaviorSubject<RightClass[]>;
    }
    else {
      return super.docs$().pipe(
        map((rights: RightModel[]) => {
          if(!!rights) {
            let ds = rights.map<RightClass>((right: RightModel) => {
              return new RightClass(right, true);
            });
            return ds;
          }
        })
      ) as BehaviorSubject<RightClass[]>;
    }
  }

  /**
   * @description Observateur d'un document dont l'uid est passé en paramètre
   * @param {string} uid
   * @returns {Observable<RightClass>}
   * @memberof RightService
   */
  get$(uid: string): Observable<RightClass> {
    return super.doc$(uid).pipe(
      map((right: RightModel) => {
        return new RightClass(right, true);
      })
    );
  }

  /**
   * @description Observateur de la liste des objects (données partielles) autorisés pour l'utilisateur courant
   * @param {boolean} [forceFullCollection=false]
   * @returns {Observable<RightClass[]>}
   * @memberof RightService
   */
  listPartials$(forceFullCollection: boolean = false): Observable<RightClass[]> {
    if(forceFullCollection) {
      return super.fullPartial$().pipe(
        map((rights: RightModel[]) => {
          if(!!rights) {
            let ds = rights.map<RightClass>((right: RightModel) => {
              return new RightClass(right, true);
            });
            return ds;
          }
        })
      );
    }
    else {
      return super.partials$().pipe(
        map((rights: RightModel[]) => {
          if(!!rights) {
            let ds = rights.map<RightClass>((right: RightModel) => {
              return new RightClass(right, true);
            });
            return ds;
          }
        })
      );
    }
  }

  /**
   * @description Observateur d'un document (données partielles) dont l'uid est passé en paramètre
   * @param {string} uid
   * @returns {Observable<RightClass>}
   * @memberof RightService
   */
  getPartial$(uid: string): Observable<RightClass> {
    return super.partial$(uid).pipe(
      map((right: RightModel) => {
        if(right !== null) return new RightClass(right, true);
      })
    );
  }

  /**
   * @description Créé un nouveau document avec les valeurs par défaut
   * @returns {RightClass}
   * @memberof RightService
   */
  create(): RightClass {
    return new RightClass({ 
      uid: this.$uid.create(),
      links: LinksModelConstructor().links,
      logs: {},
      tags: {}
    } as RightModel, false);
  }

  /**
   * @description Créé ou met à jour le document passé en paramètre
   * @param {RightClass} right
   * @returns {Promise<void>}
   * @memberof RightService
   */
  async set(right: RightClass): Promise<void> {
    return super.update(right.uid.value, right.model);
  }

  /**
   * @description Supprime le document passé en paramètre
   * @param {string} uid
   * @returns {Promise<void>}
   * @memberof RightService
   */
  async remove(uid: string): Promise<void> {
    return super.delete(uid);
  }

}
