<div class="d-flex align-items-center justify-content-between mt-3" style="height: 31px;">
  <div class="blue-grey-text font-weight-bold d-flex align-items-center">
    <i class="fa-lg fa-fw far fa-pencil-paintbrush {{color}}-text mr-1"></i>
    <div class="mx-1">{{ 'LAYOUT' | translate }}</div>
  </div>
  <!--div class="btn-group" mdbDropdown>
    <button mdbDropdownToggle type="button" size="sm" mdbBtn color="light" class="blue-grey-text z-depth-0 dropdown-toggle waves-light d-flex align-items-center px-3" mdbWavesEffect>
      <i class="far fa-bars"></i>&nbsp;
    </button>
  
    <div class="dropdown-menu dropdown-dark dropdown-menu-right">

      <a class="dropdown-item z-depth-0" (click)="editorIndex = 0">{{ 'SETTINGS' | translate }}</a>

    </div>
  </div-->
</div>

<hr>

<div [ngSwitch]="editorIndex">
  <div *ngSwitchCase="0">
    <!--app-editor-section-title [title]="'SETTINGS'" (sendNext)="receiveNext($event)"></app-editor-section-title-->

    <app-theme-option-layout-padding [themeOptions]="themeOptions"></app-theme-option-layout-padding>
    <app-theme-option-layout-background-color [themeOptions]="themeOptions"></app-theme-option-layout-background-color>

  </div>
</div>