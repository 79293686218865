/**
 * @module Theme
 */

import { Component, Input } from '@angular/core';
import { ThemeOptionsClass } from '@class/themeOptions.class';
import { ThemeStudioCurrentModel } from '@models/themeStudio.model';

@Component({
  selector: 'app-theme-options',
  templateUrl: './theme-options.component.html',
  styleUrls: ['./theme-options.component.css']
})
export class ThemeOptionsComponent {

  @Input() current: ThemeStudioCurrentModel;
  @Input() themeOptions: ThemeOptionsClass;

  constructor() {}

}
