/**
 * @module Log
 */

import { LogsModel, LogModel } from '@models/logs.model';
import { MapClass } from '@class/map.class';
import { UID } from '@models/uid.model';
import { Moment, DateApplyFormat, DateFromNow } from '@functions/moment.functions';
import { DeprecatedDurations } from '@functions/log.functions';
import { ModificationClass, ModificationStateClass } from '@class/modification.class';
import { DeepCopy } from '@functions/copy.functions';

export class LogClass {

  private _model: LogModel;

  constructor(private log: LogModel) {
    this._model = log;
  }

  get class(): Readonly<string> {
    return 'log';
  }

  get params(): Readonly<{ [key: string]: string | number }> {
    return this._model.params;
  }

  get isCritical(): Readonly<boolean> {
    return this._model.operation === 'd' || this._model.operation === 'r';
  }

  get description(): Readonly<string> {
    return this._model.description;
  }

  date(local: boolean = true): Readonly<string> {
    return local ? DateApplyFormat(this._model.date): DateFromNow(this._model.date);
  }

  get isRecent(): Readonly<boolean> {
    return Moment()().subtract(DeprecatedDurations().recent, 'days').isBefore(Moment()(this._model.date));
  }

  get color(): Readonly<string> {
    return this.isCritical ? 'warning' : 'info';
  }

  get icon(): Readonly<string> {
    switch(this._model.operation) {
      case 'c': return 'plus';
      case 'd': return 'times';
      case 'r': return 'shied-alt';
      case 'u': return 'sliders-h';
    }
  }

  get user(): Readonly<string> {
    return `${this._model.firstname} ${this._model.lastname}`;
  }

  get email(): Readonly<string> {
    return this._model.email;
  }

}

export class LogsClass extends ModificationClass<LogsModel> {

  protected _parent: LogsModel;
  protected _inital: LogsModel;
  protected _attributes: string[];

  private _logs: MapClass<LogClass>;

  constructor(logs: LogsModel, state: ModificationStateClass) {

    super();
    
    this._parent = logs;
    this._attributes = this.attributes;
    this._state = state;

    this._parent = logs;

    const logsClass = {} as { [key in UID]: LogClass };
    for(const uid in this._parent.logs || {}) {
      logsClass[uid] = new LogClass(this._parent.logs[uid]);
    }
    this._logs = new MapClass(logsClass);

    this._inital = DeepCopy({ logs: this._parent.logs });

  }

  get class(): Readonly<string> {
    return 'logs';
  }

  get attributes(): string[] {
    return ['logs'];
  }

  list(recent?: boolean, critical?: boolean): Readonly<LogClass[]> {

    let logs = this._logs.values.filter((log: LogClass) => {
      if(recent === true) return log.isRecent;
      else if(recent === false) return !log.isRecent;
      else return true;
    }).filter((log: LogClass) => {
      if(critical === true) return log.isCritical;
      else if(critical === false) return !log.isCritical;
      else return true;
    }) as LogClass[];

    return logs.sort((a: LogClass, b: LogClass) => {
      if(a.date(false) > b.date(false)) { return -1; }
      if(a.date(false) < b.date(false)) { return 1; }
      return 0;
    });
  }

  lastModification(local: boolean = true): Readonly<string> {
    const logs = this.list();
    return logs.length == 0 ? undefined : local ? logs[0].date(false) : logs[0].date();
  }

}