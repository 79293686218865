<span *ngFor="let language of languages">
  <div class="md-form md-outline mb-0 mt-3" *ngIf="current == language">
    <textarea [ngModel]="descriptions.value(language)" (ngModelChange)="onDescription($event, language)" id="form-description" class="form-control textarea form-{{color}}" rows="6" type="text" mdbInput style="resize:none;"></textarea>
    <label for="form-description">{{ label | translate }}</label>
    <small id="form-description-help" class="form-text text-muted d-flex align-items-center">
      <i *ngIf="descriptions.isValid('descriptions@' + language)" class="{{color}}-text fas fa-check-circle pr-1"></i>
      <i *ngIf="!descriptions.isValid('descriptions@' + language)" class="text-warning fas fa-times-circle pr-1"></i>
      <span class="mr-auto">{{descriptions.value(language).length}}/{{descriptions.requirement('descriptions')['maxLength']}} ({{ 'MINIMUM' | translate }}: {{descriptions.requirement('descriptions')['minLength']}})</span>
      <a *ngFor="let lg of languages">
        <mdb-badge (click)="current = lg" *ngIf="lg != language" color="grey" class="text-uppercase z-depth-0 px-1 mx-1">{{lg}}</mdb-badge>
        <mdb-badge *ngIf="lg == language" [color]="color" class="text-uppercase z-depth-0 px-1 mx-1">{{lg}}</mdb-badge>
      </a>
    </small>
  </div>
</span>