<app-theme-option [categories]="themeOptions.optionsConfiguration[optionType].categories" [label]="themeOptions.optionsConfiguration[optionType].label">
  <div app-theme-option-content>
    <app-show-details-option [showDetails]="showDetails" (sendShowDetails)="receiveShowDetails($event)">
      <div app-show-details-option-content>
        <button *ngFor="let p of positions[axesXY] ; let i = index" mdbBtn (click)="receiveValue(p)" type="button" color="light" size="sm" class="px-2 z-depth-0 my-0 ml-1 mr-0" mdbWavesEffect>
          <i class="fas fa-{{icons[axesXY][i]}} fa-fw {{ p == themeOptions.axes(axesXY, axesIndex).position ? color : 'blue-grey' }}-text"></i>
        </button>
      </div>
    </app-show-details-option>
  </div>
</app-theme-option>

<div *ngIf="showDetails">
  <app-theme-option [categories]="themeOptions.optionsConfiguration[optionType].categoriesAndLabel" [label]="type | chart" [dark]="4" *ngFor="let type of themeOptions.limitedTo(optionType)">
    <div app-theme-option-content>
      <button *ngFor="let p of positions[axesXY] ; let i = index" mdbBtn (click)="receiveValue(p, type)" type="button" color="light" size="sm" class="px-2 z-depth-0 my-0 ml-1 mr-0" mdbWavesEffect>
        <i class="fas fa-{{icons[axesXY][i]}} fa-fw {{ p == themeOptions.axes(axesXY, axesIndex, type).position ? color : 'blue-grey' }}-text"></i>
      </button>
    </div>
  </app-theme-option>
</div>