/**
 * @module Server
 */

import { Injectable } from '@angular/core';
import { ServerMetaModel } from '@models/server.model';
import { HttpClient } from '@angular/common/http';
import { ServerClass } from '@class/server.class';
import { throwError } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { TokenService } from '@services/token/token.service';
import { HandleErrors } from '@functions/errors.functions';
import { AuthService } from '@services/auth/auth.service';
import { HttpOptions } from '@functions/server.functions';

@Injectable({
  providedIn: 'root'
})
export class MetasService {

 /**
   * @description Liste des métadonnées par serveur
   * @private
   * @type {{ [key: string]: ServerMetaModel }}
   * @memberof ServersApiMetaService
   */
  private metas: { [key: string]: ServerMetaModel };

  /**
   * @description Creates an instance of ServersApiMetaService
   * @param {ServersApiService} $token
   * @param {HttpClient} $http
   * @memberof ServersApiMetaService
   */
  constructor(
    private $token: TokenService,
    private $auth: AuthService,
    private $http: HttpClient
  ) {
    this.metas = {};
  }

  /**
   * @description renvoi un observateur des métadonnées du serveur en paramètre, les métadonnées sont renvoyées sont celle présente en cache, si disponibles, sauf si un forceRefresh est demandé
   * @param {ServerClass} server
   * @param {boolean} forceRefresh
   * @returns {BehaviorSubject<ServerMetaModel>}
   * @memberof ServersApiService
   */
  async getMeta(server: ServerClass, forceRefresh: boolean = false): Promise<ServerMetaModel> {
    if(this.metas[server.uid.value] !== undefined && !forceRefresh) {
      return new Promise<ServerMetaModel>((resolve, reject) => {
        resolve(this.metas[server.uid.value]);
      });
    }
    else {
      let tokenData = await this.$token.get({ uid: server.uid.value, url: server.url }).catch((error) => { 
        return throwError(error).toPromise();
      });
      if(tokenData) {
        return this.$http.get<ServerMetaModel>(`https://${server.url}/metas`, HttpOptions(this.$auth.token$.value.token, tokenData.token))
        .pipe(
          take(1),
          tap((meta: ServerMetaModel) => {
            this.metas[server.uid.value] = meta;
          }),
          HandleErrors()
        ).toPromise();
      }
    }
  }
}
