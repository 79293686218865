<app-browser-panel (sendCreate)="receiveCreate()" [role]="'d'" (sendBookmark)="receiveBookmark()" (sendSearch)="receiveSearch()" [color]="color" [loading]="dashboards == undefined" [empty]="dashboards?.length == 0"
  [title]="'LETS_BEGIN'" [text]="'LETS_BEGIN_DASHBOARD'" [color]="color" [action]="'NEW'" [icon]="'far fa-plus'" (sendAction)="receiveCreate()">
  
  <div app-browser-panel-content-list *ngFor="let dashboard of dashboards | bookmarks:bookmarks | tags:tags | searchLabels:searchLabel:language ; trackBy: trackByUid">
    <app-item-tile class="d-sm-block d-none"
    [labels]="dashboard.labels"
    [rights]="dashboard.rights"
    [tags]="dashboard.tags"
    [type]="'dashboards'"
    [uid]="dashboard.uid"
    [icon]="dashboard.icon.code"
    [color]="dashboard.color.hexa"
    [isModified]="dashboard.modifications$.value.isModified"
    [isInfo]="dashboard.descriptions.value(language).length > 0 || dashboard.tags.list.length > 0"
    (sendEdit)="receiveEdit($event)"
    (sendDisplay)="receiveDisplay($event)"
    (sendSlides)="receiveSlides($event)"
    (sendInfo)="receiveInfo($event)"
    (sendStudio)="receiveStudio($event)"
    [isSlides]="true"
    [isDisplayable]="true"
    [isStudio]="true"
    ></app-item-tile>

    <app-item-strip class="d-block d-sm-none"
    [showInfoAndBookmark]="true"
    [labels]="dashboard.labels"
    [type]="'dashboards'"
    [uid]="dashboard.uid"
    [tags]="dashboard.tags"
    [icon]="dashboard.icon.code"
    [color]="dashboard.color.hexa"
    [isModified]="dashboard.modifications$.value.isModified"
    [isInfo]="dashboard.descriptions.value(language).length > 0 || dashboard.tags.list.length > 0"
    (sendEdit)="receiveEdit($event)"
    (sendDisplay)="receiveDisplay($event)"
    (sendSlides)="receiveSlides($event)"
    (sendInfo)="receiveInfo($event)"
    (sendStudio)="receiveStudio($event)"
    [isSlides]="true"
    [isDisplayable]="true"
    [isStudio]="true"
    ></app-item-strip>
  </div>

  <div app-browser-panel-content-fixed-button>
    <a *ngIf="hasFilters" (click)="onRemoveFilters()" mdbBtn floating="true" color="warning" class="waves-light" mdbWavesEffect><i class="fad fa-search-minus"></i></a>
  </div>
</app-browser-panel>

<app-dashboard-editor #editor [current]="current"></app-dashboard-editor>

<app-item-info #info
[title]="current.labels.value(language)"
[icon]="current.icon.code"
[color]="color"
[iconColor]="current.color.hexa" 
[descriptions]="current.descriptions"
[tags]="current.tags"
></app-item-info>

<app-browser-filters #filtersModal [tagsList]="tagsList" [onlyBookmarks]="onlyBookmarks" [searchLabel]="searchLabel" (sendSearchLabel)="receiveSearchLabel($event)" (sendBookmark)="receiveBookmark($event)" (sendTags)="receiveTags($event)"></app-browser-filters>