/**
 * @module Theme
 */

import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ItemColor } from '@functions/item.functions';
import { ThemeOptionsClass } from '@class/themeOptions.class';
import { ChartType } from '@models/chart.model';
import { Subscription } from 'rxjs';
import { ThemeOptionType } from '@models/themeStudio.model';

@Component({
  selector: 'app-theme-option-elements-hover-border-dash',
  templateUrl: './theme-option-elements-hover-border-dash.component.html',
  styleUrls: ['./theme-option-elements-hover-border-dash.component.css']
})
export class ThemeOptionElementsHoverBorderDashComponent implements OnInit, OnDestroy {

  @Input() themeOptions: ThemeOptionsClass;
  @Input() serieIndex: number;

  showDetails: boolean;
  color = ItemColor('themes');
  optionType: ThemeOptionType = 'elements_hoverBorderDash';
  label: string;
  labels: { [key: string]: string };
  changedSeries$sub: Subscription;

  constructor() {
    this.showDetails = false;
    this.labels = {};
  }

  ngOnInit() {
    this.setAttributes();

    this.changedSeries$sub = this.themeOptions.changedSeries$.subscribe({
      next:(option: ThemeOptionType) => {
        if(option === this.optionType) {
          this.setAttributes();
        }
      }
    });
  }

  setAttributes() {
    this.labels = {};
    this.themeOptions.limitedTo(this.optionType).forEach((type: ChartType) => {
      this.labels[type] = this.values[this.themeOptions.series(this.serieIndex, type)['hoverBorderDash'].toString()].label;
    });
    this.label = this.labels['line'];
  }

  ngOnDestroy() {
    this.changedSeries$sub.unsubscribe();
  }

  onValue(value: string, type?: ChartType) {
    this.themeOptions.setSeries(this.optionType, value === '' ? [] : value.split(','), this.serieIndex, type ? [type] : undefined);
  }

  receiveShowDetails(showDetails: boolean) {
    this.showDetails = showDetails;
  }

  values = {
    '10,10': { style: '10,10', label: '10-10' },
    '1,1': { style: '1,1', label: '1-1' },
    '20,5': { style: '20,5', label: '20-5' },
    '12,3,3': { style: '15,3,3', label: '12-3-3' },
    '15,3,3,3': { style: '15,3,3,3', label: '15-3-3-3' },
    '20,3,3,3,3,3,3,3': { style: '20,3,3,3,3,3,3,3', label: '20,3,3,3,3,3,3,3' },
    '': { icon: 'times', style: '', label: 'WITHOUT' }
  };

}
