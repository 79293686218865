/**
 * @module Theme
 */

import { Component, Input } from '@angular/core';
import { ItemColor } from '@functions/item.functions';
import { ThemeOptionsClass } from '@class/themeOptions.class';
import { ThemeOptionType } from '@models/themeStudio.model';

@Component({
  selector: 'app-theme-option-tooltips-body-align',
  templateUrl: './theme-option-tooltips-body-align.component.html',
  styleUrls: ['./theme-option-tooltips-body-align.component.css']
})
export class ThemeOptionTooltipsBodyAlignComponent {

  @Input() themeOptions: ThemeOptionsClass;

  color = ItemColor('themes');
  optionType: ThemeOptionType = 'tooltips_bodyAlign';
  values = ['left', 'center', 'right'];

  constructor() {}

  onValue(value: string) {
    this.themeOptions.setOption('tooltips_bodyAlign', value);
  }

}
