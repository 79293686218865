/**
 * @module Date
 */

import { Component, ViewChild, OnDestroy } from '@angular/core';
import { EditorComponent } from '@components/forms/editor/editor.component';
import { ItemColor, ItemIcon, ItemLabel } from '@functions/item.functions';
import { DateClass } from '@class/date.class';
import { LanguageType } from '@models/language.model';
import { Subscription } from 'rxjs';
import { DateService } from '@services/date/date.service';
import { AuthService } from '@services/auth/auth.service';
import { UID } from '@models/uid.model';
import { BrowserFrameComponent } from '@components/browser/browser-frame/browser-frame.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dates',
  templateUrl: './dates.component.html',
  styleUrls: ['./dates.component.css']
})
export class DatesComponent implements OnDestroy {

  @ViewChild('editor', { static: true }) editor: EditorComponent;
  @ViewChild('frameModal', { static: true }) frameModal: BrowserFrameComponent;

  label = ItemLabel('dates');
  color = ItemColor('dates');
  icon = ItemIcon('dates');
  current: DateClass;
  language: LanguageType;
  language$sub: Subscription;
  dates: DateClass[];
  dates$sub: Subscription;
  searchLabel: string;

  constructor(
    private $dates: DateService,
    private $auth: AuthService,
    private $router: Router
  ) {

    this.language = this.$auth.language$.value;
    this.language$sub = this.$auth.language$.subscribe({
      next: (language: LanguageType) => {
        this.language = language;
      }
    });
    
    this.dates$sub = this.$dates.list$().subscribe({
      next: (dates: DateClass[]) => {
        this.dates = dates.sort((a : DateClass, b: DateClass) => { return a.labels.value(this.language).localeCompare(b.labels.value(this.language)) });
      }
    });

    this.current = this.$dates.create();

  }

  ngOnDestroy() {
    this.dates$sub.unsubscribe();
    this.language$sub.unsubscribe();
  }

  public show() {
    this.frameModal.show();
  }

  trackByUid(index: number, date: DateClass) {
    return date.uid.value;
  }

  receiveImport() {
    this.$router.navigate([`/import-studio`]);
  }

  /*receiveCreate() {
    if(this.current.uid.isRegistered) this.current = this.$dates.create();
    this.editor.show();
  }*/

  receiveEdit(uid: UID) {
    this.current = this.dates.filter((date: DateClass) => {
      return date.uid.value === uid;
    })[0];
    this.editor.show();
  }

  receiveSearch(searchLabel: string) {
    this.searchLabel = searchLabel;
  }

}
