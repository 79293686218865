/**
 * @module Dashboard
 */

import { Injectable } from '@angular/core';
import { DashboardLayoutClass } from '@class/dashboardLayout.class';
import { UID } from '@models/uid.model';
import { LayoutDefault } from '@functions/dashboard.functions';
import { DashboardClass } from '@class/dashboard.class';

@Injectable({
  providedIn: 'root'
})
export class DashboardStudioService {

  private _layout: { [key in UID]: DashboardLayoutClass } = {};

  constructor() {}

  dashboardLayout(uid: UID, dashboardLayout?: DashboardLayoutClass, dashboard?: DashboardClass, forceUpdate?: boolean): DashboardLayoutClass {
    if(this._layout[uid] === undefined) {
      //on a ouvert un dashboard non précédement ouvert dans cette session dans le studio
      if(!!dashboardLayout && !!dashboardLayout.model) this._layout[uid] = dashboardLayout;
      else this._layout[uid] = new DashboardLayoutClass(LayoutDefault(dashboard));
    }
    else if(forceUpdate === true) {
      this._layout[uid].reset(dashboardLayout.model, this._layout[uid].width);
    }
    return this._layout[uid];
  }

}