<app-numbro-option [label]="label">
  <div app-numbro-option-content>
    <div class="btn-group" mdbDropdown>
      <a mdbDropdownToggle type="button" mdbBtn size="sm" color="light" class="dropdown-toggle waves-light z-depth-0 px-3 dropdown-light" mdbWavesEffect>
        <span class="{{color}}-text font-weight-bolder">{{ type | uppercase | translate }}</span>&nbsp;
      </a>
      <div class="dropdown-menu dropdown-dark dropdown-menu-right font-small app-drop-small-limit-5">
        <a *ngFor="let fmt of formats" class="dropdown-item z-depth-0" (click)="onFormat(fmt)">{{ fmt | uppercase | translate }}</a>
      </div>
    </div>
  </div>
</app-numbro-option>

<hr>

<div class="d-flex justify-content-between align-items-center p-2 my-1 font-small">
  <mdb-badge info="true" class="z-depth-0 p-2">{{ referencePositive }}</mdb-badge>
  <i class="fad fa-angle-double-right mx-3"></i>
  <mdb-badge info="true" class="z-depth-0 p-2">{{ formatedReferencePositive }}</mdb-badge>
</div>
<div class="d-flex justify-content-between align-items-center p-2 mb-1 font-small">
  <mdb-badge info="true" class="z-depth-0 p-2">{{ referenceNegative }}</mdb-badge>
  <i class="fad fa-angle-double-right mx-3"></i>
  <mdb-badge info="true" class="z-depth-0 p-2">{{ formatedReferenceNegative }}</mdb-badge>
</div>

<div *ngIf="type == 'personalized'">
  <hr>
  <app-numbro-option [label]="'OUTPUT'">
    <div app-numbro-option-content>
      <div class="btn-group" mdbDropdown>
        <a mdbDropdownToggle type="button" mdbBtn size="sm" color="light" class="dropdown-toggle waves-light z-depth-0 px-3 dropdown-light" mdbWavesEffect>
          <span class="{{color}}-text font-weight-bolder">{{ format.output | uppercase | translate }}</span>&nbsp;
        </a>
        <div class="dropdown-menu dropdown-dark dropdown-menu-right font-small app-drop-small-limit-5">
          <a *ngFor="let output of outputs" class="dropdown-item z-depth-0" (click)="receiveOutput(output)">{{ output | uppercase | translate }}</a>
        </div>
      </div>
    </div>
  </app-numbro-option>

  <app-numbro-option [label]="'MANTISSA'" [sublabel]="format.mantissa > 0 ? 'TRIM_MANTISSA' : null">
    <div app-numbro-option-content>
      <app-number [value]="format.mantissa" (sendValue)="receiveMantissa($event)" [color]="color" [min]="0" [max]="20" [step]="2"></app-number>
    </div>
    <div app-numbro-option-subcontent>
      <app-check [color]="color" [value]="format.trimMantissa" (sendValue)="receiveTrimMantissa($event)"></app-check>
    </div>
  </app-numbro-option>

  <app-numbro-option [label]="'FORCE_SIGN'">
    <div app-numbro-option-content>
      <app-check [color]="color" [value]="format.forceSign" (sendValue)="receiveForceSign($event)"></app-check>
    </div>
  </app-numbro-option>

  <app-numbro-option [label]="'NEGATIVE'">
    <div app-numbro-option-content>
      <div class="btn-group" mdbDropdown>
        <a mdbDropdownToggle type="button" mdbBtn size="sm" color="light" class="dropdown-toggle waves-light z-depth-0 px-3 dropdown-light" mdbWavesEffect>
          <span class="{{color}}-text font-weight-bolder">{{ format.negative | uppercase | translate }}</span>&nbsp;
        </a>
        <div class="dropdown-menu dropdown-dark dropdown-menu-right font-small app-drop-small-limit-5">
          <a *ngFor="let negative of negatives" class="dropdown-item z-depth-0" (click)="receiveNegative(negative)">{{ negative | uppercase | translate }}</a>
        </div>
      </div>
    </div>
  </app-numbro-option>

  <app-numbro-option [label]="'THOUSAND_SEPARATED'">
    <div app-numbro-option-content>
      <app-check [color]="color" [value]="format.thousandSeparated" (sendValue)="receiveThousandSeparated($event)"></app-check>
    </div>
  </app-numbro-option>

  <app-numbro-option [label]="'THOUSAND_ROUNDED'">
    <div app-numbro-option-content>
      <div class="btn-group" mdbDropdown>
        <a mdbDropdownToggle type="button" mdbBtn size="sm" color="light" class="dropdown-toggle waves-light z-depth-0 px-3 dropdown-light" mdbWavesEffect>
          <span class="{{color}}-text font-weight-bolder">{{ average | uppercase | translate }}</span>&nbsp;
        </a>
        <div class="dropdown-menu dropdown-dark dropdown-menu-right font-small app-drop-small-limit-5">
          <a *ngFor="let average of averages" class="dropdown-item z-depth-0" (click)="receiveAverage(average)">{{ average | uppercase | translate }}</a>
        </div>
      </div>
    </div>
  </app-numbro-option>

  <app-numbro-option [label]="'SPACE_SEPARATED'" *ngIf="average != 'inactive' || format.output == 'currency'">
    <div app-numbro-option-content>
      <app-check [color]="color" [value]="format.spaceSeparated" (sendValue)="receiveSpaceSeparated($event)"></app-check>
    </div>
  </app-numbro-option>

  <app-input *ngIf="format.output == 'currency'" [color]="color" [label]="'CURRENCY_SYMBOL'" [value]="format.currencySymbol" [maxLength]="10" [minLength]="0" (sendValue)="receiveCurrencySymbol($event)"></app-input>

  <app-numbro-option *ngIf="format.output == 'currency'" [label]="'CURRENCY_POSITION'">
    <div app-numbro-option-content>
      <div class="btn-group" mdbDropdown>
        <a mdbDropdownToggle type="button" mdbBtn size="sm" color="light" class="dropdown-toggle waves-light z-depth-0 px-3 dropdown-light" mdbWavesEffect>
          <span class="{{color}}-text font-weight-bolder">{{ format.currencyPosition | uppercase | translate }}</span>&nbsp;
        </a>
        <div class="dropdown-menu dropdown-dark dropdown-menu-right font-small app-drop-small-limit-5">
          <a *ngFor="let currencyPosition of currencyPositions" class="dropdown-item z-depth-0" (click)="receiveCurrencyPosition(currencyPosition)">{{ currencyPosition | uppercase | translate }}</a>
        </div>
      </div>
    </div>
  </app-numbro-option>

  <app-input [color]="color" [label]="'PREFIX'" [value]="format.prefix" [maxLength]="10" [minLength]="0" (sendValue)="receivePrefix($event)"></app-input>

  <app-input [color]="color" [label]="'POSTFIX'" [value]="format.postfix" [maxLength]="10" [minLength]="0" (sendValue)="receivePostfix($event)"></app-input>

</div>