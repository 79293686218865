/**
 * @module Role
 */

import { Component, Input } from '@angular/core';
import { RolesClass } from '@class/roles.class';
import { RolesCodes, RolesItemsLabels, RolesItemsIcons, RolesItemsColors } from '@functions/role.functions';
import { RoleType } from '@models/role.model';
import { UID } from '@models/uid.model';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.css']
})
export class RolesComponent {

  @Input() roles: RolesClass;

  list = RolesCodes().filter((role: RoleType) => { return role !== 't'; });
  colors = RolesItemsColors();
  icons = RolesItemsIcons();
  labels = RolesItemsLabels();

  constructor() {}

  receiveSelect(item: { uid: UID, selected: boolean }, role: RoleType) {
    if(this.roles.has(role)) this.roles.remove(role);
    else this.roles.add(role);
  }
}
