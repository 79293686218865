/**
 * @module Company
 */

import { Component } from '@angular/core';
import { CompanyService } from '@services/company/company.service';
import { Router } from '@angular/router';
import { GetSubDomainFromHost } from '@functions/company.functions';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.css']
})
export class CompanyComponent {

  code: string;
  label: string;

  constructor(
    private $company: CompanyService,
    private $route: Router
  ) {
    this.label = GetSubDomainFromHost();
    const code = this.$company.getCodeFromSubdomain(this.label);
    if(code !== undefined) {
      this.code = code
      this.$company.code = code;
      this.$route.navigate(['/']);
    }
    else {
      this.label = '';
    }
  }

  /**
   * @description: déclencheur en cas de changement dans la saisie de la company pour mise à jour
   * @param {string} company
   * @memberof AuthComponent
   */
  onCompany(label: string) {
    this.label = label;
    const code = this.$company.getCodeFromLabel(label);
    if(code !== undefined) {
      this.code = code
      this.$company.code = code;
      this.$route.navigate(['/']);
    }
  }

}
