<div mdbModal #frameModal="mdbModal" class="modal fade left" style="overflow-y: scroll" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-full-height modal-left" role="document">
    <div class="modal-content">
      <div class="modal-header d-flex align-items-center flex-column">

        <i class="animated rotateIn {{color}}-text far fa-{{icon}} fa-4x d-none d-sm-inline"></i>
        <i class="animated rotateIn {{color}}-text far fa-{{icon}} fa-2x d-sm-none"></i>

        <h6 class="modal-title w-100 text-center">
          <div class="font-weight-bold text-elegant mt-1">{{ label | translate }}</div>
        </h6>

      </div>
      <div class="modal-header d-flex align-items-center">

        <div class="mr-auto">
          <app-input *ngIf="isSearch" [marginBottom]="false" [marginTop]="false" [label]="'SEARCH'" [color]="color" [value]="search" (sendValue)="receiveTextValue($event)"></app-input>
        </div>

        <button type="button" size="sm" mdbBtn color="secondary" class="waves-light z-depth-0 px-2 m-0 app-close-modal" [ngClass]="{ 'mr-0': !isCreate }" (click)="frameModal.hide()" mdbWavesEffect>
          <i class="fas fa-times fa-fw"></i>
        </button>

        <button *ngIf="isCreate" type="button" mdbBtn [color]="color" class="waves-light z-depth-0 px-sm-4 px-3 my-0 mx-0" (click)="onCreate()" mdbWavesEffect>
          <i class="d-sm-none far fa-plus fa-fw"></i>
          <i class="d-none d-sm-inline far fa-plus"></i>
          <span class="d-none d-sm-inline ml-1">{{ 'NEW' | translate }}</span>
        </button>

      </div>
      <div class="modal-body overflow-hidden">
        
        <!-- CONTENT OPTIONS -->
        <div>
          <ng-content select="[app-browser-frame-content-options]"></ng-content>
        </div>
        <!-- ./CONTENT OPTIONS -->
        
        <!-- CONTENT LIST -->
        <div>
          <ng-content select="[app-browser-frame-content-list]"></ng-content>
        </div>
        <!-- ./CONTENT LIST -->
  
      </div>
    </div>
  </div>
</div>