<div class="d-flex align-items-center justify-content-between mt-3" style="height: 31px;">
  <div class="blue-grey-text font-weight-bold d-flex align-items-center">
    <i class="fa-lg fa-fw far fa-pencil-paintbrush {{color}}-text mr-1"></i>
    <div class="mx-1">{{ 'SPECIFIC' | translate }}</div>
  </div>
  <div class="btn-group" mdbDropdown>
    <button mdbDropdownToggle type="button" size="sm" mdbBtn color="light" class="blue-grey-text z-depth-0 dropdown-toggle waves-light d-flex align-items-center px-3" mdbWavesEffect>
      <i class="far fa-bars"></i>&nbsp;
    </button>
  
    <div class="dropdown-menu dropdown-dark dropdown-menu-right">

      <a class="dropdown-item z-depth-0" (click)="editorIndex = 0">{{ 'PIE/CHART' | translate }}</a>
      <a class="dropdown-item z-depth-0" (click)="editorIndex = 1">{{ 'GAUGE/CHART' | translate }}</a>

    </div>
  </div>
</div>

<hr>

<div [ngSwitch]="editorIndex">
  <div *ngSwitchCase="0">
    <app-editor-section-title [title]="'PIE/CHART'" (sendNext)="receiveNext($event)"></app-editor-section-title>

    <app-theme-option-elements-chart-cutout-percentage [themeOptions]="themeOptions"></app-theme-option-elements-chart-cutout-percentage>
    <app-theme-option-elements-chart-circumference [themeOptions]="themeOptions"></app-theme-option-elements-chart-circumference>
    <app-theme-option-elements-chart-rotation [themeOptions]="themeOptions"></app-theme-option-elements-chart-rotation>
    <!-- MARCHE PAS à voir coté MDB-Angular // app-theme-option-elements-chart-transition-animation [themeOptions]="themeOptions"></app-theme-option-elements-chart-transition-animation-->

  </div>
  <div *ngSwitchCase="1">
    <app-editor-section-title [title]="'GAUGE/CHART'" (sendNext)="receiveNext($event)"></app-editor-section-title>

    <app-theme-option-elements-chart-gauge-color [themeOptions]="themeOptions"></app-theme-option-elements-chart-gauge-color>
    <app-theme-option-elements-chart-gauge-width-scale [themeOptions]="themeOptions"></app-theme-option-elements-chart-gauge-width-scale>
    <hr>
    <app-theme-option-elements-chart-gauge-pointer-display [themeOptions]="themeOptions"></app-theme-option-elements-chart-gauge-pointer-display>
    <app-theme-option-elements-chart-gauge-pointer-color [themeOptions]="themeOptions"></app-theme-option-elements-chart-gauge-pointer-color>
    <app-theme-option-elements-chart-gauge-pointer-stroke-width [themeOptions]="themeOptions"></app-theme-option-elements-chart-gauge-pointer-stroke-width>
    <app-theme-option-elements-chart-gauge-pointer-stroke [themeOptions]="themeOptions"></app-theme-option-elements-chart-gauge-pointer-stroke>
    <app-theme-option-elements-chart-gauge-pointer-stroke-line-cap [themeOptions]="themeOptions"></app-theme-option-elements-chart-gauge-pointer-stroke-line-cap>
    <app-theme-option-elements-chart-gauge-pointer-top-length [themeOptions]="themeOptions"></app-theme-option-elements-chart-gauge-pointer-top-length>
    <app-theme-option-elements-chart-gauge-pointer-bottom-length [themeOptions]="themeOptions"></app-theme-option-elements-chart-gauge-pointer-bottom-length>
    <app-theme-option-elements-chart-gauge-pointer-bottom-width [themeOptions]="themeOptions"></app-theme-option-elements-chart-gauge-pointer-bottom-width>
    <!--hr> MARCHE PAS à priori ne vient pas de JustGage (la librairie de la gauge)
    <app-theme-option-elements-chart-gauge-shadow-show-inner [themeOptions]="themeOptions"></app-theme-option-elements-chart-gauge-shadow-show-inner>
    <app-theme-option-elements-chart-gauge-shadow-opacity [themeOptions]="themeOptions"></app-theme-option-elements-chart-gauge-shadow-opacity>
    <app-theme-option-elements-chart-gauge-shadow-size [themeOptions]="themeOptions"></app-theme-option-elements-chart-gauge-shadow-size>
    <app-theme-option-elements-chart-gauge-shadow-vertical-offset [themeOptions]="themeOptions"></app-theme-option-elements-chart-gauge-shadow-vertical-offset-->

  </div>
</div>