<div class="d-flex">
  <app-tags-icons-item *ngFor="let uid of this.tags.list | slice:0:(this.tags.list.length > limit ? limit - 1 : limit) ; let isFirst = first" class="d-block app-tags-icons-list-item" [uid]="uid" [isFirst]="isFirst"></app-tags-icons-item>
  <div *ngIf="this.tags.list.length > limit" id="tagsTemplate" triggers="focus" [mdbPopover]="tagsTemplate" mdbWavesEffect
    class="app-cursor-pointer app-tags-icons-list-limit app-box-shadow-depth-1 rounded-circle d-flex align-items-center justify-content-center elegant-color animated fadeInLeft">
    <i class="far fa-ellipsis-h white-text"></i>
  </div>
</div>

<ng-template #tagsTemplate>
  <div class="d-flex p-3">
    <app-tags-icons-item *ngFor="let uid of this.tags.list | slice:limit-1:this.tags.list.length ; let isFirst = first" class="d-block app-tags-icons-list-item" [uid]="uid" [isFirst]="isFirst"></app-tags-icons-item>
  </div>
</ng-template>
