/**
 * @module Server
 */

import { Component, OnInit, Input } from '@angular/core';
import { ServerClass } from '@class/server.class';
import { MetasService } from '@services/metas/metas.service';
import { ServerMetaModel } from '@models/server.model';
import { ItemColor } from '@functions/item.functions';
import { CityCountry } from '@functions/metas.functions';

@Component({
  selector: 'app-metas',
  templateUrl: './metas.component.html',
  styleUrls: ['./metas.component.css']
})
export class MetasComponent implements OnInit {

  @Input() server: ServerClass

  color = ItemColor('servers');
  metas: ServerMetaModel;
  loading: boolean;
  memory: number;
  country: string;
  city: string;

  constructor(
    private $metas: MetasService
  ) {
    this.loading = true;
  }

  ngOnInit() {
    this.$metas.getMeta(this.server)
    .then((metas: ServerMetaModel) => {
      this.metas = metas;
      this.memory = Math.round(metas.memory / 1000);
      this.country = CityCountry(metas.region);
      this.city = metas.region.slice(0, metas.region.length - 2);
      this.loading = false;
    })
    .catch(error => {
      //TODO
      console.log(error);
    });
  }

}
