/**
 * @module Dashboard
 */

import { Component, Input } from '@angular/core';
import { DashboardLayoutClass } from '@class/dashboardLayout.class';
import { ItemColor } from '@functions/item.functions';

@Component({
  selector: 'app-dashboard-layout-size',
  templateUrl: './dashboard-layout-size.component.html',
  styleUrls: ['./dashboard-layout-size.component.css']
})
export class DashboardLayoutSizeComponent {

  @Input() dashboardLayout: DashboardLayoutClass;

  color = ItemColor('dashboards');
  
  constructor() {}

  receiveValue(size: number) {
    this.dashboardLayout.change({ size: size });
  }

}
