/**
 * @module Widget
 */

import { Component, OnInit, Input, OnDestroy, ViewChild, OnChanges, SimpleChanges, ElementRef } from '@angular/core';
import { WidgetLayersClass } from '@class/widgetLayers.class';
import { Subscription } from 'rxjs';
import { Label, BaseChartDirective } from 'ng2-charts';
import { ChartDataSets } from 'chart.js';
import { WidgetQueryService } from '@providers/widgetQuery/widget-query.service';
import { LanguageType } from '@models/language.model';
import { AuthService } from '@services/auth/auth.service';
import { WidgetChartParamsModel } from '@models/widgetLayers.model';
import { WidgetDatalabelsTableModel } from '@models/widgetDatalabelsTable.model';
import { ModificationStateClass } from '@class/modification.class';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { DeepCopy } from '@functions/copy.functions';
import { SortableStopModel, SortableSortModel } from '@directives/sortable/sortable.directive';
import { UID } from '@models/uid.model';
import { CreateRandomUid } from '@functions/uid.functions';
import { CommentsClass } from '@class/comment.class';
import { DashboardDisplayService } from '@services/dashboardDisplay/dashboard-display.service';
import html2canvas from 'html2canvas';
import { CommentService } from '@services/comment/comment.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-widget-studio-chart',
  templateUrl: './widget-studio-chart.component.html',
  styleUrls: ['./widget-studio-chart.component.css'],
  providers: [WidgetQueryService]
})
export class WidgetStudioChartComponent implements OnInit, OnDestroy, OnChanges {

  @Input() dashboardUID: UID;
  @Input() height: number;
  @Input() width: number;
  @Input() resizeRatio: number;
  @Input() widgetLayers: WidgetLayersClass;
  @ViewChild('chart', { static: false }) chart: BaseChartDirective;
  @ViewChild('fluidModal', { static: true }) fluidModal: ModalDirective;
  @ViewChild('sideModal', { static: true }) sideModal: ModalDirective;
  @ViewChild('capture') capture : ElementRef;

  query$sub: Subscription;
  params: WidgetChartParamsModel;
  fullscreenParams: WidgetChartParamsModel;
  table: WidgetDatalabelsTableModel[];

  language: LanguageType;
  language$sub: Subscription;
  modifications$sub: Subscription;
  comments$sub: Subscription;
  loading: boolean;
  isMoreOptions: boolean;
  page: number;
  pages: { page: number, uid: UID }[];
  result: { labels: Label[], series: ChartDataSets[] };
  ctx: any;
  editorIndex: number;
  hoverPage: number;
  comments: CommentsClass;
  seeChart: boolean = true;
  tabLabels: any = {};
  valuesTab: any[];
  
  constructor(
    private element: ElementRef,
    private $query: WidgetQueryService,
    private $auth: AuthService,
    private $dashboardDisplay: DashboardDisplayService,
    private $comments: CommentService,
    private $translate: TranslateService
  ) {
    this.language$sub = this.$auth.language$.subscribe({
      next: (language: LanguageType) => {
        this.language = language;
      }
    });
    this.loading = true;
    this.page = 1;
    this.hoverPage = 1;
    this.isMoreOptions = false;
    this.editorIndex = 0;
  }

  receiveSortableStop(result: SortableStopModel) {
    this.page = result.targetIndex + 1;
    this.hoverPage = this.page;
    this.setParams();
  }

  receiveSortableSort(result: SortableSortModel) {
    this.hoverPage = result.currentIndex + 1;
  }

  receivePage(page: number) {
    this.page = page;
    this.hoverPage = page;
    this.setParams();
  }

  receiveComments(comments: CommentsClass) {
    this.comments = comments;
    this.setChartForDownload(); 
  }

  ngOnChanges(changes: SimpleChanges) {
    if((changes.height !== undefined && !changes.height.firstChange) 
    || (changes.width !== undefined && !changes.width.firstChange)
    || (changes.resizeRatio !== undefined && !changes.resizeRatio.firstChange)) {
      if(this.chart !== undefined) {
        this.chart.chart.resize();
        this.setParams();
      }
    }
  }

  ngOnInit() {

    this.modifications$sub = this.widgetLayers.modifications$.subscribe((modification: ModificationStateClass) => {
      if(modification.has('reload')) this.loading = true;
    });

    this.query$sub = this.$query.queryResult$(this.widgetLayers).subscribe({
      next:(result: { labels: Label[], series:ChartDataSets[] }) => {
        this.result = result;
        this.page = 1;
        this.setParams();
        if(this.loading) this.loading = false;

        this.valuesTab = []
        this.result.series.forEach(s => {
          if(!!s.tab) this.valuesTab.push(...s.tab.map(v => {
            let _v = {};
            for(const key in v) {
              if(key !== 'value') {
                _v[this.widgetLayers.layers[key.replace('sw_', '')].layerLabel.value(this.language)] = v[key];
              }
              else _v[this.$translate.instant('INDICATORLABEL')] = v[key];
            }
            _v[this.$translate.instant('INDICATORLABEL')] = s.label;
            return _v;
          }));
        });
        let tabLabels = this.result.series.filter(s => s.tab && s.tab.length > 0).map(s => s.tab[0]);
        tabLabels = tabLabels.length > 0 ? tabLabels[0] : [];
        this.tabLabels = {};
        for(const key in tabLabels) {
          this.tabLabels[key.replace('sw_', '')] = tabLabels[key];
        }
      }
    });

    this.comments$sub = this.$comments.get(this.widgetLayers.uid.value).subscribe((comments: CommentsClass) => {
      this.comments = comments;
      this.setChartForDownload(); 
    });

  }

  getLabel(id: string) {
    return id === 'value' ? '' : this.widgetLayers.layers[id.replace('sw_', '')].layerLabel.value(this.language);
  }

  setParams() {
    this.params = this.widgetLayers.chartParams(this.result.labels, this.result.series, this.language, this.page, this.resizeRatio || 1);
    this.params.options.maintainAspectRatio = false;
    this.table = this.widgetLayers.chartTable(this.params.datasets);

    this.params.labels = this.params.labels.map(l => { return isNaN(l as unknown as number) ? l : `#${l}` });

    this.fullscreenParams = DeepCopy(this.params);
    this.fullscreenParams.options.maintainAspectRatio = false;
    this.fullscreenParams.options.responsive = true;
    delete this.fullscreenParams.options.aspectRatio;

    this.pages = Array(this.params.pages).fill(0).map((x: any, i: number) => { return { page: i + 1, uid: CreateRandomUid(3) }; });

    this.setChartForDownload();    
  }

  setSeeChart(seeChart: boolean) {
    this.seeChart = seeChart;
    this.widgetLayers.gettab = !seeChart;
  }

  setChartForDownload() {
    if(this.dashboardUID !== undefined && this.params !== undefined) {
      setTimeout(() => {
        this.getDownloadImage().then((image: string) => {
          this.$dashboardDisplay.addChart(this.dashboardUID, this.widgetLayers.uid.value, image, this.params, this.comments, this.result.series.map(s => { return { filters: s.match || [], indicatorUID: s.uid, formulaUID: s.formulaUID } }), this.widgetLayers);
        });
      }, this.params.options.animation.duration * 1.5);
    }
  }

  addPage() {
    if(this.page < this.params.pages) {
      this.page++;
      this.hoverPage = this.page;
      this.setParams();
    }
  }

  getDownloadImage(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      if(this.widgetLayers.layer(this.widgetLayers.drillUID).chartType !== 'sticker' && this.widgetLayers.layer(this.widgetLayers.drillUID).chartType !== 'gauge') {
        resolve(this.chart?.toBase64Image());
      }
      else {
        if(this.widgetLayers.layer(this.widgetLayers.drillUID).chartType === 'gauge') {

          var svgElement = this.element.nativeElement.querySelector('svg');
          var xmlSerializer = new XMLSerializer();
          var svgString = xmlSerializer.serializeToString(svgElement);
          var svgData = 'data:image/svg+xml;base64,' + btoa(decodeURI(encodeURIComponent(svgString)));
          
          var canvas = document.createElement("canvas");
        
          var bounds = {
            width: this.width,
            height: this.height
          };
        
          canvas.setAttribute('width', `${bounds.width}`);
          canvas.setAttribute('height', `${bounds.height}`);
        
          var context = canvas.getContext('2d');
          var image = new Image();

          image.onload = function() {
            context.clearRect(0, 0, bounds.width, bounds.height);
            context.drawImage(image, 0, 0, bounds.width, bounds.height);
            resolve(canvas.toDataURL('image/png'));
          };
          image.src = svgData;
        }
        else {
          return html2canvas(this.capture.nativeElement, { allowTaint: true }).then((canvas: HTMLCanvasElement) => {
            resolve(canvas.toDataURL());
          });
        }
      }
    });
  }

  removePage() {
    if(this.page > 1) {
      this.page--;
      this.hoverPage = this.page;
      this.setParams();
    }
  }

  ngOnDestroy() {
    this.modifications$sub.unsubscribe();
    this.query$sub.unsubscribe();
    this.language$sub.unsubscribe();
    this.comments$sub.unsubscribe();
  }

  onFullscreen() {
    if(!this.fluidModal.isShown) {
      this.sideModal.hide();
      this.fluidModal.show();
    }
    else this.onClose();
  }

  receiveEditorIndex(index: number) {
    this.editorIndex = index;
  }

  onSidescreen(index: number) {
    this.editorIndex = index;
    this.fluidModal.hide();
    if(this.fluidModal.isShown) this.sideModal.hide();
    else this.sideModal.show();
  }

  receiveOpenDrill() {
    this.onSidescreen(3);
  }

  onClose() {
    this.fluidModal.hide();
    this.sideModal.hide();
    this.seeChart = true;
  }

  onMoreOptions() {
    this.isMoreOptions = !this.isMoreOptions;
  }

}
