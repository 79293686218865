/**
 * @module User
 */

import { Component, Input } from '@angular/core';
import { UserIdClass } from '@class/user.class';
import { ItemColor } from '@functions/item.functions';

@Component({
  selector: 'app-lastname',
  templateUrl: './lastname.component.html',
  styleUrls: ['./lastname.component.css']
})
export class LastnameComponent {

  @Input() userId: UserIdClass;
  @Input() color: string = ItemColor('users');

  constructor() {}

  onLastname(lastname: string) {
    this.userId.change({ lastname: lastname });
  }

}
