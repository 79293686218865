/**
 * @module Auth
 */

import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageType } from '@models/language.model';
import { FirebaseService } from '@services/firebase/firebase.service';
import { MessagesService } from '@services/messages/messages.service';

@Component({
  selector: 'app-auth-actions',
  templateUrl: './auth-actions.component.html',
  styleUrls: ['./auth-actions.component.css']
})
export class AuthActionsComponent {

  mode: 'resetPassword' | 'recoverEmail' | 'verifyEmail';
  actionCode: string;
  continueUrl: string;
  email: string;
  language: LanguageType;

  constructor(
    private $router: Router,
    private $firebase: FirebaseService,
    private $message: MessagesService
  ) {
    this.mode = this.$router.parseUrl(this.$router.url).queryParams.mode;
    this.actionCode = this.$router.parseUrl(this.$router.url).queryParams.oobCode;
    this.continueUrl = this.$router.parseUrl(this.$router.url).queryParams.continueUrl;
    this.language = this.$router.parseUrl(this.$router.url).queryParams.lang || 'en';

    switch(this.mode) {
      case 'resetPassword':
        this.$firebase.angularFireAuth.auth.verifyPasswordResetCode(this.actionCode).then((email) => {
          this.email = email;
        }).catch((error) => {
          if(error.code === 'auth/invalid-action-code') {
            this.$message.warning({ text: { code: 'PASSWORD_RESET_CODE_INVALID' } });
          }
          else {
            this.$message.warning({ text: { code: 'PASSWORD_RESET_FAILED' } });
          }
          this.$router.navigate(['/']);
        });
        break;
      case 'recoverEmail':
      case 'verifyEmail':
      default:
        this.$router.navigate(['/']);
    }
  }
}
