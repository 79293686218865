<app-form-strip>
  <div app-form-strip-content-left>
    <i *ngIf="!user.activated" class="fad fa-lg mt-1 fa-fw fa-user-lock animated rotateIn text-danger"></i>
    <i *ngIf="user.activated" class="fad fa-lg mt-1 fa-fw fa-user-check animated rotateIn text-success"></i>
  </div>

  <div app-form-strip-content-middle>
    {{ (user.activated ? 'ACTIVE_ACCOUNT' : 'SUSPENDED_ACCOUNT') | translate }}
  </div>

  <div app-form-strip-content-right>
    <label class="mb-0 w-100 d-flex justify-content-between align-items-center">
      <input type="checkbox" [ngModel]="user.activated" (change)="onActivated($event.target.checked)">
      <span class="lever mr-0"></span>
    </label>
  </div>
</app-form-strip>