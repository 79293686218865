/**
 * @module Comment
 */

import { Component, ViewChild, Input, Output, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { HelperBbcodeComponent } from '@components/helpers/helper-bbcode/helper-bbcode.component';
import { DescriptionsClass } from '@class/descriptions.class';
import { Subscription } from 'rxjs';
import { LanguageType } from '@models/language.model';
import { AuthService } from '@services/auth/auth.service';
import { DescriptionsModel } from '@models/descriptions.model';
import { FormGroup } from '@angular/forms';
import { ModificationStateClass } from '@class/modification.class';
import bbCodeParser from 'js-bbcode-parser';
import { CommentModel } from '@models/comment.model';

@Component({
  selector: 'app-comment-editor',
  templateUrl: './comment-editor.component.html',
  styleUrls: ['./comment-editor.component.css']
})
export class CommentEditorComponent implements OnDestroy, OnInit {

  @Input() color: string;
  @Input() comment: CommentModel
  @Output() sendComment: EventEmitter<DescriptionsClass>;

  @ViewChild('bbcodeHelper', { static: true }) bbcodeHelper: HelperBbcodeComponent;
  
  descriptions: DescriptionsClass;
  preview: string;
  descriptions$sub: Subscription;
  language: LanguageType;
  language$sub: Subscription;
  editing: boolean;

  constructor(
    private $auth: AuthService
  ) {
    this.language$sub = this.$auth.language$.subscribe({
      next: (language: LanguageType) => {
        this.language = language;
      }
    });

    this.preview = '';
    this.sendComment = new EventEmitter<DescriptionsClass>();
  
  }

  ngOnInit() {
    if(this.comment !== undefined) {
      this.editing = true;
      this.descriptions = new DescriptionsClass({ descriptions: this.comment.descriptions }, new FormGroup({}), {}, new ModificationStateClass(), 10);
      this.preview = bbCodeParser.parse(this.descriptions.value(this.language));
      this.descriptions$sub = this.descriptions.modifications$.subscribe((modifications: ModificationStateClass) => {
        if(modifications.isModified) {
          this.preview = bbCodeParser.parse(this.descriptions.value(this.language));
        }
      });
    }
    else {
      this.editing = false;
      this.descriptions = new DescriptionsClass({} as DescriptionsModel, new FormGroup({}), {}, new ModificationStateClass(), 10);
      this.descriptions$sub = this.descriptions.modifications$.subscribe((modifications: ModificationStateClass) => {
        if(modifications.isModified) {
          this.preview = bbCodeParser.parse(this.descriptions.value(this.language));
        }
      });
    }
  }

  addBold() {
    let model = this.descriptions.model;
    model.descriptions[this.language] = model.descriptions[this.language].concat('[b]text[/b]');
    this.descriptions.change(model);
  }

  addItalic() {
    let model = this.descriptions.model;
    model.descriptions[this.language] = model.descriptions[this.language].concat('[i]text[/i]');
    this.descriptions.change(model);
  }

  addUnderline() {
    let model = this.descriptions.model;
    model.descriptions[this.language] = model.descriptions[this.language].concat('[u]text[/u]');
    this.descriptions.change(model);
  }

  addBullet() {
    let model = this.descriptions.model;
    model.descriptions[this.language] = model.descriptions[this.language].concat('[*]text[/*]');
    this.descriptions.change(model);
  }

  addBreak() {
    let model = this.descriptions.model;
    model.descriptions[this.language] = model.descriptions[this.language].concat('[br]');
    this.descriptions.change(model);
  }

  ngOnDestroy() {
    this.language$sub.unsubscribe();
    this.descriptions$sub.unsubscribe();
  }

  onAdd() {
    this.sendComment.emit(this.descriptions);
  }

}
