/**
 * @module Dashboard
 */

import { Component, OnInit, Input } from '@angular/core';
import { DashboardLayoutClass } from '@class/dashboardLayout.class';
import { ItemColor } from '@functions/item.functions';

@Component({
  selector: 'app-dashboard-layout-color',
  templateUrl: './dashboard-layout-color.component.html',
  styleUrls: ['./dashboard-layout-color.component.css']
})
export class DashboardLayoutColorComponent implements OnInit {

  @Input() dashboardLayout: DashboardLayoutClass;

  color = ItemColor('dashboards');
  opacity: number;
  backgroundColor: string;

  constructor() {}

  ngOnInit() {
    this.backgroundColor = this.dashboardLayout.color.slice(0, 7);
    this.opacity = Math.round(parseInt(this.dashboardLayout.color.slice(7, 9), 16) * 100 / 255);
  }
  
  receiveValue(value: string) {
    this.backgroundColor = value;
    this.dashboardLayout.change({ color: value + Math.round(this.opacity * 255 / 100).toString(16) });
  }

  receiveOpacity(value: number) {
    this.opacity = value;
    if(this.opacity > 0) {
      this.dashboardLayout.change({ color: this.backgroundColor + Math.round(value * 255 / 100).toString(16) });
    }
    else {
      this.dashboardLayout.change({ color: this.backgroundColor + '00' });
    }
  }

}
