/**
 * @Module Shared
 */

import { Pipe, PipeTransform } from '@angular/core';

/*
 *ngFor="let c of oneDimArray | sortBy:'asc'"
 *ngFor="let c of arrayOfObjects | sortBy:'asc':'propertyName'"
 */

@Pipe({
  name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {

  transform(items: any[], order: 'asc' | 'desc', key: string): any[] {
    if (!items || !order) { return items; } // no array
    if (!key) { 
      return items.sort((a, b) => {
        if(a < b){
          return order === 'asc' ? -1 : 1;
        }
        if(a > b){
          return order === 'asc' ? 1 : -1;
        }
        return 0;
      }); 
    } // sort 1d array
    if (items.length <= 1) { return items; } // array with only one item
    return items.sort((a, b) => {
      if(a[key] < b[key]){
        return order === 'asc' ? -1 : 1;
      }
      if(a[key] > b[key]){
        return order === 'asc' ? 1 : -1;
      }
      return 0;
    });
  }

}
