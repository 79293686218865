import { Component, OnInit, Input, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { CarouselComponent } from 'ng-uikit-pro-standard';
import { WidgetChartParamsModel } from '@models/widgetLayers.model';
import { Brightness } from '@functions/color.functions';
import { ChartDataSets } from 'chart.js';
import { NumbroApplyFormat } from '@functions/numbro.functions';

@Component({
  selector: 'app-widget-studio-chart-sticker',
  templateUrl: './widget-studio-chart-sticker.component.html',
  styleUrls: ['./widget-studio-chart-sticker.component.css']
})
export class WidgetStudioChartStickerComponent implements OnInit, OnChanges {
  
  @Input() params: WidgetChartParamsModel;
  @ViewChild('carousel') carousel: CarouselComponent;

  public isBright: boolean;
  public styles: any;
  
  constructor() { }

  ngOnInit() {
    this.setStyles();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.params !== undefined && !changes.params.firstChange) this.setStyles();
  }

  setStyles() {
    this.styles = {
      titles: {},
      datasets: []
    };

    if(this.params.options) {

      this.isBright = Brightness(this.params.options.layout.backgroundColor.toString().substring(1, 7)) === 'light';

      this.params.datasets.forEach((datasets: ChartDataSets) => {
        if(datasets.hidden === false) {
          this.styles['datasets'].push({
            total: NumbroApplyFormat(datasets.total, datasets.numbro.type, datasets.numbro.format),
            icon: {
              code: datasets.icon,
              frame: datasets.iconFrame
            },
            title: {
              'color': this.params.options.fullyColored ? Brightness(datasets.backgroundColor.toString().substring(1, 7)) === 'light' ? '#00000080' : '#FFFFFF' : this.params.options.legend.labels.fontColor,
              'font-family': this.params.options.title.fontFamily,
              'font-size': `${this.params.options.title.fontSize}px`,
              'font-weight': this.params.options.title.fontStyle.includes('bold') ? 'bold' : '',
              'font-style': this.params.options.title.fontStyle.includes('italic') ? 'italic' : '',
            },
            label: {
              label: datasets.label,
              'color': this.params.options.fullyColored ? Brightness(datasets.backgroundColor.toString().substring(1, 7)) === 'light' ? '#00000080' : '#FFFFFF' : this.params.options.legend.labels.fontColor,
              'font-family': this.params.options.legend.labels.fontFamily,
              'font-size': `${this.params.options.legend.labels.fontSize}px`,
              'font-weight': this.params.options.legend.labels.fontStyle.includes('bold') ? 'bold' : '',
              'font-style': this.params.options.legend.labels.fontStyle.includes('italic') ? 'italic' : '',
            },
            frame: { 
              'background-color': datasets.iconFrame !== 'without' ? datasets.backgroundColor : '',
              'color': datasets.iconFrame === 'without' ? (datasets.backgroundColor as string).slice(0, 7) : Brightness(datasets.backgroundColor.toString().substring(1, 7)) === 'light' ? '#00000080' : '#FFFFFF'
            },
            layout: {
              'border-color': this.params.options.fullyColored ? Object.values(datasets.datalabels.labels)[0].color : Brightness(datasets.backgroundColor.toString().substring(1, 7)) === 'light' ? '#00000080' : '#FFFFFF',
              'background-color': this.params.options.fullyColored ? datasets.backgroundColor : '',
              isBright: this.params.options.fullyColored ? Brightness(datasets.backgroundColor.toString().substring(1, 7)) === 'light' : Brightness(this.params.options.layout.backgroundColor.toString().substring(1, 7)) === 'light'
            },
            datalabels: {
              'color': this.params.options.fullyColored ? Brightness(datasets.backgroundColor.toString().substring(1, 7)) === 'light' ? '#00000080' : '#FFFFFF' : Object.values(datasets.datalabels.labels)[0].color,
              'opacity': Object.values(datasets.datalabels.labels)[0].opacity,
              'font-family': Object.values(datasets.datalabels.labels)[0].font['family'],
              'font-size': `${Object.values(datasets.datalabels.labels)[0].font['size']}px`,
              'font-weight': Object.values(datasets.datalabels.labels)[0].font['style'].includes('bold') ? 'bold' : '',
              'font-style': Object.values(datasets.datalabels.labels)[0].font['style'].includes('italic') ? 'italic' : ''
            }
          });
        }
      });
    }
  }

  next() {
    this.carousel.nextSlide();
  }

  prev() {
    this.carousel.previousSlide();
  }

}
