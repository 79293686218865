<div>

  <div *ngIf="!date.uid.isRegistered" class="alert alert-info font-small text-center" role="alert">
    {{ 'NEW_DATE_CREATE/TEXT' | translate }}
  </div>

  <app-input [marginTop]="false" [label]="'SEARCH'" [color]="color" [value]="searchLabel" (sendValue)="receiveTextValue($event)"></app-input>

  <app-links-item *ngFor="let element of list | searchLabels:searchLabel:language" [color]="color" [icon]="icon" [labels]="element.labels" [uid]="element.uid">
    <div app-links-item-content-description>
    </div>
    <div app-links-item-content-option>
      <app-links-item-option class="d-block mx-1" [uid]="element.uid.value" [symbol]="'check'" [selected]="date.uid.value == element.uid.value" (sendSelected)="receiveSelected($event)"></app-links-item-option>
    </div>
  </app-links-item>

</div>

<app-empty app-browser-frame-content-list [action]="false" [type]="'dates'" *ngIf="list != undefined && list.length == 0"></app-empty>
<app-loading app-browser-frame-content-list *ngIf="list == undefined"></app-loading>