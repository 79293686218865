/**
 * @module Tag
 */

import { Component, Input } from '@angular/core';
import { TagsClass } from '@class/tag.class';

@Component({
  selector: 'app-tags-icons-list',
  templateUrl: './tags-icons-list.component.html',
  styleUrls: ['./tags-icons-list.component.css']
})
export class TagsIconsListComponent {

  @Input() limit: number = 100;
  @Input() tags: TagsClass;

  constructor() {}

}
