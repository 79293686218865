<app-theme-option [categories]="themeOptions.optionsConfiguration[optionType].categories" [label]="themeOptions.optionsConfiguration[optionType].label" [information]="'PIXELS'">
  <div app-theme-option-content>
    <app-show-details-option [showDetails]="showDetails" (sendShowDetails)="receiveShowDetails($event)">
      <div app-show-details-option-content>
        <app-theme-option-font-size [fontSize]="themeOptions.options().legend.labels.fontSize" (sendFontSize)="receiveValue($event)"></app-theme-option-font-size>
      </div>
    </app-show-details-option>
  </div>
</app-theme-option>

<div *ngIf="showDetails">
  <app-theme-option [categories]="themeOptions.optionsConfiguration[optionType].categoriesAndLabel" [information]="'PIXELS'" [label]="type | chart" [dark]="4" *ngFor="let type of themeOptions.limitedTo(optionType)">
    <div app-theme-option-content>
      <app-theme-option-font-size [fontSize]="themeOptions.options(type).legend.labels.fontSize" (sendFontSize)="receiveValue($event, type)"></app-theme-option-font-size>
    </div>
  </app-theme-option>
</div>