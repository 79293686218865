/**
 * @module Theme
 */

import { Component, Input, OnInit } from '@angular/core';
import { ItemColor } from '@functions/item.functions';
import { ThemeOptionsClass } from '@class/themeOptions.class';
import { ThemeOptionType } from '@models/themeStudio.model';

@Component({
  selector: 'app-theme-option-tooltips-multi-key-background',
  templateUrl: './theme-option-tooltips-multi-key-background.component.html',
  styleUrls: ['./theme-option-tooltips-multi-key-background.component.css']
})
export class ThemeOptionTooltipsMultiKeyBackgroundComponent implements OnInit {

  @Input() themeOptions: ThemeOptionsClass;

  color = ItemColor('themes');
  optionType: ThemeOptionType = 'tooltips_multiKeyBackground';
  opacity: number;
  multiKeyBackground: string;

  constructor() {}

  ngOnInit() {
    this.multiKeyBackground = this.themeOptions.options().tooltips.multiKeyBackground.toString().slice(0, 7);
    this.opacity = Math.round(parseInt(this.themeOptions.options().tooltips.multiKeyBackground.toString().slice(7, 9), 16) * 100 / 255);
  }
  
  receiveValue(value: string) {
    this.multiKeyBackground = value;
    this.themeOptions.setOption('tooltips_multiKeyBackground', value + Math.round(this.opacity * 255 / 100).toString(16));
  }

  receiveOpacity(value: number) {
    this.opacity = value;
    if(this.opacity > 0) this.themeOptions.setOption('tooltips_multiKeyBackground', this.multiKeyBackground + Math.round(value * 255 / 100).toString(16));
    else this.themeOptions.setOption('tooltips_multiKeyBackground', this.multiKeyBackground + '00');
  }

}
