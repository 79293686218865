/**
 * @module Comment
 */

import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { UID } from '@models/uid.model';
import { DescriptionsClass } from '@class/descriptions.class';
import { CommentService } from '@services/comment/comment.service';
import { Subscription } from 'rxjs';
import { CommentsClass, CommentClass } from '@class/comment.class';
import { AuthService } from '@services/auth/auth.service';
import { UserClass } from '@class/user.class';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.css']
})
export class CommentsComponent implements OnInit, OnDestroy {

  @Input() uid: UID;
  @Input() color: string;
  @Output() sendComments: EventEmitter<CommentsClass>;

  comments$sub: Subscription;
  comments: CommentsClass;
  user: UserClass;

  constructor(
    private $comments: CommentService,
    private $auth: AuthService
  ) {
    this.user = this.$auth.user$.value;
    this.sendComments = new EventEmitter<CommentsClass>();
  }

  ngOnInit() {
    this.comments$sub = this.$comments.get(this.uid).subscribe((comments: CommentsClass) => {
      this.comments = comments;
      this.sendComments.emit(this.comments);
    });
  }

  ngOnDestroy() {
    this.comments$sub.unsubscribe();
  }

  receiveComment(descriptions: DescriptionsClass) {
    this.comments.add({ 
      uidAuthor: { uid: this.user.uid.value }, 
      descriptions: descriptions.model.descriptions
    });
    this.$comments.set(this.comments);
  }

  receiveDelete(comment: CommentClass) {
    this.comments.remove(comment.uid.value);
    this.$comments.set(this.comments);
  }

  receiveEdit(comment: CommentClass) {
    this.comments.add({ 
      uidAuthor: { uid: this.user.uid.value }, 
      descriptions: comment.model.descriptions,
      uid: comment.uid.value
    });
    this.$comments.set(this.comments);
  }

}
