/**
 * @module User
 */

import { ResourceType, ResourcesModel } from '@models/resources.model';

/**
 * @description renvoi un tableau de toutes les valeurs possibles de ressources
 * @export
 * @returns {Array<ResourceType>}
 */
export function ResourceTypes(): Array<ResourceType> {
  return ['dashboards', 'widgets', 'servers', 'indicators'];
}

export function ResourcesModelConstructor(): ResourcesModel {
  return {
    resources: {
      dashboards: {},
      widgets: {},
      servers: {},
      indicators: {}
    }
  };
}