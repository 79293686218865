/**
 * @module Dashboard
 */

import { Component, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UID } from '@models/uid.model';
import { ItemColor, ItemIcon } from '@functions/item.functions';
import { DashboardService } from '@services/dashboard/dashboard.service';
import { DashboardClass } from '@class/dashboard.class';
import { Subscription } from 'rxjs';
import { DashboardEditorComponent } from '@components/dashboards/dashboard-editor/dashboard-editor.component';
import { LanguageType } from '@models/language.model';
import { AuthService } from '@services/auth/auth.service';
import { DashboardLayoutClass } from '@class/dashboardLayout.class';
import { ModificationStateClass } from '@class/modification.class';
import { MessagesService } from '@services/messages/messages.service';
import { StudioComponent } from '@components/forms/studio/studio.component';
import { DashboardStudioService } from '@services/dashboardStudio/dashboard-studio.service';
import { DashboardCellsAddComponent } from '@components/dashboards/dashboard-studio/options/cells/dashboard-cells-add/dashboard-cells-add.component';
import { DashboardLayoutCellClass } from '@class/dashboardLayoutCell.class';

@Component({
  selector: 'app-dashboard-studio',
  templateUrl: './dashboard-studio.component.html',
  styleUrls: ['./dashboard-studio.component.css']
})
export class DashboardStudioComponent implements OnDestroy {

  @ViewChild('editor', { static: true }) editor: DashboardEditorComponent;
  @ViewChild('cellsAdd') cellsAdd: DashboardCellsAddComponent;
  @ViewChild('studio') studio: StudioComponent;

  color = ItemColor('dashboards');
  icon = ItemIcon('dashboards');

  dashboard: DashboardClass;
  dashboard$sub: Subscription;
  language: LanguageType;
  language$sub: Subscription;
  modifications$sub: Subscription;
  uid: UID;
  panel: string;
  from: string;
  saved: boolean;
  saving: boolean;
  dashboardLayout$sub: Subscription;
  dashboardLayout: DashboardLayoutClass;
  current: DashboardLayoutCellClass;
  isDraggable: boolean;

  constructor(
    private $router: Router,
    private $dashboard: DashboardService,
    private $auth: AuthService,
    private $dashboardStudio: DashboardStudioService,
    private $messages: MessagesService
  ) { 
    this.uid = this.$router.parseUrl(this.$router.url).queryParams.uid;
    this.panel = this.$router.parseUrl(this.$router.url).queryParams.panel;
    this.from = this.$router.parseUrl(this.$router.url).queryParams.from;
    this.dashboard = this.$dashboard.create();
    this.saved = true;
    this.saving = false;
    this.isDraggable = false;

    this.language$sub = this.$auth.language$.subscribe({
      next: (language: LanguageType) => {
        this.language = language;
      }
    });

    if(!this.uid) this.onBack();
    else {
      this.dashboard$sub = this.$dashboard.get$(this.uid).subscribe({
        next:(dashboard: DashboardClass) => {
          if(!!dashboard) {
            this.dashboard = dashboard;

            if(this.dashboardLayout$sub) this.dashboardLayout$sub.unsubscribe();
            this.dashboardLayout$sub = this.$dashboard.getLayout$(this.dashboard).subscribe({
              next:(dashboardLayout: DashboardLayoutClass) => {
                this.dashboardLayout = this.$dashboardStudio.dashboardLayout(this.uid, dashboardLayout, this.dashboard, this.modifications$sub !== undefined);
                if(this.modifications$sub) this.modifications$sub.unsubscribe();
                this.modifications$sub = this.dashboardLayout.modifications$.subscribe((modification: ModificationStateClass) => {
                  this.saved = !modification.isModified;
                });
              }
            });
          }
        }
      });
    }
  }

  ngOnDestroy() {
    this.language$sub.unsubscribe();
    if(this.dashboardLayout$sub) this.dashboardLayout$sub.unsubscribe();
    if(this.dashboard$sub) this.dashboard$sub.unsubscribe();
    if(this.modifications$sub) this.modifications$sub.unsubscribe();
  }

  receiveAction() {
    this.dashboardLayout.addCell();
  }

  receiveDraggable(isDraggable: boolean) {
    this.isDraggable = isDraggable;
  }
  
  receiveSelected(cell: DashboardLayoutCellClass) {
    if(this.current !== undefined && this.current.uid.value === cell.uid.value) {
      this.current = undefined;
      this.studio.setFrame('main', true);
    }
    else {
      this.current = cell;
      this.studio.setFrame('sub', true);
    }
  }

  receiveRemove(uid: UID) {
    this.current = undefined;
    this.dashboardLayout.removeCell(uid);
  }

  onReload() {
    this.dashboardLayout.reset();
  }

  onSave() {
    this.saving = true;
    this.$dashboard.setLayout(this.uid, this.dashboardLayout)
    .then(() => {
      this.saving = false;
      this.saved = true;
      this.$messages.success({ 
        title: { plain: this.dashboard.labels.value(this.language) }, 
        text: { code: 'SUCCESSFULLY_SAVED/TEXT' } 
      });
    })
    .catch((error) => {
      this.saving = false;
      this.saved = false;
      this.$messages.error({ 
        title: { plain: this.dashboard.labels.value(this.language) }, 
        text: { 
          code: 'SAVE_FAILED/TEXT',
          params: { error: error.code }
        } 
      });
    });
  }

  receiveDelete() {
    this.onBack();
  }

  onBack() {
    this.$router.navigate([`/${this.from}`], { queryParams: this.panel !== undefined ? { panel: this.panel } : {} });
  }

  receiveEdit() {
    this.editor.show();
  }

}
