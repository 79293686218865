/**
 * @module Moment
 */

import * as m from 'moment';
import { FrequencyType, ParsedDateModel } from '@models/moment.model';
import { WidgetTimelineType } from '@models/widgetLayers.model';

export const BACKEND_DATE_FORMAT = 'YYYY-MM-DD';

/**
 * @description Accès à la librairie
 * @export
 * @returns
 */
export function Moment() {
  return m;
}

export function IsBefore(date: string, isBeforeDate: string, dateFormat: string): Readonly<boolean> {
  return m(date, dateFormat).isBefore(m(isBeforeDate, dateFormat));
}

export function IsDateValid(date: any): Readonly<boolean> {
  return m(date).isValid();
}

export function ParseDate(date: any, format?: string, forceYearForFisrtWeek?: boolean): Readonly<ParsedDateModel> {
  const _date = format === undefined ? m(m.utc(date).format()) : m(date, format, true);
  forceYearForFisrtWeek = forceYearForFisrtWeek === undefined ? false :forceYearForFisrtWeek;
  const parsed = _date.isValid() ? 
    forceYearForFisrtWeek && format !== undefined && format.toUpperCase().indexOf('W') !== -1 && _date.week() == 1 ? (format === undefined ? _date.add(2, 'days').utc().format(BACKEND_DATE_FORMAT) : _date.add(2, 'days').format(BACKEND_DATE_FORMAT))
    : (format === undefined ? _date.utc().format(BACKEND_DATE_FORMAT) : _date.format(BACKEND_DATE_FORMAT))
    : undefined;
  return {
    isValid: _date.isValid(),
    source: date,
    parsed: parsed,
    format: _date.isValid() ? m(date).creationData().format as string : BACKEND_DATE_FORMAT
  }
}

/**
 * @description Renvoi une date au format ISO
 * @export
 * @param {*} date
 * @returns {Readonly<string>}
 */
export function DateToIso(date: any): Readonly<string> {
  return m(date).format(BACKEND_DATE_FORMAT);
}

/**
 * @description Renvoi une date au format demandé ou ISO par défaut
 * @export
 * @param {string} date
 * @param {string} [format='YYYY-MM-DD HH:mm']
 * @returns {Readonly<string>}
 */
export function DateApplyFormat(date: string, format: string = 'YYYY-MM-DD HH:mm'): Readonly<string> {
  return m(date).format(format);
}

/**
 * @description Renvoi la période de temps en texte du temps passé entre le log et l'instant présent
 * @export
 * @returns {Readonly<string>}
 */
export function DateFromNow(dateISO: string): Readonly<string> {
  return m(dateISO).fromNow();
}

/**
 * @description modifie le format et la valeur en paramètre si le format contient un caractère clé de semestre/six-month ('I')
 * @export
 * @param {string} value
 * @param {string} format
 * @returns {{ value?: string, format?: string, valid: boolean }}
 */
/*export function ParseDateForSixMonth(value: string, format: string): { value?: string, format?: string, valid: boolean } {

  const length = (format.match(/Io?/g) || []).length;

  if(length === 0) return { valid: false };

  const index = format.indexOf('I');
  const _valueSource = parseInt(value.substr(index, length));

  if(_valueSource < 1 || _valueSource > 2) return { valid: false };
  else {
    let _valueTarget = ['1', '6'][_valueSource - 1];
    return {
      value: value.substr(0, (index + length - 1)) + _valueTarget + value.substr(index + length),
      format: format.replace(/Io?/g, 'M'),
      valid: true
    };
  }
}*/

/**
 * @description modifie le format et la valeur en paramètre si le format contient un caractère clé de quadrimestre/four-month ('O')
 * @export
 * @param {string} value
 * @param {string} format
 * @returns {{ value?: string, format?: string, valid: boolean }}
 */
/*export function ParseDateForFourMonth(value: string, format: string): { value?: string, format?: string, valid: boolean } {

  const length = (format.match(/Oo?/g) || []).length;

  if(length === 0) return { valid: false };

  const index = format.indexOf('O');
  const _valueSource = parseInt(value.substr(index, length));

  if(_valueSource < 1 || _valueSource > 3) return { valid: false };
  else {
    let _valueTarget = ['1', '5', '9'][_valueSource - 1];
    return {
      value: value.substr(0, (index + length - 1)) + _valueTarget + value.substr(index + length),
      format: format.replace(/Oo?/g, 'M'),
      valid: true
    };
  }
}*/

/**
 * @description modifie le format et la valeur en paramètre si le format contient un caractère clé de semestre/six-month ('I') ou de quadrimestre/four-month ('O')
 * @export
 * @param {string} value
 * @param {string} format
 * @returns {{ value?: string, format?: string, valid: boolean }}
 */
/*export function ParseDateFourAndSixMonth(value: string, format: string): { value?: string, format?: string, valid: boolean } {
  let result = ParseDateForSixMonth(value, format);
  if(result.valid) return result;
  else return ParseDateForFourMonth(value, format);
}*/

export function FrequenciesTypes(): FrequencyType[] {
  return ['D', 'W', 'M', 'Q', 'Y'];
}

export function FrequenciesLabels(): { [key in FrequencyType]: string } {
  return {
    D: 'DAY',
    W: 'WEEK',
    M: 'MONTH',
    Q: 'QUARTER',
    Y: 'YEAR'
  };
}

export function FrequencyLabel(frequency: FrequencyType): string {
  return FrequenciesLabels()[frequency];
}

export function FrequenciesDefaultFormats(): { [key in FrequencyType]: string } {
  return {
    D: 'MMM-DD',
    W: 'YYYY-WW',
    M: 'YYYY-MM',
    Q: 'YYYY-Q',
    Y: 'YYYY'
  };
}

export function FrequencyDefaultFormat(frequency: FrequencyType): string {
  return FrequenciesDefaultFormats()[frequency];
}

export function Occurences(from: string, to: string, dateFormat: string): string[] {

  let _from = m(from).startOf('day');
  let _to = m(to).startOf('day');

  let occurences = [_from.format(dateFormat)];

  while(_from.add(1, 'days').diff(_to) < 0) {
    occurences.push(_from.format(dateFormat));
  }

  occurences.push(_to.format(dateFormat));
  occurences = occurences.filter((date: string, index: number) => index === occurences.indexOf(date));

  return occurences;
}

export function Difference(from: string, to: string, periodGroup: FrequencyType): number {
  let _from = m(from);
  let _to = m(to);
  return _from.diff(_to, FrequencyLabel(periodGroup).toLowerCase() as any) + 1;
}

export function ApplyTimeline(date: string, timeline: WidgetTimelineType, periodLength?: number): string {
  if(timeline === 'previousYear') return m(date).subtract(1, 'year').format(BACKEND_DATE_FORMAT);
  else if(timeline === 'previousPeriod' && periodLength !== undefined) return m(date).subtract(periodLength, 'day').format(BACKEND_DATE_FORMAT);
  else return date;
}

export function ParseGenericDate(genericDate: string, isFrom: boolean, offsetDate?: { amount: number, frequency: FrequencyType }): string {

  let todayISO = m().format(BACKEND_DATE_FORMAT);
  let today = m(todayISO);
  if(offsetDate) {
    today.subtract(offsetDate.amount, 
      offsetDate.frequency === 'M' ? 'M' : 
      offsetDate.frequency === 'Y' ? 'y' : 
      offsetDate.frequency === 'W' ? 'w' : 
      offsetDate.frequency === 'Q' ? 'Q' : 'd');
  }
  genericDate = genericDate.toUpperCase();

  if (new RegExp('\/\Y').test(genericDate)) { //mois + année

    let exp = genericDate.split('/');

    today.month(parseInt(exp[0]) - 1);

    let opMinus = exp[1].split('-');
    let opPlus = exp[1].split('+');

    if(opMinus.length > 1) {
      today.subtract(parseInt(opMinus[1]), 'year');
    }
    else if(opPlus.length > 1) {
      today.add(parseInt(opPlus[1]), 'year');
    }
    genericDate = today.date(isFrom ? 1 : today.endOf('month').date()).format(BACKEND_DATE_FORMAT);

  } else
  if (new RegExp('^\Y').test(genericDate)) { //année seule

    /*if(waitEndPeriod) {
      if(today.month() == 0) {
        today.year(today.year() - 1);
      }
    }*/

    today.month(isFrom ? 0 : 11);

    let opMinus = genericDate.split('-');
    let opPlus = genericDate.split('+');

    if(opMinus.length > 1) {
      today.subtract(parseInt(opMinus[1]), 'year');
    }
    else if(opPlus.length > 1) {
      today.add(parseInt(opPlus[1]), 'year');
    }
    genericDate = today.date(isFrom ? 1 : today.endOf('month').date()).format(BACKEND_DATE_FORMAT);

  } else
  if(new RegExp('^\W').test(genericDate)) {
    
    let opMinus = genericDate.split('-');
    let opPlus = genericDate.split('+');

    if(opMinus.length > 1) {
      today.subtract(parseInt(opMinus[1]), 'week');
    }
    else if(opPlus.length > 1) {
      today.add(parseInt(opPlus[1]), 'week');
    }

    genericDate = today.isoWeekday(isFrom ? 1 : 7).format(BACKEND_DATE_FORMAT);

  } else
  if(new RegExp('W').test(genericDate)) {

    let fixed = parseInt(genericDate.split('W')[0]) - 1;

    /*if(waitEndPeriod) {
      if(today.week() == 1) {
        today.year(today.year() - 1);
      }
    }*/

    today.week(fixed);
    
    let opMinus = genericDate.split('-');
    let opPlus = genericDate.split('+');

    if(opMinus.length > 1) {
      today.subtract(parseInt(opMinus[1]), 'week');
    }
    else if(opPlus.length > 1) {
      today.add(parseInt(opPlus[1]), 'week');
    }

    genericDate = today.isoWeekday(isFrom ? 1 : 7).format(BACKEND_DATE_FORMAT);

  } else
  /*if(new RegExp('^\H').test(genericDate)) {

    today.month(Math.floor((today.month())/6) * 6 + (!isFrom ? 5 : 0));
    
    let opMinus = genericDate.split('-');
    let opPlus = genericDate.split('+');

    if(opMinus.length > 1) {
      today.subtract(parseInt(opMinus[1]) * 6, 'month');
    }
    else if(opPlus.length > 1) {
      today.add(parseInt(opPlus[1]) * 6, 'month');
    }
    genericDate = today.date(isFrom ? 1 : today.endOf('month').date()).format(BACKEND_DATE_FORMAT);

  } else
  if(new RegExp('H').test(genericDate)) {

    let fixed = parseInt(genericDate.split('H')[0]) - 1;

    if(waitEndPeriod) {
      if(today.month() <= 5) {
        today.year(today.year() - 1);
      }
    }

    today.month(fixed * 6 + (!isFrom ? 5 : 0));
    
    let opMinus = genericDate.split('-');
    let opPlus = genericDate.split('+');

    if(opMinus.length > 1) {
      today.subtract(parseInt(opMinus[1]) * 6, 'month');
    }
    else if(opPlus.length > 1) {
      today.add(parseInt(opPlus[1]) * 6, 'month');
    }
    genericDate = today.date(isFrom ? 1 : today.endOf('month').date()).format(BACKEND_DATE_FORMAT);

  } else
  if(new RegExp('^\F').test(genericDate)) {

    today.month(Math.floor((today.month())/4) * 4 + (!isFrom ? 3 : 0));
    
    let opMinus = genericDate.split('-');
    let opPlus = genericDate.split('+');

    if(opMinus.length > 1) {
      today.subtract(parseInt(opMinus[1]) * 4, 'month');
    }
    else if(opPlus.length > 1) {
      today.add(parseInt(opPlus[1]) * 4, 'month');
    }
    genericDate = today.date(isFrom ? 1 : today.endOf('month').date()).format(BACKEND_DATE_FORMAT);

  } else
  if(new RegExp('F').test(genericDate)) {

    let fixed = parseInt(genericDate.split('F')[0]) - 1;

    if(waitEndPeriod) {
      if(today.month() <= 3) {
        today.year(today.year() - 1);
      }
    }

    today.month(fixed * 4 + (!isFrom ? 3 : 0));
    
    let opMinus = genericDate.split('-');
    let opPlus = genericDate.split('+');

    if(opMinus.length > 1) {
      today.subtract(parseInt(opMinus[1]) * 4, 'month');
    }
    else if(opPlus.length > 1) {
      today.add(parseInt(opPlus[1]) * 4, 'month');
    }
    genericDate = today.date(isFrom ? 1 : today.endOf('month').date()).format(BACKEND_DATE_FORMAT);

  } else
  if(new RegExp('^\Q').test(genericDate)) {

    today.month(Math.floor((today.month())/3) * 3 + (!isFrom ? 2 : 0));
    
    let opMinus = genericDate.split('-');
    let opPlus = genericDate.split('+');

    if(opMinus.length > 1) {
      today.subtract(parseInt(opMinus[1]) * 3, 'month');
    }
    else if(opPlus.length > 1) {
      today.add(parseInt(opPlus[1]) * 3, 'month');
    }
    genericDate = today.date(isFrom ? 1 : today.endOf('month').date()).format(BACKEND_DATE_FORMAT);

  } else
  if(new RegExp('Q').test(genericDate)) {

    let fixed = parseInt(genericDate.split('Q')[0]) - 1;

    if(waitEndPeriod) {
      if(today.month() <= 2) {
        today.year(today.year() - 1);
      }
    }

    today.month(fixed * 3 + (!isFrom ? 2 : 0));
    
    let opMinus = genericDate.split('-');
    let opPlus = genericDate.split('+');

    if(opMinus.length > 1) {
      today.subtract(parseInt(opMinus[1]) * 3, 'month');
    }
    else if(opPlus.length > 1) {
      today.add(parseInt(opPlus[1]) * 3, 'month');
    }
    genericDate = today.date(isFrom ? 1 : today.endOf('month').date()).format(BACKEND_DATE_FORMAT);

  } else
  if(new RegExp('^\B').test(genericDate)) {

    today.month(Math.floor((today.month())/2) * 2 + (!isFrom ? 1 : 0));
    
    let opMinus = genericDate.split('-');
    let opPlus = genericDate.split('+');

    if(opMinus.length > 1) {
      today.subtract(parseInt(opMinus[1]) * 2, 'month');
    }
    else if(opPlus.length > 1) {
      today.add(parseInt(opPlus[1]) * 2, 'month');
    }
    genericDate = today.date(isFrom ? 1 : today.endOf('month').date()).format(BACKEND_DATE_FORMAT);

  } else
  if(new RegExp('B').test(genericDate)) {

    let fixed = parseInt(genericDate.split('B')[0]) - 1;

    if(waitEndPeriod) {
      if(today.month() <= 1) {
        today.year(today.year() - 1);
      }
    }

    today.month(fixed * 2 + (!isFrom ? 1 : 0));
    
    let opMinus = genericDate.split('-');
    let opPlus = genericDate.split('+');

    if(opMinus.length > 1) {
      today.subtract(parseInt(opMinus[1]) * 2, 'month');
    }
    else if(opPlus.length > 1) {
      today.add(parseInt(opPlus[1]) * 2, 'month');
    }
    genericDate = today.date(isFrom ? 1 : today.endOf('month').date()).format(BACKEND_DATE_FORMAT);

  } else*/
  if(new RegExp('^\M').test(genericDate)) {
    
    let opMinus = genericDate.split('-');
    let opPlus = genericDate.split('+');

    if(opMinus.length > 1) {
      today.subtract(parseInt(opMinus[1]), 'month');
    }
    else if(opPlus.length > 1) {
      today.add(parseInt(opPlus[1]), 'month');
    }
    genericDate = today.date(isFrom ? 1 : today.endOf('month').date()).format(BACKEND_DATE_FORMAT);

  } else
  if(new RegExp('M').test(genericDate)) {

    let fixed = parseInt(genericDate.split('M')[0]) - 1;

    /*if(waitEndPeriod) {
      if(today.month() == 0) {
        today.year(today.year() - 1);
      }
    }*/

    today.month(fixed);
    
    let opMinus = genericDate.split('-');
    let opPlus = genericDate.split('+');

    if(opMinus.length > 1) {
      today.subtract(parseInt(opMinus[1]), 'month');
    }
    else if(opPlus.length > 1) {
      today.add(parseInt(opPlus[1]), 'month');
    }
    genericDate = today.date(isFrom ? 1 : today.endOf('month').date()).format(BACKEND_DATE_FORMAT);

  } else
  if(new RegExp('^\D').test(genericDate)) {
    
    let opMinus = genericDate.split('-');
    let opPlus = genericDate.split('+');

    if(opMinus.length > 1) {
      today.subtract(parseInt(opMinus[1]), 'day');
    }
    else if(opPlus.length > 1) {
      today.add(parseInt(opPlus[1]), 'day');
    }

    genericDate = today.format(BACKEND_DATE_FORMAT);

  } else
  if(new RegExp('D').test(genericDate)) {

    let fixed = parseInt(genericDate.split('J')[0]);

    /*if(waitEndPeriod) {
      if(today.dayOfYear() == 1) {
        today.year(today.year() - 1);
      }
    }*/

    today.dayOfYear(fixed);
    
    let opMinus = genericDate.split('-');
    let opPlus = genericDate.split('+');

    if(opMinus.length > 1) {
      today.subtract(parseInt(opMinus[1]), 'day');
    }
    else if(opPlus.length > 1) {
      today.add(parseInt(opPlus[1]), 'day');
    }

  } 

  return genericDate;

}