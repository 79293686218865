/**
 * @module Theme
 */

import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-show-details-option',
  templateUrl: './show-details-option.component.html',
  styleUrls: ['./show-details-option.component.css']
})
export class ShowDetailsOptionComponent {

  @Input() showDetails: boolean = false;;
  @Output() sendShowDetails: EventEmitter<boolean>;

  constructor() { 
    this.sendShowDetails = new EventEmitter<boolean>();
  }

  onShowDetails() {
    this.showDetails = !this.showDetails;
    this.sendShowDetails.emit(this.showDetails);
  }

}
