/**
 * @module widget
 */

import { Component } from '@angular/core';

@Component({
  selector: 'app-widget-loading',
  templateUrl: './widget-loading.component.html',
  styleUrls: ['./widget-loading.component.css']
})
export class WidgetLoadingComponent {

  constructor() { }

}
