/**
 * @module Form
 */

import { Component, Input, Output, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { UID } from '@models/uid.model';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-links-item-option',
  templateUrl: './links-item-option.component.html',
  styleUrls: ['./links-item-option.component.css']
})
export class LinksItemOptionComponent implements OnDestroy, OnInit {

  @Input() uid: UID;
  @Input() symbol: string;
  @Input() selected: boolean;
  @Input() selected$: Subject<boolean>;
  @Input() selectable: boolean = true;
  @Input() shadow: boolean = false;
  @Input() color: string = 'green';

  @Output() sendSelected: EventEmitter<{ uid: UID, selected: boolean }>;

  selected$sub: Subscription;

  constructor() {
    this.sendSelected = new EventEmitter<{ uid: UID, selected: boolean }>();
  }

  onSelected() {
    if(this.selectable) this.sendSelected.emit({ uid: this.uid, selected: !this.selected });
  }

  ngOnInit() {
    if(this.selected$ !== undefined) {
      this.selected$sub = this.selected$.subscribe({
        next: (selected: boolean) => {
          if(this.selectable) this.sendSelected.emit({ uid: this.uid, selected: selected });
        }
      });
    }
  }
  
  ngOnDestroy() {
    if(this.selected$sub !== undefined) this.selected$sub.unsubscribe();
  }


}
