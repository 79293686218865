<div class="alert alert-info font-small mb-1" role="alert">
  <i class="fad fa-info-square mr-1"></i>
  <i class="fad fa-lock-alt mr-1"></i>
  {{ 'DESIGNER_FILTERS' | translate }}
</div>

<app-links-item *ngFor="let indicator of widgetLayers.indicators" [color]="color" [icon]="icon" [labels]="indicator.labels" [uid]="indicator.uid">
  <div app-links-item-content-description>
    
  </div>
  <div app-links-item-content-sub class="mt-3">
    <div *ngFor="let filter of indicator.filters ; let isLast = last">
      <div *ngIf="filter.values.length > 0" class="p-2 rounded white mt-1 d-flex w-100 align-items-center">
        <div class="mr-auto font-small">
          <span class="font-weight-bold">{{ widgetLayers.layer(filter.uid.value).layerLabel.value(language) }}</span><br>
          {{ (filter.include ? 'INCLUSION_MODE' : 'EXCLUSION_MODE') | translate }}
        </div>
        <div *ngIf="filter.include" class="app-widget-designer-filters-circle rounded-circle green lighten-3 d-flex justify-content-center align-items-center">
          <i class="fad fa-check green-text animated rotateIn"></i>
        </div>
        <div *ngIf="!filter.include" class="app-widget-designer-filters-circle rounded-circle red lighten-3 d-flex justify-content-center align-items-center">
          <i class="fad fa-times red-text animated rotateIn"></i>
        </div>
      </div>
      <div class="d-flex" [ngClass]="{ 'mb-3': !isLast }">
        <div class="font-weight-bolder font-very-small py-1 px-2 rounded mt-2 mr-2 lighten-4 {{ filter.include ? 'green green-text' : 'red red-text' }}" *ngFor="let value of filter.values">{{ value }}</div>
      </div>
    </div>
    <div *ngIf="indicator.filters.length == 0" class="font-small p-2 rounded white mt-1 d-flex w-100 align-items-center">
      {{ 'NO_DESIGNER_FILTERS' | translate }}
    </div>
  </div>
</app-links-item>