/**
 * @module Indicator
 */

import { Component, ViewChild, Input, OnDestroy } from '@angular/core';
import { EditorComponent } from '@components/forms/editor/editor.component';
import { IndicatorClass } from '@class/indicator.class';
import { MessagesService } from '@services/messages/messages.service';
import { IndicatorService } from '@services/indicator/indicator.service';
import { LanguageType } from '@models/language.model';
import { AuthService } from '@services/auth/auth.service';
import { Subscription } from 'rxjs';
import { ItemColor } from '@functions/item.functions';

@Component({
  selector: 'app-indicator-editor',
  templateUrl: './indicator-editor.component.html',
  styleUrls: ['./indicator-editor.component.css']
})
export class IndicatorEditorComponent implements OnDestroy {

  @Input() current: IndicatorClass;

  @ViewChild('editor', { static: true }) editor: EditorComponent;

  color = ItemColor('indicators');
  editorIndex: number;
  editorIndexMax: number;
  language: LanguageType;
  language$sub: Subscription;

  constructor(
    private $indicators: IndicatorService,
    private $auth: AuthService,
    private $messages: MessagesService
  ) {
    this.editorIndex = 0;
    this.editorIndexMax = 7; //TOKNOW : 7 on masque temporairement la notion de serveur dans la V3

    this.language$sub = this.$auth.language$.subscribe({
      next: (language: LanguageType) => {
        this.language = language;
      }
    });
  }

  ngOnDestroy() {
    this.language$sub.unsubscribe();
  }

  public show() {
    this.editor.show();
  }

  receiveRestore() {
    this.current.reset();
  }

  receiveDelete() {
    this.editorIndex = NaN;
  }

  receiveDeleteConfirmation() {
    this.$indicators.remove(this.current.uid.value, this.current.serverLink)
    .then(() => {
      this.editor.hide();
      this.editorIndex = 0;
      this.$messages.success({ 
        title: { plain: this.current.labels.value(this.language) }, 
        text: { code: 'SUCCESSFULLY_REMOVED/TEXT' } 
      });
      this.current = this.$indicators.create();
    })
    .catch((error) => {
      this.$messages.error({ 
        title: { plain: this.current.labels.value(this.language) }, 
        text: { 
          code: 'REMOVE_FAILED/TEXT',
          params: { error: error.code }
        } 
      });
    });
  }

  receiveSave() {
    this.$indicators.set(this.current)
    .then(() => {
      if(!this.current.uid.isRegistered) {
        this.$messages.info({ 
          title: { plain: this.current.labels.value(this.language) }, 
          text: { code: 'DELAY_TO_VALIDATE/TEXT' } 
        });
        this.current = this.$indicators.create();
      }
      this.editor.hide();
      this.$messages.success({ 
        title: { plain: this.current.labels.value(this.language) }, 
        text: { code: 'SUCCESSFULLY_SAVED/TEXT' } 
      });
    })
    .catch((error) => {
      this.$messages.error({ 
        title: { plain: this.current.labels.value(this.language) }, 
        text: { 
          code: 'SAVE_FAILED/TEXT',
          params: { error: error.code }
        } 
      });
    });
  }

  receiveNext(isNext: boolean) {
    this.editorIndex = this.editorIndex + (isNext ? 1 : -1);
    if(this.editorIndex < 0) this.editorIndex = this.editorIndexMax;
    if(this.editorIndex > this.editorIndexMax) this.editorIndex = 0;
  }  

}
