/**
 * @module Bookmark
 */

import { BookmarksModel } from '@models/bookmarks.model';

export function BookmarksModelConstructor(): BookmarksModel {
  return {
    bookmarks: {
      dashboards: {},
      widgets: {},
      servers: {},
      indicators: {},
      pivots: {},
      dates: {},
      users: {},
      themes: {},
      tags: {},
      rights: {}
    }
  };
}