<app-theme-option [categories]="['THEME']" [label]="'IMPORT_EXPORT'">
  <div app-theme-option-content>
   <button mdbBtn (click)="onImport()" type="button" color="light" size="sm" class="px-2 z-depth-0 m-0" mdbWavesEffect>
      <i class="fas fa-file-import fa-fw {{ color }}-text"></i>
    </button>
   <button mdbBtn (click)="onExport()" type="button" color="light" size="sm" class="px-2 z-depth-0 m-y mr-0 ml-2" mdbWavesEffect>
      <i class="fas fa-file-export fa-fw {{ color }}-text"></i>
    </button>
  </div>
</app-theme-option>

<input id="file" accept=".theme" 
  style="position: fixed; top: -100px; opacity:0;" type="file" (change)="onFile($event.target.files[0])" 
  onclick="this.value=null;" mdbFileSelect [options] ="{ concurrency: 1, maxUploads: 1 }">