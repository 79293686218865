/**
 * @module Descriptions
 */

import { Component, OnDestroy, Input } from '@angular/core';
import { DescriptionsClass } from '@class/descriptions.class';
import { LanguageType } from '@models/language.model';
import { Subscription } from 'rxjs';
import { AuthService } from '@services/auth/auth.service';
import { LanguagesTypes } from '@functions/language.functions';
import { DescriptionType } from '@models/descriptions.model';

@Component({
  selector: 'app-descriptions',
  templateUrl: './descriptions.component.html',
  styleUrls: ['./descriptions.component.css']
})
export class DescriptionsComponent implements OnDestroy {

  @Input() label: string = 'DESCRIPTION';
  @Input() descriptions: DescriptionsClass;
  @Input() color: string;

  languages: LanguageType[];
  current: LanguageType;
  language$sub: Subscription;

  constructor(
    private $auth: AuthService
  ) {
    this.languages = LanguagesTypes();

    this.language$sub = this.$auth.language$.subscribe({
      next: (language: LanguageType) => {
        this.current = language;
      }
    });
  }

  ngOnDestroy() {
    this.language$sub.unsubscribe();
  }

  onDescription(description: string, language: LanguageType) {
    let descriptions = Object.assign({}, this.descriptions.model.descriptions, { [language]: description } as DescriptionType);
    this.descriptions.change({ descriptions: descriptions });
  }

}