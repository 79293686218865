/**
 * @module Dashboard
 */

import { Component, Input } from '@angular/core';
import { DashboardLayoutClass } from '@class/dashboardLayout.class';
import { ItemColor } from '@functions/item.functions';

@Component({
  selector: 'app-dashboard-layout-padding',
  templateUrl: './dashboard-layout-padding.component.html',
  styleUrls: ['./dashboard-layout-padding.component.css']
})
export class DashboardLayoutPaddingComponent {

  @Input() dashboardLayout: DashboardLayoutClass;

  color = ItemColor('dashboards');
  
  constructor() {}

  receiveValue(padding: number) {
    this.dashboardLayout.change({ padding: padding });
  }

}
