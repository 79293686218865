/**
 * @module Dashboard
 */

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UID } from '@models/uid.model';
import { DashboardLayoutCellClass } from '@class/dashboardLayoutCell.class';
import { ItemColor, ItemIcon } from '@functions/item.functions';
import { take } from 'rxjs/operators';
import { WidgetService } from '@services/widget/widget.service';
import { WidgetClass } from '@class/widget.class';
import { LanguageType } from '@models/language.model';
import { AuthService } from '@services/auth/auth.service';
import { Subscription } from 'rxjs';
import { LabelsClass } from '@class/labels.class';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard-cell-widget',
  templateUrl: './dashboard-cell-widget.component.html',
  styleUrls: ['./dashboard-cell-widget.component.css']
})
export class DashboardCellWidgetComponent implements OnInit, OnChanges {

  @Input() cell: DashboardLayoutCellClass;

  color = ItemColor('widgets');
  colorDash = ItemColor('dashboards');
  icon = ItemIcon('widgets');
  label: LabelsClass;
  language: LanguageType;
  language$sub: Subscription;

  constructor(
    private $widgets: WidgetService,
    private $auth: AuthService,
    private $router: Router
    ) {
    this.language$sub = this.$auth.language$.subscribe({
      next: (language: LanguageType) => {
        this.language = language;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.cell != undefined && !changes.cell.firstChange) this.setLabel(this.cell.uidWidget);
  }

  ngOnInit(): void {
    if(this.cell.uidWidget !== undefined) this.setLabel(this.cell.uidWidget);
  }

  receiveSelected(uidWidget: UID) {
    this.cell.change({ uidWidget: uidWidget });
    this.setLabel(uidWidget);
  }

  setLabel(uidWidget: UID) {
    this.$widgets.getPartial$(uidWidget).pipe(take(1)).subscribe({
      next: (widget: WidgetClass) => {
        this.label = widget.labels;
      }
    });
  }

  openWidget() {
    window.open(`/widget-studio?from=browser&panel=widgets&uid=${this.cell.uidWidget}`, '_blank')
  }

}
