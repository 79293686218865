<app-theme-option [categories]="themeOptions.optionsConfiguration[optionType].categories" [label]="themeOptions.optionsConfiguration[optionType].label" [information]="(rotation | number:'1.0-0') + '°'" [limitedTo]="limitedTo">
  <div app-theme-option-content>
    <app-show-details-option [showDetails]="showDetails" (sendShowDetails)="receiveShowDetails($event)">
      <div app-show-details-option-content>
        <app-slider [color]="color" [value]="rotation" (sendValue)="receiveValue($event)" [max]="360"></app-slider>
      </div>
    </app-show-details-option>
  </div>
</app-theme-option>

<div *ngIf="showDetails">
  <app-theme-option [categories]="themeOptions.optionsConfiguration[optionType].categoriesAndLabel" [label]="type | chart" [dark]="4" [information]="(rotations[type] | number:'1.0-0') + '°'" *ngFor="let type of limitedTo">
    <div app-theme-option-content>
      <app-slider [value]="rotations[type]" (sendValue)="receiveValue($event, type)" [color]="color" [max]="360"></app-slider>
    </div>
  </app-theme-option>
</div>