/**
 * @module Widget
 */

import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { WidgetLayersClass } from '@class/widgetLayers.class';
import { ItemColor, ItemIcon } from '@functions/item.functions';
import { SortableStopModel } from '@directives/sortable/sortable.directive';
import { ModificationStateClass } from '@class/modification.class';
import { Subscription } from 'rxjs';
import { WidgetLayerClass } from '@class/widgetLayer.class';

@Component({
  selector: 'app-widget-pivots-order',
  templateUrl: './widget-pivots-order.component.html',
  styleUrls: ['./widget-pivots-order.component.css']
})
export class WidgetPivotsOrderComponent implements OnInit, OnDestroy {

  @Input() widgetLayers: WidgetLayersClass;

  modifications$sub: Subscription;
  layers: WidgetLayerClass[];
  lastVisibleLayerRank: number;

  color = ItemColor('pivots', true);
  icon = ItemIcon('pivots');

  constructor() {
    this.lastVisibleLayerRank = 0;
  }

  ngOnInit() {
    this.layers = Object.values(this.widgetLayers.layers).sort((a, b) => a.rank - b.rank);
    const drillables = this.layers.filter(l => l.drillable);
    this.lastVisibleLayerRank = drillables.length > 0 ? drillables[drillables.length - 1].rank : 0;
    this.modifications$sub = this.widgetLayers.modifications$.subscribe({
      next:(modifications: ModificationStateClass) => {
        if(modifications.has('layers') || modifications.has('reload')) {
          this.layers = Object.values(this.widgetLayers.layers).sort((a, b) => a.rank - b.rank);
          const drillables = this.layers.filter(l => l.drillable);
          this.lastVisibleLayerRank = drillables.length > 0 ? drillables[drillables.length - 1].rank : 0;
        }
      }
    });
  }

  ngOnDestroy() {
    this.modifications$sub.unsubscribe();
  }

  receiveSortableStop(sortable: SortableStopModel) {
    this.widgetLayers.rankLayer(sortable.sourceIndex, sortable.targetIndex);
  }

}
