<app-browser-frame #frameModal (sendCreate)="receiveCreate()" (sendSearch)="receiveSearch($event)" [color]="color" [icon]="icon" [label]="label">
  
  <app-empty app-browser-frame-content-list [type]="'themes'" (sendAction)="receiveCreate()" *ngIf="themes != undefined && themes.length == 0"></app-empty>
  <app-loading app-browser-frame-content-list *ngIf="themes == undefined"></app-loading>
  
  <div app-browser-frame-content-list *ngFor="let theme of themes | searchLabels:searchLabel:language ; trackBy: trackByUid">
    <app-item-strip
    [labels]="theme.labels"
    [tags]="theme.tags"
    [type]="'themes'"
    [uid]="theme.uid"
    [icon]="icon"
    [color]="color"
    [isModified]="theme.modifications$.value.isModified"
    (sendEdit)="receiveEdit($event)"
    (sendStudio)="receiveStudio($event)"
    [isStudio]="true"
    ></app-item-strip>
  </div>
</app-browser-frame>

<app-theme-editor #editor [current]="current"></app-theme-editor>