/**
 * @module User
 */

import { Component, Input } from '@angular/core';
import { UserClass } from '@class/user.class';

@Component({
  selector: 'app-activation',
  templateUrl: './activation.component.html',
  styleUrls: ['./activation.component.css']
})
export class ActivationComponent {

  @Input() user: UserClass;

  constructor() {}

  onActivated(activated: boolean) {
    this.user.change({ activated: activated });
  }

}
