<app-links-item *ngIf="date" [color]="color" [icon]="icon" [labels]="date.labels" [uid]="date.uid">
  <div app-links-item-content-description>
  </div>
  <div app-links-item-content-option class="d-flex align-items-center">

  </div>
  <div app-links-item-content-sub class="mt-3 white px-3 rounded pb-3 pt-2">

    <div class="w-100 d-flex flex-column">
      <div class="d-flex align-items-center">
        <div class="flex-grow-1">
          <app-moment *ngIf="!bounds.greater.equivalent ; else greaterOrEquivalent" [value]="from" [label]="'FROM'" [color]="color" (sendValue)="receiveGreater($event, false)"></app-moment>
        </div>
        <app-links-item-option class="d-block ml-3 mt-2" [symbol]="'greater-than'" [selected]="!bounds.greater.equivalent" (sendSelected)="receiveSetGreater(false)"></app-links-item-option>
        <app-links-item-option class="d-block ml-2 mt-2" [symbol]="'greater-than-equal'" [selected]="bounds.greater.equivalent" (sendSelected)="receiveSetGreater(true)"></app-links-item-option>
        <button type="button" mdbBtn (click)="pickadatefromCollapse.toggle()" class="z-depth-0 d-flex px-2 align-items-center mb-0 mt-2 mr-0 ml-2" color="blue-grey" size="sm" style="width: 30px; height: 31px;" ><i class="fas fa-calendar-day fa-fw"></i></button>
      </div>
      <div class="mx-1 font-very-small text-muted">{{ fromFull }}</div>
    </div>

    <div class="my-2" mdbCollapse #pickadatefromCollapse="bs-collapse">
      <div #pickadatefrom></div>
    </div>
  
    <div class="w-100 d-flex flex-column">
      <div class="d-flex align-items-center">
        <div class="flex-grow-1">
          <app-moment *ngIf="!bounds.less.equivalent ; else lessOrEquivalent" [value]="to" [label]="'TO'" [color]="color" (sendValue)="receiveLess($event, false)"></app-moment>
        </div>
        <app-links-item-option class="d-block ml-3 mt-2" [symbol]="'less-than'" [selected]="!bounds.less.equivalent" (sendSelected)="receiveSetLess(false)"></app-links-item-option>
        <app-links-item-option class="d-block ml-2 mt-2" [symbol]="'less-than-equal'" [selected]="bounds.less.equivalent" (sendSelected)="receiveSetLess(true)"></app-links-item-option>
        <button type="button" mdbBtn (click)="pickadatetoCollapse.toggle()" class="z-depth-0 d-flex px-2 align-items-center mb-0 mt-2 mr-0 ml-2" color="blue-grey" size="sm" style="width: 30px; height: 31px;" ><i class="fas fa-calendar-day fa-fw"></i></button>

      </div>
      <div class="mx-1 font-very-small text-muted">{{ toFull }}</div>
    </div>

    <div class="my-2" mdbCollapse #pickadatetoCollapse="bs-collapse">
      <div #pickadateto></div>
    </div>

  </div>
</app-links-item>

<ng-template #greaterOrEquivalent>
  <app-moment [value]="from" [label]="'FROM'" [color]="color" (sendValue)="receiveGreater($event, true)"></app-moment>
</ng-template>

<ng-template #lessOrEquivalent>
  <app-moment [value]="to" [label]="'TO'" [color]="color" (sendValue)="receiveLess($event, true)"></app-moment>
</ng-template>