/**
 * @module Root
 */

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BrowserComponent } from '@components/browser/browser/browser.component';
import { AuthGuard } from '@services/auth/auth.guard';
import { AuthComponent } from '@components/auth/auth.component';
import { CompanyGuard } from '@services/company/company.guard';
import { CompanyComponent } from '@components/company/company.component';
import { ThemeStudioComponent } from '@components/themes/theme-studio/theme-studio/theme-studio.component';
import { WidgetStudioComponent } from '@components/widgets/widget-studio/widget-studio/widget-studio.component';
import { DashboardStudioComponent } from '@components/dashboards/dashboard-studio/dashboard-studio/dashboard-studio.component';
import { ImportStudioComponent } from '@components/import/import-studio/import-studio/import-studio.component';
import { DashboardDisplayComponent } from '@components/dashboards/dashboard-display/dashboard-display.component';
import { ConstructorComponent } from '@components/constructor/constructor.component';
import { AuthActionsComponent } from '@components/authActions/auth-actions/auth-actions.component';
import { IndicatorStudioComponent } from '@components/indicators/indicator-studio/indicator-studio/indicator-studio.component';
import { UnsupportedComponent } from '@components/unsupported/unsupported.component';

const routes: Routes = [
  { path: '', redirectTo: 'auth', pathMatch: 'full' },
  { path: 'constructor', component: ConstructorComponent },
  { path: 'company', component: CompanyComponent },
  { path: 'action', component: AuthActionsComponent },
  { path: 'unsupported', component: UnsupportedComponent },
  { path: 'auth', component: AuthComponent, canActivate: [CompanyGuard] },
  { path: 'browser', component: BrowserComponent, canActivate: [AuthGuard, CompanyGuard] },
  { path: 'import-studio', component: ImportStudioComponent, canActivate: [AuthGuard, CompanyGuard] },
  { path: 'theme-studio', component: ThemeStudioComponent, canActivate: [AuthGuard, CompanyGuard] },
  { path: 'indicator-studio', component: IndicatorStudioComponent, canActivate: [AuthGuard, CompanyGuard] },
  { path: 'dashboard-studio', component: DashboardStudioComponent, canActivate: [AuthGuard, CompanyGuard] },
  { path: 'dashboard-display', component: DashboardDisplayComponent, canActivate: [AuthGuard, CompanyGuard] },
  { path: 'widget-studio', component: WidgetStudioComponent, canActivate: [AuthGuard, CompanyGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
