/**
 * @module Formula
 */

import { Component, Input, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { UID } from '@models/uid.model';
import * as math from 'mathjs';
import { HelperFormulaComponent } from '@components/helpers/helper-formula/helper-formula.component';
import { LabelsClass } from '@class/labels.class';
import { Subscription } from 'rxjs';
import { LanguageType } from '@models/language.model';
import { AuthService } from '@services/auth/auth.service';

@Component({
  selector: 'app-formula',
  templateUrl: './formula.component.html',
  styleUrls: ['./formula.component.css']
})
export class FormulaComponent implements OnInit {

  @Input() formulasUID: UID[];
  @Input() labels: LabelsClass[];
  @Input() formula: string;
  @Input() color: string;
  @Output() sendFormula: EventEmitter<string>;
  @ViewChild('formulaHelper', { static: true }) formulaHelper: HelperFormulaComponent;

  isValid: boolean;
  language$sub: Subscription;
  language: LanguageType

  constructor(
    private $auth: AuthService
  ) {
    this.isValid = true;
    this.sendFormula = new EventEmitter<string>();

    this.language$sub = this.$auth.language$.subscribe({
      next: (language: LanguageType) => {
        this.language = language;
      }
    });
  }

  ngOnInit() {
    this.receiveValue(this.formula);
  }

  add(formulaUID: string) {
    this.formula = this.formula.concat(formulaUID);
    this.receiveValue(this.formula);
  }

  receiveValue(formula: string) {
    this.formula = formula;
    let test = formula.slice()
    this.formulasUID.forEach((formulaUID: UID) => test = test.replace(new RegExp(formulaUID, 'g'), (Math.floor(Math.random() * 1000) + 10).toString()));
    this.isValid = true;
    try {
      math.evaluate(test);
    }
    catch {
      this.isValid = false;
    }
    finally {
      if(this.isValid) this.sendFormula.emit(formula);
    }
  }

}
