/**
 * @module Import
 */

import { Component, OnInit, OnChanges, OnDestroy, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { ImportFrameClass } from '@class/importFrame.class';
import { ItemIcon, ItemColor } from '@functions/item.functions';
import { UID } from '@models/uid.model';
import { Subscription, Observable } from 'rxjs';
import { LanguageType } from '@models/language.model';
import { ImportService } from '@services/import/import.service';
import { MessagesService } from '@services/messages/messages.service';
import { AuthService } from '@services/auth/auth.service';
import { ImportFrameUploadModel } from '@models/importFrame.model';
import { takeWhile, tap } from 'rxjs/operators';

@Component({
  selector: 'app-import-indicators-select',
  templateUrl: './import-indicators-select.component.html',
  styleUrls: ['./import-indicators-select.component.css']
})
export class ImportIndicatorsSelectComponent implements OnChanges, OnDestroy {

  @Input() importFrames: ImportFrameClass[];
  @Output() sendCurrent: EventEmitter<ImportFrameClass>;
  
  icon = ItemIcon('indicators');
  color = ItemColor('indicators');
  current: UID;
  oneValid: boolean;
  subs: Subscription[]; 
  language: LanguageType;
  language$sub: Subscription;

  constructor(
    private $import: ImportService,
    private $messages: MessagesService,
    private $auth: AuthService
  ) {
    this.oneValid = false;
    this.subs = [];
    this.sendCurrent = new EventEmitter<ImportFrameClass>();

    this.language$sub = this.$auth.language$.subscribe({
      next: (language: LanguageType) => {
        this.language = language;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.importFrames !== undefined && changes.importFrames.currentValue !== undefined) {
      this.current = this.importFrames[0].uid;
      this.subs.forEach((sub: Subscription) => {
        sub.unsubscribe();
      });
      this.subs = [];
      this.importFrames.forEach((importFrame: ImportFrameClass) => {
        this.subs.push(importFrame.dataFrame.valuesChanged$.subscribe((changed: boolean) => {
          this.oneValid = this.importFrames.map((importFrame: ImportFrameClass) => {
            return (!importFrame.dataFrame.isErrors && !importFrame.getUpload().isEnded) || (importFrame.getUpload().isEnded && importFrame.getUpload().isErrors);
          }).indexOf(true) !== -1;
        }));
      });
    }
  }

  ngOnDestroy() {
    this.language$sub.unsubscribe();
    if(this.subs.length > 0) {
      this.subs.forEach((sub: Subscription) => {
        sub.unsubscribe();
      });
    }
  }

  onUploadAll() {
    let streams = this.$import.uploadAll();
    streams.forEach((stream: { importFrame: ImportFrameClass, obs: Observable<ImportFrameUploadModel> }) => {
      stream.obs.pipe(
        takeWhile((upload: ImportFrameUploadModel) => {
          return !upload.isEnded;
        }, true),
        tap((upload: ImportFrameUploadModel) => {
          if(upload.isEnded) {
            if(upload.isErrors) {
              this.$messages.warning({ title: { plain: stream.importFrame.indicator.labels.value(this.language) }, text: { code: 'DATA_UPLOAD_FAILED', params: { rows: upload.lines.errors.toString() } } });
            }
            if(upload.lines.uploaded > 0) {
              this.$messages.success({ title: { plain: stream.importFrame.indicator.labels.value(this.language) }, text: { code: 'DATA_UPLOADED', params: { rows: upload.lines.uploaded.toString() } } });
            }
            if(!upload.isErrors) this.receiveUploadComplete(stream.importFrame.uid);
          }
        })
      ).subscribe();
    });
  }

  onSelect(importFrame: ImportFrameClass) {
    this.current = importFrame.uid;
    this.sendCurrent.emit(importFrame);
  }

  receiveUploadComplete(uid: UID) {
    this.importFrames = this.$import.removeImportFrame(uid);
    if(this.importFrames !== undefined && this.importFrames.length > 0) this.current = this.importFrames[0].uid;
    else this.current = undefined;
  }
}
