<app-editor #editor (sendRestore)="receiveRestore()" (sendDelete)="receiveDelete()" (sendSave)="receiveSave()"
  [title]="current.id.fullname" [uid]="current.uid.value" [icon]="icon" [color]="color" [isRegistered]="current.uid.isRegistered" [isValid]="current.isValid()" [isModified]="current.isModified">
  <div app-editor-content-menu>

    <a class="dropdown-item z-depth-0" (click)="editorIndex = 0">{{ 'PERSONAL_DATA' | translate }}</a>
    <a class="dropdown-item z-depth-0" (click)="editorIndex = 1">{{ 'ACCOUNT' | translate }}</a>

  </div>
  <div app-editor-content-body *ngIf="editor.isShown">
    <div [ngSwitch]="editorIndex">
      <div *ngSwitchCase="0">
        <app-editor-section-title [title]="'PERSONAL_DATA'" (sendNext)="receiveNext($event)"></app-editor-section-title>
        <app-user-id [userId]="current.id" [color]="color"></app-user-id>
      </div>
      <div *ngSwitchCase="1">
        <app-editor-section-title [title]="'ACCOUNT'" (sendNext)="receiveNext($event)"></app-editor-section-title>
        <app-language [user]="current" class="d-block mt-2"></app-language>
        <app-avatar [user]="current" class="d-block mt-2"></app-avatar>
        <app-email-reset [user]="current" class="d-block mt-2"></app-email-reset>
      </div>
      <div *ngSwitchDefault>
        <app-delete [code]="current.uid.code" (sendDelete)="receiveDeleteConfirmation($event)"></app-delete>
      </div>
    </div>
  </div>
</app-editor>