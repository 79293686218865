/**
 * @module Dashboard
 */

import { Component, Input } from '@angular/core';
import { DashboardLayoutCellClass } from '@class/dashboardLayoutCell.class';
import { ItemColor } from '@functions/item.functions';

@Component({
  selector: 'app-dashboard-cell-display',
  templateUrl: './dashboard-cell-display.component.html',
  styleUrls: ['./dashboard-cell-display.component.css']
})
export class DashboardCellDisplayComponent {

  @Input() cell: DashboardLayoutCellClass;

  color = ItemColor('dashboards');
  
  constructor() {}

  receiveValue(display: boolean) {
    this.cell.change({ display: display });
  }

}
