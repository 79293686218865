/**
 * @module Theme
 */

import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ItemColor } from '@functions/item.functions';

@Component({
  selector: 'app-theme-option-font-style',
  templateUrl: './theme-option-font-style.component.html',
  styleUrls: ['./theme-option-font-style.component.css']
})
export class ThemeOptionFontStyleComponent implements OnInit, OnChanges {

  @Input() fontStyle: string;
  @Output() sendFontStyle: EventEmitter<string>;

  color = ItemColor('themes');
  styles: string[];
  isBold: boolean;
  isItalic: boolean;

  constructor() {
    this.sendFontStyle = new EventEmitter<string>();
    this.styles = [];
  }

  ngOnInit() {
    this.setStyles();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.fontStyle !== undefined && !changes.fontStyle.firstChange) {
      this.setStyles();
    }
  }

  setStyles() {
    this.styles = this.fontStyle.split(' ');
    this.isBold = this.styles.indexOf('bold') !== -1;
    this.isItalic = this.styles.indexOf('italic') !== -1;
  }

  receiveBold() {
    const index = this.styles.indexOf('bold');
    this.isBold = index === -1;
    if(index === -1) this.styles.push('bold');
    else this.styles.splice(index, 1);
    this.sendFontStyle.emit(this.styles.join(' '));
  }

  receiveItalic() {
    const index = this.styles.indexOf('italic');
    this.isItalic = index === -1;
    if(index === -1) this.styles.push('italic');
    else this.styles.splice(index, 1);
    this.sendFontStyle.emit(this.styles.join(' '));
  }

}
