/**
 * @module Modification
 */

import { FormGroup, FormBuilder, ValidationErrors } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { DeepCopy } from '@functions/copy.functions';
import { IsLanguage, LanguagesTypes } from '@functions/language.functions';
import { LanguageType } from '@models/language.model';
import { RequirementType, RequirementsType } from '@models/requirement.model';
import { OnDestroy } from '@angular/core';
import { MapClass } from '@class/map.class';

export class ModificationStateClass {

  private _attributes: MapClass<boolean>;

  constructor() {
    this._attributes = new MapClass();
  }

  add(attribute: string) {
    this._attributes.add(attribute, true);
  }

  remove(attribute: string) {
    this._attributes.remove(attribute);
  }

  flush() {
    this._attributes = new MapClass();
  }

  get list(): Readonly<string[]> {
    return this._attributes.keys;
  }

  has(attribute: string): Readonly<boolean> {
    return this._attributes.has(attribute);
  }

  get isModified(): Readonly<boolean> {
    return this._attributes.size > 0;
  }
}

export abstract class ModificationClass<T> implements OnDestroy {

  protected abstract _parent: T;
  protected abstract _inital: T;
  protected abstract _attributes: string[];

  protected _form: FormGroup;
  protected _requirements: RequirementsType;
  protected _modifications$: BehaviorSubject<ModificationStateClass>;
  protected _lastModification$: BehaviorSubject<string>;
  protected _state: ModificationStateClass;

  constructor() {
    this._form = (new FormBuilder).group({});
    this._requirements = {} as RequirementsType;
    this._state = new ModificationStateClass();
    this._modifications$ = new BehaviorSubject<ModificationStateClass>(this._state);
    this._lastModification$ = new BehaviorSubject<string>(undefined);
  }

  ngOnDestroy() {
    this._modifications$.unsubscribe();
    this._lastModification$.unsubscribe();
  }

  change(attributes: Partial<T>, sendMessage: boolean = true, parent: T = this._parent) {

    const keys = Object.keys(attributes || {});
    
    keys.forEach(key => {

      let isLanguages = true;

      if(typeof attributes[key] === 'object' && !(attributes[key] instanceof Array)) {
        const languages = Object.keys(attributes[key] || {});
        let validValue = '';

        if(Object.keys(languages).length > 0) {
          languages.forEach((language: LanguageType) => {
            if(isLanguages && IsLanguage(language)) {
              isLanguages = true;
              const k = `${key}@${language}`;
              if(this._form.controls[k] !== undefined) {
                this._form.patchValue({ [k]: attributes[key][language] });
                this._form.controls[k].markAsDirty();
                if(this._form.controls[k].valid) validValue = this._form.controls[k].value;
              }
              parent[key][language] = attributes[key][language];
            }
            else {
              isLanguages = false;
            }
          });
        }
        else {
          isLanguages = false;
        }
        if(isLanguages) {
          languages.forEach((language: LanguageType) => {
            const k = `${key}@${language}`;
            if(this._form.controls[k] !== undefined && !this._form.controls[k].valid) {
              this._form.patchValue({ [k]: validValue });
              this._form.controls[k].markAsDirty();
            }
          });
        }
      }
      else {
        isLanguages = false;
      }
      
      if(!isLanguages) {
        if(attributes[key] !== null && typeof attributes[key] === 'object' && !(attributes[key] instanceof Array) && !(attributes[key] instanceof File)) {
          this.change(attributes[key], sendMessage, parent[key]);
        }
        else {
          if(this._form.controls[key] !== undefined) {
            this._form.patchValue({ [key]: attributes[key] });
            this._form.controls[key].markAsDirty();
          }
          parent[key] = attributes[key];
        }
      }

      if(sendMessage) {
        this._state.add(key);
        this._lastModification$.next(key);
      }
    });

    this._form.updateValueAndValidity();
    if(sendMessage) this._modifications$.next(this._state);
  }

  emit(attributesKey: string[]) {
    attributesKey.forEach(key => {
      this._state.add(key);
      this._lastModification$.next(key);
    });
    this._modifications$.next(this._state);
  }

  restore() {
    this._parent;
  }

  get copy(): Readonly<T> {
    return DeepCopy<T>(this._parent);
  }

  get initial(): Readonly<T> {
    return this._inital;
  }

  get modifications$(): BehaviorSubject<ModificationStateClass> {
    return this._modifications$;
  }

  get lastModification$(): BehaviorSubject<string> {
    return this._lastModification$;
  }

  set modifications$(modifications$: BehaviorSubject<ModificationStateClass>) {
    this._modifications$ = modifications$;
  }

  get model(): Readonly<T> {
    let model = {} as T;
    this._attributes.forEach((attribute: string) => {
      if(this._parent[attribute] !== undefined) model[attribute] = this._parent[attribute];
    });
    return model;
  }

  isValid(attribute?: string, language?: boolean): Readonly<boolean> {
    if(attribute !== undefined) {
      if(language === true) {
        return LanguagesTypes().filter((language: LanguageType) => this._form.controls[`${attribute}@${language}`].valid).length > 0;
      }
      else {
        if(this._form.controls[attribute] !== undefined) return this._form.controls[attribute].valid;
        else return true;
      }
    }
    else {
      return this._form.valid;
    }  
  }

  errors(attribute?: string): ValidationErrors {
    if(attribute !== undefined) {
      if(this._form.controls[attribute] !== undefined) return this._form.controls[attribute].errors;
      else return null;
    }
    else {
      return this._form.errors;
    } 
  }

  requirement(attribute: string): Readonly<RequirementType> {
    if(this._requirements.hasOwnProperty(attribute)) return this._requirements[attribute];
    else return {};
  }

  get requirements(): Readonly<RequirementsType> {
    let requirements = {} as RequirementsType;
    this._attributes.forEach((attribute: string) => {
      if(this._requirements[attribute] !== undefined) requirements[attribute] = this._requirements[attribute];
    });
    return requirements;
  }

  get isModified(): Readonly<boolean> {
    let isModified = false as boolean;
    this._attributes.forEach((attribute: string) => {
      if(!isModified && this._state.has(attribute)) isModified = true;
    });
    return isModified;
  }
}