/**
 * @module DownloadFileType
 */

import { Component, Output, EventEmitter, Input } from '@angular/core';
import { BookType } from 'xlsx/types';
import { DownloadFileTypes } from '@functions/downloadFileType.functions';
import { DownloadFileTypeModel } from '@models/downloadFileType.model';

@Component({
  selector: 'app-download-file-type',
  templateUrl: './download-file-type.component.html',
  styleUrls: ['./download-file-type.component.css']
})
export class DownloadFileTypeComponent {

  @Input() label: string = 'DOWNLOAD';
  @Output() sendFileType: EventEmitter<BookType>;

  mains: DownloadFileTypeModel[];
  types: DownloadFileTypeModel[];
  
  constructor() {
    this.sendFileType = new EventEmitter<BookType>();
    this.mains = DownloadFileTypes().filter((type: DownloadFileTypeModel) => type.main);
    this.types = DownloadFileTypes().filter((type: DownloadFileTypeModel) => !type.main);
  }

  onType(type: DownloadFileTypeModel) {
    this.sendFileType.emit(type.bookType);
  }
}
