/**
 * @module Labels
 */

import { Component, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { LabelsClass } from '@class/labels.class';
import { LanguagesTypes } from '@functions/language.functions';
import { LanguageType } from '@models/language.model';
import { LabelType, LabelsModel } from '@models/labels.model';
import { AuthService } from '@services/auth/auth.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-labels',
  templateUrl: './labels.component.html',
  styleUrls: ['./labels.component.css']
})
export class LabelsComponent implements OnDestroy {

  @Input() label: string = 'LABEL';
  @Input() labels: LabelsClass;
  @Input() color: string;
  @Output() sendLabel: EventEmitter<LabelsModel>;

  languages: LanguageType[];
  current: LanguageType;
  language$sub: Subscription;

  constructor(
    private $auth: AuthService
  ) {
    this.languages = LanguagesTypes();

    this.language$sub = this.$auth.language$.subscribe({
      next: (language: LanguageType) => {
        this.current = language;
      }
    });

    this.sendLabel = new EventEmitter<LabelsModel>();
  }

  ngOnDestroy() {
    this.language$sub.unsubscribe();
  }

  onLabel(label: string, language: LanguageType) {
    let labels = Object.assign({}, this.labels.model.labels, { [language]: label } as LabelType);
    this.labels.change({ labels: labels });
    this.sendLabel.emit({ labels: labels });
  }

}
