<app-studio #studio [type]="'indicators'">

  <!-- BLOC MAIN -->
  <div app-studio-main-content>
    <div class="d-flex">
      <button [disabled]="worker.step != 'wSelectScope' || worker.status == 'wWorking'" mdbBtn type="button" color="default" class="z-depth-0 my-0 ml-0 mr-1 px-sm-4 px-3" mdbWavesEffect (click)="onContinue()">
        <i class="d-sm-none far fa-ballot-check fa-fw"></i>
        <i class="d-none d-sm-inline far fa-ballot-check"></i>
        <span class="d-none d-sm-inline ml-1">{{ 'CONTINUE' | translate }}</span>
      </button>
      <button [disabled]="worker.status == 'wWorking'" mdbBtn type="button" [color]="colors['indicators']" class="z-depth-0 flex-grow-1 m-0" mdbWavesEffect (click)="onFile()">
        <i *ngIf="worker.status != 'wWorking'" class="far fa-file-import"></i>
        <i *ngIf="worker.status == 'wWorking'" class="far fa-circle-notch fa-spin"></i>
        <span class="ml-1">{{ 'SELECT' | translate }}</span>
      </button>
    </div>

    <hr *ngIf="importFrames.length > 0">

    <app-import-indicators-select *ngIf="importFrames.length > 0" [importFrames]="importFrames" (sendCurrent)="receiveCurrent($event)"></app-import-indicators-select>

    <hr *ngIf="importFrames.length > 0 && current">

    <app-import-import-frame *ngIf="importFrames.length > 0 && current" [importFrame]="current" [filters$]="filters$" (sendSelectedKey)="receiveSelectedKey($event)"></app-import-import-frame>

  </div>
  <!-- ./BLOC MAIN -->

  <!-- BLOC ITEM -->
  <div app-studio-item-content *ngIf="worker.status != 'wSleeping' && worker.step != 'wSelectScope' ; else app_empty">
    <div *ngIf="worker.status != 'wWaitingUser' && worker.status != 'wSleeping' && worker.status != 'wError' ; else import_values" class="p-3 d-block">
      <app-import-progress [worker]="worker"></app-import-progress>
    </div>
  </div>
  <!-- ./BLOC ITEM -->

  <!-- BLOC SUB -->
  <div app-studio-sub-content>
    <app-import-item-params *ngIf="key ; else selection_empty" [key]="key" [filters$]="filters$" [importFrame]="current" (sendRemoved)="receiveRemoved($event)"></app-import-item-params>
  </div>
  <!-- ./BLOC SUB -->

</app-studio>

<ng-template #import_values>
  <app-import-values *ngIf="current" [filters$]="filters$" [importFrame]="current" (sendUploadComplete)="receiveUploadComplete($event)" class="p-3 d-block"></app-import-values>
</ng-template>

<ng-template #selection_empty>
  <app-empty [action]="false" [description]="'SELECT_INDICATOR_PIVOT_OR_DATE/TEXT'"></app-empty>
</ng-template>

<ng-template #app_empty>
  <app-empty [actionText]="'SELECT'" [actionIcon]="'fa-file-import'" [actionDescription]="'NEW_DATA/TEXT'" (sendAction)="onFile()" [type]="'indicators'" class="p-3 d-block"></app-empty>
</ng-template>

<input id="file" accept=".xlsm, .csv, .xls, .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel" 
  style="position: fixed; top: -100px; opacity:0;" type="file" (change)="onChange($event.target.files[0])" 
  onclick="this.value=null;" mdbFileSelect [options] ="{ concurrency: 1, maxUploads: 1 }">

<app-import-scope #scope [dataFrames]="worker.values" (sendDataFrames)="receiveDataFrames($event)"></app-import-scope>