/**
 * @module Chart
 */

import { Component, OnInit, Input, OnDestroy, ViewChild } from '@angular/core';
import { ThemeOptionsClass } from '@class/themeOptions.class';
import { ChartType } from '@models/chart.model';
import { Label } from 'ng2-charts';
import { ChartDataSets, ChartOptions, OrientationType } from 'chart.js';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ThemeStudioService } from '@services/themeStudio/theme-studio.service';
import { DeepMerge } from '@functions/copy.functions';
import { CarouselComponent } from 'ng-uikit-pro-standard';
import { Brightness } from '@functions/color.functions';
import { Total } from '@functions/math.functions';

@Component({
  selector: 'app-sticker-chart',
  templateUrl: './sticker-chart.component.html',
  styleUrls: ['./sticker-chart.component.css']
})
export class StickerChartComponent implements OnInit, OnDestroy {

  public type: ChartType = 'sticker';

  @Input() options: ThemeOptionsClass;
  @Input() fullyColored: boolean;
  @Input() template: number;
  @Input() orientation: OrientationType;
  @ViewChild('carousel') carousel: CarouselComponent;

  public labels: Label[] = [];
  public data: ChartDataSets[] = [];
  public datasets: ChartDataSets[] = [];
  public mergedOptions: ChartOptions;
  public localOptions: ChartOptions = {
    title: {
      text: '',
    }
  };
  public plugins = [];
  public isBright: boolean;
  public styles: any;

  changedOptions$sub: Subscription;
  changedSeries$sub: Subscription;
  data$sub: Subscription;

  constructor(
    private $translate: TranslateService,
    private $themeStudio: ThemeStudioService
  ) {
    this.localOptions.title.text = `${this.$translate.instant('TITLE')} - ${this.$translate.instant(this.type.toUpperCase() + '/CHART')}`;
  }

  ngOnDestroy() {
    this.changedOptions$sub.unsubscribe();
    this.changedSeries$sub.unsubscribe();
    this.data$sub.unsubscribe();
  }

  ngOnInit() {
    this.data$sub = this.$themeStudio.data$.subscribe({
      next:(data) => {
        if(!!data) {
          this.data = data.series;
          this.labels = data.labels;
      
          this.datasets = new Array(this.data.length).fill({});
          this.data.forEach((dst: ChartDataSets, index: number) => {
            this.datasets[index] = DeepMerge(dst, this.options.series(index, this.type));
          });
          this.setStyles();
        }
      }
    });

    this.mergedOptions = DeepMerge(this.options.options(this.type), this.localOptions);
    this.setStyles();

    this.changedOptions$sub = this.options.changedOptions$.subscribe({
      next:() => {
        this.mergedOptions = DeepMerge(this.options.options(this.type), this.localOptions);
        this.setStyles();
      }
    });

    this.changedSeries$sub = this.options.changedSeries$.subscribe({
      next:() => {
        this.datasets = new Array(this.data.length).fill({});
        this.data.forEach((dst: ChartDataSets, index: number) => {
          this.datasets[index] = DeepMerge(dst, this.options.series(index, this.type));
        });
        this.setStyles();
      }
    });
  }

  setStyles() {
    this.styles = {
      titles: {},
      datasets: []
    };

    if(this.mergedOptions) {

      this.mergedOptions.fullyColored = this.fullyColored;
      this.mergedOptions.template = this.template;
      this.mergedOptions.orientation = this.orientation;
      this.mergedOptions.title.textInline = this.localOptions.title.text as string;

      this.isBright = Brightness(this.mergedOptions.layout.backgroundColor.toString().substring(1, 7)) === 'light';

      this.datasets.forEach((datasets: ChartDataSets) => {
        if(datasets.hidden === false) {
          this.styles['datasets'].push({
            total: Total(datasets.data as number[]),
            icon: {
              code: 'fad fa-rocket',
              frame: datasets.iconFrame
            },
            title: {
              'color': this.mergedOptions.fullyColored ? Brightness(datasets.backgroundColor.toString().substring(1, 7)) === 'light' ? '#00000080' : '#FFFFFF' : this.mergedOptions.legend.labels.fontColor,
              'font-family': this.mergedOptions.title.fontFamily,
              'font-size': `${this.mergedOptions.title.fontSize}px`,
              'font-weight': this.mergedOptions.title.fontStyle.includes('bold') ? 'bold' : '',
              'font-style': this.mergedOptions.title.fontStyle.includes('italic') ? 'italic' : '',
            },
            label: {
              label: datasets.label,
              'color': this.mergedOptions.fullyColored ? Brightness(datasets.backgroundColor.toString().substring(1, 7)) === 'light' ? '#00000080' : '#FFFFFF' : this.mergedOptions.legend.labels.fontColor,
              'font-family': this.mergedOptions.legend.labels.fontFamily,
              'font-size': `${this.mergedOptions.legend.labels.fontSize}px`,
              'font-weight': this.mergedOptions.legend.labels.fontStyle.includes('bold') ? 'bold' : '',
              'font-style': this.mergedOptions.legend.labels.fontStyle.includes('italic') ? 'italic' : '',
            },
            frame: { 
              'background-color': datasets.iconFrame !== 'without' ? datasets.backgroundColor : '',
              'color': datasets.iconFrame === 'without' ? (datasets.backgroundColor as string).slice(0, 7) : Brightness(datasets.backgroundColor.toString().substring(1, 7)) === 'light' ? '#00000080' : '#FFFFFF'
            },
            layout: {
              'border-color': this.mergedOptions.fullyColored ? Object.values(datasets.datalabels.labels)[0].color : Brightness(datasets.backgroundColor.toString().substring(1, 7)) === 'light' ? '#00000080' : '#FFFFFF',
              'background-color': this.mergedOptions.fullyColored ? datasets.backgroundColor : '',
              isBright: this.mergedOptions.fullyColored ? Brightness(datasets.backgroundColor.toString().substring(1, 7)) === 'light' : Brightness(this.mergedOptions.layout.backgroundColor.toString().substring(1, 7)) === 'light'
            },
            datalabels: {
              'color': this.mergedOptions.fullyColored ? Brightness(datasets.backgroundColor.toString().substring(1, 7)) === 'light' ? '#00000080' : '#FFFFFF' : Object.values(datasets.datalabels.labels)[0].color,
              'opacity': Object.values(datasets.datalabels.labels)[0].opacity,
              'font-family': Object.values(datasets.datalabels.labels)[0].font['family'],
              'font-size': `${Object.values(datasets.datalabels.labels)[0].font['size']}px`,
              'font-weight': Object.values(datasets.datalabels.labels)[0].font['style'].includes('bold') ? 'bold' : '',
              'font-style': Object.values(datasets.datalabels.labels)[0].font['style'].includes('italic') ? 'italic' : ''
            }
          });
        }
      });
    }
  }

  next() {
    this.carousel.nextSlide();
  }

  prev() {
    this.carousel.previousSlide();
  }

}