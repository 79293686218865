/**
 * @module Form
 */

import { Component, Input, OnDestroy } from '@angular/core';
import { LabelsClass } from '@class/labels.class';
import { UidClass } from '@class/uid.class';
import { AuthService } from '@services/auth/auth.service';
import { LanguageType } from '@models/language.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-links-item',
  templateUrl: './links-item.component.html',
  styleUrls: ['./links-item.component.css']
})
export class LinksItemComponent implements OnDestroy {

  @Input() color: string; //hexa or name
  @Input() icon: string;
  @Input() labels: LabelsClass;
  @Input() label: string; //à utiliser à la place de 'labels' si pas de traduction nécéssaire
  @Input() uid: UidClass;
  @Input() bottomMargin: boolean = true;
  @Input() url: string; //à utiliser à la place de 'icon' pour afficher une image à la place

  language: LanguageType;
  language$sub: Subscription;

  constructor(
    private $auth: AuthService
    ) {
    this.language$sub = this.$auth.language$.subscribe({
      next: (language: LanguageType) => {
        this.language = language;
      }
    });
  }

  ngOnDestroy() {
    this.language$sub.unsubscribe();
  }

}
