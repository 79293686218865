/**
 * @module Widget
 */

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { WidgetLayersClass } from '@class/widgetLayers.class';
import { UID } from '@models/uid.model';

@Component({
  selector: 'app-widget-studio-main',
  templateUrl: './widget-studio-main.component.html',
  styleUrls: ['./widget-studio-main.component.css']
})
export class WidgetStudioMainComponent {

  @Input() widgetLayers: WidgetLayersClass;
  @Input() currentIndicator: number;
  @Output() sendCurrentIndicator: EventEmitter<number>;
  @Output() sendCurrentLayer: EventEmitter<UID>;

  selectedIndicator: number;
  selectedLayer: UID;

  constructor() {
    this.sendCurrentIndicator = new EventEmitter<number>();
    this.sendCurrentLayer = new EventEmitter<UID>();
  }

  receiveCurrentIndicator(current: number) {
    this.selectedIndicator = current;
    this.selectedLayer = undefined;
    this.sendCurrentIndicator.emit(current);
  }

  receiveCurrentLayer(current: UID) {
    this.selectedIndicator = undefined;
    this.selectedLayer = current;
    this.sendCurrentLayer.emit(current);
  }

}
