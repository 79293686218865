/**
 * @module Descriptions
 */

import { LabelType } from '@models/labels.model';
import { SetLabels } from '@functions/labels.functions';

export function SetDescriptions(label: string = ''): LabelType {
  return SetLabels(label);
}
