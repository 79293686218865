<app-theme-studio-export [themeOptions]="themeOptions" [theme]="theme"></app-theme-studio-export>
<app-theme-studio-values></app-theme-studio-values>

<hr>

<!-- CATEGORIES -->
<app-links-item *ngFor="let category of categories" [color]="color" [icon]="'far fa-pencil-paintbrush'" [label]="category">
  <div app-links-item-content-description>
  </div>
  <div app-links-item-content-option class="d-flex align-items-center">
    <button *ngIf="category == current.category" (click)="onSelect({ category: category, index: 0 })" type="button" mdbBtn [color]="color" size="sm" class="z-depth-0 d-flex px-2 align-items-center my-0 mr-0 ml-2" style="width: 30px; height: 31px;" mdbWavesEffect>
      <i class="fas fa-fw fa-sliders-h-square"></i>
    </button>
    <button *ngIf="category != current.category" (click)="onSelect({ category: category, index: 0 })" type="button" mdbBtn color="blue-grey" size="sm" class="z-depth-0 d-flex px-2 align-items-center my-0 mr-0 ml-2" style="width: 30px; height: 31px;" mdbWavesEffect>
      <i class="fas fa-fw fa-sliders-h-square"></i>
    </button>
  </div>
</app-links-item>
<!-- ./CATEGORIES -->

<hr>

<!-- SERIES -->
<div app-sortable id="seriesID" class="p-1 isSortableContainer app-sortable-container" (sendSortableStop)="receiveSortableStop($event)">
  <app-links-item [bottomMargin]="serie.index != (series.length - 1)" *ngFor="let serie of series" [color]="color" [icon]="'far fa-analytics'" [label]="'SERIE'" class="isSortableElement app-cursor-grab">
    <div app-links-item-content-description>
      <mdb-badge info="true" class="z-depth-0">#&nbsp;{{ serie.index }}</mdb-badge>
    </div>
    <div app-links-item-content-option class="d-flex align-items-center">
      <button [disabled]="series.length == MIN_SERIES" mdbBtn color="light" size="sm" class="text-warning waves-light z-depth-0 m-0 px-2" type="button" (click)="onRemoveSerie(serie.index)" mdbWavesEffect>
        <i class="fas fa-trash fa-fw"></i>
      </button>
      <button mdbBtn [disabled]="series.length > MAX_SERIES" color="light" size="sm" class="text-default waves-light z-depth-0 my-0 mr-0 ml-2 px-2" type="button" (click)="onAddSerie(serie.index)" mdbWavesEffect>
        <i class="fas fa-clone fa-fw"></i>
      </button>
      <button *ngIf="'SERIE' == current.category && serie.index == current.index" (click)="onSelect(serie)" type="button" mdbBtn [color]="color" size="sm" class="z-depth-0 d-flex px-2 align-items-center my-0 mr-0 ml-2" style="width: 30px; height: 31px;" mdbWavesEffect>
        <i class="fas fa-fw fa-sliders-h-square"></i>
      </button>
      <button *ngIf="'SERIE' != current.category || serie.index != current.index" (click)="onSelect(serie)" type="button" mdbBtn color="blue-grey" size="sm" class="z-depth-0 d-flex px-2 align-items-center my-0 mr-0 ml-2" style="width: 30px; height: 31px;" mdbWavesEffect>
        <i class="fas fa-fw fa-sliders-h-square"></i>
      </button>
    </div>
  </app-links-item>
</div>
<!-- ./SERIES -->

<hr> 

<!-- AXES -->
<app-links-item *ngFor="let axis of xAxes" [color]="color" [icon]="'far fa-chart-area'" [label]="'AXIS'">
  <div app-links-item-content-description>
    <mdb-badge info="true" class="z-depth-0">X&nbsp;{{ axis.index }}</mdb-badge>
  </div>
  <div app-links-item-content-option class="d-flex align-items-center">
    <button *ngIf="axis.index == current.index && current.category == 'X-AXIS'" (click)="onSelect(axis)" type="button" mdbBtn [color]="color" size="sm" class="z-depth-0 d-flex px-2 align-items-center my-0 mr-0 ml-2" style="width: 30px; height: 31px;" mdbWavesEffect>
      <i class="fas fa-fw fa-sliders-h-square"></i>
    </button>
    <button *ngIf="axis.index != current.index || current.category != 'X-AXIS'" (click)="onSelect(axis)" type="button" mdbBtn color="blue-grey" size="sm" class="z-depth-0 d-flex px-2 align-items-center my-0 mr-0 ml-2" style="width: 30px; height: 31px;" mdbWavesEffect>
      <i class="fas fa-fw fa-sliders-h-square"></i>
    </button>
  </div>
</app-links-item>

<app-links-item *ngFor="let axis of yAxes" [color]="color" [icon]="'far fa-chart-area'" [label]="'AXIS'">
  <div app-links-item-content-description>
    <mdb-badge info="true" class="z-depth-0">Y&nbsp;{{ axis.index }}</mdb-badge>
  </div>
  <div app-links-item-content-option class="d-flex align-items-center">
    <button *ngIf="axis.index == current.index && current.category == 'Y-AXIS'" (click)="onSelect(axis)" type="button" mdbBtn [color]="color" size="sm" class="z-depth-0 d-flex px-2 align-items-center my-0 mr-0 ml-2" style="width: 30px; height: 31px;" mdbWavesEffect>
      <i class="fas fa-fw fa-sliders-h-square"></i>
    </button>
    <button *ngIf="axis.index != current.index || current.category != 'Y-AXIS'" (click)="onSelect(axis)" type="button" mdbBtn color="blue-grey" size="sm" class="z-depth-0 d-flex px-2 align-items-center my-0 mr-0 ml-2" style="width: 30px; height: 31px;" mdbWavesEffect>
      <i class="fas fa-fw fa-sliders-h-square"></i>
    </button>
  </div>
</app-links-item>
<!-- ./AXES -->

<hr>

<!-- DATALABELS -->
<app-links-item *ngFor="let datalabel of datalabels" [color]="color" [icon]="'far fa-comment-alt'" [label]="'DATALABELS'">
  <div app-links-item-content-description>
    <mdb-badge info="true" class="z-depth-0">#&nbsp;{{ datalabel.index }}</mdb-badge>
  </div>
  <div app-links-item-content-option class="d-flex align-items-center">
    <button [disabled]="datalabels.length == MIN_DATALABELS" mdbBtn color="light" size="sm" class="text-warning waves-light z-depth-0 m-0 px-2" type="button" (click)="onRemoveDatalabel(datalabel.index)" mdbWavesEffect>
      <i class="fas fa-trash fa-fw"></i>
    </button>
    <button mdbBtn [disabled]="datalabels.length > MAX_DATALABELS" color="light" size="sm" class="text-default waves-light z-depth-0 my-0 mr-0 ml-2 px-2" type="button" (click)="onAddDatalabel(datalabel.index)" mdbWavesEffect>
      <i class="fas fa-clone fa-fw"></i>
    </button>
    <button *ngIf="datalabel.index == current.index && current.category == 'DATALABELS'" (click)="onSelect(datalabel)" type="button" mdbBtn [color]="color" size="sm" class="z-depth-0 d-flex px-2 align-items-center my-0 mr-0 ml-2" style="width: 30px; height: 31px;" mdbWavesEffect>
      <i class="fas fa-fw fa-sliders-h-square"></i>
    </button>
    <button *ngIf="datalabel.index != current.index || current.category != 'DATALABELS'" (click)="onSelect(datalabel)" type="button" mdbBtn color="blue-grey" size="sm" class="z-depth-0 d-flex px-2 align-items-center my-0 mr-0 ml-2" style="width: 30px; height: 31px;" mdbWavesEffect>
      <i class="fas fa-fw fa-sliders-h-square"></i>
    </button>
  </div>
</app-links-item>
<!-- ./DATALABELS -->