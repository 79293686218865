<div class="d-flex align-items-center">
  <app-input class="flex-grow-1" [value]="formula" [color]="color" [label]="'FORMULA'" (sendValue)="receiveValue($event)"></app-input>
  <button (click)="formulaHelper.show()" class="z-depth-0 d-flex px-2 align-items-center mb-0 mr-0" size="sm" style="width: 30px; height: 31px;" type="button" mdbBtn color="light" mdbWavesEffect>
    <i class="fas fa-info fa-fw text-info"></i>
  </button>
</div>

<span *ngFor="let formulaUID of formulasUID ; let i = index" class="mr-2 mb-2" [mdbTooltip]="labels[i].value(language)" placement="top">
  <mdb-badge (click)="add(formulaUID)" pill="true" color="blue-grey" class="z-depth-0 waves-light app-cursor-pointer">
    {{ formulaUID }}<i class="fad fa-plus-circle ml-1"></i>
  </mdb-badge>
</span>

<div *ngIf="!isValid" class="alert alert-warning font-small animated fadeIn mb-0 mt-2" role="alert">
  <i class="fad fa-flask mr-1 fa-swap-opacity"></i>{{ 'INVALID_FORMULA' | translate }}
</div>

<app-helper-formula #formulaHelper></app-helper-formula>