/**
 * @module Company
 */

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CompanyService } from '@services/company/company.service';
import { take, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CompanyGuard implements CanActivate {
  
  /**
   * @description Creates an instance of CompanyGuard
   * @param {CompanyService} $company
   * @param {Router} $router
   * @memberof CompanyGuard
   */
  constructor(
    private $company: CompanyService, 
    private $router: Router
  ) {}

  /**
   * @description En fonction de l'état de la company autorise l'accès à la ressource ou renvoie vers la page de sélection de la company
   * @param {ActivatedRouteSnapshot} next
   * @param {RouterStateSnapshot} state
   * @returns {(Observable<boolean> | Promise<boolean> | boolean)}
   * @memberof CompanyGuard
   */
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.$company.code$.pipe(
      take(1),
      map(company => {
        return this.$company.isValid();
      }), //transforme en boolean
      tap(isCompany => {
        if(!isCompany) {
          this.$router.navigate(['/company'])
        }
      })
    );
  }
}
