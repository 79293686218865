/**
 * @module Shared
 */

import { Injectable, PLATFORM_ID, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFireDatabase } from '@angular/fire/database';
import { COMPANIES } from '@consts/companies.const';
import { CompanyService } from '@services/company/company.service';
import * as firebase from 'firebase';
import { GetSubDomainFromHost } from '@functions/company.functions';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  /**
   * @description Service firebase de gestion de l'authentification
   * @private
   * @type {AngularFireAuth}
   * @memberof FirebaseService
   */
  private _angularFireAuth: AngularFireAuth;

  /**
   * @description Service firebase de gestion de la base de données
   * @private
   * @type {AngularFirestore}
   * @memberof FirebaseService
   */
  private _angularFirestore: AngularFirestore;

  /**
   * @description Service firebase de gestion de la base de données JSON
   * @private
   * @type {AngularFireDatabase}
   * @memberof FirebaseService
   */
  private _angularFirebase: AngularFireDatabase;

  /**
   * @description Service firebase de gestion des cloud functions
   * @private
   * @type {AngularFireFunctions}
   * @memberof FirebaseService
   */
  private _angularFireFunctions: AngularFireFunctions;

  /**
   * @description Service firebase de gestion du stockage
   * @private
   * @type {AngularFireStorage}
   * @memberof FirebaseService
   */
  private _angularFireStorage: AngularFireStorage;

  /**
   * @description Creates an instance of FirebaseService
   * @param {CompanyService} $company
   * @memberof FirebaseService
   */
  constructor(
    private $company: CompanyService
  ) {}

  /**
   * @description Supprime les pointeurs des différents services Firebase
   * @memberof FirebaseService
   */
  unsubscribe() {
    this._angularFireAuth = undefined;
    this._angularFirestore = undefined;
    this._angularFirebase = undefined;
    this._angularFireFunctions = undefined;
    this._angularFireStorage = undefined;
  }

  /**
   * @description Renvoi la sentinelle de temps pour que le serveur utilise l'horloge serveur
   * @readonly
   * @type {Readonly<unknown>}
   * @memberof FirebaseService
   */
  get timeStamp(): Readonly<unknown> {
    return firebase.database.ServerValue.TIMESTAMP;
  }

  /**
   * @description Renvoi la valeur appelant la destruction d'un objet type map dans firestore au type unknown pour s'adapter au format réel du l'objet à supprimer
   * @readonly
   * @type {Readonly<unknown>}
   * @memberof FirebaseService
   */
  get deleteFieldValue(): Readonly<unknown> {
    return firebase.firestore.FieldValue.delete() as Readonly<unknown>;
  }

  /**
   * @description Renvoi la valeur appelant l'ajout d'un objet dans un array au type unknown pour s'adapter au format réel du l'objet à supprimer
   * @param {*} element
   * @returns {Readonly<unknown>}
   * @memberof FirebaseService
   */
  arrayUnionFieldValue(elements: any | any[]): Readonly<unknown> {
    return firebase.firestore.FieldValue.arrayUnion(elements) as Readonly<unknown>;
  }

  /**
   * @description Renvoi la valeur appelant la suppression d'un objet dans un array au type unknown pour s'adapter au format réel du l'objet à supprimer
   * @param {*} element
   * @returns {Readonly<unknown>}
   * @memberof FirebaseService
   */
  arrayRemoveFieldValue(elements: any | any[]): Readonly<unknown> {
    return firebase.firestore.FieldValue.arrayRemove(elements) as Readonly<unknown>;
  }

  /**
   * @description Initialise le service firebase pour la company passée en paramètre
   * @private
   * @param {string} service
   * @param {string} [company]
   * @memberof FirebaseService
   */
  private initialize(service: string, company?: string) {

    const sub = `${GetSubDomainFromHost()}-swapptechs`;
    company = company !== undefined ? company : 
      this.$company !== undefined && this.$company.code !== undefined && COMPANIES[this.$company.code] !== undefined ? 
      this.$company.code : 
      sub === 'localhost:4200-swapptechs' ? 'dev-swapptechs' : sub;
    
    if(service === 'auth') {
      this._angularFireAuth = new AngularFireAuth(COMPANIES[company].firebase, company, PLATFORM_ID, new NgZone({ enableLongStackTrace: undefined }));
    }
    else if(service === 'store') {
      this._angularFirestore = new AngularFirestore(COMPANIES[company].firebase, company, false, null, PLATFORM_ID, new NgZone({ enableLongStackTrace: undefined }), null);
    }
    else if(service === 'base') {
      this._angularFirebase = new AngularFireDatabase(COMPANIES[company].firebase, company, COMPANIES[company].firebase.databaseURL, PLATFORM_ID, new NgZone({ enableLongStackTrace: undefined }));
    }
    else if(service === 'functions') {
      this._angularFireFunctions = new AngularFireFunctions(COMPANIES[company].firebase, company, PLATFORM_ID, new NgZone({ enableLongStackTrace: undefined }), COMPANIES[company].region, undefined);
    }
    else if(service === 'storage') {
      this._angularFireStorage = new AngularFireStorage(COMPANIES[company].firebase, company, COMPANIES[company].firebase.storageBucket, PLATFORM_ID, new NgZone({ enableLongStackTrace: undefined }));
    }
    
  }

  /**
   * @description Renvoi le service firebase de gestion de l'authentification
   * @readonly
   * @type {Readonly<AngularFireAuth>}
   * @memberof FirebaseService
   */
  get angularFireAuth(): Readonly<AngularFireAuth> {
    if(this._angularFireAuth === undefined) this.initialize('auth');
    return this._angularFireAuth;
  }

  /**
   * @description Renvoi le service firebase de gestion de la base de données JSON
   * @readonly
   * @type {Readonly<AngularFireDatabase>}
   * @memberof FirebaseService
   */
  get angularFirebase(): Readonly<AngularFireDatabase> {
    if(this._angularFirebase === undefined) this.initialize('base');
    return this._angularFirebase;
  }

  /**
   * @description Renvoi le service firebase de gestion de la base de données
   * @readonly
   * @type {Readonly<AngularFirestore>}
   * @memberof FirebaseService
   */
  get angularFirestore(): Readonly<AngularFirestore> {
    if(this._angularFirestore === undefined) this.initialize('store');
    return this._angularFirestore;
  }

  /**
   * @description Renvoi le service firebase de gestion des cloud functions
   * @readonly
   * @type {Readonly<AngularFireFunctions>}
   * @memberof FirebaseService
   */
  get angularFireFunctions(): Readonly<AngularFireFunctions> {
    if(this._angularFireFunctions === undefined) this.initialize('functions');
    return this._angularFireFunctions;
  }

  /**
   * @description Renvoi le service firebase de gestion du stockage
   * @readonly
   * @type {Readonly<AngularFireStorage>}
   * @memberof FirebaseService
   */
  get angularFireStorage(): Readonly<AngularFireStorage> {
    if(this._angularFireStorage === undefined) this.initialize('storage');
    return this._angularFireStorage;
  }

}
