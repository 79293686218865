/**
 * @module Widget
 */

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ItemColor, ItemIcon } from '@functions/item.functions';
import { WidgetLayersClass } from '@class/widgetLayers.class';
import { IndicatorClass } from '@class/indicator.class';
import { WidgetIndicatorModel } from '@models/widgetLayers.model';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { Subscription } from 'rxjs';
import { ModificationStateClass } from '@class/modification.class';
import { UID } from '@models/uid.model';

@Component({
  selector: 'app-widget-indicators-add',
  templateUrl: './widget-indicators-add.component.html',
  styleUrls: ['./widget-indicators-add.component.css']
})
export class WidgetIndicatorsAddComponent implements OnInit {

  @Input() widgetLayers: WidgetLayersClass;
  @ViewChild('indicatorsModal', { static: true }) modal: ModalDirective;

  indicators: { indicator: WidgetIndicatorModel, links: { [key in UID]: 'pivots' | 'dates' } }[] = [];

  widgetLayers$sub: Subscription;

  color = ItemColor('indicators');
  icon = ItemIcon('indicators');

  constructor() {}

  ngOnInit() {
    this.indicators = this.widgetLayers.indicators.map(i => { return { indicator: i.model, links: undefined } });

    this.widgetLayers$sub = this.widgetLayers.modifications$.subscribe({
      next: (modification: ModificationStateClass) => {
        if(modification.has('indicators')) this.indicators = this.widgetLayers.indicators.map(i => { return { indicator: i.model, links: undefined } });
      }
    });
  }

  public show() {
    this.modal.show();
  }

  receiveIndicator(indicator: IndicatorClass) {
    const index = this.indicators.map((i) => i.indicator.uid).indexOf(indicator.uid.value);
    
    const links = {};
    indicator.links.list('pivots').forEach((uid: UID) => {
      links[uid] = 'pivots';
    });
    indicator.links.list('dates').forEach((uid: UID) => {
      links[uid] = 'dates';
    });

    if(index === -1) this.indicators.push({
      indicator: { 
        uid: indicator.uid.value,
        labels: indicator.model.labels,
        serverLink: indicator.model.serverLink,
        icon: { style: 'd', symbol: 'rocket', options: [] }
      },
      links: links
    });
    else this.indicators.splice(index, 1);
  }

  onSelect() {
    this.indicators.forEach((item: { indicator: WidgetIndicatorModel, links: { [key in UID]: 'pivots' | 'dates' } }) => {
      if(item.links !== undefined) this.widgetLayers.addIndicator(item.indicator, item.links);
    });
    this.indicators = [];
  }

}
