/**
 * @module Company
 */

/**
 * @description renvoi le sous domaine de l'url courante
 * @export
 * @returns {string}
 */
export function GetSubDomainFromHost(): string {
  return window.location.host.split('.')[0];
}