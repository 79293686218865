<div *ngIf="!showEditor">

  <app-widget-indicators-duplicate [widgetUID]="widgetLayers.uid.value"></app-widget-indicators-duplicate>

  <hr>

  <app-widget-option [categories]="['INDICATORS']" [label]="'TOTAL_FOR_STICKER_AND_GAUGE'" [limitedTo]="['sticker', 'gauge']">
    <div app-widget-option-content>
      <button (click)="onEditSticker()" type="button" mdbBtn color="blue-grey" size="sm" class="z-depth-0 d-flex px-2 align-items-center my-0 mr-0 ml-1" style="width: 30px; height: 31px;" mdbWavesEffect>
        <i class="fas fa-fw fa-sliders-h-square"></i>
      </button>
    </div>
  </app-widget-option>
  
  <hr>

  <div *ngIf="indicator.datalabels.length > 0" app-sortable id="datalabelsID" class="p-1 isSortableContainer app-sortable-container" (sendSortableStop)="receiveSortableStop($event)">
    <app-links-item [bottomMargin]="!isLast" [label]="datalabels.type.toUpperCase()" *ngFor="let datalabels of indicator.datalabels ; let isLast = last" class="isSortableElement app-cursor-grab" [attr.data-type]="datalabels.type">
      <div app-links-item-content-description>
      </div>
      <div app-links-item-content-option class="d-flex align-items-center">
        <app-links-item-option class="d-block mx-1" *ngIf="tableAuthorized.indexOf(datalabels.type) != -1" [symbol]="'table'" [selected]="datalabels.table" (sendSelected)="receiveTable(datalabels.type)"></app-links-item-option>
        <app-links-item-option class="d-block mx-1" [symbol]="'hand-paper'" [selected]="datalabels.hover" (sendSelected)="receiveHover(datalabels.type)"></app-links-item-option>
        <app-links-item-option class="d-block mx-1" [symbol]="'eye'" [selected]="datalabels.display" (sendSelected)="receiveDisplay(datalabels.type)"></app-links-item-option>
        <app-links-item-option class="d-block mx-1" [symbol]="'comment-alt'" [selected]="datalabels.tooltips" (sendSelected)="receiveTooltips(datalabels.type)"></app-links-item-option>
        <button *ngIf="datalabels.numbro != undefined" (click)="onEdit(datalabels.type)" type="button" mdbBtn color="blue-grey" size="sm" class="z-depth-0 d-flex px-2 align-items-center my-0 mr-0 ml-1" style="width: 30px; height: 31px;" mdbWavesEffect>
          <i class="fas fa-fw fa-sliders-h-square"></i>
        </button>
      </div>
    </app-links-item>
  </div>

  <hr *ngIf="indicator.datalabels.length > 0">

  <div *ngFor="let type of types">
    <app-links-item [label]="type.toUpperCase()" *ngIf="indicator.datalabelsByType(type) == undefined">
      <div app-links-item-content-description>
      </div>
      <div app-links-item-content-option class="d-flex align-items-center">
        <app-links-item-option class="d-block mx-1" *ngIf="tableAuthorized.indexOf(type) != -1" [symbol]="'table'" [selected]="false" (sendSelected)="receiveTable(type)"></app-links-item-option>
        <app-links-item-option class="d-block mx-1" [symbol]="'hand-paper'" [selected]="false" (sendSelected)="receiveHover(type)"></app-links-item-option>
        <app-links-item-option class="d-block mx-1" [symbol]="'eye'" [selected]="false" (sendSelected)="receiveDisplay(type)"></app-links-item-option>
        <app-links-item-option class="d-block mx-1" [symbol]="'comment-alt'" [selected]="false" (sendSelected)="receiveTooltips(type)"></app-links-item-option>
      </div>
    </app-links-item>
  </div>
</div>

<div *ngIf="showEditor" class="d-flex justify-content-end flex-column">
  
  <button type="button" mdbBtn color="secondary" size="sm" class="waves-light z-depth-0 mr-0 ml-auto mt-0 mb-2 px-2" (click)="onClose()" mdbWavesEffect>
    <i class="far fa-times fa-fw"></i>
  </button>
  
  <app-numbro *ngIf="numbro != null" [color]="color" [numbro]="numbro" (sendNumbro)="receiveNumbro($event)"></app-numbro>
  <app-numbro *ngIf="numbro == null && showSticker" [color]="color" [label]="'STICKER/CHART'" [numbro]="indicator.numbro" (sendNumbro)="receiveIndicatorNumbro($event)"></app-numbro>
</div>