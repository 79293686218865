/**
 * @module Widget
 */

import { WidgetLayerModel, WidgetSortType, WidgetLayersDefinedOptionModel } from '@models/widgetLayers.model';
import { UidModel, UID } from '@models/uid.model';
import { ModificationClass, ModificationStateClass } from '@class/modification.class';
import { UidClass } from '@class/uid.class';
import { LabelsClass } from '@class/labels.class';
import { LabelsModel } from '@models/labels.model';
import { DeepCopy, DeepMerge } from '@functions/copy.functions';
import { ChartType } from '@models/chart.model';
import { ThemeOptionsClass } from '@class/themeOptions.class';
import { Subscription } from 'rxjs';
import { OnDestroy } from '@angular/core';
import { DefinedOptionIndex, DateBoundsDefault } from '@functions/widget.functions';
import { WidgetDatesBoundsClass } from '@class/widgetDatesBounds.class';
import { OrientationType } from 'chart.js';
import { NumbroClass } from '@class/numbro.class';

export class WidgetLayerClass extends ModificationClass<WidgetLayerModel> implements OnDestroy {
  
  private _uid: UidClass;
  private _layerLabel: LabelsClass;
  private _chartTitle: LabelsClass;
  private _themeOptions: ThemeOptionsClass;
  private _themeOptionsChanged$sub: Subscription;
  private _initialThemeOptions: ThemeOptionsClass;
  private _bounds: WidgetDatesBoundsClass;

  private _x0Numbro: NumbroClass;
  private _y0Numbro: NumbroClass;
  private _y1Numbro: NumbroClass;

  protected _parent: WidgetLayerModel;
  protected _inital: WidgetLayerModel;
  protected _attributes: string[];

  constructor(widgetLayer: WidgetLayerModel, state: ModificationStateClass, themeOptions: ThemeOptionsClass) {

    super();

    this._parent = widgetLayer;
    this._state = state;
    this._themeOptions = new ThemeOptionsClass(DeepCopy(themeOptions.model));
    this._initialThemeOptions = new ThemeOptionsClass(DeepCopy(this._themeOptions.model));

    this._reset(this._parent);

    this._attributes = this.attributes.slice();
    this._attributes.push(...this._uid.attributes);
    this._attributes.push(...this._layerLabel.attributes);

  }

  private _reset(widgetLayer: WidgetLayerModel) {
    this._uid = new UidClass(widgetLayer as UidModel, true);
    this._layerLabel = new LabelsClass(widgetLayer.layerLabel as LabelsModel, this._form, this._requirements, this._state, 'layerLabel');
    this._chartTitle = new LabelsClass(widgetLayer.chartTitle as LabelsModel, this._form, this._requirements, this._state, 'chartTitle');

    if(this._parent.bounds === undefined) this._parent.bounds = DateBoundsDefault(this._parent.uid);
    this._bounds = new WidgetDatesBoundsClass(this._parent.bounds, this._state, this._modifications$, this._lastModification$);
    
    if(this._parent.sort === undefined) this._parent.sort = 'label_asc';
    if(this._parent.inTable === undefined) this._parent.inTable = this._parent.drillable;
    if(this._parent.dateFormat === undefined) this._parent.dateFormat = 'YYYY-MM-DD';
    if(this._parent.stacked === undefined) this._parent.stacked = false;
    if(this._parent.fillBlanks === undefined) this._parent.fillBlanks = false;
    if(this._parent.definedOptions === undefined) this._parent.definedOptions = [];
    if(this._parent.maxValues === undefined) this._parent.maxValues = 0;

    //axes
    this._x0Numbro = new NumbroClass(this._parent.x0Numbro !== undefined ? this._parent.x0Numbro : { type: 'numberAverage', format: {} }, this._state, this._modifications$);
    this._y0Numbro = new NumbroClass(this._parent.y0Numbro !== undefined ? this._parent.y0Numbro : { type: 'numberAverage', format: {} }, this._state, this._modifications$);
    this._y1Numbro = new NumbroClass(this._parent.y1Numbro !== undefined ? this._parent.y1Numbro : { type: 'numberAverage', format: {} }, this._state, this._modifications$);

    //sticker
    if(this._parent.stickerCarousel === undefined) this._parent.stickerCarousel = true;
    if(this._parent.stickerOrientation === undefined) this._parent.stickerOrientation = 'column';
    if(this._parent.stickerTemplate === undefined) this._parent.stickerTemplate = 1;
    if(this._parent.stickerFullyColored === undefined) this._parent.stickerFullyColored = false;

    //gauge
    if(this._parent.gaugeDonut === undefined) this._parent.gaugeDonut = false;
    if(this._parent.gaugeHideMinMax === undefined) this._parent.gaugeHideMinMax = false;
    if(this._parent.gaugeDisplayRemaining === undefined) this._parent.gaugeDisplayRemaining = false;
    if(this._parent.gaugePercent === undefined) this._parent.gaugePercent = true;
    if(this._parent.gaugeTarget === undefined) this._parent.gaugeTarget = 100;
    if(this._parent.gaugeMax === undefined) this._parent.gaugeMax = 100;
    if(this._parent.gaugeMin === undefined) this._parent.gaugeMin = 0;

    [...this._parent.definedOptions].forEach((definedOption: WidgetLayersDefinedOptionModel) => {
      this.addDefinedOption(
        definedOption, 
        definedOption.values.common,
        definedOption.values.specifics
      );
    });

    this.attributes.forEach((attribute: string) => {
      this._parent[attribute] = widgetLayer[attribute];
    });

    this._inital = DeepCopy(widgetLayer);

    this._layerLabel.modifications$ = this._modifications$;
    this._chartTitle.modifications$ = this._modifications$;
    this._themeOptionsChanged$sub = this._themeOptions.changed$.subscribe({
      next: (changed: boolean) => {
        if(changed) this.emit(['themeOptions']);
      }
    });

    this._state.flush();
    this._modifications$.next(this._state);
  }

  ngOnDestroy() {
    this._themeOptionsChanged$sub.unsubscribe();
  }

  reset() {
    this._state.flush();
    this._reset(this._inital);
  }

  change(attributes: Partial<WidgetLayerModel>, sendMessage?: boolean) {
    if(attributes.drillable !== undefined 
      || attributes.sort !== undefined 
      || attributes.sortUID !== undefined 
      || attributes.dateFormat !== undefined
      || attributes.fillBlanks !== undefined) this._state.add('reload');
    super.change(attributes, sendMessage, this._parent);
  }

  get attributes(): Readonly<string[]> {
    return ['inTable', 'cumulative', 'x0Numbro', 'y0Numbro', 'y1Numbro', 'sort', 'sortUID', 'chartType', 'layerType', 'chartTitle', 'layerLabel', 'drillable', 'rank', 'indicators', 'stacked', 'dateFormat', 'fillBlanks', 'bounds', 'maxValues', 'stickerCarousel', 'stickerOrientation', 'stickerTemplate', 'stickerFullyColored', 'gaugeDonut', 'gaugeHideMinMax', 'gaugeDisplayRemaining', 'gaugePercent', 'gaugeMax', 'gaugeMin', 'gaugeTarget'];
  }

  get class(): string {
    return 'widgetLayer';
  }

  get model(): Readonly<WidgetLayerModel> {
    const definedOptions = this._parent.definedOptions.map((definedOption: WidgetLayersDefinedOptionModel) => {
      definedOption.values = {
        common: this._themeOptions.option(definedOption.option, definedOption.index, definedOption.type, definedOption.axesXY),
        specifics: this._themeOptions.option(definedOption.option, definedOption.index, definedOption.type, definedOption.axesXY, undefined, true) as any
      };
      return definedOption;
    });
    return DeepMerge(super.model, { definedOptions: definedOptions } as WidgetLayerModel);
  }

  get uid(): UidClass {
    return this._uid;
  }

  get bounds(): WidgetDatesBoundsClass {
    return this._bounds;
  }

  get dateFormat(): Readonly<string> {
    return this._parent.dateFormat;
  }

  get x0Numbro(): Readonly<NumbroClass> {
    return this._x0Numbro;
  }

  get y0Numbro(): Readonly<NumbroClass> {
    return this._y0Numbro;
  }

  get y1Numbro(): Readonly<NumbroClass> {
    return this._y1Numbro;
  }

  get layerLabel(): LabelsClass {
    return this._layerLabel;
  }

  get chartTitle(): LabelsClass {
    return this._chartTitle;
  }

  get sort(): Readonly<WidgetSortType> {
    return this._parent.sort;
  }

  get sortUID(): Readonly<UID> {
    return this._parent.sortUID;
  }

  get inTable(): boolean {
    return this._parent.inTable;
  }

  get rank(): Readonly<number> {
    return this._parent.rank;
  }

  get maxValues(): Readonly<number> {
    return this._parent.maxValues;
  }

  get stickerTemplate(): Readonly<number> {
    return this._parent.stickerTemplate;
  }

  get stickerFullyColored(): Readonly<boolean> {
    return this._parent.stickerFullyColored;
  }

  get stickerOrientation(): Readonly<OrientationType> {
    return this._parent.stickerOrientation;
  }

  get stickerCarousel(): Readonly<boolean> {
    return this._parent.stickerCarousel;
  }

  get gaugeDonut(): Readonly<boolean> {
    return this._parent.gaugeDonut;
  }

  get gaugePercent(): Readonly<boolean> {
    return this._parent.gaugePercent;
  }

  get gaugeTarget(): Readonly<number> {
    return this._parent.gaugeTarget;
  }

  get gaugeMax(): Readonly<number> {
    return this._parent.gaugeMax;
  }

  get gaugeMin(): Readonly<number> {
    return this._parent.gaugeMin;
  }

  get gaugeHideMinMax(): Readonly<boolean> {
    return this._parent.gaugeHideMinMax;
  }

  get gaugeDisplayRemaining(): Readonly<boolean> {
    return this._parent.gaugeDisplayRemaining;
  }

  get drillable(): Readonly<boolean> {
    return this._parent.drillable;
  }

  get stacked(): Readonly<boolean> {
    return this._parent.stacked;
  }

  get fillBlanks(): Readonly<boolean> {
    return this._parent.fillBlanks;
  }

  get chartType(): Readonly<ChartType> {
    return this._parent.chartType;
  }

  get layerType(): Readonly<'pivots' | 'dates'> {
    return this._parent.layerType;
  }

  get themeOptions(): ThemeOptionsClass {
    return this._themeOptions;
  }

  get indicators(): Readonly<UID[]> {
    return this._parent.indicators;
  }

  addIndicator(uid: UID) {
    if(this._parent.indicators.indexOf(uid) === -1) {
      this._parent.indicators.push(uid);
      this.emit(['indicators']);
    }
  }

  removeIndicator(uid: UID) {
    const index = this._parent.indicators.indexOf(uid);
    if(index !== -1) {
      this._parent.indicators.splice(index, 1);
      this.emit(['indicators']);
    }
    if(this._parent.sortUID === uid) this._parent.sortUID = this._parent.indicators.length > 0 ? this._parent.indicators[0] : undefined;
  }

  get definedOptions(): Readonly<WidgetLayersDefinedOptionModel[]> {
    return this._parent.definedOptions;
  }

  definedOption(definedOption: WidgetLayersDefinedOptionModel): Readonly<WidgetLayersDefinedOptionModel> {
    const index = this._parent.definedOptions.map(d => DefinedOptionIndex(d)).indexOf(DefinedOptionIndex(definedOption));
    if(index !== -1) return this._parent.definedOptions[index];
    else return undefined;
  }

  addDefinedOption(definedOption: WidgetLayersDefinedOptionModel, commonValue?: any, specificsValues?: { [key in ChartType]: any }) {
    const index = this._parent.definedOptions.map(d => DefinedOptionIndex(d)).indexOf(DefinedOptionIndex(definedOption));
    if(index !== -1) this.removeDefinedOption(definedOption);
    
    this._parent.definedOptions.push(definedOption);
    switch(definedOption.type) {
      case 'axes': 
        if(!!commonValue) this._themeOptions.setAxes(definedOption.option, commonValue, definedOption.axesXY, definedOption.index);
        if(Object.values(specificsValues)[0] !== undefined) for(let chartType in specificsValues) { this._themeOptions.setAxes(definedOption.option, specificsValues[chartType], definedOption.axesXY, definedOption.index, [chartType as ChartType]); }
        break;
      case 'datalabels':
        if(!!commonValue) this._themeOptions.setDatalabels(definedOption.option, commonValue, definedOption.index);
        if(Object.values(specificsValues)[0] !== undefined) for(let chartType in specificsValues) { this._themeOptions.setDatalabels(definedOption.option, specificsValues[chartType], definedOption.index, [chartType as ChartType]); }
        break;
      case 'options':
        if(!!commonValue) this._themeOptions.setOption(definedOption.option, commonValue);
        if(Object.values(specificsValues)[0] !== undefined) for(let chartType in specificsValues) { this._themeOptions.setOption(definedOption.option, specificsValues[chartType], [chartType as ChartType]); }
        break;
      case 'series':
        if(!!commonValue) this._themeOptions.setSeries(definedOption.option, commonValue, definedOption.index);
        if(Object.values(specificsValues)[0] !== undefined) for(let chartType in specificsValues) { this._themeOptions.setSeries(definedOption.option, specificsValues[chartType], definedOption.index, [chartType as ChartType]); }
        break;
    }
    this.emit(['definedOptions']);
  }

  removeDefinedOption(definedOption: WidgetLayersDefinedOptionModel) {
    const index = this._parent.definedOptions.map(d => DefinedOptionIndex(d)).indexOf(DefinedOptionIndex(definedOption));
    if(index !== -1) {
      let removedOption = this._parent.definedOptions.splice(index, 1)[0];
      const value = this._initialThemeOptions.option(removedOption.option, removedOption.index, removedOption.type, removedOption.axesXY);
      switch(removedOption.type) {
        case 'axes': 
          this._themeOptions.setAxes(removedOption.option, value, removedOption.axesXY, removedOption.index);
          break;
        case 'datalabels':
          this._themeOptions.setDatalabels(removedOption.option, value, removedOption.index);
          break;
        case 'options':
          this._themeOptions.setOption(removedOption.option, value);
          break;
        case 'series':
          this._themeOptions.setSeries(removedOption.option, value, removedOption.index);
          break;
      }
      this.emit(['definedOptions']);
    }
  }

}