<app-theme-option [categories]="themeOptions.optionsConfiguration[optionType].categories" [label]="themeOptions.optionsConfiguration[optionType].label" [information]="'PIXELS'">
  <div app-theme-option-content>
    <app-show-details-option [showDetails]="showDetails" (sendShowDetails)="receiveShowDetails($event)">
      <div app-show-details-option-content>
        <app-number [value]="all" (sendValue)="receiveValue($event)" [color]="color" [min]="0" [max]="50"></app-number>
      </div>
    </app-show-details-option>
  </div>
</app-theme-option>

<div *ngIf="showDetails">

  <app-theme-option-datalabels-padding-top [themeOptions]="themeOptions" [datalabelsIndex]="datalabelsIndex"></app-theme-option-datalabels-padding-top>
  <app-theme-option-datalabels-padding-left [themeOptions]="themeOptions" [datalabelsIndex]="datalabelsIndex"></app-theme-option-datalabels-padding-left>
  <app-theme-option-datalabels-padding-right [themeOptions]="themeOptions" [datalabelsIndex]="datalabelsIndex"></app-theme-option-datalabels-padding-right>
  <app-theme-option-datalabels-padding-bottom [themeOptions]="themeOptions" [datalabelsIndex]="datalabelsIndex"></app-theme-option-datalabels-padding-bottom>

</div>