<div class="rounded p-sm-3 p-2 d-flex align-items-center animated fadeIn alert alert-warning">

  <!-- CONTENT TEXT -->
  <div class="text-warning mr-auto font-very-small font-weight-bolder">
    <ng-content select="[app-confirm-warning-strip-text]"></ng-content>
  </div>
  <!-- ./CONTENT TEXT -->

  <button (click)="onCancel()" type="button" class="z-depth-0 px-2 px-sm-3 my-0" size="sm" mdbBtn color="warning" outline="true" mdbWavesEffect>
    <i class="d-sm-none far fa-times fa-fw"></i>
    <i class="d-none d-sm-inline far fa-times"></i>
    <span class="d-none d-sm-inline ml-1">{{ 'CANCEL' | translate }}</span>
  </button>
  <button (click)="onConfim()" type="button" class="z-depth-0 px-2 px-sm-3 m-0" size="sm" mdbBtn color="warning" mdbWavesEffect>
    <i class="d-sm-none far fa-check fa-fw"></i>
    <i class="d-none d-sm-inline far fa-check"></i>
    <span class="d-none d-sm-inline ml-1">{{ confirmLabel | translate }}</span>
  </button>

</div>