/**
 * @module Widget
 */

import { Component, Input } from '@angular/core';
import { WidgetLayersClass } from '@class/widgetLayers.class';
import { UID } from '@models/uid.model';
import { ItemColor } from '@functions/item.functions';

@Component({
  selector: 'app-widget-layers-max-values',
  templateUrl: './widget-layers-max-values.component.html',
  styleUrls: ['./widget-layers-max-values.component.css']
})
export class WidgetLayersMaxValuesComponent {

  @Input() widgetLayers: WidgetLayersClass;
  @Input() current: UID;

  color = ItemColor('widgets');

  constructor() {}

  receiveValue(maxValues: number) {
    this.widgetLayers.layer(this.current).change({ maxValues: maxValues });
    if(this.widgetLayers.uid.value === this.current) this.widgetLayers.duplicateLayersOptions({ maxValues: maxValues }, this.current);
  }

}
