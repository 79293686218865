/**
 * @module Widget
 */

import { Component, Input } from '@angular/core';
import { WidgetLayersClass } from '@class/widgetLayers.class';
import { ItemColor } from '@functions/item.functions';
import { WidgetTimelineType } from '@models/widgetLayers.model';
import { WidgetTimelineTypes } from '@functions/widget.functions';

@Component({
  selector: 'app-widget-indicators-timeline',
  templateUrl: './widget-indicators-timeline.component.html',
  styleUrls: ['./widget-indicators-timeline.component.css']
})
export class WidgetIndicatorsTimelineComponent {

  @Input() widgetLayers: WidgetLayersClass;
  @Input() current: number;

  color = ItemColor('indicators');
  values: WidgetTimelineType[];

  constructor() {
    this.values = WidgetTimelineTypes();
  }

  onValue(value: WidgetTimelineType) {
    this.widgetLayers.indicators[this.current].change({ timeline: value });
  }

}
