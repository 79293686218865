/**
 * @module Theme
 */

import { Component, Input } from '@angular/core';
import { ThemeOptionsClass } from '@class/themeOptions.class';
import { ItemColor } from '@functions/item.functions';
import { ThemeOptionType } from '@models/themeStudio.model';

@Component({
  selector: 'app-theme-option-elements-chart-gauge-color',
  templateUrl: './theme-option-elements-chart-gauge-color.component.html',
  styleUrls: ['./theme-option-elements-chart-gauge-color.component.css']
})
export class ThemeOptionElementsChartGaugeColorComponent  {

  @Input() themeOptions: ThemeOptionsClass;

  color = ItemColor('themes');
  optionType: ThemeOptionType = 'elements_chart_gauge_color';

  constructor() {}

  receiveValue(value: string) {
    this.themeOptions.setOption(this.optionType, value);
  }

}
