/**
 * @module User
 */

import { UserModel, UserIdModel } from '@models/user.model';
import { UidModel, UID } from '@models/uid.model';
import { LogsModel } from '@models/logs.model';
import { ModificationClass, ModificationStateClass } from '@class/modification.class';
import { LogsClass } from '@class/logs.class';
import { TagsModel } from '@models/tag.model';
import { TagsClass } from '@class/tag.class';
import { DeepCopy, DeepMerge } from '@functions/copy.functions';
import { AvatarModel } from '@models/avatar.model';
import { ResourcesModel } from '@models/resources.model';
import { BookmarksModel } from '@models/bookmarks.model';
import { AvatarClass } from '@class/avatar.class';
import { BookmarksClass } from '@class/bookmarks.class';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RequirementsType } from '@models/requirement.model';
import { UidClass } from '@class/uid.class';
import { ResourcesClass } from '@class/resources.class';
import { LanguageType } from '@models/language.model';
import { RolesModel } from '@models/role.model';
import { RolesClass } from '@class/roles.class';
import { MomentDateType } from '@models/moment.model';
import { DateApplyFormat, DateFromNow, Moment } from '@functions/moment.functions';
import { DeletionDurations } from '@functions/user.functions';
import { GroupsClass } from '@class/right.class';
import { GroupsModel } from '@models/right.model';
import { PivotFiltersModel } from '@models/widgetLayers.model';
import { PivotFiltersClass } from '@class/widgetFilters.class';

export class UserIdClass extends ModificationClass<UserIdModel> {

  protected _parent: UserIdModel;
  protected _inital: UserIdModel;
  protected _attributes: string[];

  private _uid: UidClass;

  constructor(userId: UserIdModel, form: FormGroup, requirements: RequirementsType, isRegistered: boolean = true, state: ModificationStateClass) {
  
    super();
    
    this._parent = userId;
    this._requirements = requirements;
    this._attributes = this.attributes;
    this._form = form;
    this._state = state;

    Object.assign(requirements, {
      lastname: {
        maxLength: 75,
        minLength: 1
      },
      firstname: {
        maxLength: 75,
        minLength: 1
      },
      email: {
        maxLength: 75,
        minLength: 3
      }
    });

    form.addControl('email', new FormControl(this._parent.email, [ 
      Validators.email, 
      Validators.required, 
      Validators.maxLength(requirements.email.maxLength), 
      Validators.minLength(requirements.email.minLength) 
    ]));

    form.addControl('firstname', new FormControl(this._parent.firstname, [ 
      Validators.required, 
      Validators.maxLength(requirements.firstname.maxLength), 
      Validators.minLength(requirements.firstname.minLength) 
    ]));

    form.addControl('lastname', new FormControl(this._parent.lastname, [ 
      Validators.required, 
      Validators.maxLength(requirements.lastname.maxLength), 
      Validators.minLength(requirements.lastname.minLength) 
    ]));

    this._uid = new UidClass(userId as UidModel, isRegistered);

    this._inital = DeepCopy({ 
      lastname: this._parent.lastname,
      firstname: this._parent.firstname,
      email: this._parent.email,
      uid: this._parent.uid,
    });

  }

  get class(): string {
    return 'userId';
  }

  get attributes(): string[] {
    return ['lastname', 'firstname', 'email', 'uid'];
  }

  get uid(): UidClass {
    return this._uid;
  }

  get email(): Readonly<string> {
    return this._parent.email;
  }

  get firstname(): Readonly<string> {
    return this._parent.firstname;
  }

  get lastname(): Readonly<string> {
    return this._parent.lastname;
  }

  get fullname(): Readonly<string> {
    return `${this._parent.firstname} ${this._parent.lastname}`;
  }

}

export class UserClass extends ModificationClass<UserModel> {
  
  private _userId: UserIdClass;
  private _avatar: AvatarClass;
  private _resources: ResourcesClass;
  private _bookmarks: BookmarksClass;
  private _logs: LogsClass;
  private _tags: TagsClass;
  private _roles: RolesClass;
  private _groups: GroupsClass;

  private _filters: PivotFiltersClass[];

  protected _parent: UserModel;
  protected _inital: UserModel;
  protected _attributes: string[];

  private _isRegistered: boolean;
  private _isPrivilegesModified: boolean;

  constructor(user: UserModel, isRegistered: boolean = true) {

    super();

    this._parent = user;
    this._isRegistered = isRegistered;
    this._isPrivilegesModified = false;

    this.modifications$.subscribe({
      next: (state: ModificationStateClass) => {
        if(state.has('roles') || state.has('resources') || state.has('groups') || state.has('filters')) {
          this._isPrivilegesModified = true;
        }
        if(state.has('activated')) {
          if(!this._parent.activated) this._parent.desactivatedAt = new Date().toISOString();
          else this._parent.desactivatedAt = '';
        }
      }
    });

    this._reset(this._parent);

    this._attributes = this.attributes.slice();
    this._attributes.push(...this._userId.attributes);
    this._attributes.push(...this._avatar.attributes);
    this._attributes.push(...this._resources.attributes);
    this._attributes.push(...this._bookmarks.attributes);
    this._attributes.push(...this._logs.attributes);
    this._attributes.push(...this._tags.attributes);
    this._attributes.push(...this._roles.attributes);
    this._attributes.push(...this._groups.attributes);

  }

  private _reset(user: UserModel) {
    this._userId = new UserIdClass(user as UserIdModel, this._form, this._requirements, this._isRegistered, this._state);
    this._avatar = new AvatarClass(user as AvatarModel, this._state, this.modifications$);
    this._resources = new ResourcesClass(user as ResourcesModel, this._state);
    this._bookmarks = new BookmarksClass(user as BookmarksModel, this._state);
    this._logs = new LogsClass(user as LogsModel, this._state);
    this._tags = new TagsClass(user as TagsModel, this._state);
    this._roles = new RolesClass(user as RolesModel, this._state);
    this._groups = new GroupsClass(user as GroupsModel, this._state);

    if(this._parent.filters === undefined) this._parent.filters = [];
    this._filters = this._parent.filters.map((filter: PivotFiltersModel) => new PivotFiltersClass(filter, this._state, this._modifications$, this._lastModification$));

    this.attributes.forEach((attribute: string) => {
      this._parent[attribute] = user[attribute];
    });

    this._inital = DeepCopy(user);

    this._state.flush();
    this._modifications$.next(this._state);
  }

  reset() {
    this._state.flush();
    this._reset(this._inital);
  }

  get model(): Readonly<UserModel> {
    const filters = this._filters.filter((flts: PivotFiltersClass) => flts.values.length > 0).map((flts: PivotFiltersClass) => flts.model);
    return DeepMerge(super.model, { filters: filters } as UserModel);
  }

  get attributes(): Readonly<string[]> {
    return ['lastConnection', 'activated', 'language', 'desactivatedAt', 'createAt'];
  }

  get class(): string {
    return 'user';
  }

  get id(): UserIdClass {
    return this._userId;
  }

  get avatar(): AvatarClass {
    return this._avatar;
  }

  get resources(): ResourcesClass {
    return this._resources;
  }

  get filters(): Readonly<PivotFiltersClass[]> {
    return this._filters.filter(f => f.values.length > 0);
  }

  hasFilter(uid: UID): Readonly<boolean> {
    return this._filters.map((filter: PivotFiltersClass) => filter.uid.value).indexOf(uid) !== -1;
  }

  filter(uid: UID): Readonly<PivotFiltersClass> {
    const index = this._filters.map((filter: PivotFiltersClass) => filter.uid.value).indexOf(uid);
    if(index !== -1) return this._filters[index];
    else {
      const indexModel = this._parent.filters.push({
        uid: uid,
        include: true,
        values: []
      });
      const indexClass = this._filters.push(new PivotFiltersClass(this._parent.filters[indexModel - 1], this._state, this._modifications$, this._lastModification$));
      return this._filters[indexClass - 1];
    }
  }

  get bookmarks(): BookmarksClass {
    return this._bookmarks;
  }

  get logs(): LogsClass {
    return this._logs;
  }

  get tags(): TagsClass {
    return this._tags;
  }

  get roles(): RolesClass {
    return this._roles;
  }

  get groups(): GroupsClass {
    return this._groups;
  }

  desactivatedAt(dateType: MomentDateType = 'local'): Readonly<string> {
    if(this._parent.desactivatedAt.length === 0) return '';
    switch(dateType) {
      case 'local': return DateApplyFormat(this._parent.desactivatedAt);
      case 'ISO': return this._parent.desactivatedAt;
      case 'fromNow': return DateFromNow(this._parent.desactivatedAt);
    }
  }

  createAt(dateType: MomentDateType = 'local'): Readonly<string> {
    if(this._parent.createAt.length === 0) return '';
    switch(dateType) {
      case 'local': return DateApplyFormat(this._parent.createAt);
      case 'ISO': return this._parent.createAt;
      case 'fromNow': return DateFromNow(this._parent.createAt);
    }
  }

  lastConnection(dateType: MomentDateType = 'local'): Readonly<string> {
    if(this._parent.lastConnection.length === 0) return '';
    switch(dateType) {
      case 'local': return DateApplyFormat(this._parent.lastConnection);
      case 'ISO': return this._parent.lastConnection;
      case 'fromNow': return DateFromNow(this._parent.lastConnection);
    }
  }

  get deletionDuration(): Readonly<number> {
    let deleteAt: string;
    if(this.hasConnectedOnce && this.activated) {
      deleteAt = Moment()(this._parent.lastConnection).add(DeletionDurations().fromLastConnection, 'days').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    }
    else if(!this.hasConnectedOnce) {
      deleteAt = Moment()(this._parent.createAt).add(DeletionDurations().fromCreation, 'days').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    }
    else if(this.hasConnectedOnce && !this.activated) {
      deleteAt = Moment()(this._parent.desactivatedAt).add(DeletionDurations().fromDesactivation, 'days').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    }
    return Moment()(deleteAt).diff(Moment()(), 'days');
  }

  get hasConnectedOnce(): Readonly<boolean> {
    return this._parent.lastConnection.length > 0;
  }

  get activated(): Readonly<boolean> {
    return this._parent.activated;
  }

  get language(): Readonly<LanguageType> {
    return this._parent.language;
  }

  get uid(): Readonly<UidClass> {
    return this._userId.uid;
  }

  get isPrivilegesModified(): Readonly<boolean> {
    return this._isPrivilegesModified;
  }

  set isPrivilegesModified(modified: boolean) {
    this._isPrivilegesModified = modified;
  }

}