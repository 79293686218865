/**
 * @module Widget
 */

import { Component, Input } from '@angular/core';
import { WidgetLayersClass } from '@class/widgetLayers.class';
import { UID } from '@models/uid.model';
import { ItemColor } from '@functions/item.functions';
import { OrientationType } from 'chart.js';

@Component({
  selector: 'app-widget-layers-carousel',
  templateUrl: './widget-layers-carousel.component.html',
  styleUrls: ['./widget-layers-carousel.component.css']
})
export class WidgetLayersCarouselComponent {

  @Input() widgetLayers: WidgetLayersClass;
  @Input() current: UID;

  color = ItemColor('widgets');
  orientations: OrientationType[] = ['column', 'row'];

  constructor() {}

  receiveValue(carousel: boolean) {
    this.widgetLayers.layer(this.current).change({ stickerCarousel: carousel });
    if(this.widgetLayers.uid.value === this.current) this.widgetLayers.duplicateLayersOptions({ stickerCarousel: carousel }, this.current);
  }

  onValue(orientation: OrientationType) {
    this.widgetLayers.layer(this.current).change({ stickerOrientation: orientation });
    if(this.widgetLayers.uid.value === this.current) this.widgetLayers.duplicateLayersOptions({ stickerOrientation: orientation }, this.current);
  }

}
