/**
 * @module Widget
 */

import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { ThemeOptionsClass } from '@class/themeOptions.class';
import { WidgetLayersDefinedOptionModel } from '@models/widgetLayers.model';
import { ThemeOptionsConfiguration, ThemeOptionsConfigurationMainCategories } from '@functions/theme.functions';
import { ThemeOptionsConfigurationType } from '@models/themeOptions.model';
import { ItemColor } from '@functions/item.functions';

@Component({
  selector: 'app-widget-layers-theme-options-list',
  templateUrl: './widget-layers-theme-options-list.component.html',
  styleUrls: ['./widget-layers-theme-options-list.component.css']
})
export class WidgetLayersThemeOptionsListComponent implements OnInit {

  @Input() themeOptions: ThemeOptionsClass;
  @Output() sendSelect: EventEmitter<WidgetLayersDefinedOptionModel>;

  list: ThemeOptionsConfigurationType;
  currentCategorie: string;
  categories: string[];
  color = ItemColor('themes');

  axes: string[];
  series: number[];
  datalabels: number[];

  currentAxis: string = 'X0';
  currentSerie: number = 0;
  currentDatalabels: number = 0;

  constructor() {
    this.categories = ThemeOptionsConfigurationMainCategories();
    this.currentCategorie = this.categories[0];
    this.list = ThemeOptionsConfiguration();
    this.sendSelect = new EventEmitter<WidgetLayersDefinedOptionModel>();
  }

  ngOnInit() {
    let xAxes = new Array(this.themeOptions.xAxesCount()).fill(null).map((value: any, index: number) => { return `X${index}`; });
    let yAxes = new Array(this.themeOptions.yAxesCount()).fill(null).map((value: any, index: number) => { return `Y${index}`; });
    this.axes = xAxes.concat(yAxes);
    this.series = new Array(this.themeOptions.seriesCount()).fill(null).map((value: any, index: number) => { return index; });
    this.datalabels = new Array(this.themeOptions.datalabelsCount()).fill(null).map((value: any, index: number) => { return index; });
  }

  receiveSelect(selection: WidgetLayersDefinedOptionModel) {
    switch(selection.type) {
      case 'axes':
        selection.axesXY = this.currentAxis[0].toLowerCase() as any;
        selection.index = parseInt(this.currentAxis[1]);
        break;
      case 'datalabels':
        selection.index = this.currentDatalabels;
        break;
      case 'series':
        selection.index = this.currentSerie;
        break;
    }
    this.sendSelect.emit(selection);
  }

  onType(categorie: string) {
    this.currentCategorie = categorie;
  }

  onAxis(value: string) {
    this.currentAxis = value;
  }

  onSerie(value: number) {
    this.currentSerie = value;
  }

  onDatalabels(value: number) {
    this.currentDatalabels = value;
  }

}
