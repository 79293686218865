/**
 * @module Theme
 */

import { Component, Input } from '@angular/core';
import { ItemColor } from '@functions/item.functions';
import { DeepMerge } from '@functions/copy.functions';
import { ChartType } from '@models/chart.model';
import { ThemeOptionsClass } from '@class/themeOptions.class';
import { ThemeOptionType } from '@models/themeStudio.model';

@Component({
  selector: 'app-theme-option-tooltips-mode',
  templateUrl: './theme-option-tooltips-mode.component.html',
  styleUrls: ['./theme-option-tooltips-mode.component.css']
})
export class ThemeOptionTooltipsModeComponent {

  @Input() themeOptions: ThemeOptionsClass;

  showDetails: boolean;
  color = ItemColor('themes');
  optionType: ThemeOptionType = 'tooltips_mode';
  limitedToNonCartesians: ChartType[] = ['doughnut', 'pie', 'polarArea'];
  limitedToCartesians: ChartType[] = ['bar', 'line', 'horizontalBar', 'bubble', 'scatter', 'radar'];

  constructor() {}

  receiveShowDetails(showDetails: boolean) {
    this.showDetails = showDetails;
  }

  onMode(value: string) {
    this.themeOptions.setOption('tooltips_mode', value);
    this.onModeNonCartesians(value);
    this.onModeCartesians(value);
  }

  onModeNonCartesians(value: string) {
    this.themeOptions.setOption('tooltips_mode', value, this.limitedToNonCartesians);
  }

  onModeCartesians(value: string) {
    this.themeOptions.setOption('tooltips_mode', value, this.limitedToCartesians);
  }

  modes = {
    POINT: { value: 'point', label: 'POINT' },
    NEAREST: { value: 'nearest', label: 'NEAREST' },
    INDEX: { value: 'index', label: 'INDEX' },
    DATASET: { value: 'dataset', label: 'DATASET' }
  };

  cartesians = DeepMerge(this.modes, {
    X: { value: 'x', label: 'X_AXIS' },
    Y: { value: 'y', label: 'Y_AXIS' }
  } as any);

}
