/**
 * @module Tag
 */

import { TagsModel, TagModel } from '@models/tag.model';
import { MapClass } from '@class/map.class';
import { UID, UidModel } from '@models/uid.model';
import { ModificationClass, ModificationStateClass } from '@class/modification.class';
import { UidClass } from '@class/uid.class';
import { LabelsClass } from '@class/labels.class';
import { IconClass } from '@class/icon.class';
import { ColorClass } from '@class/color.class';
import { LinksClass } from '@class/links.class';
import { LinksModel } from '@models/links.model';
import { ColorModel } from '@models/color.model';
import { IconModel } from '@models/icon.model';
import { LabelsModel } from '@models/labels.model';
import { DeepCopy } from '@functions/copy.functions';

export class TagsClass extends ModificationClass<TagsModel> {

  protected _parent: TagsModel;
  protected _inital: TagsModel;
  protected _attributes: string[];
  
  private _tags: MapClass<boolean>;

  constructor(tags: TagsModel, state: ModificationStateClass) {

    super();
    
    this._parent = tags;
    this._attributes = this.attributes;
    this._state = state;
    this._tags = new MapClass(this._parent.tags || {});

    this._inital = DeepCopy({ tags: this._parent.tags });

  }

  get class(): Readonly<string> {
    return 'tags';
  }

  get attributes(): string[] {
    return ['tags'];
  }

  get list(): Readonly<UID[]> {
    return this._tags.keys;
  }

  has(uid: UID): Readonly<boolean> {
    return this._tags.has(uid);
  }

  add(uid: UID) {
    this._tags.add(uid, true);
    this.emit(this.attributes);
  }

  remove(uid: UID) {
    this._tags.remove(uid);
    this.emit(this.attributes);
  }

}

export class TagClass extends ModificationClass<TagModel> {
  
  private _uid: UidClass;
  private _labels: LabelsClass;
  private _icon: IconClass;
  private _color: ColorClass;
  private _links: LinksClass;

  protected _parent: TagModel;
  protected _inital: TagModel;
  protected _attributes: string[];

  private _isRegistered: boolean;

  constructor(tag: TagModel, isRegistered: boolean = true) {

    super();

    this._parent = tag;
    this._isRegistered = isRegistered;

    this._reset(this._parent);

    this._attributes = this.attributes.slice();
    this._attributes.push(...this._uid.attributes);
    this._attributes.push(...this._labels.attributes);
    this._attributes.push(...this._icon.attributes);
    this._attributes.push(...this._color.attributes);
    this._attributes.push(...this._links.attributes);

  }

  private _reset(tag: TagModel) {
    this._uid = new UidClass(tag as UidModel, this._isRegistered);
    this._labels = new LabelsClass(tag as LabelsModel, this._form, this._requirements, this._state);
    this._icon = new IconClass(tag as IconModel, this._state);
    this._color = new ColorClass(tag as ColorModel, this._state);
    this._links = new LinksClass(tag as LinksModel, this._state);

    this.attributes.forEach((attribute: string) => {
      this._parent[attribute] = tag[attribute];
    });

    this._inital = DeepCopy(tag);

    this._state.flush();
    this._modifications$.next(this._state);
  }

  reset() {
    this._state.flush();
    this._reset(this._inital);
  }

  get attributes(): Readonly<string[]> {
    return [];
  }

  get class(): string {
    return 'tag';
  }

  get uid(): UidClass {
    return this._uid;
  }

  get labels(): LabelsClass {
    return this._labels;
  }

  get icon(): IconClass {
    return this._icon;
  }

  get color(): ColorClass {
    return this._color;
  }

  get links(): LinksClass {
    return this._links;
  }

}