<app-theme-option [categories]="themeOptions.optionsConfiguration[optionType].categories" [label]="themeOptions.optionsConfiguration[optionType].label">
  <div app-theme-option-content>
    <app-show-details-option [showDetails]="showDetails" (sendShowDetails)="receiveShowDetails($event)">
      <div app-show-details-option-content>
        <app-theme-option-font-family [color]="color" [fontFamily]="themeOptions.options().title.fontFamily" (sendFontFamily)="receiveValue($event)"></app-theme-option-font-family>
      </div>
    </app-show-details-option>
  </div>
</app-theme-option>

<div *ngIf="showDetails">
  <app-theme-option [categories]="themeOptions.optionsConfiguration[optionType].categoriesAnbdLabel" [label]="type | chart" [dark]="4" *ngFor="let type of themeOptions.limitedTo(optionType)">
    <div app-theme-option-content>
      <app-theme-option-font-family [color]="color" [fontFamily]="themeOptions.options(type).title.fontFamily" (sendFontFamily)="receiveValue($event, type)"></app-theme-option-font-family>
    </div>
  </app-theme-option>
</div>