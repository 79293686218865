/**
 * @module Pivot
 */

import { Component, Input, OnInit, OnDestroy, EventEmitter, Output, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { PivotClass } from '@class/pivot.class';
import { ItemColor, ItemIcon } from '@functions/item.functions';
import { PivotService } from '@services/pivot/pivot.service';
import { UID } from '@models/uid.model';
import { Subscription } from 'rxjs';
import { DeepCopy } from '@functions/copy.functions';
import { LinksClass } from '@class/links.class';
import { AuthService } from '@services/auth/auth.service';
import { LanguageType } from '@models/language.model';

@Component({
  selector: 'app-pivots-links-item',
  templateUrl: './pivots-links-item.component.html',
  styleUrls: ['./pivots-links-item.component.css']
})
export class PivotsLinksItemComponent implements OnInit, OnDestroy, OnChanges {

  @Input() uid: UID;
  @Input() links: LinksClass;
  @Input() searchLabel: string;
  @Output() sendSelected: EventEmitter<UID>;
  @Output() sendLinkChange: EventEmitter<{ from: PivotClass, to: PivotClass }>;

  color: string = ItemColor('pivots');
  icon: string = ItemIcon('pivots');

  isChanged: boolean;
  exclude: UID[] = [];

  lastModification$sub: Subscription;
  partial$sub: Subscription;
  pivot: PivotClass;
  display: boolean;
  language: LanguageType;
  language$sub: Subscription;

  constructor(
    private $pivots: PivotService,
    private $auth: AuthService
  ) { 
    this.isChanged = false;
    this.sendSelected = new EventEmitter<UID>();
    this.sendLinkChange = new EventEmitter<{ from: PivotClass, to: PivotClass }>();
    this.display = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.searchLabel != undefined && !changes.searchLabel.firstChange) {
      this.setDisplay();
    }
  }
  
  ngOnInit() {
    this.partial$sub = this.$pivots.getPartial$(this.uid).subscribe({
      next: (pivot: PivotClass) => {
        if(!!pivot) {
          this.pivot = pivot;
          this.setDisplay();
        }
        else {
          if(this.links.isNewItem('pivots', this.uid)) {
            this.pivot = this.links.newItems('pivots').filter(p => p['uid'].value === this.uid)[0] as PivotClass;
          }
          else {
            this.pivot = this.$pivots.create({ uid: this.uid });
          }
        }
      }
    });

    this.exclude = this.links.list('pivots').slice();

    this.lastModification$sub = this.links.lastModification$.subscribe((attribute: string) => {
      if(attribute === 'links') this.exclude = this.links.list('pivots').slice();
    });

    this.language$sub = this.$auth.language$.subscribe({
      next: (language: LanguageType) => {
        this.language = language;
      }
    });
  }

  setDisplay() {
    console.log(this.searchLabel)
    this.display = this.searchLabel == undefined || this.pivot.labels.value(this.language).toLowerCase().includes(this.searchLabel.toLowerCase());
  }

  ngOnDestroy() {
    this.partial$sub.unsubscribe();
  }

  receiveSelected() {
    this.sendSelected.emit(this.pivot.uid.value);
  }

  receiveLinkChange(pivot: PivotClass) {
    if(!pivot.uid.isRegistered) {
      pivot.change({ labels: this.pivot.labels.model.labels });
    }
    this.sendLinkChange.emit({ from: this.pivot, to: pivot });
    this.pivot = new PivotClass(DeepCopy(pivot.model), pivot.uid.isRegistered);
  }

  onChange() {
    this.isChanged = !this.isChanged;
  }

}
