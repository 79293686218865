/**
 * @module Theme
 */

import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ThemeOptionsClass } from '@class/themeOptions.class';
import { ItemColor } from '@functions/item.functions';
import { ChartType } from '@models/chart.model';
import { Subscription } from 'rxjs';
import { ThemeOptionType } from '@models/themeStudio.model';

@Component({
  selector: 'app-theme-option-elements-chart-circumference',
  templateUrl: './theme-option-elements-chart-circumference.component.html',
  styleUrls: ['./theme-option-elements-chart-circumference.component.css']
})
export class ThemeOptionElementsChartCircumferenceComponent implements OnInit, OnDestroy {

  @Input() themeOptions: ThemeOptionsClass;

  showDetails: boolean;
  color = ItemColor('themes');
  optionType: ThemeOptionType = 'elements_chart_circumference';
  circumference: number;
  circumferences: { [key in ChartType]: number };
  changedOptions$sub: Subscription;

  constructor() {
    this.showDetails = false;
    this.circumferences = {} as { [key in ChartType]: number };
  }

  ngOnInit() {
    this.setAttributes();

    this.changedOptions$sub = this.themeOptions.changedOptions$.subscribe({
      next:(option: ThemeOptionType) => {
        if(option === this.optionType) {
          this.setAttributes();
        }
      }
    });
  }

  ngOnDestroy() {
    this.changedOptions$sub.unsubscribe();
  }

  setAttributes() {
    this.circumference = this.themeOptions.options().circumference / Math.PI * 100 / 2;
    this.themeOptions.limitedTo(this.optionType).forEach((type: ChartType) => {
      this.circumferences[type] = this.themeOptions.options(type).circumference / Math.PI * 100 / 2;
    });
  }

  receiveValue(value: number, type?: ChartType) {
    this.themeOptions.setOption(this.optionType, value / 100 * Math.PI * 2, type ? [type] : undefined);
  }

  receiveShowDetails(showDetails: boolean) {
    this.showDetails = showDetails;
  }

}
