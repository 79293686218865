/**
 * @module Widget
 */

import { Component, Input } from '@angular/core';
import { WidgetIndicatorClass } from '@class/widgetIndicator.class';
import { UID } from '@models/uid.model';
import { WidgetDatalabelsTypes, WidgetDatalabelsTableAuthorized } from '@functions/widget.functions';
import { WidgetDatalabelsType } from '@models/widget.model';
import { SortableStopModel } from '@directives/sortable/sortable.directive';
import { ItemColor } from '@functions/item.functions';
import { NumbroModel } from '@models/numbro.model';
import { WidgetDatalabelsClass } from '@class/widgetDatalabels.class';
import { WidgetLayersClass } from '@class/widgetLayers.class';
import { WidgetStudioService } from '@services/widgetStudio/widget-studio.service';
import { WidgetDatalabelsModel } from '@models/widgetLayers.model';

@Component({
  selector: 'app-widget-indicators-datalabels',
  templateUrl: './widget-indicators-datalabels.component.html',
  styleUrls: ['./widget-indicators-datalabels.component.css']
})
export class WidgetIndicatorsDatalabelsComponent {

  @Input() widgetLayers: WidgetLayersClass;
  @Input() indicator: WidgetIndicatorClass;
  @Input() current: UID;

  types: WidgetDatalabelsType[] = WidgetDatalabelsTypes();
  tableAuthorized: WidgetDatalabelsType[] = WidgetDatalabelsTableAuthorized();
  color = ItemColor('indicators');
  numbro: NumbroModel;
  type: WidgetDatalabelsType;
  datalabels: WidgetDatalabelsClass;
  ranks: number[];
  showEditor: boolean = false;
  showSticker: boolean = false;

  constructor(
    private $studio: WidgetStudioService
  ) {}

  receiveTooltips(type: WidgetDatalabelsType) {
    const datalabels = this.indicator.datalabelsByType(type);
    const _datalabels: WidgetDatalabelsModel = { 
      type: type, 
      display: datalabels === undefined ? false : this.indicator.datalabelsByType(type).display, 
      hover: datalabels === undefined ? false : this.indicator.datalabelsByType(type).hover,
      table: datalabels === undefined ? false : this.indicator.datalabelsByType(type).table,
      tooltips: datalabels === undefined ? true : !this.indicator.datalabelsByType(type).tooltips 
    };
    this.indicator.setDatalabels(_datalabels);
    if(this.$studio.autoDuplicate(this.widgetLayers.uid.value)) this.widgetLayers.duplicateDatalabels([_datalabels]);
  }

  receiveDisplay(type: WidgetDatalabelsType) {
    const datalabels = this.indicator.datalabelsByType(type);
    const _datalabels: WidgetDatalabelsModel = { 
      type: type, 
      display: datalabels === undefined ? true : !this.indicator.datalabelsByType(type).display, 
      hover: datalabels === undefined ? false : this.indicator.datalabelsByType(type).hover,
      table: datalabels === undefined ? false : this.indicator.datalabelsByType(type).table,
      tooltips: datalabels === undefined ? false : this.indicator.datalabelsByType(type).tooltips 
    };
    this.indicator.setDatalabels(_datalabels);
    if(this.$studio.autoDuplicate(this.widgetLayers.uid.value)) this.widgetLayers.duplicateDatalabels([_datalabels]);
  }

  receiveHover(type: WidgetDatalabelsType) {
    const datalabels = this.indicator.datalabelsByType(type);
    const _datalabels: WidgetDatalabelsModel = { 
      type: type, 
      display: datalabels === undefined ? false : this.indicator.datalabelsByType(type).display, 
      hover: datalabels === undefined ? true : !this.indicator.datalabelsByType(type).hover,
      table: datalabels === undefined ? false : this.indicator.datalabelsByType(type).table,
      tooltips: datalabels === undefined ? false : this.indicator.datalabelsByType(type).tooltips 
    };
    this.indicator.setDatalabels(_datalabels);
    if(this.$studio.autoDuplicate(this.widgetLayers.uid.value)) this.widgetLayers.duplicateDatalabels([_datalabels]);
  }

  receiveTable(type: WidgetDatalabelsType) {
    const datalabels = this.indicator.datalabelsByType(type);
    const _datalabels: WidgetDatalabelsModel = { 
      type: type, 
      display: datalabels === undefined ? false : this.indicator.datalabelsByType(type).display, 
      hover: datalabels === undefined ? false : this.indicator.datalabelsByType(type).hover ,
      table: datalabels === undefined ? true : !this.indicator.datalabelsByType(type).table,
      tooltips: datalabels === undefined ? false : this.indicator.datalabelsByType(type).tooltips 
    };
    this.indicator.setDatalabels(_datalabels);
    if(this.$studio.autoDuplicate(this.widgetLayers.uid.value)) this.widgetLayers.duplicateDatalabels([_datalabels]);
  }

  receiveSortableStop(sortable: SortableStopModel) {
    this.indicator.setDatalabels(this.indicator.datalabelsByType(sortable.element.type), sortable.targetIndex);
  }

  onEdit(type: WidgetDatalabelsType) {
    this.type = type;
    this.datalabels = this.indicator.datalabelsByType(type) as WidgetDatalabelsClass;
    this.numbro = this.datalabels.numbro;
    this.ranks = new Array(this.indicator.datalabels.length).fill('').map((value: any, index: number) => { return index; });
    this.showEditor = true;
  }

  onEditSticker() {
    this.showEditor = !this.showSticker;
    this.showSticker = !this.showSticker;
  }

  onClose() {
    this.showEditor = false;
  }

  receiveNumbro(numbro: NumbroModel) {
    this.indicator.datalabelsByType(this.type).numbro.change({ type: numbro.type, format: numbro.format });
    if(this.$studio.autoDuplicate(this.widgetLayers.uid.value)) this.widgetLayers.duplicateDatalabels([this.indicator.datalabelsByType(this.type).model]);
  }

  receiveIndicatorNumbro(numbro: NumbroModel) {
    this.indicator.numbro.change({ type: numbro.type, format: numbro.format });
    if(this.$studio.autoDuplicate(this.widgetLayers.uid.value)) this.widgetLayers.duplicateNumbro(this.indicator.numbro.model);
  }

}