<div class="d-flex align-items-center justify-content-between mt-3 app-widget-studio-sub-layers-title">
  <div class="blue-grey-text font-weight-bold">
    <i class="fa-lg {{icon}} {{color}}-text mr-1"></i>
    {{ widgetLayers.layer(current).layerLabel.value(language) | translate }}
  </div>
  <div class="btn-group" mdbDropdown>
    <button mdbDropdownToggle type="button" size="sm" mdbBtn color="light" class="blue-grey-text z-depth-0 dropdown-toggle waves-light d-flex align-items-center px-3" mdbWavesEffect>
      <i class="far fa-bars"></i>&nbsp;
    </button>
  
    <div class="dropdown-menu dropdown-dark dropdown-menu-right">

      <a class="dropdown-item z-depth-0" (click)="editorIndex = 0">{{ 'SETTINGS' | translate }}</a>
      <a class="dropdown-item z-depth-0" (click)="editorIndex = 1">{{ 'AXES' | translate }}</a>
      <a class="dropdown-item z-depth-0" (click)="editorIndex = 2">{{ 'STICKER/CHART' | translate }}</a>
      <a class="dropdown-item z-depth-0" (click)="editorIndex = 3">{{ 'GAUGE/CHART' | translate }}</a>
      <a class="dropdown-item z-depth-0" (click)="editorIndex = 4">{{ 'THEMES' | translate }}</a>
      <a class="dropdown-item z-depth-0" (click)="editorIndex = 5" *ngIf="editorIndexMax == 5">{{ 'DATES' | translate }}</a>

    </div>
  </div>
</div>

<hr>

<div [ngSwitch]="editorIndex">
  <div *ngSwitchCase="0">
    <app-editor-section-title [title]="'SETTINGS'" (sendNext)="receiveNext($event)"></app-editor-section-title>
    <hr class="mt-2">

    <app-widget-option [categories]="['OPTIONS']" [label]="'DUPLICATE'" *ngIf="widgetLayers.uid.value != current ; else autoDuplicate">
      <div app-widget-option-content>
        <div class=d-flex>
          <app-links-item-option class="d-block mx-1" [symbol]="'paint-roller'" [selected]="duplicable" (sendSelected)="receiveDuplicable()"></app-links-item-option>
        </div>
      </div>
    </app-widget-option>

    <ng-template #autoDuplicate>
      <div class="alert alert-info animated slideInRight mt-3 mb-1 font-very-small" role="alert">
        <i class="fas fa-paint-roller mr-1"></i>
        {{ 'AUTO_DUPLICATE_OPTIONS/TEXT' | translate }}
      </div>
    </ng-template>

    <hr>

    <div class="d-flex">
      <app-widget-layers-chart class="flex-grow-1" [widgetLayers]="widgetLayers" [current]="current"></app-widget-layers-chart>
      <div class="d-flex blue-grey lighten-5 p-2 mb-1 ml-1 align-items-center" *ngIf="duplicable">
        <button (click)="onDuplicate('chartType')" mdbBtn type="button" class="px-2 m-0 z-depth-0" color="default" size="sm" mdbWavesEffect><i class="fas fa-paint-roller fa-fw animated rotateIn"></i></button>
      </div>
    </div>

    <div class="d-flex">
      <app-widget-layers-stacked class="flex-grow-1" [widgetLayers]="widgetLayers" [current]="current"></app-widget-layers-stacked>
      <div class="d-flex blue-grey lighten-5 p-2 mb-1 ml-1 align-items-center" *ngIf="duplicable">
        <button (click)="onDuplicate('stacked')" mdbBtn type="button" class="px-2 m-0 z-depth-0" color="default" size="sm" mdbWavesEffect><i class="fas fa-paint-roller fa-fw animated rotateIn"></i></button>
      </div>
    </div>

    <div class="d-flex">
      <app-widget-layers-sort class="flex-grow-1" [widgetLayers]="widgetLayers" [current]="current"></app-widget-layers-sort>
      <div class="d-flex blue-grey lighten-5 p-2 mb-1 ml-1 align-items-center" *ngIf="duplicable">
        <button (click)="onDuplicate('sort')" mdbBtn type="button" class="px-2 m-0 z-depth-0" color="default" size="sm" mdbWavesEffect><i class="fas fa-paint-roller fa-fw animated rotateIn"></i></button>
      </div>
    </div>

    <div class="d-flex">
      <app-widget-layers-max-values class="flex-grow-1" [widgetLayers]="widgetLayers" [current]="current"></app-widget-layers-max-values>
      <div class="d-flex blue-grey lighten-5 p-2 mb-1 ml-1 align-items-center" *ngIf="duplicable">
        <button (click)="onDuplicate('maxValues')" mdbBtn type="button" class="px-2 m-0 z-depth-0" color="default" size="sm" mdbWavesEffect><i class="fas fa-paint-roller fa-fw animated rotateIn"></i></button>
      </div>
    </div>

    <app-widget-layers-label [layer]="widgetLayers.layer(current)"></app-widget-layers-label>
    <app-widget-layers-title [widgetLayers]="widgetLayers" [current]="current" class="mt-4 d-block"></app-widget-layers-title>
  </div>
  <div *ngSwitchCase="1">
    <app-editor-section-title [title]="'AXES'" (sendNext)="receiveNext($event)"></app-editor-section-title>
    <hr class="mt-2">

    <app-widget-layers-axes-numbro [widgetLayers]="widgetLayers" [current]="current"></app-widget-layers-axes-numbro>

  </div>
  <div *ngSwitchCase="2">
    <app-editor-section-title [title]="'STICKER/CHART'" (sendNext)="receiveNext($event)"></app-editor-section-title>
    <hr class="mt-2">

    <div class="d-flex">
      <app-widget-layers-carousel class="flex-grow-1" [widgetLayers]="widgetLayers" [current]="current"></app-widget-layers-carousel>
      <div class="d-flex blue-grey lighten-5 p-2 mb-1 ml-1 align-items-center" *ngIf="duplicable">
        <button (click)="onDuplicate('stickerCarousel')" mdbBtn type="button" class="px-2 m-0 z-depth-0" color="default" size="sm" mdbWavesEffect><i class="fas fa-paint-roller fa-fw animated rotateIn"></i></button>
      </div>
    </div>

    <div class="d-flex">
      <app-widget-layers-template class="flex-grow-1" [widgetLayers]="widgetLayers" [current]="current"></app-widget-layers-template>
      <div class="d-flex blue-grey lighten-5 p-2 mb-1 ml-1 align-items-center" *ngIf="duplicable">
        <button (click)="onDuplicate('stickerTemplate')" mdbBtn type="button" class="px-2 m-0 z-depth-0" color="default" size="sm" mdbWavesEffect><i class="fas fa-paint-roller fa-fw animated rotateIn"></i></button>
      </div>
    </div>

    <div class="d-flex">
      <app-widget-layers-fully-colored class="flex-grow-1" [widgetLayers]="widgetLayers" [current]="current"></app-widget-layers-fully-colored>
      <div class="d-flex blue-grey lighten-5 p-2 mb-1 ml-1 align-items-center" *ngIf="duplicable">
        <button (click)="onDuplicate('stickerFullyColored')" mdbBtn type="button" class="px-2 m-0 z-depth-0" color="default" size="sm" mdbWavesEffect><i class="fas fa-paint-roller fa-fw animated rotateIn"></i></button>
      </div>
    </div>

  </div>

  <div *ngSwitchCase="3">
    <app-editor-section-title [title]="'GAUGE/CHART'" (sendNext)="receiveNext($event)"></app-editor-section-title>
    <hr class="mt-2">

    <div class="d-flex">
      <app-widget-layers-donut class="flex-grow-1" [widgetLayers]="widgetLayers" [current]="current"></app-widget-layers-donut>
      <div class="d-flex blue-grey lighten-5 p-2 mb-1 ml-1 align-items-center" *ngIf="duplicable">
        <button (click)="onDuplicate('gaugeDonut')" mdbBtn type="button" class="px-2 m-0 z-depth-0" color="default" size="sm" mdbWavesEffect><i class="fas fa-paint-roller fa-fw animated rotateIn"></i></button>
      </div>
    </div>

    <div class="d-flex">
      <app-widget-layers-percent class="flex-grow-1" [widgetLayers]="widgetLayers" [current]="current"></app-widget-layers-percent>
      <div class="d-flex blue-grey lighten-5 p-2 mb-1 ml-1 align-items-center" *ngIf="duplicable">
        <button (click)="onDuplicate('gaugePercent')" mdbBtn type="button" class="px-2 m-0 z-depth-0" color="default" size="sm" mdbWavesEffect><i class="fas fa-paint-roller fa-fw animated rotateIn"></i></button>
      </div>
    </div>

    <div class="d-flex">
      <app-widget-layers-target class="flex-grow-1" [widgetLayers]="widgetLayers" [current]="current"></app-widget-layers-target>
      <div class="d-flex blue-grey lighten-5 p-2 mb-1 ml-1 align-items-center" *ngIf="duplicable">
        <button (click)="onDuplicate('gaugeTarget')" mdbBtn type="button" class="px-2 m-0 z-depth-0" color="default" size="sm" mdbWavesEffect><i class="fas fa-paint-roller fa-fw animated rotateIn"></i></button>
      </div>
    </div>

    <div class="d-flex">
      <app-widget-layers-max class="flex-grow-1" [widgetLayers]="widgetLayers" [current]="current"></app-widget-layers-max>
      <div class="d-flex blue-grey lighten-5 p-2 mb-1 ml-1 align-items-center" *ngIf="duplicable">
        <button (click)="onDuplicate('gaugeMax')" mdbBtn type="button" class="px-2 m-0 z-depth-0" color="default" size="sm" mdbWavesEffect><i class="fas fa-paint-roller fa-fw animated rotateIn"></i></button>
      </div>
    </div>

    <div class="d-flex">
      <app-widget-layers-min class="flex-grow-1" [widgetLayers]="widgetLayers" [current]="current"></app-widget-layers-min>
      <div class="d-flex blue-grey lighten-5 p-2 mb-1 ml-1 align-items-center" *ngIf="duplicable">
        <button (click)="onDuplicate('gaugeMin')" mdbBtn type="button" class="px-2 m-0 z-depth-0" color="default" size="sm" mdbWavesEffect><i class="fas fa-paint-roller fa-fw animated rotateIn"></i></button>
      </div>
    </div>

    <div class="d-flex">
      <app-widget-layers-hide-min-max class="flex-grow-1" [widgetLayers]="widgetLayers" [current]="current"></app-widget-layers-hide-min-max>
      <div class="d-flex blue-grey lighten-5 p-2 mb-1 ml-1 align-items-center" *ngIf="duplicable">
        <button (click)="onDuplicate('gaugeHideMinMax')" mdbBtn type="button" class="px-2 m-0 z-depth-0" color="default" size="sm" mdbWavesEffect><i class="fas fa-paint-roller fa-fw animated rotateIn"></i></button>
      </div>
    </div>

    <div class="d-flex">
      <app-widget-layers-display-remaining class="flex-grow-1" [widgetLayers]="widgetLayers" [current]="current"></app-widget-layers-display-remaining>
      <div class="d-flex blue-grey lighten-5 p-2 mb-1 ml-1 align-items-center" *ngIf="duplicable">
        <button (click)="onDuplicate('gaugeDisplayRemaining')" mdbBtn type="button" class="px-2 m-0 z-depth-0" color="default" size="sm" mdbWavesEffect><i class="fas fa-paint-roller fa-fw animated rotateIn"></i></button>
      </div>
    </div>

  </div>
  <div *ngSwitchCase="4">
    <app-editor-section-title [title]="'THEMES'" (sendNext)="receiveNext($event)"></app-editor-section-title>
    <hr class="mt-2">
    <app-widget-layers-theme-options [widgetLayers]="widgetLayers" [themeOptions]="themeOptions" [current]="current"></app-widget-layers-theme-options>
  </div>
  <div *ngSwitchCase="5">
    <app-editor-section-title [title]="'DATES'" (sendNext)="receiveNext($event)"></app-editor-section-title>
    <hr class="mt-2">
    <!--app-widget-layers-fill-blanks-date class="flex-grow-1" [widgetLayers]="widgetLayers" [current]="current"></app-widget-layers-fill-blanks-date-->
    <app-widget-layers-date-format class="d-block mt-4" [widgetLayers]="widgetLayers" [current]="current"></app-widget-layers-date-format>
    <app-widget-layers-date-bounds [layer]="widgetLayers.layer(current)"></app-widget-layers-date-bounds>
  </div>
</div>