/**
 * @module Icon
 */

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as Icons from 'json/icons.json.js';
import { IconClass } from '@class/icon.class';
import { ColorClass } from '@class/color.class';
import { IconType } from '@models/icon.model';

interface CategoriesModel {
  label: string;
  icons: string[];
}

interface CategoriesSelectModel {
  value: string;
  label: string;
  selected?: boolean;
}

@Component({
  selector: 'app-icons',
  templateUrl: './icons.component.html',
  styleUrls: ['./icons.component.css']
})
export class IconsComponent implements OnInit {

  @Input() icon: IconClass;
  @Input() color: ColorClass;
  @Output() sendSelect: EventEmitter<IconClass>;

  categories: CategoriesModel[];
  selectOptions: CategoriesSelectModel[];
  selectedValue: string;
  icons: string[];

  constructor() { 
    this.categories = (Icons.default as unknown as CategoriesModel[]).sort((a: CategoriesModel, b: CategoriesModel) => {
      if(a.label < b.label) { return -1; }
      if(a.label > b.label) { return 1; }
      return 0; 
    });
    this.selectOptions = this.categories.map<CategoriesSelectModel>((category: CategoriesModel, index: number) => { 
      return {
        value: category.label,
        label: `${category.label} (${category.icons.length})`
      };
    });
    this.sendSelect = new EventEmitter<IconClass>();
  }

  getSelectedValue(category: string) {
    const index = this.categories.map((_category: CategoriesModel) => {
      return _category.label;
    }).indexOf(category);
    this.icons = this.categories[index].icons;
  }

  ngOnInit() {
    let index = undefined;
    this.categories.forEach((category: CategoriesModel, i: number) => {
      if(index === undefined && category.icons.includes(this.icon.symbol)) index = i;
    });

    this.selectOptions[index].selected = true;
    this.selectedValue = this.selectOptions[index].value;
    this.icons = this.categories[index].icons;
  }

  onPreview(symbol: string) {
    this.icon.change({ 
      icon: {
        symbol: symbol.replace('fa-', '') 
      }
    });
  }

  onSelect(style: IconType, symbol: string, options?: string[]) {    
    this.icon.change({ 
      icon: {
        style: style, 
        symbol: symbol.replace('fa-', ''), 
        options: options === undefined ? [] : options
      }
    });
    this.sendSelect.emit(this.icon);
  }
}
