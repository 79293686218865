/**
 * @module Indicator
 */

import { Component, Input, OnDestroy, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { IndicatorClass } from '@class/indicator.class';
import { Subscription } from 'rxjs';
import { UID } from '@models/uid.model';
import { IndicatorService } from '@services/indicator/indicator.service';
import { ItemColor } from '@functions/item.functions';
import { LanguageType } from '@models/language.model';
import { AuthService } from '@services/auth/auth.service';

@Component({
  selector: 'app-indicators-select-list',
  templateUrl: './indicators-select-list.component.html',
  styleUrls: ['./indicators-select-list.component.css']
})
export class IndicatorsSelectListComponent implements OnDestroy, OnChanges {

  @Input() selected: UID[] = [];
  @Input() exclusions: UID[] = [];
  @Output() sendIndicator: EventEmitter<IndicatorClass>;

  color = ItemColor('indicators');

  indicators$sub: Subscription;
  list: IndicatorClass[];
  searchLabel: string;
  language: LanguageType;
  language$sub: Subscription;

  constructor(
    private $indicators: IndicatorService,
    private $auth: AuthService
  ) {


    this.language = this.$auth.language$.value;
    this.language$sub = this.$auth.language$.subscribe({
      next: (language: LanguageType) => {
        this.language = language;
      }
    });

    this.sendIndicator = new EventEmitter<IndicatorClass>();
    this.indicators$sub = this.$indicators.listPartials$().subscribe({
      next: (indicators: IndicatorClass[]) => {
        if(!!indicators) this.list = indicators.filter((indicator: IndicatorClass) => !this.exclusions.includes(indicator.uid.value))
        .sort((a : IndicatorClass, b: IndicatorClass) => { return a.labels.value(this.language).localeCompare(b.labels.value(this.language)) });;
      }
    });
  }

  receiveTextValue(searchLabel: string) {
    this.searchLabel = searchLabel;
  }

  ngOnDestroy() {
    this.indicators$sub.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.exclusions !== undefined && !changes.exclusions.firstChange) {
      if(!!this.list) this.list = this.list.filter((indicator: IndicatorClass) => !this.exclusions.includes(indicator.uid.value));
    }
  }

  isSelected(uid: UID): boolean {
    return this.selected.indexOf(uid) !== -1;
  }

  receiveSelected(indicator: IndicatorClass) {
    const index = this.selected.indexOf(indicator.uid.value);
    if(index === -1) this.selected.push(indicator.uid.value);
    else this.selected.splice(index, 1);
    this.sendIndicator.emit(indicator);
  }

}
