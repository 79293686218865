/**
 * @module Dashboard
 */

import { Injectable } from '@angular/core';
import { DashboardClass } from '@class/dashboard.class';
import { Observable, BehaviorSubject } from 'rxjs';
import { DashboardModel } from '@models/dashboard.model';
import { map} from 'rxjs/operators';
import { FirestoreService } from '@services/firestore/firestore.service';
import { ResourceType } from '@models/resources.model';
import { AuthService } from '@services/auth/auth.service';
import { FirebaseService } from '@services/firebase/firebase.service';
import { UidService } from '@services/uid/uid.service';
import { ItemSymbol } from '@functions/item.functions';
import { LinksModelConstructor } from '@functions/link.functions';
import { UID } from '@models/uid.model';
import { DashboardLayoutModel } from '@models/dashboardLayout.model';
import { DashboardLayoutClass } from '@class/dashboardLayout.class';
import { LayoutDefault } from '@functions/dashboard.functions';

@Injectable({
  providedIn: 'root'
})
export class DashboardService extends FirestoreService<DashboardModel> {

  /**
   * @description type de document manipulé par le service
   * @protected
   * @type {ResourceType}
   * @memberof DashboardService
   */
  protected _itemType: ResourceType = 'dashboards';

  /**
   * @description true si l'accès à ce type de document peut nécessiter des habilitations
   * @protected
   * @type {boolean}
   * @memberof DashboardService
   */
  protected _restricted: boolean = true;

  /**
   * @description Creates an instance of DashboardService
   * @param {UidService} $uid
   * @param {AuthService} $auth
   * @param {FirebaseService} $firebase
   * @memberof DashboardService
   */
  constructor(
    private $uid: UidService,
    protected $auth: AuthService,
    protected $firebase: FirebaseService
  ) {
    super($firebase, $auth);
  }

  /**
   * @description Observateur de la liste des objects autorisés pour l'utilisateur courant
   * @returns {BehaviorSubject<DashboardClass[]>}
   * @memberof DashboardService
   */
  list$(): BehaviorSubject<DashboardClass[]> {
    return super.docs$().pipe(
      map((dashboards: DashboardModel[]) => {
        if(!!dashboards) {
          let ds = dashboards.map<DashboardClass>((dashboard: DashboardModel) => {
            return new DashboardClass(dashboard, true);
          });
          return ds;
        }
      })
    ) as BehaviorSubject<DashboardClass[]>;
  }

  /**
   * @description Observateur d'un document dont l'uid est passé en paramètre
   * @param {string} uid
   * @returns {Observable<DashboardClass>}
   * @memberof DashboardService
   */
  get$(uid: string): Observable<DashboardClass> {
    return super.doc$(uid).pipe(
      map((dashboard: DashboardModel) => {
        return new DashboardClass(dashboard, true);
      })
    );
  }

  /**
   * @description Observateur d'un document d'une sous-collection dont l'uid est passé en paramètre
   * @param {string} uid
   * @returns {Observable<DashboardClass>}
   * @memberof DashboardService
   */
  getLayout$(dashboard: DashboardClass): Observable<DashboardLayoutClass> {
    return super.docSub$(dashboard.uid.value, 'layout').pipe(
      map((layout: DashboardLayoutModel) => {
        if(layout === undefined) layout = LayoutDefault(dashboard);
        return new DashboardLayoutClass(layout);
      })
    );
  }

  /**
   * @description Créé un nouveau document avec les valeurs par défaut
   * @returns {DashboardClass}
   * @memberof DashboardService
   */
  create(): DashboardClass {
    return new DashboardClass({ 
      uid: this.$uid.create(),
      icon: {
        symbol: ItemSymbol(this._itemType),
        style: 'r',
        options: []
      },
      color: {
        hexa: '2196f3'
      },
      links: LinksModelConstructor().links,
      logs: {},
      tags: {},
      readers: {},
      writers: {},
      share: 's'
    } as DashboardModel, false);
  }

  /**
   * @description Créé ou met à jour le document passé en paramètre
   * @param {DashboardClass} dashboard
   * @returns {Promise<void>}
   * @memberof DashboardService
   */
  async set(dashboard: DashboardClass): Promise<void> {
    return super.update(dashboard.uid.value, dashboard.model);
  }

  /**
   * @description Créé ou met à jour le sous-document à partir de l'uid du document passé en paramètre
   * @param {DashboardClass} dashboard
   * @returns {Promise<void>}
   * @memberof DashboardService
   */
  async setLayout(uid: UID, dashboard: DashboardLayoutClass): Promise<void> {
    return super.updateSub(uid, dashboard.model, 'layout', false);
  }

  /**
   * @description Supprime le document passé en paramètre
   * @param {string} uid
   * @returns {Promise<void>}
   * @memberof DashboardService
   */
  async remove(uid: string): Promise<void> {
    return super.delete(uid);
  }

}
