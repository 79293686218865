<app-studio #studio [type]="'widgets'" [itemLabel]="'WIDGET'">

  <!-- BLOC MAIN -->
  <div app-studio-main-content *ngIf="widget">
    <div class="d-flex">
      <button [disabled]="saved || saving" mdbBtn type="button" color="default" class="z-depth-0 my-0 ml-0 mr-1 px-sm-4 px-3" mdbWavesEffect (click)="onReload()">
        <i class="d-sm-none far fa-redo fa-fw"></i>
        <i class="d-none d-sm-inline far fa-redo"></i>
        <span class="d-none d-sm-inline ml-1">{{ 'RESTORE' | translate }}</span>
      </button>
      <button [disabled]="saved || saving" mdbBtn type="button" [color]="color" class="z-depth-0 flex-grow-1 m-0" mdbWavesEffect (click)="onSave()">
        <i *ngIf="!saving" class="far fa-check"></i>
        <i *ngIf="saving" class="far fa-circle-notch fa-spin"></i>
        <span class="ml-1">{{ 'SAVE' | translate }}</span>
      </button>
    </div>

    <hr>

    <app-item-strip
      [labels]="widget.labels"
      [tags]="widget.tags"
      [type]="'widgets'"
      [uid]="widget.uid"
      [icon]="icon"
      [color]="color"
      [isModified]="widget.modifications$.value.isModified"
      (sendEdit)="receiveEdit($event)"
    ></app-item-strip>
    <app-widget-indicators-add #indicatorsAdd *ngIf="widgetLayers" [widgetLayers]="widgetLayers"></app-widget-indicators-add>

    <app-widget-option *ngIf="widgetLayers?.offsetDate" [categories]="['DATE_OFFSET']" [label]="'FREQUENCY'">
      <div app-widget-option-content>
        <span class="frequency app-cursor-pointer" (click)="widgetLayers.offsetDate = { amount: widgetLayers.offsetDate.amount, frequency: 'D' }" [ngClass]="{ 'green': widgetLayers.offsetDate.frequency == 'D' }">{{ 'DAY' | translate }}</span>
        <span class="frequency app-cursor-pointer" (click)="widgetLayers.offsetDate = { amount: widgetLayers.offsetDate.amount, frequency: 'W' }" [ngClass]="{ 'green': widgetLayers.offsetDate.frequency == 'W' }">{{ 'WEEK' | translate }}</span>
        <span class="frequency app-cursor-pointer" (click)="widgetLayers.offsetDate = { amount: widgetLayers.offsetDate.amount, frequency: 'M' }" [ngClass]="{ 'green': widgetLayers.offsetDate.frequency == 'M' }">{{ 'MONTH' | translate }}</span>
        <span class="frequency app-cursor-pointer" (click)="widgetLayers.offsetDate = { amount: widgetLayers.offsetDate.amount, frequency: 'Q' }" [ngClass]="{ 'green': widgetLayers.offsetDate.frequency == 'Q' }">{{ 'QUARTER' | translate }}</span>
        <span class="frequency app-cursor-pointer" (click)="widgetLayers.offsetDate = { amount: widgetLayers.offsetDate.amount, frequency: 'Y' }" [ngClass]="{ 'green': widgetLayers.offsetDate.frequency == 'Y' }">{{ 'YEAR' | translate }}</span>
      </div>
    </app-widget-option>

    <app-widget-option *ngIf="widgetLayers?.offsetDate" [categories]="['DATE_OFFSET']" [label]="'AMOUNT'">
      <div app-widget-option-content>
        <app-number [value]="widgetLayers.offsetDate.amount" [min]="0" (sendValue)="widgetLayers.offsetDate = { amount: $event, frequency: widgetLayers.offsetDate.frequency }"></app-number>
      </div>
    </app-widget-option>

    <app-widget-option *ngIf="widgetLayers?.offsetDate" [categories]="['DATE_OFFSET']" [label]="'DEFAULT_VALUE'">
      <div app-widget-option-content>
        <button [disabled]="widgetLayers.isOffsetDateDefault()" mdbBtn (click)="widgetLayers.eraseOffsetDate()" type="button" color="light" size="sm" class="px-2 z-depth-0 m-0 {{ color }}-text" mdbWavesEffect>
          {{ 'USE' | translate }}
        </button>
      </div>
    </app-widget-option>

    <div class="alert alert-warning animated slideInLeft mt-3" role="alert" *ngIf="alertTheme && !saved">
      <div class="font-very-small">
        {{ 'NEW_THEME_AVAILABLE/TEXT' | translate }}
      </div>
      <div>
        <button (click)="loadLayers()" type="button" mdbBtn color="warning" size="sm" outline="true" class="z-depth-0 mr-auto mb-1 mt-3 ml-0" mdbWavesEffect>{{ 'LOAD_THEME' | translate }}</button>
        <button (click)="onSave(); loadLayers();" type="button" mdbBtn color="warning" size="sm" class="z-depth-0 my-0 ml-0 mr-auto" mdbWavesEffect>{{ 'SAVE_AND_LOAD_THEME' | translate }}</button>
      </div>
    </div>

    <app-widget-studio-main *ngIf="widgetLayers && themeOptions && widgetLayers.indicators.length > 0" 
      [widgetLayers]="widgetLayers" 
      (sendCurrentIndicator)="receiveCurrentIndicator($event)" 
      (sendCurrentLayer)="receiveCurrentLayer($event)" 
      [currentIndicator]="currentIndicator" 
    ></app-widget-studio-main>
  </div>
  <!-- ./BLOC MAIN -->

  <!-- BLOC ITEM -->
  <div app-studio-item-content class="d-block" style="height: 100%;" *ngIf="themeOptions && widgetLayers && widget && widgetLayers?.indicators?.length > 0; else app_empty">
    <div class="app-widget-studio-chart mx-auto"><app-widget-studio-chart [widgetLayers]="widgetLayers"></app-widget-studio-chart></div>
  </div>
  <!-- ./BLOC ITEM -->

  <!-- BLOC SUB -->
  <div app-studio-sub-content>
    <app-widget-studio-sub *ngIf="widgetLayers && themeOptions && widgetLayers.indicators.length > 0" 
      [currentIndicator]="currentIndicator" 
      [currentLayer]="currentLayer" 
      [type]="type" 
      [themeOptions]="themeOptions" 
      [widgetLayers]="widgetLayers"
      (sendDeleteIndicator)="receiveDeleteIndicator()"
    ></app-widget-studio-sub>
  </div>
  <!-- ./BLOC SUB -->

</app-studio>

<ng-template #app_empty>
  <div *ngIf="widgetLayers && widgetLayers.indicators.length == 0" class="app-widget-studio-chart"><app-empty (sendAction)="receiveAction()" [type]="'widgets'" [actionText]="'ADD'" [actionDescription]="'NO_INDICATOR_SELECTED/TEXT'"></app-empty></div>
  <div *ngIf="!widgetLayers" class="app-widget-studio-chart"><app-loading></app-loading></div>
</ng-template>

<app-widget-editor #editor [current]="widget" (sendDelete)="receiveDelete()"></app-widget-editor>