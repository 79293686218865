/**
 * @module Form
 */

import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css']
})
export class SliderComponent {

  @Input() min: number = 0;
  @Input() max: number = 100;
  @Input() value: number = 0;
  @Input() step: number = 1;
  @Input() color: string;
  @Output() sendValue: EventEmitter<number>;

  promise: any;

  constructor() {
    this.sendValue = new EventEmitter<number>();
  }

  onValue(value: number) {
    this.value = value;
    if(this.promise !== undefined) clearTimeout(this.promise);
    this.promise = setTimeout(() => this.sendValue.emit(this.value), 1000);
  }

}