/**
 * @module Tag
 */

import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { TagClass } from '@class/tag.class';
import { UID } from '@models/uid.model';
import { Subscription } from 'rxjs';
import { TagService } from '@services/tag/tag.service';

@Component({
  selector: 'app-tags-links-item',
  templateUrl: './tags-links-item.component.html',
  styleUrls: ['./tags-links-item.component.css']
})
export class TagsLinksItemComponent implements OnInit, OnDestroy {

  @Input() tag: TagClass;
  @Input() isSelected: boolean;
  @Input() uid: UID; //si 'tag' pas renseigné

  @Output() sendSelected: EventEmitter<UID>;

  tag$sub: Subscription;

  constructor(
    private $tags: TagService
  ) { 
    this.sendSelected = new EventEmitter<UID>();
  }

  ngOnInit() {
    if(this.tag === undefined && this.uid !== undefined) {
      this.tag$sub = this.$tags.getPartial$(this.uid).subscribe({
        next: (tag: TagClass) => {
          this.tag = tag;
        }
      });
    }
  }

  ngOnDestroy() {
    if(this.tag$sub) this.tag$sub.unsubscribe();
  }

  receiveSelected() {
    this.sendSelected.emit(this.tag.uid.value);
  }

}
