/**
 * @module Server
 */

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ServerClass } from '@class/server.class';
import { UID } from '@models/uid.model';
import { ItemColor, ItemIcon } from '@functions/item.functions';

@Component({
  selector: 'app-servers-links-item',
  templateUrl: './servers-links-item.component.html',
  styleUrls: ['./servers-links-item.component.css']
})
export class ServersLinksItemComponent {

  @Input() server: ServerClass;
  @Input() isSelected: boolean;

  @Output() sendSelected: EventEmitter<UID>;

  color = ItemColor('servers');
  icon = ItemIcon('servers');

  constructor() { 
    this.sendSelected = new EventEmitter<UID>();
  }

  receiveSelected() {
    this.sendSelected.emit(this.server.uid.value);
  }

}
