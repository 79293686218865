/**
 * @module Widget
 */

import { Component, Input } from '@angular/core';
import { PivotFiltersClass } from '@class/widgetFilters.class';

@Component({
  selector: 'app-widget-indicators-filter',
  templateUrl: './widget-indicators-filter.component.html',
  styleUrls: ['./widget-indicators-filter.component.css']
})
export class WidgetIndicatorsFilterComponent {

  @Input() filters: PivotFiltersClass;
  @Input() include: boolean;
  @Input() value: string;

  constructor() {}

}
