/**
 * @module Indicator
 */

import { Component, OnDestroy, ViewChild } from '@angular/core';
import { IndicatorEditorComponent } from '@components/indicators/indicator-editor/indicator-editor.component';
import { ItemColor, ItemIcon } from '@functions/item.functions';
import { IndicatorClass } from '@class/indicator.class';
import { Subscription } from 'rxjs';
import { LanguageType } from '@models/language.model';
import { UID } from '@models/uid.model';
import { Router } from '@angular/router';
import { IndicatorService } from '@services/indicator/indicator.service';
import { AuthService } from '@services/auth/auth.service';
import { IndicatorQueryService } from '@providers/indicatorQuery/indicator-query.service';
import { Total } from '@functions/math.functions';
import { PivotFiltersClass } from '@class/widgetFilters.class';
import { ModificationStateClass } from '@class/modification.class';
import { PivotService } from '@services/pivot/pivot.service';
import { PivotClass } from '@class/pivot.class';
import { take } from 'rxjs/operators';
import { DateClass } from '@class/date.class';
import { WidgetDatesBoundsClass } from '@class/widgetDatesBounds.class';
import { DateService } from '@services/date/date.service';
import { DateBoundsDefault } from '@functions/widget.functions';
import { ServerResponseModel } from '@models/server.model';
import { MessagesService } from '@services/messages/messages.service';

@Component({
  selector: 'app-indicator-studio',
  templateUrl: './indicator-studio.component.html',
  styleUrls: ['./indicator-studio.component.css'],
  providers: [IndicatorQueryService]
})
export class IndicatorStudioComponent implements OnDestroy {

  @ViewChild('editor') editor: IndicatorEditorComponent;

  color = ItemColor('indicators');
  icon = ItemIcon('indicators');

  filters: PivotFiltersClass[];
  bounds: WidgetDatesBoundsClass[];
  indicator: IndicatorClass;
  indicator$sub: Subscription;
  elements$sub: Subscription;
  modifications$sub: Subscription[];
  language: LanguageType;
  language$sub: Subscription;
  uid: UID;
  panel: string;
  from: string;

  total: number;
  count: number;
  current: UID;
  deleting: boolean;
  exporting: boolean;
  showDelete: boolean;

  pivots: PivotClass[];
  dates: DateClass[];
  byKey: { [key in UID]: DateClass | PivotClass };

  elements: { 
    value: number,
    count: number,
    groups: { [key: string]: string }
  }[];
  uids: UID[];

  constructor(
    private $router: Router,
    private $indicator: IndicatorService,
    private $query: IndicatorQueryService,
    private $pivots: PivotService,
    private $dates: DateService,
    private $auth: AuthService,
    private $messages: MessagesService
  ) { 
    this.uid = this.$router.parseUrl(this.$router.url).queryParams.uid;
    this.panel = this.$router.parseUrl(this.$router.url).queryParams.panel;
    this.from = this.$router.parseUrl(this.$router.url).queryParams.from;

    this.language$sub = this.$auth.language$.subscribe({
      next: (language: LanguageType) => {
        this.language = language;
      }
    });

    this.uids = [];
    this.modifications$sub = [];
    this.deleting = false;
    this.count = 0;
    this.showDelete = false;

    if(!this.uid) this.onBack();
    else {
      this.indicator$sub = this.$indicator.get$(this.uid).subscribe({
        next:(indicator: IndicatorClass) => {
          if(!!indicator) {
            this.indicator = indicator;

            this.modifications$sub.forEach(m => m.unsubscribe());
            this.modifications$sub = [];
            this.byKey = {};

            let uidDates = this.indicator.links.list('dates').slice();
            this.bounds = [],
            this.dates = [],
            uidDates.forEach((uid: UID) => {
              this.$dates.getPartial$(uid).pipe(take(1)).subscribe((date: DateClass) => {
                this.dates.push(date);
                this.byKey[uid] = date;
                const index = this.bounds.push(new WidgetDatesBoundsClass(DateBoundsDefault(uid), date.modifications$.value, date.modifications$, date.lastModification$)) - 1;
                this.modifications$sub.push(this.bounds[index].modifications$.subscribe((modifications: ModificationStateClass) => {
                  if(modifications.isModified) this.$query.update(this.uids, this.filters, this.bounds);
                }));
              });
            });

            let uidPivots = this.indicator.links.list('pivots').slice();
            this.filters = [],
            this.pivots = [],
            uidPivots.forEach((uid: UID) => {
              this.$pivots.getPartial$(uid).pipe(take(1)).subscribe((pivot: PivotClass) => {
                this.pivots.push(pivot);
                /*this.pivots.sort((a, b) => {
                  if(a.labels.value(this.language) < b.labels.value(this.language)) { return -1; }
                  if(a.labels.value(this.language) > b.labels.value(this.language)) { return 1; }
                  return 0;
                });*/
                this.byKey[uid] = pivot;
                const index = this.filters.push(new PivotFiltersClass({ uid: uid, include: true, values: [] }, pivot.modifications$.value, pivot.modifications$, pivot.lastModification$)) - 1;
                this.modifications$sub.push(this.filters[index].modifications$.subscribe((modifications: ModificationStateClass) => {
                  if(modifications.isModified) this.$query.update(this.uids, this.filters, this.bounds);
                }));
              });
            });

            this.uids = [];
            if(this.elements$sub) this.elements$sub.unsubscribe();
            this.elements$sub = this.$query.queryResult$(this.indicator).subscribe((elements: any[]) => {
              this.elements = elements;
              this.total = Total(this.elements.map(e => e.value)) || 0;
              this.count = Total(this.elements.map(e => e.count)) || 0;
            });
            this.$query.update(this.uids, this.filters, this.bounds);
          }
        }
      });
    }
  }

  onExport() {
    this.exporting = true;

    const rows = [];

    if(this.elements.length > 0) {
      const header = [this.indicator.labels.value(this.language)];

      const pivotsAndDates = {};

      this.pivots.forEach(p => {
        pivotsAndDates[p.uid.value] = p.labels.value(this.language);
      });

      this.dates.forEach(d => {
        pivotsAndDates[d.uid.value] = d.labels.value(this.language);
      });

      for(const group in this.elements[0].groups) {
        header.push(pivotsAndDates[group]);
      }
      rows.push(header);
    }

    for(const element of this.elements) {
      const row = [];
      row.push(element.value);
      for(const group in element.groups) {
        row.push(element.groups[group]);
      }
      rows.push(row);
    }
  
    let csvContent = 'data:text/csv;charset=utf-8,' 
      + rows.map(e => e.join(',')).join('\n');

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `export_${this.indicator.labels.value(this.language)}_${(new Date()).toISOString()}.csv`);
    document.body.appendChild(link); // Required for FF

    link.click();
    this.exporting = false;
  }

  onDelete() {
    this.showDelete = !this.showDelete;
  }

  receiveSelectedPivot(uid: UID) {
    this.elements = undefined;

    const index = this.uids.indexOf(uid);
    if(index === - 1) this.uids.push(uid);
    else this.uids.splice(index, 1);
    
    this.$query.update(this.uids, this.filters, this.bounds);
  }

  ngOnDestroy() {
    this.language$sub.unsubscribe();
    if(this.elements$sub) this.elements$sub.unsubscribe();
    if(this.indicator$sub) this.indicator$sub.unsubscribe();
    if(this.modifications$sub) this.modifications$sub.forEach(m => m.unsubscribe());
  }

  receiveSelectedFilter(uid: UID) {
    this.current = uid;
  }

  receiveFlush() {
    this.showDelete = false;
    this.deleting = true;
    this.$query.delete(this.filters, this.bounds).then((response: ServerResponseModel) => {
      this.deleting = false;
      if(response.success) {
        this.$messages.success({ 
          title: { plain: this.indicator.labels.value(this.language) }, 
          text: { code: 'LINES_SUCCESSFULLY_DELETED/TEXT' } 
        });
      }
      else {
        this.$messages.error({ 
          title: { plain: this.indicator.labels.value(this.language) }, 
          text: { 
            code: 'LINES_DELETION_FAILED/TEXT'
          } 
        });
      }
      this.$query.update(this.uids, this.filters, this.bounds);
    });
  }

  receiveDelete() {
    this.onBack();
  }

  onBack() {
    this.$router.navigate([`/${this.from}`], { queryParams: this.panel !== undefined ? { panel: this.panel } : {} });
  }

  receiveEdit() {
    this.editor.show();
  }

}