/**
 * @module Constructor
 */

import { Component, OnInit } from '@angular/core';
import { ENVIRONMENT } from '@consts/params.const';
import { FirebaseService } from '@services/firebase/firebase.service';
import { MessagesService } from '@services/messages/messages.service';
import { CompanyService } from '@services/company/company.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-constructor',
  templateUrl: './constructor.component.html',
  styleUrls: ['./constructor.component.css']
})
export class ConstructorComponent implements OnInit {

  data: {
    serverUrl: string;
    email: string;
    avatarUrl: string;
    avatarPath: string;
  }

  isWaiting: boolean;
  company: string;

  constructor(
    private $firebase: FirebaseService,
    private $messages: MessagesService,
    private $company: CompanyService,
    private $route: Router
  ) {
    this.isWaiting = false;
    this.company = this.$company.label;
    this.data = {
      serverUrl: `backend-${this.$company.infos.subdomain}.swapptechs.com`,
      email: 'support@swapplabs.com',
      avatarUrl: this.$company.infos.avatar.url,
      avatarPath: this.$company.infos.avatar.path
    }
    console.groupCollapsed(`Constructor ready`);
    console.log(this.data);
    console.groupEnd();
  }

  ngOnInit() {}

  onApply() {
    this.isWaiting = true;
    this.$firebase.angularFireFunctions.httpsCallable('constructor')(this.data).toPromise()
    .then((result) => {
      if(ENVIRONMENT.type !== 'production') {
        console.groupCollapsed(`Constructor success`);
        console.log(result);
        console.groupEnd();
      }
      this.isWaiting = false;
      this.$messages.success({ text: { code: 'SUCCESS' }});
      this.$route.navigate(['/']);
    }).catch((error: any) => {
      this.isWaiting = false;
      if(ENVIRONMENT.type !== 'production') {
        console.groupCollapsed(`Constructor error`);
        console.log(error);
        console.groupEnd();
      }
      this.$messages.error({ text: { code: 'ERROR' }});
    });
  }

}
