import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-table-chart',
  templateUrl: './table-chart.component.html',
  styleUrls: ['./table-chart.component.css']
})
export class TableChartComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
