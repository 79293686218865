/**
 * @module Shared
 */

export function GetPropertyValue(object: { [key: string]: any }, path: string[]): any {
  let value = path
    .reduce(function(o, k) {
      return o && o[k]; // get inner property if `o` is defined else get `o` and return
    }, object) // set initial value as object
  return value === undefined ? null : value;
}

export function DeletePropertyValue(object: { [key: string]: any }, path: string[]) {
  path.reduce((acc, key, index) => {
    if (index === path.length - 1) {
      delete acc[key];
      return true;
    }
    return acc[key];
  }, object);
};