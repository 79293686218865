/**
 * @module Root
 */

import { MDBBootstrapModulesPro, MDBSpinningPreloader, ToastService, ToastModule } from 'ng-uikit-pro-standard';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ChartsModule } from 'ng2-charts';
import { NgxPaginationModule } from 'ngx-pagination';
import { ResizableModule } from 'angular-resizable-element';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import 'simplebar';

/** Languages **/
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeEs from '@angular/common/locales/es';

registerLocaleData(localeFr);
registerLocaleData(localeEs);

/** Directives */
import { DropZoneDirective } from '@directives/drop-zone/drop-zone.directive';
import { GridLayoutDirective } from '@directives/gridLayout/grid-layout.directive';
import { GridSpanDirective } from '@directives/gridSpan/grid-span.directive';
import { SortableDirective } from '@directives/sortable/sortable.directive';
import { ThemeOptionsDirective } from '@directives/themeOptions/theme-options.directive';

/** Charts */
import { LineChartComponent } from '@charts/line-chart/line-chart.component';
import { BarChartComponent } from '@charts/bar-chart/bar-chart.component';
import { RadarChartComponent } from '@charts/radar-chart/radar-chart.component';
import { PieChartComponent } from '@charts/pie-chart/pie-chart.component';
import { PolarAreaChartComponent } from '@charts/polar-area-chart/polar-area-chart.component';
import { DoughnutChartComponent } from '@charts/doughnut-chart/doughnut-chart.component';
import { BubbleChartComponent } from '@charts/bubble-chart/bubble-chart.component';
import { ScatterChartComponent } from '@charts/scatter-chart/scatter-chart.component';
import { StackedBarChartComponent } from '@charts/stacked-bar-chart/stacked-bar-chart.component';
import { StackedHorizontalBarChartComponent } from '@charts/stacked-horizontal-bar-chart/stacked-horizontal-bar-chart.component';
import { StackedLineChartComponent } from '@charts/stacked-line-chart/stacked-line-chart.component';
import { TableChartComponent } from '@charts/table-chart/table-chart.component';
import { StickerChartComponent } from '@charts/sticker-chart/sticker-chart.component';
import { GaugeChartComponent } from '@charts/gauge-chart/gauge-chart.component';

/** Services */
import { AuthService } from '@services/auth/auth.service';
import { CompanyService } from '@services/company/company.service';
import { CompressImageService } from '@services/compressImage/compress-image.service';
import { DashboardService } from '@services/dashboard/dashboard.service';
import { DateService } from '@services/date/date.service';
import { FirebaseService } from '@services/firebase/firebase.service';
import { ImportService } from '@services/import/import.service';
import { IndicatorService } from '@services/indicator/indicator.service';
import { MessagesService } from '@services/messages/messages.service';
import { MetasService } from '@services/metas/metas.service';
import { PivotService } from '@services/pivot/pivot.service';
import { PresenceService } from '@services/presence/presence.service';
import { RightService } from '@services/right/right.service';
import { ServerService } from '@services/server/server.service';
import { SignoutService } from '@services/signout/signout.service';
import { TagService } from '@services/tag/tag.service';
import { ThemeService } from '@services/theme/theme.service';
import { ThemeStudioService } from '@services/themeStudio/theme-studio.service';
import { TokenService } from '@services/token/token.service';
import { UidService } from '@services/uid/uid.service';
import { UserService } from '@services/user/user.service';
import { WidgetService } from '@services/widget/widget.service';
import { WidgetStudioService } from '@services/widgetStudio/widget-studio.service';
import { DashboardStudioService } from '@services/dashboardStudio/dashboard-studio.service';
import { BackendCollectionsService } from '@services/backend/backendCollections/backend-collections.service';
import { BackendDocumentsService } from '@services/backend/backendDocuments/backend-documents.service';
import { BackendGroupbyService } from '@services/backend/backendGroupby/backend-groupby.service';
import { BackendIndexesService } from '@services/backend/backendIndexes/backend-indexes.service';
import { BackendValuesService } from '@services/backend/backendValues/backend-values.service';
import { BackendPivotsService } from '@services/backend/backendPivots/backend-pivots.service';
import { DashboardDisplayService } from '@services/dashboardDisplay/dashboard-display.service';
import { ColorsService } from '@services/colors/colors.service';

/** Pipes */
import { FiltersPipe } from '@pipes/filters/filters.pipe';
import { CapitalizeFirstPipe } from '@pipes/capitalizeFirst/capitalize-first.pipe';
import { ChartPipe } from '@pipes/chart/chart.pipe';
import { SearchTextPipe } from '@pipes/searchText/search-text.pipe';
import { OrderByPipe } from '@pipes/orderBy/order-by.pipe';
import { BookmarksPipe } from '@pipes/bookmarks/bookmarks.pipe';
import { TagsPipe } from '@pipes/tags/tags.pipe';
import { SearchUserPipe } from '@pipes/searchUser/search-user.pipe';
import { SearchLabelsPipe } from '@pipes/searchLabels/search-labels.pipe';

/** Components */
import { CompanyComponent } from '@components/company/company.component';
import { BrowserComponent } from '@components/browser/browser/browser.component';
import { AuthComponent } from '@components/auth/auth.component';
import { IconsComponent } from '@components/icons/icons.component';
import { ColorsComponent } from '@components/colors/colors.component';
import { BrowserNavbarComponent } from '@components/browser/browser-navbar/browser-navbar.component';
import { VersionComponent } from '@components/version/version.component';
import { BrowserNavbarPanelsComponent } from '@components/browser/browser-navbar-panels/browser-navbar-panels.component';
import { PresencesComponent } from '@components/presences/presences.component';
import { EditorComponent } from '@components/forms/editor/editor.component';
import { DashboardsComponent } from '@components/dashboards/dashboards/dashboards.component';
import { LabelsComponent } from '@components/forms/labels/labels.component';
import { DescriptionsComponent } from '@components/forms/descriptions/descriptions.component';
import { EditorSectionTitleComponent } from '@components/forms/editor-section-title/editor-section-title.component';
import { RightsComponent } from '@components/rights/rights/rights.component';
import { BrowserPanelComponent } from '@components/browser/browser-panel/browser-panel.component';
import { DeleteComponent } from '@components/forms/delete/delete.component';
import { RightsLinksListComponent } from '@components/rights/rights-links-list/rights-links-list.component';
import { RightsLinksItemComponent } from '@components/rights/rights-links-item/rights-links-item.component';
import { LinksItemComponent } from '@components/forms/links-item/links-item.component';
import { LinksItemOptionComponent } from '@components/forms/links-item-option/links-item-option.component';
import { ItemTileComponent } from '@components/items/item-tile/item-tile.component';
import { BrowserFrameComponent } from '@components/browser/browser-frame/browser-frame.component';
import { ItemStripComponent } from '@components/items/item-strip/item-strip.component';
import { UsersComponent } from '@components/users/users/users.component';
import { FirstnameComponent } from '@components/users/firstname/firstname.component';
import { LastnameComponent } from '@components/users/lastname/lastname.component';
import { EmailComponent } from '@components/users/email/email.component';
import { RolesComponent } from '@components/users/roles/roles.component';
import { UserIdComponent } from '@components/users/user-id/user-id.component';
import { LanguageComponent } from '@components/users/language/language.component';
import { ActivationComponent } from '@components/users/activation/activation.component';
import { FormStripComponent } from '@components/forms/form-strip/form-strip.component';
import { EmailResetComponent } from '@components/users/email-reset/email-reset.component';
import { RightsSwitchFullComponent } from '@components/rights/rights-switch-full/rights-switch-full.component';
import { TagsComponent } from '@components/tags/tags/tags.component';
import { TagsLinksListComponent } from '@components/tags/tags-links-list/tags-links-list.component';
import { TagsLinksItemComponent } from '@components/tags/tags-links-item/tags-links-item.component';
import { TagsIconsListComponent } from '@components/tags/tags-icons-list/tags-icons-list.component';
import { TagsIconsItemComponent } from '@components/tags/tags-icons-item/tags-icons-item.component';
import { GroupsComponent } from '@components/users/groups/groups.component';
import { ProfilComponent } from '@components/users/profil/profil.component';
import { AvatarComponent } from '@components/users/avatar/avatar.component';
import { IndicatorsComponent } from '@components/indicators/indicators/indicators.component';
import { PivotsComponent } from '@components/pivots/pivots/pivots.component';
import { PivotsLinksListComponent } from '@components/pivots/pivots-links-list/pivots-links-list.component';
import { PivotsLinksItemComponent } from '@components/pivots/pivots-links-item/pivots-links-item.component';
import { LinkedColumnsComponent } from '@components/linked-columns/linked-columns.component';
import { NotDeletableComponent } from '@components/forms/not-deletable/not-deletable.component';
import { DatesComponent } from '@components/dates/dates/dates.component';
import { DateFormatsComponent } from '@components/date-formats/date-formats.component';
import { DatesLinksListComponent } from '@components/dates/dates-links-list/dates-links-list.component';
import { DatesLinksItemComponent } from '@components/dates/dates-links-item/dates-links-item.component';
import { ServersComponent } from '@components/servers/servers/servers.component';
import { UrlComponent } from '@components/servers/url/url.component';
import { MetasComponent } from '@components/servers/metas/metas.component';
import { LoadingComponent } from '@components/loading/loading.component';
import { MainComponent } from '@components/servers/main/main.component';
import { ServersLinksListComponent } from '@components/servers/servers-links-list/servers-links-list.component';
import { ServersLinksItemComponent } from '@components/servers/servers-links-item/servers-links-item.component';
import { EmptyComponent } from '@components/empty/empty.component';
import { ImportScopeComponent } from '@components/import/import-studio/import-scope/import-scope.component';
import { EmptyPanelComponent } from '@components/empty-panel/empty-panel.component';
import { ItemInfoComponent } from '@components/items/item-info/item-info.component';
import { ImportCellComponent } from '@components/import/import-studio/import-cell/import-cell.component';
import { ImportDetectValueComponent } from '@components/import/import-studio/import-detect-value/import-detect-value.component';
import { FiltersComponent } from '@components/filters/filters.component';
import { IndicatorEditorComponent } from '@components/indicators/indicator-editor/indicator-editor.component';
import { PivotEditorComponent } from '@components/pivots/pivot-editor/pivot-editor.component';
import { DateEditorComponent } from '@components/dates/date-editor/date-editor.component';
import { ImportTagsComponent } from '@components/import/import-studio/import-tags/import-tags.component';
import { DownloadFileTypeComponent } from '@components/download-file-type/download-file-type.component';
import { ImportUploadingComponent } from '@components/import/import-studio/import-uploading/import-uploading.component';
import { ImportProgressComponent } from '@components/import/import-studio/import-progress/import-progress.component';
import { ThemesComponent } from '@components/themes/themes/themes.component';
import { PivotsLinksChangeComponent } from '@components/pivots/pivots-links-change/pivots-links-change.component';
import { ImportLinksChangeComponent } from '@components/import/import-studio/import-links-change/import-links-change.component';
import { DatesLinksChangeComponent } from '@components/dates/dates-links-change/dates-links-change.component';
import { IndicatorsLinksChangeComponent } from '@components/indicators/indicators-links-change/indicators-links-change.component';
import { ThemeStudioComponent } from '@components/themes/theme-studio/theme-studio/theme-studio.component';
import { ThemeOptionsComponent } from '@components/themes/theme-studio/options/theme-options/theme-options.component';
import { ThemeEditorComponent } from '@components/themes/theme-editor/theme-editor.component';
import { NumberComponent } from '@components/forms/number/number.component';
import { ThemeOptionAnimationEasingComponent } from '@components/themes/theme-studio/options/animation/theme-option-animation-easing/theme-option-animation-easing.component';
import { ThemeOptionComponent } from '@components/themes/theme-studio/options/theme-option/theme-option.component';
import { ThemeOptionAnimationDurationComponent } from '@components/themes/theme-studio/options/animation/theme-option-animation-duration/theme-option-animation-duration.component';
import { ThemeOptionLegendDisplayComponent } from '@components/themes/theme-studio/options/legend/theme-option-legend-display/theme-option-legend-display.component';
import { ThemeOptionLegendPositionComponent } from '@components/themes/theme-studio/options/legend/theme-option-legend-position/theme-option-legend-position.component';
import { ThemeOptionLegendFullWidthComponent } from '@components/themes/theme-studio/options/legend/theme-option-legend-full-width/theme-option-legend-full-width.component';
import { ThemeOptionLegendReverseComponent } from '@components/themes/theme-studio/options/legend/theme-option-legend-reverse/theme-option-legend-reverse.component';
import { CheckComponent } from '@components/forms/check/check.component';
import { ThemeOptionLegendFontSizeComponent } from '@components/themes/theme-studio/options/legend/theme-option-legend-font-size/theme-option-legend-font-size.component';
import { ThemeOptionFontSizeComponent } from '@components/themes/theme-studio/options/shared/theme-option-font-size/theme-option-font-size.component';
import { ThemeOptionFontStyleComponent } from '@components/themes/theme-studio/options/shared/theme-option-font-style/theme-option-font-style.component';
import { ThemeOptionLegendFontStyleComponent } from '@components/themes/theme-studio/options/legend/theme-option-legend-font-style/theme-option-legend-font-style.component';
import { ThemeOptionLegendFontColorComponent } from '@components/themes/theme-studio/options/legend/theme-option-legend-font-color/theme-option-legend-font-color.component';
import { ColorPickerComponent } from '@components/forms/color-picker/color-picker.component';
import { ThemeOptionLegendBoxWidthComponent } from '@components/themes/theme-studio/options/legend/theme-option-legend-box-width/theme-option-legend-box-width.component';
import { ThemeOptionLegendPaddingComponent } from '@components/themes/theme-studio/options/legend/theme-option-legend-padding/theme-option-legend-padding.component';
import { ThemeOptionLegendUsePointStyleComponent } from '@components/themes/theme-studio/options/legend/theme-option-legend-use-point-style/theme-option-legend-use-point-style.component';
import { ThemeOptionLegendFontFamilyComponent } from '@components/themes/theme-studio/options/legend/theme-option-legend-font-family/theme-option-legend-font-family.component';
import { ThemeOptionLayoutPaddingComponent } from '@components/themes/theme-studio/options/layout/theme-option-layout-padding/theme-option-layout-padding.component';
import { ThemeOptionFontFamilyComponent } from '@components/themes/theme-studio/options/shared/theme-option-font-family/theme-option-font-family.component';
import { ThemeOptionTitleDisplayComponent } from '@components/themes/theme-studio/options/title/theme-option-title-display/theme-option-title-display.component';
import { ThemeOptionTitlePositionComponent } from '@components/themes/theme-studio/options/title/theme-option-title-position/theme-option-title-position.component';
import { ThemeOptionTitleFontSizeComponent } from '@components/themes/theme-studio/options/title/theme-option-title-font-size/theme-option-title-font-size.component';
import { ThemeOptionTitleFontStyleComponent } from '@components/themes/theme-studio/options/title/theme-option-title-font-style/theme-option-title-font-style.component';
import { ThemeOptionTitleFontColorComponent } from '@components/themes/theme-studio/options/title/theme-option-title-font-color/theme-option-title-font-color.component';
import { ThemeOptionTitlePaddingComponent } from '@components/themes/theme-studio/options/title/theme-option-title-padding/theme-option-title-padding.component';
import { ThemeOptionTitleFontFamilyComponent } from '@components/themes/theme-studio/options/title/theme-option-title-font-family/theme-option-title-font-family.component';
import { ThemeOptionAnimationAnimateRotateComponent } from '@components/themes/theme-studio/options/animation/theme-option-animation-animate-rotate/theme-option-animation-animate-rotate.component';
import { ThemeOptionAnimationAnimateScaleComponent } from '@components/themes/theme-studio/options/animation/theme-option-animation-animate-scale/theme-option-animation-animate-scale.component';
import { ThemeOptionTooltipsModeComponent } from '@components/themes/theme-studio/options/tooltips/theme-option-tooltips-mode/theme-option-tooltips-mode.component';
import { ThemeOptionTooltipsDisplayComponent } from '@components/themes/theme-studio/options/tooltips/theme-option-tooltips-display/theme-option-tooltips-display.component';
import { ThemeOptionTooltipsIntersectComponent } from '@components/themes/theme-studio/options/tooltips/theme-option-tooltips-intersect/theme-option-tooltips-intersect.component';
import { ThemeOptionTooltipsPositionComponent } from '@components/themes/theme-studio/options/tooltips/theme-option-tooltips-position/theme-option-tooltips-position.component';
import { ThemeOptionTooltipsBackgroundColorComponent } from '@components/themes/theme-studio/options/tooltips/theme-option-tooltips-background-color/theme-option-tooltips-background-color.component';
import { SliderComponent } from '@components/forms/slider/slider.component';
import { ThemeOptionTooltipsTitleAlignComponent } from '@components/themes/theme-studio/options/tooltips/theme-option-tooltips-title-align/theme-option-tooltips-title-align.component';
import { ThemeOptionTitleLineHeightComponent } from '@components/themes/theme-studio/options/title/theme-option-title-line-height/theme-option-title-line-height.component';
import { ThemeOptionTooltipsTitleFontColorComponent } from '@components/themes/theme-studio/options/tooltips/theme-option-tooltips-title-font-color/theme-option-tooltips-title-font-color.component';
import { ThemeOptionTooltipsTitleFontStyleComponent } from '@components/themes/theme-studio/options/tooltips/theme-option-tooltips-title-font-style/theme-option-tooltips-title-font-style.component';
import { ThemeOptionTooltipsTitleFontSizeComponent } from '@components/themes/theme-studio/options/tooltips/theme-option-tooltips-title-font-size/theme-option-tooltips-title-font-size.component';
import { ThemeOptionTooltipsTitleFontFamilyComponent } from '@components/themes/theme-studio/options/tooltips/theme-option-tooltips-title-font-family/theme-option-tooltips-title-font-family.component';
import { ThemeOptionTooltipsTitleSpacingComponent } from '@components/themes/theme-studio/options/tooltips/theme-option-tooltips-title-spacing/theme-option-tooltips-title-spacing.component';
import { ThemeOptionTooltipsTitleMarginBottomComponent } from '@components/themes/theme-studio/options/tooltips/theme-option-tooltips-title-margin-bottom/theme-option-tooltips-title-margin-bottom.component';
import { ThemeOptionTooltipsBodyFontColorComponent } from '@components/themes/theme-studio/options/tooltips/theme-option-tooltips-body-font-color/theme-option-tooltips-body-font-color.component';
import { ThemeOptionTooltipsBodyFontStyleComponent } from '@components/themes/theme-studio/options/tooltips/theme-option-tooltips-body-font-style/theme-option-tooltips-body-font-style.component';
import { ThemeOptionTooltipsBodyFontSizeComponent } from '@components/themes/theme-studio/options/tooltips/theme-option-tooltips-body-font-size/theme-option-tooltips-body-font-size.component';
import { ThemeOptionTooltipsBodyFontFamilyComponent } from '@components/themes/theme-studio/options/tooltips/theme-option-tooltips-body-font-family/theme-option-tooltips-body-font-family.component';
import { ThemeOptionTooltipsBodyAlignComponent } from '@components/themes/theme-studio/options/tooltips/theme-option-tooltips-body-align/theme-option-tooltips-body-align.component';
import { ThemeOptionTooltipsBodySpacingComponent } from '@components/themes/theme-studio/options/tooltips/theme-option-tooltips-body-spacing/theme-option-tooltips-body-spacing.component';
import { ThemeOptionTooltipsXPaddingComponent } from '@components/themes/theme-studio/options/tooltips/theme-option-tooltips-xpadding/theme-option-tooltips-xpadding.component';
import { ThemeOptionTooltipsYPaddingComponent } from '@components/themes/theme-studio/options/tooltips/theme-option-tooltips-ypadding/theme-option-tooltips-ypadding.component';
import { ThemeOptionTooltipsCaretPaddingComponent } from '@components/themes/theme-studio/options/tooltips/theme-option-tooltips-caret-padding/theme-option-tooltips-caret-padding.component';
import { ThemeOptionTooltipsCaretSizeComponent } from '@components/themes/theme-studio/options/tooltips/theme-option-tooltips-caret-size/theme-option-tooltips-caret-size.component';
import { ThemeOptionTooltipsCornerRadiusComponent } from '@components/themes/theme-studio/options/tooltips/theme-option-tooltips-corner-radius/theme-option-tooltips-corner-radius.component';
import { ThemeOptionTooltipsMultiKeyBackgroundComponent } from '@components/themes/theme-studio/options/tooltips/theme-option-tooltips-multi-key-background/theme-option-tooltips-multi-key-background.component';
import { ThemeOptionTooltipsDisplayColorsComponent } from '@components/themes/theme-studio/options/tooltips/theme-option-tooltips-display-colors/theme-option-tooltips-display-colors.component';
import { ThemeOptionTooltipsBorderColorComponent } from '@components/themes/theme-studio/options/tooltips/theme-option-tooltips-border-color/theme-option-tooltips-border-color.component';
import { ThemeOptionTooltipsBorderWidthComponent } from '@components/themes/theme-studio/options/tooltips/theme-option-tooltips-border-width/theme-option-tooltips-border-width.component';
import { ThemeOptionElementsPointStyleComponent } from '@components/themes/theme-studio/options/elements/point/theme-option-elements-point-style/theme-option-elements-point-style.component';
import { ThemeOptionElementsPointRadiusComponent } from '@components/themes/theme-studio/options/elements/point/theme-option-elements-point-radius/theme-option-elements-point-radius.component';
import { ThemeOptionElementsPointRotationComponent } from '@components/themes/theme-studio/options/elements/point/theme-option-elements-point-rotation/theme-option-elements-point-rotation.component';
import { ThemeOptionElementsPointBackgroundColorComponent } from '@components/themes/theme-studio/options/elements/point/theme-option-elements-point-background-color/theme-option-elements-point-background-color.component';
import { ThemeOptionElementsPointBackgroundColorDetailComponent } from '@components/themes/theme-studio/options/elements/point/theme-option-elements-point-background-color-detail/theme-option-elements-point-background-color-detail.component';
import { ThemeOptionElementsPointBorderColorComponent } from '@components/themes/theme-studio/options/elements/point/theme-option-elements-point-border-color/theme-option-elements-point-border-color.component';
import { ThemeOptionElementsPointBorderWidthComponent } from '@components/themes/theme-studio/options/elements/point/theme-option-elements-point-border-width/theme-option-elements-point-border-width.component';
import { ThemeOptionElementsPointBorderColorDetailComponent } from '@components/themes/theme-studio/options/elements/point/theme-option-elements-point-border-color-detail/theme-option-elements-point-border-color-detail.component';
import { ConfirmWarningStripComponent } from '@components/confirm-warning-strip/confirm-warning-strip.component';
import { ThemeOptionElementsPointHitRadiusComponent } from '@components/themes/theme-studio/options/elements/point/theme-option-elements-point-hit-radius/theme-option-elements-point-hit-radius.component';
import { ThemeOptionElementsPointHoverBackgroundColorComponent } from '@components/themes/theme-studio/options/elements/point/theme-option-elements-point-hover-background-color/theme-option-elements-point-hover-background-color.component';
import { ThemeOptionElementsPointHoverBorderColorComponent } from '@components/themes/theme-studio/options/elements/point/theme-option-elements-point-hover-border-color/theme-option-elements-point-hover-border-color.component';
import { ThemeOptionElementsPointHoverBorderWidthComponent } from '@components/themes/theme-studio/options/elements/point/theme-option-elements-point-hover-border-width/theme-option-elements-point-hover-border-width.component';
import { ThemeOptionElementsPointHoverRadiusComponent } from '@components/themes/theme-studio/options/elements/point/theme-option-elements-point-hover-radius/theme-option-elements-point-hover-radius.component';
import { ThemeOptionElementsPointHoverBorderColorDetailComponent } from '@components/themes/theme-studio/options/elements/point/theme-option-elements-point-hover-border-color-detail/theme-option-elements-point-hover-border-color-detail.component';
import { ThemeOptionElementsPointHoverBackgroundColorDetailComponent } from '@components/themes/theme-studio/options/elements/point/theme-option-elements-point-hover-background-color-detail/theme-option-elements-point-hover-background-color-detail.component';
import { ThemeOptionElementsBackgroundColorComponent } from '@components/themes/theme-studio/options/elements/serie/theme-option-elements-background-color/theme-option-elements-background-color.component';
import { ThemeOptionElementsBackgroundColorDetailComponent } from '@components/themes/theme-studio/options/elements/serie/theme-option-elements-background-color-detail/theme-option-elements-background-color-detail.component';
import { HorizontalBarChartComponent } from './charts/horizontal-bar-chart/horizontal-bar-chart.component';
import { ThemeOptionElementsBorderColorDetailComponent } from '@components/themes/theme-studio/options/elements/serie/theme-option-elements-border-color-detail/theme-option-elements-border-color-detail.component';
import { ThemeOptionElementsBorderColorComponent } from '@components/themes/theme-studio/options/elements/serie/theme-option-elements-border-color/theme-option-elements-border-color.component';
import { ThemeOptionElementsBorderWidthComponent } from '@components/themes/theme-studio/options/elements/serie/theme-option-elements-border-width/theme-option-elements-border-width.component';
import { ThemeOptionElementsFillComponent } from '@components/themes/theme-studio/options/elements/serie/theme-option-elements-fill/theme-option-elements-fill.component';
import { ThemeOptionElementsLineTensionComponent } from '@components/themes/theme-studio/options/elements/serie/theme-option-elements-line-tension/theme-option-elements-line-tension.component';
import { ThemeOptionElementsHideInLegendAndTooltipComponent } from '@components/themes/theme-studio/options/elements/serie/theme-option-elements-hide-in-legend-and-tooltip/theme-option-elements-hide-in-legend-and-tooltip.component';
import { ThemeOptionElementsHiddenComponent } from '@components/themes/theme-studio/options/elements/serie/theme-option-elements-hidden/theme-option-elements-hidden.component';
import { ThemeOptionElementsSpanGapsComponent } from '@components/themes/theme-studio/options/elements/serie/theme-option-elements-span-gaps/theme-option-elements-span-gaps.component';
import { ThemeOptionElementsSteppedLineComponent } from '@components/themes/theme-studio/options/elements/serie/theme-option-elements-stepped-line/theme-option-elements-stepped-line.component';
import { ThemeOptionElementsBorderDashComponent } from '@components/themes/theme-studio/options/elements/serie/theme-option-elements-border-dash/theme-option-elements-border-dash.component';
import { ThemeOptionElementsBorderCapStyleComponent } from '@components/themes/theme-studio/options/elements/serie/theme-option-elements-border-cap-style/theme-option-elements-border-cap-style.component';
import { ThemeOptionElementsBorderJoinStyleComponent } from '@components/themes/theme-studio/options/elements/serie/theme-option-elements-border-join-style/theme-option-elements-border-join-style.component';
import { ThemeOptionElementsHoverBackgroundColorComponent } from '@components/themes/theme-studio/options/elements/serie/theme-option-elements-hover-background-color/theme-option-elements-hover-background-color.component';
import { ThemeOptionElementsHoverBorderColorComponent } from '@components/themes/theme-studio/options/elements/serie/theme-option-elements-hover-border-color/theme-option-elements-hover-border-color.component';
import { ThemeOptionElementsHoverBorderWidthComponent } from '@components/themes/theme-studio/options/elements/serie/theme-option-elements-hover-border-width/theme-option-elements-hover-border-width.component';
import { ThemeOptionElementsHoverBackgroundColorDetailComponent } from '@components/themes/theme-studio/options/elements/serie/theme-option-elements-hover-background-color-detail/theme-option-elements-hover-background-color-detail.component';
import { ThemeOptionElementsHoverBorderColorDetailComponent } from '@components/themes/theme-studio/options/elements/serie/theme-option-elements-hover-border-color-detail/theme-option-elements-hover-border-color-detail.component';
import { ThemeOptionElementsHoverBorderDashComponent } from '@components/themes/theme-studio/options/elements/serie/theme-option-elements-hover-border-dash/theme-option-elements-hover-border-dash.component';
import { ThemeOptionElementsChartCutoutPercentageComponent } from '@components/themes/theme-studio/options/elements/chart/theme-option-elements-chart-cutout-percentage/theme-option-elements-chart-cutout-percentage.component';
import { ThemeOptionLayoutBackgroundColorComponent } from '@components/themes/theme-studio/options/layout/theme-option-layout-background-color/theme-option-layout-background-color.component';
import { ThemeOptionElementsChartCircumferenceComponent } from '@components/themes/theme-studio/options/elements/chart/theme-option-elements-chart-circumference/theme-option-elements-chart-circumference.component';
import { ThemeOptionElementsChartRotationComponent } from '@components/themes/theme-studio/options/elements/chart/theme-option-elements-chart-rotation/theme-option-elements-chart-rotation.component';
import { ThemeOptionElementsBorderAlignComponent } from '@components/themes/theme-studio/options/elements/serie/theme-option-elements-border-align/theme-option-elements-border-align.component';
import { ThemeOptionElementsWeightComponent } from '@components/themes/theme-studio/options/elements/serie/theme-option-elements-weight/theme-option-elements-weight.component';
import { ThemeOptionLayoutBackgroundColorDetailComponent } from '@components/themes/theme-studio/options/layout/theme-option-layout-background-color-detail/theme-option-layout-background-color-detail.component';
import { ShowDetailsOptionComponent } from '@components/themes/theme-studio/options/show-details-option/show-details-option.component';
import { ThemeOptionLayoutPaddingTopComponent } from '@components/themes/theme-studio/options/layout/theme-option-layout-padding-top/theme-option-layout-padding-top.component';
import { ThemeOptionLayoutPaddingLeftComponent } from '@components/themes/theme-studio/options/layout/theme-option-layout-padding-left/theme-option-layout-padding-left.component';
import { ThemeOptionLayoutPaddingRightComponent } from '@components/themes/theme-studio/options/layout/theme-option-layout-padding-right/theme-option-layout-padding-right.component';
import { ThemeOptionLayoutPaddingBottomComponent } from '@components/themes/theme-studio/options/layout/theme-option-layout-padding-bottom/theme-option-layout-padding-bottom.component';
import { ThemeOptionDatalabelsAnchorComponent } from '@components/themes/theme-studio/options/datalabels/theme-option-datalabels-anchor/theme-option-datalabels-anchor.component';
import { ThemeOptionDatalabelsAlignComponent } from '@components/themes/theme-studio/options/datalabels/theme-option-datalabels-align/theme-option-datalabels-align.component';
import { ThemeOptionDatalabelsClampComponent } from '@components/themes/theme-studio/options/datalabels/theme-option-datalabels-clamp/theme-option-datalabels-clamp.component';
import { ThemeOptionDatalabelsDisplayComponent } from '@components/themes/theme-studio/options/datalabels/theme-option-datalabels-display/theme-option-datalabels-display.component';
import { ThemeOptionDatalabelsOffsetComponent } from '@components/themes/theme-studio/options/datalabels/theme-option-datalabels-offset/theme-option-datalabels-offset.component';
import { ThemeOptionDatalabelsRotationComponent } from '@components/themes/theme-studio/options/datalabels/theme-option-datalabels-rotation/theme-option-datalabels-rotation.component';
import { ThemeOptionDatalabelsClipComponent } from '@components/themes/theme-studio/options/datalabels/theme-option-datalabels-clip/theme-option-datalabels-clip.component';
import { ThemeOptionDatalabelsBorderWidthComponent } from '@components/themes/theme-studio/options/datalabels/theme-option-datalabels-border-width/theme-option-datalabels-border-width.component';
import { ThemeOptionDatalabelsBorderColorComponent } from '@components/themes/theme-studio/options/datalabels/theme-option-datalabels-border-color/theme-option-datalabels-border-color.component';
import { ThemeOptionDatalabelsBorderRadiusComponent } from '@components/themes/theme-studio/options/datalabels/theme-option-datalabels-border-radius/theme-option-datalabels-border-radius.component';
import { ThemeOptionDatalabelsBorderColorDetailComponent } from '@components/themes/theme-studio/options/datalabels/theme-option-datalabels-border-color-detail/theme-option-datalabels-border-color-detail.component';
import { ThemeOptionDatalabelsPaddingComponent } from '@components/themes/theme-studio/options/datalabels/theme-option-datalabels-padding/theme-option-datalabels-padding.component';
import { ThemeOptionDatalabelsPaddingTopComponent } from '@components/themes/theme-studio/options/datalabels/theme-option-datalabels-padding-top/theme-option-datalabels-padding-top.component';
import { ThemeOptionDatalabelsPaddingLeftComponent } from '@components/themes/theme-studio/options/datalabels/theme-option-datalabels-padding-left/theme-option-datalabels-padding-left.component';
import { ThemeOptionDatalabelsPaddingRightComponent } from '@components/themes/theme-studio/options/datalabels/theme-option-datalabels-padding-right/theme-option-datalabels-padding-right.component';
import { ThemeOptionDatalabelsPaddingBottomComponent } from '@components/themes/theme-studio/options/datalabels/theme-option-datalabels-padding-bottom/theme-option-datalabels-padding-bottom.component';
import { ThemeOptionDatalabelsBackgroundColorComponent } from '@components/themes/theme-studio/options/datalabels/theme-option-datalabels-background-color/theme-option-datalabels-background-color.component';
import { ThemeOptionDatalabelsBackgroundColorDetailComponent } from '@components/themes/theme-studio/options/datalabels/theme-option-datalabels-background-color-detail/theme-option-datalabels-background-color-detail.component';
import { ThemeOptionDatalabelsColorComponent } from '@components/themes/theme-studio/options/datalabels/theme-option-datalabels-color/theme-option-datalabels-color.component';
import { ThemeOptionDatalabelsFontFamilyComponent } from '@components/themes/theme-studio/options/datalabels/theme-option-datalabels-font-family/theme-option-datalabels-font-family.component';
import { ThemeOptionDatalabelsFontSizeComponent } from '@components/themes/theme-studio/options/datalabels/theme-option-datalabels-font-size/theme-option-datalabels-font-size.component';
import { ThemeOptionDatalabelsFontStyleComponent } from '@components/themes/theme-studio/options/datalabels/theme-option-datalabels-font-style/theme-option-datalabels-font-style.component';
import { ThemeOptionDatalabelsFontLineHeightComponent } from '@components/themes/theme-studio/options/datalabels/theme-option-datalabels-font-line-height/theme-option-datalabels-font-line-height.component';
import { ThemeOptionDatalabelsOpacityComponent } from '@components/themes/theme-studio/options/datalabels/theme-option-datalabels-opacity/theme-option-datalabels-opacity.component';
import { ThemeOptionDatalabelsTextAlignComponent } from '@components/themes/theme-studio/options/datalabels/theme-option-datalabels-text-align/theme-option-datalabels-text-align.component';
import { ThemeOptionDatalabelsTextStrokeColorComponent } from '@components/themes/theme-studio/options/datalabels/theme-option-datalabels-text-stroke-color/theme-option-datalabels-text-stroke-color.component';
import { ThemeOptionDatalabelsTextStrokeWidthComponent } from '@components/themes/theme-studio/options/datalabels/theme-option-datalabels-text-stroke-width/theme-option-datalabels-text-stroke-width.component';
import { ThemeOptionDatalabelsTextShadowBlurComponent } from '@components/themes/theme-studio/options/datalabels/theme-option-datalabels-text-shadow-blur/theme-option-datalabels-text-shadow-blur.component';
import { ThemeOptionDatalabelsTextShadowColorComponent } from '@components/themes/theme-studio/options/datalabels/theme-option-datalabels-text-shadow-color/theme-option-datalabels-text-shadow-color.component';
import { ThemeOptionDatalabelsTextShadowColorDetailComponent } from '@components/themes/theme-studio/options/datalabels/theme-option-datalabels-text-shadow-color-detail/theme-option-datalabels-text-shadow-color-detail.component';
import { ThemeOptionDatalabelsTextStrokeColorDetailComponent } from '@components/themes/theme-studio/options/datalabels/theme-option-datalabels-text-stroke-color-detail/theme-option-datalabels-text-stroke-color-detail.component';
import { ThemeOptionElementsXAxisIDComponent } from '@components/themes/theme-studio/options/elements/serie/theme-option-elements-xaxis-id/theme-option-elements-xaxis-id.component';
import { ThemeOptionElementsYAxisIDComponent } from '@components/themes/theme-studio/options/elements/serie/theme-option-elements-yaxis-id/theme-option-elements-yaxis-id.component';
import { ThemeOptionElementsDatalabelsIdComponent } from '@components/themes/theme-studio/options/elements/serie/theme-option-elements-datalabels-id/theme-option-elements-datalabels-id.component';
import { ThemeOptionAxesDisplayComponent } from '@components/themes/theme-studio/options/axes/axis/theme-option-axes-display/theme-option-axes-display.component';
import { ThemeOptionAxesBarPercentageComponent } from '@components/themes/theme-studio/options/axes/axis/theme-option-axes-bar-percentage/theme-option-axes-bar-percentage.component';
import { ThemeOptionAxesCategoryPercentageComponent } from '@components/themes/theme-studio/options/axes/axis/theme-option-axes-category-percentage/theme-option-axes-category-percentage.component';
import { ThemeOptionAxesMinBarLengthComponent } from '@components/themes/theme-studio/options/axes/axis/theme-option-axes-min-bar-length/theme-option-axes-min-bar-length.component';
import { ThemeOptionAxesPositionComponent } from '@components/themes/theme-studio/options/axes/axis/theme-option-axes-position/theme-option-axes-position.component';
import { ThemeOptionAxesGridLinesDisplayComponent } from '@components/themes/theme-studio/options/axes/gridLines/theme-option-axes-grid-lines-display/theme-option-axes-grid-lines-display.component';
import { ThemeOptionAxesGridLinesCircularComponent } from '@components/themes/theme-studio/options/axes/gridLines/theme-option-axes-grid-lines-circular/theme-option-axes-grid-lines-circular.component';
import { ThemeOptionAxesGridLinesColorComponent } from '@components/themes/theme-studio/options/axes/gridLines/theme-option-axes-grid-lines-color/theme-option-axes-grid-lines-color.component';
import { ThemeOptionAxesGridLinesColorDetailComponent } from '@components/themes/theme-studio/options/axes/gridLines/theme-option-axes-grid-lines-color-detail/theme-option-axes-grid-lines-color-detail.component';
import { ThemeOptionAxesGridLinesBorderDashComponent } from '@components/themes/theme-studio/options/axes/gridLines/theme-option-axes-grid-lines-border-dash/theme-option-axes-grid-lines-border-dash.component';
import { ThemeOptionAxesGridLinesLineWidthComponent } from '@components/themes/theme-studio/options/axes/gridLines/theme-option-axes-grid-lines-line-width/theme-option-axes-grid-lines-line-width.component';
import { ThemeOptionAxesGridLinesDrawBorderComponent } from '@components/themes/theme-studio/options/axes/gridLines/theme-option-axes-grid-lines-draw-border/theme-option-axes-grid-lines-draw-border.component';
import { ThemeOptionAxesGridLinesDrawTicksComponent } from '@components/themes/theme-studio/options/axes/gridLines/theme-option-axes-grid-lines-draw-ticks/theme-option-axes-grid-lines-draw-ticks.component';
import { ThemeOptionAxesGridLinesTickMarkLengthComponent } from '@components/themes/theme-studio/options/axes/gridLines/theme-option-axes-grid-lines-tick-mark-length/theme-option-axes-grid-lines-tick-mark-length.component';
import { ThemeOptionAxesTicksBeginAtZeroComponent } from '@components/themes/theme-studio/options/axes/ticks/theme-option-axes-ticks-begin-at-zero/theme-option-axes-ticks-begin-at-zero.component';
import { ThemeOptionAxesTicksDisplayComponent } from '@components/themes/theme-studio/options/axes/ticks/theme-option-axes-ticks-display/theme-option-axes-ticks-display.component';
import { ThemeOptionAxesTicksLineHeightComponent } from '@components/themes/theme-studio/options/axes/ticks/theme-option-axes-ticks-line-height/theme-option-axes-ticks-line-height.component';
import { ThemeOptionAxesTicksFontFamilyComponent } from '@components/themes/theme-studio/options/axes/ticks/theme-option-axes-ticks-font-family/theme-option-axes-ticks-font-family.component';
import { ThemeOptionAxesTicksFontColorComponent } from '@components/themes/theme-studio/options/axes/ticks/theme-option-axes-ticks-font-color/theme-option-axes-ticks-font-color.component';
import { ThemeOptionAxesTicksFontSizeComponent } from '@components/themes/theme-studio/options/axes/ticks/theme-option-axes-ticks-font-size/theme-option-axes-ticks-font-size.component';
import { ThemeOptionAxesTicksFontStyleComponent } from '@components/themes/theme-studio/options/axes/ticks/theme-option-axes-ticks-font-style/theme-option-axes-ticks-font-style.component';
import { ThemeOptionAxesTicksPaddingComponent } from '@components/themes/theme-studio/options/axes/ticks/theme-option-axes-ticks-padding/theme-option-axes-ticks-padding.component';
import { ThemeOptionAxesTitlePaddingComponent } from '@components/themes/theme-studio/options/axes/title/theme-option-axes-title-padding/theme-option-axes-title-padding.component';
import { ThemeOptionAxesTitleDisplayComponent } from '@components/themes/theme-studio/options/axes/title/theme-option-axes-title-display/theme-option-axes-title-display.component';
import { ThemeOptionAxesTitleLineHeightComponent } from '@components/themes/theme-studio/options/axes/title/theme-option-axes-title-line-height/theme-option-axes-title-line-height.component';
import { ThemeOptionAxesTitleFontFamilyComponent } from '@components/themes/theme-studio/options/axes/title/theme-option-axes-title-font-family/theme-option-axes-title-font-family.component';
import { ThemeOptionAxesTitleFontSizeComponent } from '@components/themes/theme-studio/options/axes/title/theme-option-axes-title-font-size/theme-option-axes-title-font-size.component';
import { ThemeOptionAxesTitleFontStyleComponent } from '@components/themes/theme-studio/options/axes/title/theme-option-axes-title-font-style/theme-option-axes-title-font-style.component';
import { ThemeOptionAxesTitleFontColorComponent } from '@components/themes/theme-studio/options/axes/title/theme-option-axes-title-font-color/theme-option-axes-title-font-color.component';
import { WidgetsComponent } from '@components/widgets/widgets/widgets.component';
import { WidgetEditorComponent } from '@components/widgets/widget-editor/widget-editor.component';
import { WidgetStudioComponent } from '@components/widgets/widget-studio/widget-studio/widget-studio.component';
import { DashboardEditorComponent } from '@components/dashboards/dashboard-editor/dashboard-editor.component';
import { ThemesLinksChangeComponent } from '@components/themes/themes-links-change/themes-links-change.component';
import { IndicatorsSelectListComponent } from '@components/indicators/indicators-select-list/indicators-select-list.component';
import { IndicatorsSelectItemComponent } from '@components/indicators/indicators-select-item/indicators-select-item.component';
import { WidgetOptionComponent } from '@components/widgets/widget-studio/options/widget-option/widget-option.component';
import { WidgetIndicatorsLabelComponent } from '@components/widgets/widget-studio/options/indicators/widget-indicators-label/widget-indicators-label.component';
import { WidgetStudioChartComponent } from '@components/widgets/widget-studio/widget-studio-chart/widget-studio-chart.component';
import { WidgetIndicatorsAddComponent } from '@components/widgets/widget-studio/options/indicators/widget-indicators-add/widget-indicators-add.component';
import { WidgetIndicatorsSerieComponent } from '@components/widgets/widget-studio/options/indicators/widget-indicators-serie/widget-indicators-serie.component';
import { WidgetLayersLabelComponent } from '@components/widgets/widget-studio/options/layers/widget-layers-label/widget-layers-label.component';
import { WidgetLayersListComponent } from '@components/widgets/widget-studio/options/layers/widget-layers-list/widget-layers-list.component';
import { WidgetLayersItemComponent } from '@components/widgets/widget-studio/options/layers/widget-layers-item/widget-layers-item.component';
import { WidgetIndicatorsListComponent } from '@components/widgets/widget-studio/options/indicators/widget-indicators-list/widget-indicators-list.component';
import { WidgetIndicatorsItemComponent } from '@components/widgets/widget-studio/options/indicators/widget-indicators-item/widget-indicators-item.component';
import { WidgetLayersThemeOptionsComponent } from '@components/widgets/widget-studio/options/layers/widget-layers-theme-options/widget-layers-theme-options.component';
import { WidgetLayersThemeOptionsListComponent } from '@components/widgets/widget-studio/options/layers/widget-layers-theme-options-list/widget-layers-theme-options-list.component';
import { WidgetLayersThemeOptionsItemComponent } from '@components/widgets/widget-studio/options/layers/widget-layers-theme-options-item/widget-layers-theme-options-item.component';
import { WidgetLayersThemeOptionComponent } from '@components/widgets/widget-studio/options/layers/widget-layers-theme-option/widget-layers-theme-option.component';
import { WidgetLayersChartComponent } from '@components/widgets/widget-studio/options/layers/widget-layers-chart/widget-layers-chart.component';
import { NumbroComponent } from '@components/forms/numbro/numbro.component';
import { NumbroOptionComponent } from '@components/forms/numbro/numbro-option/numbro-option.component';
import { InputComponent } from '@components/forms/input/input.component';
import { WidgetIndicatorsDatalabelsComponent } from '@components/widgets/widget-studio/options/indicators/widget-indicators-datalabels/widget-indicators-datalabels.component';
import { WidgetStudioMainComponent } from '@components/widgets/widget-studio/widget-studio-main/widget-studio-main.component';
import { WidgetStudioSubComponent } from '@components/widgets/widget-studio/widget-studio-sub/widget-studio-sub.component';
import { WidgetStudioSubIndicatorsComponent } from '@components/widgets/widget-studio/widget-studio-sub-indicators/widget-studio-sub-indicators.component';
import { WidgetStudioSubLayersComponent } from '@components/widgets/widget-studio/widget-studio-sub-layers/widget-studio-sub-layers.component';
import { WidgetIndicatorsTypeComponent } from '@components/widgets/widget-studio/options/indicators/widget-indicators-type/widget-indicators-type.component';
import { WidgetIndicatorsStackComponent } from '@components/widgets/widget-studio/options/indicators/widget-indicators-stack/widget-indicators-stack.component';
import { WidgetLayersStackedComponent } from '@components/widgets/widget-studio/options/layers/widget-layers-stacked/widget-layers-stacked.component';
import { WidgetLayersDateFormatComponent } from '@components/widgets/widget-studio/options/layers/widget-layers-date-format/widget-layers-date-format.component';
import { MomentComponent } from '@components/forms/moment/moment.component';
import { WidgetLoadingComponent } from '@components/widgets/widget-loading/widget-loading.component';
import { WidgetLayersSortComponent } from '@components/widgets/widget-studio/options/layers/widget-layers-sort/widget-layers-sort.component';
import { StudioComponent } from '@components/forms/studio/studio.component';
import { WidgetIndicatorsDuplicateComponent } from '@components/widgets/widget-studio/options/indicators/widget-indicators-duplicate/widget-indicators-duplicate.component';
import { WidgetLayersItemMainComponent } from '@components/widgets/widget-studio/options/layers/widget-layers-item-main/widget-layers-item-main.component';
import { WidgetTableComponent } from '@components/widgets/widget-table/widget-table.component';
import { DashboardStudioComponent } from '@components/dashboards/dashboard-studio/dashboard-studio/dashboard-studio.component';
import { DashboardCellsAddComponent } from '@components/dashboards/dashboard-studio/options/cells/dashboard-cells-add/dashboard-cells-add.component';
import { DashboardOptionComponent } from '@components/dashboards/dashboard-studio/options/dashboard-option/dashboard-option.component';
import { DashboardStudioLayoutComponent } from '@components/dashboards/dashboard-studio/dashboard-studio-layout/dashboard-studio-layout.component';
import { DashboardStudioCellComponent } from '@components/dashboards/dashboard-studio/dashboard-studio-cell/dashboard-studio-cell.component';
import { DashboardLayoutEditorComponent } from '@components/dashboards/dashboard-studio/options/layout/dashboard-layout-editor/dashboard-layout-editor.component';
import { DashboardLayoutDepthComponent } from '@components/dashboards/dashboard-studio/options/layout/dashboard-layout-depth/dashboard-layout-depth.component';
import { DashboardLayoutSizeComponent } from '@components/dashboards/dashboard-studio/options/layout/dashboard-layout-size/dashboard-layout-size.component';
import { DashboardLayoutGapComponent } from '@components/dashboards/dashboard-studio/options/layout/dashboard-layout-gap/dashboard-layout-gap.component';
import { DashboardLayoutPaddingComponent } from '@components/dashboards/dashboard-studio/options/layout/dashboard-layout-padding/dashboard-layout-padding.component';
import { DashboardLayoutColumnsComponent } from '@components/dashboards/dashboard-studio/options/layout/dashboard-layout-columns/dashboard-layout-columns.component';
import { DashboardLayoutColorComponent } from '@components/dashboards/dashboard-studio/options/layout/dashboard-layout-color/dashboard-layout-color.component';
import { DashboardCellEditorComponent } from '@components/dashboards/dashboard-studio/options/cells/dashboard-cell-editor/dashboard-cell-editor.component';
import { DashboardCellRowsComponent } from '@components/dashboards/dashboard-studio/options/cells/dashboard-cell-rows/dashboard-cell-rows.component';
import { DashboardCellColumnsComponent } from '@components/dashboards/dashboard-studio/options/cells/dashboard-cell-columns/dashboard-cell-columns.component';
import { DashboardCellDisplayComponent } from '@components/dashboards/dashboard-studio/options/cells/dashboard-cell-display/dashboard-cell-display.component';
import { DashboardCellRankComponent } from '@components/dashboards/dashboard-studio/options/cells/dashboard-cell-rank/dashboard-cell-rank.component';
import { DashboardCellWidgetComponent } from '@components/dashboards/dashboard-studio/options/cells/dashboard-cell-widget/dashboard-cell-widget.component';
import { WidgetsLinksChangeComponent } from '@components/widgets/widgets-links-change/widgets-links-change.component';
import { DashboardCellRemoveComponent } from '@components/dashboards/dashboard-studio/options/cells/dashboard-cell-remove/dashboard-cell-remove.component';
import { ImportStudioComponent } from '@components/import/import-studio/import-studio/import-studio.component';
import { ImportIndicatorsSelectComponent } from '@components/import/import-studio/import-indicators-select/import-indicators-select.component';
import { ImportImportFrameComponent } from '@components/import/import-studio/import-import-frame/import-import-frame.component';
import { ImportItemParamsComponent } from '@components/import/import-studio/import-item-params/import-item-params.component';
import { ImportValuesComponent } from '@components/import/import-studio/import-values/import-values.component';
import { ImportOptionComponent } from '@components/import/import-studio/import-option/import-option.component';
import { WidgetDrillDownComponent } from '@components/widgets/widget-drill-down/widget-drill-down.component';
import { WidgetIndicatorsFiltersComponent } from '@components/widgets/widget-studio/options/indicators/widget-indicators-filters/widget-indicators-filters.component';
import { WidgetIndicatorsFilterComponent } from '@components/widgets/widget-studio/options/indicators/widget-indicators-filter/widget-indicators-filter.component';
import { WidgetIndicatorsFiltersPivotComponent } from '@components/widgets/widget-studio/options/indicators/widget-indicators-filters-pivot/widget-indicators-filters-pivot.component';
import { WidgetLayersFillBlanksDateComponent } from '@components/widgets/widget-studio/options/layers/widget-layers-fill-blanks-date/widget-layers-fill-blanks-date.component';
import { WidgetLayersFiltersDatesComponent } from '@components/widgets/widget-studio/options/layers/widget-layers-filters-dates/widget-layers-filters-dates.component';
import { WidgetLayersFiltersDateComponent } from '@components/widgets/widget-studio/options/layers/widget-layers-filters-date/widget-layers-filters-date.component';
import { WidgetLayersDateBoundsComponent } from '@components/widgets/widget-studio/options/layers/widget-layers-date-bounds/widget-layers-date-bounds.component';
import { WidgetIndicatorsTimelineComponent } from '@components/widgets/widget-studio/options/indicators/widget-indicators-timeline/widget-indicators-timeline.component';
import { WidgetLayersMaxValuesComponent } from '@components/widgets/widget-studio/options/layers/widget-layers-max-values/widget-layers-max-values.component';
import { WidgetLayersTitleComponent } from '@components/widgets/widget-studio/options/layers/widget-layers-title/widget-layers-title.component';
import { WidgetFiltersComponent } from '@components/widgets/widget-filters/widget-filters.component';
import { WidgetDatesBoundsComponent } from '@components/widgets/widget-dates-bounds/widget-dates-bounds.component';
import { WidgetDateBoundsComponent } from '@components/widgets/widget-date-bounds/widget-date-bounds.component';
import { WidgetDateFormatComponent } from '@components/widgets/widget-date-format/widget-date-format.component';
import { WidgetMaxValuesComponent } from '@components/widgets/widget-max-values/widget-max-values.component';
import { HelperDateFormatComponent } from '@components/helpers/helper-date-format/helper-date-format.component';
import { DashboardDisplayComponent } from '@components/dashboards/dashboard-display/dashboard-display.component';
import { DashboardLayoutComponent } from '@components/dashboards/dashboard-layout/dashboard-layout.component';
import { DashboardCellComponent } from '@components/dashboards/dashboard-cell/dashboard-cell.component';
import { DashboardDisplayFramesComponent } from '@components/dashboards/dashboard-display-frames/dashboard-display-frames.component';
import { DashboardFiltersComponent } from '@components/dashboards/dashboard-filters/dashboard-filters.component';
import { DashboardDatesBoundsComponent } from '@components/dashboards/dashboard-dates-bounds/dashboard-dates-bounds.component';
import { DashboardDateFormatComponent } from '@components/dashboards/dashboard-date-format/dashboard-date-format.component';
import { DashboardLayoutDraggableComponent } from '@components/dashboards/dashboard-studio/options/layout/dashboard-layout-draggable/dashboard-layout-draggable.component';
import { CommentsComponent } from '@components/forms/comments/comments.component';
import { CommentComponent } from '@components/forms/comment/comment.component';
import { FormulaComponent } from '@components/forms/formula/formula.component';
import { WidgetIndicatorsFormulaComponent } from '@components/widgets/widget-studio/options/indicators/widget-indicators-formula/widget-indicators-formula.component';
import { HelperFormulaComponent } from '@components/helpers/helper-formula/helper-formula.component';
import { HelperComponent } from '@components/helpers/helper/helper.component';
import { WidgetIndicatorsDisplayComponent } from '@components/widgets/widget-studio/options/indicators/widget-indicators-display/widget-indicators-display.component';
import { ThemeStudioMainComponent } from '@components/themes/theme-studio/theme-studio-main/theme-studio-main.component';
import { ThemeStudioExportComponent } from '@components/themes/theme-studio/theme-studio-export/theme-studio-export.component';
import { ThemeStudioValuesComponent } from '@components/themes/theme-studio/theme-studio-values/theme-studio-values.component';
import { ThemeOptionsLayoutComponent } from '@components/themes/theme-studio/options/theme-options/theme-options-layout/theme-options-layout.component';
import { ThemeOptionsAnimationComponent } from '@components/themes/theme-studio/options/theme-options/theme-options-animation/theme-options-animation.component';
import { ThemeOptionsLegendComponent } from '@components/themes/theme-studio/options/theme-options/theme-options-legend/theme-options-legend.component';
import { ThemeOptionsTooltipsComponent } from '@components/themes/theme-studio/options/theme-options/theme-options-tooltips/theme-options-tooltips.component';
import { ThemeOptionsTitleComponent } from '@components/themes/theme-studio/options/theme-options/theme-options-title/theme-options-title.component';
import { ThemeOptionsChartComponent } from '@components/themes/theme-studio/options/theme-options/theme-options-chart/theme-options-chart.component';
import { ThemeOptionsSerieComponent } from '@components/themes/theme-studio/options/theme-options/theme-options-serie/theme-options-serie.component';
import { ThemeOptionsDatalabelsComponent } from '@components/themes/theme-studio/options/theme-options/theme-options-datalabels/theme-options-datalabels.component';
import { ThemeOptionsAxisComponent } from '@components/themes/theme-studio/options/theme-options/theme-options-axis/theme-options-axis.component';
import { ThemeOptionElementsIconFrameComponent } from '@components/themes/theme-studio/options/elements/serie/theme-option-elements-icon-frame/theme-option-elements-icon-frame.component';
import { WidgetStudioChartStickerComponent } from '@components/widgets/widget-studio/widget-studio-chart/widget-studio-chart-sticker/widget-studio-chart-sticker.component';
import { ThemeOptionElementsChartTransitionAnimationComponent } from '@components/themes/theme-studio/options/elements/chart/theme-option-elements-chart-transition-animation/theme-option-elements-chart-transition-animation.component';
import { ThemeOptionElementsBackgroundMultiGradiantComponent } from '@components/themes/theme-studio/options/elements/serie/theme-option-elements-background-multi-gradiant/theme-option-elements-background-multi-gradiant.component';
import { ThemeOptionElementsBorderMultiGradiantComponent } from '@components/themes/theme-studio/options/elements/serie/theme-option-elements-border-multi-gradiant/theme-option-elements-border-multi-gradiant.component';
import { ConstructorComponent } from '@components/constructor/constructor.component';
import { WidgetPivotsOrderComponent } from '@components/widgets/widget-pivots-order/widget-pivots-order.component';
import { WidgetOptionsMenuComponent } from '@components/widgets/widget-options-menu/widget-options-menu.component';
import { HelperBbcodeComponent } from '@components/helpers/helper-bbcode/helper-bbcode.component';
import { CommentEditorComponent } from '@components/forms/comment-editor/comment-editor.component';
import { WidgetIndicatorsRemoveComponent } from '@components/widgets/widget-studio/options/indicators/widget-indicators-remove/widget-indicators-remove.component';
import { WidgetIndicatorsCloneComponent } from '@components/widgets/widget-studio/options/indicators/widget-indicators-clone/widget-indicators-clone.component';
import { WidgetIndicatorsIconComponent } from '@components/widgets/widget-studio/options/indicators/widget-indicators-icon/widget-indicators-icon.component';
import { WidgetLayersCarouselComponent } from '@components/widgets/widget-studio/options/layers/widget-layers-carousel/widget-layers-carousel.component';
import { ResetPasswordComponent } from '@components/authActions/reset-password/reset-password.component';
import { AuthActionsComponent } from '@components/authActions/auth-actions/auth-actions.component';
import { IndicatorStudioComponent } from '@components/indicators/indicator-studio/indicator-studio/indicator-studio.component';
import { IndicatorStudioPivotComponent } from '@components/indicators/indicator-studio/indicator-studio-pivot/indicator-studio-pivot.component';
import { IndicatorStudioDateComponent } from '@components/indicators/indicator-studio/indicator-studio-date/indicator-studio-date.component';
import { WidgetDesignerFiltersComponent } from '@components/widgets/widget-designer-filters/widget-designer-filters.component';
import { WidgetDesignerDatesBoundsComponent } from '@components/widgets/widget-designer-dates-bounds/widget-designer-dates-bounds.component';
import { WidgetIndicatorsAxisComponent } from '@components/widgets/widget-studio/options/indicators/widget-indicators-axis/widget-indicators-axis.component';
import { WidgetLayersFullyColoredComponent } from '@components/widgets/widget-studio/options/layers/widget-layers-fully-colored/widget-layers-fully-colored.component';
import { WidgetLayersTemplateComponent } from '@components/widgets/widget-studio/options/layers/widget-layers-template/widget-layers-template.component';
import { WidgetStudioChartGaugeComponent } from '@components/widgets/widget-studio/widget-studio-chart/widget-studio-chart-gauge/widget-studio-chart-gauge.component';
import { WidgetLayersDonutComponent } from '@components/widgets/widget-studio/options/layers/widget-layers-donut/widget-layers-donut.component';
import { WidgetLayersDisplayRemainingComponent } from '@components/widgets/widget-studio/options/layers/widget-layers-display-remaining/widget-layers-display-remaining.component';
import { WidgetLayersMinComponent } from '@components/widgets/widget-studio/options/layers/widget-layers-min/widget-layers-min.component';
import { WidgetLayersMaxComponent } from '@components/widgets/widget-studio/options/layers/widget-layers-max/widget-layers-max.component';
import { WidgetLayersHideMinMaxComponent } from '@components/widgets/widget-studio/options/layers/widget-layers-hide-min-max/widget-layers-hide-min-max.component';
import { WidgetLayersPercentComponent } from '@components/widgets/widget-studio/options/layers/widget-layers-percent/widget-layers-percent.component';
import { WidgetLayersTargetComponent } from '@components/widgets/widget-studio/options/layers/widget-layers-target/widget-layers-target.component';
import { ThemeOptionElementsChartGaugeColorComponent } from '@components/themes/theme-studio/options/elements/chart/theme-option-elements-chart-gauge-color/theme-option-elements-chart-gauge-color.component';
import { ThemeOptionElementsChartGaugeShadowOpacityComponent } from '@components/themes/theme-studio/options/elements/chart/theme-option-elements-chart-gauge-shadow-opacity/theme-option-elements-chart-gauge-shadow-opacity.component';
import { ThemeOptionElementsChartGaugeShadowSizeComponent } from '@components/themes/theme-studio/options/elements/chart/theme-option-elements-chart-gauge-shadow-size/theme-option-elements-chart-gauge-shadow-size.component';
import { ThemeOptionElementsChartGaugeShadowVerticalOffsetComponent } from '@components/themes/theme-studio/options/elements/chart/theme-option-elements-chart-gauge-shadow-vertical-offset/theme-option-elements-chart-gauge-shadow-vertical-offset.component';
import { ThemeOptionElementsChartGaugeShadowShowInnerComponent } from '@components/themes/theme-studio/options/elements/chart/theme-option-elements-chart-gauge-shadow-show-inner/theme-option-elements-chart-gauge-shadow-show-inner.component';
import { ThemeOptionElementsChartGaugePointerDisplayComponent } from '@components/themes/theme-studio/options/elements/chart/theme-option-elements-chart-gauge-pointer-display/theme-option-elements-chart-gauge-pointer-display.component';
import { ThemeOptionElementsChartGaugePointerTopLengthComponent } from '@components/themes/theme-studio/options/elements/chart/theme-option-elements-chart-gauge-pointer-top-length/theme-option-elements-chart-gauge-pointer-top-length.component';
import { ThemeOptionElementsChartGaugePointerBottomLengthComponent } from '@components/themes/theme-studio/options/elements/chart/theme-option-elements-chart-gauge-pointer-bottom-length/theme-option-elements-chart-gauge-pointer-bottom-length.component';
import { ThemeOptionElementsChartGaugePointerBottomWidthComponent } from '@components/themes/theme-studio/options/elements/chart/theme-option-elements-chart-gauge-pointer-bottom-width/theme-option-elements-chart-gauge-pointer-bottom-width.component';
import { ThemeOptionElementsChartGaugePointerStrokeComponent } from '@components/themes/theme-studio/options/elements/chart/theme-option-elements-chart-gauge-pointer-stroke/theme-option-elements-chart-gauge-pointer-stroke.component';
import { ThemeOptionElementsChartGaugePointerStrokeWidthComponent } from '@components/themes/theme-studio/options/elements/chart/theme-option-elements-chart-gauge-pointer-stroke-width/theme-option-elements-chart-gauge-pointer-stroke-width.component';
import { ThemeOptionElementsChartGaugePointerStrokeLineCapComponent } from '@components/themes/theme-studio/options/elements/chart/theme-option-elements-chart-gauge-pointer-stroke-line-cap/theme-option-elements-chart-gauge-pointer-stroke-line-cap.component';
import { ThemeOptionElementsChartGaugePointerColorComponent } from '@components/themes/theme-studio/options/elements/chart/theme-option-elements-chart-gauge-pointer-color/theme-option-elements-chart-gauge-pointer-color.component';
import { ThemeOptionElementsChartGaugeWidthScaleComponent } from '@components/themes/theme-studio/options/elements/chart/theme-option-elements-chart-gauge-width-scale/theme-option-elements-chart-gauge-width-scale.component';
import { BrowserFiltersComponent } from '@components/browser/browser-filters/browser-filters.component';
import { UserFiltersComponent } from '@components/users/user-filters/user-filters.component';
import { UserFilterComponent } from '@components/users/user-filter/user-filter.component';
import { WidgetLayersAxesNumbroComponent } from '@components/widgets/widget-studio/options/layers/widget-layers-axes-numbro/widget-layers-axes-numbro.component';
import { DashboardLayoutSummaryLineComponent } from './components/dashboards/dashboard-layout/dashboard-layout-summary-line/dashboard-layout-summary-line.component';
import { UnsupportedComponent } from './components/unsupported/unsupported.component';
import { WidgetIndicatorCumulativeComponent } from './components/widgets/widget-studio/options/indicators/widget-indicator-cumulative/widget-indicator-cumulative.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    CompanyComponent,
    BrowserComponent,
    BrowserNavbarComponent,
    VersionComponent,
    GridLayoutDirective,
    DropZoneDirective,
    GridSpanDirective,
    SortableDirective,
    BrowserNavbarPanelsComponent,
    PresencesComponent,
    EditorComponent,
    DashboardsComponent,
    LabelsComponent,
    DescriptionsComponent,
    EditorSectionTitleComponent,
    IconsComponent,
    ColorsComponent,
    RightsComponent,
    BrowserPanelComponent,
    DeleteComponent,
    RightsLinksListComponent,
    RightsLinksItemComponent,
    LinksItemComponent,
    LinksItemOptionComponent,
    ItemTileComponent,
    BrowserFrameComponent,
    ItemStripComponent,
    UsersComponent,
    FirstnameComponent,
    LastnameComponent,
    EmailComponent,
    RolesComponent,
    UserIdComponent,
    LanguageComponent,
    ActivationComponent,
    FormStripComponent,
    EmailResetComponent,
    RightsSwitchFullComponent,
    TagsComponent,
    TagsLinksListComponent,
    TagsLinksItemComponent,
    TagsIconsListComponent,
    TagsIconsItemComponent,
    GroupsComponent,
    ProfilComponent,
    AvatarComponent,
    IndicatorsComponent,
    PivotsComponent,
    PivotsLinksListComponent,
    PivotsLinksItemComponent,
    LinkedColumnsComponent,
    NotDeletableComponent,
    DatesComponent,
    DateFormatsComponent,
    DatesLinksListComponent,
    DatesLinksItemComponent,
    ServersComponent,
    UrlComponent,
    MetasComponent,
    LoadingComponent,
    MainComponent,
    ServersLinksListComponent,
    ServersLinksItemComponent,
    EmptyComponent,
    ImportScopeComponent,
    EmptyPanelComponent,
    ItemInfoComponent,
    ImportCellComponent,
    FiltersPipe,
    ImportDetectValueComponent,
    FiltersComponent,
    IndicatorEditorComponent,
    PivotEditorComponent,
    DateEditorComponent,
    ImportTagsComponent,
    DownloadFileTypeComponent,
    ImportUploadingComponent,
    CapitalizeFirstPipe,
    ImportProgressComponent,
    ThemesComponent,
    PivotsLinksChangeComponent,
    ImportLinksChangeComponent,
    DatesLinksChangeComponent,
    IndicatorsLinksChangeComponent,
    ThemeStudioComponent,
    LineChartComponent,
    BarChartComponent,
    RadarChartComponent,
    PieChartComponent,
    PolarAreaChartComponent,
    DoughnutChartComponent,
    BubbleChartComponent,
    ScatterChartComponent,
    ThemeOptionsComponent,
    ThemeEditorComponent,
    NumberComponent,
    ThemeOptionAnimationEasingComponent,
    ThemeOptionComponent,
    ThemeOptionAnimationDurationComponent,
    ThemeOptionLegendDisplayComponent,
    ThemeOptionLegendPositionComponent,
    ThemeOptionLegendFullWidthComponent,
    ThemeOptionLegendReverseComponent,
    CheckComponent,
    ThemeOptionLegendFontSizeComponent,
    ThemeOptionFontSizeComponent,
    ThemeOptionFontStyleComponent,
    ThemeOptionLegendFontStyleComponent,
    ThemeOptionLegendFontColorComponent,
    ColorPickerComponent,
    ThemeOptionLegendBoxWidthComponent,
    ThemeOptionLegendPaddingComponent,
    ThemeOptionLegendUsePointStyleComponent,
    ThemeOptionLegendFontFamilyComponent,
    ThemeOptionLayoutPaddingComponent,
    ThemeOptionFontFamilyComponent,
    ThemeOptionTitleDisplayComponent,
    ThemeOptionTitlePositionComponent,
    ThemeOptionTitleFontSizeComponent,
    ThemeOptionTitleFontStyleComponent,
    ThemeOptionTitleFontColorComponent,
    ThemeOptionTitlePaddingComponent,
    ThemeOptionTitleFontFamilyComponent,
    ThemeOptionAnimationAnimateRotateComponent,
    ThemeOptionAnimationAnimateScaleComponent,
    ThemeOptionTooltipsModeComponent,
    ThemeOptionTooltipsDisplayComponent,
    ThemeOptionTooltipsIntersectComponent,
    ThemeOptionTooltipsPositionComponent,
    ThemeOptionTooltipsBackgroundColorComponent,
    SliderComponent,
    ThemeOptionTooltipsTitleAlignComponent,
    ThemeOptionTitleLineHeightComponent,
    ThemeOptionTooltipsTitleFontColorComponent,
    ThemeOptionTooltipsTitleFontStyleComponent,
    ThemeOptionTooltipsTitleFontSizeComponent,
    ThemeOptionTooltipsTitleFontFamilyComponent,
    ThemeOptionTooltipsTitleSpacingComponent,
    ThemeOptionTooltipsTitleMarginBottomComponent,
    ThemeOptionTooltipsBodyFontColorComponent,
    ThemeOptionTooltipsBodyFontStyleComponent,
    ThemeOptionTooltipsBodyFontSizeComponent,
    ThemeOptionTooltipsBodyFontFamilyComponent,
    ThemeOptionTooltipsBodyAlignComponent,
    ThemeOptionTooltipsBodySpacingComponent,
    ThemeOptionTooltipsXPaddingComponent,
    ThemeOptionTooltipsYPaddingComponent,
    ThemeOptionTooltipsCaretPaddingComponent,
    ThemeOptionTooltipsCaretSizeComponent,
    ThemeOptionTooltipsCornerRadiusComponent,
    ThemeOptionTooltipsMultiKeyBackgroundComponent,
    ThemeOptionTooltipsDisplayColorsComponent,
    ThemeOptionTooltipsBorderColorComponent,
    ThemeOptionTooltipsBorderWidthComponent,
    ThemeOptionElementsPointStyleComponent,
    ThemeOptionElementsPointRadiusComponent,
    ThemeOptionElementsPointRotationComponent,
    ThemeOptionElementsPointBackgroundColorComponent,
    ThemeOptionElementsPointBackgroundColorDetailComponent,
    ThemeOptionElementsPointBorderColorComponent,
    ThemeOptionElementsPointBorderWidthComponent,
    ThemeOptionElementsPointBorderColorDetailComponent,
    ConfirmWarningStripComponent,
    ThemeOptionElementsPointHitRadiusComponent,
    ThemeOptionElementsPointHoverBackgroundColorComponent,
    ThemeOptionElementsPointHoverBorderColorComponent,
    ThemeOptionElementsPointHoverBorderWidthComponent,
    ThemeOptionElementsPointHoverRadiusComponent,
    ThemeOptionElementsPointHoverBorderColorDetailComponent,
    ThemeOptionElementsPointHoverBackgroundColorDetailComponent,
    ThemeOptionElementsBackgroundColorComponent,
    ThemeOptionElementsBackgroundColorDetailComponent,
    HorizontalBarChartComponent,
    ThemeOptionElementsBorderColorDetailComponent,
    ThemeOptionElementsBorderColorComponent,
    ThemeOptionElementsBorderWidthComponent,
    ThemeOptionElementsFillComponent,
    ThemeOptionElementsLineTensionComponent,
    StackedBarChartComponent,
    StackedHorizontalBarChartComponent,
    ThemeOptionElementsHideInLegendAndTooltipComponent,
    ThemeOptionElementsHiddenComponent,
    ThemeOptionElementsSpanGapsComponent,
    ThemeOptionElementsSteppedLineComponent,
    ThemeOptionElementsBorderDashComponent,
    ThemeOptionElementsBorderCapStyleComponent,
    ThemeOptionElementsBorderJoinStyleComponent,
    ThemeOptionElementsHoverBackgroundColorComponent,
    ThemeOptionElementsHoverBorderColorComponent,
    ThemeOptionElementsHoverBorderWidthComponent,
    ThemeOptionElementsHoverBackgroundColorDetailComponent,
    ThemeOptionElementsHoverBorderColorDetailComponent,
    ThemeOptionElementsHoverBorderDashComponent,
    ThemeOptionElementsChartCutoutPercentageComponent,
    StackedLineChartComponent,
    ThemeOptionLayoutBackgroundColorComponent,
    ThemeOptionElementsChartCircumferenceComponent,
    ThemeOptionElementsChartRotationComponent,
    ThemeOptionElementsBorderAlignComponent,
    ThemeOptionElementsWeightComponent,
    ThemeOptionLayoutBackgroundColorDetailComponent,
    ShowDetailsOptionComponent,
    ThemeOptionLayoutPaddingTopComponent,
    ThemeOptionLayoutPaddingLeftComponent,
    ThemeOptionLayoutPaddingRightComponent,
    ThemeOptionLayoutPaddingBottomComponent,
    ThemeOptionDatalabelsAnchorComponent,
    ThemeOptionDatalabelsAlignComponent,
    ThemeOptionDatalabelsClampComponent,
    ThemeOptionDatalabelsDisplayComponent,
    ThemeOptionDatalabelsOffsetComponent,
    ThemeOptionDatalabelsRotationComponent,
    ThemeOptionDatalabelsClipComponent,
    ThemeOptionDatalabelsBorderWidthComponent,
    ThemeOptionDatalabelsBorderColorComponent,
    ThemeOptionDatalabelsBorderRadiusComponent,
    ThemeOptionDatalabelsBorderColorDetailComponent,
    ThemeOptionDatalabelsPaddingComponent,
    ThemeOptionDatalabelsPaddingTopComponent,
    ThemeOptionDatalabelsPaddingLeftComponent,
    ThemeOptionDatalabelsPaddingRightComponent,
    ThemeOptionDatalabelsPaddingBottomComponent,
    ThemeOptionDatalabelsBackgroundColorComponent,
    ThemeOptionDatalabelsBackgroundColorDetailComponent,
    ThemeOptionDatalabelsColorComponent,
    ThemeOptionDatalabelsFontFamilyComponent,
    ThemeOptionDatalabelsFontSizeComponent,
    ThemeOptionDatalabelsFontStyleComponent,
    ThemeOptionDatalabelsFontLineHeightComponent,
    ThemeOptionDatalabelsOpacityComponent,
    ThemeOptionDatalabelsTextAlignComponent,
    ThemeOptionDatalabelsTextStrokeColorComponent,
    ThemeOptionDatalabelsTextStrokeWidthComponent,
    ThemeOptionDatalabelsTextShadowBlurComponent,
    ThemeOptionDatalabelsTextShadowColorComponent,
    ThemeOptionDatalabelsTextShadowColorDetailComponent,
    ThemeOptionDatalabelsTextStrokeColorDetailComponent,
    ThemeOptionElementsXAxisIDComponent,
    ThemeOptionElementsYAxisIDComponent,
    ThemeOptionElementsDatalabelsIdComponent,
    ThemeOptionAxesDisplayComponent,
    ThemeOptionAxesBarPercentageComponent,
    ThemeOptionAxesCategoryPercentageComponent,
    ThemeOptionAxesMinBarLengthComponent,
    ThemeOptionAxesPositionComponent,
    ThemeOptionAxesGridLinesDisplayComponent,
    ThemeOptionAxesGridLinesCircularComponent,
    ThemeOptionAxesGridLinesColorComponent,
    ThemeOptionAxesGridLinesColorDetailComponent,
    ThemeOptionAxesGridLinesBorderDashComponent,
    ThemeOptionAxesGridLinesLineWidthComponent,
    ThemeOptionAxesGridLinesDrawBorderComponent,
    ThemeOptionAxesGridLinesDrawTicksComponent,
    ThemeOptionAxesGridLinesTickMarkLengthComponent,
    ThemeOptionAxesTicksBeginAtZeroComponent,
    ThemeOptionAxesTicksDisplayComponent,
    ThemeOptionAxesTicksLineHeightComponent,
    ThemeOptionAxesTicksFontFamilyComponent,
    ThemeOptionAxesTicksFontColorComponent,
    ThemeOptionAxesTicksFontSizeComponent,
    ThemeOptionAxesTicksFontStyleComponent,
    ThemeOptionAxesTicksPaddingComponent,
    ThemeOptionAxesTitlePaddingComponent,
    ThemeOptionAxesTitleDisplayComponent,
    ThemeOptionAxesTitleLineHeightComponent,
    ThemeOptionAxesTitleFontFamilyComponent,
    ThemeOptionAxesTitleFontSizeComponent,
    ThemeOptionAxesTitleFontStyleComponent,
    ThemeOptionAxesTitleFontColorComponent,
    TableChartComponent,
    WidgetsComponent,
    WidgetEditorComponent,
    WidgetStudioComponent,
    DashboardEditorComponent,
    ThemesLinksChangeComponent,
    IndicatorsSelectListComponent,
    IndicatorsSelectItemComponent,
    WidgetOptionComponent,
    WidgetIndicatorsLabelComponent,
    WidgetStudioChartComponent,
    WidgetIndicatorsAddComponent,
    WidgetIndicatorsSerieComponent,
    WidgetLayersLabelComponent,
    WidgetLayersListComponent,
    WidgetLayersItemComponent,
    WidgetIndicatorsListComponent,
    WidgetIndicatorsItemComponent,
    ThemeOptionsDirective,
    WidgetLayersThemeOptionsComponent,
    WidgetLayersThemeOptionsListComponent,
    WidgetLayersThemeOptionsItemComponent,
    WidgetLayersThemeOptionComponent,
    WidgetLayersChartComponent,
    NumbroComponent,
    NumbroOptionComponent,
    InputComponent,
    WidgetIndicatorsDatalabelsComponent,
    WidgetStudioMainComponent,
    WidgetStudioSubComponent,
    WidgetStudioSubIndicatorsComponent,
    WidgetStudioSubLayersComponent,
    WidgetIndicatorsTypeComponent,
    WidgetIndicatorsStackComponent,
    WidgetLayersStackedComponent,
    ChartPipe,
    WidgetLayersDateFormatComponent,
    MomentComponent,
    WidgetLoadingComponent,
    WidgetLayersSortComponent,
    StudioComponent,
    WidgetIndicatorsDuplicateComponent,
    WidgetLayersItemMainComponent,
    WidgetTableComponent,
    DashboardStudioComponent,
    DashboardCellsAddComponent,
    DashboardOptionComponent,
    DashboardStudioLayoutComponent,
    DashboardStudioCellComponent,
    DashboardLayoutEditorComponent,
    DashboardLayoutDepthComponent,
    DashboardLayoutSizeComponent,
    DashboardLayoutGapComponent,
    DashboardLayoutPaddingComponent,
    DashboardLayoutColumnsComponent,
    DashboardLayoutColorComponent,
    DashboardCellEditorComponent,
    DashboardCellRowsComponent,
    DashboardCellColumnsComponent,
    DashboardCellDisplayComponent,
    DashboardCellRankComponent,
    DashboardCellWidgetComponent,
    WidgetsLinksChangeComponent,
    DashboardCellRemoveComponent,
    ImportStudioComponent,
    ImportIndicatorsSelectComponent,
    ImportImportFrameComponent,
    ImportItemParamsComponent,
    ImportValuesComponent,
    ImportOptionComponent,
    WidgetDrillDownComponent,
    WidgetIndicatorsFiltersComponent,
    WidgetIndicatorsFilterComponent,
    WidgetIndicatorsFiltersPivotComponent,
    WidgetLayersFillBlanksDateComponent,
    WidgetLayersFiltersDatesComponent,
    WidgetLayersFiltersDateComponent,
    WidgetLayersDateBoundsComponent,
    WidgetIndicatorsTimelineComponent,
    WidgetLayersMaxValuesComponent,
    WidgetLayersTitleComponent,
    WidgetFiltersComponent,
    WidgetDatesBoundsComponent,
    WidgetDateBoundsComponent,
    WidgetDateFormatComponent,
    WidgetMaxValuesComponent,
    HelperDateFormatComponent,
    DashboardDisplayComponent,
    DashboardLayoutComponent,
    DashboardCellComponent,
    DashboardDisplayFramesComponent,
    DashboardFiltersComponent,
    DashboardDatesBoundsComponent,
    DashboardDateFormatComponent,
    SearchTextPipe,
    DashboardLayoutDraggableComponent,
    CommentsComponent,
    CommentComponent,
    FormulaComponent,
    WidgetIndicatorsFormulaComponent,
    HelperFormulaComponent,
    HelperComponent,
    WidgetIndicatorsDisplayComponent,
    StickerChartComponent,
    ThemeStudioMainComponent,
    ThemeStudioExportComponent,
    ThemeStudioValuesComponent,
    ThemeOptionsLayoutComponent,
    ThemeOptionsAnimationComponent,
    ThemeOptionsLegendComponent,
    ThemeOptionsTooltipsComponent,
    ThemeOptionsTitleComponent,
    ThemeOptionsChartComponent,
    ThemeOptionsSerieComponent,
    ThemeOptionsDatalabelsComponent,
    ThemeOptionsAxisComponent,
    ThemeOptionElementsIconFrameComponent,
    WidgetStudioChartStickerComponent,
    ThemeOptionElementsChartTransitionAnimationComponent,
    ThemeOptionElementsBackgroundMultiGradiantComponent,
    ThemeOptionElementsBorderMultiGradiantComponent,
    ConstructorComponent,
    WidgetPivotsOrderComponent,
    WidgetOptionsMenuComponent,
    HelperBbcodeComponent,
    OrderByPipe,
    CommentEditorComponent,
    WidgetIndicatorsRemoveComponent,
    WidgetIndicatorsCloneComponent,
    WidgetIndicatorsIconComponent,
    WidgetLayersCarouselComponent,
    ResetPasswordComponent,
    AuthActionsComponent,
    IndicatorStudioComponent,
    IndicatorStudioPivotComponent,
    IndicatorStudioDateComponent,
    WidgetDesignerFiltersComponent,
    WidgetDesignerDatesBoundsComponent,
    WidgetIndicatorsAxisComponent,
    WidgetLayersFullyColoredComponent,
    WidgetLayersTemplateComponent,
    WidgetStudioChartGaugeComponent,
    WidgetLayersDonutComponent,
    WidgetLayersDisplayRemainingComponent,
    WidgetLayersMinComponent,
    WidgetLayersMaxComponent,
    WidgetLayersHideMinMaxComponent,
    WidgetLayersPercentComponent,
    WidgetLayersTargetComponent,
    GaugeChartComponent,
    ThemeOptionElementsChartGaugeColorComponent,
    ThemeOptionElementsChartGaugeShadowOpacityComponent,
    ThemeOptionElementsChartGaugeShadowSizeComponent,
    ThemeOptionElementsChartGaugeShadowVerticalOffsetComponent,
    ThemeOptionElementsChartGaugeShadowShowInnerComponent,
    ThemeOptionElementsChartGaugePointerDisplayComponent,
    ThemeOptionElementsChartGaugePointerTopLengthComponent,
    ThemeOptionElementsChartGaugePointerBottomLengthComponent,
    ThemeOptionElementsChartGaugePointerBottomWidthComponent,
    ThemeOptionElementsChartGaugePointerStrokeComponent,
    ThemeOptionElementsChartGaugePointerStrokeWidthComponent,
    ThemeOptionElementsChartGaugePointerStrokeLineCapComponent,
    ThemeOptionElementsChartGaugePointerColorComponent,
    ThemeOptionElementsChartGaugeWidthScaleComponent,
    BookmarksPipe,
    TagsPipe,
    BrowserFiltersComponent,
    SearchLabelsPipe,
    UserFiltersComponent,
    UserFilterComponent,
    SearchUserPipe,
    WidgetLayersAxesNumbroComponent,
    DashboardLayoutSummaryLineComponent,
    UnsupportedComponent,
    WidgetIndicatorCumulativeComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ResizableModule,
    ChartsModule,
    AppRoutingModule,
    NgxPaginationModule,
    MDBBootstrapModulesPro.forRoot(),
    ToastModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    AuthService,
    FirebaseService,
    CompanyService,
    ToastService,
    UidService,
    CompressImageService,
    PresenceService,
    SignoutService,
    MessagesService,
    UserService,
    DashboardService,
    RightService,
    TagService,
    IndicatorService,
    PivotService,
    DateService,
    ServerService,
    TokenService,
    MetasService,
    ImportService,
    BackendDocumentsService,
    BackendIndexesService,
    BackendGroupbyService,
    BackendValuesService,
    BackendPivotsService,
    ThemeService,
    ThemeStudioService,
    WidgetService,
    WidgetStudioService,
    DashboardStudioService,
    BackendCollectionsService,
    DashboardDisplayService,
    ColorsService,
    MDBSpinningPreloader
  ],
  entryComponents:[
    ThemeOptionAnimationDurationComponent,
    ThemeOptionAnimationEasingComponent,
    ThemeOptionAnimationAnimateRotateComponent,
    ThemeOptionAnimationAnimateScaleComponent,
    ThemeOptionLayoutPaddingComponent,
    ThemeOptionLayoutBackgroundColorComponent,
    ThemeOptionLegendDisplayComponent,
    ThemeOptionLegendPositionComponent,
    ThemeOptionLegendFullWidthComponent,
    ThemeOptionLegendReverseComponent,
    ThemeOptionLegendBoxWidthComponent,
    ThemeOptionLegendFontSizeComponent,
    ThemeOptionLegendFontStyleComponent,
    ThemeOptionLegendFontColorComponent,
    ThemeOptionLegendFontFamilyComponent,
    ThemeOptionLegendPaddingComponent,
    ThemeOptionLegendUsePointStyleComponent,
    ThemeOptionTitleDisplayComponent,
    ThemeOptionTitlePositionComponent,
    ThemeOptionTitleFontSizeComponent,
    ThemeOptionTitleFontStyleComponent,
    ThemeOptionTitleFontColorComponent,
    ThemeOptionTitleFontFamilyComponent,
    ThemeOptionTitlePaddingComponent,
    ThemeOptionTitleLineHeightComponent,
    ThemeOptionTooltipsDisplayComponent,
    ThemeOptionTooltipsModeComponent,
    ThemeOptionTooltipsIntersectComponent,
    ThemeOptionTooltipsPositionComponent,
    ThemeOptionTooltipsBackgroundColorComponent,
    ThemeOptionTooltipsTitleFontFamilyComponent,
    ThemeOptionTooltipsTitleFontSizeComponent,
    ThemeOptionTooltipsTitleFontStyleComponent,
    ThemeOptionTooltipsTitleFontColorComponent,
    ThemeOptionTooltipsTitleAlignComponent,
    ThemeOptionTooltipsTitleSpacingComponent,
    ThemeOptionTooltipsTitleMarginBottomComponent,
    ThemeOptionTooltipsBodyFontFamilyComponent,
    ThemeOptionTooltipsBodyFontSizeComponent,
    ThemeOptionTooltipsBodyFontStyleComponent,
    ThemeOptionTooltipsBodyFontColorComponent,
    ThemeOptionTooltipsBodyAlignComponent,
    ThemeOptionTooltipsBodySpacingComponent,
    ThemeOptionTooltipsXPaddingComponent,
    ThemeOptionTooltipsXPaddingComponent,
    ThemeOptionTooltipsCaretPaddingComponent,
    ThemeOptionTooltipsCaretSizeComponent,
    ThemeOptionTooltipsCornerRadiusComponent,
    ThemeOptionTooltipsMultiKeyBackgroundComponent,
    ThemeOptionTooltipsDisplayColorsComponent,
    ThemeOptionTooltipsBorderColorComponent,
    ThemeOptionTooltipsBorderWidthComponent,
    ThemeOptionElementsPointStyleComponent,
    ThemeOptionElementsPointRadiusComponent,
    ThemeOptionElementsPointRotationComponent,
    ThemeOptionElementsPointBackgroundColorComponent,
    ThemeOptionElementsPointBorderColorComponent,
    ThemeOptionElementsPointBorderWidthComponent,
    ThemeOptionElementsPointHitRadiusComponent,
    ThemeOptionElementsPointHoverRadiusComponent,
    ThemeOptionElementsPointHoverBackgroundColorComponent,
    ThemeOptionElementsPointHoverBorderColorComponent,
    ThemeOptionElementsPointHoverBorderWidthComponent,
    ThemeOptionElementsBackgroundColorComponent,
    ThemeOptionElementsBorderColorComponent,
    ThemeOptionElementsBorderWidthComponent,
    ThemeOptionElementsFillComponent,
    ThemeOptionElementsLineTensionComponent,
    ThemeOptionElementsHideInLegendAndTooltipComponent,
    ThemeOptionElementsHiddenComponent,
    ThemeOptionElementsSpanGapsComponent,
    ThemeOptionElementsSteppedLineComponent,
    ThemeOptionElementsBorderDashComponent,
    ThemeOptionElementsBorderCapStyleComponent,
    ThemeOptionElementsBorderJoinStyleComponent,
    ThemeOptionElementsHoverBackgroundColorComponent,
    ThemeOptionElementsHoverBorderColorComponent,
    ThemeOptionElementsHoverBorderWidthComponent,
    ThemeOptionElementsHoverBorderDashComponent,
    ThemeOptionElementsChartCutoutPercentageComponent,
    ThemeOptionElementsChartCircumferenceComponent,
    ThemeOptionElementsChartRotationComponent,
    ThemeOptionElementsIconFrameComponent,
    ThemeOptionElementsBorderAlignComponent,
    ThemeOptionElementsWeightComponent,
    ThemeOptionElementsDatalabelsIdComponent,
    ThemeOptionElementsXAxisIDComponent,
    ThemeOptionElementsYAxisIDComponent,
    ThemeOptionDatalabelsAnchorComponent,
    ThemeOptionDatalabelsAlignComponent,
    ThemeOptionDatalabelsClampComponent,
    ThemeOptionDatalabelsDisplayComponent,
    ThemeOptionDatalabelsOffsetComponent,
    ThemeOptionDatalabelsRotationComponent,
    ThemeOptionDatalabelsClipComponent,
    ThemeOptionDatalabelsBorderWidthComponent,
    ThemeOptionDatalabelsBorderColorComponent,
    ThemeOptionDatalabelsBorderRadiusComponent,
    ThemeOptionDatalabelsPaddingComponent,
    ThemeOptionDatalabelsBackgroundColorComponent,
    ThemeOptionDatalabelsColorComponent,
    ThemeOptionDatalabelsFontFamilyComponent,
    ThemeOptionDatalabelsFontSizeComponent,
    ThemeOptionDatalabelsFontSizeComponent,
    ThemeOptionDatalabelsFontLineHeightComponent,
    ThemeOptionDatalabelsOpacityComponent,
    ThemeOptionDatalabelsTextAlignComponent,
    ThemeOptionDatalabelsTextStrokeColorComponent,
    ThemeOptionDatalabelsTextStrokeWidthComponent,
    ThemeOptionDatalabelsTextShadowBlurComponent,
    ThemeOptionDatalabelsTextShadowColorComponent,
    ThemeOptionAxesDisplayComponent,
    ThemeOptionAxesBarPercentageComponent,
    ThemeOptionAxesCategoryPercentageComponent,
    ThemeOptionAxesMinBarLengthComponent,
    ThemeOptionAxesPositionComponent,
    ThemeOptionAxesGridLinesDisplayComponent,
    ThemeOptionAxesGridLinesCircularComponent,
    ThemeOptionAxesGridLinesColorComponent,
    ThemeOptionAxesGridLinesBorderDashComponent,
    ThemeOptionAxesGridLinesLineWidthComponent,
    ThemeOptionAxesGridLinesDrawBorderComponent,
    ThemeOptionAxesGridLinesDrawTicksComponent,
    ThemeOptionAxesGridLinesTickMarkLengthComponent,
    ThemeOptionAxesTicksBeginAtZeroComponent,
    ThemeOptionAxesTicksDisplayComponent,
    ThemeOptionAxesTicksLineHeightComponent,
    ThemeOptionAxesTicksFontFamilyComponent,
    ThemeOptionAxesTicksFontColorComponent,
    ThemeOptionAxesTicksFontSizeComponent,
    ThemeOptionAxesTicksFontStyleComponent,
    ThemeOptionAxesTicksPaddingComponent,
    ThemeOptionTitleDisplayComponent,
    ThemeOptionAxesTitleLineHeightComponent,
    ThemeOptionAxesTitleFontFamilyComponent,
    ThemeOptionAxesTitleFontColorComponent,
    ThemeOptionAxesTitleFontSizeComponent,
    ThemeOptionAxesTitleFontStyleComponent,
    ThemeOptionAxesTitlePaddingComponent,
    ThemeOptionElementsChartTransitionAnimationComponent,
    ThemeOptionElementsChartGaugeColorComponent,
    ThemeOptionElementsChartGaugeShadowOpacityComponent,
    ThemeOptionElementsChartGaugeShadowSizeComponent,
    ThemeOptionElementsChartGaugeShadowVerticalOffsetComponent,
    ThemeOptionElementsChartGaugeShadowShowInnerComponent,
    ThemeOptionElementsChartGaugePointerDisplayComponent,
    ThemeOptionElementsChartGaugePointerTopLengthComponent,
    ThemeOptionElementsChartGaugePointerBottomLengthComponent,
    ThemeOptionElementsChartGaugePointerBottomWidthComponent,
    ThemeOptionElementsChartGaugePointerStrokeComponent,
    ThemeOptionElementsChartGaugePointerStrokeWidthComponent,
    ThemeOptionElementsChartGaugePointerStrokeLineCapComponent,
    ThemeOptionElementsChartGaugePointerColorComponent,
    ThemeOptionElementsChartGaugeWidthScaleComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
