/**
 * @module Widget
 */

import { Component, Input, SimpleChanges, OnChanges, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import * as JustGage from 'JustGage';
import { WidgetChartParamsModel } from '@models/widgetLayers.model';
import { UID } from '@models/uid.model';
import { first } from 'rxjs/operators';
import { fromEvent, interval } from 'rxjs';

@Component({
  selector: 'app-widget-studio-chart-gauge',
  templateUrl: './widget-studio-chart-gauge.component.html',
  styleUrls: ['./widget-studio-chart-gauge.component.css']
})
export class WidgetStudioChartGaugeComponent implements OnChanges, AfterViewInit {

  @Input() params: WidgetChartParamsModel;
  @Input() uid: UID;
  @ViewChild('gauge') gaugeNode: ElementRef;

  gauge: JustGage;
  height: number = 0;
  width: number = 0;

  constructor() {}
  
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.params !== undefined && !changes.params.firstChange) this.setGauge();
  }

  onResize() {
    const gauge = this.gaugeNode.nativeElement;
    const subscription = fromEvent(gauge, 'DOMSubtreeModified')
    .pipe(first())
    .subscribe(() => {
      const svgElement = gauge.querySelector('svg');
      if (svgElement) {
        if(gauge.offsetHeight > 0) svgElement.setAttribute('height', `${ gauge.offsetHeight }px`);
        if(gauge.offsetWidth > 0) svgElement.setAttribute('width', `${ gauge.offsetWidth }px`);
        subscription.unsubscribe();
      }
    });

    const timeout = interval(1000)
    .pipe(first())
    .subscribe(() => {
      subscription.unsubscribe();
      timeout.unsubscribe();
    });
  }

  ngAfterViewInit() {
    this.onResize();
    window.addEventListener('resize', this.onResize);
    this.setGauge();
  }

  setGauge() {
    if(this.gauge) this.gauge.destroy();
    this.params.gauge.parentNode = this.gaugeNode.nativeElement;
    this.gauge = new JustGage(this.params.gauge);
  }

}
