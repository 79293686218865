/**
 * @module Form
 */

import { Component, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.css']
})
export class EditorComponent {

  @Input() title: string;
  @Input() uid: string;
  @Input() icon: string; //style+symbol
  @Input() color: string; //name
  @Input() iconColor: string; //hexa
  @Input() isRegistered: boolean;
  @Input() isValid: boolean;
  @Input() isModified: boolean;
  @Input() isSaveable: boolean = true;

  @Output() sendSave = new EventEmitter<void>();
  @Output() sendClose = new EventEmitter<void>();
  @Output() sendRestore = new EventEmitter<void>();
  @Output() sendDelete = new EventEmitter<void>();

  @ViewChild('editorModal', { static: true }) editor: ModalDirective; 

  constructor() {}

  public show() {
    this.editor.show();
  }

  public hide() {
    this.editor.hide();
  }

  public get isShown(): Readonly<boolean> {
    return this.editor.isShown;
  }

  onSave() {
    this.editor.hide();
    this.sendSave.emit();
  }

  onClose() {
    this.editor.hide();
    this.sendClose.emit();
  }

  onRestore() {
    this.sendRestore.emit();
  }

  onDelete() {
    this.sendDelete.emit();
  }

}
