/**
 * @module Server
 */

import { Injectable, OnDestroy } from '@angular/core';
import { AuthService } from '@services/auth/auth.service';
import { ENVIRONMENT } from '@consts/params.const';
import * as jwtDecode from 'jwt-decode';
import { ServerUrlModel } from '@models/server.model';
import { TokenDataModel } from '@models/token.model';
import { HttpClient } from '@angular/common/http';
import { CompanyService } from '@services/company/company.service';
import { Subscription } from 'rxjs';
import { UserClass } from '@class/user.class';
import { HandleErrors } from '@functions/errors.functions';

/**
 * @interface TokenModel
 */

@Injectable({
  providedIn: 'root'
})
export class TokenService implements OnDestroy {

  /**
   * @description Liste des token par serveur avec date d'expiration
   * @private
   * @type {{ [key: string]: TokenDataModel }}
   * @memberof TokenService
   */
  private tokens: { [key: string]: TokenDataModel };

  /**
   * @description souscription aux changements de user
   * @private
   * @type {Subscription}
   * @memberof TokenService
   */
  private user$sub: Subscription;

  /**
   * @description Creates an instance of ServersApiService
   * @param {FirebaseService} $firebase
   * @param {AuthService} $auth
   * @memberof ServersApiService
   */
  constructor(
    private $auth: AuthService,
    private $http: HttpClient,
    private $company: CompanyService
  ) {
    this.tokens = {};

    this.user$sub = this.$auth.user$.subscribe((user: UserClass) => {
      if (!user) {
        this.tokens = {};
      }
    });

    const storedValues = JSON.parse(
      localStorage.getItem('tokensData')
    ) as { [key: string]: TokenDataModel };
    
    if(storedValues) {
      this.tokens = storedValues;
    }
  }

  /**
   * @description destructeur du service
   * @memberof TokenService
   */
  ngOnDestroy() {
    this.user$sub.unsubscribe();
  }

  /**
   * @description Renvoi le token si celui-ci existe sinon renvoi null
   * @private
   * @param {ServerUrlModel} server
   * @returns {TokenDataModel}
   * @memberof TokenService
   */
  private storedToken(server: ServerUrlModel): TokenDataModel {
    if(this.tokens[server.uid] !== undefined) {
      const decoded = jwtDecode(this.tokens[server.uid].token);
      if(this.$auth.token$.value.claims.issuedAtTime > decoded.iat) return null; //les claims ont été mis à jour après la création du tokenData
      else return this.tokens[server.uid] !== undefined && this.tokens[server.uid].maxAge > (Date.now() + 10000) ? this.tokens[server.uid] : null;
    }
    else {
      return null;
    }
  }

  /**
   * @description Renvoi le token pour le serveur précisé en paramètre
   * @param {ServerUrlModel} server
   * @returns {Promise<{ token: TokenDataModel }>}
   * @memberof ServersApiService
   */
  async get(server: ServerUrlModel): Promise<TokenDataModel> {

    let token = this.storedToken(server);
    if(token !== null) {
      return new Promise<TokenDataModel>((resolve, reject) => {
        if(ENVIRONMENT.type !== 'production') {
          console.groupCollapsed('[servers-api/getToken] success');
          console.log('token retreived from local storage');
          console.groupEnd();
        }
        resolve(token);
      });
    }
    else {
      return new Promise<TokenDataModel>((resolve, reject) => {
        this.$http.post(
          `https://${this.$company.region}-${this.$company.code}.cloudfunctions.net/tokens/token`,
          null,
          {
            headers: {
              authorization: this.$auth.token$.value.token
            }
          }
        )
        .pipe(
          HandleErrors(3, 20000)
        )
        .toPromise()
        .then((result: TokenDataModel) => {
          if(result === undefined || result.token === undefined) {
            if(ENVIRONMENT.type !== 'production') {
              console.groupCollapsed('[servers-api/getToken] error');
              console.log('token undefined');
              console.groupEnd();
            }
            reject('GET_TOKEN_ERROR');
          }
          else {
            if(ENVIRONMENT.type !== 'production') {
              console.groupCollapsed('[servers-api/getToken] success');
              console.log('token retreived from server');
              console.groupEnd();
            }
            this.tokens[server.uid] = result;
            localStorage.setItem('tokensData', JSON.stringify(this.tokens));
            resolve(result);
          }
        })
        .catch(error => {
          if(ENVIRONMENT.type !== 'production') {
            console.groupCollapsed('[servers-api/getToken] error');
            console.log(error);
            console.groupEnd();
          }
          reject('GET_TOKEN_ERROR');
        })
      });
    }
  }

}
