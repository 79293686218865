/**
 * @module Import
 */

import { Injectable, OnDestroy } from '@angular/core';
import { ImportFrameWorker, ImportDataFrameModel } from '@models/worker.model';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { DataFrameModel } from '@models/dataFrame.model';
import { DataFrame } from 'data-forge';
import { DataFrameClass } from '@class/dataFrame.class';
import { ServerLinkClass } from '@class/server.class';
import { ImportFrameClass } from '@class/importFrame.class';
import { DateService } from '@services/date/date.service';
import { PivotService } from '@services/pivot/pivot.service';
import { IndicatorService } from '@services/indicator/indicator.service';
import { SetLabels } from '@functions/labels.functions';
import { map, take, tap } from 'rxjs/operators';
import { IndicatorClass } from '@class/indicator.class';
import { PivotClass } from '@class/pivot.class';
import { DateClass } from '@class/date.class';
import { ServerResponseModel } from '@models/server.model';
import { ImportFrameUploadModel } from '@models/importFrame.model';
import { AuthService } from '@services/auth/auth.service';
import { CreateRandomUid } from '@functions/uid.functions';
import { UID } from '@models/uid.model';
import { isArray } from 'util';
import { UidService } from '@services/uid/uid.service';
import { BackendDocumentsService, CHUNCK_IMPORT_FRAME_SIZE } from '@services/backend/backendDocuments/backend-documents.service';
import { BackendIndexesService } from '@services/backend/backendIndexes/backend-indexes.service';

interface linkedModel { 
  importFrame: ImportFrameClass;
  linkedColumns: { [key: string]: any };
  key: string;
}

@Injectable({
  providedIn: 'root'
})
export class ImportService implements OnDestroy {

  private _server: ServerLinkClass;
  private _worker: ImportFrameWorker;
  private _worker$: BehaviorSubject<ImportFrameWorker>;
  private _workerUid: UID;

  private _wReadFile = new Worker('@workers/importFrames/w-read-file.worker', { type: 'module' });
  private _wDetectTypes = new Worker('@workers/importFrames/w-detect-types.worker', { type: 'module' });
  private _wSetupFrames = new Worker('@workers/importFrames/w-setup-frames.worker', { type: 'module' });

  constructor(
    private $date: DateService,
    private $pivot: PivotService,
    private $indicator: IndicatorService,
    private $auth: AuthService,
    private $documents: BackendDocumentsService,
    private $indexes: BackendIndexesService,
    private $uid: UidService
  ) {
    this._worker = {
      percent: 0,
      step: 'wReadFile',
      status: 'wSleeping',
      uid: CreateRandomUid()
    };
    this._worker$ = new BehaviorSubject<ImportFrameWorker>(this._worker);

    this._wReadFile.onmessage = ({ data }) => {
      if(data.uid === this._workerUid) {
        this._worker = data as ImportFrameWorker;
        this._worker$.next(this._worker);
        if(this._worker.status === 'wComplete') this._wDetectTypes.postMessage({ values: this._worker.values, uid: this._workerUid });
      }
    };

    this._wDetectTypes.onmessage = ({ data }) => {
      if(data.uid === this._workerUid) {
        this._worker = data as ImportFrameWorker;
        this._worker$.next(this._worker);
        if(this._worker.status === 'wComplete') {
          this._worker.step = 'wSelectScope';
          this._worker.status = 'wWaitingUser';
          this._worker$.next(this._worker);
        }
      }
    };

    this._wSetupFrames.onmessage = ({ data }) => {
      if(data.uid === this._workerUid) {
        this._worker = data as ImportFrameWorker;
        this._worker$.next(this._worker);
        if(this._worker.status === 'wComplete') {
          let dataFrames = this._worker.values as DataFrameModel[];
          dataFrames = dataFrames.map((dataFrame: DataFrameModel) => {
            //le worker ne gère pas le type dataFrame, renvoi un tableau de valeurs
            dataFrame.dataFrame = new DataFrame(dataFrame.dataFrame);
            return dataFrame;
          });
          this.setupLinks(dataFrames);
        }
      }
    };
  }

  private async setupLinks(dataFramesModel: DataFrameModel[]) {

    const workerUid = this._workerUid;

    this._worker = {
      percent: 0,
      step: 'wSetupLinks',
      status: 'wWorking',
      completed: 0,
      toComplete: 0,
      values: [],
      error: undefined,
      uid: this._workerUid
    } as ImportFrameWorker;
    if(workerUid === this._workerUid) this._worker$.next(this._worker);
    else return;

    let dataFrames = [] as DataFrameClass[];
    dataFramesModel.forEach((dataFrameModel: DataFrameModel) => {
      let dataFrame = new DataFrameClass(dataFrameModel, this._server)
      dataFrames.push(dataFrame);
      this._worker.toComplete += (dataFrame.keysString.length + dataFrame.keysDate.length + 2);
    });

    //création d'UID identiques pour les pivots et dates au sein d'un même groupe pour ne pas enregistrer en doublons dans Firebase
    let uids = {} as { [key: string]: { pivots: { [key: string]: UID }, dates: { [key: string]: UID } } };
    dataFrames.forEach((dataFrame: DataFrameClass) => {
      if(uids[dataFrame.group] === undefined) uids[dataFrame.group] = { pivots: {}, dates: {} };
      dataFrame.keysString.forEach((key: string) => {
        uids[dataFrame.group].pivots[key] = this.$uid.create();
      });
      dataFrame.keysDate.forEach((key: string) => {
        uids[dataFrame.group].dates[key] = this.$uid.create();
      });
    });

    let importFrames = dataFrames.map<ImportFrameClass>((dataFrame: DataFrameClass) => {

      const dates = dataFrame.keysDate.map((key: string) => { 
        const date = this.$date.create({ 
          uid: uids[dataFrame.group].dates[key],
          labels: SetLabels(key),
          linkedColumns: [key],
          dateFormats: [dataFrame.types(key).format]
        }); 
        dataFrame.setUidForKey(key, date.uid.value);
        return date;
      });

      let mainDate: string = undefined;
      if(dates.length > 0) mainDate = dates[0].uid.value;

      this._worker.completed++;
      this._worker.percent = Math.round(this._worker.completed / this._worker.toComplete * 100);
      if(workerUid === this._workerUid) this._worker$.next(this._worker);
      else return;

      return new ImportFrameClass({
        dataFrame: dataFrame,
        indicator: this.$indicator.create({ 
          labels: SetLabels(dataFrame.keyNumber),
          linkedColumns: [dataFrame.keyNumber],
          mainDate: mainDate 
        }, dataFrame.server),
        pivots: dataFrame.keysString.map((key: string) => { 
          const pivot = this.$pivot.create({ 
            uid: uids[dataFrame.group].pivots[key],
            labels: SetLabels(key),
            linkedColumns: [key]
          }); 
          dataFrame.setUidForKey(key, pivot.uid.value);
          return pivot;
        }),
        dates: dates
      });
    });

    const linkedIndicators = await this.checkForLinkedIndicators(importFrames, workerUid);
    if(workerUid !== this._workerUid) return;
    await this.setIndicators(linkedIndicators);
    for(let i = 0 ; i < importFrames.length ; i++) {
      const linkedPivots = await this.checkForLinkedPivots(importFrames[i], workerUid);
      if(workerUid !== this._workerUid) return;
      if(linkedPivots) await this.setPivots(linkedPivots);
    };
    for(let i = 0 ; i < importFrames.length ; i++) {
      const linkedDates = await this.checkForLinkedDates(importFrames[i], workerUid);
      if(workerUid !== this._workerUid) return;
      if(linkedDates) await this.setDates(linkedDates);
    };

    this._worker.values = importFrames;
    this._worker.step = 'wManageFrames';
    this._worker.status = 'wWaitingUser';
    if(workerUid === this._workerUid) this._worker$.next(this._worker);
    else return;
  }

  ngOnDestroy() {
    this._worker$.unsubscribe();
  }

  get worker$(): Readonly<BehaviorSubject<ImportFrameWorker>> {
    return this._worker$;
  }

  processFile(file: File) {
    this.flush();
    this._wReadFile.postMessage({ file: file, uid: this._workerUid });
  }

  processFrames(dataFrames: { [key: string]: ImportDataFrameModel }, server: ServerLinkClass) {
    this._server = server;
    this._wSetupFrames.postMessage({ dataFrames: dataFrames, uid: this._workerUid });
  }

  private isValuesImportFramesClass(): boolean {
    return isArray(this._worker.values) && this._worker.values.length > 0 && this._worker.values[0] instanceof ImportFrameClass;
  }

  removeImportFrame(uid: UID): ImportFrameClass[] {
    if(this.isValuesImportFramesClass()) {
      let index = this._worker.values.map((value: ImportFrameClass) => value.uid).indexOf(uid);
      if(index !== -1) {
        this._worker.values.splice(index, 1);
        if(this._worker.values.length > 0) {
          this._worker$.next(this._worker);
          return this._worker.values;
        }
        else {
          this.flush();
          return [];
        }
        
      }
      else return [];
    }
    else return [];
  }

  flush() {
    this._worker = {
      percent: undefined,
      step: 'wReadFile',
      status: 'wSleeping',
      values: undefined,
      uid: CreateRandomUid()
    };
    this._workerUid = this._worker.uid;
    this._worker$.next(this._worker);
  }

  private async checkForLinkedIndicators(importFrames: ImportFrameClass[], workerUid: UID): Promise<linkedModel[]> {
    return combineLatest(importFrames.map((importFrame: ImportFrameClass) => {
      return this.$indicator.linkedColumns(importFrame.dataFrame.keyNumber).pipe(
        map((linkedColumns: { [key: string]: any }) => {
          this._worker.completed++;
          this._worker.percent = Math.round(this._worker.completed / this._worker.toComplete * 100);
          if(workerUid === this._workerUid) this._worker$.next(this._worker);
          return {
            importFrame: importFrame,
            linkedColumns: linkedColumns,
            key: importFrame.dataFrame.keyNumber
          }
        })
      );
    })).pipe(
      take(1)
    ).toPromise();
  }

  private async checkForLinkedPivots(importFrame: ImportFrameClass, workerUid: UID): Promise<linkedModel[]> {
    return combineLatest(importFrame.dataFrame.keysString.map((key: string) => {
      return this.$pivot.linkedColumns(key).pipe(
        map((linkedColumns: { [key: string]: any }) => {
          this._worker.completed++;
          this._worker.percent = Math.round(this._worker.completed / this._worker.toComplete * 100);
          if(workerUid === this._workerUid) this._worker$.next(this._worker);
          return {
            importFrame: importFrame,
            linkedColumns: linkedColumns,
            key: key
          }
        })
      );
    })).pipe(
      take(1)
    ).toPromise();
  }

  private async checkForLinkedDates(importFrame: ImportFrameClass, workerUid: UID): Promise<linkedModel[]> {
    return combineLatest(importFrame.dataFrame.keysDate.map((key: string) => {
      return this.$date.linkedColumns(key).pipe(
        map((linkedColumns: { [key: string]: any }) => {
          this._worker.completed++;
          this._worker.percent = Math.round(this._worker.completed / this._worker.toComplete * 100);
          if(workerUid === this._workerUid) this._worker$.next(this._worker);
          return {
            importFrame: importFrame,
            linkedColumns: linkedColumns,
            key: key
          }
        })
      );
    })).pipe(
      take(1)
    ).toPromise();
  }

  private async setIndicators(results: linkedModel[]) {
    combineLatest(results
      .filter((result: linkedModel) => {
        //l'indicateur doit être sur le même serveur de données
        return !!result.linkedColumns && Object.values(result.linkedColumns).indexOf(result.importFrame.indicator.serverLink.uid) !== -1;
      })
      .map((result: linkedModel) => {
        //on prend le premier de la liste présent sur le même serveur de données
        const index = Object.values(result.linkedColumns).indexOf(result.importFrame.indicator.serverLink.uid);
        return this.$indicator.get$(Object.keys(result.linkedColumns)[index]).pipe(
          tap((indicator: IndicatorClass) => {
            result.importFrame.indicator = indicator;
          })
        );
      })
    ).pipe(
      take(1)
    ).toPromise();
  }

  private async setPivots(results: linkedModel[]) {
    combineLatest(results
      .filter((result: linkedModel) => {
        return !!result.linkedColumns;
      })
      .map((result: linkedModel) => {
        //on prend le premier de la liste présent sur le même serveur de données
        return this.$pivot.get$(Object.keys(result.linkedColumns)[0]).pipe(
          tap((pivot: PivotClass) => {
            result.importFrame.setPivot(result.importFrame.dataFrame.uidForKey(result.key), pivot);
          })
        );
      })
    ).pipe(
      take(1)
    ).toPromise();
  }

  private async setDates(results: linkedModel[]) {
    combineLatest(results
      .filter((result: linkedModel) => {
        return !!result.linkedColumns;
      })
      .map((result: linkedModel) => {
        //on prend le premier de la liste présent sur le même serveur de données
        return this.$date.get$(Object.keys(result.linkedColumns)[0]).pipe(
          tap((date: DateClass) => {
            date.dateFormats.add(result.importFrame.dataFrame.types(result.key).format);
            result.importFrame.setDate(result.importFrame.dataFrame.uidForKey(result.key), date);
          })
        );
      })
    ).pipe(
      take(1)
    ).toPromise();
  }

  private async saveObjects(importFrame: ImportFrameClass) {
    for(const uid in importFrame.pivots) {
      importFrame.indicator.links.add('pivots', importFrame.pivots[uid].uid.value);
      await this.$pivot.set(importFrame.pivots[uid]);
    }
    for(const uid in importFrame.dates) {
      importFrame.indicator.links.add('dates', importFrame.dates[uid].uid.value);
      await this.$date.set(importFrame.dates[uid]);
    };
    await this.$indicator.set(importFrame.indicator);
  }

  uploadAll(): Readonly<{ importFrame: ImportFrameClass, obs: Observable<ImportFrameUploadModel> }[]> {
    let streams = [];
    if(this.isValuesImportFramesClass()) {
      this._worker.values.forEach((importFrame: ImportFrameClass) => {
        if(!importFrame.dataFrame.isErrors) {
          streams.push({
            importFrame: importFrame,
            obs: this.upload(importFrame, importFrame.getUpload().isErrors)
          });
        }
      });
    }
    return streams;
  }

  upload(importFrame: ImportFrameClass, onlyErrors: boolean = false): Readonly<Observable<ImportFrameUploadModel>> {

    this.saveObjects(importFrame);

    const rows = importFrame.rows(this.$auth.user$.value.uid.value, onlyErrors);

    let upload = {
      lines: {
        total: rows.length,
        uploaded: 0,
        errors: 0
      },
      chuncks: {
        total: Math.ceil(rows.length / CHUNCK_IMPORT_FRAME_SIZE),
        uploaded: 0
      },
      isUploading: true,
      isEnded: false,
      isErrors: false,
      progress: 0,
      errors: 0,
      uidsErrors: []
    };

    importFrame.setUpload(upload);

    this.$documents.post(importFrame.indicator.uid.value ,rows, importFrame.indicator.serverLink).subscribe({
      next: (responses: ServerResponseModel) => {
        if(!!responses) {
          upload.chuncks.uploaded++;

          if(responses.success) {
            upload.lines.uploaded = upload.lines.uploaded + responses.message.length;
            upload.progress = Math.round(upload.lines.uploaded / upload.lines.total * 100);
          }
          else {
            upload.lines.errors = upload.lines.errors + responses.message.length;
            upload.errors = Math.round(upload.lines.errors / upload.lines.total * 100);
            upload.isErrors = true;
            upload.uidsErrors = upload.uidsErrors.concat(responses.message);
          }

          if(upload.chuncks.uploaded === upload.chuncks.total) {
            upload.isUploading = false;
            upload.isEnded = true;
            /*this.$indexes.post(
              importFrame.indicator.uid.value,
              importFrame.indicator.mainDate,
              importFrame.indexes,
              importFrame.indicator.serverLink
            );*/
          }
          importFrame.setUpload(upload);
        }
      },
      error: () => {
        upload.chuncks.uploaded++;
        if(upload.chuncks.uploaded === upload.chuncks.total) {
          upload.isUploading = false;
          upload.isEnded = true;
        }
        upload.isErrors = true;
        importFrame.setUpload(upload);
      }
    });

    return importFrame.uploadStream$;
  }

}