/**
 * @module Auth
 */

import { Injectable, OnDestroy } from '@angular/core';
import { of, BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { TokenModel } from '@models/token.model';
import { UserModel } from '@models/user.model';
import { ENVIRONMENT } from '@consts/params.const';
import { FirebaseService } from '@services/firebase/firebase.service';
import { LanguageType } from '@models/language.model';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { MessagesService } from '@services/messages/messages.service';
import { UserClass } from '@class/user.class';
import { AngularFirestoreDocument } from '@angular/fire/firestore';
import { RoleType } from '@models/role.model';
import { RolesCodes } from '@functions/role.functions';
import { Numbro } from '@functions/numbro.functions';
import { Moment } from '@functions/moment.functions';
import { BookmarksModel } from '@models/bookmarks.model';
import { ResourceType } from '@models/resources.model';
import { LinkableType } from '@models/links.model';
import { UID } from '@models/uid.model';
import { ResourceTypes } from '@functions/resource.functions';
import { DeepCopy } from '@functions/copy.functions';
import { ServerUrlModel } from '@models/server.model';
import { HandleErrors } from '@functions/errors.functions';
import { PivotFiltersClass } from '@class/widgetFilters.class';
import { OffsetDateModel } from '@models/widgetLayers.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {

  /**
   * @description Souscription au service de suivi de l'état de l'authentification
   * @type {Subscription}
   * @memberof AuthService
   */
  private authState$sub: Subscription;

  /**
   * @description Observateur de l'utilisateur connecté, renvoie une mise à jour quelque soit le changement
   * @type {BehaviorSubject<UserClass>}
   * @memberof AuthService
   */
  user$: BehaviorSubject<UserClass>;

  /**
   * @description Observateur de l'utilisateur connecté, renvoie une mise à jour en cas de changement sur les resources ou rôles
   * @type {BehaviorSubject<UserClass>}
   * @memberof AuthService
   */
  resources$: BehaviorSubject<UserClass>;

  /**
   * @description Observateur de la connexion de l'utilisateur
   * @type {BehaviorSubject<boolean>}
   * @memberof AuthService
   */
  connected$: BehaviorSubject<boolean>;

  /**
   * @description Observateur de la langue courante, égal à null si aucun utilisateur n'est connecté
   * @type {BehaviorSubject<LanguageType>}
   * @memberof AuthService
   */
  language$: BehaviorSubject<LanguageType>;

  /**
   * @description Observateur du token et de l'utilisateur connecté, égal à null si aucun utilisateur n'est authentifié
   * @type {BehaviorSubject<TokenModel>}
   * @memberof AuthService
   */
  token$: BehaviorSubject<TokenModel>;

  /**
   * @description Observateur de la tentative de reconnexion automatique de l'utilisateur
   * @type {BehaviorSubject<boolean>}
   * @memberof AuthService
   */
  reconnection$: BehaviorSubject<boolean>;

  /**
   * @description Observateur du serveur de données par défaut
   * @type {BehaviorSubject<ServerUrlModel>}
   * @memberof AuthService
   */
  server$: BehaviorSubject<ServerUrlModel>;

  offsetDate$: BehaviorSubject<OffsetDateModel>;

  /**
   * @description Observateur de la connexion, égal à null si aucun utilisateur n'est connecté
   * @private
   * @type {*}
   * @memberof AuthService
   */
  private connection$: any;

  /**
   * @description promesse pour les timers
   * @type {*}
   * @memberof AuthService
   */
  private promise: any;

  /**
   * @description Souscription au serveur par défaut
   * @type {Subscription}
   * @memberof AuthService
   */
  private server$sub: Subscription;

  /**
   * @description Creates an instance of AuthService
   * @param {FirebaseService} $firebase
   * @param {TranslateService} $translate
   * @param {Router} $router
   * @param {MessagesService} $messages
   * @memberof AuthService
   */
  constructor(
    private $firebase: FirebaseService,
    private $translate: TranslateService,
    private $router: Router,
    private $messages: MessagesService
  ) {

    this.user$ = new BehaviorSubject<UserClass>(undefined);
    this.resources$ = new BehaviorSubject<UserClass>(undefined);
    this.language$ = new BehaviorSubject<LanguageType>(undefined);
    this.connected$ = new BehaviorSubject<boolean>(true);
    this.token$ = new BehaviorSubject<TokenModel>(undefined);
    this.reconnection$ = new BehaviorSubject<boolean>(undefined);
    this.server$ = new BehaviorSubject<ServerUrlModel>(undefined);
    this.offsetDate$ = new BehaviorSubject<OffsetDateModel>(undefined);

    //écoute le statut de la connexion
    const connected$ = this.$firebase.angularFirebase.database.ref('.info/connected');
    connected$.on('value', snapshot => {
      this.connected$.next(snapshot.val() === true);
    });
    
    //écoute des changements sur l'utilisateur connecté
    this.authState$sub = this.$firebase.angularFireAuth.authState.pipe(
      switchMap(user => {
        if(user) {
          this.reconnection$.next(true);
          return combineLatest(
            this.$firebase.angularFirestore.doc<UserModel>(`users/${user.uid}`).valueChanges(),
            this.$firebase.angularFirestore.doc<UserModel>(`public/publicId`).valueChanges(),
            this.$firebase.angularFirestore.doc<UserModel>(`users/${user.uid}/bookmarks/bookmarksId`).valueChanges(),
            this.$firebase.angularFirebase.object<ServerUrlModel>(`main/server`).valueChanges(),
            this.$firebase.angularFirebase.object<OffsetDateModel>(`offsetDate`).valueChanges()
          );
        }
        else {
          this.reconnection$.next(false);
          return of(null);
        }
      })
    ).subscribe((users: UserModel[]) => {
console.log(users)
      const offsetDate = !!users ? users.pop() as unknown as OffsetDateModel : undefined;
      if(!!offsetDate) this.offsetDate$.next(offsetDate);
      else this.offsetDate$.next({ amount: 0, frequency: 'M' });

      const server = !!users ? users.pop() as unknown as ServerUrlModel : undefined;
      if(!!server) this.server$.next(server);

      if(users !== null && Object.values(users).filter((user: UserModel) => { return user !== undefined; }).length > 0) {
        let timer = 0;
        if(this.promise) {
          clearTimeout(this.promise);
          timer = 3000;
        }
        this.promise = setTimeout(() => { this.updateResources(users); }, timer);
      }
      else {
        this.user$.next(undefined);
      }
    });

    //écoute des changements sur la connexion de l'utilisateur authentifié
    this.connection$ = this.$firebase.angularFireAuth.auth.onIdTokenChanged((user: firebase.User) => {
      if(user !== null) {
        user.getIdTokenResult().then(
          info => {
            this.token$.next({
              token: info.token,
              claims: {
                authTime: info.claims.auth_time,
                expirationTime: info.claims.exp,
                issuedAtTime: info.claims.iat,
                rights: info.claims.rights || [],
                roles: info.claims.roles || []
              },
              user: {
                email: info.claims.email,
                uid: info.claims.user_id
              },
              company: info.claims.aud
            });
            if(ENVIRONMENT.type !== 'production') {
              console.groupCollapsed(`Token Streaming`);
              console.log('[user]');
              console.log('[.email]', this.token$.value.user.email);
              console.log('[.uid]', this.token$.value.user.uid);
              console.log('[claims]');
              console.log('[.rights]', this.token$.value.claims.rights);
              console.log('[.roles]', this.token$.value.claims.roles);
              console.log('[.authTime]', new Date(this.token$.value.claims.authTime * 1000));
              console.log('[.expirationTime]', new Date(this.token$.value.claims.expirationTime * 1000));
              console.log('[.issuedAtTime]', new Date(this.token$.value.claims.issuedAtTime * 1000));
              console.log('[company]', this.token$.value.company);
              console.groupEnd();
            }
          }
        )
        .catch(() => {
          this.token$.next(null);
        });
      }
      else {
        this.token$.next(null);
      }
    });
  
  }

  /**
   * @description met à jour les ressources de l'utilisateur connecté et les émets
   * @private
   * @param {UserModel[]} users
   * @memberof AuthService
   */
  private async updateResources(users: UserModel[]) {
    let _users = users.map((user: UserModel) => {
      return DeepCopy(user);
    });

    if(!_users[0].activated) {
      this.reconnection$.next(false);
      this.user$.next(undefined);
      this.$router.navigate(['/']).then(() => {
        this.$firebase.angularFireAuth.auth.signOut().then(() => {
          this.$messages.warning({ text: { code: 'YOUR_ACCOUNT_SUSPENDED/TEXT' }});
        });
      });
    }
    else {

      let user = new UserClass(_users[0], true);

      //public resources
      if(_users[1] !== undefined) {
        let pub = new UserClass(_users[1], true);
        ResourceTypes().forEach((resource: ResourceType) => {
          pub.resources.list(resource).forEach((uid: UID) => {
            user.resources.add(resource, uid);
          });
        });
      }

      //connected user bookmarks
      if(_users[2] !== undefined) {
        for(const type in _users[2].bookmarks) {
          for(const uid in _users[2].bookmarks[type]) {
            user.bookmarks.add(type as LinkableType, uid, false);
          }
        }
      }

      if(this.resources$.value === undefined || this.isUpdateNeeded(this.user$.value, user)) {
        if(this.user$.value !== undefined) {
          await this.addClaims(_users[0].email);
          await this.refreshToken();
        }
        this.resources$.next(user);
      }
      this.user$.next(user);
      this.setLanguage(user.language);
      
    }

  }

  /**
   * @description met à jour la langue utlisée pour l'utilisateur courant dans cette session et l'émet
   * @private
   * @param {LanguageType} language
   * @memberof AuthService
   */
  private setLanguage(language: LanguageType) {
    Moment().locale(language);
    Numbro(language);
    this.$translate.use(language);
    localStorage.setItem('language', language);
    this.language$.next(language);
  }

  /**
   * @description détermine si une mise à jour des ressources ou des rôles a été opérée (renvoie True)
   * @private
   * @param {UserClass} userPrevious
   * @param {UserClass} userCurrent
   * @returns {boolean}
   * @memberof AuthService
   */
  private isUpdateNeeded(userPrevious: UserClass, userCurrent: UserClass): boolean {
    let need = false;

    //vérification que les uids sont différents
    if(userPrevious === undefined || userPrevious.uid.value !== userCurrent.uid.value) return true;

    //vérification que les rôles sont différents
    RolesCodes().forEach((role: RoleType) => {
      if(userPrevious.roles.has(role) !== userCurrent.roles.has(role)) need = true;
    });
    if(need) return need;

    //vérification que les droits sont différents
    userPrevious.groups.list.forEach((uid: UID) => {
      if(!userCurrent.groups.has(uid)) need = true;
    });
    if(need) return need;
    userCurrent.groups.list.forEach((uid: UID) => {
      if(!userPrevious.groups.has(uid)) need = true;
    });
    if(need) return need;

    //vérification que les filtres sont différents
    userPrevious.filters.forEach((filter: PivotFiltersClass) => {
      if(!need) {
        if(userCurrent.filters.map(f => f.uid.value).indexOf(filter.uid.value) === -1) {
          need = true;
        }
      }
    });
    if(need) return need;

    userCurrent.filters.forEach((filter: PivotFiltersClass) => {
      if(!need) {
        if(userPrevious.filters.map(f => f.uid.value).indexOf(filter.uid.value) === -1) {
          need = true;
        }
      }
    });
    if(need) return need;

    //vérification que les ressources sont différentes
    let allCurrent = userCurrent.resources.all();
    allCurrent.forEach((resource: ResourceType) => {
      if(!need) {
        const list = userCurrent.resources.list(resource);
        list.forEach((uid: UID) => {
          if(!need) {
            if(!userPrevious.resources.has(resource, uid)) {
              need = true;
            }
          }
        });
      }
    });
    if(need) return need;

    let allPrevious = userCurrent.resources.all();
    allPrevious.forEach((resource: ResourceType) => {
      if(!need) {
        const list = userPrevious.resources.list(resource);
        list.forEach((uid: UID) => {
          if(!need) {
            if(!userCurrent.resources.has(resource, uid)) {
              need = true;
            }
          }
        });
      }
    });

    return need;
  }

  /**
   * @description Destructeur du service
   * @memberof AuthService
   */
  ngOnDestroy() {
    if(this.server$sub !== undefined) this.server$sub.unsubscribe();
    this.server$.unsubscribe();
    this.user$.unsubscribe();
    this.resources$.unsubscribe();
    this.language$.unsubscribe();
    this.token$.unsubscribe();
    this.authState$sub.unsubscribe(); 
    this.connection$(); //unsubscribe de la connexion à Firestore
  }

  /**
   * @description Connecte l'utilisateur à la base avec un email et un mot de passe et renvoie le résultat de l'enregistrement ou de la mise à jour de l'utilisateur dans la base (cf. updateUserLastConnection)
   * @param {string} email
   * @param {string} password
   * @returns {Promise<void>}
   * @memberof AuthService
   */
  async emailSignIn(email: string, password: string): Promise<any> {
    if(this.token$.value !== null) await this.addClaims(email);
    return this.$firebase.angularFireAuth.auth.signInWithEmailAndPassword(email, password);
  }

  /**
   * @description Appelle la cloud function de mise à jour des claims pour l'utilisateur dont l'email est en paramètre
   * @param {string} email
   * @returns {Promise<void>}
   * @memberof AuthService
   */
  async addClaims(email: string): Promise<void> {
    return this.$firebase.angularFireFunctions.httpsCallable('addClaims')({ email: email, token: this.token$.value.token })
    .pipe(
      HandleErrors(3, 20000)
    )
    .toPromise()
    .then((result) => {
      if(ENVIRONMENT.type !== 'production') {
        console.groupCollapsed(`Claims Update`);
        console.log(result);
        console.groupEnd();
      }
    });
  }

  /**
   * @description Refraichit le token de l'utilisateur courant
   * @returns {Promise<string>}
   * @memberof AuthService
   */
  async refreshToken(): Promise<string> {
    return this.$firebase.angularFireAuth.auth.currentUser.getIdToken(true);
  }

  /**
   * @description Enregistre en base la date de connection de l'utilisateur courant
   * @private
   * @param {UserModel} user
   * @returns {Promise<void>}
   * @memberof AuthService
   */
  async updateLastConnection(uid: UID): Promise<void> {
    const userDoc: AngularFirestoreDocument<UserModel> = this.$firebase.angularFirestore.doc(`users/${uid}`);
    return userDoc.set({ lastConnection: new Date().toISOString() } as UserModel, { merge: true });
  }

  /**
   * @description Enregistre en base la langue par défaut de l'utilisateur courant
   * @param {LanguageType} language
   * @returns {Promise<void>}
   * @memberof AuthService
   */
  async updateLanguage(language: LanguageType): Promise<void> {
    const user = this.user$.value;
    const userDoc: AngularFirestoreDocument<UserModel> = this.$firebase.angularFirestore.doc(`users/${user.uid.value}`);
    this.setLanguage(language);
    return userDoc.set({ language: language } as UserModel, { merge: true });
  }

  /**
   * @description Enregistre en base les bookmarks en paramètres pour l'utilisateur courant
   * @param {BookmarksModel} bookmarks
   * @returns {Promise<void>}
   * @memberof AuthService
   */
  async updateBookmarks(bookmarks: BookmarksModel): Promise<void> {
    const user = this.user$.value;
    const userDoc: AngularFirestoreDocument<UserModel> = this.$firebase.angularFirestore.doc(`users/${user.uid.value}/bookmarks/bookmarksId`);
    return userDoc.set(bookmarks as UserModel, { merge: true });
  }

  /**
   * @description Envoi un courriel de mise à jour du mot de passe pour l'utilisateur passé en paramètre
   * @param {string} email
   * @returns {Promise<void>}
   * @memberof AuthService
   */
  sendPasswordResetEmail(email: string, language: LanguageType): Promise<void> {
    this.$firebase.angularFireAuth.auth.languageCode = language;
    return this.$firebase.angularFireAuth.auth.sendPasswordResetEmail(email);
  }

}