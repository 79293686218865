<app-theme-option [categories]="themeOptions.optionsConfiguration[optionType].categories" [label]="themeOptions.optionsConfiguration[optionType].label" [information]="(themeOptions.axes(axesXY, axesIndex)['categoryPercentage'] * 100 | number:'1.0-0') + '%'" [limitedTo]="limitedTo">
  <div app-theme-option-content>
    <app-show-details-option [showDetails]="showDetails" (sendShowDetails)="receiveShowDetails($event)">
      <div app-show-details-option-content>
        <app-slider [color]="color" [value]="themeOptions.axes(axesXY, axesIndex)['categoryPercentage'] * 100" (sendValue)="receiveValue($event)"></app-slider>
      </div>
    </app-show-details-option>
  </div>
</app-theme-option>

<div *ngIf="showDetails">
  <app-theme-option [categories]="themeOptions.optionsConfiguration[optionType].categoriesAndLabel" [label]="type | chart" [dark]="4" *ngFor="let type of limitedTo" [information]="(themeOptions.axes(axesXY, axesIndex, type)['categoryPercentage'] * 100 | number:'1.0-0') + '%'">
    <div app-theme-option-content>
      <app-slider [value]="themeOptions.axes(axesXY, axesIndex, type)['categoryPercentage'] * 100" (sendValue)="receiveValue($event, type)" [color]="color"></app-slider>
    </div>
  </app-theme-option>
</div>