<app-widget-option [categories]="['INDICATORS']" [label]="'ADD'">
  <div app-widget-option-content>
   <button mdbBtn (click)="indicatorsModal.show()" type="button" color="light" size="sm" class="px-2 z-depth-0 m-0" mdbWavesEffect>
      <i class="fas fa-plus fa-fw {{ color }}-text"></i>
    </button>
  </div>
</app-widget-option>

<div mdbModal #indicatorsModal="mdbModal" class="modal fade" style="overflow-y: scroll" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header d-flex align-items-center flex-column">

        <i class="animated rotateIn {{ color }}-text far fa-{{ icon }} fa-4x d-none d-sm-inline"></i>
        <i class="animated rotateIn {{ color }}-text far fa-{{ icon }} fa-2x d-sm-none"></i>

        <h6 class="modal-title w-100 text-center">
          <div class="font-weight-bold text-elegant mt-1">{{ 'INDICATORS' | translate }}</div>
        </h6>

      </div>
      <div class="modal-header">

        <button type="button" size="sm" mdbBtn color="secondary" class="waves-light z-depth-0 px-2 m-0 app-close-modal" (click)="indicatorsModal.hide()" mdbWavesEffect>
          <i class="fas fa-times fa-fw"></i>
        </button>

        <button type="button" mdbBtn [color]="color" class="waves-light z-depth-0 px-sm-4 px-3 my-0 ml-auto mr-0" (click)="onSelect();indicatorsModal.hide();" mdbWavesEffect>
          <i class="d-sm-none far fa-check fa-fw"></i>
          <i class="d-none d-sm-inline far fa-check"></i>
          <span class="d-none d-sm-inline ml-1">{{ 'SELECT' | translate }}</span>
        </button>

      </div>
      <div class="modal-body overflow-hidden" *ngIf="indicatorsModal.isShown">
        
        <app-indicators-select-list [exclusions]="widgetLayers.indicatorsUID" (sendIndicator)="receiveIndicator($event)"></app-indicators-select-list>

      </div>
    </div>
  </div>
</div>