<app-theme-option [categories]="themeOptions.optionsConfiguration[optionType].categories" [label]="themeOptions.optionsConfiguration[optionType].label">
  <div app-theme-option-content>
    <div class="btn-group" mdbDropdown>
      <a mdbDropdownToggle type="button" mdbBtn size="sm" color="light" class="dropdown-toggle waves-light z-depth-0 px-3 dropdown-light" mdbWavesEffect>
        <span class="{{color}}-text font-weight-bolder">{{ themeOptions.options().animation.easing }}</span>&nbsp;
      </a>
      <div class="dropdown-menu dropdown-dark dropdown-menu-right font-small app-drop-small-limit-5">
        <a *ngFor="let value of values" class="dropdown-item z-depth-0" (click)="onValue(value)">{{ value }}</a>
      </div>
    </div>
  </div>
</app-theme-option>