<div *ngIf="key != undefined">
  <div class="blue-grey-text font-weight-bold mt-3">
    <i class="fa-lg {{icon}} {{color}}-text mr-1 fa-fw"></i>
    {{ labels.value(language) }}
  </div>

  <hr>

  <app-import-links-change (sendSelected)="receiveSelectedLink($event)" [item]="importFrame.item({ key: key })"></app-import-links-change>

  <hr>

  <div *ngIf="type != 'indicators'">

    <div class="d-flex align-items-center mb-2 p-2 font-very-small rounded blue-grey lighten-5">
      <div class="blue-grey-text font-weight-bold">{{ 'COUNT' | translate | uppercase }}</div>
      <div class="cyan-text ml-auto font-weight-bold">{{ aggregations.count | number:'1.0-2':language }}</div>
    </div>

    <div class="d-flex align-items-center mb-2 p-2 font-very-small rounded blue-grey lighten-5">
      <div class="blue-grey-text font-weight-bold">{{ 'DISTINCT' | translate | uppercase }}</div>
      <div class="cyan-text ml-auto font-weight-bold">{{ aggregations.distinct | number:'1.0-2':language }}</div>
    </div>

    <hr>

    <div *ngIf="type == 'dates'" class="flex-grow-1 d-flex align-items-center p-0 my-2 mx-1">
      <div class="app-import-detect-value-input-frame md-form md-outline m-0 w-100">
        <textarea [ngModel]="format" (ngModelChange)="onFormat($event)" id="form-format" class="app-import-detect-values-input-format form-control textarea form-{{color}} mb-0" size="sm" rows="1" type="text" mdbInput style="resize:none;"></textarea>
        <label for="form-format">{{ 'FORMAT' | translate }}: {{ format }}</label>
      </div>
      <i (click)="onRestore()" class="fad fa-lg py-1 pl-2 pr-1 fa-times-square animated fadeInRight text-secondary app-cursor-pointer"></i>
      <i (click)="onValid()" class="fad fa-lg p-1 fa-check-square animated fadeInRight text-default app-cursor-pointer"></i>
    </div>

    <hr *ngIf="type == 'dates'">

    <div class="d-flex align-items-center"> 
      <div class="app-import-detect-value-title w-100 p-2 m-1 d-flex align-items-center font-small blue-grey-text font-weight-bold">
        <div>{{ 'DETECTED_VALUES' | translate | uppercase }}</div>
      </div>
      <button [disabled]="!importFrame.dataFrame.isErrorsForKey(key)" *ngIf="!showErrors" mdbBtn color="light" class="waves-light z-depth-0 px-3 blue-grey-text mr-1" type="button" (click)="onShowErrors()" mdbWavesEffect>
        <i class="d-md-none fas fa-exclamation-triangle fa-fw"></i>
        <i class="d-none d-md-inline fas fa-exclamation-triangle"></i>
        <span class="d-none d-md-inline ml-1">{{ 'ERRORS' | translate }}</span>
      </button>
      <button [disabled]="!importFrame.dataFrame.isErrorsForKey(key)" *ngIf="showErrors" mdbBtn color="danger" class="waves-light z-depth-0 px-3 mr-1" type="button" (click)="onShowErrors()" mdbWavesEffect>
        <i class="d-md-none fas fa-exclamation-triangle fa-fw"></i>
        <i class="d-none d-md-inline fas fa-exclamation-triangle"></i>
        <span class="d-none d-md-inline ml-1">{{ 'ERRORS' | translate }}</span>
      </button>
      <app-links-item-option class="d-block ml-2 my-0 mr-0" [symbol]="'filter'" [selected]="allSelected" (sendSelected)="receiveAllSelected()"></app-links-item-option>
    </div>
    
    <div *ngFor="let detect of detects trackBy: trackByValue">
      <app-import-detect-value *ngIf="!showErrors || !importFrame.dataFrame.isValid(key, detect.value)" [key]="key" [isValid]="importFrame.dataFrame.isValid(key, detect.value)" [detect]="detect" [selected]="unselected[key][detect.value] == undefined" (sendSelected)="receiveSelected($event, detect)" (sendEdit)="receiveEdited($event)" (sendRemove)="receiveRemove(detect)"></app-import-detect-value>
    </div>

  </div>
  <div *ngIf="type == 'indicators'">

    <div *ngIf="importFrame.dataFrame.isErrorsForKey(key)" class="alert alert-danger font-small text-center" role="alert">
      {{ 'ERROR_FOR_INDICATORS/TEXT' | translate }}
    </div>

    <div *ngFor="let aggregation of aggregations | keyvalue">

      <div class="d-flex align-items-center mb-2 p-2 font-very-small rounded blue-grey lighten-5">
        <div class="blue-grey-text font-weight-bold">{{ aggregation.key | uppercase | translate | uppercase }}</div>
        <div class="cyan-text ml-auto font-weight-bold">{{ aggregation.value | number:'1.0-2':language }}</div>
      </div>

    </div>

  </div>
</div>