/**
 * @module LinkedColumn
 */

import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { LinkedColumnsClass } from '@class/linkedColumns.class';
import { MaterialChipsComponent } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-linked-columns',
  templateUrl: './linked-columns.component.html',
  styleUrls: ['./linked-columns.component.css']
})
export class LinkedColumnsComponent implements OnInit, OnChanges {

  @Input() linkedColumns: LinkedColumnsClass;

  @ViewChild('chips', { static: true }) chips: MaterialChipsComponent;

  previous: string[];
  values: string[];

  constructor() {}

  ngOnInit() {
    this.previous = this.linkedColumns.list().slice();
    this.values = this.linkedColumns.list().slice();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.linkedColumns !== undefined && !changes.linkedColumns.firstChange) {
      this.previous = this.linkedColumns.list().slice();
      this.values = this.linkedColumns.list().slice();
    }
  }

  receiveValues(values: string[]) {
    values.forEach((value: string) => {
      this.linkedColumns.add(value);
    });
    this.previous.forEach((value: string) => {
      if(!values.includes(value)) this.linkedColumns.remove(value);
    });
    this.previous = values.slice();
  }

  onAdd() {
    this.chips.addValue(this.chips.labelToAdd, new Event('add'));
  }

}
