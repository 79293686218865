/**
 * @module Root
 */

export type EnvironmentType = 'development' | 'staging' | 'production';
export type EnvironmentCodeType = 'D' | 'S' | 'P';

export interface EnvironmentModel {
  version: string;
  type: EnvironmentType;
  code: EnvironmentCodeType
}

export const ENVIRONMENT = {
  version: '3.5.3',
  type: 'development',
  code: 'D'
} as EnvironmentModel;
