/**
 * @module Widget
 */

import { Component, Input } from '@angular/core';
import { WidgetLayersClass } from '@class/widgetLayers.class';
import { UID } from '@models/uid.model';
import { ItemColor } from '@functions/item.functions';

@Component({
  selector: 'app-widget-layers-template',
  templateUrl: './widget-layers-template.component.html',
  styleUrls: ['./widget-layers-template.component.css']
})
export class WidgetLayersTemplateComponent {

  @Input() widgetLayers: WidgetLayersClass;
  @Input() current: UID;

  color = ItemColor('widgets');
  templates: number[] = [1, 2];

  constructor() {}

  onValue(template: number) {
    this.widgetLayers.layer(this.current).change({ stickerTemplate: template });
    if(this.widgetLayers.uid.value === this.current) this.widgetLayers.duplicateLayersOptions({ stickerTemplate: template }, this.current);
  }

}
