/**
 * @module Tag
 */

import { Injectable } from '@angular/core';
import { TagClass } from '@class/tag.class';
import { Observable, BehaviorSubject } from 'rxjs';
import { TagModel } from '@models/tag.model';
import { map} from 'rxjs/operators';
import { FirestoreService } from '@services/firestore/firestore.service';
import { AuthService } from '@services/auth/auth.service';
import { FirebaseService } from '@services/firebase/firebase.service';
import { UidService } from '@services/uid/uid.service';
import { ItemType } from '@models/item.model';
import { LinksModelConstructor } from '@functions/link.functions';

@Injectable({
  providedIn: 'root'
})
export class TagService extends FirestoreService<TagModel> {

  /**
   * @description type de document manipulé par le service
   * @protected
   * @type {ItemType}
   * @memberof TagService
   */
  protected _itemType: ItemType = 'tags';

  /**
   * @description true si l'accès à ce type de document peut nécessiter des habilitations
   * @protected
   * @type {boolean}
   * @memberof TagService
   */
  protected _restricted: boolean = false;

  /**
   * @description Creates an instance of TagService
   * @param {UidService} $uid
   * @param {AuthService} $auth
   * @param {FirebaseService} $firebase
   * @memberof TagService
   */
  constructor(
    private $uid: UidService,
    protected $auth: AuthService,
    protected $firebase: FirebaseService
  ) {
    super($firebase, $auth);
  }

  /**
   * @description Observateur de la liste des objects autorisés pour l'utilisateur courant
   * @returns {BehaviorSubject<TagClass[]>}
   * @memberof TagService
   */
  list$(): BehaviorSubject<TagClass[]> {
    return super.full$().pipe(
      map((tags: TagModel[]) => {
        if(!!tags) {
          let ds = tags.map<TagClass>((tag: TagModel) => {
            return new TagClass(tag, true);
          });
          return ds;
        }
      })
    ) as BehaviorSubject<TagClass[]>;
  }

  /**
   * @description Observateur d'un document dont l'uid est passé en paramètre
   * @param {string} uid
   * @returns {Observable<TagClass>}
   * @memberof TagService
   */
  get$(uid: string): Observable<TagClass> {
    return super.doc$(uid).pipe(
      map((tag: TagModel) => {
        return new TagClass(tag, true);
      })
    );
  }

  /**
   * @description Observateur de la liste des objects (données partielles) autorisés pour l'utilisateur courant
   * @returns {Observable<TagClass[]>}
   * @memberof TagService
   */
  listPartials$(): Observable<TagClass[]> {
    return super.fullPartial$().pipe(
      map((tags: TagModel[]) => {
        if(!!tags) {
          let ds = tags.map<TagClass>((tag: TagModel) => {
            return new TagClass(tag, true);
          });
          return ds;
        }
      })
    );
  }

  /**
   * @description Observateur d'un document (données partielles) dont l'uid est passé en paramètre
   * @param {string} uid
   * @returns {Observable<TagClass>}
   * @memberof TagService
   */
  getPartial$(uid: string): Observable<TagClass> {
    return super.partial$(uid).pipe(
      map((tag: TagModel) => {
        if(tag !== null) return new TagClass(tag, true);
      })
    );
  }

  /**
   * @description Créé un nouveau document avec les valeurs par défaut
   * @returns {TagClass}
   * @memberof TagService
   */
  create(): TagClass {
    return new TagClass({ 
      uid: this.$uid.create(),
      links: LinksModelConstructor().links,
      color: {
        hexa: '607d8b'
      },
      icon: {
        style: 'r',
        symbol: 'tag',
        options: []
      },
      labels: undefined
    } as TagModel, false);
  }

  /**
   * @description Créé ou met à jour le document passé en paramètre
   * @param {TagClass} tag
   * @returns {Promise<void>}
   * @memberof TagService
   */
  async set(tag: TagClass): Promise<void> {
    return super.update(tag.uid.value, tag.model);
  }

  /**
   * @description Supprime le document passé en paramètre
   * @param {string} uid
   * @returns {Promise<void>}
   * @memberof TagService
   */
  async remove(uid: string): Promise<void> {
    return super.delete(uid);
  }

}
