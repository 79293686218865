<app-widget-option [categories]="['INDICATORS']" [label]="'ICON'" [limitedTo]="['sticker']">
  <div app-widget-option-content>
    <button mdbBtn (click)="iconModal.show()" type="button" color="light" size="sm" class="px-2 z-depth-0 m-0" mdbWavesEffect>
      <i class="{{ widgetLayers.indicators[current].icon.code }} fa-fw text-default"></i>
    </button>
  </div>
</app-widget-option>

<div mdbModal #iconModal="mdbModal" class="modal fade" style="overflow-y: scroll" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header d-flex align-items-center flex-column">

        <i class="animated rotateIn {{ color }}-text {{ widgetLayers.indicators[current].icon.code }} fa-4x d-none d-sm-inline"></i>
        <i class="animated rotateIn {{ color }}-text {{ widgetLayers.indicators[current].icon.code }} fa-2x d-sm-none"></i>

        <h6 class="modal-title w-100 text-center">
          <div class="font-weight-bold text-elegant mt-1">{{ 'ICON' | translate }}</div>
        </h6>

        <button type="button" size="sm" mdbBtn color="secondary" class="waves-light z-depth-0 px-2 m-0 app-close-modal" (click)="iconModal.hide()" mdbWavesEffect>
          <i class="fas fa-times fa-fw"></i>
        </button>

      </div>
      <div class="modal-body overflow-hidden" *ngIf="iconModal.isShown">

        <app-icons [icon]="widgetLayers.indicators[current].icon" [color]="color" (sendSelect)="iconModal.hide()"></app-icons>

      </div>
    </div>
  </div>
</div>