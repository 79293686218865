/**
 * @module Theme
 */

import { Component, ViewChild, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { EditorComponent } from '@components/forms/editor/editor.component';
import { ThemeClass } from '@class/theme.class';
import { MessagesService } from '@services/messages/messages.service';
import { ThemeService } from '@services/theme/theme.service';
import { LanguageType } from '@models/language.model';
import { AuthService } from '@services/auth/auth.service';
import { Subscription } from 'rxjs';
import { ItemColor, ItemIcon } from '@functions/item.functions';

@Component({
  selector: 'app-theme-editor',
  templateUrl: './theme-editor.component.html',
  styleUrls: ['./theme-editor.component.css']
})
export class ThemeEditorComponent implements OnDestroy {

  @Input() current: ThemeClass;
  @Output() sendDelete: EventEmitter<void>;

  @ViewChild('editor', { static: true }) editor: EditorComponent;

  icon = ItemIcon('themes');
  color = ItemColor('themes');
  editorIndex: number;
  editorIndexMax: number;
  language: LanguageType;
  language$sub: Subscription;

  constructor(
    private $themes: ThemeService,
    private $auth: AuthService,
    private $messages: MessagesService
  ) {
    this.sendDelete = new EventEmitter<void>();
    this.editorIndex = 0;
    this.editorIndexMax = 1;

    this.language$sub = this.$auth.language$.subscribe({
      next: (language: LanguageType) => {
        this.language = language;
      }
    });
  }

  ngOnDestroy() {
    this.language$sub.unsubscribe();
  }

  public show() {
    this.editor.show();
  }

  receiveRestore() {
    this.current.reset();
  }

  receiveDelete() {
    this.editorIndex = NaN;
  }

  receiveDeleteConfirmation() {
    this.$themes.remove(this.current.uid.value)
    .then(() => {
      this.editor.hide();
      this.editorIndex = 0;
      this.$messages.success({ 
        title: { plain: this.current.labels.value(this.language) }, 
        text: { code: 'SUCCESSFULLY_REMOVED/TEXT' } 
      });
      this.current = this.$themes.create();
      this.sendDelete.next();
    })
    .catch((error) => {
      this.$messages.error({ 
        title: { plain: this.current.labels.value(this.language) }, 
        text: { 
          code: 'REMOVE_FAILED/TEXT',
          params: { error: error.code }
        } 
      });
    });
  }

  receiveSave() {
    this.$themes.set(this.current)
    .then(() => {
      if(!this.current.uid.isRegistered) {
        this.$messages.info({ 
          title: { plain: this.current.labels.value(this.language) }, 
          text: { code: 'DELAY_TO_VALIDATE/TEXT' } 
        });
        this.current = this.$themes.create();
      }
      this.editor.hide();
      this.$messages.success({ 
        title: { plain: this.current.labels.value(this.language) }, 
        text: { code: 'SUCCESSFULLY_SAVED/TEXT' } 
      });
    })
    .catch((error) => {
      this.$messages.error({ 
        title: { plain: this.current.labels.value(this.language) }, 
        text: { 
          code: 'SAVE_FAILED/TEXT',
          params: { error: error.code }
        } 
      });
    });
  }

  receiveNext(isNext: boolean) {
    this.editorIndex = this.editorIndex + (isNext ? 1 : -1);
    if(this.editorIndex < 0) this.editorIndex = this.editorIndexMax;
    if(this.editorIndex > this.editorIndexMax) this.editorIndex = 0;
  }  

}
