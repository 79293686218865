/**
 * @module Widget
 */

import { Component, Input } from '@angular/core';
import { WidgetLayersClass } from '@class/widgetLayers.class';
import { Subscription } from 'rxjs';
import { LanguageType } from '@models/language.model';
import { AuthService } from '@services/auth/auth.service';
import { ItemColor, ItemIcon } from '@functions/item.functions';

@Component({
  selector: 'app-widget-designer-filters',
  templateUrl: './widget-designer-filters.component.html',
  styleUrls: ['./widget-designer-filters.component.css']
})
export class WidgetDesignerFiltersComponent {

  @Input() widgetLayers: WidgetLayersClass;

  language: LanguageType;
  language$sub: Subscription

  color = ItemColor('indicators');
  icon = ItemIcon('indicators');

  constructor(
    private $auth: AuthService
  ) {
    this.language$sub = this.$auth.language$.subscribe({
      next: (language: LanguageType) => {
        this.language = language;
      }
    });
  }

}
