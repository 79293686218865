/**
 * @module Bookmark
 */

import { BookmarksModel } from '@models/bookmarks.model';
import { MapClass } from '@class/map.class';
import { UID } from '@models/uid.model';
import { LinkableType } from '@models/links.model';
import { ModificationClass, ModificationStateClass } from '@class/modification.class';
import { DeepCopy } from '@functions/copy.functions';
import { BookmarksModelConstructor } from '@functions/bookmark.functions';

export class BookmarksClass extends ModificationClass<BookmarksModel> {

  protected _parent: BookmarksModel;
  protected _inital: BookmarksModel;
  protected _attributes: string[];

  private _bookmarks: MapClass<MapClass<boolean>>;

  constructor(bookmarks: BookmarksModel, state: ModificationStateClass) {

    super();
    
    this._parent = bookmarks;
    this._attributes = this.attributes;
    this._state = state;

    if(this._parent.bookmarks === undefined) this._parent.bookmarks = BookmarksModelConstructor().bookmarks;
    else {
      for(const linkable in BookmarksModelConstructor().bookmarks) {
        if(this._parent.bookmarks[linkable] === undefined) this._parent.bookmarks[linkable] = {};
      }
    }

    let maps = {} as { [key: string]: MapClass<boolean>};
    for(const linkable in this._parent.bookmarks) {
      maps[linkable] = new MapClass<boolean>(this._parent.bookmarks[linkable]);
    }
    this._bookmarks = new MapClass(maps);

    this._inital = DeepCopy({ bookmarks: this._parent.bookmarks });

  }

  get class(): Readonly<string> {
    return 'bookmarks';
  }

  get attributes(): string[] {
    return ['bookmarks'];
  }

  list(linkable: LinkableType): Readonly<UID[]> {
    return this._bookmarks.value(linkable).keys;
  }

  has(linkable: LinkableType, uid: UID): Readonly<boolean> {
    return this._bookmarks.value(linkable).has(uid);
  }

  add(linkable: LinkableType, uid: UID, sendMessage: boolean = true) {
    this._bookmarks.value(linkable).add(uid, true);
    if(sendMessage) this.emit(this.attributes);
  }

  remove(linkable: LinkableType, uid: UID, sendMessage: boolean = true) {
    this._bookmarks.value(linkable).remove(uid);
    if(sendMessage) this.emit(this.attributes);
  }

}