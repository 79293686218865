<div mdbModal #editorModal="mdbModal" class="modal fade" style="overflow-y: scroll" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header d-flex align-items-center flex-column">

        <i class="animated rotateIn {{iconColor ? '' : color}}-text {{icon}} fa-4x d-none d-sm-inline" [ngStyle]="{ 'color': iconColor }"></i>
        <i class="animated rotateIn {{iconColor ? '' : color}}-text {{icon}} fa-2x d-sm-none" [ngStyle]="{ 'color': iconColor }"></i>

        <h6 class="modal-title w-100 text-center">
          <div class="font-weight-bold text-elegant mt-1">&nbsp;{{title}}&nbsp;</div>
          <div class="text-muted text-monospace font-extra-small">{{uid}}</div>
        </h6>

      </div>
      <div class="modal-header">

        <button *ngIf="isRegistered" [disabled]="!isModified" type="button" mdbBtn color="default" class="waves-light z-depth-0 mx-0 px-sm-4 px-3 my-0" (click)="onRestore()" mdbWavesEffect>
          <i class="d-sm-none far fa-redo fa-fw"></i>
          <i class="d-none d-sm-inline far fa-redo"></i>
          <span class="d-none d-sm-inline ml-1">{{ 'RESTORE' | translate }}</span>
        </button>

        <button type="button" size="sm" mdbBtn color="secondary" class="waves-light z-depth-0 px-2 m-0 app-close-modal" (click)="onClose()" mdbWavesEffect>
          <i class="fas fa-times fa-fw"></i>
        </button>

        <button *ngIf="isSaveable" [disabled]="!isValid || (!isModified && isRegistered)" type="button" mdbBtn [color]="color" class="waves-light z-depth-0 px-sm-4 px-3 my-0 ml-auto" (click)="onSave()" mdbWavesEffect>
          <i class="d-sm-none far fa-check fa-fw"></i>
          <i class="d-none d-sm-inline far fa-check"></i>
          <span class="d-none d-sm-inline ml-1">{{ 'SAVE' | translate }}</span>
        </button>

        <div class="btn-group" mdbDropdown>
          <button mdbDropdownToggle type="button" mdbBtn color="light" class="blue-grey-text z-depth-0 dropdown-toggle waves-light d-flex align-items-center px-3" mdbWavesEffect>
            <i class="far fa-bars"></i>&nbsp;
          </button>
        
          <div class="dropdown-menu dropdown-dark dropdown-menu-right">

            <!-- CONTENT MENU -->
            <ng-content select="[app-editor-content-menu]"></ng-content>
            <!-- ./CONTENT MENU -->

            <div *ngIf="isRegistered" class="divider dropdown-divider"></div>
            <a *ngIf="isRegistered" class="dropdown-item z-depth-0 text-danger" (click)="onDelete()">{{ 'REMOVAL' | translate }}</a>

          </div>
        </div>
        
      </div>
      <div class="modal-body overflow-hidden">
        
        <!-- CONTENT BODY -->
        <ng-content select="[app-editor-content-body]"></ng-content>
        <!-- ./CONTENT BODY -->

      </div>
    </div>
  </div>
</div>