<app-import-option *ngIf="withButton" [categories]="['LINK']" [label]="'CHANGE'">
  <div app-import-option-content>
    <button mdbBtn (click)="onShow()" type="button" color="light" size="sm" class="px-2 z-depth-0 m-0" mdbWavesEffect>
      <i class="fas fa-link fa-fw {{ colors['indicators'] }}-text"></i>
    </button>
  </div>
</app-import-option>

<div mdbModal #linkModal="mdbModal" class="modal fade" style="overflow-y: scroll" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div *ngIf="item" class="modal-content">
      <div class="modal-header d-flex align-items-center flex-column">

        <i class="animated rotateIn {{colors[item.class + 's']}}-text far fa-link fa-4x d-none d-sm-inline"></i>
        <i class="animated rotateIn {{colors[item.class + 's']}}-text far fa-link fa-2x d-sm-none"></i>

        <h6 class="modal-title w-100 text-center">
          <div class="font-weight-bold text-elegant mt-1">&nbsp;{{ 'LINK_SELECTION' | translate }}&nbsp;</div>
        </h6>

      </div>
      <div class="modal-header">

        <button type="button" size="sm" mdbBtn color="secondary" class="waves-light z-depth-0 px-2 m-0 app-close-modal" (click)="linkModal.hide()" mdbWavesEffect>
          <i class="fas fa-times fa-fw"></i>
        </button>

        <button type="button" mdbBtn [color]="colors[item.class + 's']" class="waves-light z-depth-0 px-sm-4 px-3 my-0 ml-auto" (click)="onSelect()" mdbWavesEffect>
          <i *ngIf="!linking" class="d-sm-none far fa-check fa-fw"></i>
          <i *ngIf="!linking" class="d-none d-sm-inline far fa-check"></i>
          <i *ngIf="linking" class="d-sm-none fa-fw fas fa-circle-notch fa-spin"></i>
          <i *ngIf="linking" class="d-none d-sm-inline fas fa-circle-notch fa-spin"></i>
          <span class="d-none d-sm-inline ml-1">{{ 'SELECT' | translate }}</span>
        </button>
        
      </div>

      <div class="modal-body overflow-hidden mt-2">

        <app-pivots-links-change *ngIf="item.class == 'pivot'" [pivot]="item" (sendSelected)="receiveSelected($event)"></app-pivots-links-change>
        <app-dates-links-change *ngIf="item.class == 'date'" [date]="item" (sendSelected)="receiveSelected($event)"></app-dates-links-change>
        <app-indicators-links-change *ngIf="item.class == 'indicator'" [indicator]="item" (sendSelected)="receiveSelected($event)"></app-indicators-links-change>

      </div>
    </div>
  </div>
</div>