<ul class="navbar-nav mt-3 mt-sm-0">
  <!-- CONTENT OPTIONS -->
  <ng-content select="[app-browser-navbar-panels-content-options]"></ng-content>
  <!-- ./CONTENT OPTIONS -->
  <span *ngFor="let panel of panels">
    <li *ngIf="user.roles.has(roles[panel]) || roles[panel] == 'd'" class="nav-item" [ngClass]="{ 'active': panel == current }" (click)="onPanel(panel)">
      <a class="nav-link waves-light {{ panel == current ? colors[panel] : 'white' }}-text" mdbWavesEffect><mdb-icon far [icon]="icons[panel]"></mdb-icon>
      <span class="ml-1 d-sm-none d-inline">{{ labels[panel] | translate }}</span></a>
    </li>
  </span>
</ul>