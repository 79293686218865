/**
 * @module Chart
 */

import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Label, BaseChartDirective } from 'ng2-charts';
import { DeepMerge } from '@functions/copy.functions';
import { ThemeOptionsClass } from '@class/themeOptions.class';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ThemeStudioService } from '@services/themeStudio/theme-studio.service';
import { ChartType } from '@models/chart.model';
import { ColorsService } from '@services/colors/colors.service';

@Component({
  selector: 'app-doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.css']
})
export class DoughnutChartComponent implements OnInit, OnDestroy {

  public type: ChartType = 'doughnut';

  @Input() options: ThemeOptionsClass;
  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

  public labels: Label[] = [];
  public data: ChartDataSets[] = [];
  public datasets: ChartDataSets[] = [];
  public mergedOptions: ChartOptions;
  public localOptions: ChartOptions = {
    title: {
      text: '',
    }
  };
  public plugins = [];

  changedOptions$sub: Subscription;
  changedSeries$sub: Subscription;
  data$sub: Subscription;

  constructor(
    private $translate: TranslateService,
    private $themeStudio: ThemeStudioService,
    private $colors: ColorsService
  ) {
    this.localOptions.title.text = `${this.$translate.instant('TITLE')} - ${this.$translate.instant(this.type.toUpperCase() + '/CHART')}`;
  }

  ngOnDestroy() {
    this.changedOptions$sub.unsubscribe();
    this.changedSeries$sub.unsubscribe();
    this.data$sub.unsubscribe();
  }

  ngOnInit() {
    this.data$sub = this.$themeStudio.data$.subscribe({
      next:(data) => {
        if(!!data) {
          this.data = data.series;
          this.labels = data.labels;
          this.setDatasets();
        }
      }
    });

    this.mergedOptions = DeepMerge(this.options.options(this.type), this.localOptions);

    this.changedOptions$sub = this.options.changedOptions$.subscribe({
      next:() => {
        this.mergedOptions = DeepMerge(this.options.options(this.type), this.localOptions);
      }
    });

    this.changedSeries$sub = this.options.changedSeries$.subscribe({
      next:() => {
        this.setDatasets();
      }
    });
  }

  setDatasets() {
    this.datasets = new Array(this.data.length).fill({});
    this.data.forEach((dst: ChartDataSets, index: number) => {
      this.datasets[index] = DeepMerge(dst, this.options.series(index, this.type));

      if(this.datasets[index].backgroundMultiGradiant && this.type !== 'sticker' && this.type !== 'line' && this.type !== 'radar') {
        this.datasets[index].backgroundColor = this.$colors.gradiantForColor((this.datasets[index].backgroundColor as string).slice(0, 7), this.datasets[index].data.length).map(c => c + (this.datasets[index].backgroundColor as string).slice(7, 9));
      }
      if(this.datasets[index].borderMultiGradiant && this.type !== 'sticker' && this.type !== 'line' && this.type !== 'radar') {
        this.datasets[index].borderColor = this.$colors.gradiantForColor((this.datasets[index].borderColor as string).slice(0, 7), this.datasets[index].data.length).map(c => c + (this.datasets[index].borderColor as string).slice(7, 9));
      }
    });
  }

}