/**
 * @module Widget
 */

import { Injectable } from '@angular/core';
import { WidgetLayersClass } from '@class/widgetLayers.class';
import { UID } from '@models/uid.model';
import { LayersDefault } from '@functions/widget.functions';
import { WidgetClass } from '@class/widget.class';
import { ThemeOptionsClass } from '@class/themeOptions.class';
import { ColorsService } from '@services/colors/colors.service';

@Injectable({
  providedIn: 'root'
})
export class WidgetStudioService {

  private _layers: { [key in UID]: WidgetLayersClass } = {};
  private _autoDuplicate: { [key in UID]: boolean } = {};

  private _currentLayerUID: UID;

  constructor(
    private $colors: ColorsService
  ) {}

  widgetLayers(uid: UID, widgetLayer?: WidgetLayersClass, widget?: WidgetClass, themeOptions?: ThemeOptionsClass, forceUpdate?: boolean): WidgetLayersClass {
    if(this._layers[uid] === undefined) {
      //on a ouvert un widget non précédement ouvert dans cette session dans le studio
      if(!!widgetLayer && !!widgetLayer.model) this._layers[uid] = widgetLayer;
      else this._layers[uid] = new WidgetLayersClass(LayersDefault(widget), themeOptions, this.$colors);
    }
    else if(forceUpdate === true) {
      this._layers[uid].reset(widgetLayer.model, themeOptions);
    }
    this._currentLayerUID = uid;
    return this._layers[uid];
  }

  autoDuplicate(uid: UID, autoDuplicate?: boolean): Readonly<boolean> {
    if(uid === undefined) uid = this._currentLayerUID;
    if(autoDuplicate !== undefined) {
      this._autoDuplicate[uid] = autoDuplicate;
    }
    else if(this._autoDuplicate[uid] === undefined) {
      this._autoDuplicate[uid] = true;
    }
    return this._autoDuplicate[uid];
  }

}
