<div class="d-flex align-items-center flex-column">
  <app-descriptions class="d-block w-100" [color]="color" [descriptions]="descriptions" [label]="'YOUR_COMMENT'"></app-descriptions>

  <div class="mt-2 d-flex w-100 align-items-center">
    <button (click)="addBold()" class="z-depth-0 d-flex px-2 align-items-center ml-0 mb-0" size="sm" style="width: 30px; height: 31px;" type="button" mdbBtn color="light" mdbWavesEffect>
      <i class="fas fa-bold fa-fw text-info"></i>
    </button>
    <button (click)="addItalic()" class="z-depth-0 d-flex px-2 align-items-center ml-0 mb-0" size="sm" style="width: 30px; height: 31px;" type="button" mdbBtn color="light" mdbWavesEffect>
      <i class="fas fa-italic fa-fw text-info"></i>
    </button>
    <button (click)="addUnderline()" class="z-depth-0 d-flex px-2 align-items-center ml-0 mb-0" size="sm" style="width: 30px; height: 31px;" type="button" mdbBtn color="light" mdbWavesEffect>
      <i class="fas fa-underline fa-fw text-info"></i>
    </button>
    <button (click)="addBullet()" class="z-depth-0 d-flex px-2 align-items-center ml-0 mb-0" size="sm" style="width: 30px; height: 31px;" type="button" mdbBtn color="light" mdbWavesEffect>
      <i class="fas fa-list-ul fa-fw text-info"></i>
    </button>
    <button (click)="addBreak()" class="z-depth-0 d-flex px-2 align-items-center ml-0 mb-0" size="sm" style="width: 30px; height: 31px;" type="button" mdbBtn color="light" mdbWavesEffect>
      <i class="fas fa-reply fa-flip-vertical fa-fw text-info"></i>
    </button>
    
    <button (click)="bbcodeHelper.show()" class="z-depth-0 d-flex px-2 align-items-center mr-0 mb-0 ml-auto" size="sm" style="width: 30px; height: 31px;" type="button" mdbBtn color="light" mdbWavesEffect>
      <i class="fas fa-info fa-fw text-info"></i>
    </button>
  </div>

  <div *ngIf="descriptions.value(language).length > 0" [innerHTML]="preview" class="mt-2 w-100 rounded blue-grey lighten-{{ editing ? '4' : '5' }} p-3 font-small text-elegant"></div>

  <button [disabled]="!descriptions.isValid()" type="button" mdbBtn [color]="color" block="true" class="waves-light z-depth-0 px-3 mt-2 d-flex align-items-center" (click)="onAdd()" mdbWavesEffect>
    <div class="mr-auto">{{ (editing ? 'SAVE' : 'LEAVE_A_COMMENT') | translate }}</div>
    <i class="fas fa-{{ editing ? 'comment-alt-edit' : 'comment-alt-plus' }}"></i>
  </button>
</div>
<app-helper-bbcode #bbcodeHelper></app-helper-bbcode>