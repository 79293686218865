/**
 * @module Theme
 */

import { Component, Input } from '@angular/core';
import { ItemColor } from '@functions/item.functions';
import { ThemeOptionsClass } from '@class/themeOptions.class';
import { ChartType } from '@models/chart.model';
import { ThemeOptionType } from '@models/themeStudio.model';

@Component({
  selector: 'app-theme-option-axes-position',
  templateUrl: './theme-option-axes-position.component.html',
  styleUrls: ['./theme-option-axes-position.component.css']
})
export class ThemeOptionAxesPositionComponent {

  @Input() themeOptions: ThemeOptionsClass;
  @Input() axesXY: 'x' | 'y';
  @Input() axesIndex: number;

  color = ItemColor('themes');
  positions = {
    x: ['top', 'bottom'],
    y: ['left', 'right']
  };
  icons = {
    x: ['arrow-alt-to-top', 'arrow-alt-to-bottom'],
    y: ['arrow-alt-to-left', 'arrow-alt-to-right']
  };
  optionType: ThemeOptionType = 'axes_position';
  showDetails: boolean;

  constructor() {}

  receiveShowDetails(showDetails: boolean) {
    this.showDetails = showDetails;
  }

  receiveValue(value: Position, type?: ChartType) {
    this.themeOptions.setAxes(this.optionType, value, this.axesXY, this.axesIndex, type ? [type] : undefined);
  }

}
