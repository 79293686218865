/**
 * @Module Shared
 */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchText'
})
export class SearchTextPipe implements PipeTransform {

  transform(items: (string | number)[], searchText: string): any {
    if (!items) {
      return [];
    }
    if (!searchText || searchText.length === 0) {
      return items;
    }
    const _searchText = searchText.toLowerCase();
    return items.filter((item: any) => {
      if(typeof item === 'string') return item.toLowerCase().includes(_searchText);
      else return (item || '').toString().includes(_searchText);
    });
  }

}