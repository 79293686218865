/**
 * @module Form
 */

import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-number',
  templateUrl: './number.component.html',
  styleUrls: ['./number.component.css']
})
export class NumberComponent {
  
  @Input() value: number = 0;
  @Input() step: number = 5;
  @Input() min: number = -Infinity;
  @Input() max: number = Infinity;
  @Input() precision: number = 0;
  @Input() color: string;
  @Input() withButtons: boolean = true;
  @Input() width: number;
  @Output() sendValue: EventEmitter<number>;

  promise: any;

  constructor() {
    this.sendValue = new EventEmitter<number>();
  }

  onUp() {
    if(this.value !== this.max) {
      if((this.value + this.step) <= this.max) this.value = this.round(this.value + this.step);
      else this.value = this.max;
      this.send();
    }
  }

  onDown() {
    if(this.value !== this.min) {
      if((this.value - this.step) >= this.min) this.value = this.round(this.value - this.step);
      else this.value = this.min;
      this.send();
    }
  }

  onValue(value: number) {
    if(value > this.max) this.value = this.max;
    else if(value < this.min) this.value = this.min;
    else this.value = value;
    this.send();
  }

  send() {
    if(this.promise !== undefined) clearTimeout(this.promise);
    this.promise = setTimeout(() => this.sendValue.emit(this.round(this.value)), 200);
  }

  round(value: number): number {
    return this.precision === 0 ? Math.round(value) : Math.round(value * (this.precision * 10)) / (this.precision * 10);
  }

}
