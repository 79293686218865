<div *ngIf="!loading" class="mt-3">
  <app-links-item [color]="color" [icon]="'fad fa-microchip fa-swap-opacity'" [label]="'CPU'">
    <div app-links-item-content-description>
      <div class="font-very-small">{{ 'IN_NUMBER' | translate }}</div>
    </div>
    <div app-links-item-content-option>
      <div class="font-weight-bold">{{ metas.cpus }}</div>
    </div>
  </app-links-item>

  <app-links-item [color]="color" [icon]="'fad fa-memory'" [label]="'MEMORY'">
    <div app-links-item-content-description>
      <div class="font-very-small">{{ 'IN_GIGABYTES' | translate }}</div>
    </div>
    <div app-links-item-content-option>
      <div class="font-weight-bold">{{ memory }}</div>
    </div>
  </app-links-item>

  <app-links-item [color]="color" [icon]="'fad fa-hdd'" [label]="'DISK'">
    <div app-links-item-content-description>
      <div class="font-very-small">{{ 'IN_GIGABYTES' | translate }}</div>
    </div>
    <div app-links-item-content-option>
      <div class="font-weight-bold">{{ metas.disk }}</div>
    </div>
  </app-links-item>

  <app-links-item [color]="color" [icon]="'fad fa-exchange'" [label]="'TRANSFER'">
    <div app-links-item-content-description>
      <div class="font-very-small">{{ 'IN_GIGABYTES_PER_MONTH' | translate }}</div>
    </div>
    <div app-links-item-content-option>
      <div class="font-weight-bold">{{ metas.transfer }}</div>
    </div>
  </app-links-item>

  <app-links-item [color]="color" [icon]="'fad fa-map-marker-alt'" [label]="'CITY'">
    <div app-links-item-content-description>
      <div class="font-very-small">{{ country | translate }}</div>
    </div>
    <div app-links-item-content-option>
      <div class="font-weight-bold">{{ city }}</div>
    </div>
  </app-links-item>
</div>

<app-loading *ngIf="loading"></app-loading>
