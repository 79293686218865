<app-links-item [bottomMargin]="!isLast" [color]="color" [icon]="icon" [labels]="layer.layerLabel" [uid]="layer.uid">
  <div app-links-item-content-description>
    <div class="d-flex align-items-center mt-1">
      <i class="fa-fw {{ iconItem }} mr-1 {{ colorItem }}-text"></i>
      <mdb-badge *ngIf="allIndicators" [mdbTooltip]="indicatorsLabels" [color]="colorIndicator" class="z-depth-0">{{layer.indicators.length}} / {{indicatorsTotal}}<i class="{{ iconIndicator }} ml-1"></i></mdb-badge>
      <mdb-badge *ngIf="!allIndicators" [mdbTooltip]="indicatorsLabels" warning="true" class="z-depth-0">{{layer.indicators.length}} / {{indicatorsTotal}}<i class="{{ iconIndicator }} ml-1"></i></mdb-badge>
    </div>
  </div>
  <div app-links-item-content-option class="d-flex align-items-center">
    <app-links-item-option class="d-block mx-1" [symbol]="'table'" [selected]="layer.inTable" (sendSelected)="receiveInTable()"></app-links-item-option>
    <app-links-item-option class="d-block mx-1" [symbol]="'shovel'" [selected]="drillable" (sendSelected)="receiveSelected()"></app-links-item-option>
    <button *ngIf="selected" (click)="onEdit()" type="button" mdbBtn [color]="color" size="sm" class="z-depth-0 d-flex px-2 align-items-center my-0 mr-0 ml-1" style="width: 30px; height: 31px;" mdbWavesEffect>
      <i class="fas fa-fw fa-sliders-h-square"></i>
    </button>
    <button *ngIf="!selected" (click)="onEdit()" type="button" mdbBtn color="blue-grey" size="sm" class="z-depth-0 d-flex px-2 align-items-center my-0 mr-0 ml-1" style="width: 30px; height: 31px;" mdbWavesEffect>
      <i class="fas fa-fw fa-sliders-h-square"></i>
    </button>
  </div>
</app-links-item>