/**
 * @module Auth
 */

import { Component, OnDestroy } from '@angular/core';
import { AuthService } from '@services/auth/auth.service';
import { Router } from '@angular/router';
import { UserClass } from '@class/user.class';
import { MessagesService } from '@services/messages/messages.service';
import { CompanyService } from '@services/company/company.service';
import { Subscription } from 'rxjs';
import { FirebaseService } from '@services/firebase/firebase.service';
import { GetSubDomainFromHost } from '@functions/company.functions';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnDestroy {

  /**
   * @description mot de passe saisi dans l'interface
   * @type {string}
   * @memberof AuthComponent
   */
  password: string;

  email: string;

  isCompany: boolean;

  /**
   * @description company saisie dans l'interface
   * @type {string}
   * @memberof AuthComponent
   */
  company: string;

  /**
   * @description souscription à l'utilisateur connecté
   * @type {Subscription}
   * @memberof AuthComponent
   */
  user$sub: Subscription;

  /**
   * @description souscription à l'état de la connexion internet
   * @type {any}
   * @memberof AuthComponent
   */
  isUnhealthy$sub: any;
  isManual$sub: any;
  isUnhealthy: boolean;

  /**
   * @description état de la tentative de reconnexion automatique
   * @type {boolean}
   * @memberof AuthComponent
   */
  isWaitingAutoAuth: boolean;
  isAutoAuth: boolean;
  isManual: boolean;
  isWaitingForSignIn: boolean;


  /**
   * @description souscription à la tentative de reconnexion automatique
   * @type {Subscription}
   * @memberof AuthComponent
   */
  isAutoAuth$sub: Subscription;

  /**
   * @description Creates an instance of AuthComponent
   * @param {AuthService} $auth
   * @param {Router} $router
   * @param {MessagesService} $messages
   * @param {CompanyService} $company
   * @param {FirebaseService} $firebase
   * @memberof AuthComponent
   */
  constructor(
    public $auth: AuthService,
    private $router: Router,
    private $messages: MessagesService,
    private $company: CompanyService,
    private $firebase: FirebaseService
  ) {

    const isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);

    if(isIEOrEdge) {
      this.$router.navigate(['/unsupported']);
      return;
    }

    this.isCompany = this.$company.getCodeFromLabel(GetSubDomainFromHost()) !== undefined;

    this.isUnhealthy = false;
    this.isWaitingAutoAuth = true;
    this.isManual = false;

    this.isAutoAuth$sub = this.$auth.reconnection$.subscribe({
      next: (isTrying: boolean) => {

        if(this.isManual$sub) clearTimeout(this.isManual$sub);
        this.isManual = false;
        this.isAutoAuth = isTrying;
        this.isWaitingAutoAuth = false;

        if(this.isAutoAuth) {
          this.isUnhealthy$sub = setTimeout(() => { 
            this.isUnhealthy = true; 
          }, 10000);
        }
        else {
          this.isManual$sub = setTimeout(() => { 
            this.isManual = true; 
          }, 1500);
        }
      }
    });

    this.user$sub = this.$auth.user$.subscribe({
      next: (user: UserClass) => {
        if (!!user) {
          this.$auth.updateLastConnection(user.uid.value);
          const from = this.$router.parseUrl(this.$router.url).queryParams.from;
          if(from !== undefined) {
            const root = this.$router.parseUrl(from).root.children.primary.segments[0].path;
            const queryParams = this.$router.parseUrl(from).queryParams;
            this.$router.navigate([`/${root}`], { queryParams: queryParams });
          }
          else {
            this.$router.navigate(['/browser']);
          }
        }
      }
    });
    this.email = '';
    this.password = '';
    this.company = this.$company.label;
  }

  ngOnDestroy() {
    if(this.user$sub) this.user$sub.unsubscribe();
    if(this.isAutoAuth$sub) this.isAutoAuth$sub.unsubscribe();
    if(this.isUnhealthy$sub) clearTimeout(this.isUnhealthy$sub);
    if(this.isManual$sub) clearTimeout(this.isManual$sub);
  }

  /**
   * @description: déclancheur en cas de changement dans la saisie de l'email pour mise à jour et validation
   * @param {string} email
   * @memberof AuthComponent
   */
  onEmail(email: string) {
    this.email = email;
  }

  /**
   * @description: déclancheur en cas de demande de changement de la company
   * @param {string} company
   * @memberof AuthComponent
   */
  onCompany() {
    this.$company.code = '';
    this.$firebase.unsubscribe();
    this.$router.navigate(['/company']);
  }

  /**
   * @description: déclancheur en cas de changement dans la saisie du password pour mise à jour
   * @param {string} password
   * @memberof AuthComponent
   */
  onPassword(password: string) {
    this.password = password;
  }

  onResetPassword() {
    if(this.email.length > 0) {
      this.$auth.sendPasswordResetEmail(this.email, this.$auth.language$.value || 'en')
      .then(() => {
        this.$messages.success({ text: { code: 'RESET_PASSWORD_SEND/TEXT' } });
      })
      .catch((error) => {
        this.$messages.error({ title: { code: 'RESET_PASSWORD_SEND/ERROR' }, text: { code: error.code } });
      });
    }
    else {
      this.$messages.error({ text: { code: 'AUTH/INVALID-EMAIL' } });
    }
  }

  /**
   * @description: connecte l'utilisateur
   * @memberof AuthComponent
   */
  emailSignIn() {
    if(this.email.length > 0, this.password.length > 0, this.company.length > 0) {
      this.isWaitingForSignIn = true;
      this.$auth.emailSignIn(this.email, this.password)
        .then(() => {
          this.isWaitingForSignIn = false;
          this.isManual = false;
        })
        .catch(error => {
          this.isWaitingForSignIn = false;
          this.$messages.error({ title: { code: 'LOGIN/ERROR' }, text: { code: error.code || '' } });
        });
    }
    else {
      this.$messages.error({ text: { code: 'FORM/ERROR' } });
    }
  }

}
