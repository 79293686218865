<app-widget-layers-item-main
[widgetLayers]="widgetLayers"
[layer]="widgetLayers.layer()"
[selected]="selected == widgetLayers.layer().uid.value"
(sendEdited)="receiveEdited($event)" 
(sendSelected)="receiveSelected($event)" 
></app-widget-layers-item-main>

<hr>

<div app-sortable id="layersID" class="p-1 isSortableContainer app-sortable-container" (sendSortableStop)="receiveSortableStop($event)">
  <app-widget-layers-item [isLast]="isLast" *ngFor="let layer of layers ; let isLast = last" class="isSortableElement app-cursor-grab"
    [widgetLayers]="widgetLayers"
    [layer]="layer"
    [uid]="layer.uid.value" 
    [selected]="selected == layer.uid.value"
    (sendEdited)="receiveEdited($event)" 
    (sendSelected)="receiveSelected($event)" 
    [drillable]="layer.drillable"
  ></app-widget-layers-item>
</div>