<div class="d-flex">
  <app-links-item class="d-block flex-grow-1" [color]="color" [icon]="icon" [labels]="labels" [label]="label" [uid]="hasRole ? uid : undefined" [url]="url">
    <div app-links-item-content-description>

      <!-- CONTENT DESCRIPTION -->
      <div class="d-none d-sm-block">
        <ng-content select="[app-item-strip-content-description]"></ng-content>
      </div>
      <!-- ./CONTENT DESCRIPTION -->

      <app-tags-icons-list class="d-block" *ngIf="tags != undefined" [tags]="tags" [limit]="10"></app-tags-icons-list>

    </div>

    <div app-links-item-content-option *ngIf="hasRole">
      <button (click)="onEdit()" *ngIf="!isModified" type="button" mdbBtn color="blue-grey" class="z-depth-0 d-flex px-3 align-items-center my-0 mr-0" mdbWavesEffect>
        <i class="far fa-fw fa-sliders-h"></i>
      </button>
      <button (click)="onEdit()" *ngIf="isModified" type="button" mdbBtn color="warning" class="z-depth-0 d-flex px-3 align-items-center my-0 mr-0" mdbWavesEffect>
        <i class="far fa-fw fa-sliders-h"></i>
      </button>
    </div>

    <div app-links-item-content-option *ngIf="isStudio && hasRole">
      <button (click)="onStudio()" type="button" mdbBtn color="blue-grey" class="z-depth-0 d-flex px-3 align-items-center my-0 mr-0" mdbWavesEffect>
        <i class="far fa-fw fa-drafting-compass"></i>
      </button>
    </div>

    <div app-links-item-content-option *ngIf="isSlides">
      <button (click)="onSlides()" type="button" mdbBtn color="blue-grey" class="z-depth-0 d-flex px-3 align-items-center my-0 mr-0" mdbWavesEffect>
        <i class="far fa-fw fa-presentation"></i>
      </button>
    </div>

    <div app-links-item-content-option *ngIf="isDisplayable">
      <button (click)="onDisplay()" type="button" mdbBtn color="blue-grey" class="z-depth-0 d-flex px-3 align-items-center my-0 mr-0" mdbWavesEffect>
        <i class="far fa-fw fa-arrow-right"></i>
      </button>
    </div>

    <div app-links-item-content-sub *ngIf="showInfoAndBookmark" class="d-flex flex-row">
      <button [disabled]="!isInfo" (click)="onInformations()" type="button" mdbBtn size="sm" color="info" class="z-depth-0 app-item-strip-infoAndBookmark ml-0 mr-1 mb-0 mt-3 px-2 py-1" mdbWavesEffect>
        <i class="fas fa-info fa-fw"></i>
      </button>
  
      <button *ngIf="!isBookmark" (click)="onBookmark()" type="button" mdbBtn size="sm" color="elegant" class="z-depth-0 app-item-strip-infoAndBookmark ml-0 mr-1 mb-0 mt-3 px-2 py-1" mdbWavesEffect>
        <i class="far fa-bookmark fa-fw"></i>
      </button>
  
      <button *ngIf="isBookmark" (click)="onBookmark()" type="button" mdbBtn size="sm" color="purple" class="z-depth-0 app-item-strip-infoAndBookmark ml-0 mr-1 mb-0 mt-3 px-2 py-1" mdbWavesEffect>
        <i class="fas fa-bookmark fa-fw"></i>
      </button>
    </div>

    <div app-links-item-content-sub>
      <!-- CONTENT DESCRIPTION -->
      <ng-content select="[app-item-strip-content-sub]"></ng-content>
      <!-- ./CONTENT DESCRIPTION -->
    </div>
  </app-links-item>
</div>