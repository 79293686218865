/**
 * @module Dashboard
 */

import { Component, ViewChild, OnDestroy } from '@angular/core';
import { EditorComponent } from '@components/forms/editor/editor.component';
import { ItemColor } from '@functions/item.functions';
import { DashboardClass } from '@class/dashboard.class';
import { DashboardService } from '@services/dashboard/dashboard.service';
import { AuthService } from '@services/auth/auth.service';
import { LanguageType } from '@models/language.model';
import { Subscription } from 'rxjs';
import { UID } from '@models/uid.model';
import { ItemInfoComponent } from '@components/items/item-info/item-info.component';
import { Router } from '@angular/router';
import { UserClass } from '@class/user.class';
import { BrowserFiltersComponent } from '@components/browser/browser-filters/browser-filters.component';

@Component({
  selector: 'app-dashboards',
  templateUrl: './dashboards.component.html',
  styleUrls: ['./dashboards.component.css']
})
export class DashboardsComponent implements OnDestroy {

  @ViewChild('editor', { static: true }) editor: EditorComponent;
  @ViewChild('info', { static: true }) info: ItemInfoComponent; 
  @ViewChild('filtersModal', { static: true }) filtersModal: BrowserFiltersComponent; 

  color = ItemColor('dashboards');
  current: DashboardClass;
  language: LanguageType;
  language$sub: Subscription;
  user: UserClass;
  user$sub: Subscription;
  dashboards: DashboardClass[];
  dashboards$sub: Subscription;

  hasFilters: boolean;
  onlyBookmarks: boolean;
  bookmarks: UID[];
  tagsList: UID[];
  tags: UID[];
  searchLabel: string;

  constructor(
    private $dashboards: DashboardService,
    private $auth: AuthService,
    private $router: Router
  ) {
    this.hasFilters = false;
    this.onlyBookmarks = false;
    this.bookmarks = [];
    this.tags = [];
    this.searchLabel = '';

    this.language = this.$auth.language$.value;
    this.language$sub = this.$auth.language$.subscribe({
      next: (language: LanguageType) => {
        this.language = language;
      }
    });
    
    this.dashboards$sub = this.$dashboards.list$().subscribe({
      next: (dashboards: DashboardClass[]) => {
        if(!!dashboards) {
          this.dashboards = dashboards.sort((a : DashboardClass, b: DashboardClass) => { return a.labels.value(this.language).localeCompare(b.labels.value(this.language)) });
          this.tagsList = dashboards.length === 0 ? [] : dashboards.map(d => d.tags.list as string[])
            .reduce((a, b) => [...a, ...b])
            .filter((item, pos, array) => {
              return array.indexOf(item) == pos;
            });
        }
      }
    });

    this.user$sub = this.$auth.user$.subscribe({
      next: (user: UserClass) => {
        this.user = user;
      }
    });

    this.current = this.$dashboards.create();
  }

  ngOnDestroy() {
    this.dashboards$sub.unsubscribe();
    this.language$sub.unsubscribe();
    this.user$sub.unsubscribe();
  }

  trackByUid(index: number, dashboard: DashboardClass) {
    return dashboard.uid.value;
  }

  receiveCreate() {
    if(this.current.uid.isRegistered) this.current = this.$dashboards.create();
    this.editor.show();
  }

  receiveEdit(uid: UID) {
    this.current = this.dashboards.filter((dashboard: DashboardClass) => {
      return dashboard.uid.value === uid;
    })[0];
    this.editor.show();
  }

  receiveInfo(uid: UID) {
    this.current = this.dashboards.filter((dashboard: DashboardClass) => {
      return dashboard.uid.value === uid;
    })[0];
    this.info.show();
  }

  receiveDisplay(uid: UID) {
    const panel = this.$router.parseUrl(this.$router.url).queryParams.panel;
    this.$router.navigate([`/dashboard-display`], { queryParams: { from: 'browser', panel: panel, uid: uid, slides: false } });
  }

  receiveSlides(uid: UID) {
    const panel = this.$router.parseUrl(this.$router.url).queryParams.panel;
    this.$router.navigate([`/dashboard-display`], { queryParams: { from: 'browser', panel: panel, uid: uid, slides: true } });
  }

  receiveStudio(uid: UID) {
    const panel = this.$router.parseUrl(this.$router.url).queryParams.panel;
    this.$router.navigate([`/dashboard-studio`], { queryParams: { from: 'browser', panel: panel, uid: uid } });
  }

  receiveSearch() {
    this.filtersModal.show();
  }

  receiveBookmark(onlyBookmarks?: boolean) {
    this.onlyBookmarks = onlyBookmarks === undefined ? !this.onlyBookmarks : onlyBookmarks;
    if(this.onlyBookmarks) {
      this.bookmarks = this.user.bookmarks.list('dashboards') as UID[];
    }
    else {
      this.bookmarks = [];
    }
    this.setHasFilters();
  }

  receiveTags(tags: UID[]) {
    this.tags = tags;
    this.setHasFilters();
  }

  receiveSearchLabel(searchLabel: string) {
    this.searchLabel = searchLabel;
    this.setHasFilters();
  }

  onRemoveFilters() {
    this.filtersModal.removeFilters();
    this.bookmarks = [];
    this.onlyBookmarks = false;
    this.tags = [];
    this.searchLabel = '';
    this.setHasFilters();
  }

  setHasFilters() {
    this.hasFilters = this.searchLabel.length > 0
    || this.tags.length > 0
    || this.onlyBookmarks;
  }

}