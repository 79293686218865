/**
 * @module Theme
 */

import { Component, Input } from '@angular/core';
import { ItemColor } from '@functions/item.functions';
import { ThemeOptionsClass } from '@class/themeOptions.class';
import { ChartType } from '@models/chart.model';
import { ThemeOptionType } from '@models/themeStudio.model';

@Component({
  selector: 'app-theme-option-elements-point-hover-border-width',
  templateUrl: './theme-option-elements-point-hover-border-width.component.html',
  styleUrls: ['./theme-option-elements-point-hover-border-width.component.css']
})
export class ThemeOptionElementsPointHoverBorderWidthComponent {

  @Input() themeOptions: ThemeOptionsClass;
  @Input() serieIndex: number;

  showDetails: boolean;
  color = ItemColor('themes');
  optionType: ThemeOptionType = 'elements_point_hoverBorderWidth';

  constructor() {
    this.showDetails = false;
  }

  receiveValue(value: string, type?: ChartType) {
    this.themeOptions.setSeries(this.optionType, value, this.serieIndex, type ? [type] : undefined);
  }

  receiveShowDetails(showDetails: boolean) {
    this.showDetails = showDetails;
  }

}
