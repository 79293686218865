/**
 * @module Backend
 */

import { Injectable } from '@angular/core';
import { TokenService } from '@services/token/token.service';
import { AuthService } from '@services/auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { ServerUrlModel, ServerResponseModel } from '@models/server.model';
import { throwError } from 'rxjs';
import { HttpOptions } from '@functions/server.functions';
import { take, map } from 'rxjs/operators';
import { HandleErrors } from '@functions/errors.functions';
import { UID } from '@models/uid.model';
import { ENVIRONMENT } from '@consts/params.const';
import { FirebaseService } from '@services/firebase/firebase.service';

const ROUTE = 'groupby';

@Injectable({
  providedIn: 'root'
})
export class BackendGroupbyService {

  constructor(
    private $token: TokenService,
    private $auth: AuthService,
    private $http: HttpClient,
    private $firebase: FirebaseService
  ) {}

  async get(uidIndicator: UID, server: ServerUrlModel, group: { [key: string]: any }, match: { [key: string]: any }[]): Promise<ServerResponseModel> {
    
    const data = {
      token: this.$auth.token$.value.token,
      dataset: server.uid,
      table: uidIndicator, 
      group: Object.keys(group),
      match: match.map(m => {
        const column = Object.keys(m)[0];
        const conditionValues = Object.values(m)[0];
        const condition = conditionValues['$in'] !== undefined ? 'IN' : 
          conditionValues['$not'] !== undefined ? 'NOT IN' :
          conditionValues['$gte'] !== undefined ? '>=' :
          conditionValues['$gt'] !== undefined ? '>' :
          conditionValues['$lte'] !== undefined ? '<=' : '<';
        const values = conditionValues['$in'] !== undefined ? conditionValues['$in'] : 
          conditionValues['$not'] !== undefined ? conditionValues['$not']['$in'] :
          conditionValues['$gte'] !== undefined ? conditionValues['$gte'] :
          conditionValues['$gt'] !== undefined ? conditionValues['$gt'] :
          conditionValues['$lte'] !== undefined ? conditionValues['$lte'] : conditionValues['$lt'];
        return {
          column: column,
          condition: condition,
          values: values
        }
      })
    }

    return this.$firebase.angularFireFunctions.httpsCallable('getGroupby')(data).toPromise()
    .then(result => {
      return result;
    })
    .catch(error => {
      console.log(error);
    });


/*

    let tokenData = await this.$token.get(server).catch((error) => { 
      return throwError('OBJECT_NOT_FOUND/SERVER').toPromise();
    });
    if(tokenData) {
      return this.$http.get<ServerResponseModel>(`https://${server.url}/${ROUTE}`,
        HttpOptions(this.$auth.token$.value.token, tokenData.token, 
          { 
            collection: uidIndicator, 
            group: JSON.stringify(group || []),
            match: JSON.stringify(match || []) 
          })
      ).pipe(
        take(1),
        map((response: ServerResponseModel) => {
          if(ENVIRONMENT.type !== 'production') {
            if(response.success) {
              console.groupCollapsed(`Backend GET ${ROUTE} [${uidIndicator}] ['success'] ${response.message.values.length} groupby`);
              console.log(response.message);
              console.groupEnd();
            }
            else {
              console.log(`Backend GET ${ROUTE} [${uidIndicator}] ['error'] ${response.message}`);
            }
          }
          const values = [];
          response.message.values.forEach((element: any) => {
            values.push({
              value: element['value'],
              count: element['count'],
              groups: element['_id']
            })
          });
          response.message.values = values;
          
          return response;
        }),
        HandleErrors()
      ).toPromise();
    }*/
  }

  async delete(uidIndicator: UID, server: ServerUrlModel, match: { [key: string]: any }): Promise<ServerResponseModel> {

    const _match = [];
    for(const m in match) {
      _match.push({ [m]: match[m] });
    }

    const data = {
      token: this.$auth.token$.value.token,
      dataset: server.uid,
      table: uidIndicator, 
      match: Object.keys(match).length > 0 ? _match.map(m => {
        const column = Object.keys(m)[0];
        const conditionValues = Object.values(m)[0];
        const condition = conditionValues['$in'] !== undefined ? 'IN' : 
          conditionValues['$not'] !== undefined ? 'NOT IN' :
          conditionValues['$gte'] !== undefined ? '>=' :
          conditionValues['$gt'] !== undefined ? '>' :
          conditionValues['$lte'] !== undefined ? '<=' : '<';
        const values = conditionValues['$in'] !== undefined ? conditionValues['$in'] : 
          conditionValues['$not'] !== undefined ? conditionValues['$not']['$in'] :
          conditionValues['$gte'] !== undefined ? conditionValues['$gte'] :
          conditionValues['$gt'] !== undefined ? conditionValues['$gt'] :
          conditionValues['$lte'] !== undefined ? conditionValues['$lte'] : conditionValues['$lt'];
        return {
          column: column,
          condition: condition,
          values: values
        }
      }) : []
    }

    return this.$firebase.angularFireFunctions.httpsCallable('deleteGroupby')(data).toPromise()
    .then(result => {
      return result;
    })
    .catch(error => {
      console.log(error);
    });

    /*
    let tokenData = await this.$token.get(server).catch((error) => { 
      return throwError('OBJECT_NOT_FOUND/SERVER').toPromise();
    });
    if(tokenData) {
      return this.$http.delete<ServerResponseModel>(`https://${server.url}/${ROUTE}`,
        HttpOptions(this.$auth.token$.value.token, tokenData.token, 
          { 
            collection: uidIndicator, 
            match: JSON.stringify(match || {}) 
          })
      ).pipe(
        take(1),
        map((response: ServerResponseModel) => {
          if(ENVIRONMENT.type !== 'production') {
            if(response.success) {
              console.groupCollapsed(`Backend DELETE ${ROUTE} [${uidIndicator}] ['success'] ${response.message} groupby`);
              console.log(response.message);
              console.groupEnd();
            }
            else {
              console.log(`Backend DELETE ${ROUTE} [${uidIndicator}] ['error'] ${response.message}`);
            }
          }
          return response;
        }),
        HandleErrors()
      ).toPromise();
    }*/
  }

}