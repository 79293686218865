<div class="w-100 d-flex flex-column">
  <div class="d-flex align-items-center">
    <div class="flex-grow-1">
      <app-moment *ngIf="!layer.bounds.greater.equivalent ; else greaterOrEquivalent" [value]="fromGeneric" [label]="'FROM'" [color]="color" (sendValue)="receiveGreater($event, false)"></app-moment>
    </div>
    <app-links-item-option class="d-block ml-3 mt-2" [symbol]="'greater-than'" [selected]="!layer.bounds.greater.equivalent" (sendSelected)="receiveSetGreater(false)"></app-links-item-option>
    <app-links-item-option class="d-block ml-2 mt-2" [symbol]="'greater-than-equal'" [selected]="layer.bounds.greater.equivalent" (sendSelected)="receiveSetGreater(true)"></app-links-item-option>
  </div>
  <div class="mx-1 font-very-small text-muted">{{ from }}</div>
</div>

<div class="w-100 d-flex flex-column">
  <div class="d-flex align-items-center">
    <div class="flex-grow-1">
      <app-moment *ngIf="!layer.bounds.less.equivalent ; else lessOrEquivalent" [value]="toGeneric" [label]="'TO'" [color]="color" (sendValue)="receiveLess($event, false)"></app-moment>
    </div>
    <app-links-item-option class="d-block ml-3 mt-2" [symbol]="'less-than'" [selected]="!layer.bounds.less.equivalent" (sendSelected)="receiveSetLess(false)"></app-links-item-option>
    <app-links-item-option class="d-block ml-2 mt-2" [symbol]="'less-than-equal'" [selected]="layer.bounds.less.equivalent" (sendSelected)="receiveSetLess(true)"></app-links-item-option>
  </div>
  <div class="mx-1 font-very-small text-muted">{{ to }}</div>
</div>

<ng-template #greaterOrEquivalent>
  <app-moment [value]="fromGeneric" [label]="'FROM'" [color]="color" (sendValue)="receiveGreater($event, true)"></app-moment>
</ng-template>

<ng-template #lessOrEquivalent>
  <app-moment [value]="toGeneric" [label]="'TO'" [color]="color" (sendValue)="receiveLess($event, true)"></app-moment>
</ng-template>