/**
 * @module Widget
 */

import { WidgetDatalabelsModel } from '@models/widgetLayers.model';
import { ModificationClass, ModificationStateClass } from '@class/modification.class';
import { DeepCopy, DeepMerge } from '@functions/copy.functions';
import { NumbroModel } from '@models/numbro.model';
import { WidgetDatalabelsType } from '@models/widget.model';
import { NumbroClass } from '@class/numbro.class';
import { BehaviorSubject } from 'rxjs';
import { WidgetDatalabelsFormat } from '@functions/widget.functions';

export class WidgetDatalabelsClass extends ModificationClass<WidgetDatalabelsModel> {
  
  private _numbro: NumbroClass;

  protected _parent: WidgetDatalabelsModel;
  protected _inital: WidgetDatalabelsModel;
  protected _attributes: string[];

  constructor(widgetLayerDatalabels: WidgetDatalabelsModel, state: ModificationStateClass, modifications$: BehaviorSubject<ModificationStateClass>) {

    super();

    this._parent = widgetLayerDatalabels;
    this._state = state;
    this._modifications$ = modifications$;

    this._reset(this._parent);

    this._attributes = this.attributes.slice();
    if(widgetLayerDatalabels.numbro !== undefined) this._attributes.push(...this._numbro.attributes);

  }

  private _reset(widgetLayerDatalabels: WidgetDatalabelsModel) {
    const model = WidgetDatalabelsFormat(widgetLayerDatalabels.type);
    if(widgetLayerDatalabels.numbro !== undefined || model !== null) {
      this._numbro = new NumbroClass((widgetLayerDatalabels.numbro !== undefined ? widgetLayerDatalabels.numbro : { type: model.type, format: {} }) as NumbroModel, this._state, this._modifications$);
    }

    this.attributes.forEach((attribute: string) => {
      this._parent[attribute] = widgetLayerDatalabels[attribute];
    });

    this._inital = DeepCopy(widgetLayerDatalabels);

    this._state.flush();
    this._modifications$.next(this._state);
  }

  reset() {
    this._state.flush();
    this._reset(this._inital);
  }

  change(attributes: Partial<WidgetDatalabelsModel>, sendMessage?: boolean) {
    this._state.add('reload');
    super.change(attributes, sendMessage, this._parent);
  }

  get model(): Readonly<WidgetDatalabelsModel> {
    if(this._numbro !== undefined) return DeepMerge(super.model, { numbro: this.numbro.model } as WidgetDatalabelsModel);
    else return super.model;
  }

  get attributes(): Readonly<string[]> {
    return ['type', 'display', 'hover', 'rank', 'table', 'tooltips'];
  }

  get class(): string {
    return 'widgetDatalabels';
  }

  get type(): WidgetDatalabelsType {
    return this._parent.type;
  }

  get tooltips(): boolean {
    return this._parent.tooltips;
  }

  get display(): boolean {
    return this._parent.display;
  }

  get hover(): boolean {
    return this._parent.hover;
  }

  get table(): boolean {
    return this._parent.table;
  }

  get rank(): number {
    return this._parent.rank;
  }

  get numbro(): NumbroClass {
    return this._numbro;
  }

}