/**
 * @module Dashboard
 */

import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { DashboardLayoutCellClass } from '@class/dashboardLayoutCell.class';
import { ItemColor } from '@functions/item.functions';
import { UID } from '@models/uid.model';
import { WidgetLayersClass } from '@class/widgetLayers.class';
import { WidgetService } from '@services/widget/widget.service';
import { ThemeService } from '@services/theme/theme.service';
import { WidgetClass } from '@class/widget.class';
import { ThemeOptionsClass } from '@class/themeOptions.class';
import { Subscription } from 'rxjs';
import { ResizeEvent } from 'angular-resizable-element';
import { DashboardLayoutClass } from '@class/dashboardLayout.class';

@Component({
  selector: 'app-dashboard-studio-cell',
  templateUrl: './dashboard-studio-cell.component.html',
  styleUrls: ['./dashboard-studio-cell.component.css']
})
export class DashboardStudioCellComponent implements OnInit, OnDestroy {

  @Input() current: UID;
  @Input() dashboardLayout: DashboardLayoutClass;
  @Input() cell: DashboardLayoutCellClass;
  @Output() sendSelected: EventEmitter<DashboardLayoutCellClass>;

  color = ItemColor('dashboards');

  widgetLayers: WidgetLayersClass;
  widgetNotFound: boolean;

  resizing: boolean;

  widget$sub: Subscription;
  theme$sub: Subscription;
  layers$sub: Subscription;

  constructor(
    private $widgets: WidgetService,
    private $themes: ThemeService
  ) {
    this.sendSelected = new EventEmitter<DashboardLayoutCellClass>();
    this.widgetNotFound = false;
    this.resizing = false;
  }

  ngOnInit() {
    if(this.cell.uidWidget.length > 0) this.setWidgetLayers();
    this.cell.lastModification$.subscribe((attribute: string) => {
      if(attribute === 'uidWidget' && this.cell.uidWidget.length > 0) {
        this.widgetLayers = undefined;
        setTimeout(() => this.setWidgetLayers());
      }
    });
  }

  ngOnDestroy() {
    if(this.widget$sub) this.widget$sub.unsubscribe();
    if(this.theme$sub) this.theme$sub.unsubscribe();
    if(this.layers$sub) this.layers$sub.unsubscribe();
  }

  setWidgetLayers() {
    if(this.widget$sub) this.widget$sub.unsubscribe();
    this.widget$sub = this.$widgets.getPartial$(this.cell.uidWidget).subscribe(
      (widget: WidgetClass) => {
        if(widget !== undefined) {
          this.widgetNotFound = false;
          if(this.theme$sub) this.theme$sub.unsubscribe();
          this.theme$sub = this.$themes.getOptions$(widget.uidTheme).subscribe(
            (theme: ThemeOptionsClass) => {
              if(this.layers$sub) this.layers$sub.unsubscribe();
              this.layers$sub = this.$widgets.getLayers$(widget, theme).subscribe(
                (widgetLayers: WidgetLayersClass) => {
                  this.widgetLayers = widgetLayers;
                }
              )
            }
          )
        }
        else {
          this.widgetNotFound = true;
        }
      }
    );
  }

  onResize() {
    this.resizing = !this.resizing;
  }

  onDisplay(display: boolean) {
    this.cell.change({ display: !this.cell.display });
  }

  onSelected() {
    this.sendSelected.emit(this.cell);
  }

  onResizeEnd(event: ResizeEvent) {
    this.cell.change({ 
      rows: Math.ceil((event.rectangle.height + this.dashboardLayout.gap) / (this.dashboardLayout.size + this.dashboardLayout.gap)), 
      columns: Math.ceil((event.rectangle.width + this.dashboardLayout.gap) / (this.dashboardLayout.size + this.dashboardLayout.gap)) 
    });
  }

}
