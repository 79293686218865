/**
 * @module Dashboard
 */

import { Component, Input, OnDestroy } from '@angular/core';
import { DashboardLayoutClass } from '@class/dashboardLayout.class';
import { FiltersPipeModel } from '@pipes/filters/filters.pipe';
import { ItemColor } from '@functions/item.functions';
import { DashboardClass } from '@class/dashboard.class';
import { AuthService } from '@services/auth/auth.service';
import { LanguageType } from '@models/language.model';
import { Subscription } from 'rxjs';
import { CompanyService } from '@services/company/company.service';
import bbCodeParser from 'js-bbcode-parser';
import { DateToIso } from '@functions/moment.functions';

@Component({
  selector: 'app-dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
  styleUrls: ['./dashboard-layout.component.css']
})
export class DashboardLayoutComponent implements OnDestroy {

  @Input() dashboard: DashboardClass;
  @Input() dashboardLayout: DashboardLayoutClass;
  @Input() slides: boolean;

  color = ItemColor('dashboards');
  filter: { [key: string]: FiltersPipeModel };
  current: number;
  language: LanguageType;
  language$sub: Subscription;
  date: string;
  author: string;
  company: string;

  constructor(
    private $auth: AuthService,
    private $company: CompanyService
  ) {

    this.date = DateToIso(new Date());
    this.author = this.$auth.user$.value.id.fullname;
    this.company = this.$company.label;

    this.language$sub = this.$auth.language$.subscribe({
      next: (language: LanguageType) => {
        this.language = language;
      }
    });

    this.current = -3;

    this.filter = { 
      display: {
        field: 'display',
        type: 'boolean',
        requirements: {
          value: {
            comparaison: '==',
            reference: true
          }
        } 
      } as FiltersPipeModel
    };
  }

  ngOnDestroy() {
    this.language$sub.unsubscribe();
  }

  receiveCurrent(index: number) {
    this.setCurrent(index);
  }

  setCurrent(index: number) {
    this.current = index;
  }

  next() {
    this.current++;
    if(this.current >= this.dashboardLayout.cells.filter(c => c.display).length) this.current = -3;
  }

  previous() {
    this.current--;
    if(this.current < -3) this.current = this.dashboardLayout.cells.filter(c => c.display).length - 1;
  }

  bbCodeParser(text: string): string {
    return bbCodeParser.parse(text || '');
  }

}