<app-dashboard-display-frames #display (sendSlides)="receiveSlides($event)" [slides]="slides">

  <!-- BLOC DASHBOARD -->
  <div #capture app-dashboard-display-frames-item-content *ngIf="dashboardLayout && dashboardLayout?.cells?.length > 0; else app_empty">
    <app-dashboard-layout [dashboardLayout]="dashboardLayout" [slides]="slides" [dashboard]="dashboard"></app-dashboard-layout>
  </div>
  <!-- ./BLOC DASHBOARD -->

  <!-- BLOC PARAMS -->
  <div app-dashboard-display-frames-sub-content>

    <div [ngSwitch]="editorIndex">
      <div *ngSwitchCase="0">
        <app-editor-section-title [title]="'SETTINGS'" (sendNext)="receiveNext($event)"></app-editor-section-title>

        <button (click)="onDownloadPDF()" type="button" block="true" mdbBtn color="light" class="waves-light z-depth-0 blue-grey-text d-flex align-items-center px-4 mt-2" mdbWavesEffect>
          <div class="mr-1">PDF</div>
          <i *ngIf="processingPDF" class="fas fa-circle-notch fa-spin"></i>
          <i class="fas fa-file-pdf fa-fw ml-auto"></i>
        </button>
        
        <button (click)="onDownloadImage()" type="button" block="true" mdbBtn color="light" class="waves-light z-depth-0 blue-grey-text d-flex align-items-center px-4 mt-2" mdbWavesEffect>
          <div class="mr-1">{{ 'IMAGE' | translate }}</div>
          <i *ngIf="processingIMG" class="fas fa-circle-notch fa-spin"></i>
          <i class="fas fa-camera fa-fw ml-auto"></i>
        </button>

        <div class="btn-group my-2 d-flex" mdbDropdown>
          <button mdbDropdownToggle type="button" mdbBtn color="light" class="flex-grow-1 blue-grey-text z-depth-0 dropdown-toggle waves-light d-flex align-items-center px-3" mdbWavesEffect>
            <i class="fas fa-arrow-alt-to-bottom fa-fw"></i>
            <span class="ml-1 mr-auto">Power Point (pptx)</span>&nbsp;
          </button>
        
          <div class="dropdown-menu dropdown-dark dropdown-menu-right">
        
            <a *ngFor="let layout of layouts" class="dropdown-item z-depth-0 d-flex align-items-center" (click)="onDownloadPowerPoint(layout)">
              <span class="mr-1">{{ 'FORMAT' | translate }}</span><mdb-badge color="blue-grey" class="z-depth-0">{{ layout.size }}</mdb-badge>
            </a>
        
          </div>
        </div>

        <hr>
    
        <div *ngIf="dashboardLayout && dashboardLayout.cells.length > 0">
          <app-dashboard-date-format [dashboardLayout]="dashboardLayout"></app-dashboard-date-format>
          <app-dashboard-dates-bounds [dashboardLayout]="dashboardLayout"></app-dashboard-dates-bounds>
          <app-dashboard-filters [dashboardLayout]="dashboardLayout"></app-dashboard-filters>
        </div>

      </div>
      <div *ngSwitchCase="1">
        <app-editor-section-title [title]="'COMMENTS'" (sendNext)="receiveNext($event)"></app-editor-section-title>
        
        <app-comments [color]="color" [uid]="dashboard.uid.value"></app-comments>
      </div>
    </div>
  </div>
  <!-- ./BLOC PARAMS -->

</app-dashboard-display-frames>

<ng-template #app_empty>
  <div *ngIf="dashboardLayout && dashboardLayout.cells.length == 0"><app-empty [action]="false"></app-empty></div>
  <div *ngIf="!dashboardLayout" class="app-widget-display-central"><app-loading></app-loading></div>
</ng-template>