/**
 * @module Indicator
 */

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UID } from '@models/uid.model';
import { DateClass } from '@class/date.class';
import { ItemColor, ItemIcon } from '@functions/item.functions';

@Component({
  selector: 'app-indicator-studio-date',
  templateUrl: './indicator-studio-date.component.html',
  styleUrls: ['./indicator-studio-date.component.css']
})
export class IndicatorStudioDateComponent {

  @Input() date: DateClass;
  @Output() sendSelected: EventEmitter<UID>;

  selected: boolean;
  color = ItemColor('dates', true);
  icon = ItemIcon('dates');

  constructor() {
    this.sendSelected = new EventEmitter<UID>();
    this.selected = false;
  }

  receiveSelected() {
    this.selected = !this.selected;
    this.sendSelected.emit(this.date.uid.value);
  }

}
