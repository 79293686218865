/**
 * @module Theme
 */

import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[app-theme-options-host]'
})
export class ThemeOptionsDirective {
  constructor(public viewContainerRef: ViewContainerRef) {}
}
