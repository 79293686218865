<div *ngIf="params == undefined; else chart_container" style="height: 100%;">
  <app-widget-loading></app-widget-loading>
</div>

<ng-template #loadingtab>
  <app-widget-loading></app-widget-loading>
</ng-template>


<ng-template #sheet_container>
  <table class="sheet-table">
    <tbody class="sheet-tbody" *ngFor="let serie of result.series">
      <tr *ngIf="!!serie.tab && serie.tab.length > 0">
        <td class="sheet-td-head">-</td>
        <td class="sheet-td-head" *ngFor="let label of tabLabels | keyvalue">{{ label.key == 'value' ? ('VALUE' | translate) : widgetLayers.layers[label.key].layerLabel.value(language) }}</td>
      </tr>
      <tr *ngFor="let line of serie.tab || []"><td class="sheet-td-head" >{{ serie.label }}</td><td class="sheet-td" *ngFor="let item of line | keyvalue">{{ item.value }}</td></tr>
    </tbody>
  </table>
</ng-template>

<ng-template #chart_container>
  <div [ngStyle]="{ 'background-color': params.options.layout.backgroundColor }" class="app-widget-studio-chart-full-container h-100">
    <div class="app-widget-studio-chart-container" [ngStyle]="{ 'background-color': params.options.layout.backgroundColor, 'height': params?.pages > 1 ? 'calc(100% - 35px)' : '100%' }">
      
      <div data-html2canvas-ignore="true" class="app-widget-studio-chart-options-top-left m-1 d-flex">
        <app-widget-drill-down (sendOpenDrill)="receiveOpenDrill()" [mdbTooltip]="'DIG_INTO' | translate" placement="right" [labels]="params.labels" [vanila]="params.datasets[0].vanila" [widgetLayers]="widgetLayers" class="app-widget-studio-chart-options"></app-widget-drill-down>
        <button style="min-height: 31px; min-width: 30px;" *ngIf="widgetLayers.drillFunnel.length > 1" (click)="widgetLayers.drillRoot()" type="button" size="sm" mdbBtn color="light" class="blue-grey-text waves-light z-depth-0 px-2 my-0 ml-1 mr-0 app-widget-studio-chart-options" mdbWavesEffect>
          <i class="fas fa-reply-all fa-fw"></i>
        </button>
        <button style="min-height: 31px; min-width: 30px;" *ngIf="widgetLayers.drillFunnel.length > 0" (click)="widgetLayers.drillUp()" type="button" size="sm" mdbBtn color="light" class="blue-grey-text waves-light z-depth-0 px-2 my-0 ml-1 mr-0 app-widget-studio-chart-options" mdbWavesEffect>
          <i class="fas fa-reply fa-fw"></i>
        </button>
      </div>

      <div data-html2canvas-ignore="true" class="app-widget-studio-chart-options-top-right m-1">
        <button (click)="onFullscreen()" type="button" size="sm" mdbBtn color="light" class="blue-grey-text waves-light z-depth-0 px-2 m-0 app-widget-studio-chart-options app-widget-studio-chart-options-goto-left" mdbWavesEffect>
          <i class="fas fa-expand fa-fw"></i>
        </button>
      </div>

      <div data-html2canvas-ignore="true" class="app-widget-studio-chart-options-bottom-left m-1">
        <button [mdbTooltip]="'COMMENTS' | translate" placement="top" (click)="onSidescreen(1)" type="button" size="sm" mdbBtn color="light" class="blue-grey-text waves-light z-depth-0 px-2 m-0 app-widget-studio-chart-options app-widget-studio-chart-options-goto-top" mdbWavesEffect>
          <i class="fa-comment-alt fa-fw" [ngClass]="{ 'fas': comments?.values.length > 0, 'fal': comments == undefined || comments?.values.length == 0 }"></i>
        </button>
      </div>

      <div data-html2canvas-ignore="true" class="app-widget-studio-chart-options-bottom-right m-1">
        <div class="app-widget-studio-chart-options-goto-top-left d-flex" style="min-width: 68px;">
          <button style="min-height: 31px; min-width: 30px;" [mdbTooltip]="'DESIGNER_FILTERS_AND_DATES' | translate" placement="top" (click)="onSidescreen(4)" type="button" size="sm" mdbBtn color="light" class="blue-grey-text waves-light z-depth-0 px-2 my-0 ml-1 mr-0 app-widget-studio-chart-options"mdbWavesEffect>
            <span class="fa-fw">
              <i class="fa-filter" [ngClass]="{ 'fas': widgetLayers.hasIndicatorsFilters || widgetLayers.hasLayersBounds, 'fal': !widgetLayers.hasIndicatorsFilters && !widgetLayers.hasLayersBounds }"></i>
              <i class="fas fa-lock app-opacity-75 app-widget-studio-chart-stacked-icon"></i>
            </span>
          </button>
          <button style="min-height: 31px; min-width: 30px;" [mdbTooltip]="'FILTERS_AND_DATES' | translate" placement="top" (click)="onSidescreen(2)" type="button" size="sm" mdbBtn color="light" class="blue-grey-text waves-light z-depth-0 px-2 my-0 ml-1 mr-0 app-widget-studio-chart-options"mdbWavesEffect>
            <i class="fa-filter fa-fw" [ngClass]="{ 'fas': widgetLayers.hasFilters || widgetLayers.hasBounds, 'fal': !widgetLayers.hasFilters && !widgetLayers.hasBounds }"></i>
          </button>
        </div>
      </div>

      <div data-html2canvas-ignore="true" class="app-widget-studio-chart-options-top d-flex justify-content-center">
        <div *ngIf="loading">
          <mdb-badge pill="true" color="deep-orange lighten-2" class="z-depth-0 app-opacity-75 white-text"><i class="fas fa-circle-notch fa-spin mr-1"></i>{{ 'LOADING' | translate }}</mdb-badge>
        </div>
      </div>
    
      <div ngSwitch #capture class="app-widget-studio-chart-canvas" style="overflow: auto;">
        <div *ngIf="seeChart; else sheet_container"></div>
        <canvas *ngIf="seeChart && params.chartType != 'sticker' && params.chartType != 'gauge'" baseChart #chart="base-chart" class="app-widget-studio-chart-canvas" 
          [datasets]="params.datasets"
          [labels]="params.labels"
          [options]="params.options"
          [chartType]="params.chartType"
          [plugins]="params.plugins">
        </canvas>
        <app-widget-studio-chart-sticker *ngIf="seeChart && params && params.chartType == 'sticker'" [params]="params"></app-widget-studio-chart-sticker>
        <app-widget-studio-chart-gauge *ngIf="seeChart && params && params.chartType == 'gauge'" [params]="params" [uid]="widgetLayers.uid.value"></app-widget-studio-chart-gauge>
      </div>

    </div>

    <div class="app-widget-studio-chart-pages px-1 pb-1 pt-0 d-flex" *ngIf="params?.pages > 1">
      <button (click)="removePage()" type="button" size="sm" mdbBtn color="light" class="blue-grey-text waves-light z-depth-0 px-2 py-1 my-0 ml-0 mr-1" mdbWavesEffect>
        <i class="fas fa-angle-left fa-fw"></i>
      </button>
      <div app-sortable id="pagesID" class="isSortableContainer app-sortable-container d-flex flex-grow-1 align-items-center p-1 h-100" (sendSortableSort)="receiveSortableSort($event)" (sendSortableStop)="receiveSortableStop($event)">
        <div [id]="p.page" class="isSortableElement flex-grow-1 d-flex justify-content-center align-items-center h-100"
            [ngClass]="{ 'app-cursor-grab white': p.page == page, 'app-widget-studio-chart-page isSortableDisabled': p.page != page }"
            *ngFor="let p of pages"><i *ngIf="p.page == page" class="far fa-arrows-alt-h blue-grey-text"></i></div>
      </div>
      <div class="font-small font-weight-bolder px-2 d-flex align-items-center blue-grey-text">
        <app-number [width]="48" [value]="hoverPage" [withButtons]="false" [min]="1" [max]="params.pages" [color]="'blue-grey'" (sendValue)="receivePage($event)"></app-number>
        &nbsp;/&nbsp;{{ params.pages }}
      </div>
      <button (click)="addPage()" type="button" size="sm" mdbBtn color="light" class="blue-grey-text waves-light z-depth-0 px-2 py-1 my-0 ml-1 mr-0" mdbWavesEffect>
        <i class="fas fa-angle-right fa-fw"></i>
      </button>
    </div>
  </div>
</ng-template>

<div data-html2canvas-ignore="true" mdbModal #fluidModal="mdbModal" class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-fluid modal-full-height modal-top m-0 p-0" role="document">
    <div *ngIf="fluidModal.isShown" class="modal-content" style="height: 100vh;">
      <div class="modal-header">

        <div class="btn-group app-widget-studio-chart-dropdown" mdbDropdown>
          <button mdbDropdownToggle type="button" size="sm" mdbBtn color="light" class="blue-grey-text z-depth-0 dropdown-toggle waves-light d-flex align-items-center px-3" mdbWavesEffect>
            <i class="far fa-bars"></i>&nbsp;
          </button>
        
          <div class="dropdown-menu dropdown-dark dropdown-menu-right">
      
            <a class="dropdown-item z-depth-0" (click)="editorIndex = 0">{{ 'INFO_AND_PARAMS' | translate }}</a>
            <a class="dropdown-item z-depth-0" (click)="editorIndex = 1">{{ 'COMMENTS' | translate }}</a>
            <a class="dropdown-item z-depth-0" (click)="editorIndex = 2">{{ 'FILTERS_AND_DATES' | translate }}</a>
            <a class="dropdown-item z-depth-0" (click)="editorIndex = 3">{{ 'ANALYSIS_ORDER' | translate }}</a>
            <a class="dropdown-item z-depth-0" (click)="editorIndex = 4">{{ 'DESIGNER_FILTERS_AND_DATES' | translate }}</a>
      
          </div>
        </div>
        
        <button type="button" size="sm" mdbBtn color="secondary" class="waves-light z-depth-0 px-2 m-0 app-close-modal" (click)="onClose()" mdbWavesEffect>
          <i class="fas fa-times fa-fw"></i>
        </button>
        
        <button *ngIf="isMoreOptions" type="button" size="sm" mdbBtn color="default" class="waves-light z-depth-0 px-2 m-0 app-widget-studio-chart-sub-button" (click)="onMoreOptions()" mdbWavesEffect>
          <i class="fad fa-window-maximize fa-rotate-90 fa-fw"></i>
        </button>
        
        <button *ngIf="!isMoreOptions" type="button" size="sm" mdbBtn color="light" class="blue-grey-text waves-light z-depth-0 px-2 m-0 app-widget-studio-chart-sub-button" (click)="onMoreOptions()" mdbWavesEffect>
          <i class="fad fa-window-maximize fa-rotate-90 fa-fw"></i>
        </button>

        <h5 class="font-weight-bolder blue-grey-text modal-title w-100">{{ params.options.title.textInline }}</h5>
      </div>
      <div class="modal-body d-flex">
        <div class="row no-gutters w-100">
          <div class="col-12 app-widget-studio-chart-modal-main" [ngClass]="{ 'overflow-scroll': !seeChart }" >
            <div *ngIf="params == undefined; else chart_container">
              <app-widget-loading></app-widget-loading>
            </div>
          </div>
        </div>
        <div class="app-widget-studio-chart-modal-sub ml-3" *ngIf="isMoreOptions">
          <app-widget-options-menu [valuesTab]="valuesTab" [capture]="capture" [(seeChart)]="seeChart" (seeChartChange)="setSeeChart($event)" (sendEditorIndex)="receiveEditorIndex($event)" [widgetLayers]="widgetLayers" [editorIndex]="editorIndex" [params]="params" [chart]="chart"></app-widget-options-menu>
        </div>
      </div>
    </div>
  </div>
</div>

<div data-html2canvas-ignore="true" mdbModal #sideModal="mdbModal" class="modal fade left" tabindex="-1" role="dialog"
  [config]="{ backdrop: false, ignoreBackdropClick: true }" style="overflow-y: auto; max-width: 420px;">
  <div class="modal-dialog modal-left modal-full-height" role="document">
    <div class="modal-content" *ngIf="sideModal.isShown">
      <div class="modal-header">

        <button type="button" size="sm" mdbBtn color="secondary" class="waves-light z-depth-0 px-2 m-0 app-close-modal" (click)="onClose()" mdbWavesEffect>
          <i class="fas fa-times fa-fw"></i>
        </button>

        <div class="btn-group app-widget-studio-chart-dropdown-side" mdbDropdown>
          <button mdbDropdownToggle type="button" size="sm" mdbBtn color="light" class="blue-grey-text z-depth-0 dropdown-toggle waves-light d-flex align-items-center px-3" mdbWavesEffect>
            <i class="far fa-bars"></i>&nbsp;
          </button>
        
          <div class="dropdown-menu dropdown-dark dropdown-menu-right">
      
            <a class="dropdown-item z-depth-0" (click)="editorIndex = 0">{{ 'INFO_AND_PARAMS' | translate }}</a>
            <a class="dropdown-item z-depth-0" (click)="editorIndex = 1">{{ 'COMMENTS' | translate }}</a>
            <a class="dropdown-item z-depth-0" (click)="editorIndex = 2">{{ 'FILTERS_AND_DATES' | translate }}</a>
            <a class="dropdown-item z-depth-0" (click)="editorIndex = 3">{{ 'ANALYSIS_ORDER' | translate }}</a>
            <a class="dropdown-item z-depth-0" (click)="editorIndex = 4">{{ 'DESIGNER_FILTERS_AND_DATES' | translate }}</a>
      
          </div>
        </div>

        <h5 class="font-weight-bolder blue-grey-text modal-title w-100">{{ params.options.title.textInline }}</h5>
        
      </div>
      <div class="modal-body">
        <app-widget-options-menu (sendComments)="receiveComments($event)" [capture]="capture" (sendEditorIndex)="receiveEditorIndex($event)" [widgetLayers]="widgetLayers" [editorIndex]="editorIndex" [params]="params" [chart]="chart"></app-widget-options-menu>
      </div>
    </div>
  </div>
</div>