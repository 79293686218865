/**
 * @module Link
 */

import { LinksModel, LinkType, LinkableType, LinksReplaceModel } from '@models/links.model';
import { MapClass } from '@class/map.class';
import { UID } from '@models/uid.model';
import { ModificationClass, ModificationStateClass } from '@class/modification.class';
import { DeepCopy, DeepMerge } from '@functions/copy.functions';
import { DeleteFieldValue } from '@functions/firebase.functions';

export class LinksClass extends ModificationClass<LinksModel> {

  protected _parent: LinksModel;
  protected _inital: LinksModel;
  protected _attributes: string[];
  protected _replaced: { LinkType : { [key in UID]: LinksReplaceModel } };

  private _links: MapClass<MapClass<LinkType>>;

  constructor(links: LinksModel, state: ModificationStateClass) {

    super();
    
    this._parent = links;
    this._attributes = this.attributes;
    this._state = state;
    
    let maps = {} as { [key: string]: MapClass<LinkType>};
    for(const linkable in this._parent.links) {
      maps[linkable] = new MapClass<LinkType>(this._parent.links[linkable]);
    }
    this._links = new MapClass(maps);
    this._replaced = {} as { LinkType : { [key in UID]: LinksReplaceModel } };

    this._inital = DeepCopy({ links: this._parent.links });
  }

  get class(): Readonly<string> {
    return 'links';
  }

  get attributes(): string[] {
    return ['links'];
  }

  private checkLinkType(linkable: LinkableType) {
    if(!this._links.has(linkable)) {
      this._links.add(linkable, new MapClass<LinkType>({}));
    }
    if(this._parent.links[linkable] === undefined) this._parent.links[linkable] = {};
    if(this._replaced[linkable] === undefined) this._replaced[linkable] = {};
  }

  list(linkable: LinkableType): Readonly<string[]> {
    this.checkLinkType(linkable);
    return this._links.value(linkable).keys;
  }

  has(linkable: LinkableType, uid: UID): Readonly<boolean> {
    this.checkLinkType(linkable);
    return this._links.value(linkable).has(uid);
  }

  isReplaced(linkable: LinkableType, uid: UID): Readonly<boolean> {
    this.checkLinkType(linkable);
    return this._replaced[linkable][uid] !== undefined;
  }

  isNewItem(linkable: LinkableType, uid: UID): Readonly<boolean> {
    this.checkLinkType(linkable);
    return -1 !== Object.values(this._replaced[linkable]).map((r: LinksReplaceModel) => r.newItem['uid'].value).indexOf(uid);
  }

  add(linkable: LinkableType, uid: UID) {
    this.checkLinkType(linkable);
    this._links.value(linkable).add(uid, { [uid]: true });
    this._parent.links[linkable] = DeepMerge(this._parent.links[linkable], this._links.value(linkable).value(uid));
    this.emit(this.attributes);
  }

  remove(linkable: LinkableType, uid: UID) {
    this.checkLinkType(linkable);
    this._links.value(linkable).remove(uid);
    this._parent.links[linkable][uid] = DeleteFieldValue() as boolean;
    this.emit(this.attributes);
  }

  replace(linkable: LinkableType, uidInitial: UID, uidReplace: UID, newItem?: ModificationClass<any>) {
    this.remove(linkable, uidInitial);
    this.add(linkable, uidReplace);
    const index = Object.values<{ uid: UID, newItem: ModificationClass<any> }>(this._replaced[linkable])
                        .map(r => r.uid)
                        .indexOf(uidInitial);
    if(index !== - 1) {
      //exemple on avait { A : B } et on reçoit { B : C } => on veut au final { A : C } et non pas { A : B, B : C }
      const uid = Object.keys(this._replaced[linkable])[index];
      if(uid !== uidReplace) this._replaced[linkable][uid] = { uid: uidReplace, newItem: newItem };
      else delete this._replaced[linkable][uid]; //si on a { A : A } la ligne n'est pas nécéssaire
    }
    else this._replaced[linkable][uidInitial] = { uid: uidReplace, newItem: newItem };
  }

  removed(linkable: LinkableType): Readonly<{ [key in UID]: string }> {
    const removed = {};
    for(const uid in this._parent.links[linkable]) {
      if(this._parent.links[linkable][uid] !== true && this._replaced[linkable][uid] === undefined) removed[uid] = '';
    }
    return removed;
  }

  replaced(linkable: LinkableType): Readonly<{ [key in UID]: string }> {
    this.checkLinkType(linkable);
    const replaced = {};
    for(const uid in this._replaced[linkable]) {
      replaced[uid] = this._replaced[linkable][uid].uid;
    }
    return replaced;
  }

  newItems(linkable: LinkableType): Readonly<ModificationClass<any>[]> {
    this.checkLinkType(linkable);
    const newItems = [];
    for(const uid in this._replaced[linkable]) {
      newItems.push(this._replaced[linkable][uid].newItem);
    }
    return newItems;
  }
}