/**
 * @module Import
 */

import { Component, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { ItemsColors } from '@functions/item.functions';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { PivotClass } from '@class/pivot.class';
import { DateClass } from '@class/date.class';
import { IndicatorClass } from '@class/indicator.class';
import { UID } from '@models/uid.model';
import { PivotService } from '@services/pivot/pivot.service';
import { take, tap } from 'rxjs/operators';
import { DateService } from '@services/date/date.service';
import { IndicatorService } from '@services/indicator/indicator.service';

@Component({
  selector: 'app-import-links-change',
  templateUrl: './import-links-change.component.html',
  styleUrls: ['./import-links-change.component.css']
})
export class ImportLinksChangeComponent {

  @Input() item: PivotClass | DateClass | IndicatorClass;
  @Input() withButton: boolean = true;
  @Output() sendSelected: EventEmitter<PivotClass | DateClass | IndicatorClass>;
  @ViewChild('linkModal', { static: true }) linkModal: ModalDirective;

  uid: UID;
  colors = ItemsColors();
  linking: boolean;

  constructor(
    private $pivot: PivotService,
    private $date: DateService,
    private $indicator: IndicatorService
  ) {
    this.linking = false;
    this.sendSelected = new EventEmitter<PivotClass | DateClass | IndicatorClass>();
  }

  public onShow() {
    this.linking = false;
    this.uid = this.item.uid.value;
    this.linkModal.show();
  }

  onSelect() {
    this.linkModal.hide();
    if(this.item.uid.value !== this.uid) {
      if(this.item.uid.isRegistered) {
        this.linking = true;
        if(this.item.class === 'pivot') {
          this.$pivot.get$(this.item.uid.value).pipe(
            take(1),
            tap((pivot: PivotClass) => {
              this.linking = false;
              this.sendSelected.emit(pivot);
            })
          ).subscribe();
        }
        else if(this.item.class === 'date') {
          this.$date.get$(this.item.uid.value).pipe(
            take(1),
            tap((date: DateClass) => {
              this.linking = false;
              this.sendSelected.emit(date);
            })
          ).subscribe();
        }
        else if(this.item.class === 'indicator') {
          this.$indicator.get$(this.item.uid.value).pipe(
            take(1),
            tap((indicator: IndicatorClass) => {
              this.linking = false;
              this.sendSelected.emit(indicator);
            })
          ).subscribe();
        }
      }
      else {
        this.sendSelected.emit(this.item);
      }
    }
  }

  receiveSelected(item: PivotClass | DateClass | IndicatorClass) {
    this.item = item;
  }

}
