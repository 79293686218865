/**
 * @module Widget
 */

import { WidgetLayerModel, WidgetLayersModel, WidgetIndicatorModel, WidgetLayersDefinedOptionModel, WidgetDatalabelsModel, WidgetChartParamsModel, OffsetDateModel } from '@models/widgetLayers.model';
import { UID, UidModel } from '@models/uid.model';
import { ModificationClass, ModificationStateClass } from '@class/modification.class';
import { LabelsClass } from '@class/labels.class';
import { LabelsModel } from '@models/labels.model';
import { DeepCopy, DeepMerge } from '@functions/copy.functions';
import { ThemeOptionsClass } from '@class/themeOptions.class';
import { Subscription } from 'rxjs';
import { OnDestroy } from '@angular/core';
import { SetLabels } from '@functions/labels.functions';
import { WidgetIndicatorClass } from '@class/widgetIndicator.class';
import { WidgetLayerClass } from '@class/widgetLayer.class';
import { Options } from 'chartjs-plugin-datalabels/types/options';
import { LanguageType } from '@models/language.model';
import { Label } from 'ng2-charts';
import { ChartDataSets, ChartLegendLabelItem, ChartData, ChartTooltipItem, ChartXAxe, ChartYAxe } from 'chart.js';
import { ChartType } from '@models/chart.model';
import { UidClass } from '@class/uid.class';
import { WidgetDatalabelsClass } from '@class/widgetDatalabels.class';
import { WidgetDatalabelsTableModel } from '@models/widgetDatalabelsTable.model';
import { PivotFiltersClass } from '@class/widgetFilters.class';
import { WidgetDatesBoundsClass } from '@class/widgetDatesBounds.class';
import { DateBoundsDefault } from '@functions/widget.functions';
import { ColorsService } from '@services/colors/colors.service';
import { NumbroModel } from '@models/numbro.model';
import { Average } from '@functions/math.functions';
import { NumbroApplyFormat } from '@functions/numbro.functions';

export class WidgetLayersClass extends ModificationClass<WidgetLayersModel> implements OnDestroy {

  private _uid: UidClass;
  private _indicators: WidgetIndicatorClass[];
  private _modifications$sub: Subscription[];
  private _layers: { [key in UID]: WidgetLayerClass };
  private _mainLayer: WidgetLayerClass;
  private _order: UID[];
  private _drill: number;
  private _drillFunnel: { key: string, value: string, vanila: string, labels: LabelsClass }[];
  private _chartTitle: LabelsClass;
  private _themeOptions: ThemeOptionsClass;
  private $colors: ColorsService;
  private _offsetDate: OffsetDateModel;
  private _offsetDateDefault: OffsetDateModel;
  private _gettab: boolean = false;

  //début - attributs de customisation utilisateurs (non enregistré en base de données)
  private _filters: PivotFiltersClass[];
  private _maxValues: number;
  private _bounds: { [key in UID]: WidgetDatesBoundsClass };
  private _dateFormat: string;
  //fin - attributs de customisation utilisateurs

  protected _parent: WidgetLayersModel;
  protected _inital: WidgetLayersModel;
  protected _attributes: string[];

  constructor(widgetLayers: WidgetLayersModel, themeOptions: ThemeOptionsClass, $colors: ColorsService) {

    super();

    this._parent = widgetLayers;
    this.$colors = $colors;

    this._reset(this._parent, themeOptions);

    this._attributes = this.attributes.slice();
    this._attributes.push(...this._uid.attributes);

  }

  private _reset(widgetLayers: WidgetLayersModel, themeOptions: ThemeOptionsClass, reload: boolean = false) {
    this._uid = new UidClass(widgetLayers as UidModel, true);

    this._order = [];
    this._drill = 0;
    this._drillFunnel = [];
    this._filters = [];
    this._bounds = {};
    this._dateFormat = '';

    this._themeOptions = themeOptions;
    
    this._chartTitle = new LabelsClass(widgetLayers.chartTitle as LabelsModel, this._form, this._requirements, this._state, 'chartTitle');

    this.attributes.forEach((attribute: string) => {
      this._parent[attribute] = widgetLayers[attribute];
    });

    this._inital = DeepCopy(widgetLayers);
    if(this._modifications$sub !== undefined && this._modifications$sub.length > 0) this._modifications$sub.forEach((sub: Subscription) => sub.unsubscribe());
    this._modifications$sub = [];

    this._indicators = widgetLayers.indicators.map((indicator: WidgetIndicatorModel) => {
      let i = new WidgetIndicatorClass(indicator, this._state);
      this._modifications$sub.push(i.modifications$.subscribe({ next: m => this._emit(m) }));
      return i;
    });

    if(widgetLayers.layers === undefined) widgetLayers.layers = {};
    this._layers = {};
    for(let uid in widgetLayers.layers) {
      this._layers[uid] = new WidgetLayerClass(widgetLayers.layers[uid], this._state, this._themeOptions);
      this._modifications$sub.push(this._layers[uid].modifications$.subscribe({ next: m => this._emit(m) }));
      if(this._layers[uid].layerType === 'dates') {
        this._bounds[uid] = new WidgetDatesBoundsClass(DateBoundsDefault(uid), this._state, this._modifications$, this._lastModification$);
      }
    }

    this._offsetDate = widgetLayers.offsetDate;

    if(widgetLayers.mainLayer.uid === undefined) {
      widgetLayers.mainLayer = { 
        uid: widgetLayers.uid,
        layerLabel: { labels: SetLabels('TOTAL') },
        layerType: null,
        chartTitle: DeepCopy(this._chartTitle.model),
        drillable: null,
        rank: null,
        definedOptions: [],
        chartType: 'bar',
        sort: 'label_asc',
        sortUID: '',
        indicators: []
      } as WidgetLayerModel;
    }
    widgetLayers.mainLayer.uid = widgetLayers.uid;

    this._mainLayer = new WidgetLayerClass(widgetLayers.mainLayer, this._state, this._themeOptions);
    this._modifications$sub.push(this._mainLayer.modifications$.subscribe({ next: m => this._emit(m) }));
    
    this._modifications$sub.push(this._mainLayer.themeOptions.changedDefinedOption$.subscribe({ 
      next: (definedOption: WidgetLayersDefinedOptionModel) => {
        const _definedOption = this._mainLayer.definedOption(definedOption);
        this.duplicateDefinedOption(_definedOption || definedOption, this._mainLayer.uid.value, _definedOption === undefined);
      }
    }));

    this._order = Object.values(widgetLayers.layers).sort((a, b) => a.rank - b.rank).map(l => l.uid);

    this._state.flush();
    if(reload === true) {
      this._state.add('reload');
      this._modifications$.next(this._state);
    }
    this._state.flush();
    this._modifications$.next(this._state);
  }

  ngOnDestroy() {
    if(this._modifications$sub !== undefined && this._modifications$sub.length > 0) this._modifications$sub.forEach((sub: Subscription) => sub.unsubscribe());
  }

  reset(widgetLayers?: WidgetLayersModel, themeOptions?: ThemeOptionsClass) {
    this._state.flush();
    this._reset(widgetLayers !== undefined ? widgetLayers : this._inital, themeOptions !== undefined ? themeOptions : this._themeOptions, true);
  }

  get gettab(): boolean {
    return this._gettab;
  }

  set gettab(gettab: boolean) {
    this._gettab = gettab;
    if(this._gettab) this.emit(['gettab', 'reload']);
  }

  get uid(): UidClass {
    return this._uid;
  }

  get filters(): PivotFiltersClass[] {
    return this._filters;
  }

  get hasFilters(): Readonly<boolean> {
    return this._filters.filter(f => f.values.length > 0).length > 0;
  }

  get hasBounds(): Readonly<boolean> {
    return Object.values(this._bounds).filter(b => b.hasAny).length > 0;
  }

  get hasIndicatorsFilters(): Readonly<boolean> {
    return Object.values(this._indicators).filter(i => i.filters.length > 0).length > 0;
  }

  get hasLayersBounds(): Readonly<boolean> {
    return Object.values(this._layers).filter(l => l.layerType === 'dates' && l.bounds.hasAny).length > 0;
  }

  filter(uid: UID): Readonly<PivotFiltersClass> {
    const index = this._filters.map((filter: PivotFiltersClass) => filter.uid.value).indexOf(uid);
    if(index !== -1) return this._filters[index];
    else {
      const filter = {
        uid: uid,
        include: true,
        values: []
      };
      const index = this._filters.push(new PivotFiltersClass(filter, this._state, this._modifications$, this._lastModification$));
      return this._filters[index - 1];
    }
  }

  get formulasUID(): Readonly<UID[]> {
    return this._indicators.map(i => i.formulaUID);
  }

  get dateFormat(): Readonly<string> {
    return this._dateFormat;
  }

  set dateFormat(dateFormat: string) {
    this._dateFormat = dateFormat || '';
    this.emit(['dateFormat', 'reload']);
  }

  eraseOffsetDate() {
    this._offsetDate = null;
    this.emit(['offsetDate', 'reload']);
  }

  isOffsetDateDefault(): Boolean {
    return !this._offsetDate;
  }

  get offsetDate(): OffsetDateModel {
    return this._offsetDate ? this._offsetDate : this._offsetDateDefault;
  }

  set offsetDate(offsetDate: OffsetDateModel) {
    this._offsetDate = offsetDate;
    this.emit(['offsetDate', 'reload']);
  }

  set offsetDateDefault(offsetDate: OffsetDateModel) {
    this._offsetDateDefault = offsetDate;
  }

  get bounds(): Readonly<{ [key in UID]: WidgetDatesBoundsClass }> {
    return this._bounds;
  }

  get maxValues(): Readonly<number> {
    return this._maxValues;
  }

  set maxValues(maxValues: number) {
    this._maxValues = maxValues;
    this.emit(['maxValues', 'reload']);
  }

  get pivots(): Readonly<UID[]> {
    return Object.values(this.layers).filter((layer: WidgetLayerClass) => {
      return layer.layerType === 'pivots';
    }).map((layer: WidgetLayerClass) => {
      return layer.uid.value; 
    });
  }

  get dates(): Readonly<UID[]> {
    return Object.values(this.layers).filter((layer: WidgetLayerClass) => {
      return layer.layerType === 'dates';
    }).map((layer: WidgetLayerClass) => {
      return layer.uid.value; 
    });
  }

  get themeOptions(): ThemeOptionsClass {
    return this._themeOptions;
  }

  get attributes(): Readonly<string[]> {
    return ['indicators', 'layers', 'chartTitle', 'mainLayer', 'offsetDate'];
  }

  get class(): Readonly<string> {
    return 'widgetLayers';
  }

  get chartTitle(): LabelsClass {
    return this._chartTitle;
  }

  layerTitle(layerUID: UID): LabelsClass {
    return this.layer(layerUID).chartTitle;
  }

  layer(layerUID?: UID): WidgetLayerClass {
    const layer = this._layers[layerUID];
    if(layer !== undefined) return this._layers[layerUID];
    else return this._mainLayer;
  }

  isLayer(layerUID: UID): Readonly<boolean> {
    return this._layers[layerUID] !== undefined;
  }

  get layers(): { [key in UID]: WidgetLayerClass } {
    return this._layers;
  }

  get model(): Readonly<WidgetLayersModel> {
    const layers = {};
    for(const uid in this._layers) {
      layers[uid] = this._layers[uid].model;
    }
    return {
      uid: this._uid.value,
      indicators: this.indicators.map(i => i.model),
      layers: layers,
      mainLayer: this._mainLayer.model,
      chartTitle: this._chartTitle.model,
      offsetDate: this._offsetDate === undefined ? null : this._offsetDate
    };
  }

  get indicators(): WidgetIndicatorClass[] {
    return this._indicators;
  }

  indicator(uid: UID): WidgetIndicatorClass {
    const result = this._indicators.filter((indicator: WidgetIndicatorClass) => indicator.uid.value === uid);
    return result.length > 0 ? result[0] : undefined;
  }

  indicatorByFormulaUID(formulaUID: string): WidgetIndicatorClass {
    const result = this._indicators.filter((indicator: WidgetIndicatorClass) => indicator.formulaUID === formulaUID);
    return result.length > 0 ? result[0] : undefined;
  }

  get indicatorsUID(): UID[] {
    return this._indicators.map(i => i.uid.value);
  }

  get indicatorsLabels(): LabelsClass[] {
    return this._indicators.map(i => i.labels);
  }

  private _emit(modification: ModificationStateClass) {
    if(modification.isModified) this.emit(modification.list as string[]);
  }

  addIndicator(indicator: WidgetIndicatorModel, links: { [key in UID]: 'pivots' | 'dates' }): Readonly<boolean> {

    let isModified = false;

    indicator = DeepCopy(indicator);
    const index = this._parent.indicators.map((i) => i.uid).indexOf(indicator.uid);
    if(index === -1) {
      this._parent.indicators.push(indicator);
      let i = new WidgetIndicatorClass(indicator, this._state);
      this._modifications$sub.push(i.modifications$.subscribe({ next: m => this._emit(m) }));
      this._indicators.push(i);
    }
    else {
      //vérification que les links de l'indicateur correspondent aux layers qui lui sont rattachés
      for(const uid in this._layers) {
        if(this._layers[uid].indicators.indexOf(indicator.uid) !== -1) {
          if(links[uid] === undefined) {
            this._layers[uid].removeIndicator(indicator.uid);
            if(this._layers[uid].indicators.length === 0) {
              delete this._layers[uid];
              this._order = Object.values(this._layers).sort((a, b) => a.rank - b.rank).map(l => l.uid.value);
              isModified = true;
            }
          }
        }
      }
    }

    //ajout des pivots et dates aux layers
    for(const uid in links) {
      if(!this.isLayer(uid)) {
        const values = Object.values(this._layers);
        let chartType = 'bar';
        let sort = 'label_asc';
        let sortUID = '';
        let chartTitle = DeepCopy(this._chartTitle.model);
        if(values.length > 0) {
          chartType = values[0].chartType;
          sort = values[0].sort;
          sortUID = values[0].sortUID;
          chartTitle = DeepCopy(values[0].chartTitle.model);
        }

        this._layers[uid] = new WidgetLayerClass({ 
          uid: uid,
          layerLabel: { labels: SetLabels() },
          layerType: links[uid],
          chartTitle: chartTitle,
          drillable: false,
          rank: Object.keys(this._layers).length,
          definedOptions: [],
          chartType: chartType,
          sort: sort,
          sortUID: sortUID,
          indicators: []
        } as WidgetLayerModel, this._state, this._themeOptions);
        this._order.push(uid);
        if(links[uid] === 'dates') {
          this._bounds[uid] = new WidgetDatesBoundsClass(DateBoundsDefault(uid), this._state, this._modifications$, this._lastModification$);
        }
        this._modifications$sub.push(this._layers[uid].modifications$.subscribe({ next: m => this._emit(m) }));
        isModified = true;
      }
      if(this._layers[uid].indicators.indexOf(indicator.uid) === -1) {
        this._layers[uid].addIndicator(indicator.uid);
        isModified = true;
      }
    }
    if(isModified) this.emit(['indicators', 'reload']);
    return isModified;
  }

  get drillUID(): Readonly<UID> {
    const order = this._order.filter((uid: UID) => this._layers[uid].drillable);
    if(order.length === 0) {
      return null;
    }
    else {
      if(this._drill >= order.length) {
        this._drill = order.length - 1;
        this._drillFunnel = this._drillFunnel.slice(0, order.length - 1);
        this.emit(['reload']);
      }
      return order[this._drill];
    }
  }

  get drill(): Readonly<number> {
    return this._drill;
  }

  get drillMatch(): { [key: string]: string }[] {
    return this._drillFunnel.map(d => { return { [d.key]: d.value }; });
  }

  get drillFunnel(): { key: string, value: string, vanila: string, labels: LabelsClass }[] {
    return this._drillFunnel;
  }

  get drillDepth(): Readonly<number> {
    return this._order.filter((uid: UID) => this._layers[uid].drillable).length;
  }

  rankLayer(from: number, to: number) {
    this._order.splice(to, 0, this._order.splice(from, 1)[0]);
    this._order.forEach((uid: UID, rank: number) => {
      this._layers[uid].change({ rank: rank });
    });
    this._drill = 0;
    this._drillFunnel = [];
    this.emit(['layers', 'reload']);
  }

  rankIndicator(from: number, to: number) {
    this._parent.indicators.splice(to, 0, this._parent.indicators.splice(from, 1)[0]);
    this._indicators.splice(to, 0, this._indicators.splice(from, 1)[0]);
    this.emit(['indicators', 'reload']);
  }

  cloneIndicator(index: number) {
    let model = DeepCopy(this._parent.indicators[index]);
    model.formulaUID = undefined;
    this._parent.indicators.push(model);
    let i = new WidgetIndicatorClass(model, this._state);
    this._modifications$sub.push(i.modifications$.subscribe({ next: m => this._emit(m) }));
    this._indicators.push(i);
    this.emit(['indicators', 'reload']);
  }

  removeIndicator(index: number) {
    this._parent.indicators.splice(index, 1);
    const indicator = this._indicators.splice(index, 1)[0];
    if(this._indicators.map(i => i.uid.value).indexOf(indicator.uid.value) === -1) {
      for(const uid in this._layers) {
        this._layers[uid].removeIndicator(indicator.uid.value);
        if(this._layers[uid].indicators.length === 0) {
          delete this._layers[uid];
          const index = this._order.indexOf(uid);
          this._order.splice(index, 1);
          this._drill = 0;
          this._drillFunnel = [];
        }
      }
      this._mainLayer.removeIndicator(indicator.uid.value);
    }
    this.emit(['indicators', 'reload']);
  }

  duplicateDefinedOption(definedOption: WidgetLayersDefinedOptionModel, fromLayerUID: UID, remove: boolean = false) {
    if(remove) {
      for(const uid in this._layers) {
        if(fromLayerUID !== uid) {
          this._layers[uid].removeDefinedOption(definedOption);
        }
      };
    }
    else {
      const layer = this.layer(fromLayerUID);
      const common = layer.themeOptions.option(definedOption.option, definedOption.index, definedOption.type, definedOption.axesXY);
      const specifics = layer.themeOptions.option(definedOption.option, definedOption.index, definedOption.type, definedOption.axesXY, undefined, true);
      for(const uid in this._layers) {
        if(fromLayerUID !== uid) {
          this._layers[uid].addDefinedOption(definedOption, common, specifics as any);
        }
      };
    }
  }

  duplicateDatalabels(datalabels: WidgetDatalabelsModel[]) {
    this._indicators.forEach((indicator: WidgetIndicatorClass, index: number) =>{
      datalabels.forEach((dtls: WidgetDatalabelsModel) => {
        this._indicators[index].setDatalabels(dtls);
      });
    });
  }

  duplicateNumbro(numbro: NumbroModel) {
    this._indicators.forEach((indicator: WidgetIndicatorClass, index: number) =>{
      this._indicators[index].numbro.change(numbro);
    });
  }

  duplicateLayersOptions(options: Partial<WidgetLayerModel>, fromLayerUID: UID) {
    if(options.chartTitle !== undefined) {
      for(const uid in this._layers) {
        if(fromLayerUID !== uid) {
          this._layers[uid].chartTitle.change({ labels: options.chartTitle.labels });
        }
      }
      this.chartTitle.change({ labels: options.chartTitle.labels });
      delete options.chartTitle;
    }

    if(options.x0Numbro !== undefined) {
      for(const uid in this._layers) {
        if(fromLayerUID !== uid) {
          this._layers[uid].x0Numbro.change(options.x0Numbro);
        }
      }
      delete options.x0Numbro;
    }

    if(options.y0Numbro !== undefined) {
      for(const uid in this._layers) {
        if(fromLayerUID !== uid) {
          this._layers[uid].y0Numbro.change(options.y0Numbro);
        }
      }
      delete options.y0Numbro;
    }

    if(options.y1Numbro !== undefined) {
      for(const uid in this._layers) {
        if(fromLayerUID !== uid) {
          this._layers[uid].y1Numbro.change(options.y1Numbro);
        }
      }
      delete options.y1Numbro;
    }

    for(const uid in this._layers) {
      if(fromLayerUID !== uid) {
        
        this._layers[uid].change(options);
      }
    }
  }

  drillDown(value: string, vanila: string) {
    this._drillFunnel.push({ key: this.drillUID, value: value, vanila: vanila, labels: this._layers[this.drillUID].layerLabel });
    this._drill++;
    this.emit(['reload']);
  }

  drillUp() {
    if(this._drill > 0) {
      this._drill--;
      this._drillFunnel.pop();
      this.emit(['reload']);
    }
  }

  drillRoot() {
    this._drill = 0;
    this._drillFunnel = [];
    this.emit(['reload']);
  }

  drillTo(depth: number) {
    if(depth < this._drillFunnel.length) {
      this._drill = depth;
      this._drillFunnel.splice(depth);
      this.emit(['reload']);
    }
  }

  onDrillDown?(event?: MouseEvent, activeElements?: Array<{}>) {
    if(!!activeElements && activeElements.length > 0) {
      if(activeElements[0]['$datalabels'].length > 0) {
        let { dataIndex, datasetIndex, dataset } = activeElements[0]['$datalabels'][0]['$context'];
        let { shiftKey } = event;
        
        if(this.drillUID === null) {}
        else if(this._drill === 0 && shiftKey) {}
        else if(this._drill === this._order.filter((uid: UID) => this._layers[uid].drillable).length - 1 && !shiftKey) {}
        else {
          if(shiftKey) this._drillFunnel.pop();
          else this._drillFunnel.push({ key: this.drillUID, value: dataset.labels[dataIndex], vanila: dataset.vanila[dataIndex], labels: this._layers[this.drillUID].layerLabel });
          this._drill = shiftKey ? this._drill - 1 : this._drill + 1;
          this.emit(['reload']);
        }
      }
    }
    else if(!!event && event.shiftKey && this._drill > 0) {
      this._drill--;
      this._drillFunnel.pop();
      this.emit(['reload']);
    }
  }

  datalabelsOptions(datalabels: Options, language: LanguageType, indicatorIndex: number): Readonly<Options> {
    return this._indicators[indicatorIndex].datalabelsOptions(datalabels, language, this._layers[this.drillUID]);
  }

  chartTable(series:ChartDataSets[]): WidgetDatalabelsTableModel[] {
    let table: WidgetDatalabelsTableModel[] = [] ;
    series.forEach((dst: ChartDataSets, index: number) => {
      let values = this.indicators[index].datalabels
        .filter((datalabels: WidgetDatalabelsClass) => {
          return datalabels.table === true;
        })
        .map((datalabels: WidgetDatalabelsClass) => {
          return {
            value: this.indicators[index].datalabelsFormatedValue(datalabels, dst.data as number[], dst),
            type: datalabels.type
          }
        });
      if(values.length > 0) {
        table = table.concat({
          values: values,
          labels: this.indicators[index].labels
        });
      }
    });
    return table;
  }

  chartParams(labels: Label[], series: ChartDataSets[], language: LanguageType, page: number, resizeRatio: number): Readonly<WidgetChartParamsModel> {

    let params = {} as WidgetChartParamsModel;
    
    const layer = this.layer(this.drillUID);

    //récupérartion du type de chart
    params.chartType = layer.chartType;
    
    //initialisation des datasets
    params.datasets = new Array(series.length).fill({});
    series.forEach((dst: ChartDataSets, index: number) => {
      
      params.datasets[index] = DeepMerge(dst, layer.themeOptions.series(this.indicators[index].serie !== -1 ? this.indicators[index].serie : index, params.chartType === 'bar' && this.indicators[index].type === 'line' ? 'line' : params.chartType));

      //gestion du type de réprésentation pour les barChart
      if(params.chartType === 'bar') params.datasets[index].type = this.indicators[index].type;

      //format numbro pour les stickers chart
      params.datasets[index].numbro = this.indicators[index].numbro.model;

      //gestion de l'axe pour les barChart
      if(params.chartType === 'bar') params.datasets[index].yAxisID = `y${this.indicators[index].axis === 'main' ? '0' : '1'}`;

      //gestion du formatage des datalabels
      params.datasets[index].datalabels = this.datalabelsOptions(params.datasets[index].datalabels, language, index);

      //gestion des stacks pour les barChart et lineChart
      if(params.chartType === 'bar' || params.chartType === 'line') {
        const stack = this.indicators[index].stack;
        if(stack !== null) params.datasets[index].stack = stack;
      }

      //initialisation des icones
      params.datasets[index].icon = this.indicators[index].icon.code;

      //gestion des couleurs
      if((params.datasets[index].backgroundMultiGradiant && params.chartType !== 'gauge' && params.chartType !== 'sticker' && params.chartType !== 'bar' && params.chartType !== 'line' && params.chartType !== 'radar')) {
        let length = layer.maxValues > 0 ? Math.max(layer.maxValues, params.datasets[index].data.length) : params.datasets[index].data.length;
        params.datasets[index].backgroundColor = this.$colors.gradiantForColor((params.datasets[index].backgroundColor as string).slice(0, 7), length).map(c => c + (params.datasets[index].backgroundColor as string).slice(7, 9));
      }

      if((params.datasets[index].borderMultiGradiant && params.chartType !== 'gauge' && params.chartType !== 'sticker' && params.chartType !== 'bar' && params.chartType !== 'line' && params.chartType !== 'radar')) {
        let length = layer.maxValues > 0 ? Math.max(layer.maxValues, params.datasets[index].data.length) : params.datasets[index].data.length;
        params.datasets[index].borderColor = this.$colors.gradiantForColor((params.datasets[index].borderColor as string).slice(0, 7), length).map(c => c + (params.datasets[index].borderColor as string).slice(7, 9));
      }

      /*
      if(params.datasets[index].backgroundMonoGradiant && params.chartType !== 'sticker' && params.chartType !== 'line' && params.chartType !== 'radar') {
        let context2d: CanvasRenderingContext2D = document.createElement('canvas').getContext('2d');
        if(typeof params.datasets[index].backgroundColor === 'string') {
          let gradientFill = context2d.createLinearGradient(0, 0, 0, 1000);
          gradientFill.addColorStop(0, (params.datasets[index].backgroundColor as string));
          gradientFill.addColorStop(0.5, (params.datasets[index].backgroundColor as string));
          gradientFill.addColorStop(1, (params.datasets[index].backgroundColor as string).slice(0,7) + '00');
          params.datasets[index].backgroundColor = gradientFill;
        }
        else {
          let gradientFill = context2d.createLinearGradient(0, 0, 0, 1000);
          (params.datasets[index].backgroundColor as string[]).forEach((color: string, i: number) => {
            gradientFill.addColorStop(0, color);
            gradientFill.addColorStop(0.5, color);
            gradientFill.addColorStop(1, color.slice(0,7) + '00');
            params.datasets[index].backgroundColor[i] = gradientFill;
          });
        }
      }*/

      //masquage dans le chart des series à masquer
      params.datasets[index].hidden = !this.indicators[index].display;
    }); 

    //initialisation des options
    params.options = layer.themeOptions.options(params.chartType);

    //initialisation du mode d'affichage pour les stickers
    params.options.carousel = layer.stickerCarousel;
    params.options.orientation = layer.stickerOrientation;
    params.options.template = layer.stickerTemplate;
    params.options.fullyColored = layer.stickerFullyColored;

    //mise en place du stack pour les lineChart
    if(params.chartType === 'line' && layer.stacked) params.options.scales.yAxes[0].stacked = true;

    //initialisation du titre
    params.options.title.text = this.layerTitle(this.drillUID).value(language);

    //gestion du libellé des axes
    if(!(['pie', 'doughnut'] as ChartType[]).includes(params.chartType)) {
      if(params.chartType === 'radar' || params.chartType === 'polarArea') params.options['scale'].scaleLabel.labelString = layer.layerLabel.value(language);
      else if(params.chartType === 'horizontalBar') params.options.scales.yAxes[0].scaleLabel.labelString = layer.layerLabel.value(language);
      else params.options.scales.xAxes[0].scaleLabel.labelString = layer.layerLabel.value(language);
    }

    //gestion du formatage des valeur des axes
    if(!!params.options.scales && !!params.options.scales.xAxes && !!params.options.scales.xAxes[0]) {
      params.options.scales.xAxes[0].ticks.callback = function(value: any, index: number, values: any[]) {
        return isNaN(value) || value === undefined ? value : NumbroApplyFormat(value, layer.x0Numbro.type, layer.x0Numbro.format);
      }
    }
    if(!!params.options.scales && !!params.options.scales.yAxes && !!params.options.scales.yAxes[0]) {
      params.options.scales.yAxes[0].ticks.callback = function(value: any, index: number, values: any[]) {
        return isNaN(value) || value === undefined ? value : NumbroApplyFormat(value, layer.y0Numbro.type, layer.y0Numbro.format);
      }
    }
    if(!!params.options.scales && !!params.options.scales.yAxes && !!params.options.scales.yAxes[1]) {
      params.options.scales.yAxes[1].ticks.callback = function(value: any, index: number, values: any[]) {
        return isNaN(value) || value === undefined ? value : NumbroApplyFormat(value, layer.y1Numbro.type, layer.y1Numbro.format);
      }
    }

    //gestion du click sur data pour le drilldown
    params.options.onClick = (event?: MouseEvent, activeElements?: Array<{}>) => {
      this.onDrillDown(event, activeElements);
    };

    //initialisation des libellés
    params.labels = labels.slice();

    //initialisation du title (en cas de multilignes)
    params.options.title.text = params.options.title.text.split('\\n');
    params.options.title.textInline = params.options.title.text.join(' ');

    //préparation des tooltips
    let isTooltips = this.indicators.filter((indicator: WidgetIndicatorClass) => {
      return indicator.datalabels.filter((dtls: WidgetDatalabelsClass) => {
        return dtls.tooltips;
      }).length > 0;
    }).length > 0;

    if(isTooltips) {
      const hiddenIndicators = this.indicators.map((i,idx) =>  { return { index: idx, hidden: !i.display }; }).filter(i => i.hidden).map(i => i.index);
      const shownLastIndex = Math.max(...this.indicators.map((i,idx) =>  { return { index: idx, hidden: i.display }; }).filter(i => i.hidden).map(i => i.index));
      params.options.tooltips.callbacks = {
        label: (tooltipItems: ChartTooltipItem, data: ChartData) => {

          let multistringText = [];
          const indicator = this.indicators[tooltipItems.datasetIndex];
          indicator.datalabels.forEach((datalabels: WidgetDatalabelsClass) => {
            if(datalabels.tooltips) {
              multistringText.push(indicator.datalabelsFormated(
                datalabels,
                data.datasets[tooltipItems.datasetIndex].data as number[],
                data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index],
                data.datasets[tooltipItems.datasetIndex],
                tooltipItems.index,
                language,
                this.layer(this.drillUID),
                false)
              );
            }
          });
          if(hiddenIndicators.length > 0 && shownLastIndex === tooltipItems.datasetIndex) {
            let lines = [];
            hiddenIndicators.forEach((idx: number) => {
              let multi = [];
              this.indicators[idx].datalabels.forEach((datalabels: WidgetDatalabelsClass) => {
                if(datalabels.tooltips) {
                  multi.push(indicator.datalabelsFormated(
                    datalabels,
                    data.datasets[idx].data as number[],
                    data.datasets[idx].data[tooltipItems.index],
                    data.datasets[idx],
                    tooltipItems.index,
                    language,
                    this.layer(this.drillUID),
                    false)
                  );
                }
              });
              lines.push(multi.join(', '))
            });
            return [multistringText.join(', ')].concat(lines.filter(l => l.length > 0));
          }
          else {
            return multistringText.join(', ');
          }
        },
        title: (tooltipItems: ChartTooltipItem[], data: ChartData) => {
          return params.labels[tooltipItems[0].index];
        }
      }
    }
    else {
      params.options.tooltips.enabled = false;
    }

    //initialisation des plugins
    params.plugins = [];

    //limitation du nombre de valeurs sur le chart
    if(layer.maxValues > 0) {

      const maxValues = Math.max(1, layer.maxValues * resizeRatio);

      params.pages = params.labels.length === 0 ? 1 : Math.ceil(params.labels.length / maxValues);

      const from = maxValues * (page - 1);
      const to = maxValues * page;
      params.labels = params.labels.slice(from, to);

      params.datasets.forEach((dataset: ChartDataSets, index: number) => {
        params.datasets[index].labels = dataset.labels.slice(from, to);
        params.datasets[index].vanila = dataset.vanila.slice(from, to);
        params.datasets[index].data = dataset.data.slice(from, to);
      });
    }
    
    //masquage dans la légende des series à masquer
    params.options.legend.labels.filter = (legendItem: ChartLegendLabelItem, chartData: ChartData) => { 
      return legendItem.datasetIndex !== undefined ? !chartData.datasets[legendItem.datasetIndex].hidden : true;
    };

    //restructuration des données pour les bubble charts
    if(params.chartType === 'bubble') {
      //on utilise les séries par blocs de 3 dans l'ordre
      let _datasets = [] as ChartDataSets[];
      let nbSeries = Math.floor(params.datasets.length / 3);

      for(let index = 0 ; index < nbSeries ; index++) {
        let forR = params.datasets[index * 3];
        let forX = params.datasets[index * 3 + 1];
        let forY = params.datasets[index * 3 + 2];

        const average = Average(forR.data as number[]);
        const radius = params.datasets[index * 3].pointRadius as number;//px

        _datasets.push(forR);
        _datasets[index].data = (forR.data as number[]).map((value: number, i: number) => {
          return {
            x: forX.data[i],
            y: forY.data[i],
            z: value,
            r: value * radius / average
          };
        }) as any[];
      }

      params.datasets = _datasets;
    }

    //restructuration des données pour les scatters charts
    if(params.chartType === 'scatter') {
      //on utilise les séries par blocs de 2 dans l'ordre
      let _datasets = [] as ChartDataSets[];
      let nbSeries = Math.floor(params.datasets.length / 2);

      for(let index = 0 ; index < nbSeries ; index++) {
        let forX = params.datasets[index * 2];
        let forY = params.datasets[index * 2 + 1];

        _datasets.push(forX);
        _datasets[index].data = (forX.data as number[]).map((value: number, i: number) => {
          return {
            y: forY.data[i],
            x: value
          };
        }) as any[];
      }

      params.datasets = _datasets;
    }

    //options pour les gauges
    if(params.chartType === 'gauge') {
      params = this.gaugeParams(params, layer);
    }

    return params;
  }

  private gaugeParams(params: WidgetChartParamsModel, layer: WidgetLayerClass): WidgetChartParamsModel {
    if(params.gauge === undefined) params.gauge = {};
    if(params.datasets.length === 0) return;

    const index = 0;

    params.gauge.donut = layer.gaugeDonut;
    params.gauge.hideMinMax = layer.gaugeHideMinMax;
    params.gauge.displayRemaining = layer.gaugeDisplayRemaining;

    params.gauge.value = layer.gaugePercent ? params.datasets[index].total / (params.datasets.length > 1 ? params.datasets[index + 1].total : layer.gaugeTarget) * 100 : params.datasets[index].total;
    params.gauge.relativeGaugeSize = true;
    params.gauge.counter = params.options.animation.duration > 0;
    //params.gauge.levelColors = params.datasets[index].backgroundMultiGradiant ? (params.datasets[index].backgroundColor as string[]).map(c => c.slice(0, 7)).reverse() : [(params.datasets[index].backgroundColor as string).slice(0, 7)];
    params.gauge.levelColors = [(params.datasets[index].backgroundColor as string).slice(0, 7)];
    params.gauge.donutStartAngle = (params.options.rotation / Math.PI + 1) * 180;

    //animation
    params.gauge.startAnimationTime = params.options.animation.duration;
    params.gauge.refreshAnimationTime = params.options.animation.duration;

    //titre
    params.gauge.label = params.options.title.display ? params.options.title.textInline : undefined;
    params.gauge.labelFontColor = params.options.title.fontColor as string;

    //valeur
    params.gauge.hideValue = !params.options.legend.display;
    params.gauge.valueFontColor = params.options.legend.labels.fontColor as string;
    params.gauge.valueFontFamily = params.options.legend.labels.fontFamily as string;

    //target
    params.gauge.target = params.datasets.length > 1 ? params.datasets[index + 1].total : layer.gaugeTarget;

    const indexForMinMax = index + (params.datasets.length > 1 ? 1 : 0);

    //max
    if(params.datasets.length > 1) params.gauge.max = layer.gaugePercent ? layer.gaugeMax : params.datasets[index + 1].total;
    else params.gauge.max = layer.gaugePercent ? layer.gaugeMax : layer.gaugeTarget;
    params.gauge.maxTxt = layer.gaugePercent ? NumbroApplyFormat(params.gauge.max / 100, 'percentageRounded') : params.datasets[indexForMinMax].numbro ? NumbroApplyFormat(params.gauge.max, params.datasets[indexForMinMax].numbro.type, params.datasets[indexForMinMax].numbro.format) : NumbroApplyFormat(params.gauge.max, 'number');

    //min
    params.gauge.min = layer.gaugeMin;
    params.gauge.minTxt = layer.gaugePercent ? NumbroApplyFormat(params.gauge.min / 100, 'percentageRounded') : params.datasets[indexForMinMax].numbro ? NumbroApplyFormat(params.gauge.min, params.datasets[indexForMinMax].numbro.type, params.datasets[indexForMinMax].numbro.format) : NumbroApplyFormat(params.gauge.min, 'number');

    //rendu de la valeur
    params.gauge.textRenderer = (value: number) => {
      const _value = params.gauge.displayRemaining ? layer.gaugePercent ? params.gauge.max - value : params.gauge.target - value : value;
      if(layer.gaugePercent) {
        return ['percentage', 'percentageRounded'].includes(params.datasets[index].numbro.type) ? NumbroApplyFormat(_value / 100, params.datasets[index].numbro.type, params.datasets[index].numbro.format) : NumbroApplyFormat(_value / 100, 'percentageRounded');
      }
      else {
        return params.datasets[index].numbro ? NumbroApplyFormat(_value, params.datasets[index].numbro.type, params.datasets[index].numbro.format) : NumbroApplyFormat(_value, 'number');
      }
    }

    //pointer
    params.gauge.pointer = params.options.gauge.pointer;
    params.gauge.pointerOptions = params.options.gauge.pointerOptions;

    //shadow
    params.gauge.shadowOpacity = params.options.gauge.shadowOpacity;
    params.gauge.shadowSize = params.options.gauge.shadowSize;
    params.gauge.shadowVerticalOffset = params.options.gauge.shadowVerticalOffset;
    params.gauge.showInnerShadow = params.options.gauge.showInnerShadow;

    //autres
    params.gauge.gaugeColor = params.options.gauge.gaugeColor;
    params.gauge.gaugeWidthScale = params.options.gauge.gaugeWidthScale;

    return params;
  }


}