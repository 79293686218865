/**
 * @module Theme
 */

import { Component, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UID } from '@models/uid.model';
import { ItemColor, ItemIcon } from '@functions/item.functions';
import { ThemeService } from '@services/theme/theme.service';
import { ThemeClass } from '@class/theme.class';
import { Subscription } from 'rxjs';
import { ThemeEditorComponent } from '@components/themes/theme-editor/theme-editor.component';
import { ThemeStudioService } from '@services/themeStudio/theme-studio.service';
import { ThemeOptionsClass } from '@class/themeOptions.class';
import { MessagesService } from '@services/messages/messages.service';
import { LanguageType } from '@models/language.model';
import { AuthService } from '@services/auth/auth.service';
import { ThemeStudioCurrentModel } from '@models/themeStudio.model';
import { StudioComponent } from '@components/forms/studio/studio.component';

@Component({
  selector: 'app-theme-studio',
  templateUrl: './theme-studio.component.html',
  styleUrls: ['./theme-studio.component.css']
})
export class ThemeStudioComponent implements OnDestroy {

  @ViewChild('studio') studio: StudioComponent;
  @ViewChild('editor', { static: true }) editor: ThemeEditorComponent;

  color = ItemColor('themes');
  icon = ItemIcon('themes');

  theme: ThemeClass;
  theme$sub: Subscription;
  themeOptions$sub: Subscription;
  changed$sub: Subscription;
  language: LanguageType;
  language$sub: Subscription;
  uid: UID;
  panel: string;
  from: string;
  saved: boolean;
  saving: boolean;
  current: ThemeStudioCurrentModel;

  themeOptions: ThemeOptionsClass;

  constructor(
    private $router: Router,
    private $theme: ThemeService,
    private $studio: ThemeStudioService,
    private $messages: MessagesService,
    private $auth: AuthService
  ) { 
    this.uid = this.$router.parseUrl(this.$router.url).queryParams.uid;
    this.panel = this.$router.parseUrl(this.$router.url).queryParams.panel;
    this.from = this.$router.parseUrl(this.$router.url).queryParams.from;
    this.theme = this.$theme.create();
    this.saved = true;
    this.saving = false;
    this.current = { category: 'LAYOUT', index: 0 };

    this.language$sub = this.$auth.language$.subscribe({
      next: (language: LanguageType) => {
        this.language = language;
      }
    });

    if(!this.uid) this.onBack();
    else {
      this.theme$sub = this.$theme.get$(this.uid).subscribe({
        next:(theme: ThemeClass) => {
          if(!!theme) this.theme = theme;
        }
      });

      this.themeOptions$sub = this.$theme.getOptions$(this.uid).subscribe({
        next:(themeOptions: ThemeOptionsClass) => {
          this.themeOptions = this.$studio.themeOptions(this.uid, themeOptions);
          if(this.changed$sub) this.changed$sub.unsubscribe();
          this.changed$sub = this.themeOptions.changed$.subscribe((changed: boolean) => {
            this.saved = !changed;
          });
        }
      });
    }
  }

  ngOnDestroy() {
    this.language$sub.unsubscribe();
    if(this.theme$sub) this.theme$sub.unsubscribe();
    if(this.themeOptions$sub) this.themeOptions$sub.unsubscribe();
    if(this.changed$sub) this.changed$sub.unsubscribe();
  }

  receiveDelete() {
    this.onBack();
  }

  onBack() {
    this.$router.navigate([`/${this.from}`], { queryParams: this.panel !== undefined ? { panel: this.panel } : {} });
  }

  receiveCurrent(current: ThemeStudioCurrentModel) {
    this.current = {
      category: current.category,
      index: undefined
    };
    setTimeout(() => {
      this.current = current;
      this.studio.setFrame('sub', true);
    });
  }

  receiveEdit() {
    this.editor.show();
  }

  onSave() {
    this.saving = true;
    this.$theme.setOptions(this.uid, this.themeOptions)
    .then(() => {
      this.saving = false;
      this.saved = true;
      this.$messages.success({ 
        title: { plain: this.theme.labels.value(this.language) }, 
        text: { code: 'SUCCESSFULLY_SAVED/TEXT' } 
      });
    })
    .catch((error) => {
      this.saving = false;
      this.saved = false;
      this.$messages.error({ 
        title: { plain: this.theme.labels.value(this.language) }, 
        text: { 
          code: 'SAVE_FAILED/TEXT',
          params: { error: error.code }
        } 
      });
    });
  }

}
