/**
 * @module Backend
 */

import { Injectable } from '@angular/core';
import { TokenService } from '@services/token/token.service';
import { AuthService } from '@services/auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { DocumentModel } from '@models/document.model';
import { ServerUrlModel, ServerResponseModel } from '@models/server.model';
import { Subject, throwError } from 'rxjs';
import * as pako from 'pako';
import { HttpOptions } from '@functions/server.functions';
import { take, map } from 'rxjs/operators';
import { HandleErrors } from '@functions/errors.functions';
import { UID } from '@models/uid.model';
import { ENVIRONMENT } from '@consts/params.const';
import { FirebaseService } from '@services/firebase/firebase.service';

export const CHUNCK_IMPORT_FRAME_SIZE = 1000;
const ROUTE = 'documents';

@Injectable({
  providedIn: 'root'
})
export class BackendDocumentsService {

  constructor(
    private $token: TokenService,
    private $auth: AuthService,
    private $http: HttpClient,
    private $firebase: FirebaseService
  ) {}

  post(uidIndicator: UID, documents: DocumentModel[], server: ServerUrlModel): Subject<ServerResponseModel> {

    let sub = new Subject<ServerResponseModel>();

    let post = async () => {
      if(documents.length > 0) {
        let _documents = documents.splice(0, CHUNCK_IMPORT_FRAME_SIZE);
        this.$firebase.angularFireFunctions.httpsCallable('postDocuments')({ 
          dataset: server.uid,
          table: uidIndicator, 
          documents: _documents, 
          token: this.$auth.token$.value.token 
        }).toPromise()
        .then(result => {
          sub.next(result);
          post();
        })
        .catch(error => {
          console.log(error);
        });
      }
    }
    post()
    return sub;

/*
    let sub = new Subject<ServerResponseModel>();
    
    let post = async (_sub: Subject<ServerResponseModel>) => {
      let tokenData = await this.$token.get(server).catch((error) => { 
        return throwError('OBJECT_NOT_FOUND/SERVER').toPromise();
      });
      if(tokenData) {
        while(documents.length > 0) {
          //la taille par défaut du bulk mongoDB est de 1000, on envoi donc par paquet de 1000 lignes
          let _documents = documents.splice(0, CHUNCK_IMPORT_FRAME_SIZE);
          const chunck = pako.deflate(JSON.stringify(_documents), { to: 'string' });
          let response = await this.$http.post<ServerResponseModel>(`https://${server.url}/${ROUTE}`,
            { collection: uidIndicator, documents: chunck }, 
            HttpOptions(this.$auth.token$.value.token, tokenData.token)
          ).pipe(
            take(1),
            map((response: ServerResponseModel) => {
              if(ENVIRONMENT.type !== 'production') {
                if(response.success) {
                  console.groupCollapsed(`Backend POST ${ROUTE} [${uidIndicator}] ['success'] ${_documents.length} documents`);
                  console.log(response.message);
                  console.groupEnd();
                }
                else {
                  console.log(`Backend POST ${ROUTE} [${uidIndicator}] ['error'] ${response.message}`);
                }
              }
              if(response.success) return response;
              else return { success: response.success, message: _documents.map(d => d.uidDocument) };
            }),
            HandleErrors()
          ).toPromise();
          _sub.next(response);
        }
      }
    }
    post(sub);
    return sub;
    */
  }

  /*async get(uidIndicator: UID, server: ServerUrlModel): Promise<ServerResponseModel> {
    
    let tokenData = await this.$token.get(server).catch((error) => { 
      return throwError('OBJECT_NOT_FOUND/SERVER').toPromise();
    });
    if(tokenData) {
      return this.$http.get<ServerResponseModel>(`https://${server.url}/${ROUTE}`,
        HttpOptions(this.$auth.token$.value.token, tokenData.token, { collection: uidIndicator })
      ).pipe(
        take(1),
        map((response: ServerResponseModel) => {
          const documents = response.success ? JSON.parse(pako.inflate(response.message, { to: 'string' })) : [];
          if(ENVIRONMENT.type !== 'production') {
            if(response.success) {
              console.groupCollapsed(`Backend GET ${ROUTE} [${uidIndicator}] ['success'] ${documents.length} documents`);
              console.log(documents);
              console.groupEnd();
            }
            else {
              console.log(`Backend GET ${ROUTE} [${uidIndicator}] ['error'] ${response.message}`);
            }
          }
          return { success: response.success, message: documents };
        }),
        HandleErrors()
      ).toPromise();
    }
  }*/

  /*async delete(uidIndicator: UID, server: ServerUrlModel): Promise<ServerResponseModel> {
    
    let tokenData = await this.$token.get(server).catch((error) => { 
      return throwError('OBJECT_NOT_FOUND/SERVER').toPromise();
    });
    if(tokenData) {
      return this.$http.delete<ServerResponseModel>(`https://${server.url}/${ROUTE}`,
        HttpOptions(this.$auth.token$.value.token, tokenData.token, { collection: uidIndicator })
      ).pipe(
        take(1),
        map((response: ServerResponseModel) => {
          if(ENVIRONMENT.type !== 'production') {
            if(response.success) console.log(`Backend DELETE ${ROUTE} [${uidIndicator}] ['success'] ${response.message} documents`);
            else console.log(`Backend DELETE ${ROUTE} [${uidIndicator}] ['error'] ${response.message}`);
          }
          return response;
        }),
        HandleErrors()
      ).toPromise();
    }
  }*/
}