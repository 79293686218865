<div class="btn-group mb-2 d-flex" mdbDropdown>
  <button mdbDropdownToggle type="button" mdbBtn color="light" class="flex-grow-1 blue-grey-text z-depth-0 dropdown-toggle waves-light d-flex align-items-center px-3" mdbWavesEffect>
    <i class="far fa-{{ icon }}"></i><span class="d-none d-md-inline ml-1 mr-auto">{{ 'INDICATORS' | translate }}</span>&nbsp;
  </button>

  <div class="dropdown-menu dropdown-dark dropdown-menu-right">

    <a (click)="onUploadAll()" [ngClass]="{ 'disabled text-muted': !oneValid }" class="dropdown-item z-depth-0"><i class="fas fa-fw fa-arrow-alt-from-bottom mr-1 {{color}}-text"></i>{{ 'SAVE_VALID_ONES' | translate }}</a>

    <div class="divider dropdown-divider"></div>

    <a *ngFor="let importFrame of importFrames" class="dropdown-item z-depth-0 d-flex align-items-center" (click)="onSelect(importFrame)">
      <i *ngIf="importFrame.dataFrame.isErrors" class="fa-fw fas fa-exclamation-triangle text-danger mr-1"></i>
      <i *ngIf="(importFrame.uploadStream$ | async)?.isEnded && (importFrame.uploadStream$ | async)?.isErrors == false" class="fa-fw fas fa-arrow-alt-from-bottom {{ color }}-text mr-1"></i>
      <i *ngIf="(importFrame.uploadStream$ | async)?.isErrors" class="fa-fw fas fa-arrow-alt-from-bottom text-warning mr-1"></i>
      <i *ngIf="(importFrame.uploadStream$ | async)?.isUploading" class="fa-fw fas fa-circle-notch fa-spin blue-grey-text mr-1"></i>
      {{ importFrame.dataFrame.label }}<i *ngIf="importFrame.uid == current" class="fas fa-check ml-auto"></i>
    </a>

  </div>
</div>