<app-browser-frame #frameModal [isCreate]="false" (sendSearch)="receiveSearch($event)" [color]="color" [icon]="icon" [label]="label">
  
  <div app-browser-frame-content-options>

  </div>

  <app-empty app-browser-frame-content-list [actionText]="'IMPORT'" [actionIcon]="'fa-file-import'" [type]="'pivots'" (sendAction)="receiveImport()" *ngIf="pivots != undefined && pivots.length == 0"></app-empty>
  <app-loading app-browser-frame-content-list *ngIf="pivots == undefined"></app-loading>
  
  <div app-browser-frame-content-list *ngFor="let pivot of pivots | searchLabels:searchLabel:language ; trackBy: trackByUid">
    <app-item-strip
    [labels]="pivot.labels"
    [tags]="pivot.tags"
    [type]="'pivots'"
    [uid]="pivot.uid"
    [icon]="icon"
    [color]="color"
    [isModified]="pivot.modifications$.value.isModified"
    (sendEdit)="receiveEdit($event)"
    ></app-item-strip>
  </div>
</app-browser-frame>

<app-pivot-editor #editor [current]="current"></app-pivot-editor>