/**
 * @module Import
 */

import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { DataFrameDetectValuesModel } from '@models/dataFrame.model';
import { ItemColor } from '@functions/item.functions';
import { LanguageType } from '@models/language.model';
import { Subscription } from 'rxjs';
import { AuthService } from '@services/auth/auth.service';

@Component({
  selector: 'app-import-detect-value',
  templateUrl: './import-detect-value.component.html',
  styleUrls: ['./import-detect-value.component.css']
})
export class ImportDetectValueComponent implements OnInit, OnDestroy {

  @Input() key: string;
  @Input() selected: boolean;
  @Input() isValid: boolean;
  @Input() detect: DataFrameDetectValuesModel;
  @Output() sendEdit: EventEmitter<{ key: string, search: string, replace: string }>;
  @Output() sendSelected: EventEmitter<boolean>;
  @Output() sendRemove: EventEmitter<void>;

  color = ItemColor('indicators');
  isEdit: boolean;
  modified: string;
  language: LanguageType;
  language$sub: Subscription;

  constructor(
    private $auth: AuthService
  ) {
    this.isEdit = false;
    this.selected = true;
    this.sendEdit = new EventEmitter<{ key: string, search: string, replace: string }>();
    this.sendSelected = new EventEmitter<boolean>();
    this.sendRemove = new EventEmitter<void>();

    this.language$sub = this.$auth.language$.subscribe({
      next: (language: LanguageType) => {
        this.language = language;
      }
    });
  }

  ngOnInit() {
    this.modified = this.detect.value;
  }

  ngOnDestroy() {
    this.language$sub.unsubscribe();
  }

  onEdit() {
    this.isEdit = !this.isEdit;
  }

  onValue(modified: any) {
    this.modified = modified;
  }

  onValid() {
    this.isEdit = false;
    this.sendEdit.emit({ key: this.key, search: this.detect.value, replace: this.modified });
  }

  onRestore() {
    this.isEdit = false;
    this.modified = this.detect.value;
  }

  receiveSelected() {;
    this.selected = !this.selected;
    this.sendSelected.emit(this.selected);
  }

  onRemove() {
    this.sendRemove.emit();
  }

}
