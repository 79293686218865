/**
 * @module User
 */

import { Injectable } from '@angular/core';
import { UserClass } from '@class/user.class';
import { Observable, BehaviorSubject } from 'rxjs';
import { UserModel } from '@models/user.model';
import { map, takeWhile } from 'rxjs/operators';
import { FirestoreService } from '@services/firestore/firestore.service';
import { AuthService } from '@services/auth/auth.service';
import { FirebaseService } from '@services/firebase/firebase.service';
import { UidService } from '@services/uid/uid.service';
import { ItemType } from '@models/item.model';
import { ResourcesModelConstructor } from '@functions/resource.functions';
import { BookmarksModelConstructor } from '@functions/bookmark.functions';
import { COMPANIES } from '@consts/companies.const';
import { CompanyService } from '@services/company/company.service';

@Injectable({
  providedIn: 'root'
})
export class UserService extends FirestoreService<UserModel> {

  /**
   * @description type de document manipulé par le service
   * @protected
   * @type {ItemType}
   * @memberof UserService
   */
  protected _itemType: ItemType = 'users';

  /**
   * @description true si l'accès à ce type de document peut nécessiter des habilitations
   * @protected
   * @type {boolean}
   * @memberof UserService
   */
  protected _restricted: boolean = false;

  /**
   * @description Creates an instance of UserService
   * @param {UidService} $uid
   * @param {AuthService} $auth
   * @param {FirebaseService} $firebase
   * @param {CompanyService} $company
   * @memberof UserService
   */
  constructor(
    private $uid: UidService,
    protected $auth: AuthService,
    protected $firebase: FirebaseService,
    private $company: CompanyService
  ) {
    super($firebase, $auth);
  }

  /**
   * @description Observateur de la liste des objects autorisés pour l'utilisateur courant
   * @returns {BehaviorSubject<UserClass[]>}
   * @memberof UserService
   */
  list$(): BehaviorSubject<UserClass[]> {
    return super.docs$().pipe(
      map((users: UserModel[]) => {
        if(!!users) {
          let ds = users.map<UserClass>((user: UserModel) => {
            if(user.resources === undefined) user.resources = ResourcesModelConstructor().resources;
            return new UserClass(user, true);
          });
          return ds;
        }
      })
    ) as BehaviorSubject<UserClass[]>;
  }

  /**
   * @description Observateur d'un document dont l'uid est passé en paramètre
   * @param {string} uid
   * @returns {Observable<UserClass>}
   * @memberof UserService
   */
  get$(uid: string): Observable<UserClass> {
    return super.doc$(uid).pipe(
      map((user: UserModel) => {
        if(user.resources === undefined) user.resources = ResourcesModelConstructor().resources;
        return new UserClass(user, true);
      })
    );
  }

  /**
   * @description Observateur d'un document (données partielles) dont l'uid est passé en paramètre
   * @param {string} uid
   * @returns {Observable<UserClass>}
   * @memberof UserService
   */
  getPartial$(uid: string): Observable<UserClass> {
    return super.partial$(uid).pipe(
      map((user: UserModel) => {
        if(user !== null) return new UserClass(user, true);
      })
    );
  }

  /**
   * @description Créé un nouveau document avec les valeurs par défaut
   * @returns {UserClass}
   * @memberof UserService
   */
  create(): UserClass {
    return new UserClass({ 
      uid: this.$uid.create(),
      logs: {},
      tags: {},
      resources: ResourcesModelConstructor().resources,
      groups: {},
      activated: true,
      avatar: {
        file: null,
        path: COMPANIES[this.$company.code].avatar.path,
        url: COMPANIES[this.$company.code].avatar.url
      },
      lastConnection: '',
      language: this.$auth.language$.value,
      bookmarks: BookmarksModelConstructor().bookmarks,
      lastname: '',
      firstname: '',
      email: '',
      roles: [],
      createAt: new Date().toISOString(),
      desactivatedAt: ''
    } as UserModel, false);
  }

  /**
   * @description Créé ou met à jour le document passé en paramètre ainsi que l'avatar de l'utilisateur si modifié
   * @param {UserClass} user
   * @returns {Promise<void>}
   * @memberof UserService
   */
  async set(user: UserClass): Promise<void> {
    if(!user.uid.isRegistered) {
      this.$firebase.angularFirebase.object<boolean>(`docs/users/${user.uid.value}`).valueChanges()
      .pipe(takeWhile((exist: boolean) => !exist, true))
      .subscribe({
        next:(exist: boolean) => {
          if(exist === true) this.$auth.sendPasswordResetEmail(user.id.email, user.language);
        }
      });
    }

    await this.setAvatar(user);
    return super.update(user.uid.value, user.model)
    .then(() => {
      if(user.isPrivilegesModified) {
        this.$auth.addClaims(user.id.email)
        .then(() => {
          user.isPrivilegesModified = false;
          if(user.uid.value === this.$auth.user$.value.uid.value) {
            this.$auth.refreshToken();
          }
        });
      }
    });
  }

  /**
   * @description Supprime le document passé en paramètre
   * @param {string} uid
   * @returns {Promise<void>}
   * @memberof UserService
   */
  async remove(uid: string): Promise<void> {
    return super.delete(uid);
  }

  /**
   * @description Met à jour l'avatar de l'utilisateur
   * @private
   * @param {UserClass} user
   * @memberof UserService
   */
  private async setAvatar(user: UserClass) {

    if(user.avatar.isModified) {
      if(user.avatar.file !== null) {
        const path = `avatars/${user.uid.value}`;
        const customMetadata = {
          label: user.id.fullname,
          uid: user.uid.value
        }

        const file = await this.$firebase.angularFireStorage.upload(path, user.avatar.file, { customMetadata });
        const url = await file.ref.getDownloadURL();
        user.change({
          avatar: {
            path: path,
            url: url,
            file: null
          }
        }, false);
      }
      else if(COMPANIES[this.$company.code].avatar.path !== user.avatar.path) {
        await this.$firebase.angularFireStorage.ref(`avatars/${user.uid.value}`).delete();
      
        user.change({
          avatar: {
            path: COMPANIES[this.$company.code].avatar.path,
            url: COMPANIES[this.$company.code].avatar.url,
            file: null
          }
        }, false);

      }
      this.$firebase.angularFirebase.object(`status/${user.uid.value}`).update({ 
        user_url: user.avatar.url
      });
    }
  }

}
