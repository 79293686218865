/**
 * @module Theme
 */

import { Component, Output, Input, EventEmitter } from '@angular/core';
import { ItemColor } from '@functions/item.functions';

@Component({
  selector: 'app-theme-option-font-size',
  templateUrl: './theme-option-font-size.component.html',
  styleUrls: ['./theme-option-font-size.component.css']
})
export class ThemeOptionFontSizeComponent {

  @Input() fontSize: number;
  @Output() sendFontSize: EventEmitter<number>;

  color = ItemColor('themes');

  constructor() {
    this.sendFontSize = new EventEmitter<number>();
  }

  receiveValue(fontSize: number) {
    this.sendFontSize.emit(fontSize);
  }

}
