/**
 * @module Right
 */

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { RightsClass, RightClass } from '@class/right.class';
import { Subscription } from 'rxjs';
import { UID } from '@models/uid.model';
import { AuthService } from '@services/auth/auth.service';
import { UserClass } from '@class/user.class';
import { RightService } from '@services/right/right.service';
import { ItemColor } from '@functions/item.functions';

@Component({
  selector: 'app-rights-links-list',
  templateUrl: './rights-links-list.component.html',
  styleUrls: ['./rights-links-list.component.css']
})
export class RightsLinksListComponent implements OnInit, OnDestroy {

  @Input() rights: RightsClass;

  resources$sub: Subscription;
  rights$sub: Subscription;
  uids: UID[];
  searchLabel: string;
  color = ItemColor('rights');

  constructor(
    private $auth: AuthService,
    private $rights: RightService
  ) {}

  ngOnInit() {
    this.getList(false);
  }

  ngOnDestroy() {
    if(this.resources$sub) this.resources$sub.unsubscribe();
    if(this.rights$sub) this.rights$sub.unsubscribe();
  }

  receiveTextValue(searchLabel: string) {
    this.searchLabel = searchLabel;
  }

  getList(forceFullCollection: boolean) {
    if(this.resources$sub) this.resources$sub.unsubscribe();
    if(this.rights$sub) this.rights$sub.unsubscribe();

    if(forceFullCollection) {
      this.rights$sub = this.$rights.listPartials$(forceFullCollection).subscribe({
        next: (rights: RightClass[]) => {
          this.uids = rights.map((right: RightClass) => {
            return right.uid.value;
          });
        }
      });
    }
    else {
      this.resources$sub = this.$auth.resources$.subscribe({
        next: (user: UserClass) => {
          this.uids = user.groups.list.concat(this.rights.list()).concat(this.rights.list(true));
          this.uids = this.uids.filter(function(item, pos, self) {
            return self.indexOf(item) == pos;
          });
        }
      });
    }
  }

  receiveFull(full: boolean) {
    this.getList(full);
  }

  isReader(uid: UID): boolean {
    return this.rights.has(uid);
  }

  isWriter(uid: UID): boolean {
    return this.rights.has(uid, true);
  }

  receiveReader(uid: UID) {
    if(this.rights.has(uid)) this.rights.remove(uid);
    else this.rights.add(uid);
  }

  receiveWriter(uid: UID) {
    if(this.rights.has(uid, true)) this.rights.remove(uid);
    else this.rights.add(uid, true);
  }

}
