<app-helper #helper [title]="'FORMULA'">

  <div app-helper-content>
    <table class="table table-striped table-bordered font-small blue-grey-text">
      <tbody>
        <tr>
          <th>Function</th>
          <th>Description</th>
        </tr>
        <tr>
          <td><b>abs(x)</b></td>
          <td>Calculate the absolute value of a number</td>
        </tr>
        <tr>
          <td><b>cbrt(x)</b></td>
          <td>Calculate the cubic root of a value</td>
        </tr>
        <tr>
          <td><b>cube(x)</b></td>
          <td>Compute the cube of a value, x * x * x</td>
        </tr>
        <tr>
          <td><b>fix(x)</b></td>
          <td>Round a value towards zero</td>
        </tr>
        <tr>
          <td><b>pow(x,&nbsp;y)</b></td>
          <td>Calculates the power of x to y, x ^ y</td>
        </tr>
        <tr>
          <td><b>round(x&nbsp;[,&nbsp;n])</b></td>
          <td>Round a value towards the nearest integer<br>Optional : n = number of decimals, default value: 0</td>
        </tr>
        <tr>
          <td><b>sqrt(x)</b></td>
          <td>Calculate the square root of a value</td>
        </tr>
      </tbody>
    </table>

    <table class="table table-striped table-bordered font-small blue-grey-text">
      <tbody>
        <tr>
          <th>Operator</th>
          <th>Syntax</th>
          <th>Example</th>
          <th>Result</th>
        </tr>
        <tr>
          <td>Grouping</td>
          <td><b>(x)</b></td>
          <td>2 * (3 + 4)</td>
          <td>14</td>
        </tr>
        <tr>
          <td>Separator</td>
          <td><b>x, y</b></td>
          <td>max(2, 1, 5)</td>
          <td>5</td>
        </tr>
        <tr>
          <td>Add</td>
          <td><b>x + y</b></td>
          <td>4 + 5</td>
          <td>9</td>
        </tr>
        <tr>
          <td>Subtract</td>
          <td><b>x - y</b></td>
          <td>7 - 3</td>
          <td>4</td>
        </tr>
        <tr>
          <td>Multiply</td>
          <td><b>x * y</b></td>
          <td>2 * 3</td>
          <td>6</td>
        </tr>
        <tr>
          <td>Divide</td>
          <td><b>x / y</b></td>
          <td>6 / 2</td>
          <td>3</td>
        </tr>
        <tr>
          <td>Modulus</td>
          <td><b>x % y</b></td>
          <td>8 % 3</td>
          <td>2</td>
        </tr>
        <tr>
          <td>Power</td>
          <td><b>x ^ y</b></td>
          <td>2 ^ 3</td>
          <td>8</td>
        </tr>
        <tr>
          <td>Factorial</td>
          <td><b>y!</b></td>
          <td>5!</td>
          <td>120</td>
        </tr>
        <tr>
          <td>Logical and</td>
          <td><b>x and y</b></td>
          <td>true and false</td>
          <td>false</td>
        </tr>
        <tr>
          <td>Logical not</td>
          <td><b>not y</b></td>
          <td>not true</td>
          <td>false</td>
        </tr>
        <tr>
          <td>Logical or</td>
          <td><b>x or y</b></td>
          <td>true or false</td>
          <td>true</td>
        </tr>
        <tr>
          <td>Logical xor</td>
          <td><b>x xor y</b></td>
          <td>true xor true</td>
          <td>false</td>
        </tr>
        <tr>
          <td>Assignment</td>
          <td><b>x = y</b></td>
          <td>a = 5</td>
          <td>5</td>
        </tr>
        <tr>
          <td>Conditional</td>
          <td><b>x ? y : z</b></td>
          <td>15 &gt; 100 ? 1 : -1</td>
          <td>-1</td>
        </tr>
        <tr>
          <td>Conversion</td>
          <td><b>x to y</b></td>
          <td>2 inch to cm</td>
          <td>5.08 cm</td>
        </tr>
        <tr>
          <td>Equal</td>
          <td><b>x == y</b></td>
          <td>2 == 4 - 2</td>
          <td>true</td>
        </tr>
        <tr>
          <td>Unequal</td>
          <td><b>x != y</b></td>
          <td>2 != 3</td>
          <td>true</td>
        </tr>
        <tr>
          <td>Smaller</td>
          <td><b>x &lt; y</b></td>
          <td>2 &lt; 3</td>
          <td>true</td>
        </tr>
        <tr>
          <td>Larger</td>
          <td><b>x &gt; y</b></td>
          <td>2 &gt; 3</td>
          <td>false</td>
        </tr>
        <tr>
          <td>Smallereq</td>
          <td><b>x &lt;= y</b></td>
          <td>4 &lt;= 3</td>
          <td>false</td>
        </tr>
        <tr>
          <td>Largereq</td>
          <td><b>x &gt;= y</b></td>
          <td>2 + 4 &gt;= 6</td>
          <td>true</td>
        </tr>
      </tbody>
    </table>

  </div>
  
</app-helper>