/**
 * @module Import
 */

import { Component, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { ImportScopeComponent } from '@components/import/import-studio/import-scope/import-scope.component';
import { ItemsColors, ItemsIcons } from '@functions/item.functions';
import { ImportFrameWorker, ImportDataFrameModel } from '@models/worker.model';
import { Subscription, BehaviorSubject } from 'rxjs';
import { ImportFrameClass } from '@class/importFrame.class';
import { MessagesService } from '@services/messages/messages.service';
import { ImportService } from '@services/import/import.service';
import { ServerLinkClass } from '@class/server.class';
import { FiltersPipeModel } from '@pipes/filters/filters.pipe';
import { UID } from '@models/uid.model';
import { StudioComponent } from '@components/forms/studio/studio.component';

@Component({
  selector: 'app-import-studio',
  templateUrl: './import-studio.component.html',
  styleUrls: ['./import-studio.component.css']
})
export class ImportStudioComponent implements OnDestroy {

  @ViewChild('scope', { static: true }) scope: ImportScopeComponent;
  @ViewChild('studio') studio: StudioComponent;

  colors = ItemsColors();
  icons = ItemsIcons();

  worker: ImportFrameWorker;
  worker$sub: Subscription;
  current: ImportFrameClass;
  key: string;

  filters$: BehaviorSubject<{ [key: string]: { [key: string]: FiltersPipeModel } }>;
  importFrames: ImportFrameClass[];
  promiseWorker: any;

  constructor(
    private element: ElementRef,
    private $messages: MessagesService,
    private $import: ImportService
  ) {
    this.importFrames = [];
    this.worker = this.$import.worker$.value;

    this.worker$sub = this.$import.worker$.subscribe({
      next:(worker: ImportFrameWorker) => {
        if(this.promiseWorker) clearTimeout(this.promiseWorker);
        this.promiseWorker = setTimeout((worker: ImportFrameWorker) => {
          this.worker = worker;
          if(this.worker.step === 'wSelectScope' && this.worker.status === 'wWaitingUser') {
            setTimeout(() => this.scope.show(), 10);
          }
          else if(this.worker.step === 'wManageFrames' && this.worker.status === 'wWaitingUser') {
            this.importFrames = this.worker.values;
            if(this.importFrames.length > 0) {
              this.current = this.importFrames[0];
            }
          }
        }, 250, worker);
      }
    });

    this.filters$ = new BehaviorSubject<{ [key: string]: { [key: string]: FiltersPipeModel } }>({});
  }

  ngOnDestroy() {
    this.worker$sub.unsubscribe();
  }


  receiveUploadComplete(uid: UID) {
    this.importFrames = this.$import.removeImportFrame(uid);
    if(this.current.uid === uid) {
      this.current = this.importFrames !== undefined && this.importFrames.length > 0 ? this.importFrames[0] : undefined;
      this.key = undefined;
    }
    else if(this.importFrames.length === 0) {
      this.current = undefined;
      this.key = undefined;
    }
  }

  onContinue() {
    this.scope.show();
  }

  onFile() {
    if(this.worker.values != undefined) {
      this.$messages.question({ text: { code: 'UNSAVED_WORK' } }, 'warning', 'SELECT')
      .then((isClick: boolean) => {
        if(isClick) this.element.nativeElement.querySelector('#file').click();
      });
    }
    else {
      this.element.nativeElement.querySelector('#file').click();
    }
  }

  onChange(file: File) {
    if(file !== undefined) {
      this.current = undefined;
      this.key = undefined;
      this.importFrames = [];
      this.$import.processFile(file);
    }
  }

  receiveCurrent(current: ImportFrameClass) {
    this.current = current;
    this.key = undefined;
  }

  receiveRemoved(uid: string) {
    const index = this.importFrames.map((importFrame: ImportFrameClass) => importFrame.indicator.uid.value).indexOf(uid);
    this.importFrames.splice(index, 1);
    if(this.importFrames.length > 0) this.receiveCurrent(this.importFrames[0]);
    else {
      this.current = undefined;
      this.key = undefined;
      this.worker.step = 'wReadFile';
      this.worker.status = 'wSleeping';
      this.worker.values = undefined;
    }
  }

  receiveDataFrames(selection: { dataFrames: { [key: string]: ImportDataFrameModel }, server: ServerLinkClass }) {
    if(Object.keys(selection.dataFrames).length > 0) {
      this.$import.processFrames(selection.dataFrames, selection.server);
    }
  }

  receiveSelectedKey(key: string) {
    this.key = key;
    this.studio.setFrame('sub', true);
  }

}
