/**
 * @module Backend
 */

import { Injectable } from '@angular/core';
import { TokenService } from '@services/token/token.service';
import { AuthService } from '@services/auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { ServerUrlModel, ServerResponseModel } from '@models/server.model';
import { throwError } from 'rxjs';
import { HttpOptions } from '@functions/server.functions';
import { take, map } from 'rxjs/operators';
import { HandleErrors } from '@functions/errors.functions';
import { UID } from '@models/uid.model';
import { ENVIRONMENT } from '@consts/params.const';
import { FirebaseService } from '@services/firebase/firebase.service';

const ROUTE = 'values';

@Injectable({
  providedIn: 'root'
})
export class BackendValuesService {

  constructor(
    private $token: TokenService,
    private $auth: AuthService,
    private $http: HttpClient,
    private $firebase: FirebaseService
  ) {}

  async get(uidIndicator: UID, server: ServerUrlModel, group: UID, match: { [key: string]: any }[], columns: string[] = []): Promise<ServerResponseModel> {
    
    const data = {
      token: this.$auth.token$.value.token,
      dataset: server.uid,
      table: uidIndicator, 
      group: group == null && columns.length > 0 ? columns[0] : group,
      columns: columns,
      gettab: columns.length > 0,
      match: match.map(m => {
        const column = Object.keys(m)[0];
        const conditionValues = Object.values(m)[0];
        const condition = conditionValues['$in'] !== undefined ? 'IN' : 
          conditionValues['$not'] !== undefined ? 'NOT IN' :
          conditionValues['$gte'] !== undefined ? '>=' :
          conditionValues['$gt'] !== undefined ? '>' :
          conditionValues['$lte'] !== undefined ? '<=' :
          conditionValues['$lt'] !== undefined ? '<' : '=';
        const values = conditionValues['$in'] !== undefined ? conditionValues['$in'] : 
          conditionValues['$not'] !== undefined ? conditionValues['$not']['$in'] :
          conditionValues['$gte'] !== undefined ? conditionValues['$gte'] :
          conditionValues['$gt'] !== undefined ? conditionValues['$gt'] :
          conditionValues['$lte'] !== undefined ? conditionValues['$lte'] : 
          conditionValues['$lt'] !== undefined ? conditionValues['$lt'] : conditionValues;
        return {
          column: column,
          condition: condition,
          values: values
        }
      })
    }

    return this.$firebase.angularFireFunctions.httpsCallable('getValues')(data).toPromise()
    .then(result => {
      //if(columns.length > 0) console.log(result)
      return result;
    })
    .catch(error => {
      console.log(error);
    });

    /*
    let tokenData = await this.$token.get(server).catch((error) => { 
      return throwError('OBJECT_NOT_FOUND/SERVER').toPromise();
    });
    if(tokenData) {
      return this.$http.get<ServerResponseModel>(`https://${server.url}/${ROUTE}`,
        HttpOptions(this.$auth.token$.value.token, tokenData.token, 
          { 
            collection: uidIndicator, 
            group: group,
            match: JSON.stringify(match || []) 
          })
      ).pipe(
        take(1),
        map((response: ServerResponseModel) => {
          if(ENVIRONMENT.type !== 'production') {
            if(response.success) {
              console.groupCollapsed(`Backend GET ${ROUTE} [${uidIndicator}] ['success'] ${response.message.values.length} values`);
              console.log(response.message);
              console.groupEnd();
            }
            else {
              console.log(`Backend GET ${ROUTE} [${uidIndicator}] ['error'] ${response.message}`);
            }
          }
          if(response.success) response.message.match = match;
          return response;
        }),
        HandleErrors()
      ).toPromise();
    }*/
  }

}