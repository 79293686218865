/**
 * @module Import
 */

import { Component, Input, OnDestroy, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { ImportFrameTagModel } from '@models/importFrame.model';
import { ImportFrameClass } from '@class/importFrame.class';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-import-tags',
  templateUrl: './import-tags.component.html',
  styleUrls: ['./import-tags.component.css']
})
export class ImportTagsComponent implements OnDestroy, OnChanges, OnInit {

  @Input() importFrame: ImportFrameClass;
  @Input() key: string;
  
  tags: ImportFrameTagModel[];
  tags$sub: Subscription;

  constructor() {
    this.tags = [];
  }

  ngOnInit() {
    this.subTags();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.importFrame !== undefined && changes.importFrame.currentValue !== undefined) {
      this.subTags();
    }
  }

  subTags() {
    if(this.tags$sub) this.tags$sub.unsubscribe();
    this.tags$sub = this.importFrame.tags$.subscribe({
      next: (tags: { [key: string]: ImportFrameTagModel[] }) => {
        this.tags = tags[this.key];
      }
    });
  }

  ngOnDestroy() {
    if(this.tags$sub) this.tags$sub.unsubscribe();
  }

  trackByIcon(index: number, tag: ImportFrameTagModel) {
    return tag.icon;
  }


}
