/**
 * @module Widget
 */

import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { WidgetLayerClass } from '@class/widgetLayer.class';
import { ItemColor, ItemIcon } from '@functions/item.functions';
import { Subscription } from 'rxjs';
import { DateApplyFormat, ParseGenericDate } from '@functions/moment.functions';

@Component({
  selector: 'app-widget-layers-date-bounds',
  templateUrl: './widget-layers-date-bounds.component.html',
  styleUrls: ['./widget-layers-date-bounds.component.css']
})
export class WidgetLayersDateBoundsComponent implements OnInit, OnDestroy {

  @Input() layer: WidgetLayerClass;

  color = ItemColor('dates', true);
  icon = ItemIcon('dates');
  from: string;
  to: string;
  fromGeneric: string;
  toGeneric: string;
  lastModifications$sub: Subscription;

  timeout: any;
  delay = 250;

  constructor() {}

  ngOnInit() {
    this.fromGeneric = this.layer.bounds.greater.value;
    this.from = DateApplyFormat(ParseGenericDate(this.fromGeneric, true), 'LLLL');

    this.toGeneric = this.layer.bounds.less.value;
    this.to = DateApplyFormat(ParseGenericDate(this.toGeneric, false), 'LLLL');

    this.lastModifications$sub = this.layer.lastModification$.subscribe((attribute: string) => {
      if(attribute === 'datesBounds') {
        this.fromGeneric = this.layer.bounds.greater.value;
        this.toGeneric = this.layer.bounds.less.value;
      }
    });
  }

  ngOnDestroy() {
    this.lastModifications$sub.unsubscribe();
  }

  receiveGreater(date: string, equivalent: boolean) {
    if(date === null) date = '';
    if(this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.layer.bounds.set('greater', equivalent, date);
    }, this.delay);
    this.from = DateApplyFormat(ParseGenericDate(date, true), 'LLLL');
  }

  receiveSetGreater(equivalent: boolean) {
    this.layer.bounds.set('greater', equivalent);
  }

  receiveLess(date: string, equivalent: boolean) {
    if(date === null) date = '';
    if(this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.layer.bounds.set('less', equivalent, date);
    }, this.delay);
    this.to = DateApplyFormat(ParseGenericDate(date, false), 'LLLL');
  }

  receiveSetLess(equivalent: boolean) {
    this.layer.bounds.set('less', equivalent);
  }

}
