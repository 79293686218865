/**
 * @module Widget
 */

import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { WidgetChartParamsModel } from '@models/widgetLayers.model';
import { LanguageType } from '@models/language.model';
import { Subscription } from 'rxjs';
import { AuthService } from '@services/auth/auth.service';
import { WidgetDatalabelsTableModel } from '@models/widgetDatalabelsTable.model';
import { WidgetLayersClass } from '@class/widgetLayers.class';

@Component({
  selector: 'app-widget-table',
  templateUrl: './widget-table.component.html',
  styleUrls: ['./widget-table.component.css']
})
export class WidgetTableComponent implements OnInit, OnChanges {

  @Input() widgetLayers: WidgetLayersClass;
  @Input() params: WidgetChartParamsModel;

  table: WidgetDatalabelsTableModel[];

  language: LanguageType;
  language$sub: Subscription;
  
  constructor(
    private $auth: AuthService
  ) {
    this.language$sub = this.$auth.language$.subscribe({
      next: (language: LanguageType) => {
        this.language = language;
      }
    });
  }

  ngOnInit() {
    this.table = this.widgetLayers.chartTable(this.params.datasets);
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.params !== undefined && !changes.params.firstChange) {
      this.table = this.widgetLayers.chartTable(this.params.datasets);
    }
  }

}
