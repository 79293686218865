<div *ngIf="!isRegistered">
  <div class="d-flex justify-content-center flex-column mt-3" *ngIf="list != undefined && list.length > 0 && showWarning">
    <div class="text-center alert alert-warning mb-0 p-3" role="alert">
      <i class="fad fa-lock-alt fa-2x animated rotateIn"></i><br>
      <div class="mt-3">{{ 'UNCHANGEABLE_WARNING/TEXT' | translate }}</div>
    </div>
  </div>

  <div class="mt-3">

    <app-input [marginTop]="false" [label]="'SEARCH'" [color]="color" [value]="searchLabel" (sendValue)="receiveTextValue($event)"></app-input>

    <app-servers-links-item *ngFor="let server of list | searchLabels:searchLabel:language" 
    [server]="server" [isSelected]="serverLink.uid == server.uid.value"
    (sendSelected)="receiveSelected($event)"></app-servers-links-item>

  </div>

  <app-empty app-browser-frame-content-list [action]="false" [type]="'servers'" *ngIf="list != undefined && list.length == 0"></app-empty>
  <app-loading app-browser-frame-content-list *ngIf="list == undefined"></app-loading>
</div>

<div *ngIf="isRegistered && partial != undefined">
  <div class="d-flex justify-content-center flex-column mt-3">
    <div class="text-center alert alert-info mb-0 p-3" role="alert">
      <i class="fad fa-lock-alt fa-2x animated rotateIn"></i><br>
      <div class="mt-3">{{ 'LOCKED_WARNING/TEXT' | translate }}</div>
    </div>
  </div>

  <app-links-item class="d-block mt-3" [color]="color" [icon]="icon" [labels]="partial.labels" [uid]="partial.uid">
    <div app-links-item-content-description>
      <div class="font-extra-small ng-star-inserted">{{ partial.url }}</div>
    </div>
  </app-links-item>
</div>