/**
 * @module Auth
 */

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@services/auth/auth.service';
import { map, tap } from 'rxjs/operators';
import { TokenService } from '@services/token/token.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  /**
   * @description Creates an instance of AuthGuard
   * @param {AuthService} $auth
   * @param {Router} $router
   * @memberof AuthGuard
   */
  constructor(
    private $auth: AuthService, 
    private $router: Router,
    private $token: TokenService
  ) {}

  /**
   * @description En fonction de l'état de l'authentification de l'utilisateur autorise l'accès à la ressource (authentifié) ou renvoie vers la racine (non authentifié)
   * @param {ActivatedRouteSnapshot} next
   * @param {RouterStateSnapshot} state
   * @returns {(Observable<boolean> | Promise<boolean> | boolean)}
   * @memberof AuthGuard
   */
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.$auth.user$.pipe(
      map(user => !!user), //transforme en boolean
      tap(loggedIn => {
        if(!loggedIn) {
          this.$router.navigate(['/'], { queryParams: { from: state.url } });
        }
        else {
          this.$token.get(this.$auth.server$.value);
        }
      })
    );
  }

}
