/**
 * @module Helper
 */

import { Component, ViewChild, Input } from '@angular/core';
import { ModalDirective } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-helper',
  templateUrl: './helper.component.html',
  styleUrls: ['./helper.component.css']
})
export class HelperComponent {

  @Input() title: string;
  @ViewChild('helperModal', { static: true }) modal: ModalDirective;

  constructor() {}

  public show() {
    this.modal.show();
  }

  public hide() {
    this.modal.hide();
  }

}
