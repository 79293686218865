/**
 * @module Indicator
 */

import { Injectable, OnDestroy } from '@angular/core';
import { IndicatorClass } from '@class/indicator.class';
import { Observable, BehaviorSubject, throwError, combineLatest } from 'rxjs';
import { IndicatorModel } from '@models/indicator.model';
import { map } from 'rxjs/operators';
import { FirestoreService } from '@services/firestore/firestore.service';
import { ResourceType } from '@models/resources.model';
import { AuthService } from '@services/auth/auth.service';
import { FirebaseService } from '@services/firebase/firebase.service';
import { UidService } from '@services/uid/uid.service';
import { ItemSymbol } from '@functions/item.functions';
import { LinksModelConstructor } from '@functions/link.functions';
import { ServerUrlModel } from '@models/server.model';
import { DeepMerge } from '@functions/copy.functions';
import { BackendCollectionsService } from '@services/backend/backendCollections/backend-collections.service';
import { BackendPivotsService } from '@services/backend/backendPivots/backend-pivots.service';
import { PivotService } from '@services/pivot/pivot.service';
import { DateService } from '@services/date/date.service';
import { PivotClass } from '@class/pivot.class';
import { DateClass } from '@class/date.class';

@Injectable({
  providedIn: 'root'
})
export class IndicatorService extends FirestoreService<IndicatorModel> implements OnDestroy {

  /**
   * @description type de document manipulé par le service
   * @protected
   * @type {ResourceType}
   * @memberof IndicatorService
   */
  protected _itemType: ResourceType = 'indicators';

  /**
   * @description true si l'accès à ce type de document peut nécessiter des habilitations
   * @protected
   * @type {boolean}
   * @memberof IndicatorService
   */
  protected _restricted: boolean = true;

  /**
   * @description Creates an instance of IndicatorService
   * @param {UidService} $uid
   * @param {AuthService} $auth
   * @param {FirebaseService} $firebase
   * @memberof IndicatorService
   */
  constructor(
    private $uid: UidService,
    protected $auth: AuthService,
    protected $firebase: FirebaseService,
    private $backendCollections: BackendCollectionsService,
    private $backendPivots: BackendPivotsService,
    private $pivots: PivotService,
    private $dates: DateService
  ) {
    super($firebase, $auth);
  }

  /**
   * @description Observateur de la liste des objects autorisés pour l'utilisateur courant
   * @returns {BehaviorSubject<IndicatorClass[]>}
   * @memberof IndicatorService
   */
  list$(): BehaviorSubject<IndicatorClass[]> {
    return super.docs$().pipe(
      map((indicators: IndicatorModel[]) => {
        if(!!indicators) {
          let ds = indicators.map<IndicatorClass>((indicator: IndicatorModel) => {
            return new IndicatorClass(indicator, true);
          });
          return ds;
        }
      })
    ) as BehaviorSubject<IndicatorClass[]>;
  }

  /**
   * @description Observateur d'un document dont l'uid est passé en paramètre
   * @param {string} uid
   * @returns {Observable<IndicatorClass>}
   * @memberof IndicatorService
   */
  get$(uid: string): Observable<IndicatorClass> {
    return super.doc$(uid).pipe(
      map((indicator: IndicatorModel) => {
        return new IndicatorClass(indicator, true);
      })
    );
  } 
  
  /**
   * @description Observateur de la liste des objects (données partielles) autorisés pour l'utilisateur courant
   * @returns {Observable<IndicatorClass[]>}
   * @memberof IndicatorService
   */
  listPartials$(): Observable<IndicatorClass[]> {
    return super.partials$().pipe(
      map((indicators: IndicatorModel[]) => {
        if(!!indicators) {
          let ds = indicators.map<IndicatorClass>((indicator: IndicatorModel) => {
            return new IndicatorClass(indicator, true);
          });
          return ds;
        }
      })
    );
  }

  /**
   * @description Observateur d'un document (données partielles) dont l'uid est passé en paramètre
   * @param {string} uid
   * @returns {Observable<IndicatorClass>}
   * @memberof IndicatorService
   */
  getPartial$(uid: string): Observable<IndicatorClass> {
    return super.partial$(uid).pipe(
      map((indicator: IndicatorModel) => {
        if(indicator !== null) return new IndicatorClass(indicator, true);
      })
    );
  }

  /**
   * @description Créé un nouveau document avec les valeurs par défaut
   * @param {ServerUrlModel} [server=this.$auth.server$.value]
   * @param {Partial<IndicatorModel>} [model={}]
   * @param {ServerUrlModel} [server=this.$auth.server$.value]
   * @returns {IndicatorClass}
   * @memberof IndicatorService
   */
  create(model: Partial<IndicatorModel> = {}, server: ServerUrlModel = this.$auth.server$.value): IndicatorClass {
    let _model = { 
      uid: this.$uid.create(),
      icon: {
        symbol: ItemSymbol(this._itemType),
        style: 'r',
        options: []
      },
      color: {
        hexa: '00bcd4'
      },
      links: LinksModelConstructor().links,
      logs: {},
      tags: {},
      readers: {},
      writers: {},
      frequency: {
        code: 'M'
      },
      linkedColumns: [],
      offset: 0,
      mainDate: undefined,
      share: 's',
      labels: undefined,
      descriptions: undefined,
      serverLink: { uid: server.uid, url: server.url }
    } as IndicatorModel;

    _model = DeepMerge(_model, model as IndicatorModel);

    return new IndicatorClass(_model, false);
  }

  /**
   * @description Créé ou met à jour le document passé en paramètre
   * @param {IndicatorClass} indicator
   * @returns {Promise<void>}
   * @memberof IndicatorService
   */
  async set(indicator: IndicatorClass): Promise<void> {

    //vérification des pivots/dates supprimés
    let deleted = DeepMerge(indicator.links.removed('pivots'), indicator.links.removed('dates'));

    //vérification des pivots/dates remplacés par des pivots/dates existant
    let replaced = DeepMerge(indicator.links.replaced('pivots'), indicator.links.replaced('dates'));

    //vérification des pivots/dates remplacés par des pivots/dates nouveaux
    let newItems = indicator.links.newItems('pivots').concat(indicator.links.newItems('dates'));

    newItems.forEach(async (p: PivotClass | DateClass) => {
      p.links.add('indicators', indicator.uid.value);
      if(p.class === 'pivot') await this.$pivots.set(p as PivotClass);
      if(p.class === 'date') await this.$dates.set(p as DateClass);
    });

    return super.update(indicator.uid.value, indicator.model).then(() => {
      if(Object.keys(deleted).length > 0) {
        this.$backendPivots.delete(indicator.uid.value, indicator.serverLink, deleted);
      };
      if(Object.keys(replaced).length > 0) {
        this.$backendPivots.post(indicator.uid.value, indicator.serverLink, replaced);
      };
    });
  }

  /**
   * @description Supprime le document passé en paramètre
   * @param {string} uid
   * @returns {Promise<void>}
   * @memberof IndicatorService
   */
  async remove(uid: string, server: ServerUrlModel): Promise<void> {
    const response = await this.$backendCollections.delete(uid, server);
    if(!response.success) return throwError({ code: 'MONGO_DELETION_FAILED' }).toPromise();
    else return super.delete(uid);
  }

}
