/**
 * @module Language
 */

import { LanguageType } from '@models/language.model';
import frenchTranslation from 'pickadate/builds/translations/fr_FR';
import spanishTranslation from 'pickadate/builds/translations/es_ES';

export function LanguagesTypes(): LanguageType[] {
  return ['en', 'es', 'fr'];
}

export function LanguagesLabels(): { [key in LanguageType]: string } {
  return {
    en: 'english', 
    es: 'español', 
    fr: 'français'
  };
}

export function LanguagesPickadate(language: LanguageType): any {
  return {
    en: undefined, 
    es: spanishTranslation, 
    fr: frenchTranslation
  }[language];
}

export function IsLanguage(language: any): boolean {
  return LanguagesTypes().includes(language);
} 

export function LanguageDefault(): LanguageType {
  return localStorage.getItem('language') as LanguageType || navigator.language.slice(0, 2) as LanguageType || 'en';
}

const languagesCodes = {
  en: 'en-GB',
  fr: 'fr-FR',
  es: 'es-ES'
};

export function LanguageCode(language: LanguageType): string {
  return languagesCodes[language];
}

export function LanguagesCodes(): string[] {
  return Object.values(languagesCodes);
}