/**
 * @module Widget
 */

import { Component, Input } from '@angular/core';
import { WidgetLayerClass } from '@class/widgetLayer.class';
import { ItemColor } from '@functions/item.functions';

@Component({
  selector: 'app-widget-layers-label',
  templateUrl: './widget-layers-label.component.html',
  styleUrls: ['./widget-layers-label.component.css']
})
export class WidgetLayersLabelComponent {

  @Input() layer: WidgetLayerClass;

  color = ItemColor('widgets');

  constructor() {}

}
