<div class="alert alert-info font-small mb-1" role="alert">
  <i class="fad fa-info-square mr-1"></i>{{ 'DESIGNER_DATES_BOUNDS' | translate }}&nbsp;<u><b><a (click)="onGoto()">{{ 'HERE' | translate }}</a></b></u>
</div>

<app-links-item *ngFor="let uid of widgetLayers.dates" [color]="color" [icon]="icon" [labels]="widgetLayers.layer(uid).layerLabel" [uid]="widgetLayers.layer(uid).uid">
  <div app-links-item-content-description>
  </div>
  <div app-links-item-content-sub class="mt-3">
    <div *ngIf="widgetLayers.layer(uid).bounds.hasAny ; else no_bounds">
      <div *ngIf="widgetLayers.layer(uid).bounds.has('greater')" class="p-2 rounded white mt-1 d-flex w-100 align-items-center">
        <div class="mr-auto font-small">
          {{ 'FROM' | translate }}<span class="font-weight-bold ml-1">{{ widgetLayers.layer(uid).bounds.greaterParsed }}</span>
        </div>
        <div *ngIf="widgetLayers.layer(uid).bounds.greater.equivalent" class="app-widget-designer-dates-bounds-circle rounded-circle green lighten-3 d-flex justify-content-center align-items-center">
          <i class="fad fa-greater-than-equal green-text animated rotateIn"></i>
        </div>
        <div *ngIf="!widgetLayers.layer(uid).bounds.greater.equivalent" class="app-widget-designer-dates-bounds-circle rounded-circle red lighten-3 d-flex justify-content-center align-items-center">
          <i class="fad fa-greater-than freen-text animated rotateIn"></i>
        </div>
      </div>

      <div *ngIf="widgetLayers.layer(uid).bounds.has('less')" class="p-2 rounded white mt-1 d-flex w-100 align-items-center">
        <div class="mr-auto font-small">
          {{ 'TO' | translate }}<span class="font-weight-bold ml-1">{{ widgetLayers.layer(uid).bounds.lessParsed }}</span>
        </div>
        <div *ngIf="widgetLayers.layer(uid).bounds.less.equivalent" class="app-widget-designer-dates-bounds-circle rounded-circle green lighten-3 d-flex justify-content-center align-items-center">
          <i class="fad fa-less-than-equal green-text animated rotateIn"></i>
        </div>
        <div *ngIf="!widgetLayers.layer(uid).bounds.less.equivalent" class="app-widget-designer-dates-bounds-circle rounded-circle red lighten-3 d-flex justify-content-center align-items-center">
          <i class="fad fa-less-than freen-text animated rotateIn"></i>
        </div>
      </div>
    </div>
  </div>
</app-links-item>

<ng-template #no_bounds>
  <div class="font-small p-2 rounded white mt-1 d-flex w-100 align-items-center">
    {{ 'NO_DESIGNER_BOUNDS' | translate }}
  </div>
</ng-template>