/**
 * @module Import
 */

import { Component, Input, ViewChild, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ImportFrameClass } from '@class/importFrame.class';
import { UID } from '@models/uid.model';
import { IndicatorClass } from '@class/indicator.class';
import { PivotClass } from '@class/pivot.class';
import { DateClass } from '@class/date.class';
import { IndicatorEditorComponent } from '@components/indicators/indicator-editor/indicator-editor.component';
import { DateEditorComponent } from '@components/dates/date-editor/date-editor.component';
import { PivotEditorComponent } from '@components/pivots/pivot-editor/pivot-editor.component';
import { ItemsColors, ItemsIcons } from '@functions/item.functions';
import { BookType } from 'xlsx/types';
import { LanguageType } from '@models/language.model';
import { Subscription, BehaviorSubject } from 'rxjs';
import { AuthService } from '@services/auth/auth.service';
import { FiltersPipeModel } from '@pipes/filters/filters.pipe';

@Component({
  selector: 'app-import-import-frame',
  templateUrl: './import-import-frame.component.html',
  styleUrls: ['./import-import-frame.component.css']
})
export class ImportImportFrameComponent implements OnDestroy {

  @ViewChild('indicatorEditor') indicatorEditor: IndicatorEditorComponent;
  @ViewChild('dateEditor') dateEditor: DateEditorComponent;
  @ViewChild('pivotEditor') pivotEditor: PivotEditorComponent;
  
  @Input() filters$: BehaviorSubject<{ [key: string]: { [key: string]: FiltersPipeModel } }>;
  @Input() importFrame: ImportFrameClass;
  @Output() sendSelectedKey: EventEmitter<string>;

  indicator: IndicatorClass;
  date: DateClass;
  pivot: PivotClass;
  language: LanguageType;
  language$sub: Subscription;

  colors = ItemsColors(true);
  icons = ItemsIcons();
  current: UID;

  constructor(
    private $auth: AuthService
  ) {
    this.sendSelectedKey = new EventEmitter<string>();

    this.language$sub = this.$auth.language$.subscribe({
      next: (language: LanguageType) => {
        this.language = language;
      }
    });
  }

  ngOnDestroy() {
    this.language$sub.unsubscribe();
  }

  hasFilters(uid: UID): boolean {
    const key = this.importFrame.dataFrame.keyForUid(uid);
    return this.filters$.value !== undefined && this.filters$.value[key] !== undefined && Object.keys(this.filters$.value[key]).length > 0;
  }

  receiveDisplay(uid: UID, key: string) {
    this.current = uid;
    this.sendSelectedKey.emit(key);
  }

  onMore(key: string, uid: UID) {
    this.current = uid;
    this.sendSelectedKey.emit(key);
  }

  receiveFileType(fileType: BookType) {
    this.importFrame.download(this.language, fileType);
  }

  receiveIndicator(uid: UID, current: IndicatorClass) {
    this.indicator = current;
    setTimeout(() => this.indicatorEditor.show());
  }

  receivePivot(uid: UID, current: PivotClass) {
    this.pivot = current;
    setTimeout(() => this.pivotEditor.show());
  }

  receiveDate(uid: UID, current: DateClass) {
    this.date = current;
    setTimeout(() => this.dateEditor.show());
  }

  receiveDelete() {
    //TODO
    //quoi faire ?
  }

}
