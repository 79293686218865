<div class="d-flex justify-content-center flex-column mt-3">
  <div class="text-center alert alert-danger mb-0 p-3" role="alert">
    <i class="fad fa-exclamation-triangle fa-2x animated rotateIn"></i><br>
    <div class="my-3">{{ 'REMOVE_WARNING_WITH_CODE/TEXT' | translate }}</div>
    <h2 class="mb-0">
      <mdb-badge danger="true" class="z-depth-0">{{code}}</mdb-badge>
    </h2>
  </div>

  <div class="md-form mb-0 md-outline">
    <input type="text" id="confirmId" class="form-control form-danger" mdbInput [(ngModel)]="confirm">
    <label for="confirmId">{{ 'CODE' | translate }}</label>
  </div>

  <button [disabled]="confirm != code" (click)="onDelete()" type="button" mdbBtn color="danger" class="z-depth-0 waves-light mx-0" mdbWavesEffect>
    {{ 'REMOVE' | translate }}
  </button>
</div>