<ul class="list-group" *ngIf="!upload.isUploading && !upload.isEnded" style="list-style: none;">
  <li class="d-flex flex-wrap align-items-center">

    <div class="d-flex flex-wrap align-items-center flex-grow-1">

      <button [disabled]="importFrame.dataFrame.isErrors" mdbBtn [color]="color" class="mt-0 mb-2 waves-light z-depth-0 px-3 ml-0" type="button" (click)="onPut()" mdbWavesEffect>
        <i class="d-sm-none fas fa-arrow-alt-from-bottom fa-fw"></i>
        <i class="d-none d-sm-inline fas fa-arrow-alt-from-bottom"></i>
        <span class="d-none d-sm-inline ml-1">{{ 'SAVE' | translate }}</span>
      </button>

      <button [disabled]="!importFrame.dataFrame.isErrors" mdbBtn color="danger" class="mt-0 mb-2 waves-light z-depth-0 px-3 ml-0" type="button" (click)="onFilterErrors()" mdbWavesEffect>
        <i class="{{ isShowErrors ? 'fas' : 'far' }} fa-exclamation-triangle fa-fw"></i>
      </button>

      <button [disabled]="!isOpened" mdbBtn color="secondary" class="mt-0 mb-2 waves-light z-depth-0 px-3 ml-0" type="button" (click)="onRestore()" mdbWavesEffect>
        <i class="far fa-times fa-fw"></i>
      </button>

      <button [disabled]="!isOpened" mdbBtn color="default" class="mt-0 mb-2 waves-light z-depth-0 px-3 ml-0" type="button" (click)="onSave()" mdbWavesEffect>
        <i class="far fa-check fa-fw"></i>
      </button>

      <button [disabled]="!isSelection" mdbBtn color="danger" class="mt-0 mb-2 waves-light z-depth-0 px-3 ml-0" type="button" (click)="onRemove()" mdbWavesEffect>
        <i class="fas fa-trash fa-fw"></i>
      </button>

    </div>

  </li>

  <li>
    <div class="app-import-values-cells simplebar-cyan" data-simplebar data-simplebar-auto-hide="false">
      <ul class="p-0">
        <li class="p-1 list-group-item list-group-item-action waves-light app-import-values-rows">
          <div class="d-flex align-items-center my-3 mx-2">
            <div *ngFor="let key of importFrame.dataFrame.keys">
              
              <div *ngIf="key.slice(0, 2) != '$$'">
                <div class="d-flex align-items-center m-1 p-2 blue-grey-text font-small font-weight-bold">
                  <div class="app-import-values-key">
                    <i *ngIf="filtersCount[key] > 0" class="fas fa-filter mr-1 app-opacity-50"></i>
                    {{key}}<br>
                    <span class="text-black-25 font-very-small"><i class="far fa-link mr-1"></i>{{ importFrame.item({ key: key }).labels.value(language) }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="ml-auto">
              <app-links-item-option class="d-block my-2 mr-2 ml-3" [symbol]="'check'" [selected]="isAllSelected" (click)="onSelectAll()"></app-links-item-option>
            </div>
          </div>
        </li>

        <li *ngFor="let row of importFrame.dataFrame.values | filters:filters | paginate: { id: 'cellsId', itemsPerPage: limit, currentPage: page } ; trackBy: trackByUid ; let isLast = last" class="p-1 list-group-item list-group-item-action waves-light app-import-values-rows">
          <div class="d-flex align-items-center mx-2" [ngClass]="{ 'mb-3': isLast }">
            
            <div *ngFor="let key of importFrame.dataFrame.keys">
              <app-import-cell *ngIf="key.slice(0, 2) != '$$'" [format]="importFrame.dataFrame.types(key).format" [isEdit]="opened[row.$$uid + '_' + key]" (sendEdited)="receiveEdited($event, key)" (sendOpened)="receiveOpened($event, key)" (sendClosed)="receiveClosed($event, key)"
                [forceClose$]="forceClose$" [key]="key" [row]="row" [type]="importFrame.dataFrame.types(key).item"></app-import-cell>
            </div>

            <div class="ml-auto">
              <app-links-item-option class="d-block my-2 mr-2 ml-3" [symbol]="'check'" [uid]="row.$$uid" [selected]="selection[row.$$uid]" [selected$]="forceSelected$" (sendSelected)="receiveSelected($event)"></app-links-item-option>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </li>
  <li *ngIf="!upload.isUploading && !upload.isEnded" class="py-3 px-1 app-import-values-pagination list-group-item list-group-item-action waves-light text-center blue-grey-text white-text">
    
    <pagination-controls class="font-small font-weight-bold d-block mb-2"
      id="cellsId"
      (pageChange)="onPageChanged($event)"
      [maxSize]="limit"
      directionLinks="true"
      autoHide="true"
      responsive="true"
      previousLabel=""
      nextLabel="">
    </pagination-controls>
    
    <span (click)="onLimit(10)" class="m-1 white-text app-cursor-pointer"><mdb-badge class="z-depth-0 p-2" color="cyan"><i class="far fa-{{ limit == 10 ? 'dot-circle' : 'circle' }} mr-1"></i>10</mdb-badge></span>
    <span (click)="onLimit(50)" class="m-1 white-text app-cursor-pointer"><mdb-badge class="z-depth-0 p-2" color="cyan"><i class="far fa-{{ limit == 50 ? 'dot-circle' : 'circle' }} mr-1"></i>50</mdb-badge></span>
    <span (click)="onLimit(150)" class="m-1 white-text app-cursor-pointer"><mdb-badge class="z-depth-0 p-2" color="cyan"><i class="far fa-{{ limit == 150 ? 'dot-circle' : 'circle' }} mr-1"></i>150</mdb-badge></span>
  </li>

</ul>

<div *ngIf="upload.isUploading || upload.isEnded">
  <app-import-uploading [importFrame]="importFrame" [upload]="upload" (sendRetry)="receiveRetry()"></app-import-uploading>
</div>