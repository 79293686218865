/** 
 * @module Theme 
 */

import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ThemeClass } from '@class/theme.class';
import { ThemeOptionsClass } from '@class/themeOptions.class';
import { ThemeStudioService } from '@services/themeStudio/theme-studio.service';
import { ItemColor } from '@functions/item.functions';
import { MessagesService } from '@services/messages/messages.service';
import { SortableStopModel } from '@directives/sortable/sortable.directive';
import { CreateRandomUid } from '@functions/uid.functions';
import { UID } from '@models/uid.model';
import { ThemeStudioCategoryType, ThemeStudioCurrentModel } from '@models/themeStudio.model';

@Component({
  selector: 'app-theme-studio-main',
  templateUrl: './theme-studio-main.component.html',
  styleUrls: ['./theme-studio-main.component.css']
})
export class ThemeStudioMainComponent implements OnInit {

  @Input() theme: ThemeClass;
  @Input() themeOptions: ThemeOptionsClass;
  @Input() current: ThemeStudioCurrentModel;
  @Output() sendCurrent: EventEmitter<ThemeStudioCurrentModel>;

  color = ItemColor('themes');
  categories: ThemeStudioCategoryType[] = ['LAYOUT', 'TITLE', 'LEGEND', 'TOOLTIPS', 'ANIMATION', 'SPECIFIC'];
  series: { category: ThemeStudioCategoryType, index: number, uid: UID }[] = []; //uid uniquement utile pour rafaichir le ngFor du template
  datalabels: ThemeStudioCurrentModel[] = [];
  xAxes: ThemeStudioCurrentModel[] = [{ category: 'X-AXIS', index: 0 }];
  yAxes: ThemeStudioCurrentModel[] = [{ category: 'Y-AXIS', index: 0 }, { category: 'Y-AXIS', index: 1 }];

  MAX_SERIES = 9;
  MIN_SERIES = 1;

  MAX_DATALABELS = 9;
  MIN_DATALABELS  = 1;

  constructor(
    private $studio: ThemeStudioService,
    private $messages: MessagesService
  ) {
    this.sendCurrent = new EventEmitter<ThemeStudioCurrentModel>();
  }

  ngOnInit() {
    this.series = new Array(this.themeOptions.seriesCount()).fill('').map((value: any, index: number) => { return { category: 'SERIE', index: index, uid: CreateRandomUid() }; });
    this.datalabels = new Array(this.themeOptions.datalabelsCount()).fill('').map((value: any, index: number) => { return { category: 'DATALABELS', index: index }; });
  }

  receiveValuePerSeries(values: number) {
    this.$studio.valuesPerSeries = values;
  }

  onSelect(current: ThemeStudioCurrentModel) {
    this.current = { category: current.category, index: current.index };
    this.sendCurrent.emit(this.current);
  }

  onRemoveSerie(index: number) {
    this.$messages.question({ text: { code: 'REMOVE_WARNING/TEXT' } }, 'warning', 'REMOVE')
    .then((isClick: boolean) => {
      if(isClick) {
        const i = this.series.map(s => s.index).indexOf(index);
        if(this.themeOptions.removeSerie(i)) {
          this.series.pop();
          this.$studio.setSeries(this.series.length);
          if(this.current.category == 'SERIE' && this.current.index >= this.series.length - 1) this.current = { category: 'SERIE', index:  this.series.length - 1 };
        }
      }
    });
  }

  onRemoveDatalabel(index: number) {
    this.$messages.question({ text: { code: 'REMOVE_WARNING/TEXT' } }, 'warning', 'REMOVE')
    .then((isClick: boolean) => {
      if(isClick) {
        const i = this.datalabels.map(d => d.index).indexOf(index);
        if(this.themeOptions.removeDatalabel(i)) {
          this.datalabels.pop();
          if(this.current.category == 'DATALABELS' && this.current.index >= this.series.length - 1) this.current = { category: 'DATALABELS', index:  this.series.length - 1 };
        }
      }
    });
  }

  onAddSerie(index: number) {
    this.series.push({ index: this.series.length, uid: CreateRandomUid(), category: 'SERIE' });
    this.$studio.setSeries(this.series.length);
    this.themeOptions.addSerie(index);
    this.current = { category: 'SERIE', index:  this.series.length - 1 };
  }

  onAddDatalabel(index: number) {
    this.datalabels.push({ index: this.datalabels.length, category: 'DATALABELS' });
    this.themeOptions.addDatalabel(index);
    this.current = { category: 'DATALABELS', index:  this.series.length - 1 };
  }

  receiveSortableStop(sortable: SortableStopModel) {
    this.themeOptions.setSerieIndex(sortable.sourceIndex, sortable.targetIndex);
    this.series = new Array(this.themeOptions.seriesCount()).fill('').map((value: any, index: number) => { return { category: 'SERIE', index: index, uid: CreateRandomUid() }; });
  }

}
