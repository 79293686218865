/**
 * @module Widget
 */

import { Component, Input } from '@angular/core';
import { WidgetLayersClass } from '@class/widgetLayers.class';
import { ItemColor } from '@functions/item.functions';

@Component({
  selector: 'app-widget-indicators-formula',
  templateUrl: './widget-indicators-formula.component.html',
  styleUrls: ['./widget-indicators-formula.component.css']
})
export class WidgetIndicatorsFormulaComponent {

  @Input() widgetLayers: WidgetLayersClass;
  @Input() current: number;

  color = ItemColor('indicators');

  constructor() {}

  receiveFormula(formula: string) {
    this.widgetLayers.indicators[this.current].change({ formula: formula });
  }

}
