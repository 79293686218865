<app-links-item *ngIf="date != undefined && display" [color]="color" [icon]="icon" [labels]="date.labels" [uid]="date.uid">
  <div app-links-item-content-description>
    <div *ngIf="!links.has('dates', uid)"><mdb-badge warning="true" class="z-depth-0">{{ 'REMOVE_WARNING/TEXT' | translate }}</mdb-badge></div>
    <div *ngIf="links.isReplaced('dates', uid)"><mdb-badge info="true" class="z-depth-0">{{ 'LINKDATE_MODIFIED/TEXT' | translate }}</mdb-badge></div>
  </div>
  <div app-links-item-content-option>
    <app-links-item-option class="d-block mx-1" [symbol]="'check'" [selected]="links.has('dates', uid)" (sendSelected)="receiveSelected()"></app-links-item-option>
  </div>
  <div app-links-item-content-option>
    <button (click)="onChange()" mdbBtn type="button" color="light" size="sm" class="px-2 z-depth-0 my-0 mr-0 ml-2" mdbWavesEffect>
      <i class="fas fa-link fa-fw {{ color }}-text"></i>
    </button>
  </div>
  <div app-links-item-content-sub>
    <div *ngIf="date && date?.uid?.isRegistered == false" class="mt-3 white p-2 rounded">
      <app-labels [labels]="date.labels" [color]="color"></app-labels>
    </div>
    <div class="blue-grey lighten-4 p-2 mt-3 rounded" *ngIf="isChanged && date">
      <app-dates-links-change [exclude]="exclude" [date]="date" [withIcon]="false" (sendSelected)="receiveLinkChange($event)"></app-dates-links-change>
    </div>
  </div>
</app-links-item>