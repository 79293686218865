/**
 * @module User
 */

import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { PivotFiltersClass } from '@class/widgetFilters.class';
import { PivotClass } from '@class/pivot.class';
import { UID } from '@models/uid.model';
import { ItemsColors, ItemsIcons } from '@functions/item.functions';
import { PivotService } from '@services/pivot/pivot.service';
import { take, takeWhile } from 'rxjs/operators';
import { UserClass } from '@class/user.class';
import { LanguageType } from '@models/language.model';
import { Subscription } from 'rxjs';
import { AuthService } from '@services/auth/auth.service';
import { FiltersPipeModel } from '@pipes/filters/filters.pipe';

interface PivotSelectModel {
  value: string; //UID
  label: string;
  selected: boolean;
}

@Component({
  selector: 'app-user-filters',
  templateUrl: './user-filters.component.html',
  styleUrls: ['./user-filters.component.css']
})
export class UserFiltersComponent implements OnInit, OnDestroy {

  @Input() user: UserClass;

  pivots: PivotClass[] = [];
  pivots$sub: Subscription;
  loading: boolean;
  current: UID;
  selectOptions: PivotSelectModel[] = [];
  language: LanguageType;
  language$sub: Subscription;
  pipeParams: FiltersPipeModel;

  colors = ItemsColors(true);
  icons = ItemsIcons();

  constructor(
    private $pivots: PivotService,
    private $auth: AuthService
  ) {
    this.loading = true;

    this.language$sub = this.$auth.language$.subscribe({
      next: (language: LanguageType) => {
        this.language = language;
      }
    });

    this.pipeParams = {
      field: 'selected',
      type: 'boolean',
      requirements: {
        value: {
          comparaison: '!=',
          reference: true
        }
      }
    }
  }

  ngOnInit() {
    this.user.filters.forEach((filter: PivotFiltersClass) => {
      this.$pivots.get$(filter.uid.value).pipe(take(1)).subscribe((p: PivotClass) => {
        if(!!p) {
          this.pivots.push(p);
          this.pivots.sort((a, b) => {
            if(a.labels.value(this.language) < b.labels.value(this.language)) { return -1; }
            if(a.labels.value(this.language) > b.labels.value(this.language)) { return 1; }
            return 0;
          });
          this.loading = false;
        }
      });
    });
    if(this.user.filters.length === 0) this.loading = false;

    this.pivots$sub = this.$pivots.listPartials$().subscribe((pivots: PivotClass[]) => {
      if(!!pivots) {
        this.selectOptions = pivots.map((pivot: PivotClass) => {
          return {
            value: pivot.uid.value,
            label: pivot.labels.value(this.language),
            selected: this.user.filters.map(f => f.uid.value).indexOf(pivot.uid.value) !== -1
          };
        });
      }
    });
  }

  getSelectedValue(selected: PivotSelectModel) {
    if(!this.user.hasFilter(selected.value)) {
      this.user.filter(selected.value);
      this.$pivots.get$(selected.value).pipe(take(1)).subscribe((p: PivotClass) => {
        if(!!p) {
          this.pivots.push(p);
          this.pivots.sort((a, b) => {
            if(a.labels.value(this.language) < b.labels.value(this.language)) { return -1; }
            if(a.labels.value(this.language) > b.labels.value(this.language)) { return 1; }
            return 0;
          });
        }
      });
      const index = this.selectOptions.map(s => s.value).indexOf(selected.value);
      this.selectOptions[index].selected = true;
    }
  }

  ngOnDestroy() {
    this.language$sub.unsubscribe();
    this.pivots$sub.unsubscribe();
  }

  onEdit(uid: UID) {
    this.current = this.current === uid ? undefined : uid;
  }

  receiveSelect(uid: UID) {
    this.onEdit(uid);
  }

}