/**
 * @module Widget
 */

import { Component, Input } from '@angular/core';
import { WidgetLayersClass } from '@class/widgetLayers.class';
import { ItemColor } from '@functions/item.functions';
import { WidgetIndicatorAxis } from '@models/widgetLayers.model';

@Component({
  selector: 'app-widget-indicators-axis',
  templateUrl: './widget-indicators-axis.component.html',
  styleUrls: ['./widget-indicators-axis.component.css']
})
export class WidgetIndicatorsAxisComponent {

  @Input() widgetLayers: WidgetLayersClass;
  @Input() current: number;

  color = ItemColor('indicators');
  axes: { label: string, value: WidgetIndicatorAxis }[] = [{ label: 'MAIN_AXIS', value: 'main' }, { label: 'SECONDARY_AXIS', value: 'secondary' }];

  constructor() {}

  onValue(value: WidgetIndicatorAxis) {
    this.widgetLayers.indicators[this.current].change({ axis: value });
  }

}
