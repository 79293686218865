/**
 * @module Import
 */

import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { DataFrameItemType, DataFrameValueModel } from '@models/dataFrame.model';
import { ItemsColors, ItemsIcons, ItemColor } from '@functions/item.functions';
import { Subscription, Subject } from 'rxjs';
import { ParseDate } from '@functions/moment.functions';
import { ParsedDateModel } from '@models/moment.model';

@Component({
  selector: 'app-import-cell',
  templateUrl: './import-cell.component.html',
  styleUrls: ['./import-cell.component.css']
})
export class ImportCellComponent implements OnInit, OnDestroy {

  @Input() key: string;
  @Input() type: DataFrameItemType;
  @Input() row: DataFrameValueModel;
  @Input() forceClose$: Subject<boolean>;
  @Input() isEdit: boolean;

  @Input() format: string;
  parsedDate: ParsedDateModel;

  @Output() sendEdited: EventEmitter<DataFrameValueModel>;
  @Output() sendOpened: EventEmitter<DataFrameValueModel>;
  @Output() sendClosed: EventEmitter<DataFrameValueModel>;

  colors = ItemsColors(true);
  color = ItemColor('indicators');
  icons = ItemsIcons();
  modified: any;
  forceClose$sub: Subscription;

  constructor() {
    this.sendEdited = new EventEmitter<DataFrameValueModel>();
    this.sendOpened = new EventEmitter<DataFrameValueModel>();
    this.sendClosed = new EventEmitter<DataFrameValueModel>();
  }

  ngOnInit() {
    if(this.format !== undefined) this.parsedDate = ParseDate(this.row[this.key]);
    this.modified = this.row[this.key];

    this.forceClose$sub = this.forceClose$.subscribe({
      next: (send: boolean) => {
        if(this.isEdit) {
          this.isEdit = false;
          if(send) this.send();
          else this.modified = this.row[this.key];
        }
      }
    });
  }

  ngOnDestroy() {
    this.forceClose$sub.unsubscribe();
  }

  onEdit() {
    this.isEdit = !this.isEdit;
    if(this.isEdit) this.sendOpened.emit(this.row);
    else this.sendClosed.emit(this.row);
  }

  onRestore() {
    this.isEdit = false;
    this.modified = this.row[this.key];
    this.sendClosed.emit(this.row);
  }

  onValid() {
    this.isEdit = false;
    this.send();
  }

  onValue(modified: any) {
    this.modified = this.type !== 'indicators' || isNaN(parseFloat(modified)) ? modified : parseFloat(modified);
  }

  send() {
    this.row[this.key] = this.modified;
    this.sendEdited.emit(this.row); 
  }

}
