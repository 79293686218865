/**
 * @module Widget
 */

import { Component, Input, ViewChild } from '@angular/core';
import { WidgetLayersClass } from '@class/widgetLayers.class';
import { ItemColor } from '@functions/item.functions';
import { HelperDateFormatComponent } from '@components/helpers/helper-date-format/helper-date-format.component';

@Component({
  selector: 'app-widget-date-format',
  templateUrl: './widget-date-format.component.html',
  styleUrls: ['./widget-date-format.component.css']
})
export class WidgetDateFormatComponent {

  @Input() widgetLayers: WidgetLayersClass;
  @ViewChild('dateFormatHelper', { static: true }) dateFormatHelper: HelperDateFormatComponent;

  color = ItemColor('widgets');

  timeout: any;

  constructor() {}

  receiveValue(value: string) {
    if(this.timeout !== undefined) clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.widgetLayers.dateFormat = value;
    }, 250);
  }

}
