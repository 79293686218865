/**
 * @module Log
 */

/**
 * @description Accesseur des durées de conservation des logs par criticité
 * @export
 * @returns {Readonly<{ critical: number, standard: number, recent: number }>}
 */
export function DeprecatedDurations(): Readonly<{ critical: number, standard: number, recent: number }> {
  return {
    critical: 120,
    standard: 30,
    recent: 2
  };
}
