/**
 * @module Widget
 */

import { Component, Input } from '@angular/core';
import { WidgetLayersClass } from '@class/widgetLayers.class';
import { UID } from '@models/uid.model';
import { ItemColor } from '@functions/item.functions';
import { ChartsTypes } from '@functions/chart.functions';
import { ChartType } from '@models/chart.model';

@Component({
  selector: 'app-widget-layers-chart',
  templateUrl: './widget-layers-chart.component.html',
  styleUrls: ['./widget-layers-chart.component.css']
})
export class WidgetLayersChartComponent {

  @Input() widgetLayers: WidgetLayersClass;
  @Input() current: UID;

  color = ItemColor('widgets');
  chartTypes: string[];

  constructor() {
    this.chartTypes = ChartsTypes(['bubble', 'scatter']); //TODO retirer les exclusions quand ces charts seront supportés
  }

  onValue(chartType: ChartType) {
    this.widgetLayers.layer(this.current).change({ chartType: chartType });
    if(this.widgetLayers.uid.value === this.current) this.widgetLayers.duplicateLayersOptions({ chartType: chartType }, this.current);
  }

}
