/**
 * @module Widget
 */

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { WidgetLayersClass } from '@class/widgetLayers.class';
import { ThemeOptionsClass } from '@class/themeOptions.class';
import { UID } from '@models/uid.model';
import { LanguageType } from '@models/language.model';
import { Subscription } from 'rxjs';
import { ItemColor, ItemIcon } from '@functions/item.functions';
import { AuthService } from '@services/auth/auth.service';

@Component({
  selector: 'app-widget-studio-sub-indicators',
  templateUrl: './widget-studio-sub-indicators.component.html',
  styleUrls: ['./widget-studio-sub-indicators.component.css']
})
export class WidgetStudioSubIndicatorsComponent {

  @Input() widgetLayers: WidgetLayersClass;
  @Input() themeOptions: ThemeOptionsClass;
  @Input() current: UID;
  @Output() sendDelete: EventEmitter<void>;

  editorIndex: number;
  editorIndexMax: number;

  language: LanguageType;
  language$sub: Subscription;
  
  icon = ItemIcon('indicators');
  color = ItemColor('indicators');

  constructor(
    private $auth: AuthService
  ) {

    this.sendDelete = new EventEmitter<void>();

    this.language$sub = this.$auth.language$.subscribe({
      next: (language: LanguageType) => {
        this.language = language;
      }
    });

    this.editorIndex = 0;
    this.editorIndexMax = 3;
  }

  receiveDelete() {
    this.sendDelete.emit();
  }

  receiveNext(isNext: boolean) {
    this.editorIndex = this.editorIndex + (isNext ? 1 : -1);
    if(this.editorIndex < 0) this.editorIndex = this.editorIndexMax;
    if(this.editorIndex > this.editorIndexMax) this.editorIndex = 0;
  } 

}
