<div class="app-auth-logo-swapp animated slideInLeft"></div>

<div mdbModal #authModal="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="authModal" aria-hidden="true"
  [config]="{ignoreBackdropClick: true, keyboard: false, show: true}">
  <div class="modal-dialog modal-sm modal-dialog-centered modal-dialog-scrollable" role="document">
    <div class="modal-content">

      <div class="modal-body d-flex flex-column">
        <div class="px-3 pt-3 d-flex justify-content-center flex-column mb-auto">
          <img style="height: 140px; margin-left: 40px" class="animated fadeInUp" src="assets/images/logo.svg">

          <span class="my-3">
            <mdb-card-title class="mb-0">
              <h4 class="text-elegant text-center pt-3">
                <strong>Your Internet Explorer or Edge version is no longer supporter</strong>
              </h4>
              <h6 class="text-muted text-center pt-3">
                  Outdated browser ca expose your computer to security risks.
                  To get the best experience using our application, you'll need to upgrade to a nexer browser like <strong class="text-elegant">Chrome</strong> (recommended), Firefox, Safari or Edge (Chromium version)
              </h6>
            </mdb-card-title>
          </span>
        </div>
      </div>
      
    </div>
  </div>
</div>

<app-version></app-version>