/**
 * @module Numbro
 */

import { ModificationClass, ModificationStateClass } from '@class/modification.class';
import { DeepCopy } from '@functions/copy.functions';
import { NumbroModel, NumbroFormatType, NumbroFormatModel } from '@models/numbro.model';
import { BehaviorSubject } from 'rxjs';

export class NumbroClass extends ModificationClass<NumbroModel> {
  
  protected _parent: NumbroModel;
  protected _inital: NumbroModel;
  protected _attributes: string[];

  constructor(numbro: NumbroModel, state: ModificationStateClass, modifications$: BehaviorSubject<ModificationStateClass>) {

    super();

    this._parent = !numbro.format || Object.keys(numbro.format).length === 0 ? { type: numbro.type, format: {} } : numbro;
    this._state = state;
    this._modifications$ = modifications$;

    this._reset(this._parent);
    this._attributes = this.attributes.slice();

  }

  private _reset(numbro: NumbroModel) {
    this.attributes.forEach((attribute: string) => {
      this._parent[attribute] = numbro[attribute];
    });
    if(numbro.format === undefined) numbro.format = {};

    this._inital = DeepCopy(numbro);

    this._state.flush();
    this._modifications$.next(this._state);
  }

  reset() {
    this._state.flush();
    this._reset(this._inital);
  }

  get attributes(): Readonly<string[]> {
    return ['type', 'format'];
  }

  get class(): string {
    return 'numbro';
  }

  get type(): NumbroFormatType {
    return this._parent.type;
  }

  get format(): Readonly<NumbroFormatModel> {
    if(this._parent.format.average === false || this._parent.format.forceAverage === undefined) {
      const format = DeepCopy(this._parent.format);
      delete format.forceAverage;
      return format;
    }
    else return this._parent.format;
  }

}