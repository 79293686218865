/**
 * @module Root
 */

 export const COMPANIES = {
  'dev-swapptechs': {
    auth: 'auth',
    main: 'browser',
    subdomain: 'dev',
    label: 'dev',
    region: 'us-central1',
    avatar: {
      url: 'https://firebasestorage.googleapis.com/v0/b/dev-swapptechs.appspot.com/o/avatar_default%2Favatar.jpg?alt=media&token=9d3ac05b-fde4-42fc-b7fe-b233bef68921',
      path: 'gs://dev-swapptechs.appspot.com/avatar_default/avatar.jpg'
    },
    firebase: {
      apiKey: 'AIzaSyCH_Lu7VZZZoL3jP4Y9UyNQ87qmsU2kXbM',
      authDomain: 'dev-swapptechs.firebaseapp.com',
      databaseURL: 'https://dev-swapptechs.firebaseio.com',
      projectId: 'dev-swapptechs',
      storageBucket: 'dev-swapptechs.appspot.com',
      //messagingSenderId: '851996182788',
      appId: '1:851996182788:web:bbb3f65e874ec2b28fe851',
      measurementId: 'G-2R54KH3HS3'
    }
  },
  'sta-swapptechs': {
    auth: 'auth',
    main: 'browser',
    subdomain: 'sta',
    label: 'sta',
    region: 'europe-west1',
    avatar: {
      url: 'https://firebasestorage.googleapis.com/v0/b/sta-swapptechs.appspot.com/o/avatar_default%2Favatar.jpg?alt=media&token=451874fa-f65e-4b6a-ba62-5ccc35f04a59',
      path: 'gs://sta-swapptechs.appspot.com/avatar_default/avatar.jpg'
    },
    firebase: {
      apiKey: 'AIzaSyAsyat9_aIxCYRic5p-7pXa_kEV7BXcchw',
      authDomain: 'sta-swapptechs.firebaseapp.com',
      databaseURL: 'https://sta-swapptechs.firebaseio.com',
      projectId: 'sta-swapptechs',
      storageBucket: 'sta-swapptechs.appspot.com',
      //messagingSenderId: '333199887222',
      appId: '1:333199887222:web:c22187f8c84a3d46d0c857',
      measurementId: 'G-QN7146YWJS'
    }
  },
  'com-swapptechs': {
    auth: 'auth',
    main: 'browser',
    subdomain: 'com',
    label: 'World Company',
    region: 'europe-west1',
    avatar: {
      url: 'https://firebasestorage.googleapis.com/v0/b/com-swapptechs.appspot.com/o/avatar_default%2Favatar.jpg?alt=media&token=09b9257d-1e16-4681-b784-606f21a63c3d',
      path: 'gs://com-swapptechs.appspot.com/avatar_default/avatar.jpg'
    },
    firebase: {
      apiKey: "AIzaSyA5hUK6qB7cj1f9OMZQLiur_Z-ytXIBsSw",
      authDomain: "com-swapptechs.firebaseapp.com",
      databaseURL: "https://com-swapptechs.firebaseio.com",
      projectId: "com-swapptechs",
      storageBucket: "com-swapptechs.appspot.com",
      //messagingSenderId: "435587532823",
      appId: "1:435587532823:web:947bb38db062d8fc03f452",
      measurementId: "G-D6LGKWJJQB"
    }
  },
  'azmaroc-swapptechs': {
    auth: 'auth',
    main: 'browser',
    subdomain: 'azmaroc',
    label: 'Allianz Maroc',
    region: 'europe-west1',
    avatar: {
      url: 'https://firebasestorage.googleapis.com/v0/b/azmaroc-swapptechs.appspot.com/o/avatar_default%2Favatar.jpg?alt=media&token=04aa5a55-f44e-4dfc-87bf-a1620f7232de',
      path: 'gs://azmaroc-swapptechs.appspot.com/avatar_default/avatar.jpg'
    },
    firebase: {
      apiKey: "AIzaSyA5ut1b-S4QHvbjgUnR1_sSGfhUlQEIqPs",
      authDomain: "azmaroc-swapptechs.firebaseapp.com",
      databaseURL: "https://azmaroc-swapptechs.firebaseio.com",
      projectId: "azmaroc-swapptechs",
      storageBucket: "azmaroc-swapptechs.appspot.com",
      //messagingSenderId: "535881150298",
      appId: "1:535881150298:web:9e9822fab39417c5c5cd63",
      measurementId: "G-M2XG7T7G26"
    }
  },
  'azfrance-swapptechs': {
    auth: 'auth',
    main: 'browser',
    subdomain: 'azfrance',
    label: 'Allianz France',
    region: 'europe-west1',
    avatar: {
      url: 'https://firebasestorage.googleapis.com/v0/b/azfrance-swapptechs.appspot.com/o/avatar_default%2Favatar.jpg?alt=media&token=a370603e-4c3f-48bf-83f2-fd6d1b30c4b9',
      path: 'gs://azfrance-swapptechs.appspot.com/avatar_default/avatar.jpg'
    },
    firebase: {
      apiKey: "AIzaSyDbG5HJwGkhuGaaYFkQZ3CNT-DW2QRqgUU",
      authDomain: "azfrance-swapptechs.firebaseapp.com",
      databaseURL: "https://azfrance-swapptechs.firebaseio.com",
      projectId: "azfrance-swapptechs",
      storageBucket: "azfrance-swapptechs.appspot.com",
      //messagingSenderId: "268938892628",
      appId: "1:268938892628:web:c3a9a36efea3fc7b2ea4c1",
      measurementId: "G-3F0N3YT4TE"
    }
  },
  'azparts-swapptechs': {
    auth: 'auth',
    main: 'browser',
    subdomain: 'azparts',
    label: 'Allianz Partners',
    region: 'europe-west1',
    avatar: {
      url: 'https://firebasestorage.googleapis.com/v0/b/azparts-swapptechs.appspot.com/o/avatar_default%2Favatar.jpeg?alt=media&token=92efcbe3-4429-4efc-a262-736225f45306',
      path: 'gs://azparts-swapptechs.appspot.com/avatar_default/avatar.jpeg'
    },
    firebase: {
      apiKey: "AIzaSyBupfZZs7H_RbjXb1iniRRvnKeCF4GQOXs",
      authDomain: "azparts-swapptechs.firebaseapp.com",
      databaseURL: "https://azparts-swapptechs-default-rtdb.firebaseio.com",
      projectId: "azparts-swapptechs",
      storageBucket: "azparts-swapptechs.appspot.com",
      //messagingSenderId: "1090225717431",
      appId: "1:1090225717431:web:09becfef2da4a5ac118d32",
      measurementId: "G-Y1MFHBD3DT"
    }
  },
  'ixi-swapptechs': {
    auth: 'auth',
    main: 'browser',
    subdomain: 'ixi',
    label: 'ixi',
    region: 'europe-west1',
    avatar: {
      url: 'https://firebasestorage.googleapis.com/v0/b/ixi-swapptechs.appspot.com/o/avatar_default%2Favatar.jpeg?alt=media&token=c52bc279-7723-4ff5-961c-5d213c868f94',
      path: 'gs://ixi-swapptechs.appspot.com/avatar_default/avatar.jpeg'
    },
    firebase: {
      apiKey: "AIzaSyBi3FkoLmQL8yx6EiAmdJhbpFTk-gpwWQk",
      authDomain: "ixi-swapptechs.firebaseapp.com",
      databaseURL: "https://ixi-swapptechs-default-rtdb.firebaseio.com",
      projectId: "ixi-swapptechs",
      storageBucket: "ixi-swapptechs.appspot.com",
      //messagingSenderId: "925149381807",
      appId: "1:925149381807:web:e60e20d701477c19bdc05a",
      measurementId: "G-16E3C5YSPB"
    }
  },
  'adc-swapptechs': {
    auth: 'auth',
    main: 'browser',
    subdomain: 'adc',
    label: 'adc',
    region: 'europe-west1',
    avatar: {
      url: 'https://firebasestorage.googleapis.com/v0/b/adc-swapptechs.appspot.com/o/avatar_default%2Favatar.jpeg?alt=media&token=550d9383-f442-4358-ac8f-81d386fdebe7',
      path: 'gs://adc-swapptechs.appspot.com/avatar_default/avatar.jpeg'
    },
    firebase: {
      apiKey: "AIzaSyBkETo2v4NaahxLkQBZvOS_oJG4Ma0Vq2A",
      authDomain: "adc-swapptechs.firebaseapp.com",
      databaseURL: "https://adc-swapptechs-default-rtdb.firebaseio.com",
      projectId: "adc-swapptechs",
      storageBucket: "adc-swapptechs.appspot.com",
      //messagingSenderId: "464240137084",
      appId: "1:464240137084:web:fb643926ed91feb69c0af9",
      measurementId: "G-LV9MW4HPEH"
    }
  }
};

