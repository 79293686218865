/**
 * @module Filters
 */

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FiltersPipeModel } from '@pipes/filters/filters.pipe';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.css']
})
export class FiltersComponent {

  @Input() label: string;
  @Input() key: string;
  @Input() values: any[];
  @Output() sendFilters: EventEmitter<{ [key: string]: FiltersPipeModel }>;

  filters: { [key: string]: FiltersPipeModel };
  count: number;

  constructor() {
    this.count = 0;
    this.filters = {};
    this.sendFilters = new EventEmitter<{ [key: string]: FiltersPipeModel }>();
  }

  onFlush() {
    this.count = 0;
    this.filters = {};
  }

}
