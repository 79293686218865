/**
 * @module Shared
 */
import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-confirm-warning-strip',
  templateUrl: './confirm-warning-strip.component.html',
  styleUrls: ['./confirm-warning-strip.component.css']
})
export class ConfirmWarningStripComponent  {

  @Output() sendResponse: EventEmitter<boolean>;
  @Input() confirmLabel: string = 'CONFIRM';

  constructor() {
    this.sendResponse = new EventEmitter<boolean>();
  }

  onConfim() {
    this.sendResponse.emit(true);
  }

  onCancel() {
    this.sendResponse.emit(false);
  }

}
