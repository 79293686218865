/**
 * @module User
 */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CompressImageService {

  /**
   * @description Creates an instance of CompressImageService
   * @memberof CompressImageService
   */
  constructor() {}

  /**
   * @description Réduit et recentre une image pour tenir dans un carré de 104 pixel de coté
   * @param {File} file
   * @returns {Observable<File>}
   * @memberof CompressImageService
   */
  compress(file: File): Observable<File> {
    //source adaptée depuis : https://zocada.com/compress-resize-images-javascript-browser/
    const size = 104;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return Observable.create(observer => {
      reader.onload = ev => {
        const img = new Image();
        img.src = (ev.target as any).result;
        (img.onload = () => {
          const isWithBigger = img.width > img.height;
          const elem = document.createElement('canvas');
          const scaleFactor = size / (isWithBigger ? img.height : img.width);
          const move = ((isWithBigger ? img.width : img.height) - size / scaleFactor) / 2;
          elem.width = size;
          elem.height = size;
          const ctx = <CanvasRenderingContext2D>elem.getContext('2d');
          ctx.drawImage(img, isWithBigger ? move : 0, isWithBigger ? 0 : move, size / scaleFactor, size / scaleFactor, 0, 0, size, size);
          ctx.canvas.toBlob(
            blob => {
              observer.next(
                new File([blob], file.name, {
                  type: 'image/jpeg',
                  lastModified: Date.now(),
                }),
              );
            },
            'image/jpeg',
            .95,
          );
        }),
        (reader.onerror = error => observer.error(error));
      };
    });
  }

}
