<app-helper #helper [title]="'FORMAT'">

  <div app-helper-content>
    <table class="table table-striped table-bordered font-small blue-grey-text">
      <tbody>
        <tr>
          <th>Code</th>
          <th>Description</th>
        </tr>
        <tr>
          <td><b>[br]</b></td>
          <td>break line</td>
        </tr>
        <tr>
          <td><b>[b]text[/b]</b></td>
          <td>bold</td>
        </tr>
        <tr>
          <td><b>[i]text[/i]</b></td>
          <td>italic</td>
        </tr>
        <tr>
          <td><b>[u]text[/u]</b></td>
          <td>underline</td>
        </tr>
        <tr>
          <td><b>[*]text[/*]</b></td>
          <td>bullet point</td>
        </tr>
      </tbody>
    </table>

  </div>
  
</app-helper>