/**
 * @module Server
 */

import { retryWhen, take, delay, concat } from 'rxjs/operators';
import { throwError } from 'rxjs';

/**
 * @description gère les erreurs au sein d'un pipe pour un appel serveur (par défaut 3 tentatives espacées de 2000 millisecondes)
 * @export
 * @param {number} [retry=3]
 * @param {number} [interval=2000]
 * @returns
 */
export function HandleErrors(retry: number = 3, interval: number = 2000) {
  return retryWhen<any>(errors => {
    return errors.pipe(
      delay(interval),
      take(retry),
      concat(throwError('UNABLE_CONNECT_DATA_SERVER/TEXT'))
    );
  });
}