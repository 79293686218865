/**
 * @module Import
 */

import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ImportFrameClass } from '@class/importFrame.class';
import { UID } from '@models/uid.model';
import { ItemIcon, ItemColor, ItemsColors, ItemsIcons } from '@functions/item.functions';
import { FiltersPipeModel } from '@pipes/filters/filters.pipe';
import { BehaviorSubject, Subscription, Subject } from 'rxjs';
import { ImportFrameUploadModel } from '@models/importFrame.model';
import { LanguageType } from '@models/language.model';
import { MessagesService } from '@services/messages/messages.service';
import { AuthService } from '@services/auth/auth.service';
import { ImportService } from '@services/import/import.service';
import { DataFrameValueModel } from '@models/dataFrame.model';
import { DeepCopy, DeepMerge } from '@functions/copy.functions';

@Component({
  selector: 'app-import-values',
  templateUrl: './import-values.component.html',
  styleUrls: ['./import-values.component.css']
})
export class ImportValuesComponent implements OnDestroy, OnInit, OnChanges {

  @Input() filters$: BehaviorSubject<{ [key: string]: { [key: string]: FiltersPipeModel } }>;
  @Input() importFrame: ImportFrameClass;
  @Output() sendUploadComplete: EventEmitter<UID>;

  icon = ItemIcon('indicators');
  color = ItemColor('indicators');
  colors = ItemsColors(true);
  icons = ItemsIcons();

  limit = 10;

  isSelection: boolean;
  isOpened: boolean;
  isShowErrors: boolean;
  forceClose$: Subject<boolean>;
  forceSelected$: Subject<boolean>;
  isAllSelected: boolean;
  uploading: boolean;
  upload: ImportFrameUploadModel;
  page: number;
  language: LanguageType;
  language$sub: Subscription;
  uploadStream$sub: Subscription;
  filters: { [key: string]: FiltersPipeModel };
  filters$sub: Subscription;
  filtersCount: {[key: string]: number };

  selection: { [key in UID]: boolean };
  opened: { [key in UID]: boolean };
  
  constructor(
    private $messages: MessagesService,
    private $auth: AuthService,
    private $import: ImportService
  ) {
    this.isOpened = false;
    this.isShowErrors = false;
    this.isSelection = false;
    this.isAllSelected = false;
    this.selection = {};
    this.opened = {};
    this.page = 0;
    this.filtersCount = {};

    this.sendUploadComplete = new EventEmitter<UID>();

    this.forceClose$ = new Subject<boolean>();
    this.forceSelected$ = new Subject<boolean>();

    this.language$sub = this.$auth.language$.subscribe({
      next: (language: LanguageType) => {
        this.language = language;
      }
    });
  }

  ngOnInit() {
    this.setSubs();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.importFrame !== undefined && !changes.importFrame.firstChange) {
      this.setSubs();
    }
  }

  setSubs() {
    if(this.filters$sub) this.filters$sub.unsubscribe();
    this.filters$sub = this.filters$.subscribe({
      next: (filters: { [key: string]: { [key: string]: FiltersPipeModel } }) => {
        this.filters = {};
        for(const key in filters) {
          Object.assign(this.filters, DeepCopy(filters[key]));
          this.filtersCount[key] = Object.keys(filters[key]).length;
        }
        if(!this.importFrame.dataFrame.isErrors && this.filters['$$errors'] !== undefined) {
          this.filters$.next({});
        }
      }
    });

    if(this.uploadStream$sub) this.uploadStream$sub.unsubscribe();
    this.uploadStream$sub = this.importFrame.uploadStream$.subscribe({
      next:(upload: ImportFrameUploadModel) => {
        this.upload = upload;
        if(this.upload.isEnded && this.uploading) {
          this.uploading = false;
          if(this.upload.isErrors) {
            this.$messages.warning({ title: { plain: this.importFrame.indicator.labels.value(this.language) }, text: { code: 'DATA_UPLOAD_FAILED', params: { rows: this.upload.lines.errors.toString() } } });
          }
          if(this.upload.lines.uploaded > 0) {
            this.$messages.success({ title: { plain: this.importFrame.indicator.labels.value(this.language) }, text: { code: 'DATA_UPLOADED', params: { rows: this.upload.lines.uploaded.toString() } } });
            this.sendUploadComplete.emit(this.importFrame.uid);
          }
        }
      }
    });
  }

  receiveRetry() {
    this.uploading = true;
    this.$import.upload(this.importFrame, true);
  }

  ngOnDestroy() {
    this.filters$sub.unsubscribe();
    this.uploadStream$sub.unsubscribe();
    this.language$sub.unsubscribe();
  }

  onRestore() {
    this.opened = {};
    this.forceClose$.next(false);
    this.isOpened = false;
  }

  onPut() {
    this.uploading = true;
    this.$import.upload(this.importFrame);
  }

  onSave() {
    this.opened = {};
    this.forceClose$.next(true);
    this.isOpened = false;
  }

  trackByUid(index: number, row: DataFrameValueModel) {
    return row.$$uid;
  }

  changeValue(row: any[], key: string, event: any) {
    row[key] = event.target.textContent;
    this.importFrame.dataFrame.values = [row];
  }

  onLimit(limit: number) {
    this.limit = limit;
  }

  onPageChanged(page: number) {
    this.page = page;
  }

  onSelectAll() {
    this.isAllSelected = !this.isAllSelected;
    this.forceSelected$.next(this.isAllSelected);
  }

  receiveSelected(item: { uid: UID, selected: boolean }) {
    this.selection[item.uid] = item.selected;
    this.isSelection = Object.values(this.selection).filter((selected: boolean) => { return selected; }).length > 0;
  }

  onRemove() {
    this.importFrame.dataFrame.removeValues(Object.keys(this.selection).filter((key: UID, index: number) => { return this.selection[key] === true; }));
    for(const uid in this.selection) {
      delete this.opened[uid];
    }
    this.isOpened = Object.keys(this.opened).length > 0;
    this.selection = {};
    this.isSelection = false;
    if(!this.importFrame.dataFrame.isErrors) this.filters$.next(DeepMerge(this.filters$.value, { $$errors: {} }));
  }

  receiveEdited(row: DataFrameValueModel, key: string) {
    delete this.opened[`${row.$$uid}_${key}`];
    this.isOpened = Object.keys(this.opened).length > 0;
    this.importFrame.dataFrame.values = [row];
    if(!this.importFrame.dataFrame.isErrors) this.filters$.next(DeepMerge(this.filters$.value, { $$errors: {} }));
  }

  receiveOpened(row: DataFrameValueModel, key: string) {
    this.opened[`${row.$$uid}_${key}`] = true;
    this.isOpened = true;
  }

  receiveClosed(row: DataFrameValueModel, key: string) {
    delete this.opened[`${row.$$uid}_${key}`];
    this.isOpened = Object.keys(this.opened).length > 0;
  }

  onFilterErrors() {
    if(this.filters['$$errors'] !== undefined && Object.keys(this.filters['$$errors']).length > 0) {
      this.isShowErrors = false;
      delete this.filters$.value['$$errors'];
      this.filters$.next(this.filters$.value);
    }
    else {
      this.isShowErrors = true;
      this.filters$.next(
        DeepMerge(this.filters$.value, { 
        $$errors: { 
          $$errors: {
            field: '$$errors',
            type: 'object',
            requirements: {
              length: {
                comparaison: '>',
                reference: 0
              }
            }
          }
        }
      }));
    }
  }
}