/**
 * @module Theme
 */

import { Component, Input } from '@angular/core';
import { ChartType } from '@models/chart.model';
import { ChartsIcons } from '@functions/chart.functions';
import { ItemColor } from '@functions/item.functions';

@Component({
  selector: 'app-theme-option',
  templateUrl: './theme-option.component.html',
  styleUrls: ['./theme-option.component.css']
})
export class ThemeOptionComponent {

  @Input() label: string;
  @Input() sublabel: string;
  @Input() categories: string[];
  @Input() information: string;
  @Input() subinformation: string;
  @Input() dark: number = 5;
  @Input() limitedTo: ChartType[] = [];

  icons = ChartsIcons();
  color = ItemColor('themes');

  constructor() {}

}
