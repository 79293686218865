<div class="mt-3">

  <app-rights-switch-full class="d-block mb-1" (sendFull)="receiveFull($event)" [full]="false"></app-rights-switch-full>

  <app-links-item *ngFor="let right of rights" [color]="color" [icon]="icon" [labels]="right.labels" [uid]="right.uid">
    <div app-links-item-content-description>

    </div>
    <div app-links-item-content-option>
      <app-links-item-option class="d-block ml-1" [uid]="right.uid.value" [symbol]="'check'" [selected]="isSelected(right.uid.value)" (sendSelected)="receiveSelected($event)"></app-links-item-option>
    </div>
  </app-links-item>

</div>