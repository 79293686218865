/**
 * @module Dashboard
 */

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DashboardLayoutCellClass } from '@class/dashboardLayoutCell.class';
import { UID } from '@models/uid.model';

@Component({
  selector: 'app-dashboard-cell-editor',
  templateUrl: './dashboard-cell-editor.component.html',
  styleUrls: ['./dashboard-cell-editor.component.css']
})
export class DashboardCellEditorComponent {

  @Input() cell: DashboardLayoutCellClass;
  @Output() sendRemove: EventEmitter<UID>;

  constructor() {
    this.sendRemove = new EventEmitter<UID>();
  }

  receiveRemove(uid: UID) {
    this.sendRemove.emit(uid);
  }

}
