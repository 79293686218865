<app-studio #studio [type]="'themes'" [itemLabel]="'CHARTS'">

  <!-- BLOC MAIN -->
  <div app-studio-main-content *ngIf="theme">
    <div class="d-flex">
      <button [disabled]="saved || saving" mdbBtn type="button" [color]="color" class="z-depth-0 flex-grow-1 m-0" mdbWavesEffect (click)="onSave()">
        <i *ngIf="!saving" class="far fa-check"></i>
        <i *ngIf="saving" class="far fa-circle-notch fa-spin"></i>
        <span class="ml-1">{{ 'SAVE' | translate }}</span>
      </button>
    </div>

    <hr>

    <app-item-strip
      [labels]="theme.labels"
      [tags]="theme.tags"
      [type]="'themes'"
      [uid]="theme.uid"
      [icon]="icon"
      [color]="color"
      [isModified]="theme.modifications$.value.isModified"
      (sendEdit)="receiveEdit($event)"
    ></app-item-strip>
    
    <app-theme-studio-main *ngIf="themeOptions && current" [themeOptions]="themeOptions" [theme]="theme" [current]="current" (sendCurrent)="receiveCurrent($event)"></app-theme-studio-main>

  </div>
  <!-- ./BLOC MAIN -->

  <!-- BLOC ITEM -->
  <div app-studio-item-content>
    <div *ngIf="themeOptions" class="white row no-gutters pb-3 d-flex justify-content-center">
      <div></div>
      <div class="app-theme-studio-chart"><app-sticker-chart [fullyColored]="false" [orientation]="'row'" [template]="1" class="d-block border border-light rounded mr-3 mt-3" [options]="themeOptions"></app-sticker-chart></div>
      <div class="app-theme-studio-chart"><app-sticker-chart [fullyColored]="true" [orientation]="'row'" [template]="1" class="d-block border border-light rounded mr-3 mt-3" [options]="themeOptions"></app-sticker-chart></div>
      <div class="app-theme-studio-chart"><app-sticker-chart [fullyColored]="false" [orientation]="'row'" [template]="2" class="d-block border border-light rounded mr-3 mt-3" [options]="themeOptions"></app-sticker-chart></div>
      <div class="app-theme-studio-chart"><app-sticker-chart [fullyColored]="true" [orientation]="'row'" [template]="2" class="d-block border border-light rounded mr-3 mt-3" [options]="themeOptions"></app-sticker-chart></div>
      <div class="app-theme-studio-chart"><app-gauge-chart style="height: calc(100% - 16px);" [id]="1" [donut]="false" class="d-block border border-light rounded mr-3 mt-3" [options]="themeOptions"></app-gauge-chart></div>
      <div class="app-theme-studio-chart"><app-gauge-chart [id]="2" [donut]="true" class="d-block border border-light rounded mr-3 mt-3" [options]="themeOptions"></app-gauge-chart></div>
      <div class="app-theme-studio-chart"><app-line-chart class="d-block border border-light rounded mr-3 mt-3" [options]="themeOptions"></app-line-chart></div>
      <div class="app-theme-studio-chart"><app-stacked-line-chart class="d-block border border-light rounded mr-3 mt-3" [options]="themeOptions"></app-stacked-line-chart></div>
      <div class="app-theme-studio-chart"><app-horizontal-bar-chart class="d-block border border-light rounded mr-3 mt-3" [options]="themeOptions"></app-horizontal-bar-chart></div>
      <div class="app-theme-studio-chart"><app-stacked-horizontal-bar-chart class="d-block border border-light rounded mr-3 mt-3" [options]="themeOptions"></app-stacked-horizontal-bar-chart></div>
      <div class="app-theme-studio-chart"><app-bar-chart class="d-block border border-light rounded mr-3 mt-3" [options]="themeOptions"></app-bar-chart></div>
      <div class="app-theme-studio-chart"><app-stacked-bar-chart class="d-block border border-light rounded mr-3 mt-3" [options]="themeOptions"></app-stacked-bar-chart></div>
      <div class="app-theme-studio-chart"><app-pie-chart class="d-block border border-light rounded mr-3 mt-3" [options]="themeOptions"></app-pie-chart></div>
      <div class="app-theme-studio-chart"><app-doughnut-chart class="d-block border border-light rounded mr-3 mt-3" [options]="themeOptions"></app-doughnut-chart></div>
      <div class="app-theme-studio-chart"><app-radar-chart class="d-block border border-light rounded mr-3 mt-3" [options]="themeOptions"></app-radar-chart></div>
      <div class="app-theme-studio-chart"><app-polar-area-chart class="d-block border border-light rounded mr-3 mt-3" [options]="themeOptions"></app-polar-area-chart></div>
      <!--div class="app-theme-studio-chart"><app-bubble-chart class="d-block border border-light rounded mr-3 mt-3" [options]="themeOptions"></app-bubble-chart></div>
      <div class="app-theme-studio-chart"><app-scatter-chart class="d-block border border-light rounded mr-3 mt-3" [options]="themeOptions"></app-scatter-chart></div-->
    </div>
  </div>
  <!-- ./BLOC ITEM -->

  <!-- BLOC SUB -->
  <div app-studio-sub-content>
    <app-theme-options *ngIf="themeOptions && current.index != undefined" [themeOptions]="themeOptions" [current]="current"></app-theme-options>
  </div>
  <!-- ./BLOC SUB -->

</app-studio>

<app-theme-editor #editor [current]="theme" (sendDelete)="receiveDelete()"></app-theme-editor>