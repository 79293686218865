/**
 * @module Widget
 */

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { WidgetIndicatorClass } from '@class/widgetIndicator.class';
import { ItemIcon, ItemColor } from '@functions/item.functions';

@Component({
  selector: 'app-widget-indicators-item',
  templateUrl: './widget-indicators-item.component.html',
  styleUrls: ['./widget-indicators-item.component.css']
})
export class WidgetIndicatorsItemComponent {

  @Input() indicator: WidgetIndicatorClass;
  @Input() index: number;
  @Input() selected: boolean;
  @Input() isLast: boolean;
  @Output() sendEdited: EventEmitter<number>;

  icon = ItemIcon('indicators');
  color = ItemColor('indicators');

  constructor() {
    this.sendEdited = new EventEmitter<number>();
  }

  onEdit() {
    this.sendEdited.emit(this.index);
  }

  receiveDisplay() {
    this.indicator.change({ display: !this.indicator.display });
  }

  receiveInTable() {
    this.indicator.change({ inTable: !this.indicator.inTable });
  }

}
