<div class="d-flex align-items-center justify-content-between mt-3" style="height: 31px;">
  <div class="blue-grey-text font-weight-bold d-flex align-items-center">
    <i class="fa-lg fa-fw far fa-pencil-paintbrush {{color}}-text mr-1"></i>
    <div class="mx-1">{{ 'TOOLTIPS' | translate }}</div>
  </div>
  <div class="btn-group" mdbDropdown>
    <button mdbDropdownToggle type="button" size="sm" mdbBtn color="light" class="blue-grey-text z-depth-0 dropdown-toggle waves-light d-flex align-items-center px-3" mdbWavesEffect>
      <i class="far fa-bars"></i>&nbsp;
    </button>
  
    <div class="dropdown-menu dropdown-dark dropdown-menu-right">

      <a class="dropdown-item z-depth-0" (click)="editorIndex = 0">{{ 'TO_DISPLAY' | translate }}</a>
      <a class="dropdown-item z-depth-0" (click)="editorIndex = 1">{{ 'LAYOUT' | translate }}</a>
      <a class="dropdown-item z-depth-0" (click)="editorIndex = 2">{{ 'BORDER' | translate }}</a>
      <a class="dropdown-item z-depth-0" (click)="editorIndex = 3">{{ 'INTERSECT' | translate }}</a>
      <a class="dropdown-item z-depth-0" (click)="editorIndex = 4">{{ 'TITLE_TO_DISPLAY' | translate }}</a>
      <a class="dropdown-item z-depth-0" (click)="editorIndex = 5">{{ 'TITLE_FONT' | translate }}</a>
      <a class="dropdown-item z-depth-0" (click)="editorIndex = 6">{{ 'BODY_TO_DISPLAY' | translate }}</a>
      <a class="dropdown-item z-depth-0" (click)="editorIndex = 7">{{ 'BODY_FONT' | translate }}</a>

    </div>
  </div>
</div>

<hr>

<div [ngSwitch]="editorIndex">
  <div *ngSwitchCase="0">
    <app-editor-section-title [title]="'SETTINGS'" (sendNext)="receiveNext($event)"></app-editor-section-title>

    <app-theme-option-tooltips-display [themeOptions]="themeOptions"></app-theme-option-tooltips-display>
    <app-theme-option-tooltips-display-colors [themeOptions]="themeOptions"></app-theme-option-tooltips-display-colors>
    <app-theme-option-tooltips-position [themeOptions]="themeOptions"></app-theme-option-tooltips-position>

  </div>
  <div *ngSwitchCase="1">
    <app-editor-section-title [title]="'LAYOUT'" (sendNext)="receiveNext($event)"></app-editor-section-title>

    <app-theme-option-tooltips-background-color [themeOptions]="themeOptions"></app-theme-option-tooltips-background-color>
    <app-theme-option-tooltips-xpadding [themeOptions]="themeOptions"></app-theme-option-tooltips-xpadding>
    <app-theme-option-tooltips-ypadding [themeOptions]="themeOptions"></app-theme-option-tooltips-ypadding>

  </div>
  <div *ngSwitchCase="2">
    <app-editor-section-title [title]="'BORDER'" (sendNext)="receiveNext($event)"></app-editor-section-title>

    <app-theme-option-tooltips-border-color [themeOptions]="themeOptions"></app-theme-option-tooltips-border-color>
    <app-theme-option-tooltips-border-width [themeOptions]="themeOptions"></app-theme-option-tooltips-border-width>
    <app-theme-option-tooltips-corner-radius [themeOptions]="themeOptions"></app-theme-option-tooltips-corner-radius>
    <app-theme-option-tooltips-caret-padding [themeOptions]="themeOptions"></app-theme-option-tooltips-caret-padding>
    <app-theme-option-tooltips-caret-size [themeOptions]="themeOptions"></app-theme-option-tooltips-caret-size>

  </div>
  <div *ngSwitchCase="3">
    <app-editor-section-title [title]="'INTERSECT'" (sendNext)="receiveNext($event)"></app-editor-section-title>

    <app-theme-option-tooltips-mode [themeOptions]="themeOptions"></app-theme-option-tooltips-mode>
    <app-theme-option-tooltips-intersect [themeOptions]="themeOptions"></app-theme-option-tooltips-intersect>
    <app-theme-option-tooltips-multi-key-background [themeOptions]="themeOptions"></app-theme-option-tooltips-multi-key-background>
    
  </div>
  <div *ngSwitchCase="4">
    <app-editor-section-title [title]="'TITLE_TO_DISPLAY'" (sendNext)="receiveNext($event)"></app-editor-section-title>

    <app-theme-option-tooltips-title-align [themeOptions]="themeOptions"></app-theme-option-tooltips-title-align>
    <app-theme-option-tooltips-title-spacing [themeOptions]="themeOptions"></app-theme-option-tooltips-title-spacing>
    <app-theme-option-tooltips-title-margin-bottom [themeOptions]="themeOptions"></app-theme-option-tooltips-title-margin-bottom>

  </div>
  <div *ngSwitchCase="5">
    <app-editor-section-title [title]="'TITLE_FONT'" (sendNext)="receiveNext($event)"></app-editor-section-title>

    <app-theme-option-tooltips-title-font-family [themeOptions]="themeOptions"></app-theme-option-tooltips-title-font-family>
    <app-theme-option-tooltips-title-font-size [themeOptions]="themeOptions"></app-theme-option-tooltips-title-font-size>
    <app-theme-option-tooltips-title-font-style [themeOptions]="themeOptions"></app-theme-option-tooltips-title-font-style>
    <app-theme-option-tooltips-title-font-color [themeOptions]="themeOptions"></app-theme-option-tooltips-title-font-color>

  </div>
  <div *ngSwitchCase="6">
    <app-editor-section-title [title]="'BODY_TO_DISPLAY'" (sendNext)="receiveNext($event)"></app-editor-section-title>

    <app-theme-option-tooltips-body-align [themeOptions]="themeOptions"></app-theme-option-tooltips-body-align>
    <app-theme-option-tooltips-body-spacing [themeOptions]="themeOptions"></app-theme-option-tooltips-body-spacing>

  </div>
  <div *ngSwitchCase="7">
    <app-editor-section-title [title]="'BODY_FONT'" (sendNext)="receiveNext($event)"></app-editor-section-title>

    <app-theme-option-tooltips-body-font-family [themeOptions]="themeOptions"></app-theme-option-tooltips-body-font-family>
    <app-theme-option-tooltips-body-font-size [themeOptions]="themeOptions"></app-theme-option-tooltips-body-font-size>
    <app-theme-option-tooltips-body-font-style [themeOptions]="themeOptions"></app-theme-option-tooltips-body-font-style>
    <app-theme-option-tooltips-body-font-color [themeOptions]="themeOptions"></app-theme-option-tooltips-body-font-color>
    
  </div>
</div>