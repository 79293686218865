/**
 * @module Indicator
 */

import { Component, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { ItemColor, ItemIcon } from '@functions/item.functions';
import { IndicatorClass } from '@class/indicator.class';
import { IndicatorService } from '@services/indicator/indicator.service';
import { Subscription } from 'rxjs';
import { UID } from '@models/uid.model';

@Component({
  selector: 'app-indicators-links-change',
  templateUrl: './indicators-links-change.component.html',
  styleUrls: ['./indicators-links-change.component.css']
})
export class IndicatorsLinksChangeComponent implements OnDestroy {

  @Input() indicator: IndicatorClass;
  @Output() sendSelected: EventEmitter<IndicatorClass>;

  color = ItemColor('indicators');
  icon = ItemIcon('indicators');

  indicators$sub: Subscription;
  list: IndicatorClass[];

  constructor(
    private $indicators: IndicatorService
  ) {
    this.sendSelected = new EventEmitter<IndicatorClass>();
    this.indicators$sub = this.$indicators.listPartials$().subscribe({
      next: (indicators: IndicatorClass[]) => {
        this.list = indicators;
      }
    });
  }

  ngOnDestroy() {
    this.indicators$sub.unsubscribe();
  }

  receiveSelected(item: { uid: UID, selected: boolean }) {
    if(item.uid !== this.indicator.uid.value)  {
      const indicator = this.list.filter((indicator: IndicatorClass) => { return indicator.uid.value === item.uid; })[0];
      this.sendSelected.emit(indicator);
    }
    else if(!item.selected) {
      this.sendSelected.emit(this.$indicators.create());
    }
  }

}
