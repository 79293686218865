/**
 * @module Theme
 */

import { ChartType } from '@models/chart.model';
import { ChartsTypes } from '@functions/chart.functions';
import { ThemeOptionsModel, ThemeOptionsConfigurationType } from '@models/themeOptions.model';
import { ChartOptions, ChartDataSets, ChartXAxe, ChartYAxe } from 'chart.js';
import { Options } from 'chartjs-plugin-datalabels/types/options';
import { DeepCopy, DeepMerge } from './copy.functions';


export function ThemeOptionsEmpty(): ThemeOptionsModel {
  return { 
    options: {
      common: {}, 
      specific: {} as unknown as { [key in ChartType]: ChartOptions }
    },
    series: {
      common: [],
      specific: {} as { [key in ChartType]: ChartDataSets[] }
    },
    datalabels: {
      common: [],
      specific: {} as { [key in ChartType]: Options[] }
    },
    xAxes: {
      common: [] as ChartXAxe[],
      specific: {} as { [key in ChartType]: ChartXAxe[] }
    },
    yAxes: {
      common: [] as ChartYAxe[],
      specific: {} as { [key in ChartType]: ChartYAxe[] }
    }
  };
}

export function ThemeOptionsConfigurationMainCategories(): string[] {
  return ['ANIMATION', 'LAYOUT', 'LEGEND', 'TITLE', 'TOOLTIPS', 'SPECIFIC', 'POINT', 'SERIE', 'LABELS', 'AXES'];
}

export function ThemeOptionsConfiguration(): ThemeOptionsConfigurationType {
  return {
    'animation_easing': { limitedTo: ChartsTypes(), path: ['animation', 'easing'], type: 'options', categories: ['ANIMATION'], label: 'EASING', categoriesAndLabel: ['ANIMATION', 'EASING'] },
    'animation_duration': { limitedTo: ChartsTypes(), path: ['animation', 'duration'], type: 'options', categories: ['ANIMATION'], label: 'DURATION', categoriesAndLabel: ['ANIMATION', 'DURATION'] },
    'animation_animateRotate': { limitedTo: ['pie', 'doughnut', 'radar'], path: ['animation', 'animateRotate'], type: 'options', categories: ['ANIMATION'], label: 'ANIMATE_ROTATE', categoriesAndLabel: ['ANIMATION', 'ANIMATE_ROTATE'] },
    'animation_animateScale': { limitedTo: ['pie', 'doughnut', 'radar'], path: ['animation', 'animateScale'], type: 'options', categories: ['ANIMATION'], label: 'ANIMATE_SCALE', categoriesAndLabel: ['ANIMATION', 'ANIMATE_SCALE'] },
    'layout_padding': { limitedTo: ChartsTypes(), path: ['layout', 'padding'], type: 'options', categories: ['LAYOUT'], label: 'PADDING', categoriesAndLabel: ['LAYOUT', 'PADDING'] },
    'layout_padding_top': { limitedTo: ChartsTypes(), path: ['layout', 'padding', 'top'], type: 'options', categories: ['LAYOUT', 'PADDING'], label: 'TOP', categoriesAndLabel: ['LAYOUT', 'PADDING', 'TOP'], subOption: true },
    'layout_padding_left': { limitedTo: ChartsTypes(), path: ['layout', 'padding', 'left'], type: 'options', categories: ['LAYOUT', 'PADDING'], label: 'LEFT', categoriesAndLabel: ['LAYOUT', 'PADDING', 'LEFT'], subOption: true },
    'layout_padding_right': { limitedTo: ChartsTypes(), path: ['layout', 'padding', 'right'], type: 'options', categories: ['LAYOUT', 'PADDING'], label: 'RIGHT', categoriesAndLabel: ['LAYOUT', 'PADDING', 'RIGHT'], subOption: true },
    'layout_padding_bottom': { limitedTo: ChartsTypes(), path: ['layout', 'padding', 'bottom'], type: 'options', categories: ['LAYOUT', 'PADDING'], label: 'BOTTOM', categoriesAndLabel: ['LAYOUT', 'PADDING', 'BOTTOM'], subOption: true },
    'layout_backgroundColor': { limitedTo: ChartsTypes(), path: ['layout', 'backgroundColor'], type: 'options', categories: ['LAYOUT'], label: 'BACKGROUND_COLOR', categoriesAndLabel: ['LAYOUT', 'BACKGROUND_COLOR'] },
    'legend_display': { limitedTo: ChartsTypes(), path: ['legend', 'display'], type: 'options', categories: ['LEGEND'], label: 'TO_DISPLAY', categoriesAndLabel: ['LEGEND', 'TO_DISPLAY'] },
    'legend_position': { limitedTo: ChartsTypes(), path: ['legend', 'position'], type: 'options', categories: ['LEGEND'], label: 'POSITION', categoriesAndLabel: ['LEGEND', 'POSITION'] },
    'legend_fullWidth': { limitedTo: ChartsTypes(), path: ['legend', 'fullWidth'], type: 'options', categories: ['LEGEND'], label: 'FULL_WIDTH', categoriesAndLabel: ['LEGEND', 'FULL_WIDTH'] },
    'legend_reverse': { limitedTo: ChartsTypes(), path: ['legend', 'reverse'], type: 'options', categories: ['LEGEND'], label: 'REVERSE', categoriesAndLabel: ['LEGEND', 'REVERSE'] },
    'legend_fontSize': { limitedTo: ChartsTypes(), path: ['legend', 'labels', 'fontSize'], type: 'options', categories: ['LEGEND'], label: 'FONT_SIZE', categoriesAndLabel: ['LEGEND', 'FONT_SIZE'] },
    'legend_fontStyle': { limitedTo: ChartsTypes(), path: ['legend', 'labels', 'fontStyle'], type: 'options', categories: ['LEGEND'], label: 'FONT_STYLE', categoriesAndLabel: ['LEGEND', 'FONT_STYLE'] },
    'legend_fontColor': { limitedTo: ChartsTypes(), path: ['legend', 'labels', 'fontColor'], type: 'options', categories: ['LEGEND'], label: 'FONT_COLOR', categoriesAndLabel: ['LEGEND', 'FONT_COLOR'] },
    'legend_boxWidth': { limitedTo: ChartsTypes(), path: ['legend', 'labels', 'boxWidth'], type: 'options', categories: ['LEGEND'], label: 'BOX_WIDTH', categoriesAndLabel: ['LEGEND', 'BOX_WIDTH'] },
    'legend_padding': { limitedTo: ChartsTypes(), path: ['legend', 'labels', 'padding'], type: 'options', categories: ['LEGEND'], label: 'PADDING', categoriesAndLabel: ['LEGEND', 'PADDING'] },
    'legend_usePointStyle': { limitedTo: ChartsTypes(), path: ['legend', 'labels', 'usePointStyle'], type: 'options', categories: ['LEGEND'], label: 'USE_POINT_STYLE', categoriesAndLabel: ['LEGEND', 'USE_POINT_STYLE'] },
    'legend_fontFamily': { limitedTo: ChartsTypes(), path: ['legend', 'labels', 'fontFamily'], type: 'options', categories: ['LEGEND'], label: 'FONT_FAMILY', categoriesAndLabel: ['LEGEND', 'FONT_FAMILY'] },
    'title_display': { limitedTo: ChartsTypes(), path: ['title', 'display'], type: 'options', categories: ['TITLE'], label: 'TO_DISPLAY', categoriesAndLabel:['TITLE', 'TO_DISPLAY'] },
    'title_position': { limitedTo: ChartsTypes(), path: ['title', 'position'], type: 'options', categories: ['TITLE'], label: 'POSITION', categoriesAndLabel: ['TITLE', 'POSITION'] },
    'title_fontSize': { limitedTo: ChartsTypes(), path: ['title', 'fontSize'], type: 'options', categories: ['TITLE'], label: 'FONT_SIZE', categoriesAndLabel: ['TITLE', 'FONT_SIZE'] },
    'title_fontStyle': { limitedTo: ChartsTypes(), path: ['title', 'fontStyle'], type: 'options', categories: ['TITLE'], label: 'FONT_STYLE', categoriesAndLabel: ['TITLE', 'FONT_STYLE'] },
    'title_fontColor': { limitedTo: ChartsTypes(), path: ['title', 'fontColor'], type: 'options', categories: ['TITLE'], label: 'FONT_COLOR', categoriesAndLabel: ['TITLE', 'FONT_COLOR'] },
    'title_padding': { limitedTo: ChartsTypes(), path: ['title', 'padding'], type: 'options', categories: ['TITLE'], label: 'PADDING', categoriesAndLabel: ['TITLE', 'PADDING'] },
    'title_fontFamily': { limitedTo: ChartsTypes(), path: ['title', 'fontFamily'], type: 'options', categories: ['TITLE'], label: 'FONT_FAMILY', categoriesAndLabel: ['TITLE', 'FONT_FAMILY'] },
    'title_lineHeight': { limitedTo: ChartsTypes(), path: ['title', 'lineHeight'], type: 'options', categories: ['TITLE'], label: 'LINE_HEIGHT', categoriesAndLabel: ['TITLE', 'LINE_HEIGHT'] },
    'tooltips_enabled': { limitedTo: ChartsTypes(), path: ['tooltips', 'enabled'], type: 'options', categories: ['TOOLTIPS'], label: 'TO_DISPLAY', categoriesAndLabel: ['TOOLTIPS', 'TO_DISPLAY'] },
    'tooltips_mode': { limitedTo: ChartsTypes(), path: ['tooltips', 'mode'], type: 'options', categories: ['TOOLTIPS'], label: 'MODE', categoriesAndLabel: ['TOOLTIPS', 'MODE'] },
    'tooltips_intersect': { limitedTo: ChartsTypes(), path: ['tooltips', 'intersect'], type: 'options', categories: ['TOOLTIPS'], label: 'INTERSECT', categoriesAndLabel: ['TOOLTIPS', 'INTERSECT'] },
    'tooltips_position': { limitedTo: ChartsTypes(), path: ['tooltips', 'position'], type: 'options', categories: ['TOOLTIPS'], label: 'POSITION', categoriesAndLabel: ['TOOLTIPS', 'POSITION'] },
    'tooltips_backgroundColor': { limitedTo: ChartsTypes(), path: ['tooltips', 'backgroundColor'], type: 'options', categories: ['TOOLTIPS'], label: 'BACKGROUND_COLOR', categoriesAndLabel: ['TOOLTIPS', 'BACKGROUND_COLOR'] },
    'tooltips_titleAlign': { limitedTo: ChartsTypes(), path: ['tooltips', 'titleAlign'], type: 'options', categories: ['TOOLTIPS', 'TITLE'], label: 'ALIGN', categoriesAndLabel: ['TOOLTIPS', 'TITLE', 'ALIGN'] },
    'tooltips_titleFontColor': { limitedTo: ChartsTypes(), path: ['tooltips', 'titleFontColor'], type: 'options', categories: ['TOOLTIPS', 'TITLE'], label: 'FONT_COLOR', categoriesAndLabel: ['TOOLTIPS', 'TITLE', 'FONT_COLOR'] },
    'tooltips_titleFontFamily': { limitedTo: ChartsTypes(), path: ['tooltips', 'titleFontFamily'], type: 'options', categories: ['TOOLTIPS', 'TITLE'], label: 'FONT_FAMILY', categoriesAndLabel: ['TOOLTIPS', 'TITLE', 'FONT_FAMILY'] },
    'tooltips_titleFontSize': { limitedTo: ChartsTypes(), path: ['tooltips', 'titleFontSize'], type: 'options', categories: ['TOOLTIPS', 'TITLE'], label: 'FONT_SIZE', categoriesAndLabel: ['TOOLTIPS', 'TITLE', 'FONT_SIZE'] },
    'tooltips_titleFontStyle': { limitedTo: ChartsTypes(), path: ['tooltips', 'titleFontStyle'], type: 'options', categories: ['TOOLTIPS', 'TITLE'], label: 'FONT_STYLE', categoriesAndLabel: ['TOOLTIPS', 'TITLE', 'FONT_STYLE'] },
    'tooltips_titleMarginBottom': { limitedTo: ChartsTypes(), path: ['tooltips', 'titleMarginBottom'], type: 'options', categories: ['TOOLTIPS', 'TITLE'], label: 'MARGIN_BOTTOM', categoriesAndLabel: ['TOOLTIPS', 'TITLE', 'MARGIN_BOTTOM'] },
    'tooltips_titleSpacing': { limitedTo: ChartsTypes(), path: ['tooltips', 'titleSpacing'], type: 'options', categories: ['TOOLTIPS', 'TITLE'], label: 'SPACING', categoriesAndLabel: ['TOOLTIPS', 'TITLE', 'SPACING'] },
    'tooltips_bodyAlign': { limitedTo: ChartsTypes(), path: ['tooltips', 'bodyAlign'], type: 'options', categories: ['TOOLTIPS', 'BODY'], label: 'ALIGN', categoriesAndLabel: ['TOOLTIPS', 'BODY', 'ALIGN'] },
    'tooltips_bodyFontColor': { limitedTo: ChartsTypes(), path: ['tooltips', 'bodyFontColor'], type: 'options', categories: ['TOOLTIPS', 'BODY'], label: 'FONT_COLOR', categoriesAndLabel: ['TOOLTIPS', 'BODY', 'FONT_COLOR'] },
    'tooltips_bodyFontFamily': { limitedTo: ChartsTypes(), path: ['tooltips', 'bodyFontFamily'], type: 'options', categories: ['TOOLTIPS', 'BODY'], label: 'FONT_FAMILY', categoriesAndLabel: ['TOOLTIPS', 'BODY', 'FONT_FAMILY'] },
    'tooltips_bodyFontSize': { limitedTo: ChartsTypes(), path: ['tooltips', 'bodyFontSize'], type: 'options', categories: ['TOOLTIPS', 'BODY'], label: 'FONT_SIZE', categoriesAndLabel: ['TOOLTIPS', 'BODY', 'FONT_SIZE'] },
    'tooltips_bodyFontStyle': { limitedTo: ChartsTypes(), path: ['tooltips', 'bodyFontStyle'], type: 'options', categories: ['TOOLTIPS', 'BODY'], label: 'FONT_STYLE', categoriesAndLabel: ['TOOLTIPS', 'BODY', 'FONT_STYLE'] },
    'tooltips_bodySpacing': { limitedTo: ChartsTypes(), path: ['tooltips', 'bodySpacing'], type: 'options', categories: ['TOOLTIPS', 'BODY'], label: 'SPACING', categoriesAndLabel: ['TOOLTIPS', 'BODY', 'SPACING'] },
    'tooltips_xPadding': { limitedTo: ChartsTypes(), path: ['tooltips', 'xPadding'], type: 'options', categories: ['TOOLTIPS'], label: 'X_PADDING', categoriesAndLabel: ['TOOLTIPS', 'X_PADDING'] },
    'tooltips_yPadding': { limitedTo: ChartsTypes(), path: ['tooltips', 'yPadding'], type: 'options', categories: ['TOOLTIPS'], label: 'Y_PADDING', categoriesAndLabel: ['TOOLTIPS', 'Y_PADDING'] },
    'tooltips_caretPadding': { limitedTo: ChartsTypes(), path: ['tooltips', 'caretPadding'], type: 'options', categories: ['TOOLTIPS', 'CARET'], label: 'PADDING', categoriesAndLabel: ['TOOLTIPS', 'CARET', 'PADDING'] },
    'tooltips_caretSize': { limitedTo: ChartsTypes(), path: ['tooltips', 'caretSize'], type: 'options', categories: ['TOOLTIPS', 'CARET'], label: 'SIZE', categoriesAndLabel: ['TOOLTIPS', 'CARET', 'SIZE'] },
    'tooltips_cornerRadius': { limitedTo: ChartsTypes(), path: ['tooltips', 'cornerRadius'], type: 'options', categories: ['TOOLTIPS'], label: 'CORNER_RADIUS', categoriesAndLabel: ['TOOLTIPS', 'CORNER_RADIUS'] },
    'tooltips_multiKeyBackground': { limitedTo: ChartsTypes(), path: ['tooltips', 'multiKeyBackground'], type: 'options', categories: ['TOOLTIPS'], label: 'MULTI_KEY_BACKGROUND', categoriesAndLabel: ['TOOLTIPS', 'MULTI_KEY_BACKGROUND'] },
    'tooltips_displayColors': { limitedTo: ChartsTypes(), path: ['tooltips', 'displayColors'], type: 'options', categories: ['TOOLTIPS'], label: 'TO_DISPLAY_COLORS', categoriesAndLabel: ['TOOLTIPS', 'TO_DISPLAY_COLORS'] },
    'tooltips_borderColor': { limitedTo: ChartsTypes(), path: ['tooltips', 'borderColor'], type: 'options', categories: ['TOOLTIPS'], label: 'BORDER_COLOR', categoriesAndLabel: ['TOOLTIPS', 'BORDER_COLOR'] },
    'tooltips_borderWidth': { limitedTo: ChartsTypes(), path: ['tooltips', 'borderWidth'], type: 'options', categories: ['TOOLTIPS'], label: 'BORDER_WIDTH', categoriesAndLabel: ['TOOLTIPS', 'BORDER_WIDTH'] },
    'elements_point_style': { limitedTo: ['radar', 'bubble', 'scatter', 'line'], path: ['pointStyle'], type: 'series', categories: ['POINT'], label: 'STYLE', categoriesAndLabel: ['POINT', 'STYLE'] },
    'elements_point_radius': { limitedTo: ['radar', 'scatter', 'line'], path: ['pointRadius'], type: 'series', categories: ['POINT'], label: 'RADIUS', categoriesAndLabel: ['POINT', 'RADIUS'] },
    'elements_point_rotation': { limitedTo: ['radar', 'scatter', 'line'], path: ['pointRotation'], type: 'series', categories: ['POINT'], label: 'ROTATION', categoriesAndLabel: ['POINT', 'ROTATION'] },
    'elements_point_backgroundColor': { limitedTo: ['radar', 'scatter', 'line'], path: ['pointBackgroundColor'], type: 'series', categories: ['POINT'], label: 'BACKGROUND_COLOR', categoriesAndLabel: ['POINT', 'BACKGROUND_COLOR'] },
    'elements_point_borderColor': { limitedTo: ['radar', 'scatter', 'line'], path: ['pointBorderColor'], type: 'series', categories: ['POINT'], label: 'BORDER_COLOR', categoriesAndLabel: ['POINT', 'BORDER_COLOR'] },
    'elements_point_borderWidth': { limitedTo: ['radar', 'scatter', 'line'], path: ['pointBorderWidth'], type: 'series', categories: ['POINT'], label: 'BORDER_WIDTH', categoriesAndLabel: ['POINT', 'BORDER_WIDTH'] },
    'elements_point_hitRadius': { limitedTo: ['radar', 'scatter', 'line'], path: ['pointHitRadius'], type: 'series', categories: ['POINT'], label: 'HIT_RADIUS', categoriesAndLabel: ['POINT', 'HIT_RADIUS'] },
    'elements_point_hoverRadius': { limitedTo: ['radar', 'scatter', 'line'], path: ['pointHoverRadius'], type: 'series', categories: ['POINT', 'HOVER'], label: 'RADIUS', categoriesAndLabel: ['POINT', 'HOVER', 'RADIUS'] },
    'elements_point_hoverBackgroundColor': { limitedTo: ['radar', 'scatter', 'line'], path: ['pointHoverBackgroundColor'], type: 'series', categories: ['POINT', 'HOVER'], label: 'BACKGROUND_COLOR', categoriesAndLabel: ['POINT', 'HOVER', 'BACKGROUND_COLOR'] },
    'elements_point_hoverBorderColor': { limitedTo: ['radar', 'scatter', 'line'], path: ['pointHoverBorderColor'], type: 'series', categories: ['POINT', 'HOVER'], label: 'BORDER_COLOR', categoriesAndLabel: ['POINT', 'HOVER', 'BORDER_COLOR'] },
    'elements_point_hoverBorderWidth': { limitedTo: ['radar', 'scatter', 'line'], path: ['pointHoverBorderWidth'], type: 'series', categories: ['POINT', 'HOVER'], label: 'BORDER_WIDTH', categoriesAndLabel: ['POINT', 'HOVER', 'BORDER_WIDTH'] },
    'elements_backgroundColor': { limitedTo: ChartsTypes(), path: ['backgroundColor'], type: 'series', categories: ['SERIE'], label: 'BACKGROUND_COLOR', categoriesAndLabel: ['SERIE', 'BACKGROUND_COLOR'] },
    'elements_backgroundMultiGradiant': { limitedTo: ['bar', 'bubble', 'doughnut', 'horizontalBar', 'pie', 'polarArea'], path: ['backgroundMultiGradiant'], type: 'series', categories: ['SERIE'], label: 'BACKGROUND_GRADIANT', categoriesAndLabel: ['SERIE', 'BACKGROUND_GRADIANT'] },
    'elements_borderColor': { limitedTo: ChartsTypes(), path: ['borderColor'], type: 'series', categories: ['SERIE'], label: 'BORDER_COLOR', categoriesAndLabel: ['SERIE', 'BORDER_COLOR'] },
    'elements_borderMultiGradiant': { limitedTo: ['bar', 'bubble', 'doughnut', 'horizontalBar', 'pie', 'polarArea'], path: ['borderMultiGradiant'], type: 'series', categories: ['SERIE'], label: 'BORDER_GRADIANT', categoriesAndLabel: ['SERIE', 'BORDER_GRADIANT'] },
    'elements_borderWidth': { limitedTo: ChartsTypes(), path: ['borderWidth'], type: 'series', categories: ['SERIE'], label: 'BORDER_WIDTH', categoriesAndLabel: ['SERIE', 'BORDER_WIDTH'] },
    'elements_fill': { limitedTo: ['radar', 'line'], path: ['fill'], type: 'series', categories: ['SERIE'], label: 'FILL', categoriesAndLabel: ['SERIE', 'FILL'] },
    'elements_lineTension': { limitedTo: ['radar', 'line'], path: ['lineTension'], type: 'series', categories: ['SERIE'], label: 'LINE_TENSION', categoriesAndLabel: ['SERIE', 'LINE_TENSION'] },
    'elements_hideInLegendAndTooltip': { limitedTo: ChartsTypes(), path: ['hideInLegendAndTooltip'], type: 'series', categories: ['SERIE'], label: 'HIDE_IN_LEGEND_AND_TOOLTIPS', categoriesAndLabel: ['SERIE', 'HIDE_IN_LEGEND_AND_TOOLTIPS'] },
    'elements_hidden': { limitedTo: ChartsTypes(), path: ['hidden'], type: 'series', categories: ['SERIE'], label: 'HIDDEN', categoriesAndLabel: ['SERIE', 'HIDDEN'] },
    'elements_spanGaps': { limitedTo: [/*'radar',*/ 'line'], path: ['spanGaps'], type: 'series', categories: ['SERIE'], label: 'SPAN_GAPS', categoriesAndLabel: ['SERIE', 'SPAN_GAPS'] }, //pour le moment il y a un problème sur les spanGaps des radars : https://github.com/chartjs/Chart.js/pull/5359
    'elements_steppedLine': { limitedTo: ['line'], path: ['steppedLine'], type: 'series', categories: ['SERIE'], label: 'STEPPED_LINE', categoriesAndLabel: ['SERIE', 'STEPPED_LINE'] },
    'elements_borderDash': { limitedTo: ['radar', 'line'], path: ['borderDash'], type: 'series', categories: ['SERIE'], label: 'BORDER_DASH', categoriesAndLabel: ['SERIE', 'BORDER_DASH'] },
    'elements_borderCapStyle': { limitedTo: ['radar', 'line'], path: ['borderCapStyle'], type: 'series', categories: ['SERIE'], label: 'BORDER_CAP_STYLE', categoriesAndLabel: ['SERIE', 'BORDER_CAP_STYLE'] },
    'elements_borderJoinStyle': { limitedTo: ['radar', 'line'], path: ['borderJoinStyle'], type: 'series', categories: ['SERIE'], label: 'BORDER_JOIN_STYLE', categoriesAndLabel: ['SERIE', 'BORDER_JOIN_STYLE'] },
    'elements_hoverBackgroundColor': { limitedTo: ChartsTypes(), path: ['hoverBackgroundColor'], type: 'series', categories: ['SERIE', 'HOVER'], label: 'BACKGROUND_COLOR', categoriesAndLabel: ['SERIE', 'HOVER', 'BACKGROUND_COLOR'] },
    'elements_hoverBorderColor': { limitedTo: ChartsTypes(), path: ['hoverBorderColor'], type: 'series', categories: ['SERIE', 'HOVER'], label: 'BORDER_COLOR', categoriesAndLabel: ['SERIE', 'HOVER', 'BORDER_COLOR'] },
    'elements_hoverBorderWidth': { limitedTo: ChartsTypes(), path: ['hoverBorderWidth'], type: 'series', categories: ['SERIE', 'HOVER'], label: 'BORDER_WIDTH', categoriesAndLabel: ['SERIE', 'HOVER', 'BORDER_WIDTH'] },
    'elements_hoverBorderDash': { limitedTo: ['radar', 'line'], path: ['hoverBorderDash'], type: 'series', categories: ['SERIE', 'HOVER'], label: 'BORDER_DASH', categoriesAndLabel: ['SERIE', 'HOVER', 'BORDER_DASH'] },
    'elements_chart_cutoutPercentage': { limitedTo: ['doughnut'], path: ['cutoutPercentage'], type: 'series', categories: ['SPECIFIC'], label: 'CUTOUT', categoriesAndLabel: ['SPECIFIC', 'CUTOUT'] },
    'elements_chart_circumference': { limitedTo: ['pie', 'doughnut'], path: ['circumference'], type: 'series', categories: ['SPECIFIC'], label: 'CIRCUMFERENCE', categoriesAndLabel: ['SPECIFIC', 'CIRCUMFERENCE'] },
    'elements_chart_rotation': { limitedTo: ['pie', 'doughnut', 'gauge'], path: ['rotation'], type: 'series', categories: ['SPECIFIC'], label: 'ROTATION', categoriesAndLabel: ['SPECIFIC', 'ROTATION'] },
    'elements_chart_transitionAnimation': { limitedTo: ['sticker'], path: ['transitionAnimation'], type: 'series', categories: ['SPECIFIC'], label: 'TRANSITION', categoriesAndLabel: ['SPECIFIC', 'TRANSITION'] },
    'elements_chart_gauge_width_scale': { limitedTo: ['gauge'], path: ['gauge', 'gaugeWidthScale'], type: 'series', categories: ['SPECIFIC'], label: 'WIDTH_SCALE', categoriesAndLabel: ['SPECIFIC', 'WIDTH_SCALE'] },
    'elements_chart_gauge_color': { limitedTo: ['gauge'], path: ['gauge', 'gaugeColor'], type: 'series', categories: ['SPECIFIC'], label: 'BACKGROUND_COLOR', categoriesAndLabel: ['SPECIFIC', 'BACKGROUND_COLOR'] },
    'elements_chart_gauge_shadow_opacity': { limitedTo: ['gauge'], path: ['gauge', 'shadowOpacity'], type: 'series', categories: ['SPECIFIC', 'SHADOW'], label: 'OPACITY', categoriesAndLabel: ['SPECIFIC', 'SHADOW', 'OPACITY'] },
    'elements_chart_gauge_shadow_size': { limitedTo: ['gauge'], path: ['gauge', 'shadowSize'], type: 'series', categories: ['SPECIFIC', 'SHADOW'], label: 'SIZE', categoriesAndLabel: ['SPECIFIC', 'SHADOW', 'SIZE'] },
    'elements_chart_gauge_shadow_vertical_offset': { limitedTo: ['gauge'], path: ['gauge', 'shadowVerticalOffset'], type: 'series', categories: ['SPECIFIC', 'SHADOW'], label: 'VERTICAL_OFFSET', categoriesAndLabel: ['SPECIFIC', 'SHADOW', 'VERTICAL_OFFSET'] },
    'elements_chart_gauge_shadow_show_inner': { limitedTo: ['gauge'], path: ['gauge', 'showInnerShadow'], type: 'series', categories: ['SPECIFIC', 'SHADOW'], label: 'DISPLAY', categoriesAndLabel: ['SPECIFIC', 'SHADOW', 'DISPLAY'] },
    'elements_chart_gauge_pointer_display': { limitedTo: ['gauge'], path: ['gauge', 'pointer'], type: 'series', categories: ['SPECIFIC', 'POINTER'], label: 'DISPLAY', categoriesAndLabel: ['SPECIFIC', 'POINTER', 'DISPLAY'] },
    'elements_chart_gauge_pointer_top_length': { limitedTo: ['gauge'], path: ['gauge', 'pointerOptions', 'toplength'], type: 'series', categories: ['SPECIFIC', 'POINTER'], label: 'TOP_LENGTH', categoriesAndLabel: ['SPECIFIC', 'POINTER', 'TOP_LENGTH'] },
    'elements_chart_gauge_pointer_bottom_length': { limitedTo: ['gauge'], path: ['gauge', 'pointerOptions', 'bottomlength'], type: 'series', categories: ['SPECIFIC', 'POINTER'], label: 'BOTTOM_LENGTH', categoriesAndLabel: ['SPECIFIC', 'POINTER', 'BOTTOM_LENGTH'] },
    'elements_chart_gauge_pointer_bottom_width': { limitedTo: ['gauge'], path: ['gauge', 'pointerOptions', 'bottomwidth'], type: 'series', categories: ['SPECIFIC', 'POINTER'], label: 'BOTTOM_WIDTH', categoriesAndLabel: ['SPECIFIC', 'POINTER', 'BOTTOM_WIDTH'] },
    'elements_chart_gauge_pointer_stroke': { limitedTo: ['gauge'], path: ['gauge', 'pointerOptions', 'stroke'], type: 'series', categories: ['SPECIFIC', 'POINTER'], label: 'BORDER_COLOR', categoriesAndLabel: ['SPECIFIC', 'POINTER', 'BORDER_COLOR'] },
    'elements_chart_gauge_pointer_stroke_width': { limitedTo: ['gauge'], path: ['gauge', 'pointerOptions', 'stroke_width'], type: 'series', categories: ['SPECIFIC', 'POINTER'], label: 'BORDER_WIDTH', categoriesAndLabel: ['SPECIFIC', 'POINTER', 'BORDER_WIDTH'] },
    'elements_chart_gauge_pointer_stroke_line_cap': { limitedTo: ['gauge'], path: ['gauge', 'pointerOptions', 'stroke_linecap'], type: 'series', categories: ['SPECIFIC', 'POINTER'], label: 'BORDER_CAP_STYLE', categoriesAndLabel: ['SPECIFIC', 'POINTER', 'BORDER_CAP_STYLE'] },
    'elements_chart_gauge_pointer_color': { limitedTo: ['gauge'], path: ['gauge', 'pointerOptions', 'color'], type: 'series', categories: ['SPECIFIC', 'POINTER'], label: 'BACKGROUND_COLOR', categoriesAndLabel: ['SPECIFIC', 'POINTER', 'BACKGROUND_COLOR'] },
    'elements_iconFrame': { limitedTo: ['sticker'], path: ['iconFrame'], type: 'series', categories: ['SERIE', 'ICON'], label: 'LAYOUT', categoriesAndLabel: ['SERIE', 'ICON_FRAME'] },
    //'elements_chart_startAngle': { limitedTo: ['polarArea', 'radar'], path: ['startAngle'], type: 'series', categories: ['SPECIFIC'], label: 'START_ANGLE', categoriesAndLabel: ['SPECIFIC', 'START_ANGLE'] },
    'elements_borderAlign': { limitedTo: ['pie', 'doughnut', 'polarArea'], path: ['borderAlign'], type: 'series', categories: ['SERIE'], label: 'BORDER_ALIGN', categoriesAndLabel: ['SERIE', 'BORDER_ALIGN'] },
    'elements_weight': { limitedTo: ['pie', 'doughnut'], path: ['weight'], type: 'series', categories: ['SERIE'], label: 'WEIGHT', categoriesAndLabel: ['SERIE', 'WEIGHT'] },
    'elements_datalabelsID': { limitedTo: ChartsTypes(), path: ['datalabelsID'], type: 'series', categories: ['SERIE'], label: 'LABELS', categoriesAndLabel: ['SERIE', 'LABELS'] },
    'elements_xAxisID': { limitedTo: ChartsTypes(), path: ['xAxisID'], type: 'series', categories: ['SERIE', 'X'], label: 'AXIS', categoriesAndLabel: ['SERIE', 'X', 'AXIS'] },
    'elements_yAxisID': { limitedTo: ChartsTypes(), path: ['yAxisID'], type: 'series', categories: ['SERIE', 'Y'], label: 'AXIS', categoriesAndLabel: ['SERIE', 'Y', 'AXIS'] },
    'datalabels_anchor': { limitedTo: ChartsTypes(), path: ['anchor'], type: 'datalabels', categories: ['LABELS'], label: 'ANCHOR', categoriesAndLabel: ['LABELS', 'ANCHOR'] },
    'datalabels_align': { limitedTo: ChartsTypes(), path: ['align'], type: 'datalabels', categories: ['LABELS'], label: 'ALIGN', categoriesAndLabel: ['LABELS', 'ALIGN'] },
    'datalabels_clamp': { limitedTo: ChartsTypes(), path: ['clamp'], type: 'datalabels', categories: ['LABELS'], label: 'CLAMP', categoriesAndLabel: ['LABELS', 'CLAMP'] },
    'datalabels_display': { limitedTo: ChartsTypes(), path: ['display'], type: 'datalabels', categories: ['LABELS'], label: 'TO_DISPLAY', categoriesAndLabel: ['LABELS', 'TO_DISPLAY'] },
    'datalabels_offset': { limitedTo: ChartsTypes(), path: ['offset'], type: 'datalabels', categories: ['LABELS'], label: 'OFFSET', categoriesAndLabel: ['LABELS', 'OFFSET'] },
    'datalabels_rotation': { limitedTo: ChartsTypes(), path: ['rotation'], type: 'datalabels', categories: ['LABELS'], label: 'ROTATION', categoriesAndLabel: ['LABELS', 'ROTATION'] },
    'datalabels_clip': { limitedTo: ChartsTypes(), path: ['clip'], type: 'datalabels', categories: ['LABELS'], label: 'CLIP', categoriesAndLabel: ['LABELS', 'CLIP'] },
    'datalabels_borderWidth': { limitedTo: ChartsTypes(), path: ['borderWidth'], type: 'datalabels', categories: ['LABELS'], label: 'BORDER_WIDTH', categoriesAndLabel: ['LABELS', 'BORDER_WIDTH'] },
    'datalabels_borderRadius': { limitedTo: ChartsTypes(), path: ['borderRadius'], type: 'datalabels', categories: ['LABELS'], label: 'BORDER_RADIUS', categoriesAndLabel: ['LABELS', 'BORDER_RADIUS'] },
    'datalabels_borderColor': { limitedTo: ChartsTypes(), path: ['borderColor'], type: 'datalabels', categories: ['LABELS'], label: 'BORDER_COLOR', categoriesAndLabel: ['LABELS', 'BORDER_COLOR'] },
    'datalabels_padding': { limitedTo: ChartsTypes(), path: ['padding'], type: 'datalabels', categories: ['LABELS'], label: 'PADDING', categoriesAndLabel: ['LABELS', 'PADDING'] },
    'datalabels_padding_top': { limitedTo: ChartsTypes(), path: ['padding', 'top'], type: 'datalabels', categories: ['LABELS', 'PADDING'], label: 'TOP', categoriesAndLabel: ['LABELS', 'TOP'], subOption: true },
    'datalabels_padding_left': { limitedTo: ChartsTypes(), path: ['padding', 'left'], type: 'datalabels', categories: ['LABELS', 'PADDING'], label: 'LEFT', categoriesAndLabel: ['LABELS', 'LEFT'], subOption: true },
    'datalabels_padding_right': { limitedTo: ChartsTypes(), path: ['padding', 'right'], type: 'datalabels', categories: ['LABELS', 'PADDING'], label: 'RIGHT', categoriesAndLabel: ['LABELS', 'RIGHT'], subOption: true },
    'datalabels_padding_bottom': { limitedTo: ChartsTypes(), path: ['padding', 'bottom'], type: 'datalabels', categories: ['LABELS', 'PADDING'], label: 'BOTTOM', categoriesAndLabel: ['LABELS', 'BOTTOM'], subOption: true },
    'datalabels_backgroundColor': { limitedTo: ChartsTypes(), path: ['backgroundColor'], type: 'datalabels', categories: ['LABELS'], label: 'BACKGROUND_COLOR', categoriesAndLabel: ['LABELS', 'BACKGROUND_COLOR'] },
    'datalabels_color': { limitedTo: ChartsTypes(), path: ['color'], type: 'datalabels', categories: ['LABELS'], label: 'FONT_COLOR', categoriesAndLabel: ['LABELS', 'FONT_COLOR'] },
    'datalabels_fontFamily': { limitedTo: ChartsTypes(), path: ['font', 'family'], type: 'datalabels', categories: ['LABELS'], label: 'FONT_FAMILY', categoriesAndLabel: ['LABELS', 'FONT_FAMILY'] },
    'datalabels_fontLineHeight': { limitedTo: ChartsTypes(), path: ['font', 'lineHeight'], type: 'datalabels', categories: ['LABELS'], label: 'LINE_HEIGHT', categoriesAndLabel: ['LABELS', 'LINE_HEIGHT'] },
    'datalabels_fontSize': { limitedTo: ChartsTypes(), path: ['font', 'size'], type: 'datalabels', categories: ['LABELS'], label: 'FONT_SIZE', categoriesAndLabel: ['LABELS', 'FONT_SIZE'] },
    'datalabels_fontStyle': { limitedTo: ChartsTypes(), path: ['font', 'style'], type: 'datalabels', categories: ['LABELS'], label: 'FONT_STYLE', categoriesAndLabel: ['LABELS', 'FONT_STYLE'] },
    'datalabels_opacity': { limitedTo: ChartsTypes(), path: ['opacity'], type: 'datalabels', categories: ['LABELS'], label: 'OPACITY', categoriesAndLabel: ['LABELS', 'OPACITY'] },
    'datalabels_textAlign': { limitedTo: ChartsTypes(), path: ['textAlign'], type: 'datalabels', categories: ['LABELS', 'TEXT'], label: 'ALIGN', categoriesAndLabel: ['LABELS', 'TEXT', 'ALIGN'] },
    'datalabels_textStrokeColor': { limitedTo: ChartsTypes(), path: ['textStrokeColor'], type: 'datalabels', categories: ['LABELS'], label: 'STROKE_COLOR', categoriesAndLabel: ['LABELS', 'STROKE_COLOR'] },
    'datalabels_textStrokeWidth': { limitedTo: ChartsTypes(), path: ['textStrokeWidth'], type: 'datalabels', categories: ['LABELS'], label: 'STROKE_WIDTH', categoriesAndLabel: ['LABELS', 'STROKE_WIDTH'] },
    'datalabels_textShadowBlur': { limitedTo: ChartsTypes(), path: ['textShadowBlur'], type: 'datalabels', categories: ['LABELS'], label: 'SHADOW_BLUR', categoriesAndLabel: ['LABELS', 'SHADOW_BLUR'] },
    'datalabels_textShadowColor': { limitedTo: ChartsTypes(), path: ['textShadowColor'], type: 'datalabels', categories: ['LABELS'], label: 'SHADOW_COLOR', categoriesAndLabel: ['LABELS', 'SHADOW_COLOR'] },
    'axes_display': { limitedTo: ['bar', 'horizontalBar', 'bubble', 'line', 'scatter', 'radar', 'polarArea'], path: ['display'], type: 'axes', categories: ['AXES'], label: 'TO_DISPLAY', categoriesAndLabel: ['AXES', 'TO_DISPLAY'] },
    'axes_barPercentage': { limitedTo: ['bar', 'horizontalBar'], path: ['barPercentage'], type: 'axes', categories: ['AXES', 'BARS'], label: 'PERCENTAGE', categoriesAndLabel: ['AXES', 'BARS', 'PERCENTAGE'] },
    'axes_categoryPercentage': { limitedTo: ['bar', 'horizontalBar'], path: ['categoryPercentage'], type: 'axes', categories: ['AXES', 'CATEGORY'], label: 'PERCENTAGE', categoriesAndLabel: ['AXES', 'CATEGORY', 'PERCENTAGE'] },
    'axes_minBarLength': { limitedTo: ['bar', 'horizontalBar'], path: ['minBarLength'], type: 'axes', categories: ['AXES', 'BAR'], label: 'MIN_HEIGHT', categoriesAndLabel: ['AXES', 'BAR', 'MIN_HEIGHT'] },
    'axes_position': { limitedTo: ['bar', 'horizontalBar', 'bubble', 'line', 'scatter'], path: ['position'], type: 'axes', categories: ['AXES'], label: 'POSITION', categoriesAndLabel: ['AXES', 'POSITION'] },
    'axes_gridLines_display': { limitedTo: ['bar', 'horizontalBar', 'bubble', 'line', 'scatter', 'radar', 'polarArea'], path: ['gridLines', 'display'], type: 'axes', categories: ['AXES', 'GRID', 'LINES'], label: 'TO_DISPLAY', categoriesAndLabel: ['AXES', 'GRID', 'LINES', 'TO_DISPLAY'] },
    'axes_gridLines_circular': { limitedTo: ['radar'], path: ['gridLines', 'circular'], type: 'axes', categories: ['AXES', 'GRID', 'LINES'], label: 'CIRCULAR', categoriesAndLabel: ['AXES', 'GRID', 'LINES', 'CIRCULAR'] },
    'axes_gridLines_color': { limitedTo: ['bar', 'horizontalBar', 'bubble', 'line', 'scatter', 'radar', 'polarArea'], path: ['gridLines', 'color'], type: 'axes', categories: ['AXES', 'GRID', 'LINES'], label: 'COLOR', categoriesAndLabel: ['AXES', 'GRID', 'LINES', 'COLOR'] },
    'axes_gridLines_borderDash': { limitedTo: ['bar', 'horizontalBar', 'bubble', 'line', 'scatter', 'radar', 'polarArea'], path: ['gridLines', 'borderDash'], type: 'axes', categories: ['AXES', 'GRID', 'LINES'], label: 'BORDER_DASH', categoriesAndLabel: ['AXES', 'GRID', 'LINES', 'BORDER_DASH'] },
    'axes_gridLines_lineWidth': { limitedTo: ['bar', 'horizontalBar', 'bubble', 'line', 'scatter', 'radar', 'polarArea'], path: ['gridLines', 'lineWidth'], type: 'axes', categories: ['AXES', 'GRID', 'LINES'], label: 'WIDTH', categoriesAndLabel: ['AXES', 'GRID', 'LINES', 'WIDTH'] },
    'axes_gridLines_drawBorder': { limitedTo: ['bar', 'horizontalBar', 'bubble', 'line', 'scatter'], path: ['gridLines', 'drawBorder'], type: 'axes', categories: ['AXES', 'GRID', 'LINES'], label: 'DRAW_BORDER', categoriesAndLabel: ['AXES', 'GRID', 'LINES', 'DRAW_BORDER'] },
    'axes_gridLines_drawTicks': { limitedTo: ['bar', 'horizontalBar', 'bubble', 'line', 'scatter'], path: ['gridLines', 'drawTicks'], type: 'axes', categories: ['AXES', 'GRID', 'LINES'], label: 'DRAW_TICKS', categoriesAndLabel: ['AXES', 'GRID', 'LINES', 'DRAW_TICKS'] },
    'axes_gridLines_tickMarkLength': { limitedTo: ['bar', 'horizontalBar', 'bubble', 'line', 'scatter'], path: ['gridLines', 'tickMarkLength'], type: 'axes', categories: ['AXES', 'GRID', 'LINES'], label: 'TICKS_LENGTH', categoriesAndLabel: ['AXES', 'GRID', 'LINES', 'TICKS_LENGTH'] },
    'axes_ticks_beginAtZero': { limitedTo: ['bar', 'horizontalBar', 'bubble', 'line', 'scatter', 'radar', 'polarArea'], path: ['ticks', 'beginAtZero'], type: 'axes', categories: ['AXES', 'TICKS'], label: 'BEGIN_AT_ZERO', categoriesAndLabel: ['AXES', 'TICKS', 'BEGIN_AT_ZERO'] },
    'axes_ticks_display': { limitedTo: ['bar', 'horizontalBar', 'bubble', 'line', 'scatter', 'radar', 'polarArea'], path: ['ticks', 'display'], type: 'axes', categories: ['AXES', 'TICKS'], label: 'TO_DISPLAY', categoriesAndLabel: ['AXES', 'TICKS', 'TO_DISPLAY'] },
    'axes_ticks_lineHeight': { limitedTo: ['bar', 'horizontalBar', 'bubble', 'line', 'scatter'], path: ['ticks', 'lineHeight'], type: 'axes', categories: ['AXES', 'TICKS'], label: 'LINE_HEIGHT', categoriesAndLabel: ['AXES', 'TICKS', 'LINE_HEIGHT'] },
    'axes_ticks_fontColor': { limitedTo: ['bar', 'horizontalBar', 'bubble', 'line', 'scatter', 'radar', 'polarArea'], path: ['ticks', 'fontColor'], type: 'axes', categories: ['AXES', 'TICKS'], label: 'FONT_COLOR', categoriesAndLabel: ['AXES', 'TICKS', 'FONT_COLOR'] },
    'axes_ticks_fontFamily': { limitedTo: ['bar', 'horizontalBar', 'bubble', 'line', 'scatter', 'radar', 'polarArea'], path: ['ticks', 'fontFamily'], type: 'axes', categories: ['AXES', 'TICKS'], label: 'FONT_FAMILY', categoriesAndLabel: ['AXES', 'TICKS', 'FONT_FAMILY'] },
    'axes_ticks_fontSize': { limitedTo: ['bar', 'horizontalBar', 'bubble', 'line', 'scatter', 'radar', 'polarArea'], path: ['ticks', 'fontSize'], type: 'axes', categories: ['AXES', 'TICKS'], label: 'FONT_SIZE', categoriesAndLabel: ['AXES', 'TICKS', 'FONT_SIZE'] },
    'axes_ticks_fontStyle': { limitedTo: ['bar', 'horizontalBar', 'bubble', 'line', 'scatter', 'radar', 'polarArea'], path: ['ticks', 'fontStyle'], type: 'axes', categories: ['AXES', 'TICKS'], label: 'FONT_STYLE', categoriesAndLabel: ['AXES', 'TICKS', 'FONT_STYLE'] },
    'axes_ticks_padding': { limitedTo: ['bar', 'horizontalBar', 'bubble', 'line', 'scatter'], path: ['ticks', 'padding'], type: 'axes', categories: ['AXES', 'TICKS'], label: 'PADDING', categoriesAndLabel: ['AXES', 'TICKS', 'PADDING'] },
    'axes_title_display': { limitedTo: ['bar', 'horizontalBar', 'bubble', 'line', 'scatter'], path: ['scaleLabel', 'display'], type: 'axes', categories: ['AXES', 'TITLE'], label: 'TO_DISPLAY', categoriesAndLabel: ['AXES', 'TITLE', 'TO_DISPLAY'] },
    'axes_title_lineHeight': { limitedTo: ['bar', 'horizontalBar', 'bubble', 'line', 'scatter'], path: ['scaleLabel', 'lineHeight'], type: 'axes', categories: ['AXES', 'TITLE'], label: 'LINE_HEIGHT', categoriesAndLabel: ['AXES', 'TITLE', 'LINE_HEIGHT'] },
    'axes_title_fontColor': { limitedTo: ['bar', 'horizontalBar', 'bubble', 'line', 'scatter'], path: ['scaleLabel', 'fontColor'], type: 'axes', categories: ['AXES', 'TITLE'], label: 'FONT_COLOR', categoriesAndLabel: ['AXES', 'TITLE', 'FONT_COLOR'] },
    'axes_title_fontFamily': { limitedTo: ['bar', 'horizontalBar', 'bubble', 'line', 'scatter'], path: ['scaleLabel', 'fontFamily'], type: 'axes', categories: ['AXES', 'TITLE'], label: 'FONT_FAMILY', categoriesAndLabel: ['AXES', 'TITLE', 'FONT_FAMILY'] },
    'axes_title_fontSize': { limitedTo: ['bar', 'horizontalBar', 'bubble', 'line', 'scatter'], path: ['scaleLabel', 'fontSize'], type: 'axes', categories: ['AXES', 'TITLE'], label: 'FONT_SIZE', categoriesAndLabel: ['AXES', 'TITLE', 'FONT_SIZE'] },
    'axes_title_fontStyle': { limitedTo: ['bar', 'horizontalBar', 'bubble', 'line', 'scatter'], path: ['scaleLabel', 'fontStyle'], type: 'axes', categories: ['AXES', 'TITLE'], label: 'FONT_STYLE', categoriesAndLabel: ['AXES', 'TITLE', 'FONT_STYLE'] },
    'axes_title_padding': { limitedTo: ['bar', 'horizontalBar', 'bubble', 'line', 'scatter'], path: ['scaleLabel', 'padding'], type: 'axes', categories: ['AXES', 'TITLE'], label: 'PADDING', categoriesAndLabel: ['AXES', 'TITLE', 'PADDING'] }
  } ;
}

export function CheckOptions(themeOptions: ThemeOptionsModel): ThemeOptionsModel {
  themeOptions.options.common = DeepMerge(_optionsModel, themeOptions.options.common);
  return themeOptions;
}

export function ThemeOptionsDefault(): ThemeOptionsModel {
  return { 
    options: {
      common: DeepCopy(_optionsModel), 
      specific: {
        radar: { startAngle: 0 },
        doughnut: { cutoutPercentage: 50 }
      } as unknown as { [key in ChartType]: ChartOptions }
    },
    series: {
      common: [DeepCopy(_serieModel)],
      specific: {} as { [key in ChartType]: ChartDataSets[] }
    },
    datalabels: {
      common: [DeepCopy(_datalabelsModel)],
      specific: {} as { [key in ChartType]: Options[] }
    },
    xAxes: {
      common: DeepCopy(_xAxes),
      specific: {} as { [key in ChartType]: ChartXAxe[] }
    },
    yAxes: {
      common: DeepCopy(_yAxes),
      specific: {} as { [key in ChartType]: ChartYAxe[] }
    }
  };
}

const _xAxes = [
  {
    id: 'x0',
    position: 'bottom',
    barPercentage: 0.9,
    categoryPercentage: 0.9,
    display: true,
    scaleLabel: {
      display: true,
      lineHeight: 1.2,
      fontColor: '#616161',
      fontFamily: 'Roboto',
      fontSize: 12,
      fontStyle: 'normal',
      labelString: 'x0',
      padding: 0
    },
    gridLines: {
      display: true,
      color: '#00000037',
      borderDash: [],
      lineWidth: 1,
      drawBorder: true,
      drawTicks: true,
      tickMarkLength: 10
    },
    ticks: {
      beginAtZero: false,
      display: true,
      lineHeight: 1.2,
      fontColor: '#616161',
      fontFamily: 'Roboto',
      fontSize: 12,
      fontStyle: 'normal',
      padding: 0
    }
  }
] as ChartXAxe[];

const _yAxes = [
  {
    id: 'y0',
    position: 'left',
    barPercentage: 0.9,
    categoryPercentage: 0.9,
    display: true,
    scaleLabel: {
      display: true,
      lineHeight: 1.2,
      fontColor: '#616161',
      fontFamily: 'Roboto',
      fontSize: 12,
      fontStyle: 'normal',
      labelString: 'y0',
      padding: 0
    },
    gridLines: {
      display: true,
      color: '#00000037',
      borderDash: [],
      lineWidth: 1,
      drawBorder: true,
      drawTicks: true,
      tickMarkLength: 10
    },
    ticks: {
      beginAtZero: false,
      display: true,
      lineHeight: 1.2,
      fontColor: '#616161',
      fontFamily: 'Roboto',
      fontSize: 12,
      fontStyle: 'normal',
      padding: 0
    }
  },
  {
    id: 'y1',
    position: 'right',
    barPercentage: 0.9,
    categoryPercentage: 0.9,
    display: true,
    scaleLabel: {
      display: true,
      lineHeight: 1.2,
      fontColor: '#616161',
      fontFamily: 'Roboto',
      fontSize: 12,
      fontStyle: 'normal',
      labelString: 'y1',
      padding: 0
    },
    gridLines: {
      display: true,
      color: '#00000037',
      borderDash: [],
      lineWidth: 1,
      drawBorder: true,
      drawTicks: true,
      tickMarkLength: 10
    },
    ticks: {
      beginAtZero: false,
      display: true,
      lineHeight: 1.2,
      fontColor: '#616161',
      fontFamily: 'Roboto',
      fontSize: 12,
      fontStyle: 'normal',
      padding: 0
    }
  }
] as ChartXAxe[];

const _datalabelsModel = {
  anchor: 'center',
  align: 'center',
  clamp: false,
  display: true,
  offset: 4,
  rotation: 0,
  clip: false,
  borderWidth: 0,
  borderColor: '#00000037',
  borderRadius: 0,
  padding: {
    top: 4,
    left: 4,
    right: 4,
    bottom: 4
  },
  backgroundColor: '#00000000',
  color: '#616161',
  font: {
    size: 12,
    style: 'normal',
    family: 'Roboto',
    lineHeight: 1.2
  },
  opacity: 1,
  textAlign: 'center',
  textShadowBlur: 0,
  textShadowColor: '#00000000',
  textStrokeColor: '#00000037',
  textStrokeWidth: 0
} as Options;

const _serieModel = {
  pointStyle: 'circle',
  pointRadius: 15,
  pointHitRadius: 15,
  pointRotation: 0,
  pointBackgroundColor: '#00000037',
  pointBorderColor: '#00000037',
  pointBorderWidth: 1,
  pointHoverRadius: 15,
  pointHoverBackgroundColor: '#00000037',
  pointHoverBorderColor: '#00000037',
  pointHoverBorderWidth: 1,
  backgroundColor: '#00000037',
  backgroundMultiGradiant: false,
  borderMultiGradiant: false,
  borderColor: '#00000037',
  borderWidth: 3,
  fill: 'origin',
  lineTension: 0.4,
  hideInLegendAndTooltip: false,
  hidden: false,
  spanGaps: false,
  steppedLine: false,
  borderDash: [],
  borderCapStyle: 'butt',
  borderJoinStyle: 'miter',
  //borderSkipped: false,
  hoverBackgroundColor: '#00000037',
  hoverBorderColor: '#00000037',
  hoverBorderWidth: 3,
  hoverBorderDash: [],
  borderAlign: 'inner',
  weight: 1,
  datalabelsID: '0',
  xAxisID: 'x0',
  yAxisID: 'y0',
  iconFrame: 'rounded-circle'
} as ChartDataSets;

const _optionsModel = {
  responsive: true,
  transitionAnimation: 'fade',
  aspectRatio: 1,
  minBarLength: 10,
  circumference: 2 * Math.PI,
  rotation: 1.5 * Math.PI,
  //startAngle: 1.5 * Math.PI,
  animation: {
    easing: 'easeOutQuart',
    duration: 1000,
    animateRotate: true,
    animateScale: true
  },
  title: {
    display: true,
    position: 'top',
    fontSize: 12,
    fontStyle: 'normal',
    fontColor: '#616161',
    fontFamily: 'Roboto',
    padding: 10,
    text: 'title',
    lineHeight: 1.2
  },
  tooltips: {
    enabled: true,
    mode: 'nearest',
    intersect: true,
    position: 'average',
    backgroundColor: '#000000CD',
    titleAlign: 'left',
    titleFontColor: '#FFFFFF',
    titleFontSize: 12,
    titleFontStyle: 'bold',
    titleFontFamily: 'Roboto',
    titleMarginBottom: 6,
    titleSpacing: 2,
    bodyAlign: 'left',
    bodyFontColor: '#FFFFFF',
    bodyFontSize: 12,
    bodyFontStyle: 'normal',
    bodyFontFamily: 'Roboto',
    bodySpacing: 2,
    xPadding: 6,
    yPadding: 6,
    caretPadding: 2,
    caretSize: 5,
    cornerRadius: 6,
    multiKeyBackground: '#FFFFFFFF',
    displayColors: true,
    borderColor: '#00000000',
    borderWidth: 0
  },
  legend: {
    position: 'top',
    display: true,
    fullWidth: true,
    reverse: false,
    labels: {
      boxWidth: 40,
      fontSize: 12,
      fontStyle: 'normal',
      fontColor: '#616161',
      fontFamily: 'Roboto',
      padding: 10,
      usePointStyle: false
    }
  },
  layout: {
    backgroundColor: '#FFFFFFFF',
    padding: {
      top: 0,
      bottom: 0,
      right: 0,
      left: 0
    }
  },
  gauge: {
    gaugeColor: '#cfd8dc',
    gaugeWidthScale: 1,
    pointer: false,
    pointerOptions: {
      toplength: -15,
      bottomlength: 10,
      bottomwidth: 12,
      stroke: '#ffffff',
      stroke_width: 2,
      stroke_linecap: 'round',
      color: '#8e8e93'
    },
    shadowOpacity: 0.2,
    shadowSize: 5,
    shadowVerticalOffset: 3,
    showInnerShadow: false
  }
} as ChartOptions;