/**
 * @module Resource
 */

import { ResourcesModel } from '@models/resources.model';
import { MapClass } from '@class/map.class';
import { UID } from '@models/uid.model';
import { ResourceType } from '@models/resources.model';
import { ModificationClass, ModificationStateClass } from '@class/modification.class';
import { DeepCopy } from '@functions/copy.functions';
import { DeleteFieldValue } from '@functions/firebase.functions';
import { ResourcesModelConstructor } from '@functions/resource.functions';

export class ResourcesClass extends ModificationClass<ResourcesModel> {

  protected _parent: ResourcesModel;
  protected _inital: ResourcesModel;
  protected _attributes: string[];

  private _resources: MapClass<MapClass<MapClass<boolean>>>;//par type, puis par resource, puis par right

  constructor(resources: ResourcesModel, state: ModificationStateClass) {

    super();
    
    this._parent = resources;
    this._attributes = this.attributes;
    this._state = state;

    if(this._parent.resources === undefined) this._parent.resources = ResourcesModelConstructor().resources;
    
    let _resources = {} as { [key: string]: MapClass<MapClass<boolean>>};
    for(const resource in ResourcesModelConstructor().resources) {
      let uids = {} as { [key: string]: MapClass<boolean> };
      for(const uid in this._parent.resources[resource] || {}) {
        uids[uid] = new MapClass<boolean>(this._parent.resources[resource][uid] || {});
      }
      _resources[resource] = new MapClass<MapClass<boolean>>(uids);
    }
    this._resources = new MapClass(_resources);

    this._inital = DeepCopy({ resources: this._parent.resources });

  }

  get class(): Readonly<string> {
    return 'resources';
  }

  get attributes(): string[] {
    return []; //on enregistre pas les resources (traité directement pas le serveur)
  }

  all(): Readonly<ResourceType[]> {
    return this._resources.keys as ResourceType[];
  }

  list(resource: ResourceType, uidResource?: UID): Readonly<UID[]> {
    if(uidResource === undefined) return this._resources.value(resource).keys;
    else {
      if(this._resources.value(resource).value(uidResource) === undefined) return [];
      else return this._resources.value(resource).value(uidResource).keys;
    }
  }

  has(resource: ResourceType, uidResource: UID): Readonly<boolean> {
    return this._resources.value(resource).has(uidResource);
  }

  add(resource: ResourceType, uidResource: UID, uidRight: UID = uidResource) {
    this._resources.value(resource).add(uidResource, new MapClass({ [uidRight]: true }));
    if(this._parent.resources[resource] === undefined) this._parent.resources[resource] = {};
    this._parent.resources[resource][uidResource] = this._resources.value(resource).value(uidResource).model;
    this.emit(this.attributes);
  }

  remove(resource: ResourceType, uidResource: UID, uidRight: UID = uidResource) {
    this._resources.value(resource).value(uidResource).remove(uidRight);
    if(this._resources.value(resource).value(uidResource).size === 0) {
      this._parent.resources[resource][uidResource] = DeleteFieldValue();
    }
    else {
      this._parent.resources[resource][uidResource] = this._resources.value(resource).value(uidResource).model;
    }
    this.emit(this.attributes);
  }

}