import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-theme-option-elements-chart-gauge-shadow-size',
  templateUrl: './theme-option-elements-chart-gauge-shadow-size.component.html',
  styleUrls: ['./theme-option-elements-chart-gauge-shadow-size.component.css']
})
export class ThemeOptionElementsChartGaugeShadowSizeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
