<app-widget-option [categories]="['INDICATORS']" [label]="'SERIE'">
  <div app-widget-option-content>
    <div class="btn-group" mdbDropdown>
      <a mdbDropdownToggle type="button" mdbBtn size="sm" color="light" class="dropdown-toggle waves-light z-depth-0 px-3 dropdown-light" mdbWavesEffect>
        <span class="{{color}}-text font-weight-bolder">
          <div class="app-widget-indicators-serie-color d-inline-block" [ngStyle]="{ 'background-color': themeOptions.series(serie).backgroundColor, 'border-color': themeOptions.series(serie).borderColor }"></div>
          <div class="app-widget-indicators-serie-color d-inline-block mx-1" [ngStyle]="{ 'background-color': themeOptions.series(serie).hoverBackgroundColor, 'border-color': themeOptions.series(serie).hoverBorderColor }"></div>
          {{ (widgetLayers.indicators[current].serie == -1 ? 'DEFAULT' : 'SERIE') | translate }}&nbsp;{{ widgetLayers.indicators[current].serie == -1 ? '' : serie }}</span>&nbsp;
      </a>
      <div class="dropdown-menu dropdown-dark dropdown-menu-right font-small app-drop-small-limit-5">
        <a class="dropdown-item z-depth-0" (click)="onValue(-1)">
          <div class="app-widget-indicators-serie-color d-inline-block" [ngStyle]="{ 'background-color': themeOptions.series(current).backgroundColor, 'border-color': themeOptions.series(current).borderColor }"></div>
          <div class="app-widget-indicators-serie-color d-inline-block mx-1" [ngStyle]="{ 'background-color': themeOptions.series(current).hoverBackgroundColor, 'border-color': themeOptions.series(current).hoverBorderColor }"></div>
          {{ 'DEFAULT' | translate }}
        </a>
        <a *ngFor="let value of values; let i = index" class="dropdown-item z-depth-0" (click)="onValue(i)">
          <div class="app-widget-indicators-serie-color d-inline-block" [ngStyle]="{ 'background-color': themeOptions.series(i).backgroundColor, 'border-color': themeOptions.series(i).borderColor }"></div>
          <div class="app-widget-indicators-serie-color d-inline-block mx-1" [ngStyle]="{ 'background-color': themeOptions.series(i).hoverBackgroundColor, 'border-color': themeOptions.series(i).hoverBorderColor }"></div>
          {{ 'SERIE' | translate }}&nbsp;{{ i }}
        </a>
      </div>
    </div>
  </div>
</app-widget-option>