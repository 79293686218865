/**
 * @module Widget
 */

import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ItemColor } from '@functions/item.functions';
import { WidgetLayersClass } from '@class/widgetLayers.class';
import { ThemeOptionsClass } from '@class/themeOptions.class';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-widget-indicators-serie',
  templateUrl: './widget-indicators-serie.component.html',
  styleUrls: ['./widget-indicators-serie.component.css']
})
export class WidgetIndicatorsSerieComponent implements OnInit, OnChanges {

  @Input() widgetLayers: WidgetLayersClass;
  @Input() current: number;
  @Input() themeOptions: ThemeOptionsClass;

  color = ItemColor('indicators');
  values: number[] = [];
  serie: number;
  changed$sub: Subscription;

  constructor() {}

  ngOnInit() {
    this.values = new Array(this.themeOptions.seriesCount());
    this.serie = this.widgetLayers.indicators[this.current].serie !== -1 ? this.widgetLayers.indicators[this.current].serie : this.current;

    this.changed$sub = this.themeOptions.changed$.subscribe({
      next:(isChanged) => {
        if(isChanged) this.values = new Array(this.themeOptions.seriesCount());
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.current !== undefined && ! changes.current.firstChange) {
      this.serie = this.widgetLayers.indicators[this.current].serie !== -1 ? this.widgetLayers.indicators[this.current].serie : this.current;
    }
  }

  onValue(value: number) {
    this.serie = value === -1 ? this.current : value;
    this.widgetLayers.indicators[this.current].change({ serie: value });
  }

}
