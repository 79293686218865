<div *ngIf="currentIndicator == undefined && currentLayer == undefined">
  <app-empty [action]="false" [description]="'SELECT_INDICATOR_OR_LAYER/TEXT'"></app-empty>
</div>

<div *ngIf="type == 'indicators'">
  <app-widget-studio-sub-indicators (sendDelete)="receiveDelete()"  [widgetLayers]="widgetLayers" [current]="currentIndicator" [themeOptions]="themeOptions"></app-widget-studio-sub-indicators>
</div>

<div *ngIf="type == 'layers'">
  <app-widget-studio-sub-layers [widgetLayers]="widgetLayers" [current]="currentLayer" [themeOptions]="themeOptions"></app-widget-studio-sub-layers>
</div>