/**
 * @module Dashboard
 */

import { Component, Output, EventEmitter } from '@angular/core';
import { ItemColor } from '@functions/item.functions';

@Component({
  selector: 'app-dashboard-layout-draggable',
  templateUrl: './dashboard-layout-draggable.component.html',
  styleUrls: ['./dashboard-layout-draggable.component.css']
})
export class DashboardLayoutDraggableComponent {

  @Output() sendDraggable: EventEmitter<boolean>;
  
  color = ItemColor('dashboards');
  
  constructor() {
    this.sendDraggable = new EventEmitter<boolean>();
  }

  receiveValue(isDraggable: boolean) {
    this.sendDraggable.emit(isDraggable);
  }
}
