/**
 * @module Theme
 */

import { Component, Input } from '@angular/core';
import { ItemColor } from '@functions/item.functions';
import { ThemeOptionsClass } from '@class/themeOptions.class';
import { ChartType } from '@models/chart.model';
import { ThemeOptionType } from '@models/themeStudio.model';

@Component({
  selector: 'app-theme-option-elements-yaxis-id',
  templateUrl: './theme-option-elements-yaxis-id.component.html',
  styleUrls: ['./theme-option-elements-yaxis-id.component.css']
})
export class ThemeOptionElementsYAxisIDComponent  {

  @Input() themeOptions: ThemeOptionsClass;
  @Input() serieIndex: number;

  showDetails: boolean;
  color = ItemColor('themes');
  optionType: ThemeOptionType = 'elements_yAxisID';
  values: string[];

  constructor() {
    this.showDetails = false;
  }

  ngOnInit() {
    this.values = new Array(this.themeOptions.yAxesCount()).fill('').map((value: any, index: number) => { return `y${index.toString()}`; });
  }

  onValue(value: string, type?: ChartType) {
    this.themeOptions.setSeries(this.optionType, value, this.serieIndex, type ? [type] : undefined);
  }

  receiveShowDetails(showDetails: boolean) {
    this.showDetails = showDetails;
  }

}
