/**
 * @module Pivot
 */

import { Component, Input, OnDestroy, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ItemColor, ItemIcon } from '@functions/item.functions';
import { PivotClass } from '@class/pivot.class';
import { PivotService } from '@services/pivot/pivot.service';
import { Subscription } from 'rxjs';
import { UID } from '@models/uid.model';
import { LanguageType } from '@models/language.model';
import { AuthService } from '@services/auth/auth.service';

@Component({
  selector: 'app-pivots-links-change',
  templateUrl: './pivots-links-change.component.html',
  styleUrls: ['./pivots-links-change.component.css']
})
export class PivotsLinksChangeComponent implements OnDestroy, OnChanges {

  @Input() pivot: PivotClass;
  @Input() withIcon: boolean = true;
  @Input() exclude: UID[] = [];
  @Output() sendSelected: EventEmitter<PivotClass>;

  color = ItemColor('pivots');
  icon = ItemIcon('pivots');

  pivots: PivotClass[];
  pivots$sub: Subscription;
  list: PivotClass[];
  searchLabel: string;
  language: LanguageType;
  language$sub: Subscription;

  constructor(
    private $pivots: PivotService,
    private $auth: AuthService
  ) {
    this.sendSelected = new EventEmitter<PivotClass>();
    this.pivots$sub = this.pivots$sub = this.$pivots.listPartials$().subscribe({
      next: (pivots: PivotClass[]) => {
        this.pivots = pivots;
        this.list = this.pivots.filter(p => this.exclude.indexOf(p.uid.value) === -1 || p.uid.value === this.pivot.uid.value);
      }
    });

    this.language$sub = this.$auth.language$.subscribe({
      next: (language: LanguageType) => {
        this.language = language;
      }
    });
  }

  receiveTextValue(searchLabel: string) {
    this.searchLabel = searchLabel;
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.exclude !== undefined) {
      this.list = this.pivots.filter(p => this.exclude.indexOf(p.uid.value) === -1 || p.uid.value === this.pivot.uid.value);
    }
  }

  ngOnDestroy() {
    this.pivots$sub.unsubscribe();
  }

  receiveSelected(item: { uid: UID, selected: boolean }) {
    if(item.uid !== this.pivot.uid.value)  {
      const pivot = this.list.filter((pivot: PivotClass) => { return pivot.uid.value === item.uid; })[0];
      this.sendSelected.emit(pivot);
    }
    else if(!item.selected) {
      this.sendSelected.emit(this.$pivots.create());
    }
  }

}
