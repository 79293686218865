<div class="app-no-back"></div>
<div class="d-flex justify-content-center white-text {{ color }} p-5">
  <div style="max-width: 22rem;" class="d-flex flex-column justify-content-between animated fadeInLeft">
    <h3 class="mb-3"><strong>{{ title | translate | capitalizeFirst }}</strong></h3>
    <h5 class="mb-auto">{{ text | translate | capitalizeFirst }}</h5>
    <div>
      <button *ngIf="actionAlt" (click)="onActionAlt()" mdbBtn color="white" class="z-depth-0 waves-light mx-0 mb-0 mt-2" mdbWavesEffect>
        <i class="{{ iconAlt }} mr-2 {{ colorAltText }}-text"></i><span class="{{ colorAltText }}-text">{{ actionAlt | translate }}</span>
      </button>
      <button (click)="onAction()" mdbBtn color="white" outline="true" class="z-depth-0 waves-light mr-2 ml-0 mb-0 mt-2" mdbWavesEffect>
        <i class="{{ icon }} mr-2"></i><span>{{ action | translate }}</span>
      </button>
    </div>
  </div>
  <img style="height: 255px;" src="./assets/images/begin.svg" class="animated rotateIn d-none d-md-inline ml-3">
</div>