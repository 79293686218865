/**
 * @module Widget
 */

import { Injectable } from '@angular/core';
import { WidgetClass } from '@class/widget.class';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { WidgetModel } from '@models/widget.model';
import { map, take} from 'rxjs/operators';
import { FirestoreService } from '@services/firestore/firestore.service';
import { ResourceType } from '@models/resources.model';
import { AuthService } from '@services/auth/auth.service';
import { FirebaseService } from '@services/firebase/firebase.service';
import { UidService } from '@services/uid/uid.service';
import { ItemSymbol } from '@functions/item.functions';
import { LinksModelConstructor } from '@functions/link.functions';
import { UID } from '@models/uid.model';
import { WidgetLayersClass } from '@class/widgetLayers.class';
import { WidgetLayersModel, WidgetIndicatorModel } from '@models/widgetLayers.model';
import { LayersDefault } from '@functions/widget.functions';
import { ThemeOptionsClass } from '@class/themeOptions.class';
import { ColorsService } from '@services/colors/colors.service';
import { IndicatorService } from '@services/indicator/indicator.service';
import { IndicatorClass } from '@class/indicator.class';

@Injectable({
  providedIn: 'root'
})
export class WidgetService extends FirestoreService<WidgetModel> {

  /**
   * @description type de document manipulé par le service
   * @protected
   * @type {ResourceType}
   * @memberof WidgetService
   */
  protected _itemType: ResourceType = 'widgets';

  /**
   * @description true si l'accès à ce type de document peut nécessiter des habilitations
   * @protected
   * @type {boolean}
   * @memberof WidgetService
   */
  protected _restricted: boolean = true;

  /**
   * @description Creates an instance of WidgetService
   * @param {UidService} $uid
   * @param {AuthService} $auth
   * @param {FirebaseService} $firebase
   * @memberof WidgetService
   */
  constructor(
    private $uid: UidService,
    protected $auth: AuthService,
    protected $firebase: FirebaseService,
    private $colors: ColorsService,
    private $indicators: IndicatorService
  ) {
    super($firebase, $auth);
  }

  /**
   * @description Observateur de la liste des objects autorisés pour l'utilisateur courant
   * @returns {BehaviorSubject<WidgetClass[]>}
   * @memberof WidgetService
   */
  list$(): BehaviorSubject<WidgetClass[]> {
    return super.docs$().pipe(
      map((widgets: WidgetModel[]) => {
        if(!!widgets) {
          let ds = widgets.map<WidgetClass>((widget: WidgetModel) => {
            return new WidgetClass(widget, true);
          });
          return ds;
        }
      })
    ) as BehaviorSubject<WidgetClass[]>;
  }

  /**
   * @description Observateur de la liste des objects (données partielles) autorisés pour l'utilisateur courant
   * @returns {Observable<WidgetClass[]>}
   * @memberof WidgetService
   */
  listPartials$(): Observable<WidgetClass[]> {
    return super.partials$().pipe(
      map((widgets: WidgetModel[]) => {
        if(!!widgets) {
          let ds = widgets.map<WidgetClass>((widget: WidgetModel) => {
            return new WidgetClass(widget, true);
          });
          return ds;
        }
      })
    );
  }

  /**
   * @description Observateur d'un document dont l'uid est passé en paramètre
   * @param {string} uid
   * @returns {Observable<WidgetClass>}
   * @memberof WidgetService
   */
  get$(uid: string): Observable<WidgetClass> {
    return super.doc$(uid).pipe(
      map((widget: WidgetModel) => {
        return new WidgetClass(widget, true);
      })
    );
  }

  /**
   * @description Observateur d'un document (données partielles) dont l'uid est passé en paramètre
   * @param {string} uid
   * @returns {Observable<WidgetClass>}
   * @memberof WidgetService
   */
  getPartial$(uid: string): Observable<WidgetClass> {
    return super.partial$(uid).pipe(
      map((widget: WidgetModel) => {
        if(widget !== null) return new WidgetClass(widget, true);
      })
    );
  }

  /**
   * @description Observateur d'un document d'une sous-collection dont le widget est passé en paramètre
   * @param {WidgetClass} widget
   * @param {ThemeOptionsClass} themeOptions
   * @returns {Observable<WidgetLayersClass>}
   * @memberof WidgetService
   */
  getLayers$(widget: WidgetClass, themeOptions: ThemeOptionsClass): Observable<WidgetLayersClass> {
    return super.docSub$(widget.uid.value, 'layers').pipe(
      map((layers: WidgetLayersModel) => {
        if(layers === undefined) layers = LayersDefault(widget);
        const widgetLayers = new WidgetLayersClass(layers, themeOptions, this.$colors);
        widgetLayers.offsetDateDefault = this.$auth.offsetDate$.value;
        //en asynchrone on vérifie les indicateurs et pivots/dates
        layers.indicators.forEach((indicator: WidgetIndicatorModel) => {
          combineLatest(this.$indicators.getPartial$(indicator.uid)).pipe(take(1)).subscribe((indicators: IndicatorClass[]) => {
            let isModified = false;
            indicators.forEach((i: IndicatorClass) => {
              const links = {};
              i.links.list('pivots').forEach((uid: UID) => {
                links[uid] = 'pivots';
              });
              i.links.list('dates').forEach((uid: UID) => {
                links[uid] = 'dates';
              });
              isModified = widgetLayers.addIndicator(indicator, links) || isModified;
            });
            if(isModified) widgetLayers.reset(widgetLayers.model, widgetLayers.themeOptions);
          });
        }); 
      
        return widgetLayers;
      })
    );
  }

  /**
   * @description Créé un nouveau document avec les valeurs par défaut
   * @returns {WidgetClass}
   * @memberof WidgetService
   */
  create(): WidgetClass {
    return new WidgetClass({ 
      uid: this.$uid.create(),
      icon: {
        symbol: ItemSymbol(this._itemType),
        style: 'r',
        options: []
      },
      color: {
        hexa: '8bc34a'
      },
      links: LinksModelConstructor().links,
      logs: {},
      tags: {},
      readers: {},
      writers: {},
      share: 's'
    } as WidgetModel, false);
  }

  /**
   * @description Créé ou met à jour le document passé en paramètre
   * @param {WidgetClass} widget
   * @returns {Promise<void>}
   * @memberof WidgetService
   */
  async set(widget: WidgetClass): Promise<void> {
    return super.update(widget.uid.value, widget.model);
  }

  /**
   * @description Créé ou met à jour le sous-document à partir de l'uid du document passé en paramètre
   * @param {UID} uid
   * @param {WidgetLayersClass} layers
   * @returns {Promise<void>}
   * @memberof WidgetService
   */
  async setLayers(uid: UID, layers: WidgetLayersClass): Promise<void> {
    return super.updateSub(uid, layers.model, 'layers', false);
  }

  /**
   * @description Supprime le document passé en paramètre
   * @param {string} uid
   * @returns {Promise<void>}
   * @memberof WidgetService
   */
  async remove(uid: string): Promise<void> {
    return super.delete(uid);
  }

}
