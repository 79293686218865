<app-browser-frame #frameModal (sendCreate)="receiveCreate()" (sendSearch)="receiveSearch($event)" [color]="color" [icon]="icon" [label]="label">

  <div app-browser-frame-content-options>

  </div>

  <app-empty app-browser-frame-content-list [type]="'users'" (sendAction)="receiveCreate()" *ngIf="users != undefined && users.length == 0"></app-empty>
  <app-loading app-browser-frame-content-list *ngIf="users == undefined"></app-loading>
  
  <div app-browser-frame-content-list *ngFor="let user of users | searchUser:searchLabel ; trackBy: trackByUid">
    <app-item-strip
    [tags]="user.tags"
    [label]="user.id.fullname"
    [url]="user.avatar.url"
    [type]="'users'"
    [uid]="user.uid"
    [icon]="icon"
    [color]="color"
    [isModified]="user.modifications$.value.isModified"
    (sendEdit)="receiveEdit($event)">
      <div app-item-strip-content-description>
        <div *ngIf="user.hasConnectedOnce && user.activated" class="text-muted font-extra-small mb-1"><i class="fas fa-user-check fa-fw mr-1 text-success"></i>{{ 'LAST_CONNECTION' | translate }}: {{ user.lastConnection() }}<br>({{ user.lastConnection('fromNow') }})</div>
        <div *ngIf="!user.hasConnectedOnce" class="text-muted font-extra-small mb-1"><i class="fas fa-user-times fa-fw mr-1 text-warning"></i>{{ 'NEVER_CONNECTED' | translate }}<!--<br>({{ 'DELETED_IN_DAYS' | translate:{ days: user.deletionDuration }  }})--></div>
        <div *ngIf="user.hasConnectedOnce && !user.activated" class="text-muted font-extra-small mb-1"><i class="fas fa-user-lock fa-fw mr-1 text-danger"></i>{{ 'ACCOUNT_DISABLED' | translate }}<!--<br>({{ 'DELETED_IN_DAYS' | translate:{ days: user.deletionDuration }  }})--></div>
      </div>
    </app-item-strip>
  </div>
</app-browser-frame>

<app-editor #editor (sendRestore)="receiveRestore()" (sendDelete)="receiveDelete()" (sendSave)="receiveSave()"
  [title]="current.id.fullname" [uid]="current.uid.value" [icon]="icon" [color]="color" [isRegistered]="current.uid.isRegistered" [isValid]="current.isValid()" [isModified]="current.modifications$.value.isModified">
  <div app-editor-content-menu>

    <a class="dropdown-item z-depth-0" (click)="editorIndex = 0">{{ 'PERSONAL_DATA' | translate }}</a>
    <a class="dropdown-item z-depth-0" (click)="editorIndex = 1">{{ 'ACCOUNT' | translate }}</a>
    <a class="dropdown-item z-depth-0" (click)="editorIndex = 2">{{ 'ROLES' | translate }}</a>
    <a class="dropdown-item z-depth-0" (click)="editorIndex = 3">{{ 'RIGHTS' | translate }}</a>
    <a class="dropdown-item z-depth-0" (click)="editorIndex = 4">{{ 'FILTERS' | translate }}</a>
    <a class="dropdown-item z-depth-0" (click)="editorIndex = 5">{{ 'TAGS' | translate }}</a>

  </div>
  <div app-editor-content-body *ngIf="editor.isShown">
    <div [ngSwitch]="editorIndex">
      <div *ngSwitchCase="0">
        <app-editor-section-title [title]="'PERSONAL_DATA'" (sendNext)="receiveNext($event)"></app-editor-section-title>
        <app-user-id [userId]="current.id"></app-user-id>
      </div>
      <div *ngSwitchCase="1">
        <app-editor-section-title [title]="'ACCOUNT'" (sendNext)="receiveNext($event)"></app-editor-section-title>
        <app-language [user]="current" class="d-block mt-2"></app-language>
        <app-avatar [user]="current" class="d-block mt-2"></app-avatar>
        <app-email-reset [user]="current" class="d-block mt-2"></app-email-reset>
        <app-activation [user]="current" class="d-block mt-2"></app-activation>
      </div>
      <div *ngSwitchCase="2">
        <app-editor-section-title [title]="'ROLES'" (sendNext)="receiveNext($event)"></app-editor-section-title>
        <app-roles [roles]="current.roles"></app-roles>
      </div>
      <div *ngSwitchCase="3">
        <app-editor-section-title [title]="'RIGHTS'" (sendNext)="receiveNext($event)"></app-editor-section-title>
        <app-groups [groups]="current.groups"></app-groups>
      </div>
      <div *ngSwitchCase="4" style="min-height: 500px;">
        <app-editor-section-title [title]="'FILTERS'" (sendNext)="receiveNext($event)"></app-editor-section-title>
        <app-user-filters [user]="current"></app-user-filters>
      </div>
      <div *ngSwitchCase="5">
        <app-editor-section-title [title]="'TAGS'" (sendNext)="receiveNext($event)"></app-editor-section-title>
        <app-tags-links-list [tags]="current.tags"></app-tags-links-list>
      </div>
      <div *ngSwitchDefault>
        <app-delete [code]="current.uid.code" (sendDelete)="receiveDeleteConfirmation($event)"></app-delete>
      </div>
    </div>
  </div>
</app-editor>