<app-theme-option [categories]="themeOptions.optionsConfiguration[optionType].categories" [label]="themeOptions.optionsConfiguration[optionType].label" [information]="'PIXELS'" [dark]="4">
  <div app-theme-option-content>
    <app-show-details-option [showDetails]="showDetails" (sendShowDetails)="receiveShowDetails($event)">
      <div app-show-details-option-content>
        <app-number [value]="themeOptions.datalabels(datalabelsIndex).padding['top']" (sendValue)="receiveValue($event)" [color]="color" [min]="0" [max]="50"></app-number>
      </div>
    </app-show-details-option>
  </div>
</app-theme-option>

<div *ngIf="showDetails">
  <app-theme-option [categories]="themeOptions.optionsConfiguration[optionType].categoriesAndLabel" [label]="type | chart" [information]="'PIXELS'" [dark]="3" *ngFor="let type of themeOptions.limitedTo(optionType)">
    <div app-theme-option-content>
      <app-number [value]="themeOptions.datalabels(datalabelsIndex, type).padding['top']" (sendValue)="receiveValue($event, type)" [color]="color" [min]="0" [max]="50"></app-number>
    </div>
  </app-theme-option>
</div>