/**
 * @module Pivot
 */

import { PivotModel } from '@models/pivot.model';
import { DescriptionsModel } from '@models/descriptions.model';
import { LabelsModel } from '@models/labels.model';
import { UidModel } from '@models/uid.model';
import { LinksModel } from '@models/links.model';
import { LogsModel } from '@models/logs.model';
import { ModificationClass } from '@class/modification.class';
import { UidClass } from '@class/uid.class';
import { LabelsClass } from '@class/labels.class';
import { DescriptionsClass } from '@class/descriptions.class';
import { LinksClass } from '@class/links.class';
import { LogsClass } from '@class/logs.class';
import { TagsModel } from '@models/tag.model';
import { TagsClass } from '@class/tag.class';
import { DeepCopy } from '@functions/copy.functions';
import { LinkedColumnsModel } from '@models/linkedColumns.model';
import { LinkedColumnsClass } from '@class/linkedColumns.class';

export class PivotClass extends ModificationClass<PivotModel> {
  
  private _uid: UidClass;
  private _labels: LabelsClass;
  private _descriptions: DescriptionsClass;
  private _links: LinksClass;
  private _logs: LogsClass;
  private _tags: TagsClass;
  private _linkedColumns: LinkedColumnsClass;

  protected _parent: PivotModel;
  protected _inital: PivotModel;
  protected _attributes: string[];

  private _isRegistered: boolean;

  constructor(pivot: PivotModel, isRegistered: boolean = true) {

    super();

    this._parent = pivot;
    this._isRegistered = isRegistered;

    this._reset(this._parent);

    this._attributes = this.attributes.slice();
    this._attributes.push(...this._labels.attributes);
    this._attributes.push(...this._descriptions.attributes);
    this._attributes.push(...this._uid.attributes);
    this._attributes.push(...this._links.attributes);
    this._attributes.push(...this._logs.attributes);
    this._attributes.push(...this._tags.attributes);
    this._attributes.push(...this._linkedColumns.attributes);

  }

  private _reset(pivot: PivotModel) {
    this._uid = new UidClass(pivot as UidModel, this._isRegistered);
    this._labels = new LabelsClass(pivot as LabelsModel, this._form, this._requirements, this._state);
    this._descriptions = new DescriptionsClass(pivot as DescriptionsModel, this._form, this._requirements, this._state);
    this._links = new LinksClass(pivot as LinksModel, this._state);
    this._logs = new LogsClass(pivot as LogsModel, this._state);
    this._tags = new TagsClass(pivot as TagsModel, this._state);
    this._linkedColumns = new LinkedColumnsClass(pivot as LinkedColumnsModel, this._state);

    this.attributes.forEach((attribute: string) => {
      this._parent[attribute] = pivot[attribute];
    });

    this._inital = DeepCopy(pivot);

    this._state.flush();
    this._modifications$.next(this._state);
  }

  reset() {
    this._state.flush();
    this._reset(this._inital);
  }

  get attributes(): Readonly<string[]> {
    return [];
  }

  get class(): string {
    return 'pivot';
  }

  get uid(): UidClass {
    return this._uid;
  }

  get labels(): LabelsClass {
    return this._labels;
  }

  get descriptions(): DescriptionsClass {
    return this._descriptions;
  }

  get links(): LinksClass {
    return this._links;
  }

  get logs(): LogsClass {
    return this._logs;
  }

  get tags(): TagsClass {
    return this._tags;
  }

  get linkedColumns(): LinkedColumnsClass {
    return this._linkedColumns;
  }

}