/**
 * @module Theme
 */

import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-theme-option-font-family',
  templateUrl: './theme-option-font-family.component.html',
  styleUrls: ['./theme-option-font-family.component.css']
})
export class ThemeOptionFontFamilyComponent {

  @Input() fontFamily: string;
  @Input() color: string;
  @Output() sendFontFamily: EventEmitter<string>;

  constructor() {
    this.sendFontFamily = new EventEmitter<string>();
  }

  onFontFamily(fontFamily: string) {
    this.sendFontFamily.emit(fontFamily)
  }

  fonts = [
    'Merriweather',
    'Playfair Display',
    'Montserrat',
    'Roboto',
    'Roboto Mono',
    'Lobster',
    'Fredoka One',
    'Bangers',
    'Caveat',
    'Righteous'
  ];

}
