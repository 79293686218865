/**
 * @module Form
 */

import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { ColorClass } from '@class/color.class';
import { ModificationStateClass } from '@class/modification.class';
import { Brightness } from '@functions/color.functions';
import { BrightnessType } from '@models/color.model';

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.css']
})
export class ColorPickerComponent implements OnInit {

  @Input() color: string;
  @Input() itemColor: string;
  @Output() sendColor: EventEmitter<string>;

  _color: ColorClass;
  _hexa: string;
  _brightness: BrightnessType;

  constructor() {
    this.sendColor = new EventEmitter<string>();
  }

  ngOnInit() {
    this._color = new ColorClass({ color: { hexa: this.color.replace('#', '') } }, new ModificationStateClass());
    this._brightness = Brightness(this._color.hexa);
    this._color.modifications$.subscribe({
      next:(modifications: ModificationStateClass) => {
        if(modifications.isModified) this._hexa = this._color.hexa;
      }
    });
  }

  onSelect() {
    this.color = this._hexa;
    this._brightness = Brightness(this._color.hexa);
    this.sendColor.emit(this.color);
  }

}
