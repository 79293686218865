/**
 * @module Import
 */

import { Component, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { ItemIcon, ItemColor } from '@functions/item.functions';
import { ImportFrameUploadModel } from '@models/importFrame.model';
import { AuthService } from '@services/auth/auth.service';
import { LanguageType } from '@models/language.model';
import { Subscription } from 'rxjs';
import { BookType } from 'xlsx/types';
import { ImportFrameClass } from '@class/importFrame.class';

@Component({
  selector: 'app-import-uploading',
  templateUrl: './import-uploading.component.html',
  styleUrls: ['./import-uploading.component.css']
})
export class ImportUploadingComponent implements OnDestroy {

  @Input() importFrame: ImportFrameClass;
  @Input() upload: ImportFrameUploadModel;
  @Output() sendRetry: EventEmitter<void>;

  icon = ItemIcon('indicators');
  color = ItemColor('indicators');
  language: LanguageType;
  language$sub: Subscription;

  constructor(
    private $auth: AuthService
  ) {
    this.sendRetry = new EventEmitter<void>();

    this.language$sub = this.$auth.language$.subscribe({
      next: (language: LanguageType) => {
        this.language = language;
      }
    });
  }

  ngOnDestroy() {
    this.language$sub.unsubscribe();
  }

  receiveFileType(fileType: BookType) {
    this.importFrame.download(this.language, fileType, true);
  }

  onRetry() {
    this.sendRetry.emit();
  }

}
