/**
 * @module DateFormat
 */

import { UID } from '@models/uid.model';
import { ModificationClass, ModificationStateClass } from '@class/modification.class';
import { DeepCopy } from '@functions/copy.functions';
import { DateFormatsModel } from '@models/dateFormats.model';

export class DateFormatsClass extends ModificationClass<DateFormatsModel> {

  protected _parent: DateFormatsModel;
  protected _inital: DateFormatsModel;
  protected _attributes: string[];

  constructor(dateFormats: DateFormatsModel, state: ModificationStateClass) {

    super();
    
    this._parent = dateFormats;
    this._attributes = this.attributes;
    this._state = state;

    this._inital = DeepCopy({ dateFormats: this._parent.dateFormats });

  }

  get class(): Readonly<string> {
    return 'dateFormats';
  }

  get attributes(): string[] {
    return ['dateFormats'];
  }

  get noDateFormats(): Readonly<boolean> {
    return this._parent.dateFormats.length === 0;
  }

  get hasDateFormats(): Readonly<boolean> {
    return this._parent.dateFormats.length > 0;
  }

  list(): Readonly<UID[]> {
    return this._parent.dateFormats;
  }

  has(dateFormat: string): Readonly<boolean> {
    return this._parent.dateFormats.includes(dateFormat);
  }

  add(dateFormat: string) {
    if(!this.has(dateFormat)) {
      this._parent.dateFormats.push(dateFormat);
      this.emit(this.attributes);
    }
  }

  remove(dateFormat: string) {
    const index = this._parent.dateFormats.indexOf(dateFormat);
    if(index !== -1) {
      this._parent.dateFormats.splice(index, 1);
      this.emit(this.attributes);
    }
  }

}