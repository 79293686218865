/** 
 * @module Theme 
 */

import { Component, } from '@angular/core';
import { ItemColor } from '@functions/item.functions';
import { ThemeStudioService } from '@services/themeStudio/theme-studio.service';

@Component({
  selector: 'app-theme-studio-values',
  templateUrl: './theme-studio-values.component.html',
  styleUrls: ['./theme-studio-values.component.css']
})
export class ThemeStudioValuesComponent {

  color = ItemColor('themes');

  constructor(
    public $studio: ThemeStudioService
  ) {}

  receiveValuePerSeries(values: number) {
    this.$studio.valuesPerSeries = values;
  }

}
