/**
 * @Module Bookmarks
 */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bookmarks',
  pure: false
})
export class BookmarksPipe implements PipeTransform {

  transform(items: any[], bookmarks: string[]): any {
    if(items !== undefined && items !== undefined && bookmarks.length > 0) {
      return items.filter(i => bookmarks.indexOf(i['uid']['value']) !== -1);
    }
    else {
      return items;
    }
  }

}
