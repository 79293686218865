/**
 * @module Color
 */

import { ColorModel } from '@models/color.model';
import { ModificationStateClass, ModificationClass } from '@class/modification.class';
import { DeepCopy } from '@functions/copy.functions';

export class ColorClass extends ModificationClass<ColorModel> {

  protected _parent: ColorModel;
  protected _inital: ColorModel;
  protected _attributes: string[];

  constructor(color: ColorModel, state: ModificationStateClass) {

    super();
    
    this._parent = color;
    this._attributes = this.attributes;
    this._state = state;

    this._inital = DeepCopy({ color: this._parent.color });
  }

  get class(): Readonly<string> {
    return 'color';
  }

  get hexa(): Readonly<string> {
    return `#${this._parent.color.hexa}`;
  }

  get attributes(): string[] {
    return ['color'];
  }

}