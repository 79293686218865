<app-dashboard-option [categories]="['CELL']" [label]="'WIDGET'">
  <div app-dashboard-option-content>
   <button mdbBtn (click)="widgetsModal.show()" type="button" color="light" size="sm" class="px-2 z-depth-0 m-0" mdbWavesEffect>
      <i class="fas fa-{{ icon }} fa-fw {{ colorDash }}-text"></i>
    </button>
  </div>
</app-dashboard-option>
<div class="d-flex py-1 px-3 align-items-center" *ngIf="cell.uidWidget?.length > 0">
  <h6 class="text-muted mb-0 mr-auto"><i class="fas fa-{{ icon }} fa-fw mr-2"></i>{{ label?.value(language) }}</h6>
  <button mdbBtn (click)="openWidget()" type="button" color="light" size="sm" class="px-2 z-depth-0 m-0" mdbWavesEffect>
    <i class="fas fa-drafting-compass fa-fw {{ colorDash }}-text"></i>
  </button>
</div>
<hr>

<div mdbModal #widgetsModal="mdbModal" class="modal fade" style="overflow-y: scroll" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header d-flex align-items-center flex-column">

        <i class="animated rotateIn {{ color }}-text far fa-{{ icon }} fa-4x d-none d-sm-inline"></i>
        <i class="animated rotateIn {{ color }}-text far fa-{{ icon }} fa-2x d-sm-none"></i>

        <h6 class="modal-title w-100 text-center">
          <div class="font-weight-bold text-elegant mt-1">{{ 'WIDGETS' | translate }}</div>
        </h6>

        <button type="button" size="sm" mdbBtn color="secondary" class="waves-light z-depth-0 px-2 m-0 app-close-modal" (click)="widgetsModal.hide()" mdbWavesEffect>
          <i class="fas fa-times fa-fw"></i>
        </button>

      </div>
      <div class="modal-body overflow-hidden" *ngIf="widgetsModal.isShown">
        
        <app-widgets-links-change [uidWidget]="cell.uidWidget" (sendSelected)="receiveSelected($event);widgetsModal.hide();"></app-widgets-links-change>

      </div>
    </div>
  </div>
</div>