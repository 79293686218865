/**
 * @module Server
 */

import { ServerModel, ServerLinkModel, ServerUrlModel } from '@models/server.model';
import { DescriptionsModel } from '@models/descriptions.model';
import { LabelsModel } from '@models/labels.model';
import { UidModel, UID } from '@models/uid.model';
import { LinksModel } from '@models/links.model';
import { LogsModel } from '@models/logs.model';
import { ModificationClass, ModificationStateClass } from '@class/modification.class';
import { UidClass } from '@class/uid.class';
import { LabelsClass } from '@class/labels.class';
import { DescriptionsClass } from '@class/descriptions.class';
import { LinksClass } from '@class/links.class';
import { LogsClass } from '@class/logs.class';
import { ShareModel } from '@models/share.model';
import { ShareClass } from '@class/share.class';
import { TagsModel } from '@models/tag.model';
import { TagsClass } from '@class/tag.class';
import { DeepCopy } from '@functions/copy.functions';
import { Validators, FormControl } from '@angular/forms';

export class ServerClass extends ModificationClass<ServerModel> {
  
  private _uid: UidClass;
  private _labels: LabelsClass;
  private _descriptions: DescriptionsClass;
  private _links: LinksClass;
  private _logs: LogsClass;
  private _share: ShareClass;
  private _tags: TagsClass;

  protected _parent: ServerModel;
  protected _inital: ServerModel;
  protected _attributes: string[];

  private _isRegistered: boolean;

  constructor(server: ServerModel, isRegistered: boolean = true) {

    super();

    this._parent = server;
    this._isRegistered = isRegistered;

    Object.assign(this._requirements, {
      url: {
        maxLength: 150,
        minLength: 1
      }
    });

    this._form.addControl('url', new FormControl(this._parent.url, [ 
      Validators.required, 
      Validators.maxLength(this._requirements.url.maxLength), 
      Validators.minLength(this._requirements.url.minLength) 
    ]));

    this._reset(this._parent);

    this._attributes = this.attributes.slice();
    this._attributes.push(...this._labels.attributes);
    this._attributes.push(...this._descriptions.attributes);
    this._attributes.push(...this._uid.attributes);
    this._attributes.push(...this._links.attributes);
    this._attributes.push(...this._logs.attributes);
    this._attributes.push(...this._share.attributes);
    this._attributes.push(...this._tags.attributes);

  }

  private _reset(server: ServerModel) {
    this._uid = new UidClass(server as UidModel, this._isRegistered);
    this._labels = new LabelsClass(server as LabelsModel, this._form, this._requirements, this._state);
    this._descriptions = new DescriptionsClass(server as DescriptionsModel, this._form, this._requirements, this._state);
    this._links = new LinksClass(server as LinksModel, this._state);
    this._logs = new LogsClass(server as LogsModel, this._state);
    this._share = new ShareClass(server as ShareModel, this._state);
    this._tags = new TagsClass(server as TagsModel, this._state);

    this.attributes.forEach((attribute: string) => {
      this._parent[attribute] = server[attribute];
    });

    server.main = false; //on impose false car la valeur true est déduite de l'uid stocké dans firebase sous 'main/server'

    this._inital = DeepCopy(server);

    this._state.flush();
    this._modifications$.next(this._state);
  }

  reset() {
    this._state.flush();
    this._reset(this._inital);
  }

  get attributes(): Readonly<string[]> {
    return ['url', 'main'];
  }

  get class(): string {
    return 'server';
  }

  get uid(): UidClass {
    return this._uid;
  }

  get labels(): LabelsClass {
    return this._labels;
  }

  get descriptions(): DescriptionsClass {
    return this._descriptions;
  }

  get links(): LinksClass {
    return this._links;
  }

  get logs(): LogsClass {
    return this._logs;
  }

  get share(): ShareClass {
    return this._share;
  }

  get tags(): TagsClass {
    return this._tags;
  }

  get url(): Readonly<string> {
    return this._parent.url;
  }

  get main(): Readonly<boolean> {
    return this._parent.main;
  }

}

export class ServerLinkClass extends ModificationClass<ServerLinkModel> {

  protected _parent: ServerLinkModel;
  protected _inital: ServerLinkModel;
  protected _attributes: string[];

  constructor(serverLink: ServerLinkModel, state: ModificationStateClass) {

    super();
    
    this._parent = serverLink;
    this._attributes = this.attributes;
    this._state = state;

    this._inital = DeepCopy({ serverLink: this._parent.serverLink });
  }

  get class(): Readonly<string> {
    return 'serverLink';
  }

  get uid(): Readonly<UID> {
    return this._parent.serverLink.uid;
  }

  get url(): Readonly<string> {
    return this._parent.serverLink.url;
  }

  get attributes(): string[] {
    return ['serverLink'];
  }

}