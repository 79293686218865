/**
 * @module Date
 */

import { DateModel } from '@models/date.model';
import { DescriptionsModel } from '@models/descriptions.model';
import { LabelsModel } from '@models/labels.model';
import { UidModel } from '@models/uid.model';
import { LinksModel } from '@models/links.model';
import { LogsModel } from '@models/logs.model';
import { ModificationClass } from '@class/modification.class';
import { UidClass } from '@class/uid.class';
import { LabelsClass } from '@class/labels.class';
import { DescriptionsClass } from '@class/descriptions.class';
import { LinksClass } from '@class/links.class';
import { LogsClass } from '@class/logs.class';
import { TagsModel } from '@models/tag.model';
import { TagsClass } from '@class/tag.class';
import { DeepCopy } from '@functions/copy.functions';
import { LinkedColumnsModel } from '@models/linkedColumns.model';
import { LinkedColumnsClass } from '@class/linkedColumns.class';
import { DateFormatsModel } from '@models/dateFormats.model';
import { DateFormatsClass } from '@class/dateFormats.class';

export class DateClass extends ModificationClass<DateModel> {
  
  private _uid: UidClass;
  private _labels: LabelsClass;
  private _descriptions: DescriptionsClass;
  private _links: LinksClass;
  private _logs: LogsClass;
  private _tags: TagsClass;
  private _linkedColumns: LinkedColumnsClass;
  private _dateFormats: DateFormatsClass;

  protected _parent: DateModel;
  protected _inital: DateModel;
  protected _attributes: string[];

  private _isRegistered: boolean;

  constructor(date: DateModel, isRegistered: boolean = true) {

    super();

    this._parent = date;
    this._isRegistered = isRegistered;

    this._reset(this._parent);

    this._attributes = this.attributes.slice();
    this._attributes.push(...this._labels.attributes);
    this._attributes.push(...this._descriptions.attributes);
    this._attributes.push(...this._uid.attributes);
    this._attributes.push(...this._links.attributes);
    this._attributes.push(...this._logs.attributes);
    this._attributes.push(...this._tags.attributes);
    this._attributes.push(...this._linkedColumns.attributes);
    this._attributes.push(...this._dateFormats.attributes);

  }

  private _reset(date: DateModel) {
    this._uid = new UidClass(date as UidModel, this._isRegistered);
    this._labels = new LabelsClass(date as LabelsModel, this._form, this._requirements, this._state);
    this._descriptions = new DescriptionsClass(date as DescriptionsModel, this._form, this._requirements, this._state);
    this._links = new LinksClass(date as LinksModel, this._state);
    this._logs = new LogsClass(date as LogsModel, this._state);
    this._tags = new TagsClass(date as TagsModel, this._state);
    this._linkedColumns = new LinkedColumnsClass(date as LinkedColumnsModel, this._state);
    this._dateFormats = new DateFormatsClass(date as DateFormatsModel, this._state);

    this.attributes.forEach((attribute: string) => {
      this._parent[attribute] = date[attribute];
    });

    this._inital = DeepCopy(date);

    this._state.flush();
    this._modifications$.next(this._state);
  }

  reset() {
    this._state.flush();
    this._reset(this._inital);
  }

  get attributes(): Readonly<string[]> {
    return [];
  }

  get class(): string {
    return 'date';
  }

  get uid(): UidClass {
    return this._uid;
  }

  get labels(): LabelsClass {
    return this._labels;
  }

  get descriptions(): DescriptionsClass {
    return this._descriptions;
  }

  get links(): LinksClass {
    return this._links;
  }

  get logs(): LogsClass {
    return this._logs;
  }

  get tags(): TagsClass {
    return this._tags;
  }

  get linkedColumns(): LinkedColumnsClass {
    return this._linkedColumns;
  }

  get dateFormats(): DateFormatsClass {
    return this._dateFormats;
  }

}