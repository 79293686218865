/**
 * @module Widget
 */

import { Component, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { WidgetLayersClass } from '@class/widgetLayers.class';
import { WidgetLayerClass } from '@class/widgetLayer.class';
import { ItemColor } from '@functions/item.functions';
import { LayerIcon } from '@functions/widget.functions';
import { UID } from '@models/uid.model';
import { LanguageType } from '@models/language.model';
import { Subscription } from 'rxjs';
import { AuthService } from '@services/auth/auth.service';

@Component({
  selector: 'app-widget-layers-item-main',
  templateUrl: './widget-layers-item-main.component.html',
  styleUrls: ['./widget-layers-item-main.component.css']
})
export class WidgetLayersItemMainComponent implements OnDestroy {

  @Input() widgetLayers: WidgetLayersClass;
  @Input() layer: WidgetLayerClass;
  @Input() selected: boolean;
  @Output() sendSelected: EventEmitter<UID>;
  @Output() sendEdited: EventEmitter<UID>;

  color = ItemColor('widgets');
  icon = LayerIcon();

  language: LanguageType;
  language$sub: Subscription;

  constructor(
    private $auth: AuthService
  ) {
    this.sendSelected = new EventEmitter<UID>();
    this.sendEdited = new EventEmitter<UID>();

    this.language$sub = this.$auth.language$.subscribe({
      next: (language: LanguageType) => {
        this.language = language;
      }
    });
  }

  ngOnDestroy() {
    this.language$sub.unsubscribe();
  }

  onEdit() {
    this.sendEdited.emit(this.layer.uid.value);
  }

  receiveSelected() {
    this.sendSelected.emit(this.layer.uid.value);
  }
}