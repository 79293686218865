/**
 * @module Shared
 */

/**
 * @description renvoie la moyenne d'une serie de valeur
 * @export
 * @param {number[]} values
 * @returns {number}
 */
export function Average(values: number[]): number {
  const _values = values.filter(v => v !== undefined && v !== Infinity);
  if(_values.length === 0) return NaN;
  return _values.reduce((p: number, c: number) => p + c, 0) / _values.length;
}

/**
 * @description renvoie le total d'une serie de valeur
 * @export
 * @param {number[]} values
 * @returns {number}
 */
export function Total(values: number[]): number {
  const _values = values.filter(v => v !== undefined && v !== Infinity);
  if(_values.length === 0) return NaN;
  return _values.reduce((p: number, c: number) => p + c, 0);
}

/**
 * @description renvoie le minimum d'une serie de valeur
 * @export
 * @param {number[]} values
 * @returns {number}
 */
export function Minimum(values: number[]): number {
  const _values = values.filter(v => v !== undefined && v !== Infinity);
  if(_values.length === 0) return NaN;
  return Math.min(..._values);
}

/**
 * @description renvoie le maximum d'une serie de valeur
 * @export
 * @param {number[]} values
 * @returns {number}
 */
export function Maximum(values: number[]): number {
  const _values = values.filter(v => v !== undefined && v !== Infinity);
  if(_values.length === 0) return NaN;
  return Math.max(..._values);
}

/**
 * @description renvoie le nombre de valeur strictement différentes (les majuscules/minuscules comptent)
 * @export
 * @param {any[]} values
 * @returns {number}
 */
export function CountUnique(values: (number | string)[]): number {
  return new Set(values).size;
}