/**
 * @module Browser
 */

import { Component } from '@angular/core';
import { ItemType } from '@models/item.model';

@Component({
  selector: 'app-browser',
  templateUrl: './browser.component.html',
  styleUrls: ['./browser.component.css']
})
export class BrowserComponent {

  panel: ItemType;

  constructor() {}

  receivePanel(panel: ItemType) {
    this.panel = panel;
  }

}
