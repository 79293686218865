/**
 * @Module Labels
 */

import { Pipe, PipeTransform } from '@angular/core';
import { LabelsClass } from '@class/labels.class';
import { LanguageType } from '@models/language.model';

@Pipe({
  name: 'searchLabels',
  pure: false
})
export class SearchLabelsPipe implements PipeTransform {

  transform(items: { labels: LabelsClass }[], searchLabel: string, language: LanguageType): any {
    if(!items) {
      return [];
    }
    if(!searchLabel || searchLabel.length === 0) {
      return items;
    }
    const _searchLabel = searchLabel.toLowerCase();
    return items.filter((item: { labels: LabelsClass }) => {
      return item.labels.value(language).toLowerCase().includes(_searchLabel);
    });
  }

}