<div class="btn-group mb-3 w-100" mdbDropdown>
  <button  mdbDropdownToggle type="button" mdbBtn color="light" class="blue-grey-text z-depth-0 dropdown-toggle waves-light d-flex align-items-center px-3" mdbWavesEffect>
    <span class="mr-auto">{{ currentCategorie | translate }}</span>
  </button>
  <div class="dropdown-menu dropdown-dark dropdown-menu-right font-small app-drop-small-limit-5">
    <a *ngFor="let categorie of categories" class="dropdown-item z-depth-0" (click)="onType(categorie)">
      {{ categorie | translate }}
    </a>
  </div>
</div>

<app-links-item [label]="'AXES'" *ngIf="currentCategorie == 'AXES'">
  <div app-links-item-content-description>
  </div>
  <div app-links-item-content-option class="d-flex align-items-center">

    <div class="btn-group" mdbDropdown>
      <a mdbDropdownToggle type="button" mdbBtn size="sm" color="light" class="dropdown-toggle waves-light z-depth-0 px-3 dropdown-light" mdbWavesEffect>
        <span class="{{color}}-text font-weight-bolder">{{ 'AXIS' | translate }}&nbsp;{{ currentAxis }}</span>&nbsp;
      </a>
      <div class="dropdown-menu dropdown-dark dropdown-menu-right font-small app-drop-small-limit-5">
        <a *ngFor="let value of axes" class="dropdown-item z-depth-0" (click)="onAxis(value)">
          {{ 'AXIS' | translate }}&nbsp;{{ value }}
        </a>
      </div>
    </div>

  </div>
</app-links-item>

<app-links-item [label]="'SERIES'" *ngIf="currentCategorie == 'SERIE'">
  <div app-links-item-content-description>
  </div>
  <div app-links-item-content-option class="d-flex align-items-center">

    <div class="btn-group" mdbDropdown>
      <a mdbDropdownToggle type="button" mdbBtn size="sm" color="light" class="dropdown-toggle waves-light z-depth-0 px-3 dropdown-light" mdbWavesEffect>
        <span class="{{color}}-text font-weight-bolder">{{ 'SERIE' | translate }}&nbsp;{{ currentSerie }}</span>&nbsp;
      </a>
      <div class="dropdown-menu dropdown-dark dropdown-menu-right font-small app-drop-small-limit-5">
        <a *ngFor="let value of series" class="dropdown-item z-depth-0" (click)="onSerie(value)">
          {{ 'SERIE' | translate }}&nbsp;{{ value }}
        </a>
      </div>
    </div>

  </div>
</app-links-item>

<app-links-item [label]="'DATALABELS'" *ngIf="currentCategorie == 'LABELS'">
  <div app-links-item-content-description>
  </div>
  <div app-links-item-content-option class="d-flex align-items-center">

    <div class="btn-group" mdbDropdown>
      <a mdbDropdownToggle type="button" mdbBtn size="sm" color="light" class="dropdown-toggle waves-light z-depth-0 px-3 dropdown-light" mdbWavesEffect>
        <span class="{{color}}-text font-weight-bolder">{{ 'LABELS' | translate }}&nbsp;{{ currentDatalabels }}</span>&nbsp;
      </a>
      <div class="dropdown-menu dropdown-dark dropdown-menu-right font-small app-drop-small-limit-5">
        <a *ngFor="let value of datalabels" class="dropdown-item z-depth-0" (click)="onDatalabels(value)">
          {{ 'LABELS' | translate }}&nbsp;{{ value }}
        </a>
      </div>
    </div>

  </div>
</app-links-item>

<div *ngFor="let option of list | keyvalue">
  <app-widget-layers-theme-options-item
    [key]="option.key"
    [option]="option.value"
    (sendSelect)="receiveSelect($event)"
    *ngIf="option.value.categories[0] == currentCategorie && option.value.subOption != true"
  ></app-widget-layers-theme-options-item>
</div>