/**
 * @module Avatar
 */

import { AvatarModel } from '@models/avatar.model';
import { ModificationStateClass, ModificationClass } from '@class/modification.class';
import { DeepCopy } from '@functions/copy.functions';
import { BehaviorSubject } from 'rxjs';

export class AvatarClass extends ModificationClass<AvatarModel> {

  protected _parent: AvatarModel;
  protected _inital: AvatarModel;
  protected _attributes: string[];

  private _isModified: boolean;

  constructor(avatar: AvatarModel, state: ModificationStateClass, modifications$: BehaviorSubject<ModificationStateClass>) {
    
    super();
    
    this._parent = avatar;
    this._attributes = this.attributes;
    this._state = state;

    this._modifications$ = modifications$;
    this._isModified = false;

    this._modifications$.subscribe({
      next: (st: ModificationStateClass) => {
        if(st.has('file')) {
          this._isModified = true;
        }
      }
    });

    this._inital = DeepCopy({ avatar: this._parent.avatar });
  }

  get class(): Readonly<string> {
    return 'avatar';
  }

  get attributes(): string[] {
    return ['avatar'];
  }

  get path(): Readonly<string> {
    return this._parent.avatar.path;
  }

  get url(): Readonly<string> {
    return this._parent.avatar.url;
  }

  get file(): Readonly<File> {
    return this._parent.avatar.file;
  }

  get isModified(): Readonly<boolean> {
    return this._isModified;
  }

}