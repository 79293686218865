<div mdbModal #presencesModal="mdbModal" class="modal fade right" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-side modal-top-right modal-auto-width" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="presencesModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h6 class="modal-title w-100 font-weight-bolder blue-grey-text">
          <i class="fa-users-class fad mr-2"></i>{{ 'LOCAL_PRESENCES/TEXT' | translate }}
        </h6>
      </div>
      <div class="modal-body modal-auto-height px-0 pt-3 pb-4 blue-grey lighten-5">
        <div *ngFor="let presence of presences; let isLast = last">
          <div class="d-flex align-items-center px-3">
            <div class="mr-2">
              <img class="img-fluid rounded-circle app-presences-avatar" [src]="presence.user_url">
            </div>
          
            <div class="label p-1 font-small">
              <i class="fas fa-fw fa-circle pr-1 text-success" *ngIf="presence.status == 'online'"></i>
              <i class="fas fa-fw fa-circle pr-1 text-danger" *ngIf="presence.status == 'offline'"></i>
              <i class="fas fa-fw fa-circle pr-1 text-warning" *ngIf="presence.status == 'inactive'"></i>
              <strong>{{presence.user_label}}</strong><br>
              <div class="font-extra-small text-muted">
                <span *ngIf="presence.status == 'online'">{{ 'ONLINE' | translate }}</span>
                <span *ngIf="presence.status == 'offline'">{{ 'OFFLINE' | translate }}</span>
                <span *ngIf="presence.status == 'inactive'">{{ 'INACTIVE' | translate }}</span>
              </div>
            </div>
          </div>
          <hr *ngIf="!isLast">
        </div>
      </div>
    </div>
  </div>
</div>