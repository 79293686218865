<app-theme-option [categories]="themeOptions.optionsConfiguration[optionType].categories" [label]="themeOptions.optionsConfiguration[optionType].label">
  <div app-theme-option-content>
    <app-show-details-option [showDetails]="showDetails" (sendShowDetails)="receiveShowDetails($event)">
      <div app-show-details-option-content>
        <app-number [value]="themeOptions.datalabels(datalabelsIndex).textStrokeWidth" (sendValue)="receiveValue($event)" [color]="color" [min]="0" [max]="10" [step]="1"></app-number>
      </div>
    </app-show-details-option>
  </div>
</app-theme-option>

<div *ngIf="showDetails">
  <app-theme-option [categories]="themeOptions.optionsConfiguration[optionType].categoriesAndLabel" [label]="type | chart" [dark]="4" *ngFor="let type of themeOptions.limitedTo(optionType)">
    <div app-theme-option-content>
      <app-number [value]="themeOptions.datalabels(datalabelsIndex, type).textStrokeWidth" (sendValue)="receiveValue($event, type)" [color]="color" [min]="0" [max]="10" [step]="1"></app-number>
    </div>
  </app-theme-option>
</div>