<div class="app-dashboard-studio-layout-frame" [ngStyle]="{ 'background-color': dashboardLayout.color, 'width': dashboardLayout.widthPixel }">
  <div class="mx-auto isSortableContainer" appGridLayout [maxColumns]="dashboardLayout.maxColumns" [gap]="dashboardLayout.gap" [padding]="dashboardLayout.padding" [size]="dashboardLayout.size"
    app-sortable (sendSortableStop)="receiveSortableStop($event)" [sortableDisabled]="!isDraggable" [forceSortable]="isDraggable">
    <div *ngFor="let cell of dashboardLayout.cells ; let i = index" [id]="i"
      appGridSpan [columns]="cell.columns" [maxColumns]="dashboardLayout.maxColumns" [rows]="cell.rows" 
      [ngClass]="{ 'app-cursor-grab': isDraggable }"
      class="app-dashboard-studio-layout-cell-depth-{{dashboardLayout.depth}} isSortableElement">

      <app-dashboard-studio-cell [cell]="cell" (sendSelected)="receiveSelected($event)" [current]="current" [dashboardLayout]="dashboardLayout"></app-dashboard-studio-cell>

    </div>
  </div>
</div>
