/**
 * @Module Tag
 */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tags',
  pure: false
})
export class TagsPipe implements PipeTransform {

  transform(items: any[], tags: string[]): any {
    if(items !== undefined && items !== undefined && tags.length > 0) {
      return items.filter(i => tags.some(t => i.tags.list.includes(t)));
    }
    else {
      return items;
    }
  }

}
