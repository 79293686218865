/**
 * @module Dashboard
 */

import { Component, Input } from '@angular/core';
import { ItemColor } from '@functions/item.functions';
import { DashboardLayoutClass } from '@class/dashboardLayout.class';

@Component({
  selector: 'app-dashboard-cells-add',
  templateUrl: './dashboard-cells-add.component.html',
  styleUrls: ['./dashboard-cells-add.component.css']
})
export class DashboardCellsAddComponent {

  @Input() dashboardLayout: DashboardLayoutClass;

  color = ItemColor('dashboards');

  constructor() {}

  onAdd() {
    this.dashboardLayout.addCell();
  }

}
