/**
 * @module Widget
 */

import { Component, OnInit, Input } from '@angular/core';
import { WidgetLayersClass } from '@class/widgetLayers.class';
import { DateClass } from '@class/date.class';
import { UID } from '@models/uid.model';
import { ItemsColors, ItemsIcons } from '@functions/item.functions';
import { DateService } from '@services/date/date.service';
import { take } from 'rxjs/operators';
import { WidgetLayerClass } from '@class/widgetLayer.class';

@Component({
  selector: 'app-widget-layers-filters-dates',
  templateUrl: './widget-layers-filters-dates.component.html',
  styleUrls: ['./widget-layers-filters-dates.component.css']
})
export class WidgetLayersFiltersDatesComponent implements OnInit {

  @Input() widgetLayers: WidgetLayersClass;

  datesAndLayers: { date: DateClass, layer: WidgetLayerClass }[] = [];
  loading: boolean;
  current: UID;

  colors = ItemsColors(true);
  icons = ItemsIcons();

  constructor(
    private $dates: DateService
  ) {
    this.loading = true;
  }

  ngOnInit() {
    Object.values(this.widgetLayers.layers).forEach((layer: WidgetLayerClass) => {
      if(layer.layerType === 'dates') {
        if(this.datesAndLayers.map(dl => dl.date.uid.value).indexOf(layer.uid.value) === -1) {
          this.$dates.getPartial$(layer.uid.value).pipe(take(1)).subscribe((d: DateClass) => {
            this.datesAndLayers.push({ date: d, layer: layer });
            this.loading = false;
          });
        }
      }
    });
  }

  onEdit(uid: UID) {
    this.current = this.current === uid ? undefined : uid;
  }

  receiveSelect(uid: UID) {
    this.onEdit(uid);
  }

}