<app-version></app-version>


<div class="app-constructor-logo-swapp animated slideInLeft"></div>

<div mdbModal #constructorModal="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="constructorModal" aria-hidden="true"
  [config]="{ignoreBackdropClick: true, keyboard: false, show: true}">
  <div class="modal-dialog modal-sm modal-dialog-centered modal-dialog-scrollable" role="document">
    <div class="modal-content">

      <div class="modal-body p-0">
        <div class="d-flex flex-column">
          <div class="px-3 pt-4 d-flex justify-content-center flex-column col-12">
            <img style="height: 140px;" class="animated fadeInUp" src="assets/images/logo.svg">
  
            <span class="mt-3">
              <mdb-card-title class="mb-0">
                <h3 class="text-elegant text-center">
                  <strong>{{ company }}</strong>
                </h3>
              </mdb-card-title>
    
              <h6 class="mb-0 text-muted text-center">
                <strong>Swapp.Dash</strong>
              </h6>
            </span>
          </div>
        <div class="p-4 col-12">

          <div class="font-weight-bolder grey lighten-4 alert alert-light font-small" role="alert">
            Check the company name and apply
          </div>
    
          <button block="true" type="button" class="z-depth-0 mt-2 mb-0" mdbBtn color="primary" (click)="onApply()" mdbWavesEffect>
            <i *ngIf="isWaiting" class="fas fa-circle-notch fa-spin mr-1"></i>
            {{ (isWaiting ? '' : 'APPLY') | translate }}
          </button>

        </div>
      </div>
    </div>
      
    </div>
  </div>
</div>

<app-version></app-version>