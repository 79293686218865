<div class="rounded p-sm-3 p-2 blue-grey lighten-5 switch round blue-grey-switch d-flex align-items-center app-form-strip" [ngClass]="{ 'justify-content-center': center }">

  <!-- CONTENT LEFT -->
  <div class="d-none d-sm-block blue-grey-text">
    <ng-content select="[app-form-strip-content-left]"></ng-content>
  </div>
  <!-- ./CONTENT LEFT -->

  <!-- CONTENT MIDDLE -->
  <div class="font-weight-bolder font-small blue-grey-text mx-2">
    <ng-content select="[app-form-strip-content-middle]"></ng-content>
  </div>
  <!-- ./CONTENT MIDDLE -->

  <!-- CONTENT RIGHT -->
  <div [ngClass]="{ 'ml-auto': !center }">
    <ng-content select="[app-form-strip-content-right]"></ng-content>
  </div>
  <!-- ./CONTENT RIGHT -->

</div>