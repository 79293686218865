/**
 * @module Shared
 */

/**
 * @description Renvoi un identifiant aléatoire
 * @export
 * @param {number} [length]
 * @returns {string}
 */
export function CreateRandomUid(length?: number): string {
  if(length !== undefined) return (Date.now().toString(36) + Math.random().toString(36).substring(2, 7)).toUpperCase().substring(2, length + 2);
  else return (Date.now().toString(36) + Math.random().toString(36).substring(2, 7)).toUpperCase();
}

/**
 * @description Renvoi un chaîne aléatoire en majuscule
 * @export
 * @param {number} length
 * @returns {string}
 */
export function CreateRandomString(length: number): string {
  let result = '';
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let charactersLength = characters.length;
  for( let i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}