<app-theme-option [categories]="themeOptions.optionsConfiguration[optionType].categories" [label]="themeOptions.optionsConfiguration[optionType].label" [information]="themeOptions.series(serieIndex).hoverBorderColor" [sublabel]="'OPACITY'" [subinformation]="opacity + '%'">
  <div app-theme-option-content>
    <app-show-details-option [showDetails]="showDetails" (sendShowDetails)="receiveShowDetails($event)">
      <div app-show-details-option-content>
        <app-color-picker [itemColor]="color" [color]="hoverBorderColor" (sendColor)="receiveValue($event)"></app-color-picker>
      </div>
    </app-show-details-option>
  </div>
  <div app-theme-option-subcontent>
    <app-slider [color]="color" [value]="opacity" (sendValue)="receiveOpacity($event)"></app-slider>
  </div>
</app-theme-option>

<div *ngIf="showDetails">
  <app-theme-option-elements-border-color-detail *ngFor="let type of themeOptions.limitedTo(optionType)" [themeOptions]="themeOptions" [serieIndex]="serieIndex" [type]="type"></app-theme-option-elements-border-color-detail>
</div>