/**
 * @module Theme
 */

import { Component, Input, OnInit } from '@angular/core';
import { ItemColor } from '@functions/item.functions';
import { ThemeOptionsClass } from '@class/themeOptions.class';
import { ThemeOptionType } from '@models/themeStudio.model';

@Component({
  selector: 'app-theme-option-tooltips-background-color',
  templateUrl: './theme-option-tooltips-background-color.component.html',
  styleUrls: ['./theme-option-tooltips-background-color.component.css']
})
export class ThemeOptionTooltipsBackgroundColorComponent implements OnInit {

  @Input() themeOptions: ThemeOptionsClass;

  color = ItemColor('themes');
  optionType: ThemeOptionType = 'tooltips_backgroundColor';
  opacity: number;
  backgroundColor: string;

  constructor() {}

  ngOnInit() {
    this.backgroundColor = this.themeOptions.options().tooltips.backgroundColor.toString().slice(0, 7);
    this.opacity = Math.round(parseInt(this.themeOptions.options().tooltips.backgroundColor.toString().slice(7, 9), 16) * 100 / 255);
  }
  
  receiveValue(value: string) {
    this.backgroundColor = value;
    this.themeOptions.setOption('tooltips_backgroundColor', value + Math.round(this.opacity * 255 / 100).toString(16));
  }

  receiveOpacity(value: number) {
    this.opacity = value;
    if(this.opacity > 0) this.themeOptions.setOption('tooltips_backgroundColor', this.backgroundColor + Math.round(value * 255 / 100).toString(16));
    else this.themeOptions.setOption('tooltips_backgroundColor', this.backgroundColor + '00');
  }

}
