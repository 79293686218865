<app-studio #studio [type]="'dashboards'" [itemLabel]="'DASHBOARD'">

  <!-- BLOC MAIN -->
  <div *ngIf="dashboard" app-studio-main-content>
    <div class="d-flex">
      <button [disabled]="saved || saving" mdbBtn type="button" color="default" class="z-depth-0 my-0 ml-0 mr-1 px-sm-4 px-3" mdbWavesEffect (click)="onReload()">
        <i class="d-sm-none far fa-redo fa-fw"></i>
        <i class="d-none d-sm-inline far fa-redo"></i>
        <span class="d-none d-sm-inline ml-1">{{ 'RESTORE' | translate }}</span>
      </button>
      <button [disabled]="saved || saving" mdbBtn type="button" [color]="color" class="z-depth-0 flex-grow-1 m-0" mdbWavesEffect (click)="onSave()">
        <i *ngIf="!saving" class="far fa-check"></i>
        <i *ngIf="saving" class="far fa-circle-notch fa-spin"></i>
        <span class="ml-1">{{ 'SAVE' | translate }}</span>
      </button>
    </div>

    <hr>

    <app-item-strip
      [labels]="dashboard.labels"
      [tags]="dashboard.tags"
      [type]="'dashboards'"
      [uid]="dashboard.uid"
      [icon]="icon"
      [color]="color"
      [isModified]="dashboard.modifications$.value.isModified"
      (sendEdit)="receiveEdit($event)"
    ></app-item-strip>
    <app-dashboard-cells-add #cellsAdd *ngIf="dashboardLayout" [dashboardLayout]="dashboardLayout"></app-dashboard-cells-add>
    
    <hr>

    <app-dashboard-layout-editor *ngIf="dashboardLayout" [dashboardLayout]="dashboardLayout" (sendDraggable)="receiveDraggable($event)"></app-dashboard-layout-editor>

  </div>
  <!-- ./BLOC MAIN -->

  <!-- BLOC ITEM -->
  <div app-studio-item-content *ngIf="dashboardLayout && dashboardLayout?.cells?.length > 0; else app_empty">
    <app-dashboard-studio-layout [isDraggable]="isDraggable" [studio]="studio" [dashboardLayout]="dashboardLayout" (sendSelected)="receiveSelected($event)" [current]="current?.uid.value"></app-dashboard-studio-layout>
  </div>
  <!-- ./BLOC ITEM -->

  <!-- BLOC SUB -->
  <div app-studio-sub-content>
    <div *ngIf="current ; else selection_empty">
      <app-dashboard-cell-editor [cell]="current" (sendRemove)="receiveRemove($event)"></app-dashboard-cell-editor>
    </div>
  </div>
  <!-- ./BLOC SUB -->

</app-studio>

<ng-template #selection_empty>
  <app-empty [action]="false" [description]="'SELECT_CELL/TEXT'"></app-empty>
</ng-template>

<ng-template #app_empty>
  <div *ngIf="dashboardLayout && dashboardLayout.cells.length == 0"><app-empty (sendAction)="receiveAction()" [type]="'dashboards'" [actionText]="'ADD'" [actionDescription]="'NO_CELL_ADDED/TEXT'"></app-empty></div>
  <div *ngIf="!dashboardLayout" class="app-widget-studio-central"><app-loading></app-loading></div>
</ng-template>

<app-dashboard-editor #editor [current]="dashboard" (sendDelete)="receiveDelete()"></app-dashboard-editor>