/**
 * @module Server
 */

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ServerClass, ServerLinkClass } from '@class/server.class';
import { Subscription } from 'rxjs';
import { UID } from '@models/uid.model';
import { ServerService } from '@services/server/server.service';
import { ItemColor, ItemIcon } from '@functions/item.functions';
import { LanguageType } from '@models/language.model';
import { AuthService } from '@services/auth/auth.service';

@Component({
  selector: 'app-servers-links-list',
  templateUrl: './servers-links-list.component.html',
  styleUrls: ['./servers-links-list.component.css']
})
export class ServersLinksListComponent implements OnDestroy, OnInit {

  @Input() serverLink: ServerLinkClass;
  @Input() isRegistered: boolean;
  @Input() showWarning: boolean = true;

  color = ItemColor('servers');
  icon = ItemIcon('servers');

  partial$sub: Subscription;
  partial: ServerClass;
  servers$sub: Subscription;
  list: ServerClass[];
  searchLabel: string;
  language: LanguageType;
  language$sub: Subscription;

  constructor(
    private $servers: ServerService,
    private $auth: AuthService
  ) {
    this.language$sub = this.$auth.language$.subscribe({
      next: (language: LanguageType) => {
        this.language = language;
      }
    });
  }

  receiveTextValue(searchLabel: string) {
    this.searchLabel = searchLabel;
  }

  ngOnInit() {
    if(this.isRegistered) {
      this.partial$sub = this.$servers.getPartial$(this.serverLink.uid).subscribe({
        next: (partial: ServerClass) => {
          this.partial = partial;
        }
      });
    }
    else {
      this.servers$sub = this.servers$sub = this.$servers.listPartials$().subscribe({
        next: (servers: ServerClass[]) => {
          this.list = servers;
        }
      });
    }
  }

  ngOnDestroy() {
    if(this.partial$sub !== undefined) this.partial$sub.unsubscribe();
    if(this.servers$sub !== undefined) this.servers$sub.unsubscribe();
  }

  receiveSelected(uid: UID) {
    const server = this.list.filter((server: ServerClass) => { return server.uid.value === uid; })[0];
    return this.serverLink.change({
      serverLink: {
        uid: server.uid.value,
        url: server.url
      }
    });
  }

}
