/**
 * @module Widget
 */

import { WidgetIndicatorModel, WidgetDatalabelsModel, PivotFiltersModel, WidgetTimelineType, WidgetIndicatorAxis } from '@models/widgetLayers.model';
import { UidModel, UID } from '@models/uid.model';
import { ModificationClass, ModificationStateClass } from '@class/modification.class';
import { UidClass } from '@class/uid.class';
import { LabelsClass } from '@class/labels.class';
import { LabelsModel } from '@models/labels.model';
import { DeepCopy, DeepMerge } from '@functions/copy.functions';
import { ServerLinkClass } from '@class/server.class';
import { ServerLinkModel } from '@models/server.model';
import { WidgetDatalabelsClass } from '@class/widgetDatalabels.class';
import { WidgetDatalabelsType } from '@models/widget.model';
import { Options } from 'chartjs-plugin-datalabels/types/options';
import { LanguageType } from '@models/language.model';
import { Context } from 'chartjs-plugin-datalabels';
import { NumbroApplyFormat } from '@functions/numbro.functions';
import { ChartMixedType } from '@models/chart.model';
import { WidgetLayerClass } from '@class/widgetLayer.class';
import { PivotFiltersClass } from '@class/widgetFilters.class';
import { CreateRandomString } from '@functions/uid.functions';
import { NumbroClass } from '@class/numbro.class';
import { ChartDataSets } from 'chart.js';
import { IconClass } from '@class/icon.class';
import { IconModel } from '@models/icon.model';

export class WidgetIndicatorClass extends ModificationClass<WidgetIndicatorModel> {
  
  private _uid: UidClass;
  private _labels: LabelsClass;
  private _serverLink: ServerLinkClass;
  private _icon: IconClass;

  protected _parent: WidgetIndicatorModel;
  protected _inital: WidgetIndicatorModel;
  protected _attributes: string[];
  
  private _datalabels: WidgetDatalabelsClass[];
  private _filters: PivotFiltersClass[];
  private _numbro: NumbroClass;

  private _hoverDatalabelsList: WidgetDatalabelsType[];
  private _displayDatalabelsList: WidgetDatalabelsType[];

  constructor(widgetIndicator: WidgetIndicatorModel, state: ModificationStateClass) {

    super();

    this._parent = widgetIndicator;
    this._state = state;

    this._reset(this._parent);

    this._attributes = this.attributes.slice();
    this._attributes.push(...this._uid.attributes);
    this._attributes.push(...this._labels.attributes);
    this._attributes.push(...this._serverLink.attributes);
    this._attributes.push(...this._icon.attributes);

    this._labels.modifications$ = this._modifications$;
    this._icon.modifications$ = this._modifications$;
  }

  private _reset(widgetIndicator: WidgetIndicatorModel) {

    if(widgetIndicator.icon === undefined) widgetIndicator.icon = { style: 'd', symbol: 'rocket', options: [] };

    this._uid = new UidClass(widgetIndicator as UidModel, true);
    this._labels = new LabelsClass(widgetIndicator as LabelsModel, this._form, this._requirements, this._state);
    this._serverLink = new ServerLinkClass(widgetIndicator as ServerLinkModel, this._state);
    this._icon = new IconClass(widgetIndicator as IconModel, this._state);

    if(this._parent.stack === undefined) this._parent.stack = null;
    if(this._parent.inTable === undefined) this._parent.inTable = true;
    if(this._parent.type === undefined) this._parent.type = 'bar';
    if(this._parent.timeline === undefined) this._parent.timeline = 'reference';
    if(this._parent.axis === undefined) this._parent.axis = 'main';
    if(this._parent.formula === undefined) this._parent.formula = '';
    if(this._parent.display === undefined) this._parent.display = true;
    if(this._parent.cumulative === undefined) this._parent.cumulative = false;
    if(this._parent.formulaUID === undefined) this._parent.formulaUID = CreateRandomString(3);
    this._numbro = new NumbroClass(this._parent.numbro === undefined ? { type: 'number' } : this._parent.numbro , this._state, this._modifications$);

    if(this._parent.datalabels === undefined) this._parent.datalabels = [
      { type: 'value', hover: false, display: true, tooltips: true, table: false, rank: 0, numbro: { type: 'number' } },
      { type: 'indicatorLabel', hover: false, display: false, tooltips: true, table: false, rank: 1 }
    ];
    this._datalabels = this._parent.datalabels.map((datalabels: WidgetDatalabelsModel) => new WidgetDatalabelsClass(datalabels, this._state, this._modifications$));
    this.setDatalabelsLists();

    if(this._parent.filters === undefined) this._parent.filters = [];
    this._filters = this._parent.filters.map((filter: PivotFiltersModel) => new PivotFiltersClass(filter, this._state, this._modifications$, this._lastModification$));

    if(this._parent.serie === undefined) this._parent.serie = -1; 

    this.attributes.forEach((attribute: string) => {
      this._parent[attribute] = widgetIndicator[attribute];
    });

    this._inital = DeepCopy(widgetIndicator);

    this._state.flush();
    this._modifications$.next(this._state);
  }

  reset() {
    this._state.flush();
    this._reset(this._inital);
  }

  get model(): Readonly<WidgetIndicatorModel> {
    const datalabels = this._datalabels.map((dtls: WidgetDatalabelsClass) => dtls.model);
    const filters = this._filters.map((flts: PivotFiltersClass) => flts.model);
    const numbro = this._numbro.model;
    return DeepMerge(super.model, { datalabels: datalabels, filters: filters, numbro: numbro } as WidgetIndicatorModel);
  }

  get attributes(): Readonly<string[]> {
    return ['serie', 'stack', 'timeline', 'formula', 'formulaUID', 'display', 'type', 'axis', 'cumulative', 'inTable'];
  }

  get class(): string {
    return 'widgetIndicator';
  }

  get uid(): UidClass {
    return this._uid;
  }

  get inTable(): boolean {
    return this._parent.inTable;
  }

  get icon(): IconClass {
    return this._icon;
  }

  get numbro(): NumbroClass {
    return this._numbro;
  }

  get stack(): string {
    return this._parent.stack;
  }

  get timeline(): WidgetTimelineType {
    return this._parent.timeline;
  }

  get axis(): Readonly<WidgetIndicatorAxis> {
    return this._parent.axis;
  }

  get formula(): Readonly<string> {
    return this._parent.formula;
  }

  get isFormula(): Readonly<boolean> {
    return this._parent.formula.length > 0;
  }

  get formulaUID(): Readonly<UID> {
    return this._parent.formulaUID;
  }

  get display(): Readonly<boolean> {
    return this._parent.display;
  }

  get labels(): LabelsClass {
    return this._labels;
  }

  get serverLink(): ServerLinkClass {
    return this._serverLink;
  }

  get serie(): number {
    return this._parent.serie;
  }

  get type(): ChartMixedType {
    return this._parent.type;
  }

  get datalabels(): Readonly<WidgetDatalabelsClass[]> {
    return this._datalabels;
  }

  get filters(): Readonly<PivotFiltersClass[]> {
    return this._filters.filter(f => f.values.length > 0);
  }

  get cumulative(): Readonly<boolean> {
    return this._parent.cumulative || false;
  }

  change(attributes: Partial<WidgetIndicatorModel>, sendMessage?: boolean) {
    if(attributes.timeline !== undefined || attributes.formula !== undefined || attributes.cumulative !== undefined) this._state.add('reload');
    super.change(attributes, sendMessage, this._parent);
  }


  filter(uid: UID): Readonly<PivotFiltersClass> {
    const index = this._filters.map((filter: PivotFiltersClass) => filter.uid.value).indexOf(uid);
    if(index !== -1) return this._filters[index];
    else {
      const indexModel = this._parent.filters.push({
        uid: uid,
        include: true,
        values: []
      });
      const indexClass = this._filters.push(new PivotFiltersClass(this._parent.filters[indexModel - 1], this._state, this._modifications$, this._lastModification$));
      return this._filters[indexClass - 1];
    }
  }

  datalabelsByType(type: WidgetDatalabelsType): WidgetDatalabelsClass {
    return this._datalabels.filter(dtls => dtls.type === type)[0];
  }

  setDatalabels(datalabels: WidgetDatalabelsModel, moveToIndex?: number) {
    const keys = this._datalabels.map(dtls => dtls.type);
    const index = keys.indexOf(datalabels.type);
    if(datalabels.display || datalabels.hover || datalabels.table || datalabels.tooltips) {
      if(index === -1) {
        datalabels.rank = keys.length;
        this._datalabels.push(new WidgetDatalabelsClass(datalabels, this._state, this._modifications$));
      }
      else {
        this._datalabels[index].change({ display: datalabels.display, hover: datalabels.hover, table: datalabels.table, tooltips: datalabels.tooltips });
        if(this._datalabels[index].numbro !== undefined) this._datalabels[index].numbro.change(datalabels.numbro);
        if(moveToIndex !== undefined && moveToIndex !== index) {
          this._datalabels.splice(moveToIndex, 0, this._datalabels.splice(index, 1)[0]);
          this._datalabels.forEach((dtls: WidgetDatalabelsClass, index: number) => {
            this._datalabels[index].change({ rank: index });
          });
        }
      }
    }
    else {
      if(index !== -1) {
        this._datalabels.splice(index, 1);
        this._datalabels.forEach((dtls: WidgetDatalabelsClass, index: number) => {
          this._datalabels[index].change({ rank: index });
        });
      }
    }
    this.setDatalabelsLists();
    this.emit(['datalabels']);
  }

  datalabelsOptions(datalabels: Options, language: LanguageType, layer: WidgetLayerClass): Readonly<Options> {
    const options = DeepCopy(Object.values(datalabels.labels)[0])
    datalabels.labels[Object.keys(datalabels.labels)[0]].display = false;

    this._datalabels.forEach((dtls: WidgetDatalabelsClass, index: number) => {
      datalabels.labels[dtls.type] = DeepCopy(options);

      if(this._displayDatalabelsList.length > 1 || this._hoverDatalabelsList.length > 1) {
        if((['isMax', 'isMin', 'isAboveAverage', 'isBelowAverage', 'comparedAverage', 'isAverage'] as WidgetDatalabelsType[]).includes(dtls.type)) {
          datalabels.labels[dtls.type].font['family'] = 'fa-solid-900';
        }
        datalabels.labels[dtls.type].align = 90;
        datalabels.labels[dtls.type].offset = (context: Context) => {
          return this.datalabelsOffset(context, dtls.type as WidgetDatalabelsType);
        };
      }
      datalabels.labels[dtls.type].formatter = (value: any, context: Context) => {
        return this.onHover(value, context, dtls.type as WidgetDatalabelsType, language, layer);
      };
    });
    return datalabels;
  }

  private setDatalabelsLists() {
    this._hoverDatalabelsList = this._datalabels.filter(dtls => dtls.hover).map(dtls => dtls.type);
    this._displayDatalabelsList = this._datalabels.filter(dtls => dtls.display).map(dtls => dtls.type);
  }

  private datalabelsOffset(context: Context, type: WidgetDatalabelsType): number {
    const { dataset, active } = context;
    const offset = (dataset.datalabels.labels[type].padding['top'] + dataset.datalabels.labels[type].padding['bottom'] + Math.ceil(dataset.datalabels.labels[type].font['size'] * dataset.datalabels.labels[type].font['lineHeight']) + 1)
    if(active) {
      const index = this._hoverDatalabelsList.indexOf(type);
      return offset * Math.round(index - this._hoverDatalabelsList.length / 2);
    }
    else {
      const index = this._displayDatalabelsList.indexOf(type);
      return offset * Math.round(index - this._displayDatalabelsList.length / 2);
    }
  }
 
  private onHover(value: any, context: Context, type: WidgetDatalabelsType, language: LanguageType, layer: WidgetLayerClass): string {

    const { dataIndex, datasetIndex, dataset, active } = context;
    const chartData = context.chart.data;
    const serieData = [...context.dataset.data] as number[];
    const datalabels = this.datalabelsByType(type);

    if(active && datalabels.hover || !active && datalabels.display) {
      return this.datalabelsFormated(datalabels, serieData, value, dataset, dataIndex, language, layer, true);
    }
    else return null;
  }

  datalabelsFormated(datalabels: WidgetDatalabelsClass, serieData: number[], value: any, dataset: ChartDataSets, dataIndex: number, language: LanguageType, layer: WidgetLayerClass, hideIfUndefined: boolean): string {

    if(value === undefined && hideIfUndefined) return null;

    const total = dataset.total; 
    const average = dataset.average;
    const min = dataset.min;
    const max = dataset.max; 

    switch(datalabels.type) {
      case 'average': return datalabels.numbro ? NumbroApplyFormat(average, datalabels.numbro.type, datalabels.numbro.format) : NumbroApplyFormat(average, 'number');
      case 'indicatorLabel': return dataset.label;
      case 'comparedAverage': return value === undefined ? '' : value > average ? '\uf346' : value === average ? '\uf52c' : '\uf349';
      case 'isAboveAverage': return value === undefined ? '' : value > average ? '\uf346' : null;
      case 'isAverage': return value === undefined ? '' : value === average ? '\uf52c' : null;
      case 'isBelowAverage': return value === undefined ? '' : value < average ? '\uf349' : null;
      case 'isMax': return value === undefined ? '' : max === value ? '\uf34d' : null;
      case 'isMin': return value === undefined ? '' : min === value ? '\uf34a' : null;
      case 'max': return datalabels.numbro ? NumbroApplyFormat(max, datalabels.numbro.type, datalabels.numbro.format) : NumbroApplyFormat(max, 'number');
      case 'min': return datalabels.numbro ? NumbroApplyFormat(min, datalabels.numbro.type, datalabels.numbro.format) : NumbroApplyFormat(min, 'number');
      case 'pivotLabel': return layer.layerLabel.value(language);
      case 'rankAsc': return value === undefined ? '' : datalabels.numbro ? NumbroApplyFormat([...serieData].sort((a: number, b: number) => { return a - b }).indexOf(value) + 1, datalabels.numbro.type, datalabels.numbro.format) : NumbroApplyFormat([...serieData].sort((a: number, b: number) => { return a - b }).indexOf(value) + 1, 'personalized', { output: 'ordinal' });
      case 'rankDesc': return value === undefined ? '' : datalabels.numbro ? NumbroApplyFormat([...serieData].sort((a: number, b: number) => { return b - a }).indexOf(value) + 1, datalabels.numbro.type, datalabels.numbro.format) : NumbroApplyFormat([...serieData].sort((a: number, b: number) => { return b - a }).indexOf(value) + 1, 'personalized', { output: 'ordinal' });
      case 'share': return value === undefined ? '' : datalabels.numbro ? NumbroApplyFormat(value / total, datalabels.numbro.type, datalabels.numbro.format) : NumbroApplyFormat(value / total, 'percentageRounded');
      case 'total': return  datalabels.numbro ? NumbroApplyFormat(total, datalabels.numbro.type, datalabels.numbro.format) : NumbroApplyFormat(total, 'number');
      case 'value': return value === undefined ? '' : datalabels.numbro ? NumbroApplyFormat(value, datalabels.numbro.type, datalabels.numbro.format) : NumbroApplyFormat(value, 'number');
      case 'valueLabel': return dataset.labels[dataIndex] as string;//layer.layerType === 'pivots' ? dataset.labels[dataIndex] as string : DateApplyFormat(dataset.labels[dataIndex] as string, layer.dateFormat);
      default: return null;
    }
  }

  datalabelsFormatedValue(datalabels: WidgetDatalabelsClass, values: number[], dataset: ChartDataSets) {
    
    const total = dataset.total; 
    const average = dataset.average;
    const min = dataset.min;
    const max = dataset.max; 

    switch(datalabels.type) {
      case 'average': return datalabels.numbro ? NumbroApplyFormat(average, datalabels.numbro.type, datalabels.numbro.format) : NumbroApplyFormat(average, 'number');
      case 'max': return datalabels.numbro ? NumbroApplyFormat(max, datalabels.numbro.type, datalabels.numbro.format) : NumbroApplyFormat(max, 'number');
      case 'min': return datalabels.numbro ? NumbroApplyFormat(min, datalabels.numbro.type, datalabels.numbro.format) : NumbroApplyFormat(min, 'number');
      case 'total': return  datalabels.numbro ? NumbroApplyFormat(total, datalabels.numbro.type, datalabels.numbro.format) : NumbroApplyFormat(total, 'number');
      default: return null;
    }
  }

}