/**
 * @module Dashboard
 */

import { DashboardClass } from '@class/dashboard.class';
import { DashboardLayoutModel, DashboardLayoutCellModel } from '@models/dashboardLayout.model';
import { CreateRandomUid } from '@functions/uid.functions';

export function LayoutDefault(dashboard: DashboardClass): DashboardLayoutModel {
  return {
    uid: dashboard.uid.value,
    cells: [],
    depth: 1,
    padding: 10,
    size: 50,
    gap: 10,
    columns: 20,
    color: '#f5f5f5ff'
  }
}

export function CellDefault(rank: number): DashboardLayoutCellModel {
  return {
    uid: CreateRandomUid(),
    columns: 5,
    rows: 5,
    rank: rank,
    display: true,
    uidWidget: ''
  }
}