/**
 * @module Widget
 */

import { Component, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UID } from '@models/uid.model';
import { ItemColor, ItemIcon } from '@functions/item.functions';
import { WidgetService } from '@services/widget/widget.service';
import { WidgetClass } from '@class/widget.class';
import { Subscription } from 'rxjs';
import { WidgetEditorComponent } from '@components/widgets/widget-editor/widget-editor.component';
import { LanguageType } from '@models/language.model';
import { AuthService } from '@services/auth/auth.service';
import { ThemeService } from '@services/theme/theme.service';
import { ThemeOptionsClass } from '@class/themeOptions.class';
import { WidgetLayersClass } from '@class/widgetLayers.class';
import { ThemeStudioService } from '@services/themeStudio/theme-studio.service';
import { WidgetStudioService } from '@services/widgetStudio/widget-studio.service';
import { ModificationStateClass } from '@class/modification.class';
import { MessagesService } from '@services/messages/messages.service';
import { WidgetIndicatorsAddComponent } from '@components/widgets/widget-studio/options/indicators/widget-indicators-add/widget-indicators-add.component';
import { StudioComponent } from '@components/forms/studio/studio.component';

@Component({
  selector: 'app-widget-studio',
  templateUrl: './widget-studio.component.html',
  styleUrls: ['./widget-studio.component.css']
})
export class WidgetStudioComponent implements OnDestroy {

  @ViewChild('editor', { static: true }) editor: WidgetEditorComponent;
  @ViewChild('indicatorsAdd') indicatorsAdd: WidgetIndicatorsAddComponent;
  @ViewChild('studio') studio: StudioComponent;

  color = ItemColor('widgets');
  icon = ItemIcon('widgets');

  widget: WidgetClass;
  widget$sub: Subscription;
  language: LanguageType;
  language$sub: Subscription;
  modifications$sub: Subscription;
  uid: UID;
  panel: string;
  from: string;
  saved: boolean;
  saving: boolean;
  widgetLayers$sub: Subscription;
  widgetLayers: WidgetLayersClass;
  themeOptions$sub: Subscription;
  themeOptions: ThemeOptionsClass;
  alertTheme: boolean;
  themeBuffer: ThemeOptionsClass;
  uidTheme: UID;

  currentIndicator: number;
  currentLayer: UID;
  type: 'indicators' | 'layers';

  constructor(
    private $router: Router,
    private $widget: WidgetService,
    private $auth: AuthService,
    private $theme: ThemeService,
    private $themeStudio: ThemeStudioService,
    private $widgetStudio: WidgetStudioService,
    private $messages: MessagesService
  ) { 
    this.uid = this.$router.parseUrl(this.$router.url).queryParams.uid;
    this.panel = this.$router.parseUrl(this.$router.url).queryParams.panel;
    this.from = this.$router.parseUrl(this.$router.url).queryParams.from;
    this.widget = this.$widget.create();
    this.saved = true;
    this.saving = false;
    this.alertTheme = false;

    this.language$sub = this.$auth.language$.subscribe({
      next: (language: LanguageType) => {
        this.language = language;
      }
    });

    if(!this.uid) this.onBack();
    else {
      this.widget$sub = this.$widget.get$(this.uid).subscribe({
        next:(widget: WidgetClass) => {
          if(!!widget) {
            if(this.uidTheme !== widget.uidTheme || this.widget === undefined) {
              this.widget = widget;
              if(this.themeOptions$sub) this.themeOptions$sub.unsubscribe();
              this.themeOptions$sub = this.$theme.getOptions$(this.widget.uidTheme).subscribe({
                next:(themeOptions: ThemeOptionsClass) => {
                  if(this.widgetLayers$sub && !this.saved) {
                    this.widgetLayers$sub.unsubscribe();
                    this.alertTheme = true;
                    this.themeBuffer = !!themeOptions.model ? themeOptions : this.$themeStudio.themeDefault();
                  }
                  else {
                    this.themeBuffer = undefined;
                    this.themeOptions = !!themeOptions.model ? themeOptions : this.$themeStudio.themeDefault();
                    this.loadLayers();
                  }
                }
              });
            }
            else {
              this.widget = widget;
            }
          }
        }
      });
    }
  }

  loadLayers() {
    if(this.themeBuffer !== undefined) this.themeOptions = this.themeBuffer;
    if(this.widgetLayers$sub) this.widgetLayers$sub.unsubscribe();
    this.widgetLayers$sub = this.$widget.getLayers$(this.widget, this.themeOptions).subscribe({
      next:(widgetLayers: WidgetLayersClass) => {
        this.widgetLayers = this.$widgetStudio.widgetLayers(this.uid, widgetLayers, this.widget, this.themeOptions, this.modifications$sub !== undefined);
        if(this.modifications$sub) this.modifications$sub.unsubscribe();
        this.modifications$sub = this.widgetLayers.modifications$.subscribe((modification: ModificationStateClass) => {
          this.saved = !modification.isModified;
        });
      }
    });
    this.alertTheme = false;
  }

  ngOnDestroy() {
    this.language$sub.unsubscribe();
    if(this.themeOptions$sub) this.themeOptions$sub.unsubscribe();
    if(this.widget$sub) this.widget$sub.unsubscribe();
    if(this.modifications$sub) this.modifications$sub.unsubscribe();
    if(this.widgetLayers$sub) this.widgetLayers$sub.unsubscribe();
  }

  receiveAction() {
    this.indicatorsAdd.show();
  }

  receiveCurrentIndicator(current: number) {
    this.currentIndicator = current;
    this.type = undefined;
    this.studio.setFrame('sub', true);
    setTimeout(() => this.type = 'indicators');
  }

  receiveCurrentLayer(current: UID) {
    this.currentLayer = current;
    this.type = undefined;
    this.studio.setFrame('sub', true);
    setTimeout(() => this.type = 'layers');
  }

  receiveDeleteIndicator() {
    this.currentIndicator = this.widgetLayers.indicators.length > 0 ? 0 : undefined;
    if(this.currentIndicator === undefined) this.type = undefined;
    this.studio.setFrame('main', true);
  }

  onReload() {
    this.widgetLayers.reset();
  }

  onSave() {
    this.saving = true;
    this.$widget.setLayers(this.uid, this.widgetLayers)
    .then(() => {
      this.saving = false;
      this.saved = true;
      this.$messages.success({ 
        title: { plain: this.widget.labels.value(this.language) }, 
        text: { code: 'SUCCESSFULLY_SAVED/TEXT' } 
      });
    })
    .catch((error) => {
      this.saving = false;
      this.saved = false;
      this.$messages.error({ 
        title: { plain: this.widget.labels.value(this.language) }, 
        text: { 
          code: 'SAVE_FAILED/TEXT',
          params: { error: error.code }
        } 
      });
    });
  }

  receiveDelete() {
    this.onBack();
  }

  onBack() {
    this.$router.navigate([`/${this.from}`], { queryParams: this.panel !== undefined ? { panel: this.panel } : {} });
  }

  receiveEdit() {
    this.editor.show();
  }

}
