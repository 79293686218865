/**
 * @module Item
 */

import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { AuthService } from '@services/auth/auth.service';
import { UserClass } from '@class/user.class';
import { Subscription } from 'rxjs';
import { LabelsClass } from '@class/labels.class';
import { UID } from '@models/uid.model';
import { LanguageType } from '@models/language.model';
import { RightsClass } from '@class/right.class';
import { ItemRole } from '@functions/item.functions';
import { LinkableType } from '@models/links.model';
import { TagsClass } from '@class/tag.class';
import { UidClass } from '@class/uid.class';

@Component({
  selector: 'app-item-tile',
  templateUrl: './item-tile.component.html',
  styleUrls: ['./item-tile.component.css']
})
export class ItemTileComponent implements OnInit, OnDestroy {

  @Input() labels: LabelsClass;
  @Input() rights: RightsClass;
  @Input() tags: TagsClass;
  @Input() type: LinkableType;
  @Input() uid: UidClass;
  @Input() isModified: boolean;
  @Input() isInfo: boolean;
  @Input() isSlides: boolean = false;
  @Input() isDisplayable: boolean = false;
  @Input() isStudio: boolean = false;
  @Input() icon: string; //style+symbol
  @Input() color: string; //name or hexa

  @Output() sendEdit: EventEmitter<UID>;
  @Output() sendDisplay: EventEmitter<UID>;
  @Output() sendInfo: EventEmitter<UID>;
  @Output() sendStudio: EventEmitter<UID>;
  @Output() sendSlides: EventEmitter<UID>;

  animation: string;
  user: UserClass;
  user$sub: Subscription;
  language: LanguageType;
  language$sub: Subscription;
  isBookmark: boolean;
  hasRole: boolean;
  isWriter: boolean;

  constructor(
    private $auth: AuthService
  ) {
    const animations = ['fadeInLeft', 'fadeInRight', 'fadeInUp'];
    this.animation = animations[Math.floor(Math.random() * animations.length)];

    this.sendEdit = new EventEmitter<UID>();
    this.sendDisplay = new EventEmitter<UID>();
    this.sendInfo = new EventEmitter<UID>();
    this.sendStudio = new EventEmitter<UID>();
    this.sendSlides = new EventEmitter<UID>();

    this.language$sub = this.$auth.language$.subscribe({
      next: (language: LanguageType) => {
        this.language = language;
      }
    }); 
  }

  ngOnInit() {
    this.user$sub = this.$auth.user$.subscribe({
      next: (user: UserClass) => {
        if(!!user) {
          this.user = user;
          this.isBookmark = this.user.bookmarks.has(this.type, this.uid.value);
          this.hasRole = this.user.roles.has(ItemRole(this.type));
          this.isWriter = this.rights.writers.length === 0 || this.user.groups.hasAny(this.rights.writers as string[]);
        }
      }
    });
  }

  ngOnDestroy() {
    this.user$sub.unsubscribe();
    this.language$sub.unsubscribe();
  }

  onEdit() {
    this.sendEdit.emit(this.uid.value);
  }

  onStudio() {
    this.sendStudio.emit(this.uid.value);
  }

  onDisplay() {
    this.sendDisplay.emit(this.uid.value);
  }

  onSlides() {
    this.sendSlides.emit(this.uid.value);
  }

  onBookmark() {
    if(this.isBookmark) this.user.bookmarks.remove(this.type, this.uid.value);
    else this.user.bookmarks.add(this.type, this.uid.value);
    this.isBookmark = this.user.bookmarks.has(this.type, this.uid.value);
    this.$auth.updateBookmarks({ bookmarks: this.user.model.bookmarks });
  }

  onInformations() {
    this.sendInfo.emit(this.uid.value);
  }

}
