/**
 * @module Widget
 */

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { WidgetLayersClass } from '@class/widgetLayers.class';
import { ThemeOptionsClass } from '@class/themeOptions.class';
import { UID } from '@models/uid.model';
import { ItemIcon } from '@functions/item.functions';

@Component({
  selector: 'app-widget-studio-sub',
  templateUrl: './widget-studio-sub.component.html',
  styleUrls: ['./widget-studio-sub.component.css']
})
export class WidgetStudioSubComponent {

  @Input() widgetLayers: WidgetLayersClass;
  @Input() themeOptions: ThemeOptionsClass;
  @Input() currentIndicator: number;
  @Input() currentLayer: UID;
  @Input() type: 'indicators' | 'layers';
  @Output() sendDeleteIndicator: EventEmitter<void>;

  iconIndicator = ItemIcon('indicators');

  constructor() {
    this.sendDeleteIndicator = new EventEmitter<void>();
  }

  receiveDelete() {
    this.sendDeleteIndicator.next();
  }
}
