<app-editor #editor (sendRestore)="receiveRestore()" (sendDelete)="receiveDelete()" (sendSave)="receiveSave()"
  [title]="current.labels.value(language)" [uid]="current.uid.value" [icon]="current.icon.code" [color]="color" [iconColor]="current.color.hexa" [isRegistered]="current.uid.isRegistered" [isValid]="current.isValid()" [isModified]="current.isModified">
  <div app-editor-content-menu>

    <a class="dropdown-item z-depth-0" (click)="editorIndex = 0">{{ 'SETTINGS' | translate }}</a>
    <a class="dropdown-item z-depth-0" (click)="editorIndex = 1">{{ 'THEMES' | translate }}</a>
    <a class="dropdown-item z-depth-0" (click)="editorIndex = 2">{{ 'RIGHTS' | translate }}</a>
    <a class="dropdown-item z-depth-0" (click)="editorIndex = 3">{{ 'TAGS' | translate }}</a>
    <a class="dropdown-item z-depth-0" (click)="editorIndex = 4">{{ 'ICON' | translate }}</a>
    <a class="dropdown-item z-depth-0" (click)="editorIndex = 5">{{ 'COLOR' | translate }}</a>
    <a class="dropdown-item z-depth-0" (click)="setClone();editorIndex = 6;">{{ 'CLONE' | translate }}</a>

  </div>
  <div app-editor-content-body *ngIf="editor.isShown">
    <div class="alert alert-info font-small text-center" role="alert" *ngIf="current.errors('uidTheme')">
      {{ 'SELECT_A_THEME' | translate }}
    </div>
    <div [ngSwitch]="editorIndex">
      <div *ngSwitchCase="0">
        <app-editor-section-title [title]="'SETTINGS'" (sendNext)="receiveNext($event)"></app-editor-section-title>
        <app-labels [labels]="current.labels" [color]="color"></app-labels>
        <app-descriptions [descriptions]="current.descriptions" [color]="color" class="animated fadeIn"></app-descriptions>
      </div>
      <div *ngSwitchCase="1">
        <app-editor-section-title [title]="'THEMES'" (sendNext)="receiveNext($event)"></app-editor-section-title>
        <app-themes-links-change [uidTheme]="current.uidTheme" (sendSelected)="receiveSelectedTheme($event)"></app-themes-links-change>
      </div>
      <div *ngSwitchCase="2">
        <app-editor-section-title [title]="'RIGHTS'" (sendNext)="receiveNext($event)"></app-editor-section-title>
        <app-rights-links-list [rights]="current.rights"></app-rights-links-list>
      </div>
      <div *ngSwitchCase="3">
        <app-editor-section-title [title]="'TAGS'" (sendNext)="receiveNext($event)"></app-editor-section-title>
        <app-tags-links-list [tags]="current.tags"></app-tags-links-list>
      </div>
      <div *ngSwitchCase="4">
        <app-editor-section-title [title]="'ICON'" (sendNext)="receiveNext($event)"></app-editor-section-title>
        <app-icons [icon]="current.icon" [color]="current.color"></app-icons>
      </div>
      <div *ngSwitchCase="5">
        <app-editor-section-title [title]="'COLOR'" (sendNext)="receiveNext($event)"></app-editor-section-title>
        <app-colors [color]="current.color"></app-colors>
      </div>
      <div *ngSwitchCase="6">
        <app-editor-section-title [title]="'CLONE'" (sendNext)="receiveNext($event)"></app-editor-section-title>
        
        <app-labels [labels]="clone.labels" [color]="color"></app-labels>
      
        <button [disabled]="isCloning || !clone.isValid()" (click)="onClone()" type="button" mdbBtn color="default" block="true" class="z-depth-0 waves-light mt-3" mdbWavesEffect>
          {{ 'CLONE' | translate }}
        </button>


      </div>
      <div *ngSwitchDefault>
        <app-delete [code]="current.uid.code" (sendDelete)="receiveDeleteConfirmation($event)"></app-delete>
      </div>
    </div>
  </div>

</app-editor>