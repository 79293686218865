/**
 * @module Widget
 */

import { WidgetLayersModel, WidgetLayerModel, WidgetLayersDefinedOptionModel, WidgetSortType, WidgetTimelineType, WidgetDatesBoundsModel } from '@models/widgetLayers.model';
import { UID } from '@models/uid.model';
import { WidgetClass } from '@class/widget.class';
import { DeepCopy } from '@functions/copy.functions';
import { WidgetDatalabelsType } from '@models/widget.model';
import { NumbroModel } from '@models/numbro.model';
import { IconType } from '@models/icon.model';

export function LayerIcon(style: IconType = 'r'): Readonly<string> {
  return `fa${style} fa-layer-group`;
}

export function LayersDefault(widget: WidgetClass): WidgetLayersModel {
  return {
    uid: widget.uid.value,
    indicators: [],
    layers: {} as { [key in UID]: WidgetLayerModel },
    mainLayer: {} as WidgetLayerModel,
    chartTitle: DeepCopy(widget.labels.model)
  }
}

export function DateBoundsDefault(uid: UID): WidgetDatesBoundsModel {
  return { 
    uid: uid,
    greater: { equivalent: true, value: '' },
    less: { equivalent: true, value: '' }
  }
}

export function DefinedOptionIndex(definedOption: WidgetLayersDefinedOptionModel): string {
  return `${definedOption.option}${definedOption.index}${definedOption.axesXY}`;
}

export function WidgetDatalabelsTypes(): WidgetDatalabelsType[] {
  return [
    'value',
    'valueLabel',
    'pivotLabel',
    'indicatorLabel',
    'total',
    'share',
    'average',
    'rankDesc',
    'rankAsc',
    'max',
    'min',
    /*'isMax', //TODO : améliorer l'exploitation de ces types avant de les rendre disponibles
    'isMin',
    'comparedAverage',
    'isAverage',
    'isAboveAverage',
    'isBelowAverage'*/
  ];
}

export function WidgetDatalabelsFormat(type: WidgetDatalabelsType): NumbroModel {
  switch(type) {
    case 'average': return { type: 'number' };
    case 'rankAsc': return { type: 'ordinal' };
    case 'rankDesc': return { type: 'ordinal' };
    case 'share': return { type: 'percentageRounded' };
    case 'total': return { type: 'number' };
    case 'min': return { type: 'number' };
    case 'max': return { type: 'number' };
    case 'value': return { type: 'number' };
    default: return null;
  }
}

export function WidgetDatalabelsTableAuthorized(): WidgetDatalabelsType[] {
  return [
    'total',
    'average',
    'max',
    'min'
  ];
}

export function WidgetSortTypes(): WidgetSortType[] {
  return ['label_asc', 'label_desc', 'value_asc', 'value_desc'];
}

export function WidgetTimelineTypes(): WidgetTimelineType[] {
  return ['reference', 'previousYear', 'previousPeriod'];
}
