/**
 * @module Form
 */

import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-editor-section-title',
  templateUrl: './editor-section-title.component.html',
  styleUrls: ['./editor-section-title.component.css']
})
export class EditorSectionTitleComponent {

  @Input() title: string;
  @Output() sendNext: EventEmitter<boolean>;

  constructor() {
    this.sendNext = new EventEmitter<boolean>();
  }

  onNext() {
    this.sendNext.emit(true);
  }

  onPrevious() {
    this.sendNext.emit(false);
  }

}
