<div class="p-5 text-center rounded animated fadeInUp blue-grey lighten-5 mx-auto">
  <i class="{{ color }}-text fa-4x d-none d-sm-inline fad fa-arrow-alt-from-bottom app-import-uploading-icon"></i>
  <i class="{{ color }}-text fa-2x d-sm-none d-inline fad fa-arrow-alt-from-bottom app-import-uploading-icon"></i>

  <div class="app-box-shadow-depth-1 app-depth-2 p-sm-5 p-3 text-center rounded animated fadeInUp blue-grey lighten-4 my-3 mx-auto blue-grey-text">
    <i class="fa-fw {{ icon }} {{ color }}-text mr-1"></i>{{ importFrame.indicator.labels.value(language) }}
    <div class="blue-grey-text font-small">{{ upload.lines.total }} {{ 'LINES_TO_RECORD' | translate }}</div>
    <div *ngIf="upload.progress == 0" class="blue-grey-text font-small"><i class="fas fa-circle-notch fa-spin {{ color }}-text mr-1"></i>{{ 'ACCESS_REQUEST_IN_PROGRESS' | translate }}...</div>
    <div *ngIf="upload.progress > 0" class="blue-grey-text font-small">{{ upload.lines.uploaded | number:'1.0-0':language }} {{ 'SAVED_LINES' | translate }}...<strong class="{{ color }}-text">{{ upload.progress }}%</strong></div>
    <div *ngIf="upload.errors > 0" class="blue-grey-text font-small">{{ upload.lines.errors | number:'1.0-0':language }} {{ 'UNSAVED_LINES' | translate }}...<strong class="text-warning">{{ upload.errors }}%</strong></div>
  </div>
  <div class="d-flex mt-3">
    <mdb-progress [ngClass]="{ 'mr-1': upload.progress > 0 && upload.progress != 100 }" [ngStyle]="{ 'width': upload.progress + '%' }" value="100" min="0" max="100" type="info" class="app-progress-8"></mdb-progress>
    <mdb-progress [ngClass]="{ 'mr-1': upload.errors > 0 && !upload.isEnded }" [ngStyle]="{ 'width': upload.errors + '%' }" value="100" min="0" max="100" type="warning" class="app-progress-8"></mdb-progress>
    <mdb-progress [ngStyle]="{ 'width': (100 - upload.progress - upload.errors) + '%' }" value="0" min="0" max="100" type="info" class="app-progress-8"></mdb-progress>
  </div>
  <div *ngIf="upload.isErrors && upload.isEnded" class="d-flex align-items-center justify-content-center mt-3">
    <app-download-file-type [label]="'ERRORS'" (sendFileType)="receiveFileType($event)" class="d-block animated slideInLeft" style="margin: 6px;"></app-download-file-type>

    <button mdbBtn [color]="color" class="waves-light z-depth-0 px-3 animated slideInRight ml-0" type="button" (click)="onRetry()" mdbWavesEffect>
      <i class="fas fa-arrow-alt-from-bottom fa-fw"></i>
      <span class="d-none d-md-inline ml-1">{{ 'RETRY' | translate }}</span>
    </button>
  </div>
</div>