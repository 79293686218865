/**
 * @module Theme
 */

import { Component, Input } from '@angular/core';
import { ThemeOptionsClass } from '@class/themeOptions.class';
import { ItemColor } from '@functions/item.functions';
import { ThemeOptionType } from '@models/themeStudio.model';

@Component({
  selector: 'app-theme-option-elements-chart-cutout-percentage',
  templateUrl: './theme-option-elements-chart-cutout-percentage.component.html',
  styleUrls: ['./theme-option-elements-chart-cutout-percentage.component.css']
})
export class ThemeOptionElementsChartCutoutPercentageComponent {

  @Input() themeOptions: ThemeOptionsClass;

  color = ItemColor('themes');
  optionType: ThemeOptionType = 'elements_chart_cutoutPercentage';

  constructor() {}

  receiveValue(value: number) {
    this.themeOptions.setOption(this.optionType, value, this.themeOptions.limitedTo(this.optionType));
  }

}
