/**
 * @module Theme
 */

import { Component, Input, OnInit } from '@angular/core';
import { ItemColor } from '@functions/item.functions';
import { ThemeOptionsClass } from '@class/themeOptions.class';
import { ChartType } from '@models/chart.model';
import { ThemeOptionType } from '@models/themeStudio.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-theme-option-datalabels-opacity',
  templateUrl: './theme-option-datalabels-opacity.component.html',
  styleUrls: ['./theme-option-datalabels-opacity.component.css']
})
export class ThemeOptionDatalabelsOpacityComponent implements OnInit {

  @Input() themeOptions: ThemeOptionsClass;
  @Input() datalabelsIndex: number;

  color = ItemColor('themes');
  optionType: ThemeOptionType = 'datalabels_opacity';
  showDetails: boolean;
  changedSeries$sub: Subscription;
  value: number;
  values: { [key in ChartType]: number };

  constructor() {}

  ngOnInit() {
    this.setAttributes();

    this.changedSeries$sub = this.themeOptions.changedSeries$.subscribe({
      next:(option: ThemeOptionType) => {
        if(option === this.optionType) {
          this.setAttributes();
        }
      }
    });

  }

  ngOnDestroy() {
    this.changedSeries$sub.unsubscribe();
  }

  setAttributes() {
    this.values = {} as { [key in ChartType]: any };
    this.value = parseFloat(this.themeOptions.datalabels(this.datalabelsIndex).opacity.toString()) * 100;
    this.themeOptions.limitedTo(this.optionType).forEach((type: ChartType) => {
      this.values[type] = parseFloat(this.themeOptions.datalabels(this.datalabelsIndex, type).opacity.toString()) * 100;
    });
  }

  receiveShowDetails(showDetails: boolean) {
    this.showDetails = showDetails;
  }

  receiveValue(value: number, type?: ChartType) {
    this.themeOptions.setDatalabels(this.optionType, value / 100, this.datalabelsIndex, type ? [type] : undefined);
  }

}
