/**
 * @module Dashboard
 */

import { ModificationClass } from '@class/modification.class';
import { DeepCopy } from '@functions/copy.functions';
import { CommentModel, CommentsModel } from '@models/comment.model';
import { DescriptionsClass } from '@class/descriptions.class';
import { DescriptionsModel } from '@models/descriptions.model';
import { DateFromNow, DateApplyFormat } from '@functions/moment.functions';
import { UidClass } from '@class/uid.class';
import { UidModel, UID } from '@models/uid.model';
import { MapClass } from '@class/map.class';
import { CreateRandomUid } from '@functions/uid.functions';
import { LanguageType } from '@models/language.model';

export class CommentClass extends ModificationClass<CommentModel> {

  private _uid: UidClass;
  private _uidAuthor: UidClass;
  private _descriptions: DescriptionsClass;

  protected _parent: CommentModel;
  protected _inital: CommentModel;
  protected _attributes: string[];

  constructor(comment: CommentModel) {

    super();

    this._parent = comment;

    this._reset(this._parent);

    this._attributes = this.attributes.slice();
    this._attributes.push(...this._uid.attributes);
    this._attributes.push(...this._descriptions.attributes);

  }

  private _reset(comment: CommentModel) {

    if(comment.uid === undefined) comment.uid = CreateRandomUid();
    if(comment.date === undefined) comment.date = DateApplyFormat(Date(), 'YYYY-MM-DD HH:mm:ss');

    this._uid = new UidClass(comment as UidModel, true);
    this._uidAuthor = new UidClass(comment.uidAuthor as UidModel, true);
    this._descriptions = new DescriptionsClass(comment as DescriptionsModel, this._form, this._requirements, this._state);

    this.attributes.forEach((attribute: string) => {
      this._parent[attribute] = comment[attribute];
    });

    this._inital = DeepCopy(comment);
    
    this._state.flush();
    this._modifications$.next(this._state);
  }

  reset(comment?: CommentModel) {
    this._state.flush();
    this._reset(comment !== undefined ? comment : this._inital);
  }

  get attributes(): Readonly<string[]> {
    return ['date', 'uidAuthor'];
  }

  get class(): Readonly<string> {
    return 'comment';
  }

  get uidAuthor(): UidClass {
    return this._uidAuthor;
  }

  get uid(): UidClass {
    return this._uid;
  }

  get descriptions(): DescriptionsClass {
    return this._descriptions;
  }

  get date(): Readonly<string> {
    return this._parent.date;
  }

  get dateFromNow(): Readonly<string> {
    return DateFromNow(this._parent.date);
  }

}

export class CommentsClass extends ModificationClass<CommentsModel> {

  private _uid: UidClass;
  private _comments: MapClass<CommentModel>;

  protected _parent: CommentsModel;
  protected _inital: CommentsModel;
  protected _attributes: string[];

  constructor(comments: CommentsModel) {

    super();

    this._parent = comments;

    this._reset(this._parent);

    this._attributes = this.attributes.slice();
    this._attributes.push(...this._uid.attributes);

  }

  private _reset(comments: CommentsModel) {

    this._uid = new UidClass(comments as UidModel, true);

    this.attributes.forEach((attribute: string) => {
      this._parent[attribute] = comments[attribute];
    });

    if(this._parent.comments === undefined) this._parent.comments = {};

    this._inital = DeepCopy(comments);

    this._comments = new MapClass(this._parent.comments, false);
    
    this._state.flush();
    this._modifications$.next(this._state);
  }

  reset(comments?: CommentsModel) {
    this._state.flush();
    this._reset(comments !== undefined ? comments : this._inital);
  }

  get attributes(): string[] {
    return ['comments'];
  }

  get class(): Readonly<string> {
    return 'comments';
  }

  get uid(): UidClass {
    return this._uid;
  }

  get uids(): Readonly<UID[]> {
    return this._comments.keys;
  }

  get values(): Readonly<CommentModel[]> {
    return this._comments.values;
  }

  valuesWithoutBBCodes(language: LanguageType): Readonly<string[]> {
    return this._comments.values.map((comment: CommentModel) => {
      return comment.descriptions[language].replace(/\[.[a-z]*\]/g, '');
    });
  }

  has(uid: UID): Readonly<boolean> {
    return this._comments.has(uid);
  }

  add(comment: Partial<CommentModel>) {
    if(comment.uid === undefined) {
      comment.uid = CreateRandomUid();
    }
    comment.date = DateApplyFormat(Date(), 'YYYY-MM-DD HH:mm:ss');
    this._comments.add(comment.uid, comment as CommentModel);
    this.emit(this.attributes);
  }

  remove(uid: UID) {
    this._comments.remove(uid);
    this.emit(this.attributes);
  }

}
