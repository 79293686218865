/**
 * @module Date
 */

import { Component, Input, OnDestroy, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ItemColor, ItemIcon } from '@functions/item.functions';
import { DateClass } from '@class/date.class';
import { DateService } from '@services/date/date.service';
import { Subscription } from 'rxjs';
import { UID } from '@models/uid.model';
import { LanguageType } from '@models/language.model';
import { AuthService } from '@services/auth/auth.service';

@Component({
  selector: 'app-dates-links-change',
  templateUrl: './dates-links-change.component.html',
  styleUrls: ['./dates-links-change.component.css']
})
export class DatesLinksChangeComponent implements OnDestroy, OnChanges {

  @Input() date: DateClass;
  @Input() withIcon: boolean = true;
  @Input() exclude: UID[] = [];
  @Output() sendSelected: EventEmitter<DateClass>;

  color = ItemColor('dates');
  icon = ItemIcon('dates');

  dates: DateClass[];
  dates$sub: Subscription;
  list: DateClass[];
  searchLabel: string;
  language: LanguageType;
  language$sub: Subscription;

  constructor(
    private $dates: DateService,
    private $auth: AuthService
  ) {
    this.sendSelected = new EventEmitter<DateClass>();
    this.dates$sub = this.dates$sub = this.$dates.listPartials$().subscribe({
      next: (dates: DateClass[]) => {
        this.dates = dates;
        this.list = this.dates.filter(p => this.exclude.indexOf(p.uid.value) === -1 || p.uid.value === this.date.uid.value);
      }
    });

    this.language$sub = this.$auth.language$.subscribe({
      next: (language: LanguageType) => {
        this.language = language;
      }
    });
  }

  receiveTextValue(searchLabel: string) {
    this.searchLabel = searchLabel;
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.exclude !== undefined) {
      this.list = this.dates.filter(p => this.exclude.indexOf(p.uid.value) === -1 || p.uid.value === this.date.uid.value);
    }
  }

  ngOnDestroy() {
    this.dates$sub.unsubscribe();
  }

  receiveSelected(item: { uid: UID, selected: boolean }) {
    if(item.uid !== this.date.uid.value)  {
      const date = this.list.filter((date: DateClass) => { return date.uid.value === item.uid; })[0];
      this.sendSelected.emit(date);
    }
    else if(!item.selected) {
      this.sendSelected.emit(this.$dates.create());
    }
  }

}
