/**
 * @module Shared
 */

import { Component, OnInit, Input } from '@angular/core';
import { ENVIRONMENT } from '@consts/params.const';

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.css']
})
export class VersionComponent implements OnInit {

  /**
   * @description true pour afficher la version en format sombre, false (défaut) pour l'affiché en format clair
   * @type {boolean}
   * @memberof VersionComponent
   */
  @Input() dark: boolean;

  /**
   * @description version de l'application
   * @type {string}
   * @memberof AuthComponent
   */
  version: string;

  /**
   * @description Creates an instance of VersionComponent
   * @memberof VersionComponent
   */
  constructor() {
    this.version = ENVIRONMENT.code + ENVIRONMENT.version;
  }

  /**
   * @description initialise les valeurs par défaut
   * @memberof VersionComponent
   */
  ngOnInit() {
    if(this.dark === undefined) this.dark = false;
  }

}
