/**
 * @module Theme
 */

import { Component, Input } from '@angular/core';
import { ItemColor } from '@functions/item.functions';
import { ThemeOptionsClass } from '@class/themeOptions.class';
import { ChartType } from '@models/chart.model';
import { ThemeOptionType } from '@models/themeStudio.model';

@Component({
  selector: 'app-theme-option-elements-fill',
  templateUrl: './theme-option-elements-fill.component.html',
  styleUrls: ['./theme-option-elements-fill.component.css']
})
export class ThemeOptionElementsFillComponent {

  @Input() themeOptions: ThemeOptionsClass;
  @Input() serieIndex: number;

  showDetails: boolean;
  color = ItemColor('themes');
  optionType: ThemeOptionType = 'elements_fill';

  constructor() {
    this.showDetails = false;
  }

  onValue(value: string, type?: ChartType) {
    this.themeOptions.setSeries(this.optionType, value === 'false' ? false : value, this.serieIndex, type ? [type] : undefined);
  }

  receiveShowDetails(showDetails: boolean) {
    this.showDetails = showDetails;
  }

  values = {
    start: { icon: 'arrow-alt-to-bottom', style: 'start', label: 'START' },
    end: { icon: 'arrow-alt-to-top', style: 'end', label: 'END' },
    origin: { icon: 'arrow-alt-from-top', style: 'origin', label: 'ORIGIN' },
    false: { icon: 'times', style: 'false', label: 'WITHOUT' }
  };

}
