/**
 * @module Link
 */

import { LinksModel } from '@models/links.model';

export function LinksModelConstructor(): LinksModel {
  return {
    links: {
      dashboards: {},
      widgets: {},
      servers: {},
      indicators: {},
      dates: {},
      pivots: {},
      users: {},
      themes: {},
      tags: {},
      rights: {}
    }
  };
}