<app-browser-frame #frameModal [isCreate]="false" (sendSearch)="receiveSearch($event)" [color]="color" [icon]="icon" [label]="label">

  <div app-browser-frame-content-options>

  </div>

  <app-empty app-browser-frame-content-list [type]="'servers'" (sendAction)="receiveCreate()" *ngIf="servers != undefined && servers.length == 0"></app-empty>
  <app-loading app-browser-frame-content-list *ngIf="servers == undefined"></app-loading>
  
  <div app-browser-frame-content-list *ngFor="let server of servers | searchLabels:searchLabel:language ; trackBy: trackByUid">
    <app-item-strip
    [labels]="server.labels"
    [tags]="server.tags"
    [type]="'servers'"
    [uid]="server.uid"
    [icon]="icon"
    [color]="color"
    [isModified]="server.modifications$.value.isModified"
    (sendEdit)="receiveEdit($event)"
    >
      <div app-item-strip-content-description>
        <div *ngIf="uidMain == server.uid.value" class="text-success font-extra-small font-weight-bold">
          <i class="fad fa-check mr-1"></i>{{ 'DEFAULT_SERVER' | translate }}
        </div>
      </div>
    </app-item-strip>
  </div>
</app-browser-frame>

<app-editor #editor (sendRestore)="receiveRestore()" (sendDelete)="receiveDelete()" (sendSave)="receiveSave()"
  [title]="current.labels.value(language)" [uid]="current.uid.value" [icon]="icon" [color]="color" [isRegistered]="current.uid.isRegistered" [isValid]="current.isValid()" [isModified]="current.isModified">
  <div app-editor-content-menu>

    <a class="dropdown-item z-depth-0" (click)="editorIndex = 0">{{ 'SETTINGS' | translate }}</a>
    <a class="dropdown-item z-depth-0" (click)="editorIndex = 1">{{ 'TAGS' | translate }}</a>
    <a class="dropdown-item z-depth-0" (click)="editorIndex = 2">{{ 'CONFIGURATION' | translate }}</a>

  </div>
  <div app-editor-content-body *ngIf="editor.isShown">
    <div [ngSwitch]="editorIndex">
      <div *ngSwitchCase="0">
        <app-editor-section-title [title]="'SETTINGS'" (sendNext)="receiveNext($event)"></app-editor-section-title>
        <app-main [server]="current" class="d-block mb-4"></app-main>
        <app-url [server]="current"></app-url>
        <app-labels [labels]="current.labels" [color]="color"></app-labels>
        <app-descriptions [descriptions]="current.descriptions" [color]="color" class="animated fadeIn"></app-descriptions>
      </div>
      <div *ngSwitchCase="1">
        <app-editor-section-title [title]="'TAGS'" (sendNext)="receiveNext($event)"></app-editor-section-title>
        <app-tags-links-list [tags]="current.tags"></app-tags-links-list>
      </div>
      <div *ngSwitchCase="2">
        <app-editor-section-title [title]="'CONFIGURATION'" (sendNext)="receiveNext($event)"></app-editor-section-title>
        <app-metas [server]="current"></app-metas>
      </div>
      <div *ngSwitchDefault>
        <app-delete [code]="current.uid.code" (sendDelete)="receiveDeleteConfirmation($event)"></app-delete>
      </div>
    </div>
  </div>

</app-editor>