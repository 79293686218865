<div class="app-dashboard-studio-cell white" [ngClass]="{ 'app-dashboard-studio-cell-resize-rectangle': resizing }" [ngStyle]="{ 'height': cell.height + 'px', 'width': cell.width + 'px' }"
  mwlResizable
  [enableGhostResize]="true"
  [resizeCursorPrecision]="10"
  [resizeSnapGrid]="{ left: dashboardLayout.size + dashboardLayout.gap, right: dashboardLayout.size + dashboardLayout.gap, top: dashboardLayout.size + dashboardLayout.gap, bottom: dashboardLayout.size + dashboardLayout.gap }"
  (resizeEnd)="onResizeEnd($event)"
  >
  <div class="app-dashboard-studio-cell-content flex-grow-1" *ngIf="widgetNotFound == false ; else notFound">
    <app-widget-studio-chart *ngIf="widgetLayers" [widgetLayers]="widgetLayers" [width]="cell.width" [height]="cell.height"></app-widget-studio-chart>
  </div>
  <div *ngIf="resizing" class="app-dashboard-studio-cell-resize-handle-top app-cursor-resize-top-hover" mwlResizeHandle [resizeEdges]="{ top: true }"></div>
  <div *ngIf="resizing" class="app-dashboard-studio-cell-resize-handle-left app-cursor-resize-left-hover" mwlResizeHandle [resizeEdges]="{ left: true }"></div>
  <div *ngIf="resizing" class="app-dashboard-studio-cell-resize-handle-right app-cursor-resize-right-hover" mwlResizeHandle [resizeEdges]="{ right: true }"></div>
  <div *ngIf="resizing" class="app-dashboard-studio-cell-resize-handle-bottom app-cursor-resize-bottom-hover" mwlResizeHandle [resizeEdges]="{ bottom: true }"></div>
</div>

<div class="d-flex flex-column ml-3 float-left pl-5" [ngStyle]="{ 'transform': 'translateY(-' + (cell.height / 2 + 58 * 1.5) + 'px)' }">

  <button *ngIf="current != cell.uid.value" mdbBtn (click)="onSelected()" rounded="true" type="button" color="light" mdbWavesEffect class="app-dashboard-studio-cell-edit app-box-shadow-depth-2 px-3 mx-0 my-1">
    <i class="fas fa-sliders-h fa-fw {{ color }}-text animated rotateIn"></i>
  </button>

  <button *ngIf="current == cell.uid.value" mdbBtn (click)="onSelected()" rounded="true" type="button" [color]="color" mdbWavesEffect class="app-dashboard-studio-cell-edit app-box-shadow-depth-2 px-3 mx-0 my-1">
    <i class="fas fa-sliders-h fa-fw animated rotateIn"></i>
  </button>

  <button *ngIf="!resizing" mdbBtn (click)="onResize()" rounded="true" type="button" color="light" mdbWavesEffect class="app-dashboard-studio-cell-edit app-box-shadow-depth-2 px-3 mx-0 my-1">
    <i class="fas fa-crop-alt fa-fw {{ color }}-text animated rotateIn"></i>
  </button>

  <button *ngIf="resizing" mdbBtn (click)="onResize()" rounded="true" type="button" [color]="color" mdbWavesEffect class="app-dashboard-studio-cell-edit app-box-shadow-depth-2 px-3 mx-0 my-1">
    <i class="fas fa-crop-alt fa-fw animated rotateIn"></i>
  </button>

  <button *ngIf="!cell.display" mdbBtn (click)="onDisplay()" rounded="true" type="button" color="warning" mdbWavesEffect class="app-dashboard-studio-cell-edit app-box-shadow-depth-2 px-3 mx-0 my-1">
    <i class="fas fa-eye-slash fa-fw animated rotateIn"></i>
  </button>

  <button *ngIf="cell.display" mdbBtn (click)="onDisplay()" rounded="true" type="button" color="light-green" mdbWavesEffect class="app-dashboard-studio-cell-edit app-box-shadow-depth-2 px-3 mx-0 my-1">
    <i class="fas fa-eye fa-fw animated rotateIn"></i>
  </button>

</div>

<ng-template #notFound>
  <app-empty [action]="false" [description]="'WIDGET_NOT_FOUND/TEXT'"></app-empty>
</ng-template>