/**
 * @module User
 */

export function DeletionDurations(): Readonly<{ fromLastConnection: number, fromCreation: number, fromDesactivation: number }> {
  return {
    fromLastConnection: 360, //si c'est déjà connecté au moins une fois
    fromCreation: 30, //si ne s'est jamais connecté
    fromDesactivation: 90 //si le compte a été suspendu
  };
}