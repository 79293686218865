/**
 * @module Labels
 */

import { LabelType } from '@models/labels.model';
import { LanguageType } from '@models/language.model';
import { LanguagesTypes } from '@functions/language.functions';

export function SetLabels(label: string = ''): LabelType {
  return LanguagesTypes().reduce((obj, item) => {
    obj[item] = label;
    return obj;
  }, {}) as { [key in LanguageType]: string };
}
