/**
 * @module Shared
 */

import * as firebase from 'firebase';

/**
 * @description Renvoi la valeur appelant la destruction d'un objet type map dans firebase au type unknown pour s'adapter au format réel du l'objet à supprimer
 * @readonly
 * @type {Readonly<unknown>}
 * @memberof FirebaseService
 */
export function DeleteFieldValue(): Readonly<unknown> {
  return firebase.firestore.FieldValue.delete() as Readonly<unknown>;
}