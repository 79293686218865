/**
 * @module Uid
 */

import { UidModel, UID } from '@models/uid.model';

export class UidClass {
  
  private _model: UidModel;

  private _isRegistered: boolean;

  constructor(uid: UidModel, isRegistered: boolean) {
    this._model = uid;
    this._isRegistered = isRegistered;
  }

  get isRegistered(): Readonly<boolean> {
    return this._isRegistered;
  }

  set isRegistered(isRegistered: boolean) {
    this._isRegistered = isRegistered;
  }

  get value(): Readonly<UID> {
    return this._model.uid;
  }

  get class(): Readonly<string> {
    return 'uid';
  }

  get attributes(): string[] {
    return ['uid'];
  }

  get code(): Readonly<string> {
    return this._model.uid.substring(this._model.uid.length - 4, this._model.uid.length).toUpperCase();
  }

}