/**
 * @module Comment
 */

import { Injectable } from '@angular/core';
import { FirebaseService } from '@services/firebase/firebase.service';
import { Observable } from 'rxjs';
import { CommentsClass } from '@class/comment.class';
import { CommentsModel } from '@models/comment.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CommentService {

  constructor(
    private $firebase: FirebaseService
  ) {}

  get(uid: string): Observable<CommentsClass> {
    return (this.$firebase.angularFirebase.object(`comments/${uid}`).valueChanges() as Observable<CommentsModel>)
    .pipe(
      map((comments: CommentsModel) => {
        if(comments === null) comments = { uid: uid, comments: {} };
        return new CommentsClass(comments);
      })
    );
  }

  set(comments: CommentsClass): Promise<void> {
    return this.$firebase.angularFirebase.object<CommentsModel>(`comments/${comments.uid.value}`).update(comments.model);
  }
}
