/**
 * @module Chart
 */

import { Component, Input, OnDestroy, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { ThemeOptionsClass } from '@class/themeOptions.class';
import { ChartType } from '@models/chart.model';
import { Label } from 'ng2-charts';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ThemeStudioService } from '@services/themeStudio/theme-studio.service';
import { DeepMerge } from '@functions/copy.functions';
import { CarouselComponent } from 'ng-uikit-pro-standard';
import * as JustGage from 'JustGage';
import { WidgetGaugeParamsModel } from '@models/widgetLayers.model';
import { Total } from '@functions/math.functions';

@Component({
  selector: 'app-gauge-chart',
  templateUrl: './gauge-chart.component.html',
  styleUrls: ['./gauge-chart.component.css']
})
export class GaugeChartComponent implements OnDestroy, AfterViewInit, OnInit {

  public type: ChartType = 'gauge';

  @Input() options: ThemeOptionsClass;
  @Input() donut: boolean;
  @Input() id: string;
  @ViewChild('carousel') carousel: CarouselComponent;

  public labels: Label[] = [];
  public data: ChartDataSets[] = [];
  public datasets: ChartDataSets[] = [];
  public mergedOptions: ChartOptions;
  public localOptions: ChartOptions = {
    title: {
      text: '',
    }
  };

  changedOptions$sub: Subscription;
  changedSeries$sub: Subscription;
  data$sub: Subscription;
  gauge: JustGage;
  gaugeOptions: WidgetGaugeParamsModel;

  constructor(
    private $translate: TranslateService,
    private $themeStudio: ThemeStudioService
  ) {
    this.localOptions.title.text = `${this.$translate.instant('TITLE')} - ${this.$translate.instant(this.type.toUpperCase() + '/CHART')}`;
    this.gaugeOptions = {};
  }

  ngOnInit() {
    this.mergedOptions = DeepMerge(this.options.options(this.type), this.localOptions);
  }

  ngOnDestroy() {
    this.changedOptions$sub.unsubscribe();
    this.changedSeries$sub.unsubscribe();
    this.data$sub.unsubscribe();
  }

  ngAfterViewInit() {

    this.data$sub = this.$themeStudio.data$.subscribe({
      next:(data) => {
        if(!!data) {
          this.data = data.series;
          this.labels = data.labels;
      
          this.datasets = new Array(this.data.length).fill({});
          this.data.forEach((dst: ChartDataSets, index: number) => {
            this.datasets[index] = DeepMerge(dst, this.options.series(index, this.type));
          });
          this.setGauge();
        }
      }
    });

    this.changedOptions$sub = this.options.changedOptions$.subscribe({
      next:() => {
        this.mergedOptions = DeepMerge(this.options.options(this.type), this.localOptions);
        this.setGauge();
      }
    });

    this.changedSeries$sub = this.options.changedSeries$.subscribe({
      next:() => {
        this.datasets = new Array(this.data.length).fill({});
        this.data.forEach((dst: ChartDataSets, index: number) => {
          this.datasets[index] = DeepMerge(dst, this.options.series(index, this.type));
        });
        this.setGauge();
      }
    });
  }

  setGauge() {
    if(this.gauge) this.gauge.destroy();

    if(this.mergedOptions) {
      this.gaugeOptions = this.gaugeParams(this.mergedOptions, this.datasets);
    }
    this.gaugeOptions.id = 'gauge' + this.id;
    this.gaugeOptions.donut = this.donut;

    this.gauge = new JustGage(this.gaugeOptions);
  }

  next() {
    this.carousel.nextSlide();
  }

  prev() {
    this.carousel.previousSlide();
  }

  gaugeParams(options: ChartOptions, datasets: ChartDataSets[]): WidgetGaugeParamsModel {

    let gauge: WidgetGaugeParamsModel = {}

    if(gauge === undefined) gauge = {};
    if(datasets.length === 0) return;

    const index = 0;

    gauge.donut = this.donut;
    gauge.hideMinMax = false;
    gauge.displayRemaining = false;

    gauge.value = Total(datasets[index].data as number[]);
    gauge.relativeGaugeSize = true;
    gauge.counter = options.animation.duration > 0;
    //gauge.levelColors = datasets[index].backgroundMultiGradiant ? (datasets[index].backgroundColor as string[]).map(c => c.slice(0, 7)).reverse() : [(datasets[index].backgroundColor as string).slice(0, 7)];
    gauge.levelColors = [(datasets[index].backgroundColor as string).slice(0, 7)];
    gauge.donutStartAngle = (options.rotation / Math.PI + 1) * 180;

    //animation
    gauge.startAnimationTime = options.animation.duration;
    gauge.refreshAnimationTime = options.animation.duration;

    //titre
    gauge.label = options.title.display ? this.localOptions.title.text as string : undefined;
    gauge.labelFontColor = options.title.fontColor as string;

    //valeur
    gauge.hideValue = !options.legend.display;
    gauge.valueFontColor = options.legend.labels.fontColor as string;
    gauge.valueFontFamily = options.legend.labels.fontFamily as string;

    //target
    gauge.target = Math.round(gauge.value * (1 + Math.random()) + 1);

    //max
    gauge.max = gauge.target;

    //min
    gauge.min = 0;

    //pointer
    gauge.pointer = options.gauge.pointer;
    gauge.pointerOptions = options.gauge.pointerOptions;

    //shadow
    gauge.shadowOpacity = options.gauge.shadowOpacity;
    gauge.shadowSize = options.gauge.shadowSize;
    gauge.shadowVerticalOffset = options.gauge.shadowVerticalOffset;
    gauge.showInnerShadow = options.gauge.showInnerShadow;

    //autres
    gauge.gaugeColor = options.gauge.gaugeColor;
    gauge.gaugeWidthScale = options.gauge.gaugeWidthScale;

    return gauge;
  }

}