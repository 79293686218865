<app-browser-panel (sendCreate)="receiveCreate()" [role]="'w'" (sendBookmark)="receiveBookmark()" (sendSearch)="receiveSearch()" [color]="color" [loading]="widgets == undefined" [empty]="widgets?.length == 0"
  [title]="'LETS_BEGIN'" [text]="'LETS_BEGIN_WIDGET'" [color]="color" [action]="'NEW'" [icon]="'far fa-plus'" (sendAction)="receiveCreate()">
  
  <div app-browser-panel-content-list *ngFor="let widget of widgets | bookmarks:bookmarks | tags:tags | searchLabels:searchLabel:language ; trackBy: trackByUid">
    <app-item-tile class="d-sm-block d-none"
    [labels]="widget.labels"
    [rights]="widget.rights"
    [tags]="widget.tags"
    [type]="'widgets'"
    [uid]="widget.uid"
    [icon]="widget.icon.code"
    [color]="widget.color.hexa"
    [isModified]="widget.modifications$.value.isModified"
    [isInfo]="widget.descriptions.value(language).length > 0 || widget.tags.list.length > 0"
    (sendEdit)="receiveEdit($event)"
    (sendInfo)="receiveInfo($event)"
    (sendStudio)="receiveSudio($event)"
    [isStudio]="true"
    ></app-item-tile>

    <app-item-strip class="d-block d-sm-none"
    [showInfoAndBookmark]="true"
    [labels]="widget.labels"
    [type]="'widgets'"
    [uid]="widget.uid"
    [tags]="widget.tags"
    [icon]="widget.icon.code"
    [color]="widget.color.hexa"
    [isModified]="widget.modifications$.value.isModified"
    [isInfo]="widget.descriptions.value(language).length > 0 || widget.tags.list.length > 0"
    (sendEdit)="receiveEdit($event)"
    (sendInfo)="receiveInfo($event)"
    (sendStudio)="receiveSudio($event)"
    [isStudio]="true"
    ></app-item-strip>
  </div>

  <div app-browser-panel-content-fixed-button>
    <a *ngIf="hasFilters" (click)="onRemoveFilters()" mdbBtn floating="true" color="warning" class="waves-light" mdbWavesEffect><i class="fad fa-search-minus"></i></a>
    <a (click)="offsetDateModal.show()" mdbBtn floating="true" color="default" class="waves-light" mdbWavesEffect><i class="fad fa-calendar-edit"></i></a>
  </div>
</app-browser-panel>

<app-widget-editor #editor [current]="current"></app-widget-editor>

<app-item-info #info
[title]="current.labels.value(language)"
[icon]="current.icon.code"
[color]="color"
[iconColor]="current.color.hexa" 
[descriptions]="current.descriptions"
[tags]="current.tags"
></app-item-info>

<app-browser-filters #filtersModal [tagsList]="tagsList" [onlyBookmarks]="onlyBookmarks" [searchLabel]="searchLabel" (sendSearchLabel)="receiveSearchLabel($event)" (sendBookmark)="receiveBookmark($event)" (sendTags)="receiveTags($event)"></app-browser-filters>

<div mdbModal #offsetDateModal="mdbModal" class="modal fade" style="overflow-y: scroll" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header d-flex align-items-center flex-column">

        <h6 class="modal-title w-100 text-center">
          <div class="font-weight-bold text-elegant mt-1">{{ 'DATE_OFFSET' | translate }}</div>
        </h6>

      </div>

      <div class="modal-body overflow-hidden font-small font-weight-bold">
        
        <div class="m-1 d-flex align-items-center justify-content-between">{{ 'FREQUENCY' | translate }}
          <div>
            <span class="frequency app-cursor-pointer" (click)="setOffsetFrequency('D')" [ngClass]="{ 'green': offsetDate.frequency == 'D' }">{{ 'DAY' | translate }}</span>
            <span class="frequency app-cursor-pointer" (click)="setOffsetFrequency('W')" [ngClass]="{ 'green': offsetDate.frequency == 'W' }">{{ 'WEEK' | translate }}</span>
            <span class="frequency app-cursor-pointer" (click)="setOffsetFrequency('M')" [ngClass]="{ 'green': offsetDate.frequency == 'M' }">{{ 'MONTH' | translate }}</span>
            <span class="frequency app-cursor-pointer" (click)="setOffsetFrequency('Q')" [ngClass]="{ 'green': offsetDate.frequency == 'Q' }">{{ 'QUARTER' | translate }}</span>
            <span class="frequency app-cursor-pointer" (click)="setOffsetFrequency('Y')" [ngClass]="{ 'green': offsetDate.frequency == 'Y' }">{{ 'YEAR' | translate }}</span>
          </div>
        </div>
        <br>
        <div class="m-1 d-flex align-items-center justify-content-between">{{ 'AMOUNT' | translate }} <app-number [value]="offsetDate.amount" [min]="0" (sendValue)="setOffsetAmount($event)"></app-number></div>
        <br>
        <div class="w-100 d-flex">
          <button (click)="offsetDateModal.hide()" type="button" mdbBtn color="secondary" class="z-depth-0 waves-light mt-3" mdbWavesEffect>
            {{ 'CLOSE' | translate }}
          </button>

          <button [disabled]="saving" (click)="setOffsetDate()" type="button" mdbBtn color="light-green" class="ml-auto z-depth-0 waves-light mt-3" mdbWavesEffect>
            {{ 'SAVE' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>