/**
 * @module Empty
 */

import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-empty-panel',
  templateUrl: './empty-panel.component.html',
  styleUrls: ['./empty-panel.component.css']
})
export class EmptyPanelComponent {

  @Input() title: string;
  @Input() text: string;
  @Input() color: string;
  @Input() action: string;
  @Input() actionAlt: string;
  @Input() icon: string;
  @Input() iconAlt: string;
  @Input() colorAltText: string;
  @Output() sendAction: EventEmitter<void>;
  @Output() sendActionAlt: EventEmitter<void>;

  constructor() {
    this.sendAction = new EventEmitter<void>();
    this.sendActionAlt = new EventEmitter<void>();
  }

  onAction() {
    this.sendAction.emit();
  }

  onActionAlt() {
    this.sendActionAlt.emit();
  }

}
