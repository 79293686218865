<app-theme-option [categories]="themeOptions.optionsConfiguration[optionType].categories" [label]="themeOptions.optionsConfiguration[optionType].label">
  <div app-theme-option-content>
    <app-show-details-option [showDetails]="showDetails" (sendShowDetails)="receiveShowDetails($event)">
      <div app-show-details-option-content>
        <div class="btn-group" mdbDropdown>
          <a mdbDropdownToggle type="button" mdbBtn size="sm" color="light" class="dropdown-toggle waves-light z-depth-0 px-3 dropdown-light" mdbWavesEffect>
            <span class="{{color}}-text font-weight-bolder">{{ modes[themeOptions.options().tooltips.mode.toUpperCase()].label | translate }}</span>&nbsp;
          </a>
          <div class="dropdown-menu dropdown-dark dropdown-menu-right font-small app-drop-small-limit-5">
            <a *ngFor="let mode of modes | keyvalue" class="dropdown-item z-depth-0" (click)="onMode(mode.value.value)">{{ mode.value.label | translate }}</a>
          </div>
        </div>
      </div>
    </app-show-details-option>
  </div>
</app-theme-option>

<div *ngIf="showDetails">
  <app-theme-option [categories]="themeOptions.optionsConfiguration[optionType].categories" [label]="themeOptions.optionsConfiguration[optionType].label" [dark]="4" [limitedTo]="limitedToNonCartesians">
    <div app-theme-option-content>
      <div class="btn-group" mdbDropdown>
        <a mdbDropdownToggle type="button" mdbBtn size="sm" color="light" class="dropdown-toggle waves-light z-depth-0 px-3 dropdown-light" mdbWavesEffect>
          <span class="{{color}}-text font-weight-bolder">{{ modes[themeOptions.options('pie').tooltips.mode.toUpperCase()].label | translate }}</span>&nbsp;
        </a>
        <div class="dropdown-menu dropdown-dark dropdown-menu-right font-small app-drop-small-limit-5">
          <a *ngFor="let mode of modes | keyvalue" class="dropdown-item z-depth-0" (click)="onModeNonCartesians(mode.value.value)">{{ mode.value.label | translate }}</a>
        </div>
      </div>
    </div>
  </app-theme-option>

  <app-theme-option [categories]="themeOptions.optionsConfiguration[optionType].categories" [label]="themeOptions.optionsConfiguration[optionType].label" [dark]="4" [limitedTo]="limitedToCartesians">
    <div app-theme-option-content>
      <div class="btn-group" mdbDropdown>
        <a mdbDropdownToggle type="button" mdbBtn size="sm" color="light" class="dropdown-toggle waves-light z-depth-0 px-3 dropdown-light" mdbWavesEffect>
          <span class="{{color}}-text font-weight-bolder">{{ cartesians[themeOptions.options('bar').tooltips.mode.toUpperCase()].label | translate }}</span>&nbsp;
        </a>
        <div class="dropdown-menu dropdown-dark dropdown-menu-right font-small app-drop-small-limit-5">
          <a *ngFor="let mode of cartesians | keyvalue" class="dropdown-item z-depth-0" (click)="onModeCartesians(mode.value.value)">{{ mode.value.label | translate }}</a>
        </div>
      </div>
    </div>
  </app-theme-option>
</div>