/**
 * @module Copy
 */

export function DeepCopy<T>(source: T): T {
  let copy: any;

  // Handle the 3 simple types, and null or undefined
  if (null == source || 'object' != typeof source) return source;

  // Handle Date
  if (source instanceof Date) {
    copy = new Date();
    copy.setTime(source.getTime());
    return copy;
  }

  // Handle Array
  if (source instanceof Array) {
    copy = [];
    for (let i = 0, len = source.length; i < len; i++) {
      copy[i] = DeepCopy(source[i]);
    }
    return copy;
  }

  // Handle Object
  if (source instanceof Object) {
    copy = {};
    for (let attr in source) {
      if (source.hasOwnProperty(attr)) copy[attr] = DeepCopy(source[attr]);
    }
    return copy;
  }

  throw new Error('DeepCopy');
}

export function DeepMerge<T>(...objects: T[]): T {
  const isObject = (obj: any) => obj && typeof obj === 'object';
  
  return objects.reduce<T>((prev, obj) => {
    Object.keys(obj).forEach(key => {
      const pVal = prev[key];
      const oVal = obj[key];
      
      if (Array.isArray(pVal) && Array.isArray(oVal)) {
        prev[key] = pVal.concat(...oVal);
      }
      else if (isObject(pVal) && isObject(oVal)) {
        prev[key] = DeepMerge(pVal, oVal);
      }
      else {
        prev[key] = oVal;
      }
    });
    
    return prev;
  }, {} as T);
}