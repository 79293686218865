/**
 * @module Dashboard
 */

import { UidModel, UID } from '@models/uid.model';
import { ModificationClass, ModificationStateClass } from '@class/modification.class';
import { DeepCopy } from '@functions/copy.functions';
import { UidClass } from '@class/uid.class';
import { DashboardLayoutCellModel } from '@models/dashboardLayout.model';

export class DashboardLayoutCellClass extends ModificationClass<DashboardLayoutCellModel> {

  private _uid: UidClass;

  private _height: number;
  private _width: number;
  private _resizeRatio: number;

  protected _parent: DashboardLayoutCellModel;
  protected _inital: DashboardLayoutCellModel;
  protected _attributes: string[];

  constructor(dashboardLayoutCell: DashboardLayoutCellModel, state: ModificationStateClass) {

    super();

    this._parent = dashboardLayoutCell;
    this._state = state;

    this._reset(this._parent);

    this._attributes = this.attributes.slice();
    this._attributes.push(...this._uid.attributes);

  }

  private _reset(dashboardLayoutCell: DashboardLayoutCellModel) {
    this._uid = new UidClass(dashboardLayoutCell as UidModel, true);

    this.attributes.forEach((attribute: string) => {
      this._parent[attribute] = dashboardLayoutCell[attribute];
    });

    this._inital = DeepCopy(dashboardLayoutCell);
    
    this._state.flush();
    this._modifications$.next(this._state);
  }

  reset(dashboardLayoutCell?: DashboardLayoutCellModel) {
    this._state.flush();
    this._reset(dashboardLayoutCell !== undefined ? dashboardLayoutCell : this._inital);
  }

  get uid(): UidClass {
    return this._uid;
  }

  get attributes(): Readonly<string[]> {
    return ['rank', 'columns', 'rows', 'uidWidget', 'display'];
  }

  get class(): Readonly<string> {
    return 'dashboardLayoutCell';
  }

  get rank(): Readonly<number> {
    return this._parent.rank;
  }

  get columns(): Readonly<number> {
    return this._parent.columns;
  }

  get rows(): Readonly<number> {
    return this._parent.rows;
  }

  get display(): Readonly<boolean> {
    return this._parent.display;
  }

  get uidWidget(): Readonly<UID> {
    return this._parent.uidWidget;
  }

  get height(): Readonly<number> {
    return this._height;
  }

  get width(): Readonly<number> {
    return this._width;
  }

  setSizes(height: number, width: number, resizeRatio: number) {
    this._height = height;
    this._width = width;
    this._resizeRatio = resizeRatio;
  }

  get resizeRatio(): Readonly<number> {
    return this._resizeRatio;
  }

}