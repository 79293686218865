/**
 * @module Dashboard
 */

import { Component, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { UID } from '@models/uid.model';
import { ItemColor, ItemIcon } from '@functions/item.functions';
import { DashboardService } from '@services/dashboard/dashboard.service';
import { DashboardClass } from '@class/dashboard.class';
import { Subscription } from 'rxjs';
import { LanguageType } from '@models/language.model';
import { AuthService } from '@services/auth/auth.service';
import { DashboardLayoutClass } from '@class/dashboardLayout.class';
import { DashboardDisplayFramesComponent } from '@components/dashboards/dashboard-display-frames/dashboard-display-frames.component';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import pptxgen from "pptxgenjs";
import bbCodeParser from 'js-bbcode-parser';
import { DashboardDisplayService } from '@services/dashboardDisplay/dashboard-display.service';
import { CompanyService } from '@services/company/company.service';
import { DateApplyFormat, DateToIso } from '@functions/moment.functions';
import { Brightness } from '@functions/color.functions';
import { DashboardLayoutChartModel } from '@models/dashboardLayout.model';
import { TranslateService } from '@ngx-translate/core';

interface layoutModel {
  code: string;
  size: string;
  w: number;
  h: number;
};

@Component({
  selector: 'app-dashboard-display',
  templateUrl: './dashboard-display.component.html',
  styleUrls: ['./dashboard-display.component.css']
})
export class DashboardDisplayComponent implements OnDestroy {
  
  @ViewChild('display') display: DashboardDisplayFramesComponent;
  @ViewChild('capture') capture : ElementRef;

  color = ItemColor('dashboards');
  icon = ItemIcon('dashboards');

  dashboard: DashboardClass;
  dashboard$sub: Subscription;
  language: LanguageType;
  language$sub: Subscription;
  uid: UID;
  panel: string;
  from: string;
  dashboardLayout$sub: Subscription;
  framesChange$sub: Subscription;
  dashboardLayout: DashboardLayoutClass;
  slides: boolean;

  processingPDF: boolean;
  processingIMG: boolean;
  processingPPT: boolean;
  editorIndex: number;
  editorIndexMax: number;

  layouts: layoutModel[];

  constructor(
    private $router: Router,
    private $dashboard: DashboardService,
    private $dashboardDisplay: DashboardDisplayService,
    private $auth: AuthService,
    private $company: CompanyService,
    private $translate: TranslateService
  ) { 
    this.uid = this.$router.parseUrl(this.$router.url).queryParams.uid;
    this.panel = this.$router.parseUrl(this.$router.url).queryParams.panel;
    this.from = this.$router.parseUrl(this.$router.url).queryParams.from;
    this.slides = this.$router.parseUrl(this.$router.url).queryParams.slides == 'true';
    this.dashboard = this.$dashboard.create();

    this.processingPDF = false;
    this.processingIMG = false;
    this.processingPPT = false;
    this.editorIndex = 0;
    this.editorIndexMax = 1;

    this.layouts = [
      { code: 'LAYOUT_16x10', size: '16:10', w: 10, h: 6.25 },
      { code: 'LAYOUT_16x9', size: '16:9', w: 10, h: 5.625 },
      { code: 'LAYOUT_4x3', size: '4:3', w: 10, h: 7.5 },
      { code: 'LAYOUT_A4', size: 'A4', w: 11.67, h: 8.27 },
      { code: 'LAYOUT_US_LETTER', size: 'US Letter', w: 11, h: 8.5 },
    ];

    this.language$sub = this.$auth.language$.subscribe({
      next: (language: LanguageType) => {
        this.language = language;
      }
    });

    if(!this.uid) this.onBack();
    else {
      this.dashboard$sub = this.$dashboard.get$(this.uid).subscribe({
        next:(dashboard: DashboardClass) => {
          if(!!dashboard) {
            this.dashboard = dashboard;

            if(this.dashboardLayout$sub) this.dashboardLayout$sub.unsubscribe();
            this.dashboardLayout$sub = this.$dashboard.getLayout$(this.dashboard).subscribe({
              next:(dashboardLayout: DashboardLayoutClass) => {
                this.dashboardLayout = dashboardLayout;
                this.dashboardLayout.setWidth(window.innerWidth);
                if(this.framesChange$sub) this.framesChange$sub.unsubscribe();
                this.framesChange$sub = this.display.framesChange$.subscribe(() => this.setWidth());
              }
            });
          }
        }
      });
    }
  }

  receiveSlides(slides: boolean) {
    this.slides = slides;
  }

  receiveNext(isNext: boolean) {
    this.editorIndex = this.editorIndex + (isNext ? 1 : -1);
    if(this.editorIndex < 0) this.editorIndex = this.editorIndexMax;
    if(this.editorIndex > this.editorIndexMax) this.editorIndex = 0;
  }  

  onDownloadPowerPoint(layout: layoutModel) {

    let pres = new pptxgen();
    pres.defineLayout({ name: layout.code, width: layout.w, height: layout.h })
    pres.layout = layout.code;
    pres.author = this.$auth.user$.value.id.fullname;
    pres.company = this.$company.label;
    pres.title = this.dashboard.labels.value(this.language);

    let cells = this.dashboardLayout.cells.map(c => c.uidWidget);
    let charts = this.$dashboardDisplay.charts(this.uid).sort((chartA: DashboardLayoutChartModel, chartB: DashboardLayoutChartModel) => {
      let indexA = cells.indexOf(chartA.uid);
      let indexB = cells.indexOf(chartB.uid);
      return indexA - indexB;
    })

    const colorGrey = '212121';
    const colorWhite = 'ffffff';
    const colorBlack = '000000';

    const colorTile = this.dashboard.color.hexa.slice(1, 7);
    let colorLayout = this.dashboardLayout.colorRGBhexa;

    const textColorOnTile = Brightness(this.dashboard.color.hexa) === 'dark' ? colorWhite : colorGrey;
    const textColorOnLayout = Brightness(this.dashboardLayout.colorRGBhexa) === 'dark' ? colorWhite : colorGrey;

    /** 
     * page title 
     */
    let title = pres.addSlide();
    //image
    title.addImage({ 
      x: 0, y: 0, w: layout.w, h: layout.h, sizing: { type: 'crop' },
      path: `assets/images/slides/${layout.code}.jpg` 
    });
    //film sur image
    title.addShape(pres.ShapeType.rect, { x: 0, y: 0, w: '100%', h: '100%', fill: { color: colorTile, type: 'solid', alpha: 50 } });
    //cadre pour texte
    title.addShape(pres.ShapeType.rect, { x: layout.w / 2, y: 0, w: '50%', h: '100%', fill: colorWhite });
    //film bandeau
    title.addShape(pres.ShapeType.rect, { x: layout.w / 2 - 0.05, y: 0, w: 0.1, h: '100%', fill: { color: colorTile, type: 'solid', alpha: 50 } });
    //titre
    title.addText(this.dashboard.labels.value(this.language), { fontFace:'Arial', fontSize: 40, x: layout.w / 2 + 0.25, y: 0.25, w: layout.w / 2 - 0.5, h: layout.h / 2, color: colorTile, bold: true, align: pres.AlignH.left, valign: pres.AlignV.bottom });
    //company
    title.addText(pres.company, { fontFace:'Arial', fontSize: 18, x: layout.w / 2 + 0.25, y: layout.h / 2 + 0.25, color: colorGrey });
    //author
    title.addText(pres.author, { fontFace:'Arial', fontSize: 18, x: layout.w / 2 + 0.25, y: layout.h / 2 + 0.25 + 0.4, color: colorGrey });
    //date
    title.addText(DateToIso(Date()), { fontFace:'Arial', fontSize: 18, x: layout.w / 2 + 0.25, y: layout.h / 2 + 0.25 + 0.4 * 2, color: colorGrey });
    //cadre footer
    title.addShape(pres.ShapeType.rect, { x: 0, y: layout.h - 0.275, w: '100%', h: 0.275, fill: colorTile });
    //generate by and confidential
    title.addText(this.$translate.instant('CONFIDENTIAL'), { align: pres.AlignH.left, valign: pres.AlignV.bottom, fontFace:'Arial', fontSize: 12, x: 0, y: layout.h - 0.25, h: 0.25, color: colorWhite });
    title.addText(this.$translate.instant('GENERATED_WITH'), { align: pres.AlignH.right, valign: pres.AlignV.bottom, fontFace:'Arial', fontSize: 12, x: 0, y: layout.h - 0.25, h: 0.25, w: layout.w - 1, color: colorWhite });
    title.addText('SwappDash', { bold: true, align: pres.AlignH.right, valign: pres.AlignV.bottom, fontFace:'Arial', fontSize: 12, x: 0, y: layout.h - 0.25, h: 0.25, w: layout.w, color: textColorOnLayout });

    /**
     * page description
     */
    if(this.dashboard.descriptions.value(this.language).length > 0) {
      let description = pres.addSlide();
      //image
      description.addImage({ 
        x: 0, y: 0, w: layout.w, h: layout.h, sizing: { type: 'crop', w: layout.w, h: layout.h },
        path: `assets/images/slides/${layout.code}.jpg` 
      });
      //film sur image
      description.addShape(pres.ShapeType.rect, { x: 0, y: 0, w: '100%', h: '100%', fill: { color: colorTile, type: 'solid', alpha: 50 } });
      //cadre pour texte
      description.addShape(pres.ShapeType.rect, { x: 1, y: 0, w: layout.w - 1, h: '100%', fill: colorWhite });
      //film bandeau
      description.addShape(pres.ShapeType.rect, { x: 0.95, y: 0, w: 0.1, h: '100%', fill: { color: colorTile, type: 'solid', alpha: 50 } });
      //titre
      description.addText(this.$translate.instant('DESCRIPTION'), { fontFace:'Arial', fontSize: 36, x: 1.25, y: 0.25, color: colorTile, bold: true, align: pres.AlignH.left, valign: pres.AlignV.middle });
      //chapitres sommaire
      description.addText(bbCodeParser.parse(this.dashboard.descriptions.value(this.language)), { fontFace:'Arial', fontSize: 18, x: 1.25, y: 0.8, color: colorGrey, breakLine: true, lineSpacing: 20, paraSpaceAfter: 10, align: pres.AlignH.left, valign: pres.AlignV.top });
      //cadre footer
      description.addShape(pres.ShapeType.rect, { x: 0, y: layout.h - 0.275, w: '100%', h: 0.275, fill: colorTile });
      //generate by and confidential
      description.addText(this.$translate.instant('CONFIDENTIAL'), { align: pres.AlignH.left, valign: pres.AlignV.bottom, fontFace:'Arial', fontSize: 12, x: 0, y: layout.h - 0.25, h: 0.25, color: colorWhite });
      description.addText(this.$translate.instant('GENERATED_WITH'), { align: pres.AlignH.right, valign: pres.AlignV.bottom, fontFace:'Arial', fontSize: 12, x: 0, y: layout.h - 0.25, h: 0.25, w: layout.w - 1, color: colorWhite });
      description.addText('SwappDash', { bold: true, align: pres.AlignH.right, valign: pres.AlignV.bottom, fontFace:'Arial', fontSize: 12, x: 0, y: layout.h - 0.25, h: 0.25, w: layout.w, color: textColorOnLayout });
    }
    
    /**
     * page sommaire
     */
    let summary = pres.addSlide();
    //image
    summary.addImage({ 
      x: 0, y: 0, w: layout.w, h: layout.h, sizing: { type: 'crop', w: layout.w, h: layout.h },
      path: `assets/images/slides/${layout.code}.jpg` 
    });
    //film sur image
    summary.addShape(pres.ShapeType.rect, { x: 0, y: 0, w: '100%', h: '100%', fill: { color: colorTile, type: 'solid', alpha: 50 } });
    //cadre pour texte
    summary.addShape(pres.ShapeType.rect, { x: 1, y: 0, w: layout.w - 1, h: '100%', fill: colorWhite });
    //film bandeau
    summary.addShape(pres.ShapeType.rect, { x: 0.95, y: 0, w: 0.1, h: '100%', fill: { color: colorTile, type: 'solid', alpha: 50 } });
    //titre
    summary.addText(this.$translate.instant('SUMMARY'), { fontFace:'Arial', fontSize: 36, x: 1.25, y: 0.25, color: colorTile, bold: true, align: pres.AlignH.left, valign: pres.AlignV.middle });
    //film sur chapitres
    charts.forEach((chart: DashboardLayoutChartModel, index: number) => {
      summary.addShape(pres.ShapeType.rect, { x: 1, y: (0.825 + 0.4025 * index), w: 0.75, h: 0.3, fill: { color: colorTile, type: 'solid', alpha: 50 } });
    });
    //chapitres sommaire
    summary.addText(charts.map((chart: DashboardLayoutChartModel, index: number) => {
      return { text: `${(index + 1) <= 9 ? '   ' : ' ' }${index + 1}. ${chart.params.options.title.textInline}` };
    }), { fontFace:'Arial', fontSize: 18, x: 1.25, y: 0.8, color: colorGrey, breakLine: true, lineSpacing: 20, paraSpaceAfter: 9, align: pres.AlignH.left, valign: pres.AlignV.top });
    //cadre footer
    summary.addShape(pres.ShapeType.rect, { x: 0, y: layout.h - 0.275, w: '100%', h: 0.275, fill: colorTile });
    //generate by and confidential
    summary.addText(this.$translate.instant('CONFIDENTIAL'), { align: pres.AlignH.left, valign: pres.AlignV.bottom, fontFace:'Arial', fontSize: 12, x: 0, y: layout.h - 0.25, h: 0.25, color: colorWhite });
    summary.addText(this.$translate.instant('GENERATED_WITH'), { align: pres.AlignH.right, valign: pres.AlignV.bottom, fontFace:'Arial', fontSize: 12, x: 0, y: layout.h - 0.25, h: 0.25, w: layout.w - 1, color: colorWhite });
    summary.addText('SwappDash', { bold: true, align: pres.AlignH.right, valign: pres.AlignV.bottom, fontFace:'Arial', fontSize: 12, x: 0, y: layout.h - 0.25, h: 0.25, w: layout.w, color: textColorOnLayout });

    /**
     * pages charts
     */
    charts.map((chart: DashboardLayoutChartModel, index: number) => {

      let slide = pres.addSlide();

      const cell = this.dashboardLayout.cells[cells.indexOf(chart.uid)];
      const cellColor = chart.params.options.layout.backgroundColor.slice(1, 7);
      const textColorOnCell = Brightness(cellColor) === 'dark' ? colorWhite : colorGrey;
      const isPortrait = cell.columns / cell.rows < 1.6;

      slide.bkgd = colorLayout;
      //cadre pour titre
      slide.addShape(pres.ShapeType.rect, { x: 0, y: 0, w: '100%', h: 0.8, fill: colorTile });
      slide.addShape(pres.ShapeType.rect, { x: 0, y: 0.75, w: '100%', h: 0.1, fill: { color: colorWhite, type: 'solid', alpha: 80 } });
      //titre
      slide.addText(`${index + 1}. ${chart.params.options.title.textInline}`, { fontFace:'Arial', fontSize: 22, x: 0.25, y: 0.25, color: textColorOnTile, bold: true, align: pres.AlignH.left, valign: pres.AlignV.middle });
      //generate by and confidential
      slide.addText(this.$translate.instant('CONFIDENTIAL'), { align: pres.AlignH.left, valign: pres.AlignV.bottom, fontFace:'Arial', fontSize: 12, x: 0, y: layout.h - 0.25, h: 0.25, color: textColorOnLayout });
      slide.addText(this.$translate.instant('GENERATED_WITH'), { align: pres.AlignH.right, valign: pres.AlignV.bottom, fontFace:'Arial', fontSize: 12, x: 0, y: layout.h - 0.25, h: 0.25, w: layout.w - 1, color: textColorOnLayout });
      slide.addText('SwappDash', { bold: true, align: pres.AlignH.right, valign: pres.AlignV.bottom, fontFace:'Arial', fontSize: 12, x: 0, y: layout.h - 0.25, h: 0.25, w: layout.w, color: textColorOnLayout });  

      let margin = 0.3;
      let w = layout.w / (isPortrait ? 2 : 1) - margin * (isPortrait ? 1.5 : 2);
      let h = layout.h - 0.8 - margin * 2 - (isPortrait ? 0 : 2);

      let x = margin;
      let y = 0.8 + margin;

      slide.addShape(pres.ShapeType.rect, { x: x, y: y, w: w, h: h, fill: cellColor });

      //dates chart
      const dates = chart.match.map(value => {
        let indicator = `📅 [${chart.widgetLayers.indicatorByFormulaUID(value.formulaUID).labels.value(this.language)}]`;
        let dates = value.filters.map<string>(fts => {
          let labels: string[] = [];
          for(let ft in fts) {
            let isGreater  = fts[ft]['$gt'] !== undefined || fts[ft]['$gte'] !== undefined;
            let isLess  = fts[ft]['$lt'] !== undefined || fts[ft]['$lte'] !== undefined;;
            let isEquivalent  = fts[ft]['$gte'] !== undefined || fts[ft]['$lte'] !== undefined;
            if(isGreater || isLess || isEquivalent) {
              labels.push(`${chart.widgetLayers.layer(ft).layerLabel.value(this.language)} ${isGreater ? isEquivalent ? '>' : '≥' : isEquivalent ? '<' : '≤'} ${fts[ft][isGreater ? isEquivalent ? '$gte' : '$gt' : isEquivalent ? '$lte' : '$lt']}`);
            }
          }
          return labels.join(', ');
        });
        dates = dates.filter(d => d.length > 0);
        return dates.length > 0 ? ([indicator].concat(dates)).join(' ; ') : '';
      }).filter(d => d.length > 0);
      if(dates.length === 0) dates.push(this.$translate.instant('NO_DATES'));
      if(isPortrait) {
        slide.addShape(pres.ShapeType.rect, { x: w + margin * 2, y: y + h - 2.1, w: w, h: 1, fill: { color: colorBlack, type: 'solid', alpha: 90 } });
        slide.addText(dates.map(t => { return { text: t }}), { fontFace:'Arial', breakLine: true, fontSize: 10, x: w + margin * 2, y: y + h - 2.1, w: w, h: 1 - 0.1, color: textColorOnLayout, align: pres.AlignH.left, valign: pres.AlignV.top });
      }
      else {
        slide.addShape(pres.ShapeType.rect, { x: w / 2 + margin, y: y + h + 0.1, w: (w - margin) / 2, h: 1 - 0.1, fill: { color: colorBlack, type: 'solid', alpha: 90 } });
        slide.addText(dates.map(t => { return { text: t }}), { fontFace:'Arial', breakLine: true, fontSize: 10, x: w / 2 + margin, y: y + h + 0.1, w: (w - margin) / 2, h: 1 - 0.05, color: textColorOnLayout, align: pres.AlignH.left, valign: pres.AlignV.top });
      }

      //comments chart
      const comments = chart.comments.values.length > 0 ? chart.comments.valuesWithoutBBCodes(this.language) : [this.$translate.instant('NO_COMMENTS') as string];
      if(isPortrait) {
        slide.addShape(pres.ShapeType.rect, { x: w + margin * 2, y: y, w: w, h: h - 2.2, fill: { color: colorBlack, type: 'solid', alpha: 90 } });
        slide.addText(comments.map((comment: string) => { return { text: comment }; }), { fontFace:'Arial', breakLine: true, fontSize: 12, x: w + margin * 2, y: y, w: w, h: h - 2.2, color: textColorOnLayout, align: pres.AlignH.left, valign: pres.AlignV.top });
      }
      else {
        slide.addShape(pres.ShapeType.rect, { x: margin, y: y + h + 0.1, w: (w - margin) / 2, h: 2 - 0.1, fill: { color: colorBlack, type: 'solid', alpha: 90 } });
        slide.addText(comments.map((comment: string) => { return { text: comment }; }), { fontFace:'Arial', breakLine: true, fontSize: 12, x: margin, y: y + h + 0.1, w: (w - margin) / 2, h: 2 - 0.1, color: textColorOnLayout, align: pres.AlignH.left, valign: pres.AlignV.top });
      }

      //filters chart
      const filters = chart.match.map(value => {
        let indicator = `📌 [${chart.widgetLayers.indicatorByFormulaUID(value.formulaUID).labels.value(this.language)}]`;
        let filters = value.filters.map<string>(fts => {
          let labels: string[] = [];
          for(let ft in fts) {
            let isExcluding  = fts[ft]['$not'] !== undefined;
            let isIncluding  = fts[ft]['$in'] !== undefined;
            if(isExcluding || isIncluding) labels.push(`${chart.widgetLayers.layer(ft).layerLabel.value(this.language)} ${isExcluding ? this.$translate.instant('EXCLUSION_MODE') : this.$translate.instant('INCLUSION_MODE')}: ${isExcluding ? (fts[ft]['$not']['$in'] as string[]).join(', ') : (fts[ft]['$in'] as string[]).join(', ')}`);
          }
          return labels.join(', ');
        });
        filters = filters.filter(f => f.length > 0);
        return filters.length > 0 ? ([indicator].concat(filters)).join(' ; ') : '';
      }).filter(f => f.length > 0);
      if(filters.length === 0) filters.push(this.$translate.instant('NO_FILTERS'));
      if(isPortrait) {
        slide.addShape(pres.ShapeType.rect, { x: w + margin * 2, y: y + h - 1, w: w, h: 1, fill: { color: colorBlack, type: 'solid', alpha: 90 } });
        slide.addText(filters.map(t => { return { text: t }}), { fontFace:'Arial', breakLine: true, fontSize: 10, x: w + margin * 2, y: y + h - 1, w: w, h: 1 - 0.1, color: textColorOnLayout, align: pres.AlignH.left, valign: pres.AlignV.top });
      }
      else {
        slide.addShape(pres.ShapeType.rect, { x: w / 2 + margin, y: y + h + 1.1, w: (w - margin) / 2, h: 1 - 0.1, fill: { color: colorBlack, type: 'solid', alpha: 90 } });
        slide.addText(filters.map(t => { return { text: t }}), { fontFace:'Arial', breakLine: true, fontSize: 10, x: w / 2 + margin, y: y + h + 1.1, w: (w - margin) / 2, h: 1 - 0.1, color: textColorOnLayout, align: pres.AlignH.left, valign: pres.AlignV.top });
      }

      //widget
      let ratio = Math.min(h / cell.rows, w / cell.columns);
      slide.addImage({ data: chart.image, w: cell.columns * ratio, h: cell.rows * ratio, x: x + (w - (cell.columns * ratio)) / 2, y: y + (h - (cell.rows * ratio)) / 2, sizing: { type: 'contain' } });
    });

    this.processingPPT = true;
    pres.writeFile(`${this.dashboard.labels.value(this.language)} - ${DateApplyFormat(Date())}`).then(() => this.processingPPT = false);
  }

  onDownloadImage() {
    this.processingIMG = true;
    this.display.setFrame('dashboard', true);

    setTimeout(() => {
      html2canvas(this.capture.nativeElement, { allowTaint: true }).then((canvas: HTMLCanvasElement) => {
        var link = document.createElement('a');
        let uri = canvas.toDataURL();

        if (typeof link.download === 'string') {
          link.href = uri;
          link.download = this.dashboard.labels.value(this.language);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } 
        else {
          window.open(uri);
        }
        this.processingIMG = false;
      });
    }, 1000);
  }

  onDownloadPDF() {
    this.processingPDF = true;
    this.display.setFrame('dashboard', true);

    setTimeout(() => {
      //https://www.freakyjolly.com/jspdf-multipage-example-generate-multipage-pdf-using-single-canvas-of-html-document-using-jspdf/
      var HTML_Width = this.capture.nativeElement.offsetWidth;
      var HTML_Height = this.capture.nativeElement.offsetHeight;
      var top_left_margin = 0;
      var PDF_Width = HTML_Width + (top_left_margin * 2);
      var PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
      var canvas_image_width = HTML_Width;
      var canvas_image_height = HTML_Height;
      
      var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
      
      html2canvas(this.capture.nativeElement, { allowTaint: true }).then((canvas: HTMLCanvasElement) => {
        canvas.getContext('2d');
        
        var imgData = canvas.toDataURL("image/jpeg", 1.0);
        var pdf = new jsPDF('p', 'pt',  [PDF_Width, PDF_Height]);
        pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);
        
        for(var i = 1; i <= totalPDFPages; i++) { 
          pdf.addPage(PDF_Width, PDF_Height);
          pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height * i) + (top_left_margin * 4), canvas_image_width, canvas_image_height);
        }
        
        pdf.save(this.dashboard.labels.value(this.language));
        this.processingPDF = false;
      });
    }, 100);
  }

  setWidth() {
    let width = window.innerWidth - (this.display.framesCount - 1) * this.display.frameSize;
    this.dashboardLayout.setWidth(width);
  }

  ngOnDestroy() {
    this.language$sub.unsubscribe();
    if(this.dashboardLayout$sub) this.dashboardLayout$sub.unsubscribe();
    if(this.dashboard$sub) this.dashboard$sub.unsubscribe();
    if(this.framesChange$sub) this.framesChange$sub.unsubscribe();
  }

  onBack() {
    this.$router.navigate([`/${this.from}`], { queryParams: this.panel !== undefined ? { panel: this.panel } : {} });
  }

}
