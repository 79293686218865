/**
 * @module Presence
 */

import { Component, ViewChild, Output, EventEmitter, OnDestroy } from '@angular/core';
import { PresenceService } from '@services/presence/presence.service';
import { Observable, Subscription } from 'rxjs';
import { PresenceModel } from '@models/presence.model';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-presences',
  templateUrl: './presences.component.html',
  styleUrls: ['./presences.component.css']
})
export class PresencesComponent implements OnDestroy {

  @Output() sendPresencesCount = new EventEmitter<number>();
  @ViewChild('presencesModal', { static: true }) presencesModal: ModalDirective;

  presences$: Observable<PresenceModel[]>;
  presences$sub: Subscription;
  router$sub: Subscription;
  url: string;
  presences: PresenceModel[] = [];

  constructor(
    private $presence: PresenceService,
    private $router: Router
  ) {
    this.router$sub = this.$router.events.subscribe({
      next: (event: RouterEvent) => {
        if(event.url !== undefined && event.url !== this.url) {
          this.url = event.url;
          if(this.presences$sub !== undefined) this.presences$sub.unsubscribe();
          this.presences$sub = this.$presence.getLocalsPresences(event.url).subscribe({
            next: (presences: PresenceModel[]) => {
              this.sendPresencesCount.emit(presences.length);
              this.presences = presences;
            }
          });
        }
      }
    });

  }

  public show() {
    this.presencesModal.show();
  }

  ngOnDestroy() {
    if(this.router$sub !== undefined) this.router$sub.unsubscribe();
    if(this.presences$sub !== undefined) this.presences$sub.unsubscribe();
  }

}
