/**
 * @module Is
 */

import * as isjs from 'is';

/**
 * @description Accès à la librairie
 * @export
 * @returns
 */
export function Is() {
  return isjs;
}