/**
 * @module Widget
 */

import { Component, Input } from '@angular/core';
import { WidgetLayersClass } from '@class/widgetLayers.class';
import { ItemColor } from '@functions/item.functions';

@Component({
  selector: 'app-widget-indicators-clone',
  templateUrl: './widget-indicators-clone.component.html',
  styleUrls: ['./widget-indicators-clone.component.css']
})
export class WidgetIndicatorsCloneComponent {
  
  @Input() widgetLayers: WidgetLayersClass;
  @Input() current: number;

  color = ItemColor('indicators');

  constructor() {}

  onClone() {
    this.widgetLayers.cloneIndicator(this.current);
  }

}
