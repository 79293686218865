/**
 * @module Widget
 */

import { Component, Input } from '@angular/core';
import { WidgetLayersClass } from '@class/widgetLayers.class';

@Component({
  selector: 'app-widget-dates-bounds',
  templateUrl: './widget-dates-bounds.component.html',
  styleUrls: ['./widget-dates-bounds.component.css']
})
export class WidgetDatesBoundsComponent {

  @Input() widgetLayers: WidgetLayersClass;

  constructor() {}

}
