/**
 * @module Dashboard
 */

import { Directive, OnChanges, HostBinding, Input, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Directive({
  selector: '[appGridLayout]'
})
export class GridLayoutDirective implements OnChanges {

  @HostBinding('style.max-width') maxWidth: string;
  @HostBinding('style.display') display: string = 'grid';
  @HostBinding('style.grid-auto-flow') gridAutoFlow: string = 'dense';
  @HostBinding('style.grid-gap') gridGap: string;
  @HostBinding('style.grid-template-columns') gridTemplateColumns: SafeStyle;
  @HostBinding('style.grid-auto-rows') gridAutoRows: string;
  @HostBinding('style.padding') padd: string;

  @Input() public gap: number;
  @Input() public padding: number;
  @Input() public size: number;
  @Input() public maxColumns: number;

  constructor(
    private sanitizer: DomSanitizer
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    this.padd = `${this.padding}px`;
    this.gridGap = `${this.gap}px`;
    this.gridTemplateColumns = this.sanitizer.bypassSecurityTrustStyle(`repeat(auto-fill, minmax(${this.size}px, 1fr))`);
    this.gridAutoRows = `${this.size}px`;
    this.maxWidth = `${this.maxColumns * this.size + (this.maxColumns - 1) * this.gap + this.padding * 2}px`;
  }

}