/**
 * @module Widget
 */

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { WidgetLayersClass } from '@class/widgetLayers.class';
import { LanguageType } from '@models/language.model';
import { Subscription } from 'rxjs';
import { AuthService } from '@services/auth/auth.service';

@Component({
  selector: 'app-widget-drill-down',
  templateUrl: './widget-drill-down.component.html',
  styleUrls: ['./widget-drill-down.component.css']
})
export class WidgetDrillDownComponent {

  @Input() widgetLayers: WidgetLayersClass;
  @Input() labels: string[];
  @Input() vanila: string[];
  @Output() sendOpenDrill: EventEmitter<void>;

  language: LanguageType;
  language$sub: Subscription;
  
  constructor(
    private $auth: AuthService
  ) {
    this.language$sub = this.$auth.language$.subscribe({
      next: (language: LanguageType) => {
        this.language = language;
      }
    });
    this.sendOpenDrill = new EventEmitter<void>();
  }

  onOpenDrill() {
    this.sendOpenDrill.emit();
  }

}
