/**
 * @module Company
 */

import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { COMPANIES } from '@consts/companies.const';

@Injectable({
  providedIn: 'root'
})
export class CompanyService implements OnDestroy {

  /**
   * @description Company actuellement connectée
   * @private
   * @type {string}
   * @memberof CompanyService
   */
  private _company: string;

  /**
   *
   * @description Observateur du code de la company actuellement connectée
   * @type {BehaviorSubject<string>}
   * @memberof CompanyService
   */
  code$: BehaviorSubject<string>;

  /**
   * @description Creates an instance of CompanyService
   * @memberof CompanyService
   */
  constructor() {
    this.code$ = new BehaviorSubject<string>(null);
  }

  /**
   * @description Renvoi la validité du code company courant si aucun n'est passé en paramètre
   * @param {string} [company]
   * @returns {boolean}
   * @memberof CompanyService
   */
  isValid(company?: string): boolean {
    return Object.keys(COMPANIES).indexOf(company === undefined ? this.code : company) !== -1; 
  }

  /**
   * @description Renvoi le code company pour le libellé passé en paramètre ou undefined si non trouvé
   * @param {string} [company]
   * @returns {boolean}
   * @memberof CompanyService
   */
  getCodeFromLabel(label: string): string {
    const index = Object.values(COMPANIES).map((c) => { return c.label.toLowerCase() }).indexOf(label.toLowerCase()); 
    if(index === -1) return undefined;
    else return Object.keys(COMPANIES)[index]; 
  }

  /**
   * @description Renvoi le code company pour le sous-domaine passé en paramètre ou undefined si non trouvé
   * @param {string} [company]
   * @returns {boolean}
   * @memberof CompanyService
   */
  getCodeFromSubdomain(subdomain: string): string {
    const index = Object.values(COMPANIES).map((c) => { return c.subdomain.toLowerCase() }).indexOf(subdomain.toLowerCase()); 
    if(index === -1) return undefined;
    else return Object.keys(COMPANIES)[index]; 
  }

  /**
   * @description Accesseur du label de la company actuellement connectée
   * @readonly
   * @type {Readonly<string>}
   * @memberof CompanyService
   */
  get label(): Readonly<string> {
    return COMPANIES[this.code].label;
  }

  /**
   * @description Accesseur de la region de la company actuellement connectée (region au sens Firebase)
   * @readonly
   * @type {Readonly<string>}
   * @memberof CompanyService
   */
  get region(): Readonly<string> {
    return COMPANIES[this.code].region;
  }

  /**
   * @description Renvoi la totalité des informations sur la company
   * @readonly
   * @type {Readonly<any>}
   * @memberof CompanyService
   */
  get infos(): Readonly<any> {
    return COMPANIES[this.code];
  }

  /**
   * @description Accesseur du code de la company actuellement connectée
   * @type {string}
   * @memberof CompanyService
   */
  get code(): string {
    if(this._company) {
      return this._company;
    }
    else if(localStorage.getItem('company')) {
      return localStorage.getItem('company');
    }
    else if(this.isValid(window.location.hostname.split('.')[0])) {
      window.location.hostname.split('.')[0];
    }
    else {
      return undefined;
    }
  }

  /**
   * @description Modificateur du code de la company actuellement connectée 
   * @memberof CompanyService
   */
  set code(value: string) {
    this._company = value;
    localStorage.setItem('company', this._company);
    this.code$.next(this._company);
  }

  /**
   * @description Destructeur du service
   * @memberof CompanyService
   */
  ngOnDestroy() {
    this.code$.unsubscribe();
  }

}
