<div class="container-fluid my-3" *ngIf="!empty">
  <div class="row">
    <div class="col-12 px-1 px-sm-4">

      <app-loading app-browser-panel-content-list *ngIf="loading"></app-loading>

      <!-- CONTENT LIST -->
      <div class="d-flex flex-wrap justify-content-center flex-sm-row flex-column">
        <ng-content select="[app-browser-panel-content-list]"></ng-content>
      </div>
      <!-- ./CONTENT LIST -->

    </div>
  </div>
</div>

<div class="container-fluid" *ngIf="empty">
  <div class="row">
    <div class="col-12 px-0">

      <app-empty-panel app-browser-panel-content-list [title]="title" [text]="text" [color]="color" [action]="action" [icon]="icon" (sendAction)="receiveAction()"></app-empty-panel>

    </div>
  </div>
</div>

<div class="fixed-action-btn" style="bottom: 24px; right: 24px;">

  <a class="btn-floating btn-lg {{fixed.isCollapsed ? color : 'secondary-color'}} waves-light" #button mdbWavesEffect (click)="fixed.toggle($event)">
    <i *ngIf="fixed.isCollapsed" class="fas fa-bars animated rotateIn"></i>
    <i *ngIf="!fixed.isCollapsed" class="fas fa-times animated rotateIn"></i>
  </a>

  <div class="fixed_collapse" mdbCollapse="isCollapsed" #fixed="bs-collapse" (click)="fixed.toggle($event)">

    <!-- CONTENT FIXED BUTTON -->
    <ng-content select="[app-browser-panel-content-fixed-button]"></ng-content>
    <!-- ./CONTENT FIXED BUTTON -->
    
    <a (click)="onSearch()" mdbBtn floating="true" color="default" class="waves-light" mdbWavesEffect><i class="fad fa-search fa-swap-opacity"></i></a>
    <a (click)="onBookmark()" mdbBtn floating="true" color="default" class="waves-light" mdbWavesEffect><i class="fad fa-bookmark fa-swap-opacity"></i></a>
    <a (click)="onCreate()" *ngIf="user?.roles.has(role)" mdbBtn floating="true" [color]="color" class="waves-light" mdbWavesEffect><i class="fas fa-plus"></i></a>

  </div>

</div>