<app-widget-option [categories]="['INDICATORS']" [label]="'STACK'" [limitedTo]="['bar', 'horizontalBar']" [color]="color">
  <div app-widget-option-content>
    <div class="btn-group" mdbDropdown>
      <a mdbDropdownToggle type="button" mdbBtn size="sm" color="light" class="dropdown-toggle waves-light z-depth-0 px-3 dropdown-light" mdbWavesEffect>
        <span *ngIf="widgetLayers.indicators[current].stack != null" class="{{color}}-text font-weight-bolder">{{ 'STACK' | translate }}&nbsp;{{ widgetLayers.indicators[current].stack }}</span>&nbsp;
        <span *ngIf="widgetLayers.indicators[current].stack == null" class="{{color}}-text font-weight-bolder">{{ 'NOT_STACKED' | translate }}</span>&nbsp;
      </a>
      <div class="dropdown-menu dropdown-dark dropdown-menu-right font-small app-drop-small-limit-5">
        <a class="dropdown-item z-depth-0" (click)="onValue()">{{ 'NOT_STACKED' | translate }}</a>
        <a *ngFor="let value of values" class="dropdown-item z-depth-0" (click)="onValue(value)">
          {{ 'STACK' | translate }}&nbsp;{{ value }}
        </a>
      </div>
    </div>
  </div>
</app-widget-option>