/**
 * @module Widget
 */

import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { WidgetLayersClass } from '@class/widgetLayers.class';
import { WidgetLayerClass } from '@class/widgetLayer.class';
import { UID } from '@models/uid.model';
import { ItemColor, ItemIcon } from '@functions/item.functions';
import { PivotService } from '@services/pivot/pivot.service';
import { DateService } from '@services/date/date.service';
import { take, tap } from 'rxjs/operators';
import { PivotClass } from '@class/pivot.class';
import { DateClass } from '@class/date.class';
import { ModificationStateClass } from '@class/modification.class';
import { LanguageType } from '@models/language.model';
import { AuthService } from '@services/auth/auth.service';
import { Subscription } from 'rxjs';
import { LayerIcon } from '@functions/widget.functions';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-widget-layers-item',
  templateUrl: './widget-layers-item.component.html',
  styleUrls: ['./widget-layers-item.component.css']
})
export class WidgetLayersItemComponent implements OnInit, OnDestroy {

  @Input() widgetLayers: WidgetLayersClass;
  @Input() layer: WidgetLayerClass;
  @Input() uid: UID;
  @Input() selected: boolean;
  @Input() drillable: boolean;
  @Input() isLast: boolean;
  @Output() sendSelected: EventEmitter<UID>;
  @Output() sendEdited: EventEmitter<UID>;

  color = ItemColor('widgets');
  icon = LayerIcon();
  iconIndicator = ItemIcon('indicators');
  colorIndicator = ItemColor('indicators');
  iconItem: string;
  colorItem: string;

  indicatorsLabels: string;
  indicatorsTotal: number;
  allIndicators: boolean;

  language: LanguageType;
  language$sub: Subscription;
  layer$sub: Subscription;

  constructor(
    private $pivots: PivotService,
    private $dates: DateService,
    private $auth: AuthService,
    private $translate: TranslateService
  ) {
    this.sendSelected = new EventEmitter<UID>();
    this.sendEdited = new EventEmitter<UID>();

    this.language$sub = this.$auth.language$.subscribe({
      next: (language: LanguageType) => {
        this.language = language;
      }
    });
  }

  ngOnInit() {
    this.iconItem = ItemIcon(this.layer.layerType === 'pivots' ? 'pivots' : 'dates', 's');
    this.colorItem = ItemColor(this.layer.layerType === 'pivots' ? 'pivots' : 'dates');

    this.setIndicators();
    this.layer$sub = this.widgetLayers.lastModification$.subscribe({ 
      next: (attribute: string) => {
        if(attribute === 'indicators') this.setIndicators();
      }
    });

    if(this.layer.layerType === 'pivots' && !this.layer.isValid('layerLabel', true)) {
      this.$pivots.getPartial$(this.uid).pipe(
        take(1),
        tap((pivot: PivotClass) => {
          this.layer.layerLabel.change({ labels: pivot.labels.model.labels }, false);
        })
      ).subscribe();
    }
    else if(this.layer.layerType === 'dates' && !this.layer.isValid('layerLabel', true)) {
      this.$dates.getPartial$(this.uid).pipe(
        take(1),
        tap((date: DateClass) => {
          this.layer.layerLabel.change({ labels: date.labels.model.labels }, false);
        })
      ).subscribe();
    }
  }

  ngOnDestroy() {
    this.layer$sub.unsubscribe();
    this.language$sub.unsubscribe();
  }

  setIndicators() {
    this.indicatorsTotal = [...new Set(this.widgetLayers.indicators.map(i => i.uid.value))].length;

    this.indicatorsLabels = [...new Set(this.widgetLayers.indicators
      .map(i => i.labels.value(this.language)))]
      .reduce((previous: string, current: string) => previous.length > 0 ? `${previous}, ${current}` : current, '');
    
    this.indicatorsLabels = `${this.$translate.instant('AVAILABLE_FOR_INDICATORS', { count: this.layer.indicators.length, total: this.indicatorsTotal })}: ${this.indicatorsLabels}`;
    this.allIndicators = this.layer.indicators.length === this.indicatorsTotal;
  }

  onEdit() {
    this.sendEdited.emit(this.uid);
  }

  receiveSelected() {
    this.sendSelected.emit(this.uid);
  }

  receiveInTable() {
    this.layer.change({ inTable: !this.layer.inTable });
  }

}
