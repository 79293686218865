<app-theme-option [categories]="themeOptions.optionsConfiguration[optionType].categories" [label]="themeOptions.optionsConfiguration[optionType].label" [information]="(themeOptions.datalabels(datalabelsIndex).rotation | number:'1.0-0') + '°'">
  <div app-theme-option-content>
    <app-show-details-option [showDetails]="showDetails" (sendShowDetails)="receiveShowDetails($event)">
      <div app-show-details-option-content>
        <app-slider [color]="color" [value]="themeOptions.datalabels(datalabelsIndex).rotation" (sendValue)="receiveValue($event)" [max]="360"></app-slider>
      </div>
    </app-show-details-option>
  </div>
</app-theme-option>

<div *ngIf="showDetails">
  <app-theme-option [categories]="themeOptions.optionsConfiguration[optionType].categoriesAndLabel" [label]="type | chart" [dark]="4" *ngFor="let type of themeOptions.limitedTo(optionType)" [information]="(themeOptions.datalabels(datalabelsIndex, type).rotation | number:'1.0-0') + '°'">
    <div app-theme-option-content>
      <app-slider [color]="color" [value]="themeOptions.datalabels(datalabelsIndex, type).rotation" (sendValue)="receiveValue($event, type)" [max]="360"></app-slider>
    </div>
  </app-theme-option>
</div>