/**
 * @module Theme
 */

import { Component, Input } from '@angular/core';
import { ThemeOptionsClass } from '@class/themeOptions.class';
import { ItemColor } from '@functions/item.functions';
import { ThemeOptionType } from '@models/themeStudio.model';
import { ChartType } from '@models/chart.model';
import { IconFrameType } from 'chart.js';

@Component({
  selector: 'app-theme-option-elements-icon-frame',
  templateUrl: './theme-option-elements-icon-frame.component.html',
  styleUrls: ['./theme-option-elements-icon-frame.component.css']
})
export class ThemeOptionElementsIconFrameComponent {

  @Input() themeOptions: ThemeOptionsClass;
  @Input() serieIndex: number;

  showDetails: boolean;
  color = ItemColor('themes');
  optionType: ThemeOptionType = 'elements_iconFrame';

  constructor() {
    this.showDetails = false;
  }

  onValue(value: IconFrameType, type?: ChartType) {
    this.themeOptions.setSeries(this.optionType, value, this.serieIndex, type ? [type] : undefined);
  }

  receiveShowDetails(showDetails: boolean) {
    this.showDetails = showDetails;
  }

  values = {
    'rounded-circle': { style: 'rounded-circle', label: 'CIRCLE' },
    'rounded': { style: 'rounded', label: 'ROUNDED' },
    'rounded-0': { style: 'rounded-0', label: 'SQUARE' },
    'without': { style: 'without', label: 'WITHOUT' }
  };

}
