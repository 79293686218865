/**
 * @module Right
 */

import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { RightClass } from '@class/right.class';
import { ItemColor, ItemIcon } from '@functions/item.functions';
import { RightService } from '@services/right/right.service';
import { UID } from '@models/uid.model';
import { Subscription } from 'rxjs';
import { LanguageType } from '@models/language.model';
import { AuthService } from '@services/auth/auth.service';

@Component({
  selector: 'app-rights-links-item',
  templateUrl: './rights-links-item.component.html',
  styleUrls: ['./rights-links-item.component.css']
})
export class RightsLinksItemComponent implements OnInit, OnDestroy, OnChanges {

  @Input() uid: UID;
  @Input() isReader: boolean;
  @Input() isWriter: boolean;
  @Input() searchLabel: string;

  @Output() sendReader: EventEmitter<UID>;
  @Output() sendWriter: EventEmitter<UID>;

  color: string = ItemColor('rights');
  icon: string = ItemIcon('rights');

  partial$sub: Subscription;
  right: RightClass;
  display: boolean;
  language: LanguageType;
  language$sub: Subscription;

  constructor(
    private $rights: RightService,
    private $auth: AuthService
  ) { 
    this.sendReader = new EventEmitter<UID>();
    this.sendWriter = new EventEmitter<UID>();
    this.display = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.searchLabel !== undefined && !changes.searchLabel.firstChange && !!this.right) this.setDisplay();
  }

  ngOnInit() {
    this.partial$sub = this.$rights.getPartial$(this.uid).subscribe({
      next: (right: RightClass) => {
        this.right = right;
        this.setDisplay;
      }
    });

    this.language$sub = this.$auth.language$.subscribe({
      next: (language: LanguageType) => {
        this.language = language;
      }
    });
  }

  setDisplay() {
    this.display = this.right.labels.value(this.language).toLowerCase().includes(this.searchLabel);
  }

  ngOnDestroy() {
    this.partial$sub.unsubscribe();
  }

  receiveReader() {
    this.sendReader.emit(this.uid);
  }

  receiveWriter() {
    this.sendWriter.emit(this.uid);
  }

}
