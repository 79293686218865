<app-studio #studio [type]="'indicators'" [itemLabel]="'INDICATOR'">

  <!-- BLOC MAIN -->
  <div app-studio-main-content *ngIf="indicator">

    <app-item-strip
      [labels]="indicator.labels"
      [tags]="indicator.tags"
      [type]="'indicators'"
      [uid]="indicator.uid"
      [icon]="icon"
      [color]="color"
      [isModified]="indicator.modifications$.value.isModified"
      (sendEdit)="receiveEdit($event)"
    ></app-item-strip>

    <hr>

    <button [disabled]="count == 0" mdbBtn type="button" [color]="color" class="z-depth-0" block="true" mdbWavesEffect (click)="onExport()">
      <i *ngIf="!exporting" class="far fa-file-export"></i>
      <i *ngIf="exporting" class="far fa-circle-notch fa-spin"></i>
      <span class="ml-1">{{ 'EXPORT_TABLE' | translate:{ count: count } }}</span>
    </button>

    <br>

    <button [disabled]="count == 0" mdbBtn type="button" outline="true" color="warning" class="z-depth-0" block="true" mdbWavesEffect (click)="onDelete()">
      <i *ngIf="!deleting" class="far fa-file-trash"></i>
      <i *ngIf="deleting" class="far fa-circle-notch fa-spin"></i>
      <span class="ml-1">{{ 'REMOVE_LINES' | translate:{ count: count } }}</span>
    </button>

    <app-delete *ngIf="showDelete" [code]="indicator.uid.code" (sendDelete)="receiveFlush()"></app-delete>

    <hr>

    <app-indicator-studio-date *ngFor="let date of dates" [date]="date" (sendSelected)="receiveSelectedPivot($event)"></app-indicator-studio-date>
    <app-indicator-studio-pivot *ngFor="let pivot of pivots" [pivot]="pivot" (sendSelected)="receiveSelectedPivot($event)"></app-indicator-studio-pivot>

  </div>
  <!-- ./BLOC MAIN -->

  <!-- BLOC ITEM -->
  <div app-studio-item-content class="d-block p-3" style="height: 100%;" *ngIf="indicator; else app_empty">
    
    <table mdbTable *ngIf="elements; else app_empty" striped="true" borderless="true" hover="true">
      <thead class="{{ color }} white-text">
        <tr>
          <th>{{ 'COUNT' | translate }}</th>
          <th *ngFor="let uid of uids">{{ byKey[uid].labels.value(language) }}</th>
          <th class="text-right">{{ 'TOTAL' | translate }}</th>
        </tr>
      </thead>
      <thead class="{{ color }} lighten-2 white-text">
        <tr>
          <th>{{ count | number:'1.0-0':language }}</th>
          <th *ngFor="let uid of uids">&nbsp;</th>
          <th class="text-right">{{ total | number:'1.0-20':language }}</th>
        </tr>
      </thead>
      <tbody class="text-muted">
        <tr mdbTableCol *ngFor="let element of elements">
          <th>{{ element.count | number:'1.0-0':language }}</th>
          <td *ngFor="let uid of uids">{{ element.groups[uid] }}</td>
          <th class="text-right">{{ element.value | number:'1.0-20':language }}</th>
        </tr>
      </tbody>
    </table>

  </div>
  <!-- ./BLOC ITEM -->

  <!-- BLOC SUB -->
  <div app-studio-sub-content>
    <app-widget-date-bounds *ngFor="let bounds of bounds" [bounds]="bounds"></app-widget-date-bounds>
    <app-widget-indicators-filters-pivot *ngFor="let pivot of pivots ; let i = index" (sendSelected)="receiveSelectedFilter($event)" [filters]="filters[i]" [indicators]="[indicator]" [pivot]="pivot" [current]="current == pivot.uid.value"></app-widget-indicators-filters-pivot>
  </div>
  <!-- ./BLOC SUB -->

</app-studio>

<ng-template #app_empty>
  <app-loading></app-loading>
</ng-template>

<app-indicator-editor *ngIf="indicator" #editor [current]="indicator" (sendDelete)="receiveDelete()"></app-indicator-editor>