/**
 * @module Widget
 */

import { Component, OnDestroy, Input, ViewChild, OnInit } from '@angular/core';
import { WidgetClass } from '@class/widget.class';
import { EditorComponent } from '@components/forms/editor/editor.component';
import { ItemColor } from '@functions/item.functions';
import { LanguageType } from '@models/language.model';
import { Subscription } from 'rxjs';
import { WidgetService } from '@services/widget/widget.service';
import { AuthService } from '@services/auth/auth.service';
import { MessagesService } from '@services/messages/messages.service';
import { UID } from '@models/uid.model';
import { CreateRandomUid } from '@functions/uid.functions';
import { DeepCopy } from '@functions/copy.functions';
import { WidgetModel } from '@models/widget.model';
import { take } from 'rxjs/operators';
import { WidgetLayersModel } from '@models/widgetLayers.model';
import { WidgetLayersClass } from '@class/widgetLayers.class';
import { ThemeService } from '@services/theme/theme.service';
import { ThemeOptionsClass } from '@class/themeOptions.class';
import { ColorsService } from '@services/colors/colors.service';

@Component({
  selector: 'app-widget-editor',
  templateUrl: './widget-editor.component.html',
  styleUrls: ['./widget-editor.component.css']
})
export class WidgetEditorComponent implements OnDestroy {

  @Input() current: WidgetClass;

  @ViewChild('editor', { static: true }) editor: EditorComponent;

  color = ItemColor('widgets');
  editorIndex: number;
  editorIndexMax: number;
  language: LanguageType;
  language$sub: Subscription;
  clone: WidgetClass;
  isCloning: boolean;

  constructor(
    private $widgets: WidgetService,
    private $auth: AuthService,
    private $messages: MessagesService,
    private $themes: ThemeService,
    private $colors: ColorsService
  ) {
    this.editorIndex = 0;
    this.editorIndexMax = 6;
    this.isCloning = false;

    this.language$sub = this.$auth.language$.subscribe({
      next: (language: LanguageType) => {
        this.language = language;
      }
    });
  }

  setClone() {
    let model = DeepCopy(this.current.model) as WidgetModel;
    model.uid = CreateRandomUid();
    this.clone = new WidgetClass(model, false);
  }

  ngOnDestroy() {
    this.language$sub.unsubscribe();
  }

  public show() {
    this.setClone();
    this.editor.show();
  }

  onClone() {
    this.isCloning = true;
    this.$widgets.set(this.clone).then(() => {
      this.$themes.getOptions$(this.clone.uidTheme)
      .pipe(take(1))
      .subscribe((theme: ThemeOptionsClass) => {
        this.$widgets.getLayers$(this.current, theme)
        .pipe(take(1))
        .subscribe((layers: WidgetLayersClass) => {
          let layersModel = DeepCopy(layers.model) as WidgetLayersModel;
          layersModel.uid = this.clone.uid.value;
          let layersClass = new WidgetLayersClass(layersModel, theme, this.$colors)
          this.$widgets.setLayers(layersModel.uid, layersClass)
          .then(() => {
            this.isCloning = false;
            this.editor.hide();
            this.$messages.info({ 
              title: { plain: this.clone.labels.value(this.language) }, 
              text: { code: 'DELAY_TO_VALIDATE/TEXT' } 
            });
            this.$messages.success({ 
              title: { plain: this.clone.labels.value(this.language) }, 
              text: { code: 'SUCCESSFULLY_SAVED/TEXT' } 
            });
          })
          .catch((error) => {
            this.isCloning = false;
            this.$messages.error({ 
              title: { plain: this.clone.labels.value(this.language) }, 
              text: { 
                code: 'SAVE_FAILED/TEXT',
                params: { error: error.code }
              } 
            });
          });
        });
      });
    })
    .catch((error) => {
      this.isCloning = false;
      this.$messages.error({ 
        title: { plain: this.clone.labels.value(this.language) }, 
        text: { 
          code: 'SAVE_FAILED/TEXT',
          params: { error: error.code }
        } 
      });
    });
  }

  receiveRestore() {
    this.current.reset();
  }

  receiveDelete() {
    this.editorIndex = NaN;
  }

  receiveSelectedTheme(uidTheme: UID) {
    this.current.change({ uidTheme: uidTheme });
  }

  receiveDeleteConfirmation() {
    this.$widgets.remove(this.current.uid.value)
    .then(() => {
      this.editor.hide();
      this.editorIndex = 0;
      this.$messages.success({ 
        title: { plain: this.current.labels.value(this.language) }, 
        text: { code: 'SUCCESSFULLY_REMOVED/TEXT' } 
      });
    })
    .catch((error) => {
      this.$messages.error({ 
        title: { plain: this.current.labels.value(this.language) }, 
        text: { 
          code: 'REMOVE_FAILED/TEXT',
          params: { error: error.code }
        } 
      });
    });
  }

  receiveNext(isNext: boolean) {
    this.editorIndex = this.editorIndex + (isNext ? 1 : -1);
    if(this.editorIndex < 0) this.editorIndex = this.editorIndexMax;
    if(this.editorIndex > this.editorIndexMax) this.editorIndex = 0;
  }  
  
  receiveSave() {
    this.$widgets.set(this.current)
    .then(() => {
      if(!this.current.uid.isRegistered) {
        this.$messages.info({ 
          title: { plain: this.current.labels.value(this.language) }, 
          text: { code: 'DELAY_TO_VALIDATE/TEXT' } 
        });
        this.current = this.$widgets.create();
      }
      this.editor.hide();
      this.$messages.success({ 
        title: { plain: this.current.labels.value(this.language) }, 
        text: { code: 'SUCCESSFULLY_SAVED/TEXT' } 
      });
    })
    .catch((error) => {
      this.$messages.error({ 
        title: { plain: this.current.labels.value(this.language) }, 
        text: { 
          code: 'SAVE_FAILED/TEXT',
          params: { error: error.code }
        } 
      });
    });
  }

}
