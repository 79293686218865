/**
 * @module Dashboard
 */

import { Component, Input } from '@angular/core';
import { DashboardLayoutCellClass } from '@class/dashboardLayoutCell.class';
import { ItemColor } from '@functions/item.functions';

@Component({
  selector: 'app-dashboard-cell-rows',
  templateUrl: './dashboard-cell-rows.component.html',
  styleUrls: ['./dashboard-cell-rows.component.css']
})
export class DashboardCellRowsComponent {

  @Input() cell: DashboardLayoutCellClass;

  color = ItemColor('dashboards');
  
  constructor() {}

  receiveValue(rows: number) {
    this.cell.change({ rows: rows });
  }

}
