<app-form-strip>
  <div app-form-strip-content-left>
    <i class="fad fa-fw fa-language fa-lg mt-1"></i>
  </div>

  <div app-form-strip-content-middle>
    {{ 'LANGUAGE' | translate }}
  </div>

  <div app-form-strip-content-right>
    <div class="btn-group" mdbDropdown>
      <a mdbDropdownToggle type="button" mdbBtn size="sm" color="blue-grey" class="dropdown-toggle waves-light z-depth-0 px-3" mdbWavesEffect>
        {{ languages[user.language] }}
      </a>
      <div class="dropdown-menu dropdown-dark dropdown-menu-right">
        <a *ngFor="let language of languages | keyvalue" class="dropdown-item z-depth-0" (click)="onLanguage(language.key)">{{ language.value }}</a>
      </div>
    </div>
  </div>
</app-form-strip>