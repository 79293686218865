/**
 * @module Right
 */

import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-rights-switch-full',
  templateUrl: './rights-switch-full.component.html',
  styleUrls: ['./rights-switch-full.component.css']
})
export class RightsSwitchFullComponent {

  @Input() full: boolean;
  @Output() sendFull: EventEmitter<boolean>;

  constructor() {
    this.sendFull = new EventEmitter<boolean>();
  }

  onFull(full: boolean) {
    this.sendFull.emit(full);
  }

}
