<app-widget-option [categories]="['LAYERS']" [label]="'CAROUSEL'" [limitedTo]="['sticker']" [sublabel]="widgetLayers.layer(current).stickerCarousel ? undefined : 'ORIENTATION'">
  <div app-widget-option-content>
    <app-check [color]="color" [value]="widgetLayers.layer(current).stickerCarousel" (sendValue)="receiveValue($event)"></app-check>
  </div>
  <div app-widget-option-subcontent>
    <div class="btn-group py-2" mdbDropdown>
      <a mdbDropdownToggle type="button" mdbBtn size="sm" color="light" class="dropdown-toggle waves-light z-depth-0 px-3 dropdown-light" mdbWavesEffect>
        <span class="{{color}}-text font-weight-bolder">{{ widgetLayers.layer(current).stickerOrientation | uppercase | translate }}</span>&nbsp;
      </a>
      <div class="dropdown-menu dropdown-dark dropdown-menu-right font-small app-drop-small-limit-5">
        <a *ngFor="let orientation of orientations" class="dropdown-item z-depth-0" (click)="onValue(orientation)">
          {{ orientation | uppercase | translate }}
        </a>
      </div>
    </div>
  </div>
</app-widget-option>