/**
 * @module Item
 */

import { ItemType } from '@models/item.model';
import { RoleType } from '@models/role.model';
import { IconType } from '@models/icon.model';


export function ItemsPanel(): Array<ItemType> {
  return ['dashboards' ,'widgets' ,'indicators'];
}

export function ItemsFrame(): Array<ItemType> {
  return ['pivots', 'dates', 'themes', 'users', 'rights', 'servers', 'tags'];
}

export function ItemsTypes(): Array<ItemType> {
  return ['dashboards', 'comments' ,'widgets' ,'rights' ,'indicators' ,'pivots' ,'dates' ,'servers' ,'users' ,'tags', 'themes'];
}

/**
 * @description Renvoi la liste des codes pour chaque item
 * @export
 * @returns {Readonly<{ [key in ItemType]: RoleType }>}
 */
export function ItemsRoles(): Readonly<{ [key in ItemType]: RoleType }> {
  return {
    indicators: 'i',
    comments: 'c',
    dates: 'i',
    pivots: 'i',
    tags: 't',
    dashboards: 'd',
    widgets: 'w',
    users: 'u',
    rights: 'r',
    servers: 's',
    themes: 'h'
  };
}

/**
 * @description Renvoi le code pour le item demandé
 * @export
 * @param {RoleType} code
 * @returns {Readonly<RoleType>}
 */
export function ItemRole(type: ItemType): Readonly<RoleType> {
  return ItemsRoles()[type];
}

/**
 * @description Renvoi la liste des couleurs pour chaque item
 * @export
 * @returns {Readonly<{ [key in ItemType]: string }>}
 */
export function ItemsColors(alt: boolean = false): Readonly<{ [key in ItemType]: string }> {
  return alt ? {
    indicators: 'cyan',
    comments: 'blue',
    dates: 'cyan lighten-3',
    pivots: 'cyan darken-3',
    tags: 'blue-grey',
    dashboards: 'blue',
    widgets: 'light-green',
    users: 'teal',
    rights: 'orange',
    servers: 'deep-purple',
    themes: 'pink'
  } : {
    indicators: 'cyan',
    comments: 'blue',
    dates: 'cyan',
    pivots: 'cyan',
    tags: 'blue-grey',
    dashboards: 'blue',
    widgets: 'light-green',
    users: 'teal',
    rights: 'orange',
    servers: 'deep-purple',
    themes: 'pink'
  };
}

/**
 * @description Renvoi la couleur pour le item demandé
 * @export
 * @param {ItemType} item
 * @returns {Readonly<string>}
 */
export function ItemColor(item: ItemType, alt: boolean = false): Readonly<string> {
  return ItemsColors(alt)[item];
}

/**
 * @description Renvoi la liste des icones pour chaque item
 * @export
 * @returns {Readonly<{ [key in ItemType]: string }>}
 */
export function ItemsIcons(style: IconType = 'r'): Readonly<{ [key in ItemType]: string }> {
  let icons = {} as { [key in ItemType]: string };
  let symbols = ItemsSymbols()
  for(const type in symbols) {
    icons[type] = `fa${style} fa-${symbols[type]}`;
  }
  return icons;
}

export function ItemsSymbols(): Readonly<{ [key in ItemType]: string }> {
  return {
    indicators: 'cube',
    comments: 'comments-alt',
    dates: 'calendar',
    pivots: 'cubes',
    tags: 'tags',
    dashboards: 'user-chart',
    widgets: 'chart-pie',
    users: 'user',
    rights: 'users',
    servers: 'server',
    themes: 'palette'
  };
}

export function ItemSymbol(item: ItemType): Readonly<string> {
  return ItemsSymbols()[item];
}

/**
 * @description Renvoi la couleur pour le item demandé
 * @export
 * @param {ItemType} item
 * @returns {Readonly<string>}
 */
export function ItemIcon(item: ItemType, style: IconType = 'r'): Readonly<string> {
  return ItemsIcons(style)[item];
}

export function ItemLabel(item: ItemType): Readonly<string> {
  return ItemsLabels()[item];
}

export function ItemsLabels(): Readonly<{ [key in ItemType]: string }> {
  return {
    indicators: 'INDICATORS',
    comments: 'COMMENTS',
    dates: 'DATES',
    pivots: 'PIVOTS',
    tags: 'TAGS',
    dashboards: 'DASHBOARDS',
    widgets: 'WIDGETS',
    users: 'USERS',
    rights: 'RIGHTS',
    servers: 'SERVERS',
    themes: 'THEMES'
  };
};