/**
 * @module Widget
 */

import { Injectable, OnDestroy, EventEmitter } from '@angular/core';
import { AuthService } from '@services/auth/auth.service';
import { LanguageType } from '@models/language.model';
import { ModificationStateClass } from '@class/modification.class';
import { WidgetIndicatorClass } from '@class/widgetIndicator.class';
import { combineLatest, Subscription } from 'rxjs';
import { ServerResponseModel } from '@models/server.model';
import { ChartDataSets } from 'chart.js';
import { UID } from '@models/uid.model';
import { WidgetLayersClass } from '@class/widgetLayers.class';
import { Label } from 'ng2-charts';
import { BackendValuesService } from '@services/backend/backendValues/backend-values.service';
import { take } from 'rxjs/operators';
import { PivotFiltersClass } from '@class/widgetFilters.class';
import { ParseGenericDate, ApplyTimeline, DateApplyFormat, Difference, Occurences, BACKEND_DATE_FORMAT, DateToIso, IsBefore, Moment } from '@functions/moment.functions';
import { WidgetLayerClass } from '@class/widgetLayer.class';
import { WidgetTimelineType } from '@models/widgetLayers.model';
import * as math from 'mathjs';
import { Total, Maximum, Average, Minimum } from '@functions/math.functions';
import * as moment from 'moment';

const TIMEOUT_DELAY = 500;

@Injectable({
  providedIn: 'root'
})
export class WidgetQueryService implements OnDestroy {

  private widgetLayers: WidgetLayersClass;

  private labels: Label[] = [];
  private vanila: { 
    [key in WidgetTimelineType]: { 
      [key: string]: string[];
    } 
  };
  private series: ChartDataSets[] = [];
  private timeout: any;
  
  private language: LanguageType;
  private language$sub: Subscription;
  private modifications$sub: Subscription;
  private changed$sub: Subscription;

  private result: EventEmitter<{ labels: Label[], series:ChartDataSets[] }>;

  constructor(
    private $values: BackendValuesService,
    private $auth: AuthService
  ) {
    this.result = new EventEmitter<{ labels: Label[], series:ChartDataSets[] }>();
    this.language$sub = this.$auth.language$.subscribe({
      next: (language: LanguageType) => {
        this.language = language;
      }
    });
  }

  queryResult$(widgetLayers: WidgetLayersClass): EventEmitter<{ labels: Label[], series:ChartDataSets[] }> {
    
    this.widgetLayers = widgetLayers;

    this.setSeries();

    if(this.modifications$sub) this.modifications$sub.unsubscribe();
    this.modifications$sub = this.widgetLayers.modifications$.subscribe((modification: ModificationStateClass) => {
      if(modification.isModified && this.series.length !== this.widgetLayers.indicators.length) {
        if(this.timeout !== undefined) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.setSeries();
        }, TIMEOUT_DELAY);
      }
      else if(modification.isModified) {
        if(modification.has('reload')) {
          modification.remove('reload');
          this.series = [];
        }
        if(this.timeout !== undefined) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.setSeries();
        }, TIMEOUT_DELAY);
      }
    });

    this.changed$sub = this.widgetLayers.themeOptions.changed$.subscribe(() => {
      if(this.timeout !== undefined) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.setSeries();
      }, TIMEOUT_DELAY);
    });

    return this.result;
  }

  ngOnDestroy() {
    this.language$sub.unsubscribe();
    this.modifications$sub.unsubscribe();
    this.changed$sub.unsubscribe();
  }

  private setSeries() {
    if(this.series.length !== this.widgetLayers.indicators.length) {
      if(this.timeout !== undefined) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.queryServer().then(() => this.setSeries());
      }, TIMEOUT_DELAY);
    }
    else {
      this.widgetLayers.indicators.forEach((indicator: WidgetIndicatorClass, index: number) => {
        this.series[index].label = indicator.labels.value(this.language);
      });
      this.result.emit({ labels: this.labels, series: this.series });
    }
  }

  private queryServer(): Promise<void> {
    return new Promise<void>((resolve, reject) => { 
      combineLatest(
        this.widgetLayers.indicators.map((indicator: WidgetIndicatorClass) => {
          let match = [];

          //recherche des filtres sur les layers
          //TODO : ne garder que les pivots utilisés par cet indicateur sinon ne vas retourner aucune données => finalement on garde comme cela ?
          this.widgetLayers.filters.forEach((filters: PivotFiltersClass) => {
            if(filters.values.length > 0) {
              if(filters.include) {
                match.push({ [filters.uid.value]: { $in : filters.values } });
              }
              else {
                match.push({ [filters.uid.value]: { $not: { $in : filters.values } } });
              }
            }
          });

          //recherche des filtres sur les pivots
          indicator.filters.forEach((filters: PivotFiltersClass) => {
            if(filters.values.length > 0) {
              if(filters.include) {
                match.push({ [filters.uid.value]: { $in : filters.values } });
              }
              else {
                match.push({ [filters.uid.value]: { $not: { $in : filters.values } } });
              }
            }
          });

          //recherche des bornes sur les dates
          Object.values(this.widgetLayers.layers).forEach((layer: WidgetLayerClass) => {
            if(layer.layerType === 'dates') {

              //vérification de customisation des dates par l'utilisateur
              if(this.widgetLayers.bounds[layer.uid.value].hasAny) {
                let bounds = this.widgetLayers.bounds[layer.uid.value]
                let periodLength: number;
                if(indicator.timeline === 'previousPeriod' && bounds.has('greater') && bounds.has('less')) {
                  periodLength = Difference(
                    ApplyTimeline(DateToIso(bounds.less.value), indicator.timeline), 
                    ApplyTimeline(DateToIso(bounds.greater.value), indicator.timeline), 
                    'D');
                }

                if(bounds.has('greater')) {
                  const bound = bounds.greater;
                  match.push({ [layer.uid.value]: { [bound.equivalent ? '$gte' : '$gt']: ApplyTimeline(DateToIso(bound.value), indicator.timeline, periodLength) } });
                }
                else {
                  const bound = layer.bounds.greater;
                  match.push({ [layer.uid.value]: { [bound.equivalent ? '$gte' : '$gt']: ApplyTimeline(ParseGenericDate(bound.value, true, this.widgetLayers.offsetDate), indicator.timeline, periodLength) } });
                }
                if(bounds.has('less')) {
                  const bound = bounds.less;
                  match.push({ [layer.uid.value]: { [bound.equivalent ? '$lte' : '$lt']: ApplyTimeline(DateToIso(bound.value), indicator.timeline, periodLength) } });
                }
                else {
                  const bound = layer.bounds.less;
                  match.push({ [layer.uid.value]: { [bound.equivalent ? '$lte' : '$lt']: ApplyTimeline(ParseGenericDate(bound.value, false, this.widgetLayers.offsetDate), indicator.timeline, periodLength) } });
                }
              }
              else {
                let periodLength: number;
                if(indicator.timeline === 'previousPeriod' && layer.bounds.has('greater') && layer.bounds.has('less')) {
                  periodLength = Difference(
                    ApplyTimeline(ParseGenericDate(layer.bounds.less.value, false, this.widgetLayers.offsetDate), indicator.timeline), 
                    ApplyTimeline(ParseGenericDate(layer.bounds.greater.value, true, this.widgetLayers.offsetDate), indicator.timeline), 
                    'D');
                }

                if(layer.bounds.has('greater')) {
                  const bound = layer.bounds.greater;
                  match.push({ [layer.uid.value]: { [bound.equivalent ? '$gte' : '$gt']: ApplyTimeline(ParseGenericDate(bound.value, true, this.widgetLayers.offsetDate), indicator.timeline, periodLength) } });
                }
                if(layer.bounds.has('less')) {
                  const bound = layer.bounds.less;
                  match.push({ [layer.uid.value]: { [bound.equivalent ? '$lte' : '$lt']: ApplyTimeline(ParseGenericDate(bound.value, false, this.widgetLayers.offsetDate), indicator.timeline, periodLength) } });
                }
              }

            }
          });

          let drillMatch = this.widgetLayers.drillMatch;

          drillMatch.forEach((value: {[key: string]: string}, index: number) => {
            const _layer = this.widgetLayers.layer(Object.keys(value)[0])
            let list: string[];
            if(_layer.layerType === 'dates') {
              if(indicator.cumulative) {

                const ordered = Object.keys(this.vanila[indicator.timeline]).map(d => {
                  return {
                    date: Moment()(d, _layer.dateFormat).format(BACKEND_DATE_FORMAT),
                    label: d
                  }
                }).sort((dA, dB) => dA.date.localeCompare(dB.date)).map(o => o.label);

                const labels = ordered.filter((l, i) => i <= ordered.indexOf(Object.values(value)[0])) as string[];

                for(let i = 0 ; i < labels.length ; i++) {
                  if(!list) list = [];
                  list.push(...this.vanila[indicator.timeline][labels[i]]);
                }
              }
              else list = this.vanila[indicator.timeline][Object.values(value)[0]];

              if(list === undefined) drillMatch[index][Object.keys(value)[0]] = '@undefined';
              else {
                drillMatch[index][_layer.uid.value] = { $in: [... new Set(list)] } as any;
              }
              //on retire les bornes sur les dates car les filtres de dates sur le funnel sont plus restrictifs
              match = match.filter(m => Object.keys(m)[0] !== _layer.uid.value);
            }
          });

          match = match.concat(drillMatch);

          //this.$values.get(indicator.uid.value, indicator.serverLink, this.widgetLayers.drillUID, match)
          return this.$values.get(indicator.uid.value, indicator.serverLink, this.widgetLayers.drillUID, match, this.widgetLayers.gettab && indicator.inTable ? Object.values(this.widgetLayers.layers).filter(l => l.inTable).map(l => l.uid.value) : []);
        })
      ).pipe(take(1)).subscribe((responses: ServerResponseModel[]) => {

        const layer = this.widgetLayers.layer(this.widgetLayers.drillUID) as WidgetLayerClass;
        const dateFormat = this.widgetLayers.dateFormat.length > 0 ? this.widgetLayers.dateFormat : layer.dateFormat;
        const isDateLayer = layer.layerType === 'dates' && dateFormat !== null;
        let sortLabels = [];
        this.labels = [];
        this.series = this.widgetLayers.indicators.map(() => { return {} as ChartDataSets; });

        //initialisation des séries
        responses.forEach((response: ServerResponseModel, index: number) => {
          const result = response.message.values as { _id: { label: string }, value: number }[];
          const uid = response.message.collection as UID;

          //tri des libellés par leur valeur si demandé pour ce layer
          if(layer !== undefined && this.widgetLayers.indicators[index].uid.value === layer.sortUID) {
            if(layer.sort === 'value_asc') {
              sortLabels = result
                .sort((a, b) => a.value - b.value)
                .map((row: { _id: { label: string, uid: UID }, value: number }) => row._id.label.toString());
            }
            else if(layer.sort === 'value_desc') {
              sortLabels = result
                .sort((a, b) => b.value - a.value)
                .map((row: { _id: { label: string, uid: UID }, value: number }) => row._id.label.toString());
            }
          }
          
          //initialisation de la série
          this.series[index] = {
            uid: uid,
            match: response.message.match,
            formulaUID: this.widgetLayers.indicators[index].formulaUID,
            label: this.widgetLayers.indicators[index].labels.value(this.language),
            tab: response.message.columns,
            data: result.map((row: { _id: { label: string }, value: number }) => row.value),
            vanila: result.map((row: { _id: { label: string, uid: UID }, value: number }) => (row._id.label || '').toString()),
            labels: result.map((row: { _id: { label: string, uid: UID }, value: number }) => (isDateLayer ? DateApplyFormat(row._id.label, dateFormat) : (row._id.label || '')).toString())
          }
        });

        //tri des données
        if(layer !== undefined) {
          let sortedLabels = [];
          switch (layer.sort) {
            case 'value_asc':
            case 'value_desc':
              this.labels = this.reduceLabels(layer, dateFormat);

              //ajout des labels inexistant dans l'indicateur de référence du tri
              this.labels.forEach((label: Label) => {
                if(sortLabels.indexOf(label) === -1) sortLabels.push(label);
              });
              //remplacement des labels par les labels triés
              this.labels = sortLabels; 
              break;
            case 'label_asc':
              if(isDateLayer) {
                sortedLabels = this.sortedLabels(layer, dateFormat);

                //ajout des labels inexistant dans l'indicateur de référence du tri
                this.labels.forEach((label: Label) => {
                  if(sortLabels.indexOf(label) === -1) sortLabels.push(label);
                });

                this.labels = this.reduceLabels(layer, dateFormat, sortedLabels).sort((a: string, b: string) => {  
                  return sortedLabels.indexOf(a) - sortedLabels.indexOf(b);
                });
              }
              else {
                this.labels = this.reduceLabels(layer, dateFormat).sort((a: string, b: string) => {
                  return a.localeCompare(b, undefined, { sensitivity: 'base' });
                });
              }
              break;
            case 'label_desc':
              if(isDateLayer) {
                sortedLabels = this.sortedLabels(layer, dateFormat);

                //ajout des labels inexistant dans l'indicateur de référence du tri
                this.labels.forEach((label: Label) => {
                  if(sortLabels.indexOf(label) === -1) sortLabels.push(label);
                });
                
                this.labels = this.reduceLabels(layer, dateFormat, sortedLabels).sort((a: string, b: string) => {  
                  return sortedLabels.indexOf(a) - sortedLabels.indexOf(b);
                });
              }
              else {
                this.labels = this.reduceLabels(layer, dateFormat).sort((a: string, b: string) => {
                  return b.localeCompare(a, undefined, { sensitivity: 'base' });
                });
              }
              break;
          }
        }

        if(isDateLayer) this.setVanila(layer as WidgetLayerClass, dateFormat);

        this.series.forEach((serie: ChartDataSets, index: number) => {
          if(isDateLayer) this.series[index] = this.setValuesAndLabelsForDate(serie, this.labels, dateFormat);
          else this.series[index] = this.setValuesAndLabels(serie, this.labels);
        });

        //calcul des montants globaux
        this.series.forEach((serie: ChartDataSets, index: number) => {
          this.series[index].total = Total(serie.data as number[]);
          this.series[index].min = Minimum(serie.data as number[]);
          this.series[index].max = Maximum(serie.data as number[]);
          this.series[index].average = Average(serie.data as number[]);
        });

        //mise en place des cumuls
        if(this.widgetLayers.layer(this.widgetLayers.drillUID).layerType === 'dates') {
          this.widgetLayers.indicators.forEach((indicator: WidgetIndicatorClass, index: number) => {
            if(indicator.cumulative) {
              let sum: number;
              const lastValueIndexes = (this.series[index].data as number[]).map((elem, index) => elem > 0 ? index : -1).filter(index => index > 0);
              const lastValueIndexe = lastValueIndexes.length > 0 ? lastValueIndexes[lastValueIndexes.length - 1] : 0;
              this.series[index].data = (this.series[index].data as number[]).map((elem, index) => {
                return index <= lastValueIndexe ? sum = (sum || 0) + elem : undefined;
              });
            }
          });
        }

        const isFormula = this.widgetLayers.indicators.map(i => i.formula.length).filter(l => l > 0).length > 0;

        if(isFormula) {
          //collecte des données pour l'application des formules
          let scopes = [];
          let globals = {};
          if(this.series.length > 0) {
            this.series[0].data.forEach((value: any, index: number) => {
              scopes.push({});
              this.series.forEach((datasets: ChartDataSets) => {
                scopes[index][datasets.formulaUID] = datasets.data[index] === undefined ? 0 : datasets.data[index];
                globals[datasets.formulaUID] = (globals[datasets.formulaUID] || 0) + scopes[index][datasets.formulaUID];
              });
            });
          }
          
          //application des formules
          this.series.forEach((datasets: ChartDataSets, index: number) => {
            const indicator = this.widgetLayers.indicators[index];
            if(indicator.formula.length > 0) {
              let results = [];
              datasets.data.forEach((value: any, i: number) => {
                let result = undefined;
                try {
                  result = math.evaluate(indicator.formula, scopes[i]);
                }
                finally {
                  results.push(result);
                }
              });
              //on réinjecte le résultat de la formule dans la série
              this.series[index].data = results;

              //calcul des montants globaux
              if(datasets.data.length > 0) {
                let total = undefined;
                try {
                  total = math.evaluate(indicator.formula, globals);
                }
                finally {
                  this.series[index].total = total;
                }
                this.series[index].min = Minimum(this.series[index].data as number[]);
                this.series[index].max = Maximum(this.series[index].data as number[]);
                this.series[index].average = Average(this.series[index].data as number[]);
              }
            }
          });
        }

        //suppression des libellés sans aucune valeur dans aucun indicateur
        if(!isDateLayer) {
          this.labels.forEach((label: Label, index: number) => {
            const isOneValue = this.series.filter(s => s.data[index] !== undefined).length > 0;
            if(!isOneValue) {
              this.labels[index] = null;
              this.series.forEach((serie: ChartDataSets, i: number) => {
                this.series[i].data[index] = null;
              });
            }
          });
        }
        this.labels = this.labels.filter(l => l !== null);
        this.series.forEach((serie: ChartDataSets, index: number) => {
          this.series[index].data = (this.series[index].data as number[]).filter(l => l !== null);
        });
        
        resolve();
      });
    });
  }

  private setValuesAndLabels(serie: ChartDataSets, labels: Label[]): ChartDataSets {
    let _values = [];
    labels.forEach((label: Label) => {
      const index = serie.labels.indexOf(label);
      _values.push(index === -1 ? undefined : serie.data[index]);
    });
    serie.data = _values;
    serie.labels = labels.slice();
    return serie;
  }

  private setValuesAndLabelsForDate(serie: ChartDataSets, labels: Label[], dateFormat: string): ChartDataSets {
    let _values = new Array(labels.length).fill(undefined);
    serie.vanila.forEach((label: string, index: number) => {
      if(!isNaN(serie.data[index] as number)) {
        const i = labels.indexOf(DateApplyFormat(label, dateFormat));
        if(i !== -1 && serie.data[index] !== undefined) {
          if(_values[i] === undefined) _values[i] = 0;
          _values[i] = _values[i] + serie.data[index];
        }
      }
    });
    serie.data = _values;
    serie.labels = labels.slice();
    return serie;
  }

  private enumerateDaysBetweenDates(startDate: string, endDate: string): string[] {
    var now = moment(startDate), dates = [];

    while (now.isSameOrBefore(endDate)) {
        dates.push(now.format(BACKEND_DATE_FORMAT));
        now.add(1, 'days');
    }
    return dates;
  };
  
  private sortedLabels(layer: WidgetLayerClass, dateFormat: string): Label[] {
    let _labels = [];
    this.series.forEach((serie: ChartDataSets) => {
      _labels = _labels.concat(serie.vanila);
    });
    _labels = _labels.concat(this.occurences(layer, _labels, false, dateFormat));

    const greaterParsed = this.widgetLayers.bounds[layer.uid.value] !== undefined && this.widgetLayers.bounds[layer.uid.value].greaterParsed.length > 0 ? 
      this.widgetLayers.bounds[layer.uid.value].greaterParsed : layer.bounds.greaterParsed;

    const lessParsed = this.widgetLayers.bounds[layer.uid.value] !== undefined && this.widgetLayers.bounds[layer.uid.value].lessParsed.length > 0 ? 
      this.widgetLayers.bounds[layer.uid.value].lessParsed : layer.bounds.lessParsed;

    _labels = _labels.concat(this.enumerateDaysBetweenDates(greaterParsed, lessParsed));

    if(layer.sort === 'label_asc') {
      _labels = _labels
        .sort((a: string, b: string) => {
          return IsBefore(a, b, 'YYYY-MM-DD') ? -1 : 1;
        })
        .map((label: string) => DateApplyFormat(label, dateFormat));
    }
    else if(layer.sort === 'label_desc') {
      _labels = _labels
        .sort((a: string, b: string) => {
          return IsBefore(a, b, 'YYYY-MM-DD') ? 1 : -1;
        })
        .map((label: string) => DateApplyFormat(label, dateFormat));
    }
    return _labels.filter((label: Label, index: number) => index === _labels.indexOf(label));
  }

  private setVanila(layer: WidgetLayerClass, dateFormat: string) {
    this.vanila = {
      reference: {},
      previousYear: {},
      previousPeriod: {}
    };


    this.series.forEach((serie: ChartDataSets, index: number) => {
      const timeline = this.widgetLayers.indicators[index].timeline;
      serie.vanila.forEach((value: string) => {
        let list = this.vanila[timeline][DateApplyFormat(value, dateFormat)];
        
        if(list === undefined) list = [];
        list.push(value);

        this.vanila[timeline][DateApplyFormat(value, dateFormat)] = list;
      });
    });
  }
  
  private reduceLabels(layer: WidgetLayerClass, dateFormat: string, sortedLabels: string[] = []): Label[] {
    let _labels = [];
    this.series.forEach((serie: ChartDataSets) => {
      _labels = _labels.concat(serie.labels);
    });
    _labels = _labels.concat(sortedLabels);

    _labels = _labels.filter((label: Label, index: number) => index === _labels.indexOf(label));
    _labels = _labels.concat(this.occurences(layer, _labels, true, dateFormat));
    _labels = _labels.filter((label: Label, index: number) => index === _labels.indexOf(label));

    return _labels;
  }

  private occurences(layer: WidgetLayerClass, labels: string[], applyFormat: boolean, dateFormat: string): string[] {
    let occurences = [] as string[];
    if(layer.fillBlanks && layer.layerType === 'dates' && labels.length > 0) {

      let bounds = layer.bounds;
      if(this.widgetLayers.bounds[layer.uid.value].hasAny) {
        bounds = this.widgetLayers.bounds[layer.uid.value];
      }

      const sortedLayers = labels.sort();
      const from = layer.bounds.has('greater') ? ParseGenericDate(layer.bounds.greater.value, true, this.widgetLayers.offsetDate) : sortedLayers[0];
      const to = layer.bounds.has('less') ? ParseGenericDate(layer.bounds.less.value, false, this.widgetLayers.offsetDate) : sortedLayers[sortedLayers.length - 1];
      occurences = Occurences(from, to, applyFormat ? dateFormat : BACKEND_DATE_FORMAT);
    }
    return occurences;
  }
}