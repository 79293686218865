<app-links-item [color]="colors['dates']" [icon]="icon" [labels]="date.labels" [uid]="date.uid">
  <div app-links-item-content-description>
  </div>
  <div app-links-item-content-option class="d-flex align-items-center">
  <mdb-badge *ngIf="layer.bounds.count == 0 ; else has_filters" color="grey" class="z-depth-0">0<i class="fad fa-calendar ml-1"></i></mdb-badge>
    <button (click)="onSelect()" type="button" mdbBtn color="blue-grey" size="sm" class="z-depth-0 d-flex px-2 align-items-center my-0 mr-0 ml-2" style="width: 30px; height: 31px;" mdbWavesEffect>
      <i class="fas fa-angle-{{ current ? 'down' : 'left' }} fa-fw"></i>
    </button>
  </div>
  <div app-links-item-content-sub class="mt-3" *ngIf="current">

    <div class="p-2 rounded white mt-1 d-flex w-100 align-items-center app-cursor-pointer">
      <div class="font-weight-bold mr-auto font-small">{{ 'CLEAR_ENTRY' | translate }}</div>

      <button (click)="layer.bounds.flush()" type="button" mdbBtn color="warning" size="sm" class="z-depth-0 d-flex px-2 align-items-center my-0 mr-0 ml-2" style="width: 30px; height: 31px;" mdbWavesEffect>
        <i class="fas fa-eraser fa-fw"></i>
      </button>
    </div>

    <hr>

    <div class="p-2 rounded white mt-1 w-100 d-flex flex-column">
      <div class="d-flex align-items-center">
        <div class="flex-grow-1">
          <app-moment *ngIf="!layer.bounds.greater.equivalent ; else greaterOrEquivalent" [value]="fromGeneric" [label]="'FROM'" [color]="colors['dates']" (sendValue)="receiveGreater($event, false)"></app-moment>
        </div>
        <app-links-item-option class="d-block ml-3 mt-2" [symbol]="'greater-than'" [selected]="!layer.bounds.greater.equivalent" (sendSelected)="receiveSetGreater(false)"></app-links-item-option>
        <app-links-item-option class="d-block ml-2 mt-2" [symbol]="'greater-than-equal'" [selected]="layer.bounds.greater.equivalent" (sendSelected)="receiveSetGreater(true)"></app-links-item-option>
      </div>
      <div class="mx-1 font-very-small text-muted">{{ from }}</div>
    </div>

    <div class="p-2 rounded white mt-1 w-100 d-flex flex-column">
      <div class="d-flex align-items-center">
        <div class="flex-grow-1">
          <app-moment *ngIf="!layer.bounds.less.equivalent ; else lessOrEquivalent" [value]="toGeneric" [label]="'TO'" [color]="colors['dates']" (sendValue)="receiveLess($event, false)"></app-moment>
        </div>
        <app-links-item-option class="d-block ml-3 mt-2" [symbol]="'less-than'" [selected]="!layer.bounds.less.equivalent" (sendSelected)="receiveSetLess(false)"></app-links-item-option>
        <app-links-item-option class="d-block ml-2 mt-2" [symbol]="'less-than-equal'" [selected]="layer.bounds.less.equivalent" (sendSelected)="receiveSetLess(true)"></app-links-item-option>
      </div>
      <div class="mx-1 font-very-small text-muted">{{ to }}</div>
    </div>

  </div>
</app-links-item>

<ng-template #app_loading>
  <app-loading></app-loading>
</ng-template>

<ng-template #has_filters>
  <mdb-badge (click)="layer.bounds.flush()" color="green" class="z-depth-0 app-cursor-pointer">{{ layer.bounds.count }}<i class="fad fa-calendar-check ml-1"></i></mdb-badge>
</ng-template>

<ng-template #greaterOrEquivalent>
  <app-moment [value]="fromGeneric" [label]="'FROM'" [color]="colors['dates']" (sendValue)="receiveGreater($event, true)"></app-moment>
</ng-template>

<ng-template #lessOrEquivalent>
  <app-moment [value]="toGeneric" [label]="'TO'" [color]="colors['dates']" (sendValue)="receiveLess($event, true)"></app-moment>
</ng-template>