/**
 * @module Theme
 */

import { ThemeOptionsHostClass } from '@class/themeOptions.class';
import { Type } from '@angular/core';
import { ThemeOptionTitleDisplayComponent } from '@components/themes/theme-studio/options/title/theme-option-title-display/theme-option-title-display.component';
import { ThemeOptionTitleFontFamilyComponent } from '@components/themes/theme-studio/options/title/theme-option-title-font-family/theme-option-title-font-family.component';
import { ThemeOptionTitleFontColorComponent } from '@components/themes/theme-studio/options/title/theme-option-title-font-color/theme-option-title-font-color.component';
import { ThemeOptionAnimationDurationComponent } from '@components/themes/theme-studio/options/animation/theme-option-animation-duration/theme-option-animation-duration.component';
import { ThemeOptionAnimationEasingComponent } from '@components/themes/theme-studio/options/animation/theme-option-animation-easing/theme-option-animation-easing.component';
import { ThemeOptionAnimationAnimateRotateComponent } from '@components/themes/theme-studio/options/animation/theme-option-animation-animate-rotate/theme-option-animation-animate-rotate.component';
import { ThemeOptionAnimationAnimateScaleComponent } from '@components/themes/theme-studio/options/animation/theme-option-animation-animate-scale/theme-option-animation-animate-scale.component';
import { ThemeOptionLayoutPaddingComponent } from '@components/themes/theme-studio/options/layout/theme-option-layout-padding/theme-option-layout-padding.component';
import { ThemeOptionLayoutBackgroundColorComponent } from '@components/themes/theme-studio/options/layout/theme-option-layout-background-color/theme-option-layout-background-color.component';
import { ThemeOptionLegendDisplayComponent } from '@components/themes/theme-studio/options/legend/theme-option-legend-display/theme-option-legend-display.component';
import { ThemeOptionLegendPositionComponent } from '@components/themes/theme-studio/options/legend/theme-option-legend-position/theme-option-legend-position.component';
import { ThemeOptionLegendFullWidthComponent } from '@components/themes/theme-studio/options/legend/theme-option-legend-full-width/theme-option-legend-full-width.component';
import { ThemeOptionLegendReverseComponent } from '@components/themes/theme-studio/options/legend/theme-option-legend-reverse/theme-option-legend-reverse.component';
import { ThemeOptionLegendBoxWidthComponent } from '@components/themes/theme-studio/options/legend/theme-option-legend-box-width/theme-option-legend-box-width.component';
import { ThemeOptionLegendFontSizeComponent } from '@components/themes/theme-studio/options/legend/theme-option-legend-font-size/theme-option-legend-font-size.component';
import { ThemeOptionLegendFontStyleComponent } from '@components/themes/theme-studio/options/legend/theme-option-legend-font-style/theme-option-legend-font-style.component';
import { ThemeOptionLegendFontColorComponent } from '@components/themes/theme-studio/options/legend/theme-option-legend-font-color/theme-option-legend-font-color.component';
import { ThemeOptionLegendFontFamilyComponent } from '@components/themes/theme-studio/options/legend/theme-option-legend-font-family/theme-option-legend-font-family.component';
import { ThemeOptionLegendPaddingComponent } from '@components/themes/theme-studio/options/legend/theme-option-legend-padding/theme-option-legend-padding.component';
import { ThemeOptionLegendUsePointStyleComponent } from '@components/themes/theme-studio/options/legend/theme-option-legend-use-point-style/theme-option-legend-use-point-style.component';
import { ThemeOptionTitlePositionComponent } from '@components/themes/theme-studio/options/title/theme-option-title-position/theme-option-title-position.component';
import { ThemeOptionTitleFontSizeComponent } from '@components/themes/theme-studio/options/title/theme-option-title-font-size/theme-option-title-font-size.component';
import { ThemeOptionTitleFontStyleComponent } from '@components/themes/theme-studio/options/title/theme-option-title-font-style/theme-option-title-font-style.component';
import { ThemeOptionTitlePaddingComponent } from '@components/themes/theme-studio/options/title/theme-option-title-padding/theme-option-title-padding.component';
import { ThemeOptionTitleLineHeightComponent } from '@components/themes/theme-studio/options/title/theme-option-title-line-height/theme-option-title-line-height.component';
import { ThemeOptionTooltipsDisplayComponent } from '@components/themes/theme-studio/options/tooltips/theme-option-tooltips-display/theme-option-tooltips-display.component';
import { ThemeOptionTooltipsModeComponent } from '@components/themes/theme-studio/options/tooltips/theme-option-tooltips-mode/theme-option-tooltips-mode.component';
import { ThemeOptionTooltipsIntersectComponent } from '@components/themes/theme-studio/options/tooltips/theme-option-tooltips-intersect/theme-option-tooltips-intersect.component';
import { ThemeOptionTooltipsPositionComponent } from '@components/themes/theme-studio/options/tooltips/theme-option-tooltips-position/theme-option-tooltips-position.component';
import { ThemeOptionTooltipsBackgroundColorComponent } from '@components/themes/theme-studio/options/tooltips/theme-option-tooltips-background-color/theme-option-tooltips-background-color.component';
import { ThemeOptionTooltipsTitleFontFamilyComponent } from '@components/themes/theme-studio/options/tooltips/theme-option-tooltips-title-font-family/theme-option-tooltips-title-font-family.component';
import { ThemeOptionTooltipsTitleFontSizeComponent } from '@components/themes/theme-studio/options/tooltips/theme-option-tooltips-title-font-size/theme-option-tooltips-title-font-size.component';
import { ThemeOptionTooltipsTitleFontStyleComponent } from '@components/themes/theme-studio/options/tooltips/theme-option-tooltips-title-font-style/theme-option-tooltips-title-font-style.component';
import { ThemeOptionTooltipsTitleFontColorComponent } from '@components/themes/theme-studio/options/tooltips/theme-option-tooltips-title-font-color/theme-option-tooltips-title-font-color.component';
import { ThemeOptionTooltipsTitleAlignComponent } from '@components/themes/theme-studio/options/tooltips/theme-option-tooltips-title-align/theme-option-tooltips-title-align.component';
import { ThemeOptionTooltipsTitleSpacingComponent } from '@components/themes/theme-studio/options/tooltips/theme-option-tooltips-title-spacing/theme-option-tooltips-title-spacing.component';
import { ThemeOptionTooltipsTitleMarginBottomComponent } from '@components/themes/theme-studio/options/tooltips/theme-option-tooltips-title-margin-bottom/theme-option-tooltips-title-margin-bottom.component';
import { ThemeOptionTooltipsBodyFontFamilyComponent } from '@components/themes/theme-studio/options/tooltips/theme-option-tooltips-body-font-family/theme-option-tooltips-body-font-family.component';
import { ThemeOptionTooltipsBodyFontSizeComponent } from '@components/themes/theme-studio/options/tooltips/theme-option-tooltips-body-font-size/theme-option-tooltips-body-font-size.component';
import { ThemeOptionTooltipsBodyFontStyleComponent } from '@components/themes/theme-studio/options/tooltips/theme-option-tooltips-body-font-style/theme-option-tooltips-body-font-style.component';
import { ThemeOptionTooltipsBodyFontColorComponent } from '@components/themes/theme-studio/options/tooltips/theme-option-tooltips-body-font-color/theme-option-tooltips-body-font-color.component';
import { ThemeOptionTooltipsBodyAlignComponent } from '@components/themes/theme-studio/options/tooltips/theme-option-tooltips-body-align/theme-option-tooltips-body-align.component';
import { ThemeOptionTooltipsBodySpacingComponent } from '@components/themes/theme-studio/options/tooltips/theme-option-tooltips-body-spacing/theme-option-tooltips-body-spacing.component';
import { ThemeOptionTooltipsXPaddingComponent } from '@components/themes/theme-studio/options/tooltips/theme-option-tooltips-xpadding/theme-option-tooltips-xpadding.component';
import { ThemeOptionTooltipsCaretPaddingComponent } from '@components/themes/theme-studio/options/tooltips/theme-option-tooltips-caret-padding/theme-option-tooltips-caret-padding.component';
import { ThemeOptionTooltipsCaretSizeComponent } from '@components/themes/theme-studio/options/tooltips/theme-option-tooltips-caret-size/theme-option-tooltips-caret-size.component';
import { ThemeOptionTooltipsCornerRadiusComponent } from '@components/themes/theme-studio/options/tooltips/theme-option-tooltips-corner-radius/theme-option-tooltips-corner-radius.component';
import { ThemeOptionTooltipsMultiKeyBackgroundComponent } from '@components/themes/theme-studio/options/tooltips/theme-option-tooltips-multi-key-background/theme-option-tooltips-multi-key-background.component';
import { ThemeOptionTooltipsDisplayColorsComponent } from '@components/themes/theme-studio/options/tooltips/theme-option-tooltips-display-colors/theme-option-tooltips-display-colors.component';
import { ThemeOptionTooltipsBorderColorComponent } from '@components/themes/theme-studio/options/tooltips/theme-option-tooltips-border-color/theme-option-tooltips-border-color.component';
import { ThemeOptionTooltipsBorderWidthComponent } from '@components/themes/theme-studio/options/tooltips/theme-option-tooltips-border-width/theme-option-tooltips-border-width.component';
import { ThemeOptionElementsPointStyleComponent } from '@components/themes/theme-studio/options/elements/point/theme-option-elements-point-style/theme-option-elements-point-style.component';
import { ThemeOptionElementsPointRadiusComponent } from '@components/themes/theme-studio/options/elements/point/theme-option-elements-point-radius/theme-option-elements-point-radius.component';
import { ThemeOptionElementsPointRotationComponent } from '@components/themes/theme-studio/options/elements/point/theme-option-elements-point-rotation/theme-option-elements-point-rotation.component';
import { ThemeOptionElementsPointBackgroundColorComponent } from '@components/themes/theme-studio/options/elements/point/theme-option-elements-point-background-color/theme-option-elements-point-background-color.component';
import { ThemeOptionElementsPointBorderColorComponent } from '@components/themes/theme-studio/options/elements/point/theme-option-elements-point-border-color/theme-option-elements-point-border-color.component';
import { ThemeOptionElementsPointBorderWidthComponent } from '@components/themes/theme-studio/options/elements/point/theme-option-elements-point-border-width/theme-option-elements-point-border-width.component';
import { ThemeOptionElementsPointHitRadiusComponent } from '@components/themes/theme-studio/options/elements/point/theme-option-elements-point-hit-radius/theme-option-elements-point-hit-radius.component';
import { ThemeOptionElementsPointHoverRadiusComponent } from '@components/themes/theme-studio/options/elements/point/theme-option-elements-point-hover-radius/theme-option-elements-point-hover-radius.component';
import { ThemeOptionElementsPointHoverBackgroundColorComponent } from '@components/themes/theme-studio/options/elements/point/theme-option-elements-point-hover-background-color/theme-option-elements-point-hover-background-color.component';
import { ThemeOptionElementsPointHoverBorderColorComponent } from '@components/themes/theme-studio/options/elements/point/theme-option-elements-point-hover-border-color/theme-option-elements-point-hover-border-color.component';
import { ThemeOptionElementsPointHoverBorderWidthComponent } from '@components/themes/theme-studio/options/elements/point/theme-option-elements-point-hover-border-width/theme-option-elements-point-hover-border-width.component';
import { ThemeOptionElementsBackgroundColorComponent } from '@components/themes/theme-studio/options/elements/serie/theme-option-elements-background-color/theme-option-elements-background-color.component';
import { ThemeOptionElementsBorderColorComponent } from '@components/themes/theme-studio/options/elements/serie/theme-option-elements-border-color/theme-option-elements-border-color.component';
import { ThemeOptionElementsBorderWidthComponent } from '@components/themes/theme-studio/options/elements/serie/theme-option-elements-border-width/theme-option-elements-border-width.component';
import { ThemeOptionElementsFillComponent } from '@components/themes/theme-studio/options/elements/serie/theme-option-elements-fill/theme-option-elements-fill.component';
import { ThemeOptionElementsLineTensionComponent } from '@components/themes/theme-studio/options/elements/serie/theme-option-elements-line-tension/theme-option-elements-line-tension.component';
import { ThemeOptionElementsHideInLegendAndTooltipComponent } from '@components/themes/theme-studio/options/elements/serie/theme-option-elements-hide-in-legend-and-tooltip/theme-option-elements-hide-in-legend-and-tooltip.component';
import { ThemeOptionElementsHiddenComponent } from '@components/themes/theme-studio/options/elements/serie/theme-option-elements-hidden/theme-option-elements-hidden.component';
import { ThemeOptionElementsSpanGapsComponent } from '@components/themes/theme-studio/options/elements/serie/theme-option-elements-span-gaps/theme-option-elements-span-gaps.component';
import { ThemeOptionElementsSteppedLineComponent } from '@components/themes/theme-studio/options/elements/serie/theme-option-elements-stepped-line/theme-option-elements-stepped-line.component';
import { ThemeOptionElementsBorderDashComponent } from '@components/themes/theme-studio/options/elements/serie/theme-option-elements-border-dash/theme-option-elements-border-dash.component';
import { ThemeOptionElementsBorderCapStyleComponent } from '@components/themes/theme-studio/options/elements/serie/theme-option-elements-border-cap-style/theme-option-elements-border-cap-style.component';
import { ThemeOptionElementsBorderJoinStyleComponent } from '@components/themes/theme-studio/options/elements/serie/theme-option-elements-border-join-style/theme-option-elements-border-join-style.component';
import { ThemeOptionElementsHoverBackgroundColorComponent } from '@components/themes/theme-studio/options/elements/serie/theme-option-elements-hover-background-color/theme-option-elements-hover-background-color.component';
import { ThemeOptionElementsHoverBorderColorComponent } from '@components/themes/theme-studio/options/elements/serie/theme-option-elements-hover-border-color/theme-option-elements-hover-border-color.component';
import { ThemeOptionElementsHoverBorderWidthComponent } from '@components/themes/theme-studio/options/elements/serie/theme-option-elements-hover-border-width/theme-option-elements-hover-border-width.component';
import { ThemeOptionElementsHoverBorderDashComponent } from '@components/themes/theme-studio/options/elements/serie/theme-option-elements-hover-border-dash/theme-option-elements-hover-border-dash.component';
import { ThemeOptionElementsChartCutoutPercentageComponent } from '@components/themes/theme-studio/options/elements/chart/theme-option-elements-chart-cutout-percentage/theme-option-elements-chart-cutout-percentage.component';
import { ThemeOptionElementsChartCircumferenceComponent } from '@components/themes/theme-studio/options/elements/chart/theme-option-elements-chart-circumference/theme-option-elements-chart-circumference.component';
import { ThemeOptionElementsChartRotationComponent } from '@components/themes/theme-studio/options/elements/chart/theme-option-elements-chart-rotation/theme-option-elements-chart-rotation.component';
import { ThemeOptionElementsChartTransitionAnimationComponent } from '@components/themes/theme-studio/options/elements/chart/theme-option-elements-chart-transition-animation/theme-option-elements-chart-transition-animation.component';
import { ThemeOptionElementsIconFrameComponent } from '@components/themes/theme-studio/options/elements/serie/theme-option-elements-icon-frame/theme-option-elements-icon-frame.component';
import { ThemeOptionElementsBorderAlignComponent } from '@components/themes/theme-studio/options/elements/serie/theme-option-elements-border-align/theme-option-elements-border-align.component';
import { ThemeOptionElementsWeightComponent } from '@components/themes/theme-studio/options/elements/serie/theme-option-elements-weight/theme-option-elements-weight.component';
import { ThemeOptionElementsDatalabelsIdComponent } from '@components/themes/theme-studio/options/elements/serie/theme-option-elements-datalabels-id/theme-option-elements-datalabels-id.component';
import { ThemeOptionElementsXAxisIDComponent } from '@components/themes/theme-studio/options/elements/serie/theme-option-elements-xaxis-id/theme-option-elements-xaxis-id.component';
import { ThemeOptionElementsYAxisIDComponent } from '@components/themes/theme-studio/options/elements/serie/theme-option-elements-yaxis-id/theme-option-elements-yaxis-id.component';
import { ThemeOptionDatalabelsAnchorComponent } from '@components/themes/theme-studio/options/datalabels/theme-option-datalabels-anchor/theme-option-datalabels-anchor.component';
import { ThemeOptionDatalabelsAlignComponent } from '@components/themes/theme-studio/options/datalabels/theme-option-datalabels-align/theme-option-datalabels-align.component';
import { ThemeOptionDatalabelsClampComponent } from '@components/themes/theme-studio/options/datalabels/theme-option-datalabels-clamp/theme-option-datalabels-clamp.component';
import { ThemeOptionDatalabelsDisplayComponent } from '@components/themes/theme-studio/options/datalabels/theme-option-datalabels-display/theme-option-datalabels-display.component';
import { ThemeOptionDatalabelsOffsetComponent } from '@components/themes/theme-studio/options/datalabels/theme-option-datalabels-offset/theme-option-datalabels-offset.component';
import { ThemeOptionDatalabelsRotationComponent } from '@components/themes/theme-studio/options/datalabels/theme-option-datalabels-rotation/theme-option-datalabels-rotation.component';
import { ThemeOptionDatalabelsClipComponent } from '@components/themes/theme-studio/options/datalabels/theme-option-datalabels-clip/theme-option-datalabels-clip.component';
import { ThemeOptionDatalabelsBorderWidthComponent } from '@components/themes/theme-studio/options/datalabels/theme-option-datalabels-border-width/theme-option-datalabels-border-width.component';
import { ThemeOptionDatalabelsBorderColorComponent } from '@components/themes/theme-studio/options/datalabels/theme-option-datalabels-border-color/theme-option-datalabels-border-color.component';
import { ThemeOptionDatalabelsBorderRadiusComponent } from '@components/themes/theme-studio/options/datalabels/theme-option-datalabels-border-radius/theme-option-datalabels-border-radius.component';
import { ThemeOptionDatalabelsPaddingComponent } from '@components/themes/theme-studio/options/datalabels/theme-option-datalabels-padding/theme-option-datalabels-padding.component';
import { ThemeOptionDatalabelsBackgroundColorComponent } from '@components/themes/theme-studio/options/datalabels/theme-option-datalabels-background-color/theme-option-datalabels-background-color.component';
import { ThemeOptionDatalabelsColorComponent } from '@components/themes/theme-studio/options/datalabels/theme-option-datalabels-color/theme-option-datalabels-color.component';
import { ThemeOptionDatalabelsFontFamilyComponent } from '@components/themes/theme-studio/options/datalabels/theme-option-datalabels-font-family/theme-option-datalabels-font-family.component';
import { ThemeOptionDatalabelsFontSizeComponent } from '@components/themes/theme-studio/options/datalabels/theme-option-datalabels-font-size/theme-option-datalabels-font-size.component';
import { ThemeOptionDatalabelsFontLineHeightComponent } from '@components/themes/theme-studio/options/datalabels/theme-option-datalabels-font-line-height/theme-option-datalabels-font-line-height.component';
import { ThemeOptionDatalabelsOpacityComponent } from '@components/themes/theme-studio/options/datalabels/theme-option-datalabels-opacity/theme-option-datalabels-opacity.component';
import { ThemeOptionDatalabelsTextAlignComponent } from '@components/themes/theme-studio/options/datalabels/theme-option-datalabels-text-align/theme-option-datalabels-text-align.component';
import { ThemeOptionDatalabelsTextStrokeColorComponent } from '@components/themes/theme-studio/options/datalabels/theme-option-datalabels-text-stroke-color/theme-option-datalabels-text-stroke-color.component';
import { ThemeOptionDatalabelsTextStrokeWidthComponent } from '@components/themes/theme-studio/options/datalabels/theme-option-datalabels-text-stroke-width/theme-option-datalabels-text-stroke-width.component';
import { ThemeOptionDatalabelsTextShadowBlurComponent } from '@components/themes/theme-studio/options/datalabels/theme-option-datalabels-text-shadow-blur/theme-option-datalabels-text-shadow-blur.component';
import { ThemeOptionDatalabelsTextShadowColorComponent } from '@components/themes/theme-studio/options/datalabels/theme-option-datalabels-text-shadow-color/theme-option-datalabels-text-shadow-color.component';
import { ThemeOptionAxesDisplayComponent } from '@components/themes/theme-studio/options/axes/axis/theme-option-axes-display/theme-option-axes-display.component';
import { ThemeOptionAxesBarPercentageComponent } from '@components/themes/theme-studio/options/axes/axis/theme-option-axes-bar-percentage/theme-option-axes-bar-percentage.component';
import { ThemeOptionAxesCategoryPercentageComponent } from '@components/themes/theme-studio/options/axes/axis/theme-option-axes-category-percentage/theme-option-axes-category-percentage.component';
import { ThemeOptionAxesMinBarLengthComponent } from '@components/themes/theme-studio/options/axes/axis/theme-option-axes-min-bar-length/theme-option-axes-min-bar-length.component';
import { ThemeOptionAxesPositionComponent } from '@components/themes/theme-studio/options/axes/axis/theme-option-axes-position/theme-option-axes-position.component';
import { ThemeOptionAxesGridLinesDisplayComponent } from '@components/themes/theme-studio/options/axes/gridLines/theme-option-axes-grid-lines-display/theme-option-axes-grid-lines-display.component';
import { ThemeOptionAxesGridLinesCircularComponent } from '@components/themes/theme-studio/options/axes/gridLines/theme-option-axes-grid-lines-circular/theme-option-axes-grid-lines-circular.component';
import { ThemeOptionAxesGridLinesColorComponent } from '@components/themes/theme-studio/options/axes/gridLines/theme-option-axes-grid-lines-color/theme-option-axes-grid-lines-color.component';
import { ThemeOptionAxesGridLinesBorderDashComponent } from '@components/themes/theme-studio/options/axes/gridLines/theme-option-axes-grid-lines-border-dash/theme-option-axes-grid-lines-border-dash.component';
import { ThemeOptionAxesGridLinesLineWidthComponent } from '@components/themes/theme-studio/options/axes/gridLines/theme-option-axes-grid-lines-line-width/theme-option-axes-grid-lines-line-width.component';
import { ThemeOptionAxesGridLinesDrawBorderComponent } from '@components/themes/theme-studio/options/axes/gridLines/theme-option-axes-grid-lines-draw-border/theme-option-axes-grid-lines-draw-border.component';
import { ThemeOptionAxesGridLinesDrawTicksComponent } from '@components/themes/theme-studio/options/axes/gridLines/theme-option-axes-grid-lines-draw-ticks/theme-option-axes-grid-lines-draw-ticks.component';
import { ThemeOptionAxesGridLinesTickMarkLengthComponent } from '@components/themes/theme-studio/options/axes/gridLines/theme-option-axes-grid-lines-tick-mark-length/theme-option-axes-grid-lines-tick-mark-length.component';
import { ThemeOptionAxesTicksBeginAtZeroComponent } from '@components/themes/theme-studio/options/axes/ticks/theme-option-axes-ticks-begin-at-zero/theme-option-axes-ticks-begin-at-zero.component';
import { ThemeOptionAxesTicksDisplayComponent } from '@components/themes/theme-studio/options/axes/ticks/theme-option-axes-ticks-display/theme-option-axes-ticks-display.component';
import { ThemeOptionAxesTicksLineHeightComponent } from '@components/themes/theme-studio/options/axes/ticks/theme-option-axes-ticks-line-height/theme-option-axes-ticks-line-height.component';
import { ThemeOptionAxesTicksFontFamilyComponent } from '@components/themes/theme-studio/options/axes/ticks/theme-option-axes-ticks-font-family/theme-option-axes-ticks-font-family.component';
import { ThemeOptionAxesTicksFontColorComponent } from '@components/themes/theme-studio/options/axes/ticks/theme-option-axes-ticks-font-color/theme-option-axes-ticks-font-color.component';
import { ThemeOptionAxesTicksFontSizeComponent } from '@components/themes/theme-studio/options/axes/ticks/theme-option-axes-ticks-font-size/theme-option-axes-ticks-font-size.component';
import { ThemeOptionAxesTicksFontStyleComponent } from '@components/themes/theme-studio/options/axes/ticks/theme-option-axes-ticks-font-style/theme-option-axes-ticks-font-style.component';
import { ThemeOptionAxesTicksPaddingComponent } from '@components/themes/theme-studio/options/axes/ticks/theme-option-axes-ticks-padding/theme-option-axes-ticks-padding.component';
import { ThemeOptionAxesTitleLineHeightComponent } from '@components/themes/theme-studio/options/axes/title/theme-option-axes-title-line-height/theme-option-axes-title-line-height.component';
import { ThemeOptionAxesTitleFontFamilyComponent } from '@components/themes/theme-studio/options/axes/title/theme-option-axes-title-font-family/theme-option-axes-title-font-family.component';
import { ThemeOptionAxesTitleFontColorComponent } from '@components/themes/theme-studio/options/axes/title/theme-option-axes-title-font-color/theme-option-axes-title-font-color.component';
import { ThemeOptionAxesTitleFontSizeComponent } from '@components/themes/theme-studio/options/axes/title/theme-option-axes-title-font-size/theme-option-axes-title-font-size.component';
import { ThemeOptionAxesTitleFontStyleComponent } from '@components/themes/theme-studio/options/axes/title/theme-option-axes-title-font-style/theme-option-axes-title-font-style.component';
import { ThemeOptionAxesTitlePaddingComponent } from '@components/themes/theme-studio/options/axes/title/theme-option-axes-title-padding/theme-option-axes-title-padding.component';
import { ThemeOptionElementsBackgroundMultiGradiantComponent } from '@components/themes/theme-studio/options/elements/serie/theme-option-elements-background-multi-gradiant/theme-option-elements-background-multi-gradiant.component';
import { ThemeOptionElementsBorderMultiGradiantComponent } from '@components/themes/theme-studio/options/elements/serie/theme-option-elements-border-multi-gradiant/theme-option-elements-border-multi-gradiant.component';
import { ThemeOptionElementsChartGaugeColorComponent } from '@components/themes/theme-studio/options/elements/chart/theme-option-elements-chart-gauge-color/theme-option-elements-chart-gauge-color.component';
import { ThemeOptionElementsChartGaugeShadowOpacityComponent } from '@components/themes/theme-studio/options/elements/chart/theme-option-elements-chart-gauge-shadow-opacity/theme-option-elements-chart-gauge-shadow-opacity.component';
import { ThemeOptionElementsChartGaugeShadowSizeComponent } from '@components/themes/theme-studio/options/elements/chart/theme-option-elements-chart-gauge-shadow-size/theme-option-elements-chart-gauge-shadow-size.component';
import { ThemeOptionElementsChartGaugeShadowVerticalOffsetComponent } from '@components/themes/theme-studio/options/elements/chart/theme-option-elements-chart-gauge-shadow-vertical-offset/theme-option-elements-chart-gauge-shadow-vertical-offset.component';
import { ThemeOptionElementsChartGaugeShadowShowInnerComponent } from '@components/themes/theme-studio/options/elements/chart/theme-option-elements-chart-gauge-shadow-show-inner/theme-option-elements-chart-gauge-shadow-show-inner.component';
import { ThemeOptionElementsChartGaugePointerDisplayComponent } from '@components/themes/theme-studio/options/elements/chart/theme-option-elements-chart-gauge-pointer-display/theme-option-elements-chart-gauge-pointer-display.component';
import { ThemeOptionElementsChartGaugePointerTopLengthComponent } from '@components/themes/theme-studio/options/elements/chart/theme-option-elements-chart-gauge-pointer-top-length/theme-option-elements-chart-gauge-pointer-top-length.component';
import { ThemeOptionElementsChartGaugePointerBottomLengthComponent } from '@components/themes/theme-studio/options/elements/chart/theme-option-elements-chart-gauge-pointer-bottom-length/theme-option-elements-chart-gauge-pointer-bottom-length.component';
import { ThemeOptionElementsChartGaugePointerBottomWidthComponent } from '@components/themes/theme-studio/options/elements/chart/theme-option-elements-chart-gauge-pointer-bottom-width/theme-option-elements-chart-gauge-pointer-bottom-width.component';
import { ThemeOptionElementsChartGaugePointerStrokeComponent } from '@components/themes/theme-studio/options/elements/chart/theme-option-elements-chart-gauge-pointer-stroke/theme-option-elements-chart-gauge-pointer-stroke.component';
import { ThemeOptionElementsChartGaugePointerStrokeWidthComponent } from '@components/themes/theme-studio/options/elements/chart/theme-option-elements-chart-gauge-pointer-stroke-width/theme-option-elements-chart-gauge-pointer-stroke-width.component';
import { ThemeOptionElementsChartGaugePointerStrokeLineCapComponent } from '@components/themes/theme-studio/options/elements/chart/theme-option-elements-chart-gauge-pointer-stroke-line-cap/theme-option-elements-chart-gauge-pointer-stroke-line-cap.component';
import { ThemeOptionElementsChartGaugePointerColorComponent } from '@components/themes/theme-studio/options/elements/chart/theme-option-elements-chart-gauge-pointer-color/theme-option-elements-chart-gauge-pointer-color.component';
import { ThemeOptionElementsChartGaugeWidthScaleComponent } from '@components/themes/theme-studio/options/elements/chart/theme-option-elements-chart-gauge-width-scale/theme-option-elements-chart-gauge-width-scale.component';
import { ThemeOptionType } from '@models/themeStudio.model';

export function ThemeOptionsHosts(definedOptions: ThemeOptionType[]): ThemeOptionsHostClass[] {
  let host: ThemeOptionsHostClass[] = [];
  definedOptions.forEach((option: ThemeOptionType) => {
    host.push(new ThemeOptionsHostClass(ThemeOptionsComponent()[option]));
  });
  return host;
}

export function ThemeOptionsHost(definedOptions: ThemeOptionType): ThemeOptionsHostClass {
  return new ThemeOptionsHostClass(ThemeOptionsComponent()[definedOptions]);
}

export function ThemeOptionsComponent(): { [key: string]: Type<any> } {
  return {
    'animation_duration': ThemeOptionAnimationDurationComponent,
    'animation_easing': ThemeOptionAnimationEasingComponent,
    'animation_animateRotate': ThemeOptionAnimationAnimateRotateComponent,
    'animation_animateScale': ThemeOptionAnimationAnimateScaleComponent,
    'layout_padding': ThemeOptionLayoutPaddingComponent,
    'layout_backgroundColor': ThemeOptionLayoutBackgroundColorComponent,
    'legend_display': ThemeOptionLegendDisplayComponent,
    'legend_position': ThemeOptionLegendPositionComponent,
    'legend_fullWidth': ThemeOptionLegendFullWidthComponent,
    'legend_reverse': ThemeOptionLegendReverseComponent,
    'legend_boxWidth': ThemeOptionLegendBoxWidthComponent,
    'legend_fontSize': ThemeOptionLegendFontSizeComponent,
    'legend_fontStyle': ThemeOptionLegendFontStyleComponent,
    'legend_fontColor': ThemeOptionLegendFontColorComponent,
    'legend_fontFamily': ThemeOptionLegendFontFamilyComponent,
    'legend_padding': ThemeOptionLegendPaddingComponent,
    'legend_usePointStyle': ThemeOptionLegendUsePointStyleComponent,
    'title_display': ThemeOptionTitleDisplayComponent,
    'title_position': ThemeOptionTitlePositionComponent,
    'title_fontSize': ThemeOptionTitleFontSizeComponent,
    'title_fontStyle': ThemeOptionTitleFontStyleComponent,
    'title_fontColor': ThemeOptionTitleFontColorComponent,
    'title_fontFamily': ThemeOptionTitleFontFamilyComponent,
    'title_padding': ThemeOptionTitlePaddingComponent,
    'title_lineHeight': ThemeOptionTitleLineHeightComponent,
    'tooltips_enabled': ThemeOptionTooltipsDisplayComponent,
    'tooltips_mode': ThemeOptionTooltipsModeComponent,
    'tooltips_intersect': ThemeOptionTooltipsIntersectComponent,
    'tooltips_position': ThemeOptionTooltipsPositionComponent,
    'tooltips_backgroundColor': ThemeOptionTooltipsBackgroundColorComponent,
    'tooltips_titleFontFamily': ThemeOptionTooltipsTitleFontFamilyComponent,
    'tooltips_titleFontSize': ThemeOptionTooltipsTitleFontSizeComponent,
    'tooltips_titleFontStyle': ThemeOptionTooltipsTitleFontStyleComponent,
    'tooltips_titleFontColor': ThemeOptionTooltipsTitleFontColorComponent,
    'tooltips_titleAlign': ThemeOptionTooltipsTitleAlignComponent,
    'tooltips_titleSpacing': ThemeOptionTooltipsTitleSpacingComponent,
    'tooltips_titleMarginBottom': ThemeOptionTooltipsTitleMarginBottomComponent,
    'tooltips_bodyFontFamily': ThemeOptionTooltipsBodyFontFamilyComponent,
    'tooltips_bodyFontSize': ThemeOptionTooltipsBodyFontSizeComponent,
    'tooltips_bodyFontStyle': ThemeOptionTooltipsBodyFontStyleComponent,
    'tooltips_bodyFontColor': ThemeOptionTooltipsBodyFontColorComponent,
    'tooltips_bodyAlign': ThemeOptionTooltipsBodyAlignComponent,
    'tooltips_bodySpacing': ThemeOptionTooltipsBodySpacingComponent,
    'tooltips_xPadding': ThemeOptionTooltipsXPaddingComponent,
    'tooltips_yPadding': ThemeOptionTooltipsXPaddingComponent,
    'tooltips_caretPadding': ThemeOptionTooltipsCaretPaddingComponent,
    'tooltips_caretSize': ThemeOptionTooltipsCaretSizeComponent,
    'tooltips_cornerRadius': ThemeOptionTooltipsCornerRadiusComponent,
    'tooltips_multiKeyBackground': ThemeOptionTooltipsMultiKeyBackgroundComponent,
    'tooltips_displayColors': ThemeOptionTooltipsDisplayColorsComponent,
    'tooltips_borderColor': ThemeOptionTooltipsBorderColorComponent,
    'tooltips_borderWidth': ThemeOptionTooltipsBorderWidthComponent,
    'elements_point_style': ThemeOptionElementsPointStyleComponent,
    'elements_point_radius': ThemeOptionElementsPointRadiusComponent,
    'elements_point_rotation': ThemeOptionElementsPointRotationComponent,
    'elements_point_backgroundColor': ThemeOptionElementsPointBackgroundColorComponent,
    'elements_point_borderColor': ThemeOptionElementsPointBorderColorComponent,
    'elements_point_borderWidth': ThemeOptionElementsPointBorderWidthComponent,
    'elements_point_hitRadius': ThemeOptionElementsPointHitRadiusComponent,
    'elements_point_hoverRadius': ThemeOptionElementsPointHoverRadiusComponent,
    'elements_point_hoverBackgroundColor': ThemeOptionElementsPointHoverBackgroundColorComponent,
    'elements_point_hoverBorderColor': ThemeOptionElementsPointHoverBorderColorComponent,
    'elements_point_hoverBorderWidth': ThemeOptionElementsPointHoverBorderWidthComponent,
    'elements_backgroundColor': ThemeOptionElementsBackgroundColorComponent,
    'elements_backgroundMultiGradiant': ThemeOptionElementsBackgroundMultiGradiantComponent,
    'elements_borderColor': ThemeOptionElementsBorderColorComponent,
    'elements_borderMultiGradiant': ThemeOptionElementsBorderMultiGradiantComponent,
    'elements_borderWidth': ThemeOptionElementsBorderWidthComponent,
    'elements_fill': ThemeOptionElementsFillComponent,
    'elements_lineTension': ThemeOptionElementsLineTensionComponent,
    'elements_hideInLegendAndTooltip': ThemeOptionElementsHideInLegendAndTooltipComponent,
    'elements_hidden': ThemeOptionElementsHiddenComponent,
    'elements_spanGaps': ThemeOptionElementsSpanGapsComponent,
    'elements_steppedLine': ThemeOptionElementsSteppedLineComponent,
    'elements_borderDash': ThemeOptionElementsBorderDashComponent,
    'elements_borderCapStyle': ThemeOptionElementsBorderCapStyleComponent,
    'elements_borderJoinStyle': ThemeOptionElementsBorderJoinStyleComponent,
    'elements_hoverBackgroundColor': ThemeOptionElementsHoverBackgroundColorComponent,
    'elements_hoverBorderColor': ThemeOptionElementsHoverBorderColorComponent,
    'elements_hoverBorderWidth': ThemeOptionElementsHoverBorderWidthComponent,
    'elements_hoverBorderDash': ThemeOptionElementsHoverBorderDashComponent,
    'elements_chart_cutoutPercentage': ThemeOptionElementsChartCutoutPercentageComponent,
    'elements_chart_circumference': ThemeOptionElementsChartCircumferenceComponent,
    'elements_chart_rotation': ThemeOptionElementsChartRotationComponent,
    'elements_chart_transitionAnimation': ThemeOptionElementsChartTransitionAnimationComponent,
    'elements_iconFrame': ThemeOptionElementsIconFrameComponent,
    'elements_borderAlign': ThemeOptionElementsBorderAlignComponent,
    'elements_weight': ThemeOptionElementsWeightComponent,
    'elements_datalabelsID': ThemeOptionElementsDatalabelsIdComponent,
    'elements_xAxisID': ThemeOptionElementsXAxisIDComponent,
    'elements_yAxisID': ThemeOptionElementsYAxisIDComponent,
    'datalabels_anchor': ThemeOptionDatalabelsAnchorComponent,
    'datalabels_align': ThemeOptionDatalabelsAlignComponent,
    'datalabels_clamp': ThemeOptionDatalabelsClampComponent,
    'datalabels_display': ThemeOptionDatalabelsDisplayComponent,
    'datalabels_offset': ThemeOptionDatalabelsOffsetComponent,
    'datalabels_rotation': ThemeOptionDatalabelsRotationComponent,
    'datalabels_clip': ThemeOptionDatalabelsClipComponent,
    'datalabels_borderWidth': ThemeOptionDatalabelsBorderWidthComponent,
    'datalabels_borderColor': ThemeOptionDatalabelsBorderColorComponent,
    'datalabels_borderRadius': ThemeOptionDatalabelsBorderRadiusComponent,
    'datalabels_padding': ThemeOptionDatalabelsPaddingComponent,
    'datalabels_backgroundColor': ThemeOptionDatalabelsBackgroundColorComponent,
    'datalabels_color': ThemeOptionDatalabelsColorComponent,
    'datalabels_fontFamily': ThemeOptionDatalabelsFontFamilyComponent,
    'datalabels_fontSize': ThemeOptionDatalabelsFontSizeComponent,
    'datalabels_fontStyle': ThemeOptionDatalabelsFontSizeComponent,
    'datalabels_fontLineHeight': ThemeOptionDatalabelsFontLineHeightComponent,
    'datalabels_opacity': ThemeOptionDatalabelsOpacityComponent,
    'datalabels_textAlign': ThemeOptionDatalabelsTextAlignComponent,
    'datalabels_textStrokeColor': ThemeOptionDatalabelsTextStrokeColorComponent,
    'datalabels_textStrokeWidth': ThemeOptionDatalabelsTextStrokeWidthComponent,
    'datalabels_textShadowBlur': ThemeOptionDatalabelsTextShadowBlurComponent,
    'datalabels_textShadowColor': ThemeOptionDatalabelsTextShadowColorComponent,
    'axes_display': ThemeOptionAxesDisplayComponent,
    'axes_barPercentage': ThemeOptionAxesBarPercentageComponent,
    'axes_categoryPercentage': ThemeOptionAxesCategoryPercentageComponent,
    'axes_minBarLength': ThemeOptionAxesMinBarLengthComponent,
    'axes_position': ThemeOptionAxesPositionComponent,
    'axes_gridLines_display': ThemeOptionAxesGridLinesDisplayComponent,
    'axes_gridLines_circular': ThemeOptionAxesGridLinesCircularComponent,
    'axes_gridLines_color': ThemeOptionAxesGridLinesColorComponent,
    'axes_gridLines_borderDash': ThemeOptionAxesGridLinesBorderDashComponent,
    'axes_gridLines_lineWidth': ThemeOptionAxesGridLinesLineWidthComponent,
    'axes_gridLines_drawBorder': ThemeOptionAxesGridLinesDrawBorderComponent,
    'axes_gridLines_drawTicks': ThemeOptionAxesGridLinesDrawTicksComponent,
    'axes_gridLines_tickMarkLength': ThemeOptionAxesGridLinesTickMarkLengthComponent,
    'axes_ticks_beginAtZero': ThemeOptionAxesTicksBeginAtZeroComponent,
    'axes_ticks_display': ThemeOptionAxesTicksDisplayComponent,
    'axes_ticks_lineHeight': ThemeOptionAxesTicksLineHeightComponent,
    'axes_ticks_fontFamily': ThemeOptionAxesTicksFontFamilyComponent,
    'axes_ticks_fontColor': ThemeOptionAxesTicksFontColorComponent,
    'axes_ticks_fontSize': ThemeOptionAxesTicksFontSizeComponent,
    'axes_ticks_fontStyle': ThemeOptionAxesTicksFontStyleComponent,
    'axes_ticks_padding': ThemeOptionAxesTicksPaddingComponent,
    'axes_title_display': ThemeOptionTitleDisplayComponent,
    'axes_title_lineHeight': ThemeOptionAxesTitleLineHeightComponent,
    'axes_title_fontFamily': ThemeOptionAxesTitleFontFamilyComponent,
    'axes_title_fontColor': ThemeOptionAxesTitleFontColorComponent,
    'axes_title_fontSize': ThemeOptionAxesTitleFontSizeComponent,
    'axes_title_fontStyle': ThemeOptionAxesTitleFontStyleComponent,
    'axes_title_padding': ThemeOptionAxesTitlePaddingComponent,
    'elements_chart_gauge_color': ThemeOptionElementsChartGaugeColorComponent,
    'elements_chart_gauge_shadow_opacity': ThemeOptionElementsChartGaugeShadowOpacityComponent,
    'elements_chart_gauge_shadow_size': ThemeOptionElementsChartGaugeShadowSizeComponent,
    'elements_chart_gauge_shadow_vertical_offset': ThemeOptionElementsChartGaugeShadowVerticalOffsetComponent,
    'elements_chart_gauge_shadow_show_inner': ThemeOptionElementsChartGaugeShadowShowInnerComponent,
    'elements_chart_gauge_pointer_display': ThemeOptionElementsChartGaugePointerDisplayComponent,
    'elements_chart_gauge_pointer_top_length': ThemeOptionElementsChartGaugePointerTopLengthComponent,
    'elements_chart_gauge_pointer_bottom_length': ThemeOptionElementsChartGaugePointerBottomLengthComponent,
    'elements_chart_gauge_pointer_bottom_width': ThemeOptionElementsChartGaugePointerBottomWidthComponent,
    'elements_chart_gauge_pointer_stroke': ThemeOptionElementsChartGaugePointerStrokeComponent,
    'elements_chart_gauge_pointer_stroke_width': ThemeOptionElementsChartGaugePointerStrokeWidthComponent,
    'elements_chart_gauge_pointer_stroke_line_cap': ThemeOptionElementsChartGaugePointerStrokeLineCapComponent,
    'elements_chart_gauge_pointer_color': ThemeOptionElementsChartGaugePointerColorComponent,
    'elements_chart_gauge_width_scale': ThemeOptionElementsChartGaugeWidthScaleComponent
  };
}