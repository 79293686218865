/**
 * @module Root
 */

import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseChartDirective } from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { LanguagesTypes, LanguageDefault } from '@functions/language.functions';
import html2canvas from 'html2canvas';

declare var cordova;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'rhino';
  constructor(
    public $translate: TranslateService,
  ) {

    //déclaration de html2canvas pour jsPDF
    window['html2canvas'] = html2canvas;
    
    //enregistrement du plugin datalabels pour ChartJS
    BaseChartDirective.registerPlugin(pluginDataLabels as unknown); //unknown ajouté suite migration du plugin datalabels en 0.7.0

    //initialisation de la gestion des langues
    $translate.addLangs(LanguagesTypes() as string[]);
    $translate.setDefaultLang(LanguageDefault());
    $translate.use(LanguageDefault());

  }
}
