/**
 * @module User
 */

import { Component, Input } from '@angular/core';
import { GroupsClass, RightClass } from '@class/right.class';
import { ItemColor, ItemIcon } from '@functions/item.functions';
import { Subscription } from 'rxjs';
import { RightService } from '@services/right/right.service';
import { UID } from '@models/uid.model';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.css']
})
export class GroupsComponent {

  @Input() groups: GroupsClass;

  color: string = ItemColor('rights');
  icon: string = ItemIcon('rights');

  rights$sub: Subscription;
  rights: RightClass[];

  constructor(
    private $rights: RightService
  ) {
    this.getList(false);
  }

  ngOnDestroy() {
    this.rights$sub.unsubscribe();
  }

  getList(forceFullCollection: boolean) {
    if(this.rights$sub) this.rights$sub.unsubscribe();
    this.rights$sub = this.$rights.listPartials$(forceFullCollection).subscribe({
      next: (rights: RightClass[]) => {
        this.rights = rights;
      }
    });
  }

  receiveFull(full: boolean) {
    this.getList(full);
  }

  isSelected(uid: UID): boolean {
    return this.groups.has(uid);
  }

  receiveSelected(item: { uid: UID, selected: boolean }) {
    if(this.groups.has(item.uid)) this.groups.remove(item.uid);
    else this.groups.add(item.uid);
  }

}
