<app-links-item *ngIf="pivot" [color]="colors['pivots']" [icon]="icon" [labels]="pivot.labels" [uid]="pivot.uid">
  <div app-links-item-content-description>
  </div>
  <div app-links-item-content-option class="d-flex align-items-center">
    <mdb-badge *ngIf="filters.values.length == 0 ; else has_filters" color="grey" class="z-depth-0">0<i class="fas fa-circle ml-1 app-opacity-50"></i></mdb-badge>
    <button (click)="onSelect()" type="button" mdbBtn color="blue-grey" size="sm" class="z-depth-0 d-flex px-2 align-items-center my-0 mr-0 ml-2" style="width: 30px; height: 31px;" mdbWavesEffect>
      <i class="fas fa-angle-{{ current ? 'down' : 'left' }} fa-fw"></i>
    </button>
  </div>
  <div app-links-item-content-sub class="mt-3" *ngIf="current">

    <div class="p-2 rounded white mt-1 d-flex w-100 align-items-center app-cursor-pointer">
      <div class="font-weight-bold mr-auto font-small">{{ (filters.include ? 'INCLUSION_MODE' : 'EXCLUSION_MODE') | translate }}</div>

      <div class="d-flex align-items-center">
        <app-links-item-option class="d-block mx-1" [color]="'red'" [symbol]="'times'" [selected]="!filters.include" (sendSelected)="receiveInclude(false)"></app-links-item-option>
        <app-links-item-option class="d-block ml-1" [symbol]="'check'" [selected]="filters.include" (sendSelected)="receiveInclude(true)"></app-links-item-option>
      </div>
    </div>

    <div class="p-2 rounded white mt-1 d-flex w-100 align-items-center app-cursor-pointer">
      <div class="font-weight-bold mr-auto font-small">{{ 'CLEAR_SELECTION' | translate }}</div>

      <button (click)="filters.flush()" type="button" mdbBtn color="warning" size="sm" class="z-depth-0 d-flex px-2 align-items-center my-0 mr-0 ml-2" style="width: 30px; height: 31px;" mdbWavesEffect>
        <i class="fas fa-eraser fa-fw"></i>
      </button>
    </div>

    <app-input [value]="searchText" [label]="'SEARCH'" [color]="color" (sendValue)="receiveSearchText($event)"></app-input>

    <hr>

    <div *ngIf="values?.length > 0 ; else app_loading">
      <app-widget-indicators-filter *ngFor="let value of values | searchText:searchText | paginate: { id: 'valuesId', itemsPerPage: limit, currentPage: page }" [filters]="filters" [value]="value" [include]="filters.include"></app-widget-indicators-filter>

      <div class="d-flex justify-content-center mt-3">
        <pagination-controls class="font-small font-weight-bold"
          id="valuesId"
          (pageChange)="onPageChanged($event)"
          [maxSize]="limit"
          directionLinks="true"
          autoHide="true"
          responsive="true"
          previousLabel=""
          nextLabel="">
        </pagination-controls>
      </div>
    </div>
  </div>
</app-links-item>

<ng-template #app_loading>
  <app-loading></app-loading>
</ng-template>

<ng-template #has_filters>
  <mdb-badge (click)="filters.flush()" *ngIf="filters.include ; else has_filters_exclusions" color="green" class="z-depth-0 app-cursor-pointer">{{ filters.values.length }}<i class="fad fa-check-circle ml-1"></i></mdb-badge>
</ng-template>

<ng-template #has_filters_exclusions>
  <mdb-badge (click)="filters.flush()" color="red" class="z-depth-0 app-cursor-pointer">{{ filters.values.length }}<i class="fad fa-times-circle ml-1"></i></mdb-badge>
</ng-template>