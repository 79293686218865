<div class="p-5 text-center rounded animated fadeInUp blue-grey lighten-5 mx-auto">
  <i class="{{ color }}-text fa-4x d-none d-sm-inline fad fa-cogs app-import-progress-icon"></i>
  <i class="{{ color }}-text fa-2x d-sm-none d-inline fad fa-cogs app-import-progress-icon"></i>

  <div class="app-box-shadow-depth-1 app-depth-2 p-sm-5 p-3 text-center rounded animated fadeInUp blue-grey lighten-4 my-3 mx-auto blue-grey-text">
    <i class="fas fa-circle-notch fa-spin {{ color }}-text mr-1" *ngIf="worker.percent == undefined"></i>{{ titles[worker.step] | translate }}...<strong *ngIf="worker.percent != undefined" class="{{ color }}-text">{{ worker.percent }}%</strong>
  </div>
  <div class="d-flex mt-3">
    <mdb-progress [ngClass]="{ 'mr-1': worker.percent > 0 && worker.percent != 100 }" [ngStyle]="{ 'width': worker.percent + '%' }" value="100" min="0" max="100" type="info" class="app-progress-8"></mdb-progress>
    <mdb-progress [ngStyle]="{ 'width': (100 - worker.percent) + '%' }" value="0" min="0" max="100" type="info" class="app-progress-8"></mdb-progress>
  </div>
</div>