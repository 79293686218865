/**
 * @module Shared
 */

/** 
 * @description class CSS de mise en forme
 * .draggable-container--is-dragging {} //pour le container lors d'un déplacement d'un élément
 * .draggable-source--is-dragging {} //pour la cible de larguage pendant le déplacement
 * .draggable-source--placed {} //pour l'élément une fois largué
 * .draggable-mirror {} //pour l'image de la cible de l'élément déplacé
 * .draggable--original {} //pour l'image de la source de l'élément déplacé
 * 
 * appliquer la classe css "app-sortable-container" au container pour le fond à rayure par défaut
 * 
 * Les données "element" à récupérer sont à placer dans des attribut dataset: exemple : [attr.data-uid]="uid" pour récupérer { uid: any }
 * En cas de multiples containers, ils doivent avoir un attribut "id" d'initialisé
 */

import { Directive, AfterViewInit, Input, Output, ElementRef, EventEmitter } from '@angular/core';
import { Sortable, SortableStopEvent, SortableSortEvent, SortableStartEvent } from '@shopify/draggable';

export interface SortableStopModel {
  sourceIndex: number;
  targetIndex: number;
  element: any;
  sourceContainer?: any;
  targetContainer?: any;
}

export interface SortableSortModel {
  currentIndex: number;
}

@Directive({
  selector: '[app-sortable]'
})
export class SortableDirective implements AfterViewInit {

  @Input() forceSortable: boolean = false;
  @Input() sortableDisabled: boolean = false;
  @Input() containersDisabled: string[] = [];
  @Output() sendSortableStop = new EventEmitter<SortableStopModel>();
  @Output() sendSortableSort = new EventEmitter<SortableSortModel>();

  sortable: Sortable;
  containers: any[];
  disabled: any[];

  constructor(
    private element: ElementRef
  ) {}

  ngAfterViewInit() {
    this.containers = this.element.nativeElement.querySelectorAll('.isSortableContainer');
    this.sortable = new Sortable(this.containers.length > 0 ? this.containers : this.element.nativeElement, {
      draggable: '.isSortableElement',
      mirror: {
        constrainDimensions: true
      }
    });
    this.sortable.on('sortable:stop', (event: SortableStopEvent) => this.handleStop(event));
    this.sortable.on('sortable:sort', (event: SortableStopEvent) => this.handleSort(event));
    this.sortable.on('sortable:start', (event: SortableStartEvent) => this.handleStart(event));
  }

  handleStart(event: SortableSortEvent) {
    const { source } = event.dragEvent;
    this.disabled = [...this.element.nativeElement.querySelectorAll('.isSortableDisabled')].map(e => e.id);
    if(!this.forceSortable && (this.sortableDisabled || this.disabled.indexOf(source.id) !== -1)) {
      event.cancel();
      return;
    }
  }

  handleSort(event: SortableSortEvent) {
    const { overContainer } = event.dragEvent;
    if(this.containersDisabled.includes(overContainer.id)) {
      event.cancel();
      return;
    }
    else {
      this.sendSortableSort.emit({ currentIndex: event.currentIndex })
    }
  }

  handleStop(event: SortableStopEvent) {
    const { newIndex, oldContainer, newContainer } = event;
    const { dataset } = event.data.dragEvent.source;

    const oldIndex = event.data.dragEvent.source.id.length > 0 && !isNaN(event.data.dragEvent.source.id) ? parseInt(event.data.dragEvent.source.id) : event.oldIndex;
    
    if(!this.sortableDisabled) {
      if(this.containers.length === 0) {
        this.sendSortableStop.emit({ 
          sourceIndex: oldIndex, 
          targetIndex: newIndex, 
          element: dataset
        });
      }
      else {
        this.sendSortableStop.emit({ 
          sourceIndex: oldIndex, 
          targetIndex: newIndex, 
          element: dataset,
          sourceContainer: oldContainer.id,
          targetContainer: newContainer.id
        });
      }
    }
  }

}
