/** 
 * @module Theme 
 */

import { Component, ElementRef, Input, OnDestroy } from '@angular/core';
import { ThemeOptionsModel } from '@models/themeOptions.model';
import { ThemeOptionsClass } from '@class/themeOptions.class';
import { MessagesService } from '@services/messages/messages.service';
import { LanguageType } from '@models/language.model';
import { Subscription } from 'rxjs';
import { AuthService } from '@services/auth/auth.service';
import { ThemeClass } from '@class/theme.class';
import { ItemColor } from '@functions/item.functions';

@Component({
  selector: 'app-theme-studio-export',
  templateUrl: './theme-studio-export.component.html',
  styleUrls: ['./theme-studio-export.component.css']
})
export class ThemeStudioExportComponent implements OnDestroy {

  @Input() theme: ThemeClass;
  @Input() themeOptions: ThemeOptionsClass;

  language: LanguageType;
  language$sub: Subscription;

  color = ItemColor('themes');

  constructor(
    private $auth: AuthService,
    private $messages: MessagesService,
    private element: ElementRef
  ) {
    this.language$sub = this.$auth.language$.subscribe({
      next: (language: LanguageType) => {
        this.language = language;
      }
    });
  }

  ngOnDestroy() {
    this.language$sub.unsubscribe();
  }

  onImport() {
    this.element.nativeElement.querySelector('#file').click();
  }

  onFile(file: File) {
    const reader: FileReader = new FileReader();
    reader.onload = (event: any) => {

      this.themeOptions.model = JSON.parse(event.target.result) as ThemeOptionsModel;
      delete this.themeOptions.model['uidLog'];
      
      this.$messages.success({ 
        title: { plain: this.theme.labels.value(this.language) }, 
        text: { code: 'SUCCESSFULLY_IMPORTED/TEXT' } 
      });
      
    };
    reader.onerror = () => {
      this.$messages.error({ 
        title: { plain: this.theme.labels.value(this.language) }, 
        text: { 
          code: 'IMPORT_FAILED/TEXT'
        } 
      });
    }
    reader.readAsBinaryString(file);
  }

  onExport() {
    this.themeOptions.download(this.theme.labels.value(this.language));
  }

}
