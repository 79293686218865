/**
 * @module Theme
 */

import { Component, ViewChild, OnDestroy } from '@angular/core';
import { ItemColor, ItemIcon, ItemLabel } from '@functions/item.functions';
import { ThemeClass } from '@class/theme.class';
import { LanguageType } from '@models/language.model';
import { Subscription } from 'rxjs';
import { ThemeService } from '@services/theme/theme.service';
import { AuthService } from '@services/auth/auth.service';
import { UID } from '@models/uid.model';
import { BrowserFrameComponent } from '@components/browser/browser-frame/browser-frame.component';
import { Router } from '@angular/router';
import { ThemeEditorComponent } from '../theme-editor/theme-editor.component';

@Component({
  selector: 'app-themes',
  templateUrl: './themes.component.html',
  styleUrls: ['./themes.component.css']
})
export class ThemesComponent implements OnDestroy {

  @ViewChild('editor', { static: true }) editor: ThemeEditorComponent;
  @ViewChild('frameModal', { static: true }) frameModal: BrowserFrameComponent;

  label = ItemLabel('themes');
  color = ItemColor('themes');
  icon = ItemIcon('themes');
  current: ThemeClass;
  language: LanguageType;
  language$sub: Subscription;
  themes: ThemeClass[];
  themes$sub: Subscription;
  searchLabel: string;

  constructor(
    private $themes: ThemeService,
    private $auth: AuthService,
    private $router: Router
  ) {

    this.language = this.$auth.language$.value;
    this.language$sub = this.$auth.language$.subscribe({
      next: (language: LanguageType) => {
        this.language = language;
      }
    });
    
    this.themes$sub = this.$themes.list$().subscribe({
      next: (themes: ThemeClass[]) => {
        this.themes = themes.sort((a : ThemeClass, b: ThemeClass) => { return a.labels.value(this.language).localeCompare(b.labels.value(this.language)) });
      }
    });

    this.current = this.$themes.create();

  }

  ngOnDestroy() {
    this.language$sub.unsubscribe();
    this.themes$sub.unsubscribe();
  }

  public show() {
    this.frameModal.show();
  }

  trackByUid(index: number, theme: ThemeClass) {
    return theme.uid.value;
  }

  receiveCreate() {
    if(this.current.uid.isRegistered) this.current = this.$themes.create();
    this.editor.show();
  }

  receiveEdit(uid: UID) {
    this.current = this.themes.filter((theme: ThemeClass) => {
      return theme.uid.value === uid;
    })[0];
    this.editor.show();
  }

  receiveStudio(uid: UID) {
    const panel = this.$router.parseUrl(this.$router.url).queryParams.panel;
    this.$router.navigate([`/theme-studio`], { queryParams: { from: 'browser', panel: panel, uid: uid } });
  }

  receiveSearch(searchLabel: string) {
    this.searchLabel = searchLabel;
  }

}
