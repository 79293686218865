<app-form-strip [center]="true">

  <div app-form-strip-content-middle>
    <div class="switch round blue-grey-switch-both">
      <label class="mb-0">
        <span class="font-weight-bolder font-small blue-grey-text">{{ 'YOUR_RIGHTS' | translate }}</span>
        <input type="checkbox" [checked]="full" (change)="onFull($event.target.checked)">
        <span class="lever"></span>
        <span class="font-weight-bolder font-small blue-grey-text">{{ 'ALL_RIGHTS' | translate }}</span>
      </label>
    </div>
  </div>

</app-form-strip>