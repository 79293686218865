/**
 * @module User
 */

import { Component, OnInit, Input, ElementRef, OnDestroy } from '@angular/core';
import { CompressImage } from '@functions/image.functions';
import { UserClass } from '@class/user.class';
import { MessagesService } from '@services/messages/messages.service';
import { COMPANIES } from '@consts/companies.const';
import { CompanyService } from '@services/company/company.service';
import { Subscription } from 'rxjs';
import { ModificationStateClass } from '@class/modification.class';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.css']
})
export class AvatarComponent implements OnInit, OnDestroy {

  @Input() user: UserClass;

  url: string | ArrayBuffer;
  modifications$sub: Subscription;

  constructor(
    private element: ElementRef,
    private $messages: MessagesService,
    private $company: CompanyService
  ) {}

  ngOnInit() {
    this.url = this.user.avatar.url;

    this.modifications$sub = this.user.modifications$.subscribe({
      next: (state: ModificationStateClass) => {
        if(!state.isModified) {
          this.url = this.user.avatar.url;
        }
      }
    });
  }

  ngOnDestroy() {
    this.modifications$sub.unsubscribe();
  }

  onChange() {
    this.element.nativeElement.querySelector('#file').click();
  }

  onReset() {
    this.user.change({ avatar: {
      file: null
    } });
    this.url = COMPANIES[this.$company.code].avatar.url;
  }

  onFile(file: File) {
    if(file !== undefined) {
      CompressImage(file).subscribe({
        next: (compress: { file: File, preview: string | ArrayBuffer }) => {
          this.url = compress.preview;
          this.user.change({ avatar: {
            file: compress.file
          } });
        },
        error: error => {
          this.$messages.warning({ text: { code: error } })
        }
      })
    }
  }

}
