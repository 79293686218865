<div *ngIf="!isEdit" (click)="onEdit()" class="d-flex align-items-center m-1 p-2 rounded lighten-5 font-small app-cursor-pointer" [ngClass]="{ 'blue-grey-text blue-grey': !row.$$errors[key], 'white-text danger-color': row.$$errors[key] }">
  <div class="app-import-cell mr-1">
    {{row[key]}}
    <div *ngIf="format != undefined" class="font-extra-small app-opacity-50 app-import-cell-date">{{ parsedDate.parsed }}</div>
  </div>
  <div class="rounded-circle ml-auto {{ row.$$errors[key] ? 'danger-color-dark' : colors[type] }} d-flex justify-content-center align-items-center app-import-icon">
    <i class="fa-fw {{ icons[type] }} white-text"></i>
  </div>
</div>
<div *ngIf="isEdit" class="d-flex align-items-center m-1 p-0 app-import-cell-input-container">
  <div class="w-100 app-import-cell-input-frame md-form md-outline m-0">
    <textarea [ngModel]="row[key]" (ngModelChange)="onValue($event)" id="form-value" class="app-import-cell-input form-control textarea form-{{color}} mb-0" size="sm" rows="1" type="text" mdbInput></textarea>
    <label for="form-value">{{ key }}</label>
  </div>
  <i (click)="onRestore()" class="fad fa-lg py-1 pl-2 pr-1 fa-times-square animated fadeInRight text-secondary app-cursor-pointer"></i>
  <i (click)="onValid()" class="fad fa-lg p-1 fa-check-square animated fadeInRight text-default app-cursor-pointer"></i>
</div>