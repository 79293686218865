/**
 * @module Pivot
 */

import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { UID } from '@models/uid.model';
import { LinksClass } from '@class/links.class';
import { PivotClass } from '@class/pivot.class';
import { Subscription } from 'rxjs';
import { ItemColor } from '@functions/item.functions';

@Component({
  selector: 'app-pivots-links-list',
  templateUrl: './pivots-links-list.component.html',
  styleUrls: ['./pivots-links-list.component.css']
})
export class PivotsLinksListComponent implements OnInit, OnDestroy {

  @Input() links: LinksClass;

  uids: UID[];
  lastModification$sub: Subscription;
  searchLabel: string;
  color = ItemColor('rights');

  constructor() {}

  ngOnInit() {
    this.uids = this.links.list('pivots').slice();

    this.lastModification$sub = this.links.lastModification$.subscribe((attribute: string) => {
      if(attribute === 'links') this.uids = this.links.list('pivots').slice();
    });
  }

  ngOnDestroy() {
    this.lastModification$sub.unsubscribe();
  }

  receiveTextValue(searchLabel: string) {
    this.searchLabel = searchLabel;
  }

  receiveSelected(uid: UID) {
    if(this.links.has('pivots', uid)) this.links.remove('pivots', uid);
    else this.links.add('pivots', uid);
  }

  receiveLinkChange(replace: { from: PivotClass, to: PivotClass }) {
    this.links.replace('pivots', replace.from.uid.value, replace.to.uid.value, replace.to);
    this.uids = this.links.list('pivots').slice();
  }

}
