<app-item-strip
  [labels]="importFrame.indicator.labels"
  [tags]="importFrame.indicator.tags"
  [type]="'indicators'"
  [uid]="importFrame.indicator.uid"
  [icon]="icons['indicators']"
  [color]="colors['indicators']"
  [isModified]="importFrame.indicator.modifications$.value.isModified"
  (sendEdit)="receiveIndicator($event, importFrame.indicator)"
  [isDisplayable]="true"
  (sendDisplay)="receiveDisplay($event, importFrame.dataFrame.keyNumber)"
>
  <div app-item-strip-content-description>
    <app-import-tags [importFrame]="importFrame" [key]="importFrame.dataFrame.keyNumber"></app-import-tags>
  </div>
</app-item-strip>

<hr>

<app-download-file-type (sendFileType)="receiveFileType($event)"></app-download-file-type>

<hr *ngIf="importFrame.dates.length > 0">

<app-item-strip *ngFor="let date of importFrame.dates"
  [labels]="date.labels"
  [tags]="date.tags"
  [type]="'dates'"
  [uid]="date.uid"
  [icon]="icons['dates']"
  [color]="colors['dates']"
  [isModified]="date.modifications$.value.isModified"
  (sendEdit)="receiveDate($event, date)"
  [isDisplayable]="true"
  (sendDisplay)="receiveDisplay($event, importFrame.dataFrame.keyForUid(date.uid.value))"
>
  <div app-item-strip-content-description>
    <app-import-tags [importFrame]="importFrame" [key]="importFrame.dataFrame.keyForUid(date.uid.value)"></app-import-tags>
  </div>
</app-item-strip>

<hr *ngIf="importFrame.pivots.length > 0">

<app-item-strip *ngFor="let pivot of importFrame.pivots"
  [labels]="pivot.labels"
  [tags]="pivot.tags"
  [type]="'pivots'"
  [uid]="pivot.uid"
  [icon]="icons['pivots']"
  [color]="colors['pivots']"
  [isModified]="pivot.modifications$.value.isModified"
  (sendEdit)="receivePivot($event, pivot)"
  [isDisplayable]="true"
  (sendDisplay)="receiveDisplay($event, importFrame.dataFrame.keyForUid(pivot.uid.value))"
>
  <div app-item-strip-content-description>
    <mdb-badge color="green" class="z-depth-0" *ngIf="hasFilters(pivot.uid.value)">
      <i class="fa-fw fa-filter fas white-text animated rotateIn mr-1"></i>{{ 'ACTIVE_FILTERS' | translate }}
    </mdb-badge>
    <app-import-tags [importFrame]="importFrame" [key]="importFrame.dataFrame.keyForUid(pivot.uid.value)"></app-import-tags>
  </div>
</app-item-strip>

<app-indicator-editor #indicatorEditor *ngIf="indicator" [current]="indicator" (sendDelete)="receiveDelete()"></app-indicator-editor>
<app-date-editor #dateEditor *ngIf="date" [current]="date" (sendDelete)="receiveDelete()"></app-date-editor>
<app-pivot-editor #pivotEditor *ngIf="pivot" [current]="pivot" (sendDelete)="receiveDelete()"></app-pivot-editor>