/**
 * @module Widget
 */

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { WidgetLayersClass } from '@class/widgetLayers.class';
import { SortableStopModel } from '@directives/sortable/sortable.directive';

@Component({
  selector: 'app-widget-indicators-list',
  templateUrl: './widget-indicators-list.component.html',
  styleUrls: ['./widget-indicators-list.component.css']
})
export class WidgetIndicatorsListComponent {

  @Input() widgetLayers: WidgetLayersClass;
  @Input() current: number;
  @Input() selected: number;
  @Output() sendCurrent: EventEmitter<number>;
  
  constructor() {
    this.sendCurrent = new EventEmitter<number>();
  }

  receiveEdited(index: number) {
    this.sendCurrent.emit(index);
  }

  receiveSortableStop(sortable: SortableStopModel) {
    this.widgetLayers.rankIndicator(sortable.sourceIndex, sortable.targetIndex);
  }

}
