/**
 * @module User
 */

import { Component, OnInit, OnChanges, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { PivotFiltersClass } from '@class/widgetFilters.class';
import { PivotClass } from '@class/pivot.class';
import { UID } from '@models/uid.model';
import { ItemsColors, ItemColor, ItemIcon } from '@functions/item.functions';
import { BackendPivotsService } from '@services/backend/backendPivots/backend-pivots.service';
import { ServerResponseModel } from '@models/server.model';
import { AuthService } from '@services/auth/auth.service';

@Component({
  selector: 'app-user-filter',
  templateUrl: './user-filter.component.html',
  styleUrls: ['./user-filter.component.css']
})
export class UserFilterComponent implements OnInit, OnChanges {

  @Input() filters: PivotFiltersClass;
  @Input() pivot: PivotClass;
  @Input() current: boolean;
  @Output() sendSelected: EventEmitter<UID>;

  colors = ItemsColors(true);
  color = ItemColor('pivots');
  icon = ItemIcon('pivots');
  values: string[];
  searchText: string;
  page: number;
  limit: number;

  constructor(
    private $backendPivots: BackendPivotsService,
    private $auth: AuthService
  ) {
    this.sendSelected = new EventEmitter<UID>();
    this.searchText = '';
    this.limit = 20;
    this.page = 0;
  }

  ngOnInit() {
    if(this.current) {
      this.getValues();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.current !== undefined && !changes.current.firstChange && changes.current.currentValue === true) this.getValues();
  }

  receiveSearchText(searchText: string) {
    this.searchText = searchText;
  }

  onPageChanged(page: number) {
    this.page = page;
  }

  getValues() {
    if(this.values === undefined) {
      this.values = [];
      this.pivot.links.list('indicators').forEach((uid: UID) => {
        this.$backendPivots.get(uid, this.$auth.server$.value, this.pivot.uid.value, false) //TODO : attention ici utilisation du main à retirer si on gère un jour le multi-serveurs
        .then((reponse: ServerResponseModel) => {
          this.values = this.values.concat(reponse.message.values !== undefined ? reponse.message.values : []);
          this.values = this.values.filter((value: string, index: number) => index === this.values.indexOf(value));
        });
      });
    }
  }

  onSelect() {
    this.sendSelected.emit(this.pivot.uid.value);
  }

  receiveInclude(include: boolean) {
    this.filters.setInclude(include);
  }

}