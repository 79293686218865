<app-browser-navbar>
  <div app-browser-navbar-content>
    <app-browser-navbar-panels (sendPanel)="receivePanel($event)">
      
    </app-browser-navbar-panels>
  </div>
</app-browser-navbar>

<div [ngSwitch]="panel">
  <app-dashboards *ngSwitchCase="'dashboards'"></app-dashboards>
  <app-indicators *ngSwitchCase="'indicators'"></app-indicators>
  <app-widgets *ngSwitchCase="'widgets'"></app-widgets>
</div>