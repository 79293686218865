/**
 * @module Widget
 */

import { Component, Input } from '@angular/core';
import { ItemColor } from '@functions/item.functions';
import { LabelsModel } from '@models/labels.model';
import { WidgetLayersClass } from '@class/widgetLayers.class';
import { UID } from '@models/uid.model';

@Component({
  selector: 'app-widget-layers-title',
  templateUrl: './widget-layers-title.component.html',
  styleUrls: ['./widget-layers-title.component.css']
})
export class WidgetLayersTitleComponent {

  @Input() widgetLayers: WidgetLayersClass;
  @Input() current: UID;

  color = ItemColor('widgets');

  constructor() {}

  receiveLabel(labels: LabelsModel) {
    if(this.widgetLayers.uid.value === this.current) this.widgetLayers.duplicateLayersOptions({ chartTitle: labels }, this.current);
  }

}
