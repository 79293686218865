/**
 * @module Browser
 */

import { Component, ViewChild, OnDestroy, Input, OnInit } from '@angular/core';
import { AuthService } from '@services/auth/auth.service';
import { UserClass } from '@class/user.class';
import { Subscription, BehaviorSubject } from 'rxjs';
import { SignoutService } from '@services/signout/signout.service';
import { MessagesService } from '@services/messages/messages.service';
import { PresencesComponent } from '@components/presences/presences.component';
import { ItemsFrame, ItemsRoles, ItemsLabels, ItemsIcons, ItemsColors } from '@functions/item.functions';
import { ItemType } from '@models/item.model';
import { RightsComponent } from '@components/rights/rights/rights.component';
import { UsersComponent } from '@components/users/users/users.component';
import { TagsComponent } from '@components/tags/tags/tags.component';
import { ProfilComponent } from '@components/users/profil/profil.component';
import { ServersComponent } from '@components/servers/servers/servers.component';
import { PivotsComponent } from '@components/pivots/pivots/pivots.component';
import { DatesComponent } from '@components/dates/dates/dates.component';
import { Router } from '@angular/router';
import { ENVIRONMENT } from '@consts/params.const';

@Component({
  selector: 'app-browser-navbar',
  templateUrl: './browser-navbar.component.html',
  styleUrls: ['./browser-navbar.component.css']
})
export class BrowserNavbarComponent implements OnDestroy, OnInit {

  @Input() showFrames: boolean = true;
  @Input() showBack: boolean = false;

  @ViewChild('themesModal') themesModal: DatesComponent;
  @ViewChild('datesModal') datesModal: DatesComponent;
  @ViewChild('pivotsModal') pivotsModal: PivotsComponent;
  @ViewChild('serversModal') serversModal: ServersComponent;
  @ViewChild('tagsModal') tagsModal: TagsComponent;
  @ViewChild('rightsModal') rightsModal: RightsComponent;
  @ViewChild('usersModal') usersModal: UsersComponent;
  @ViewChild('presences', { static: true }) presencesModal: PresencesComponent;
  @ViewChild('profil', { static: true }) profilModal: ProfilComponent;

  user: UserClass;
  user$sub: Subscription;
  connected$: BehaviorSubject<boolean>;
  countUsers: number;
  frames: ItemType[];
  labels = ItemsLabels();
  icons = ItemsIcons();
  colors = ItemsColors();
  frame: ItemType;

  panel: string;
  from: string;
  version: string = ENVIRONMENT.code + ENVIRONMENT.version;
  isProduction: boolean = ENVIRONMENT.type === 'production';

  constructor(
    private $auth: AuthService,
    private $router: Router,
    private $messages: MessagesService,
    private $signout: SignoutService,
  ) {
    this.countUsers = 0;
    this.frames = [];
    this.connected$ = this.$auth.connected$;
  }

  ngOnInit() {
    this.user$sub = this.$auth.user$.subscribe({
      next: (user: UserClass) => {
        if(!!user) {
          this.user = user;
          if(this.showFrames) {
            this.frames = ItemsFrame().filter((item: ItemType) => {
              return this.user.roles.has(ItemsRoles()[item]);
            });
          }
        }
      }
    });
  }

  onBack() {
    this.panel = this.$router.parseUrl(this.$router.url).queryParams.panel;
    this.from = this.$router.parseUrl(this.$router.url).queryParams.from;
    this.$router.navigate([`/${this.from}`], { queryParams: this.panel !== undefined ? { panel: this.panel } : {} });
  }

  ngOnDestroy() {
    this.user$sub.unsubscribe();
  }

  onFrame(frame: ItemType) {
    switch(frame) {
      case 'rights': 
        this.frame = frame;
        setTimeout(() => { this.rightsModal.show() });
        break;
      case 'users': 
        this.frame = frame;
        setTimeout(() => { this.usersModal.show() });
        break;
      case 'tags': 
        this.frame = frame;
        setTimeout(() => { this.tagsModal.show() });
        break;
      case 'servers': 
        this.frame = frame;
        setTimeout(() => { this.serversModal.show() });
        break;
      case 'pivots': 
        this.frame = frame;
        setTimeout(() => { this.pivotsModal.show() });
        break;
      case 'dates': 
        this.frame = frame;
        setTimeout(() => { this.datesModal.show() });
        break;
      case 'themes': 
        this.frame = frame;
        setTimeout(() => { this.themesModal.show() });
        break;
    }
  }

  onSignOut() {
    this.$signout.signOut()
    .then(() => {
      this.$messages.info({ text: { code: 'LOGOUT_SUCCESS/TEXT' } });
    });
  }

  onProfil() {
    this.profilModal.show();
  }
  
  onPresence() {
    this.presencesModal.show();
  }

  receivePresencesCount(countUsers: number) {
    this.countUsers = countUsers;
  }

}
