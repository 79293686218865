/**
 * @module Widget
 */

import { Component, Input, OnDestroy, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { WidgetDatesBoundsClass } from '@class/widgetDatesBounds.class';
import { ItemColor, ItemIcon } from '@functions/item.functions';
import { Subscription } from 'rxjs';
import { LanguageType } from '@models/language.model';
import { AuthService } from '@services/auth/auth.service';
import { DateApplyFormat, IsDateValid } from '@functions/moment.functions';
import { DateClass } from '@class/date.class';
import { DateService } from '@services/date/date.service';
import { take } from 'rxjs/operators';
import pickadate from 'pickadate';
import { LanguagesPickadate } from '@functions/language.functions';

@Component({
  selector: 'app-widget-date-bounds',
  templateUrl: './widget-date-bounds.component.html',
  styleUrls: ['./widget-date-bounds.component.css']
})
export class WidgetDateBoundsComponent implements OnDestroy, OnInit {

  @Input() bounds: WidgetDatesBoundsClass;
  @ViewChild('pickadatefrom') pickerFromElement: ElementRef;
  @ViewChild('pickadateto') pickerToElement: ElementRef;
  
  date: DateClass;
  color = ItemColor('dates', true);
  icon = ItemIcon('dates');
  from: string;
  to: string;
  fromFull: string;
  toFull: string;

  timeout: any;
  delay = 250;

  language$sub: Subscription;
  language: LanguageType;

  pickerFrom: any;
  pickerTo: any;

  pickerFromTimeout: any;
  pickerToTimeout: any;

  constructor(
    private $auth: AuthService,
    private $dates: DateService
  ) {
    this.language$sub = this.$auth.language$.subscribe({
      next: (language: LanguageType) => {
        this.language = language;
      }
    });
  }

  getPicker() {
    if(this.pickerFrom === undefined && this.pickerFromElement !== undefined) {
      this.pickerFrom = pickadate.create(!!this.from ? { selected: new Date(this.from) } : undefined, LanguagesPickadate(this.language));
      const onChange = () => {
        this.receiveGreater(this.pickerFrom.getValue('YYYY-MM-DD'), this.bounds.greater.equivalent);
      };
      this.pickerFromElement.nativeElement.addEventListener('pickadate:change', onChange);
      pickadate.render(this.pickerFromElement.nativeElement, this.pickerFrom, { 
        className: { 
          root: ['datepicker--root']
        }
      });
    }
    if(this.pickerTo === undefined && this.pickerToElement !== undefined) {
      this.pickerTo = pickadate.create(!!this.to ? { selected: new Date(this.to) } : undefined, LanguagesPickadate(this.language));
      const onChange = () => {
        this.receiveLess(this.pickerTo.getValue('YYYY-MM-DD'), this.bounds.less.equivalent);
      };
      this.pickerToElement.nativeElement.addEventListener('pickadate:change', onChange);
      pickadate.render(this.pickerToElement.nativeElement, this.pickerTo, { 
        className: { 
          root: ['datepicker--root']
        }
      });
    }
  }

  ngOnInit() {
    this.$dates.getPartial$(this.bounds.uid.value).pipe(take(1)).subscribe((d: DateClass) => {
      if(!!d) {
        this.date = d;
        setTimeout(() => this.getPicker());
      }
    });

    this.from = this.bounds.greater.value;
    this.fromFull = this.from.length > 0 ? DateApplyFormat(this.from, 'LL') : '';
    this.to = this.bounds.less.value;
    this.toFull = this.to.length > 0 ? DateApplyFormat(this.to, 'LL') : '';
  }

  ngOnDestroy() {
    this.language$sub.unsubscribe();
  }

  receiveGreater(date: string, equivalent: boolean) {
    if(DateApplyFormat(this.from, 'YYYY-MM-DD') !== DateApplyFormat(date, 'YYYY-MM-DD')) {
      if(date === null) date = '';
      if(this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.bounds.set('greater', equivalent, date);
      }, this.delay);
      this.from = date;
      this.fromFull = date.length > 0 ? DateApplyFormat(date, 'LL') : '';

      if(!!this.pickerFromTimeout) clearTimeout(this.pickerFromTimeout);
      this.pickerFromTimeout = setTimeout(() => { if(IsDateValid(this.from)) this.pickerFrom.setSelected({ value: new Date(this.from) }); }, 1000);
    }
  }

  receiveSetGreater(equivalent: boolean) {
    this.bounds.set('greater', equivalent);
  }

  receiveLess(date: string, equivalent: boolean) {
    if(DateApplyFormat(this.to, 'YYYY-MM-DD') !== DateApplyFormat(date, 'YYYY-MM-DD')) {
      if(date === null) date = '';
      if(this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.bounds.set('less', equivalent, date);
      }, this.delay);
      this.to = date;
      this.toFull = date.length > 0 ? DateApplyFormat(date, 'LL') : '';

      if(!!this.pickerToTimeout) clearTimeout(this.pickerToTimeout);
      this.pickerToTimeout = setTimeout(() => { if(IsDateValid(this.to)) this.pickerTo.setSelected({ value: new Date(this.to) }); }, 1000);
    }
  }

  receiveSetLess(equivalent: boolean) {
    this.bounds.set('less', equivalent);
  }

}