<app-links-item [bottomMargin]="false" [color]="color" [icon]="icon" [label]="'LAYERS_COMMON_OPTIONS'">
  <div app-links-item-content-description>
  </div>
  <div app-links-item-content-option class="d-flex align-items-center">
    <button *ngIf="selected" (click)="onEdit()" type="button" mdbBtn [color]="color" size="sm" class="z-depth-0 d-flex px-2 align-items-center my-0 mr-0 ml-1" style="width: 30px; height: 31px;" mdbWavesEffect>
      <i class="fas fa-fw fa-sliders-h-square"></i>
    </button>
    <button *ngIf="!selected" (click)="onEdit()" type="button" mdbBtn color="blue-grey" size="sm" class="z-depth-0 d-flex px-2 align-items-center my-0 mr-0 ml-1" style="width: 30px; height: 31px;" mdbWavesEffect>
      <i class="fas fa-fw fa-sliders-h-square"></i>
    </button>
  </div>
</app-links-item>