/**
 * @module Dashboard
 */

import { Component, Input } from '@angular/core';
import { ChartType } from '@models/chart.model';
import { ChartsIcons } from '@functions/chart.functions';
import { ItemColor } from '@functions/item.functions';

@Component({
  selector: 'app-dashboard-option',
  templateUrl: './dashboard-option.component.html',
  styleUrls: ['./dashboard-option.component.css']
})
export class DashboardOptionComponent {

  @Input() label: string;
  @Input() color: string = ItemColor('dashboards');
  @Input() sublabel: string;
  @Input() categories: string[];
  @Input() information: string;
  @Input() subinformation: string;
  @Input() dark: number = 5;
  @Input() limitedTo: ChartType[] = [];

  icons = ChartsIcons();

  constructor() {}

}
