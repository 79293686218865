/**
 * @module Icon
 */

import { IconModel } from '@models/icon.model';
import { ModificationClass, ModificationStateClass } from '@class/modification.class';
import { DeepCopy } from '@functions/copy.functions';

export class IconClass extends ModificationClass<IconModel> {

  protected _parent: IconModel;
  protected _inital: IconModel;
  protected _attributes: string[];

  constructor(icon: IconModel, state: ModificationStateClass) {
    
    super();
    
    this._parent = icon;
    this._attributes = this.attributes;
    this._state = state;

    this._inital = DeepCopy({ icon: this._parent.icon });
    
  }

  get class(): Readonly<string> {
    return 'icon';
  }

  get symbol(): Readonly<string> {
    return `fa-${this._parent.icon.symbol}`;
  }

  get style(): Readonly<string> {
    return `fa${this._parent.icon.style}`;
  }

  get code(): Readonly<string> {
    return `${this.style} ${this.symbol} ${this.options}`;
  }

  get options(): Readonly<string> {
    return this._parent.icon.options !== undefined ? `fa-${this._parent.icon.options.join(' fa-')}` : '';
  }

  get attributes(): string[] {
    return ['icon'];
  }

}