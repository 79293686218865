/**
 * @module Forms
 */

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-form-strip',
  templateUrl: './form-strip.component.html',
  styleUrls: ['./form-strip.component.css']
})
export class FormStripComponent {

  @Input() center: boolean = false;

  constructor() { }

}
