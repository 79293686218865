/**
 * @module Theme
 */

import { Component, Input } from '@angular/core';
import { ItemColor } from '@functions/item.functions';
import { ThemeOptionsClass } from '@class/themeOptions.class';
import { ChartType } from '@models/chart.model';
import { ThemeOptionType } from '@models/themeStudio.model';

@Component({
  selector: 'app-theme-option-datalabels-clamp',
  templateUrl: './theme-option-datalabels-clamp.component.html',
  styleUrls: ['./theme-option-datalabels-clamp.component.css']
})
export class ThemeOptionDatalabelsClampComponent {

  @Input() themeOptions: ThemeOptionsClass;
  @Input() datalabelsIndex: number;

  color = ItemColor('themes');
  optionType: ThemeOptionType = 'datalabels_clamp';
  showDetails: boolean;

  constructor() {}

  receiveShowDetails(showDetails: boolean) {
    this.showDetails = showDetails;
  }

  receiveValue(value: boolean, type?: ChartType) {
    this.themeOptions.setDatalabels(this.optionType, value, this.datalabelsIndex, type ? [type] : undefined);
  }

}
