/**
 * @module LinkedColumn
 */

import { UID } from '@models/uid.model';
import { ModificationClass, ModificationStateClass } from '@class/modification.class';
import { DeepCopy } from '@functions/copy.functions';
import { LinkedColumnsModel } from '@models/linkedColumns.model';

export class LinkedColumnsClass extends ModificationClass<LinkedColumnsModel> {

  protected _parent: LinkedColumnsModel;
  protected _inital: LinkedColumnsModel;
  protected _attributes: string[];

  constructor(linkedColumns: LinkedColumnsModel, state: ModificationStateClass) {

    super();
    
    this._parent = linkedColumns;
    this._attributes = this.attributes;
    this._state = state;

    this._inital = DeepCopy({ linkedColumns: this._parent.linkedColumns });

  }

  get class(): Readonly<string> {
    return 'linkedColumns';
  }

  get attributes(): string[] {
    return ['linkedColumns'];
  }

  get noLinkedColumns(): Readonly<boolean> {
    return this._parent.linkedColumns.length === 0;
  }

  get hasLinkedColumns(): Readonly<boolean> {
    return this._parent.linkedColumns.length > 0;
  }

  list(): Readonly<UID[]> {
    return this._parent.linkedColumns;
  }

  has(linkedColumn: string): Readonly<boolean> {
    return this._parent.linkedColumns.includes(linkedColumn);
  }

  add(linkedColumn: string) {
    if(!this.has(linkedColumn)) {
      this._parent.linkedColumns.push(linkedColumn);
      this.emit(this.attributes);
    }
  }

  remove(linkedColumn: string) {
    const index = this._parent.linkedColumns.indexOf(linkedColumn);
    if(index !== -1) {
      this._parent.linkedColumns.splice(index, 1);
      this.emit(this.attributes);
    }
  }

}