/**
 * @module Theme
 */

import { Component, Input } from '@angular/core';
import { ThemeOptionsClass } from '@class/themeOptions.class';
import { ItemColor } from '@functions/item.functions';
import { ThemeOptionType } from '@models/themeStudio.model';
import { IconFrameType } from 'chart.js';
import { ChartType } from '@models/chart.model';

@Component({
  selector: 'app-theme-option-elements-chart-transition-animation',
  templateUrl: './theme-option-elements-chart-transition-animation.component.html',
  styleUrls: ['./theme-option-elements-chart-transition-animation.component.css']
})
export class ThemeOptionElementsChartTransitionAnimationComponent {

  @Input() themeOptions: ThemeOptionsClass;

  showDetails: boolean;
  color = ItemColor('themes');
  optionType: ThemeOptionType = 'elements_chart_transitionAnimation';

  constructor() {
    this.showDetails = false;
  }

  onValue(value: IconFrameType, type?: ChartType) {
    this.themeOptions.setOption(this.optionType, value, type ? [type] : undefined);
  }

  receiveShowDetails(showDetails: boolean) {
    this.showDetails = showDetails;
  }

  values = {
    'fade': { style: 'fade', label: 'FADE' },
    'slide': { style: 'slide', label: 'SLIDE' },
  };

}
