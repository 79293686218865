/**
 * @module Widget
 */

import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { DateClass } from '@class/date.class';
import { ItemsColors, ItemIcon } from '@functions/item.functions';
import { UID } from '@models/uid.model';
import { DateApplyFormat, ParseGenericDate } from '@functions/moment.functions';
import { WidgetLayerClass } from '@class/widgetLayer.class';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-widget-layers-filters-date',
  templateUrl: './widget-layers-filters-date.component.html',
  styleUrls: ['./widget-layers-filters-date.component.css']
})
export class WidgetLayersFiltersDateComponent implements OnInit, OnDestroy {

  @Input() date: DateClass;
  @Input() layer: WidgetLayerClass;
  @Input() current: boolean;
  @Output() sendSelected: EventEmitter<UID>;

  colors = ItemsColors(true);
  icon = ItemIcon('dates');
  from: string;
  to: string;
  fromGeneric: string;
  toGeneric: string;
  lastModifications$sub: Subscription;

  timeout: any;
  delay = 250;

  constructor() {
    this.sendSelected = new EventEmitter<UID>();
  }

  ngOnInit() {
    this.fromGeneric = this.layer.bounds.greater.value;
    this.from = DateApplyFormat(ParseGenericDate(this.fromGeneric, true), 'LLLL');

    this.toGeneric = this.layer.bounds.less.value;
    this.to = DateApplyFormat(ParseGenericDate(this.toGeneric, false), 'LLLL');

    this.lastModifications$sub = this.layer.lastModification$.subscribe((attribute: string) => {
      if(attribute === 'datesBounds') {
        this.fromGeneric = this.layer.bounds.greater.value;
        this.toGeneric = this.layer.bounds.less.value;
      }
    });
  }

  ngOnDestroy() {
    this.lastModifications$sub.unsubscribe();
  }

  onSelect() {
    this.sendSelected.emit(this.date.uid.value);
  }

  receiveGreater(date: string, equivalent: boolean) {
    if(date === null) date = '';
    if(this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.layer.bounds.set('greater', equivalent, date);
    }, this.delay);
    this.from = DateApplyFormat(ParseGenericDate(date, true), 'LLLL');
  }

  receiveSetGreater(equivalent: boolean) {
    this.layer.bounds.set('greater', equivalent);
  }

  receiveLess(date: string, equivalent: boolean) {
    if(date === null) date = '';
    if(this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.layer.bounds.set('less', equivalent, date);
    }, this.delay);
    this.to = DateApplyFormat(ParseGenericDate(date, false), 'LLLL');
  }

  receiveSetLess(equivalent: boolean) {
    this.layer.bounds.set('less', equivalent);
  }

}
