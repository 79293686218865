<div mdbModal #scopeModal="mdbModal" class="modal fade" style="overflow-y: scroll" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content" *ngIf="dataFrames != undefined">
      <div class="modal-header d-flex align-items-center flex-column">

        <i class="animated rotateIn {{color}}-text far fa-ballot-check fa-4x d-none d-sm-inline"></i>
        <i class="animated rotateIn {{color}}-text far fa-ballot-check fa-2x d-sm-none"></i>

        <h6 class="modal-title w-100 text-center">
          <div class="font-weight-bold text-elegant mt-1">&nbsp;{{ 'SCOPE_SELECTION' | translate }}&nbsp;</div>
        </h6>

      </div>
      <div class="modal-header">

        <button type="button" size="sm" mdbBtn color="secondary" class="waves-light z-depth-0 px-2 m-0 app-close-modal" (click)="scopeModal.hide()" mdbWavesEffect>
          <i class="fas fa-times fa-fw"></i>
        </button>

        <button type="button" mdbBtn [color]="color" class="waves-light z-depth-0 px-sm-4 px-3 my-0 ml-auto" (click)="onSelect()" mdbWavesEffect>
          <i class="d-sm-none far fa-check fa-fw"></i>
          <i class="d-none d-sm-inline far fa-check"></i>
          <span class="d-none d-sm-inline ml-1">{{ 'SELECT' | translate }}</span>
        </button>

        <div class="btn-group" mdbDropdown>
          <a mdbDropdownToggle type="button" mdbBtn color="light" class="blue-grey-text z-depth-0 dropdown-toggle waves-light d-flex align-items-center px-3" mdbWavesEffect>
            <i class="far fa-bars"></i>&nbsp;
          </a>
        
          <div class="dropdown-menu dropdown-menu-right dropdown-dark">

            <a *ngFor="let dataFrame of dataFrames | keyvalue ; let i = index" 
            class="dropdown-item z-depth-0" (click)="editorIndex = i">{{ dataFrame.key }}</a>

            <!--div class="divider dropdown-divider"></div>
            <a class="dropdown-item z-depth-0" (click)="editorIndex = editorIndexMax">{{ 'SERVER' | translate }}</a-->

          </div>
        </div>
        
      </div>

      <div class="modal-body overflow-hidden">
        
        <div [ngSwitch]="editorIndex">
          <div *ngFor="let dataFrame of dataFrames | keyvalue ; let i = index">
            <div *ngSwitchCase="i">
              <app-editor-section-title [title]="dataFrame.key" (sendNext)="receiveNext($event)"></app-editor-section-title>

              <app-form-strip class="d-block mb-1 mt-3">

                <div app-form-strip-content-middle>
                  <span *ngIf="dataFrame.value.isImported" class="font-weight-bolder font-small blue-grey-text">{{ 'IMPORT' | translate }}</span>
                  <span *ngIf="!dataFrame.value.isImported" class="font-weight-bolder font-small blue-grey-text">{{ 'DO_NOT_IMPORT' | translate }}</span>
                </div>
                <div app-form-strip-content-right>
                  <div class="switch round success-danger-switch">
                    <label class="mb-0">
                      <input type="checkbox" [checked]="dataFrame.value.isImported" (change)="onImport($event.target.checked, dataFrame.key)">
                      <span class="lever mr-0"></span>
                    </label>
                  </div>
                </div>
              
              </app-form-strip>

              <div class="alert alert-warning mb-1 animated fadeIn text-center" role="alert" *ngIf="dataFrame.value.countIndicators == 0">
                <i class="fad fa-exclamation-triangle fa-2x animated rotateIn"></i><br>
                <div class="mt-3">{{ 'SELECT_AT_LEAST_AN_INDICATOR/TEXT' | translate }}</div>
              </div>

              <div *ngIf="dataFrame.value.isImported">
                <div *ngFor="let serie of dataFrame.value.series | keyvalue">
                  <app-links-item *ngIf="serie.value.label != '$$uid'" [color]="serie.value.isImported ? colors[serie.value.selected.item] : 'danger-color'" [icon]="serie.value.isImported ? icons[serie.value.selected.item] : 'far fa-times'" [label]="serie.value.label">
                    <div app-links-item-content-description>
                      
                      <div *ngIf="serie.value.isImported">
                        <div *ngFor="let type of serie.value.types" class="font-very-small">
                          <span class="mr-2">{{ ('TYPE_' + type.Type.toUpperCase()) | translate }}</span>
                          <mdb-badge class="z-depth-0" color="blue-grey" *ngIf="type.Type != serie.value.recommended.code">{{ type.Frequency | number:'1.0-2' }}%</mdb-badge>
                          <mdb-badge class="z-depth-0" [color]="color" *ngIf="type.Type == serie.value.recommended.code && serie.value.recommended.type == serie.value.selected.type">{{ type.Frequency | number:'1.0-2' }}%</mdb-badge>
                          <mdb-badge class="z-depth-0" warning="true" *ngIf="type.Type == serie.value.recommended.code && serie.value.recommended.type != serie.value.selected.type">{{ type.Frequency | number:'1.0-2' }}%</mdb-badge>
                        </div>
                      </div>

                    </div>
                    <div app-links-item-content-option class="ml-1" *ngIf="serie.value.isImported" mdbTooltip="{{ 'TYPE_DATE' | translate }}" placement="top">
                      <app-links-item-option [symbol]="icons['dates']" [selected]="serie.value.selected.item == 'dates'" [uid]="serie.value.uid" (sendSelected)="receiveSelected($event, dataFrame.key, 'dates', 'date')"></app-links-item-option>
                    </div>
                    <div app-links-item-content-option class="ml-1" *ngIf="serie.value.isImported" mdbTooltip="{{ 'TYPE_STRING' | translate }}" placement="top">
                      <app-links-item-option [symbol]="icons['pivots']" [selected]="serie.value.selected.item == 'pivots'" [uid]="serie.value.uid" (sendSelected)="receiveSelected($event, dataFrame.key, 'pivots', 'string')"></app-links-item-option>
                    </div>
                    <div app-links-item-content-option class="ml-1" *ngIf="serie.value.isImported" mdbTooltip="{{ 'TYPE_NUMBER' | translate }}" placement="top">
                      <app-links-item-option [symbol]="icons['indicators']" [selected]="serie.value.selected.item == 'indicators'" [uid]="serie.value.uid" (sendSelected)="receiveSelected($event, dataFrame.key, 'indicators', 'number')"></app-links-item-option>
                    </div>
                    <div app-links-item-content-option class="ml-1">
                      <app-links-item-option [symbol]="'check'" [selected]="serie.value.isImported" [uid]="serie.value.uid" (sendSelected)="receiveSelected($event, dataFrame.key, undefined, undefined)"></app-links-item-option>
                    </div>
                  </app-links-item>
                </div>
              </div>

            </div>
          </div>
          
          <!--div *ngSwitchCase="editorIndexMax">
            <app-editor-section-title [title]="'SERVER'" (sendNext)="receiveNext($event)"></app-editor-section-title>
            <div class="d-flex justify-content-center flex-column mt-3">
              <div class="text-center alert alert-info mb-0 p-3" role="alert">
                <i class="fad fa-sliders-h fa-2x animated rotateIn"></i><br>
                <div class="mt-3">{{ 'CHANGEABLE_INFO/TEXT' | translate }}</div>
              </div>
            </div>
            <app-servers-links-list [serverLink]="server" [isRegistered]="false" [showWarning]="false"></app-servers-links-list>
          </div-->
        </div>

      </div>
    </div>
  </div>
</div>