/**
 * @module Theme
 */

import { Component, Input } from '@angular/core';
import { ThemeOptionsClass } from '@class/themeOptions.class';
import { ItemColor } from '@functions/item.functions';
import { ThemeOptionType } from '@models/themeStudio.model';

@Component({
  selector: 'app-theme-option-elements-hide-in-legend-and-tooltip',
  templateUrl: './theme-option-elements-hide-in-legend-and-tooltip.component.html',
  styleUrls: ['./theme-option-elements-hide-in-legend-and-tooltip.component.css']
})
export class ThemeOptionElementsHideInLegendAndTooltipComponent {

  @Input() themeOptions: ThemeOptionsClass;
  @Input() serieIndex: number;

  color = ItemColor('themes');
  optionType: ThemeOptionType = 'elements_hideInLegendAndTooltip';

  constructor() {}

  receiveValue(value: boolean) {
    this.themeOptions.setSeries(this.optionType, value, this.serieIndex);
  }

}
