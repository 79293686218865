/**
 * @module Widget
 */

import { Component, Input } from '@angular/core';
import { ThemeOptionsClass } from '@class/themeOptions.class';
import { WidgetLayersClass } from '@class/widgetLayers.class';
import { UID } from '@models/uid.model';
import { ItemColor, ItemIcon } from '@functions/item.functions';
import { WidgetLayersDefinedOptionModel } from '@models/widgetLayers.model';
import { DefinedOptionIndex } from '@functions/widget.functions';
import { ThemeOptionsConfiguration } from '@functions/theme.functions';
import { ThemeOptionsConfigurationType } from '@models/themeOptions.model';

@Component({
  selector: 'app-widget-layers-theme-options',
  templateUrl: './widget-layers-theme-options.component.html',
  styleUrls: ['./widget-layers-theme-options.component.css']
})
export class WidgetLayersThemeOptionsComponent {

  @Input() widgetLayers: WidgetLayersClass;
  @Input() themeOptions: ThemeOptionsClass;
  @Input() current: UID;

  definedOptions: WidgetLayersDefinedOptionModel[];
  list: ThemeOptionsConfigurationType;
  duplicable: boolean = false;
  deletable: boolean = false;

  color = ItemColor('themes');
  icon = ItemIcon('themes');

  constructor() {
    this.definedOptions = [];
    this.list = ThemeOptionsConfiguration();
  }

  receiveDuplicable() {
    this.duplicable = !this.duplicable;
    if(this.duplicable) this.deletable = false;
  }

  receiveDeletable() {
    this.deletable = !this.deletable;
    if(this.deletable) this.duplicable = false;
  }

  onSelect() {
    this.definedOptions.forEach((definedOption: WidgetLayersDefinedOptionModel) => {
      this.widgetLayers.layer(this.current).addDefinedOption(
        definedOption, 
        this.themeOptions.option(definedOption.option, definedOption.index, definedOption.type, definedOption.axesXY),
        this.themeOptions.option(definedOption.option, definedOption.index, definedOption.type, definedOption.axesXY, undefined, true) as any
      );
    });
    this.definedOptions = [];
  }

  onCancel() {
    this.definedOptions = [];
  }

  onRemove(index: number) {
    this.definedOptions.splice(index, 1);
  }

  receiveSelect(definedOption: WidgetLayersDefinedOptionModel) {
    const index = this.definedOptions.map(d => DefinedOptionIndex(d)).indexOf(DefinedOptionIndex(definedOption));
    if(index !== -1) this.definedOptions.splice(index, 1);
    else this.definedOptions.push(definedOption);
  }

}
