/**
 * @module Widget
 */

import { Component, Input } from '@angular/core';
import { WidgetLayersClass } from '@class/widgetLayers.class';
import { ItemColor } from '@functions/item.functions';

@Component({
  selector: 'app-widget-indicators-display',
  templateUrl: './widget-indicators-display.component.html',
  styleUrls: ['./widget-indicators-display.component.css']
})
export class WidgetIndicatorsDisplayComponent {

  @Input() widgetLayers: WidgetLayersClass;
  @Input() current: number;

  color = ItemColor('indicators');

  constructor() {}

  receiveDisplay(display: boolean) {
    this.widgetLayers.indicators[this.current].change({ display: display });
  }

}
