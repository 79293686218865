/**
 * @module Item
 */

import { Component, Input, ViewChild, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { DescriptionsClass } from '@class/descriptions.class';
import { TagsClass } from '@class/tag.class';
import { AuthService } from '@services/auth/auth.service';
import { LanguageType } from '@models/language.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-item-info',
  templateUrl: './item-info.component.html',
  styleUrls: ['./item-info.component.css']
})
export class ItemInfoComponent implements OnDestroy {

  @Input() title: string;
  @Input() icon: string; //style+symbol
  @Input() color: string; //name
  @Input() iconColor: string; //hexa

  @Input() descriptions: DescriptionsClass;
  @Input() tags: TagsClass;

  @ViewChild('infoModal', { static: true }) info: ModalDirective; 

  @Output() sendClose = new EventEmitter<void>();
  
  language: LanguageType;
  language$sub: Subscription;

  constructor(
    private $auth: AuthService
  ) {
    this.language$sub = this.$auth.language$.subscribe({
      next: (language: LanguageType) => {
        this.language = language;
      }
    });
  }

  ngOnDestroy() {
    this.language$sub.unsubscribe();
  }

  public show() {
    this.info.show();
  }

  public hide() {
    this.info.hide();
  }

  public get isShown(): Readonly<boolean> {
    return this.info.isShown;
  }

  onClose() {
    this.info.hide();
    this.sendClose.emit();
  }

}
