<div [ngSwitch]="current?.category">
  <app-theme-options-layout *ngSwitchCase="'LAYOUT'" [themeOptions]="themeOptions"></app-theme-options-layout>
  <app-theme-options-title *ngSwitchCase="'TITLE'" [themeOptions]="themeOptions"></app-theme-options-title>
  <app-theme-options-legend *ngSwitchCase="'LEGEND'" [themeOptions]="themeOptions"></app-theme-options-legend>
  <app-theme-options-tooltips *ngSwitchCase="'TOOLTIPS'" [themeOptions]="themeOptions"></app-theme-options-tooltips>
  <app-theme-options-animation *ngSwitchCase="'ANIMATION'" [themeOptions]="themeOptions"></app-theme-options-animation>
  <app-theme-options-chart *ngSwitchCase="'SPECIFIC'" [themeOptions]="themeOptions"></app-theme-options-chart>
  <app-theme-options-serie *ngSwitchCase="'SERIE'" [themeOptions]="themeOptions" [current]="current"></app-theme-options-serie>
  <app-theme-options-datalabels *ngSwitchCase="'DATALABELS'" [themeOptions]="themeOptions" [current]="current"></app-theme-options-datalabels>
  <app-theme-options-axis *ngSwitchCase="'X-AXIS'" [themeOptions]="themeOptions" [current]="current" [axesXY]="'x'"></app-theme-options-axis>
  <app-theme-options-axis *ngSwitchCase="'Y-AXIS'" [themeOptions]="themeOptions" [current]="current" [axesXY]="'y'"></app-theme-options-axis>
</div>