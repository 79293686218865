/**
 * @module Form
 */

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NumbroModel, NumbroFormatModel, NumbroFormatType, NumbroNegativeType, NumbroForceAverageType, NumbroOutputType, NumbroCurrencyPosition } from '@models/numbro.model';
import { DeepMerge } from '@functions/copy.functions';
import { NumbroFormatDefault, NumbroFormat, NumbroFormatTypes, NumbroApplyFormat, NumbroNegativeTypes, NumbroForceAverageOptions, NumbroOutputTypes, NumbroCurrencyPositions } from '@functions/numbro.functions';
import { NumbroClass } from '@class/numbro.class';

@Component({
  selector: 'app-numbro',
  templateUrl: './numbro.component.html',
  styleUrls: ['./numbro.component.css']
})
export class NumbroComponent implements OnInit {

  @Input() numbro: NumbroClass;
  @Input() color: string;
  @Input() label: string = 'FORMAT';
  @Output() sendNumbro: EventEmitter<NumbroModel>;

  format: NumbroFormatModel;
  personalized: boolean;
  type: NumbroFormatType;

  formats = NumbroFormatTypes() as NumbroFormatType[];
  outputs = NumbroOutputTypes() as NumbroOutputType[];
  negatives = NumbroNegativeTypes() as NumbroNegativeType[];
  averages = NumbroForceAverageOptions() as string[];
  currencyPositions = NumbroCurrencyPositions() as string[];
  average: string;

  reference: number = 12345.678900;
  referencePositive: string = '12345.678900';
  referenceNegative: string = '-12345.678900';
  formatedReferencePositive: string;
  formatedReferenceNegative: string;

  constructor() {
    this.sendNumbro = new EventEmitter<NumbroModel>();
  }

  ngOnInit() {
    this.personalized = this.numbro.type === 'personalized';
    this.format = DeepMerge(NumbroFormatDefault(), this.personalized ? this.numbro.format : NumbroFormat(this.numbro.type));
    this.type = this.numbro.type;
    this.average = !this.format.average ? 'inactive' : this.format.forceAverage === undefined ? 'automatic' : this.format.forceAverage;
    this.setReferences();
  }

  onFormat(value: NumbroFormatType) {
    if(value === 'personalized') this.format = DeepMerge(NumbroFormatDefault(), NumbroFormat(this.type));
    else this.format = NumbroFormat(value);
    this.type = value;
    this.sendNumbro.emit({ type: this.type, format: this.format });
    this.setReferences();
  }

  receiveMantissa(value: number) {
    this.format.mantissa = value;
    this.sendNumbro.emit({ type: this.type, format: this.format });
    this.setReferences();
  }

  receiveTrimMantissa(value: boolean) {
    this.format.trimMantissa = value;
    this.sendNumbro.emit({ type: this.type, format: this.format });
    this.setReferences();
  }

  receiveForceSign(value: boolean) {
    this.format.forceSign = value;
    this.sendNumbro.emit({ type: this.type, format: this.format });
    this.setReferences();
  }

  receiveThousandSeparated(value: boolean) {
    this.format.thousandSeparated = value;
    this.sendNumbro.emit({ type: this.type, format: this.format });
    this.setReferences();
  }

  receiveSpaceSeparated(value: boolean) {
    this.format.spaceSeparated = value;
    this.sendNumbro.emit({ type: this.type, format: this.format });
    this.setReferences();
  }

  receiveNegative(value: NumbroNegativeType) {
    this.format.negative = value;
    this.sendNumbro.emit({ type: this.type, format: this.format });
    this.setReferences();
  }

  receiveOutput(value: NumbroOutputType) {
    this.format.output = value;
    this.sendNumbro.emit({ type: this.type, format: this.format });
    this.setReferences();
  }

  receiveCurrencyPosition(value: NumbroCurrencyPosition) {
    this.format.currencyPosition = value;
    this.sendNumbro.emit({ type: this.type, format: this.format });
    this.setReferences();
  }

  receivePrefix(value: string) {
    this.format.prefix = value;
    this.sendNumbro.emit({ type: this.type, format: this.format });
    this.setReferences();
  }

  receivePostfix(value: string) {
    this.format.postfix = value;
    this.sendNumbro.emit({ type: this.type, format: this.format });
    this.setReferences();
  }

  receiveCurrencySymbol(value: string) {
    this.format.currencySymbol = value;
    this.sendNumbro.emit({ type: this.type, format: this.format });
    this.setReferences();
  }

  receiveAverage(value: string) {
    if(value === 'inactive') {
      this.format.average = false;
      delete this.format.forceAverage;
    }
    else if(value === 'automatic') {
      this.format.average = true
      delete this.format.forceAverage;
    }
    else {
      this.format.average = true;
      this.format.forceAverage = value as NumbroForceAverageType;
    }
    this.average = value;
    this.sendNumbro.emit({ type: this.type, format: this.format });
    this.setReferences();
  }

  setReferences() {
    this.formatedReferencePositive = NumbroApplyFormat(this.reference, 'personalized', this.format);
    this.formatedReferenceNegative = NumbroApplyFormat(this.reference * -1, 'personalized', this.format);
  }

}
