/**
 * @module Pivot
 */

import { Injectable } from '@angular/core';
import { PivotClass } from '@class/pivot.class';
import { Observable, BehaviorSubject } from 'rxjs';
import { PivotModel } from '@models/pivot.model';
import { map} from 'rxjs/operators';
import { FirestoreService } from '@services/firestore/firestore.service';
import { AuthService } from '@services/auth/auth.service';
import { FirebaseService } from '@services/firebase/firebase.service';
import { UidService } from '@services/uid/uid.service';
import { ItemType } from '@models/item.model';
import { LinksModelConstructor } from '@functions/link.functions';
import { DeepMerge } from '@functions/copy.functions';

@Injectable({
  providedIn: 'root'
})
export class PivotService extends FirestoreService<PivotModel> {

  /**
   * @description type de document manipulé par le service
   * @protected
   * @type {ItemType}
   * @memberof PivotService
   */
  protected _itemType: ItemType = 'pivots';

  /**
   * @description true si l'accès à ce type de document peut nécessiter des habilitations
   * @protected
   * @type {boolean}
   * @memberof PivotService
   */
  protected _restricted: boolean = false;

  /**
   * @description Creates an instance of PivotService
   * @param {UidService} $uid
   * @param {AuthService} $auth
   * @param {FirebaseService} $firebase
   * @memberof PivotService
   */
  constructor(
    private $uid: UidService,
    protected $auth: AuthService,
    protected $firebase: FirebaseService
  ) {
    super($firebase, $auth);
  }

  /**
   * @description Observateur de la liste des objects autorisés pour l'utilisateur courant
   * @returns {BehaviorSubject<PivotClass[]>}
   * @memberof PivotService
   */
  list$(): BehaviorSubject<PivotClass[]> {
    return super.full$().pipe(
      map((pivots: PivotModel[]) => {
        if(!!pivots) {
          let ds = pivots.map<PivotClass>((pivot: PivotModel) => {
            return new PivotClass(pivot, true);
          });
          return ds;
        }
      })
    ) as BehaviorSubject<PivotClass[]>;
  }

  /**
   * @description Observateur d'un document dont l'uid est passé en paramètre
   * @param {string} uid
   * @returns {Observable<PivotClass>}
   * @memberof PivotService
   */
  get$(uid: string): Observable<PivotClass> {
    return super.doc$(uid).pipe(
      map((pivot: PivotModel) => {
        return new PivotClass(pivot, true);
      })
    );
  }

  /**
   * @description Observateur de la liste des objects (données partielles) autorisés pour l'utilisateur courant
   * @returns {Observable<PivotClass[]>}
   * @memberof PivotService
   */
  listPartials$(): Observable<PivotClass[]> {
    return super.fullPartial$().pipe(
      map((pivots: PivotModel[]) => {
        if(!!pivots) {
          let ds = pivots.map<PivotClass>((pivot: PivotModel) => {
            return new PivotClass(pivot, true);
          });
          return ds;
        }
      })
    );
  }

  /**
   * @description Observateur d'un document (données partielles) dont l'uid est passé en paramètre
   * @param {string} uid
   * @returns {Observable<PivotClass>}
   * @memberof PivotService
   */
  getPartial$(uid: string): Observable<PivotClass> {
    return super.partial$(uid).pipe(
      map((pivot: PivotModel) => {
        if(pivot !== null) return new PivotClass(pivot, true);
      })
    );
  }

  /**
   * @description Créé un nouveau document avec les valeurs par défaut
   * @param {Partial<PivotModel>} [model={}]
   * @returns {PivotClass}
   * @memberof PivotService
   */
  create(model: Partial<PivotModel> = {}): PivotClass {
    let _model = { 
      uid: this.$uid.create(),
      links: LinksModelConstructor().links,
      logs: {},
      tags: {},
      linkedColumns: [],
      labels: undefined,
      descriptions: undefined
    } as PivotModel;

    _model = DeepMerge(_model, model as PivotModel);

    return new PivotClass(_model, false);
  }

  /**
   * @description Créé ou met à jour le document passé en paramètre
   * @param {PivotClass} pivot
   * @returns {Promise<void>}
   * @memberof PivotService
   */
  async set(pivot: PivotClass): Promise<void> {
    return super.update(pivot.uid.value, pivot.model);
  }

  /**
   * @description Supprime le document passé en paramètre
   * @param {string} uid
   * @returns {Promise<void>}
   * @memberof PivotService
   */
  async remove(uid: string): Promise<void> {
    return super.delete(uid);
  }

}
