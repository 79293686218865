/**
 * @module Role
 */

import { UID } from '@models/uid.model';
import { RolesModel, RoleType } from '@models/role.model';
import { ModificationClass, ModificationStateClass } from '@class/modification.class';
import { DeepCopy } from '@functions/copy.functions';

export class RolesClass extends ModificationClass<RolesModel> {

  protected _parent: RolesModel;
  protected _inital: RolesModel;
  protected _attributes: string[];

  constructor(roles: RolesModel, state: ModificationStateClass) {

    super();
    
    this._parent = roles;
    this._attributes = this.attributes;
    this._state = state;

    this._inital = DeepCopy({ roles: this._parent.roles });

  }

  get class(): Readonly<string> {
    return 'roles';
  }

  get attributes(): string[] {
    return ['roles'];
  }

  get noRole(): Readonly<boolean> {
    return this._parent.roles.length === 0;
  }

  get hasRoles(): Readonly<boolean> {
    return this._parent.roles.length > 0;
  }

  list(): Readonly<UID[]> {
    return this._parent.roles;
  }

  has(role: RoleType): Readonly<boolean> {
    if(role === 't') return this._parent.roles.length > 0;
    else return this._parent.roles.includes(role);
  }

  add(role: RoleType) {
    if(!this.has(role)) {
      this._parent.roles.push(role);
      this.emit(this.attributes);
    }
  }

  remove(role: RoleType) {
    const index = this._parent.roles.indexOf(role);
    if(index !== -1) {
      this._parent.roles.splice(index, 1);
      this.emit(this.attributes);
    }
  }

}