/**
 * @module Map
 */

import { DeleteFieldValue } from '@functions/firebase.functions';

export class MapClass<T> {

  private _model: { [key in string]: T };

  private _elements: Map<string, T>;

  private _firestore: boolean;

  private _added: Map<string, T>;

  private _removed: Map<string, T>;

  private _initial: Map<string, T>;

  constructor(elements: { [key in string]: T } = {}, firestore: boolean = true) {

    this._model = elements;

    this._elements = new Map<string, T>();
    this._added = new Map<string, T>();
    this._removed = new Map<string, T>();
    this._initial = new Map<string, T>();
    this._firestore = firestore;

    for(const key in elements) {
      this._elements.set(key, elements[key]);
      this._initial.set(key, elements[key]);
    }
  }

  add(key: string, value: T) {
    const initial = this._initial.get(key)
    if(!initial) this._added.set(key, value);

    this._elements.set(key, value);
    this._removed.delete(key);

    this._model[key] = value;
  }

  remove(key: string) {
    this._elements.delete(key);
    this._added.delete(key);

    const initial = this._initial.get(key)
    if(initial) this._removed.set(key, initial);

    this._model[key] = this._firestore ? DeleteFieldValue() as T : null;
  }

  get class(): Readonly<string> {
    return 'map';
  }

  get model(): Readonly<{ [key in string]: T }> {
    let model = {};
    this._elements.forEach((value: T, key: string) => {
      model[key] = value;
    });
    this._removed.forEach((value: T, key: string) => {
      model[key] = this._firestore ? DeleteFieldValue() : null;
    });
    return model;
  }

  get keys(): Readonly<string[]> {
    return Array.from(this._elements.keys());
  }

  get values(): Readonly<T[]> {
    return Array.from(this._elements.values());
  }

  get added(): Readonly<T[]> {
    return Array.from(this._added.values());
  }

  get removed(): Readonly<T[]> {
    return Array.from(this._removed.values());
  }

  get initial(): Readonly<T[]> {
    return Array.from(this._initial.values());
  }

  value(key: string): T {
    return this._elements.get(key);
  }

  has(key: string): Readonly<boolean> {
    return this._elements.has(key) && 
    ((this._elements.get(key) as any)._methodName === undefined || (this._elements.get(key) as any)._methodName !== (this._firestore ? 'FieldValue.delete' : null));
  }

  hasAny(keys: string[]): Readonly<boolean> {
    return keys.filter((key: string) => this.has(key)).length > 0;
  }

  get size(): Readonly<number> {
    return this._elements.size;
  }

  isModified(): Readonly<boolean> {
    return this._added.size > 0 || this._removed.size > 0;
  }
}