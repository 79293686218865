/**
 * @module Theme
 */

import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ThemeOptionsClass } from '@class/themeOptions.class';
import { ItemColor } from '@functions/item.functions';
import { ChartType } from '@models/chart.model';
import { Subscription } from 'rxjs';
import { ThemeOptionType } from '@models/themeStudio.model';

@Component({
  selector: 'app-theme-option-elements-chart-rotation',
  templateUrl: './theme-option-elements-chart-rotation.component.html',
  styleUrls: ['./theme-option-elements-chart-rotation.component.css']
})
export class ThemeOptionElementsChartRotationComponent implements OnInit, OnDestroy {

  @Input() themeOptions: ThemeOptionsClass;

  showDetails: boolean;
  color = ItemColor('themes');
  optionType: ThemeOptionType = 'elements_chart_rotation';
  rotation: number;
  rotations: { [key in ChartType]: number };
  changedOptions$sub: Subscription;
  limitedTo: ChartType[];

  constructor() {
    this.showDetails = false;
    this.rotations = {} as { [key in ChartType]: number };
  }

  ngOnInit() {
    this.limitedTo = this.themeOptions.limitedTo('elements_chart_rotation');//.concat(this.themeOptions.limitedTo('elements_chart_startAngle'));
    this.setAttributes();

    this.changedOptions$sub = this.themeOptions.changedOptions$.subscribe({
      next:(option: ThemeOptionType) => {
        if(option === 'elements_chart_rotation') {// || option === 'elements_chart_startAngle') {
          this.setAttributes();
        }
      }
    });
  }

  ngOnDestroy() {
    this.changedOptions$sub.unsubscribe();
  }

  setAttributes() {
    this.rotation = this.themeOptions.options().rotation / Math.PI * 360 / 2;
    this.limitedTo.forEach((type: ChartType) => {
      //if(type === 'radar') this.rotations[type] = this.themeOptions.options(type)['startAngle'];
      //else if(type === 'polarArea') this.rotations[type] = this.themeOptions.options(type)['startAngle'] / Math.PI * 100;
      /*else*/ this.rotations[type] = this.themeOptions.options(type).rotation / Math.PI * 360 / 2;
    });
  }

  receiveValue(value: number, type?: ChartType) {
    if(type !== undefined) {
      //if(type === 'polarArea') this.themeOptions.setOption('elements_chart_startAngle', value / 100 * Math.PI, ['polarArea']);
      //else if(type === 'radar') this.themeOptions.setOption('elements_chart_startAngle', value, ['radar']);
      /*else*/ this.themeOptions.setOption('elements_chart_rotation', value / 360 * Math.PI * 2, type ? [type] : undefined);
    }
    else {
      this.themeOptions.setOption('elements_chart_rotation', value / 360 * Math.PI * 2);
      //this.themeOptions.setOption('elements_chart_startAngle', value, ['radar']);
      //this.themeOptions.setOption('elements_chart_startAngle', value / 100 * Math.PI, ['polarArea']);
    }
  }

  receiveShowDetails(showDetails: boolean) {
    this.showDetails = showDetails;
  }

}
