/**
 * @module Right
 */

import { Component, ViewChild, OnDestroy } from '@angular/core';
import { EditorComponent } from '@components/forms/editor/editor.component';
import { ItemColor, ItemIcon, ItemLabel } from '@functions/item.functions';
import { RightClass } from '@class/right.class';
import { LanguageType } from '@models/language.model';
import { Subscription } from 'rxjs';
import { RightService } from '@services/right/right.service';
import { AuthService } from '@services/auth/auth.service';
import { MessagesService } from '@services/messages/messages.service';
import { UID } from '@models/uid.model';
import { BrowserFrameComponent } from '@components/browser/browser-frame/browser-frame.component';

@Component({
  selector: 'app-rights',
  templateUrl: './rights.component.html',
  styleUrls: ['./rights.component.css']
})
export class RightsComponent implements OnDestroy {

  @ViewChild('editor', { static: true }) editor: EditorComponent;
  @ViewChild('frameModal', { static: true }) frameModal: BrowserFrameComponent;

  label = ItemLabel('rights');
  color = ItemColor('rights');
  icon = ItemIcon('rights');
  current: RightClass;
  language: LanguageType;
  language$sub: Subscription;
  editorIndex: number;
  editorIndexMax: number;
  rights: RightClass[];
  rights$sub: Subscription;
  searchLabel: string;

  constructor(
    private $rights: RightService,
    private $auth: AuthService,
    private $messages: MessagesService
  ) {

    this.language = this.$auth.language$.value;
    this.language$sub = this.$auth.language$.subscribe({
      next: (language: LanguageType) => {
        this.language = language;
      }
    });
    
    this.getList(true);

    this.current = this.$rights.create();
    this.editorIndex = 0;
    this.editorIndexMax = 1;

  }

  getList(forceFullCollection: boolean) {
    if(this.rights$sub) this.rights$sub.unsubscribe();
    this.rights$sub = this.$rights.list$(forceFullCollection).subscribe({
      next: (rights: RightClass[]) => {
        this.rights = rights.sort((a : RightClass, b: RightClass) => { return a.labels.value(this.language).localeCompare(b.labels.value(this.language)) });
      }
    });
  }

  receiveFull(force: boolean) {
    this.getList(force);
  }

  ngOnDestroy() {
    this.language$sub.unsubscribe();
    this.rights$sub.unsubscribe();
  }

  public show() {
    this.frameModal.show();
  }

  trackByUid(index: number, right: RightClass) {
    return right.uid.value;
  }

  receiveCreate() {
    if(this.current.uid.isRegistered) this.current = this.$rights.create();
    this.editor.show();
  }

  receiveEdit(uid: UID) {
    this.current = this.rights.filter((right: RightClass) => {
      return right.uid.value === uid;
    })[0];
    this.editor.show();
  }

  receiveSearch(searchLabel: string) {
    this.searchLabel = searchLabel;
  }

  receiveRestore() {
    this.current.reset();
  }

  receiveDelete() {
    this.editorIndex = NaN;
  }

  receiveDeleteConfirmation() {
    this.$rights.remove(this.current.uid.value)
    .then(() => {
      this.editor.hide();
      this.editorIndex = 0;
      this.$messages.success({ 
        title: { plain: this.current.labels.value(this.language) }, 
        text: { code: 'SUCCESSFULLY_REMOVED/TEXT' } 
      });
    })
    .catch((error) => {
      this.$messages.error({ 
        title: { plain: this.current.labels.value(this.language) }, 
        text: { 
          code: 'REMOVE_FAILED/TEXT',
          params: { error: error.code }
        } 
      });
    });
  }

  receiveNext(isNext: boolean) {
    this.editorIndex = this.editorIndex + (isNext ? 1 : -1);
    if(this.editorIndex < 0) this.editorIndex = this.editorIndexMax;
    if(this.editorIndex > this.editorIndexMax) this.editorIndex = 0;
  }  
  
  receiveSave() {
    this.$rights.set(this.current)
    .then(() => {
      if(!this.current.uid.isRegistered) this.current = this.$rights.create();
      this.editor.hide();
      this.$messages.success({ 
        title: { plain: this.current.labels.value(this.language) }, 
        text: { code: 'SUCCESSFULLY_SAVED/TEXT' } 
      });
    })
    .catch((error) => {
      this.$messages.error({ 
        title: { plain: this.current.labels.value(this.language) }, 
        text: { 
          code: 'SAVE_FAILED/TEXT',
          params: { error: error.code }
        } 
      });
    });
  }

}
