/**
 * @module Comment
 */

import { Component, Input, OnDestroy, OnInit, EventEmitter, Output } from '@angular/core';
import { CommentClass } from '@class/comment.class';
import { AuthService } from '@services/auth/auth.service';
import { LanguageType } from '@models/language.model';
import { Subscription } from 'rxjs';
import { UserClass } from '@class/user.class';
import { UserService } from '@services/user/user.service';
import { take } from 'rxjs/operators';
import bbCodeParser from 'js-bbcode-parser';
import { CommentModel } from '@models/comment.model';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.css']
})
export class CommentComponent implements OnDestroy, OnInit {

  @Input('comment') model: CommentModel;
  @Input() color: string;

  @Output() sendDelete: EventEmitter<CommentClass>;
  @Output() sendEdit: EventEmitter<CommentClass>;

  comment: CommentClass;
  language: LanguageType;
  language$sub: Subscription;
  user: UserClass;
  author: UserClass;
  description: string;
  isEditor: boolean;

  constructor(
    private $auth: AuthService,
    private $users: UserService
  ) {
    this.description = '';
    this.user = this.$auth.user$.value;
    this.sendDelete = new EventEmitter<CommentClass>();
    this.sendEdit = new EventEmitter<CommentClass>();
    this.isEditor = false;
  }

  ngOnInit() {
    this.comment = new CommentClass(this.model);
    this.$users.getPartial$(this.comment.uidAuthor.value).pipe(take(1)).subscribe((user: UserClass) => {
      if(!!user) this.author = user;
    });
    this.language$sub = this.$auth.language$.subscribe({
      next: (language: LanguageType) => {
        this.language = language;
        this.description = bbCodeParser.parse(this.comment.descriptions.value(this.language))
      }
    });
  }

  receiveComment() {
    this.sendEdit.emit(this.comment);
  }

  onEdit() {
    this.isEditor = !this.isEditor
  }

  onDelete() {
    this.sendDelete.emit(this.comment);
  }

  ngOnDestroy() {
    this.language$sub.unsubscribe();
  }

}
