/**
 * @module Dashboard
 */

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DashboardLayoutClass } from '@class/dashboardLayout.class';

@Component({
  selector: 'app-dashboard-layout-editor',
  templateUrl: './dashboard-layout-editor.component.html',
  styleUrls: ['./dashboard-layout-editor.component.css']
})
export class DashboardLayoutEditorComponent {

  @Input() dashboardLayout: DashboardLayoutClass;
  @Output() sendDraggable: EventEmitter<boolean>;
  
  constructor() {
    this.sendDraggable = new EventEmitter<boolean>();
  }

  receiveDraggable(isDraggable: boolean) {
    this.sendDraggable.emit(isDraggable);
  }

}
