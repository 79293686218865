/**
 * @module Theme
 */

import { Component, Input } from '@angular/core';
import { ItemColor } from '@functions/item.functions';
import { ThemeOptionsClass } from '@class/themeOptions.class';
import { ChartType } from '@models/chart.model';
import { ThemeOptionType } from '@models/themeStudio.model';

@Component({
  selector: 'app-theme-option-elements-stepped-line',
  templateUrl: './theme-option-elements-stepped-line.component.html',
  styleUrls: ['./theme-option-elements-stepped-line.component.css']
})
export class ThemeOptionElementsSteppedLineComponent {

  @Input() themeOptions: ThemeOptionsClass;
  @Input() serieIndex: number;

  showDetails: boolean;
  color = ItemColor('themes');
  optionType: ThemeOptionType = 'elements_steppedLine';

  constructor() {
    this.showDetails = false;
  }

  onValue(value: string) {
    this.themeOptions.setSeries(this.optionType, value === 'false' ? false : value, this.serieIndex);
  }

  receiveShowDetails(showDetails: boolean) {
    this.showDetails = showDetails;
  }

  values = {
    before: { icon: 'wave-square', style: 'before', label: 'BEFORE' },
    after: { icon: 'wave-square fa-flip-vertical', style: 'after', label: 'AFTER' },
    middle: { icon: 'wave-square fa-rotate-180', style: 'middle', label: 'MIDDLE' },
    false: { icon: 'times', style: 'false', label: 'WITHOUT' }
  };

}
