/**
 * @module Widget
 */

import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { WidgetLayersClass } from '@class/widgetLayers.class';
import { WidgetLayerClass } from '@class/widgetLayer.class';
import { UID } from '@models/uid.model';
import { Subscription } from 'rxjs';
import { ModificationStateClass } from '@class/modification.class';
import { SortableStopModel } from '@directives/sortable/sortable.directive';

@Component({
  selector: 'app-widget-layers-list',
  templateUrl: './widget-layers-list.component.html',
  styleUrls: ['./widget-layers-list.component.css']
})
export class WidgetLayersListComponent implements OnInit {

  @Input() widgetLayers: WidgetLayersClass;
  @Input() selected: UID;
  @Output() sendCurrent: EventEmitter<UID>;

  layers: WidgetLayerClass[];
  modifications$sub: Subscription;

  constructor() {
    this.sendCurrent = new EventEmitter<UID>();
  }

  ngOnInit() {
    this.layers = Object.values(this.widgetLayers.layers).sort((a, b) => a.rank - b.rank);
    this.modifications$sub = this.widgetLayers.modifications$.subscribe({
      next:(modifications: ModificationStateClass) => {
        if(modifications.has('layers') || modifications.has('reload')) {
          this.layers = Object.values(this.widgetLayers.layers).sort((a, b) => a.rank - b.rank);
        }
      }
    });
  }

  receiveEdited(uid: UID) {
    this.sendCurrent.emit(uid);
  }

  receiveSelected(uid: UID) {
    this.widgetLayers.layer(uid).change({ drillable: !this.widgetLayers.layer(uid).drillable });
  }

  receiveSortableStop(sortable: SortableStopModel) {
    this.widgetLayers.rankLayer(sortable.sourceIndex, sortable.targetIndex);
  }

}
