/**
 * @module Import
 */

import { Component, OnDestroy, Input, ViewChild, OnInit, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { FiltersPipeModel } from '@pipes/filters/filters.pipe';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { LanguageType } from '@models/language.model';
import { ItemColor, ItemsIcons } from '@functions/item.functions';
import { DataFrameDetectValuesModel, DataFrameAggregationsModel, DataFrameItemType } from '@models/dataFrame.model';
import { AuthService } from '@services/auth/auth.service';
import { ImportFrameClass } from '@class/importFrame.class';
import { LabelsClass } from '@class/labels.class';
import { PivotClass } from '@class/pivot.class';
import { DateClass } from '@class/date.class';
import { IndicatorClass } from '@class/indicator.class';
import { PivotService } from '@services/pivot/pivot.service';
import { DateService } from '@services/date/date.service';
import { IndicatorService } from '@services/indicator/indicator.service';
import { SetLabels } from '@functions/labels.functions';

@Component({
  selector: 'app-import-item-params',
  templateUrl: './import-item-params.component.html',
  styleUrls: ['./import-item-params.component.css']
})
export class ImportItemParamsComponent implements OnDestroy, OnInit, OnChanges {
  
  @Input() importFrame: ImportFrameClass;
  @Input() key: string;
  @Input() filters$: BehaviorSubject<{ [key: string]: { [key: string]: FiltersPipeModel } }>;
  @Output() sendRemoved: EventEmitter<string>;

  @ViewChild('detectValuesModal', { static: true }) detectValuesModal: ModalDirective;

  language$sub: Subscription;
  language: LanguageType;

  color = ItemColor('indicators');
  icons = ItemsIcons();
  icon: string;
  labels: LabelsClass;

  unselected: { [key: string]: { [key: string]: boolean } };
  detects: DataFrameDetectValuesModel[];
  aggregations: DataFrameAggregationsModel;
  format: string;
  modifiedFormat: string
  type: DataFrameItemType;
  filters: { [key: string]: { [key: string]: FiltersPipeModel } };
  allSelected: boolean;
  showErrors: boolean;
  valuesChanged$: Subscription;

  constructor(
    private $auth: AuthService,    
    private $pivot: PivotService,
    private $date: DateService,
    private $indicator: IndicatorService

  ) {
    this.unselected = {};
    this.filters = {};
    this.allSelected = true;
    this.showErrors = false;

    this.sendRemoved = new EventEmitter<string>();

    this.language$sub = this.$auth.language$.subscribe({
      next: (language: LanguageType) => {
        this.language = language;
      }
    });
  }

  ngOnDestroy() {
    this.language$sub.unsubscribe();
    if(this.valuesChanged$) this.valuesChanged$.unsubscribe();
  }

  onShowErrors() {
    this.showErrors = !this.showErrors;
  }

  receiveSelectedLink(item: PivotClass | DateClass | IndicatorClass) {
    const uid = this.importFrame.item({ key: this.key }).uid.value;
    if(item.class === 'pivot') {
      if(!item.uid.isRegistered) {
        item = this.$pivot.create({
          labels: SetLabels(this.key),
          linkedColumns: [this.key]
        });
      }
      this.importFrame.setPivot(uid, item as PivotClass);
    }
    else if(item.class === 'date') {
      if(!item.uid.isRegistered) {
        item = this.$date.create({
          labels: SetLabels(this.key),
          linkedColumns: [this.key],
          dateFormats: [this.importFrame.dataFrame.types(this.key).format]
        });
      }
      this.importFrame.setDate(uid, item as DateClass);
    }
    else if(item.class === 'indicator') {
      if(!item.uid.isRegistered) {
        item = this.$indicator.create({
          labels: SetLabels(this.key),
          linkedColumns: [this.key]
        });
      }
      this.importFrame.indicator = item as IndicatorClass;
    }
  }

  ngOnInit() {
    this.setParams();
    if(this.valuesChanged$) this.valuesChanged$.unsubscribe();
    this.valuesChanged$ = this.importFrame.dataFrame.valuesChanged$.subscribe((change: boolean) => {
      this.showErrors = this.showErrors ? this.importFrame.dataFrame.isErrorsForKey(this.key) : this.showErrors;
      this.setParams();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.key !== undefined && !changes.key.firstChange) this.setParams();
  }

  setParams() {
    if(this.importFrame !== undefined && this.importFrame.dataFrame.values.length > 0) {
      if(this.unselected[this.key] === undefined) this.unselected[this.key] = {};
      this.aggregations = this.importFrame.dataFrame.aggregations(this.key);
      this.type = this.importFrame.dataFrame.types(this.key).item;
      this.format = this.importFrame.dataFrame.types(this.key).format;
      this.icon = this.icons[this.type];
      this.detects = this.importFrame.dataFrame.detectValuesForKey(this.key, 'frequency', true);
      this.labels = this.importFrame.item({ key: this.key }).labels;
    }
  }

  receiveAllSelected() {
    this.allSelected = !this.allSelected;
    if(this.allSelected) {
      this.unselected[this.key] = {};
      this.filters[this.key] = {};
    }
    else {
      if(this.filters[this.key] === undefined) this.filters[this.key] = {};
      this.detects.forEach((detect: DataFrameDetectValuesModel) => {
        this.unselected[this.key][detect.value] = true;
        this.filters[this.key][detect.value] = this.setFilter(detect);
      });
    }
    this.filters$.next(this.filters);
  }

  onFormat(format: string) {
    this.modifiedFormat = format;
  }

  onRestore() {
    this.modifiedFormat = this.importFrame.dataFrame.types(this.key).format;
  }

  onValid() {
    this.importFrame.dataFrame.setFormat(this.key, this.modifiedFormat);
    if(!this.importFrame.dataFrame.isErrorsForKey(this.key)) this.showErrors = false;
  }

  trackByValue(index: number, detect: DataFrameDetectValuesModel) {
    return detect.value;
  }

  receiveEdited(edit: { key: string, search: string, replace: string }) {
    this.importFrame.dataFrame.setDetectValueForKey(edit.key, edit.search, edit.replace);
    this.detects = this.importFrame.dataFrame.detectValuesForKey(this.key, 'frequency', true);
    if(!this.importFrame.dataFrame.isErrorsForKey(this.key)) this.showErrors = false;
  }

  receiveSelected(selected: boolean, detect: DataFrameDetectValuesModel) {
    if(selected && this.filters[this.key] !== undefined) {
      delete this.filters[this.key][detect.value];
      delete this.unselected[this.key][detect.value];
    }
    else {
      if(this.filters[this.key] === undefined) this.filters[this.key] = {};
      this.unselected[this.key][detect.value] = true;
      this.filters[this.key][detect.value] = this.setFilter(detect);
    }
    this.filters$.next(this.filters);
  }

  receiveRemove(detect: DataFrameDetectValuesModel) {
    if(this.filters[this.key] !== undefined) this.receiveSelected(true, detect);
    this.importFrame.dataFrame.removeDetectValue(this.key, detect.value);
    if(this.importFrame.dataFrame.values.length === 0) this.sendRemoved.emit(this.importFrame.indicator.uid.value);
  }

  private setFilter(detect: DataFrameDetectValuesModel): FiltersPipeModel {
    return {
      field: this.key,
      type: 'string',
      requirements: {
        value: {
          comparaison: '!=',
          reference: detect.value
        }
      }
    };
  }

}