<app-widget-option [categories]="['LAYERS']" [label]="'SORT'" [sublabel]="showIndicators ? 'INDICATOR' : undefined">
  <div app-widget-option-content>
    <div class="btn-group" mdbDropdown>
      <a mdbDropdownToggle type="button" mdbBtn size="sm" color="light" class="dropdown-toggle waves-light z-depth-0 px-3 dropdown-light" mdbWavesEffect>
        <span class="{{color}}-text font-weight-bolder">{{ widgetLayers.layer(current).sort | uppercase | translate }}</span>&nbsp;
      </a>
      <div class="dropdown-menu dropdown-dark dropdown-menu-right font-small app-drop-small-limit-5">
        <a *ngFor="let sort of sortTypes" class="dropdown-item z-depth-0" (click)="onValue(sort)">
          {{ sort | uppercase | translate }}
        </a>
      </div>
    </div>
  </div>
  <div app-widget-option-subcontent>
    <div class="btn-group p-2" mdbDropdown *ngIf="showIndicators">
      <a mdbDropdownToggle type="button" mdbBtn size="sm" color="light" class="dropdown-toggle waves-light z-depth-0 px-3 dropdown-light" mdbWavesEffect>
        <span class="{{color}}-text font-weight-bolder">{{ currentLabel.value(language) | uppercase | translate }}</span>&nbsp;
      </a>
      <div class="dropdown-menu dropdown-dark dropdown-menu-right font-small app-drop-small-limit-5">
        <a *ngFor="let indicator of widgetLayers.indicators" class="dropdown-item z-depth-0" (click)="onSortValue(indicator.uid.value)">
          {{ indicator.labels.value(language) | uppercase | translate }}
        </a>
      </div>
    </div>
  </div>
</app-widget-option>