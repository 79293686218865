/**
 * @module Color
 */

import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ColorClass } from '@class/color.class';
import { Brightness } from '@functions/color.functions';
import { BrightnessType, ColorStockModel } from '@models/color.model';
import { ColorsService } from '@services/colors/colors.service';


@Component({
  selector: 'app-colors',
  templateUrl: './colors.component.html',
  styleUrls: ['./colors.component.css']
})
export class ColorsComponent implements OnInit {

  @Input() color: ColorClass;
  @Output() sendColor: EventEmitter<string>;

  colors: ColorStockModel[];
  brightness: BrightnessType;

  constructor(
    private $colors: ColorsService
  ) { 
    this.colors = this.$colors.list();
    this.sendColor = new EventEmitter<string>();
  }

  ngOnInit() {
    this.brightness = Brightness(this.color.hexa);
  }

  onSelect(hexa: string) {
    this.brightness = Brightness(hexa);
    this.color.change({ color: { hexa : hexa.replace('#', '') } });
    this.sendColor.emit(hexa.replace('#', ''));
  }
}