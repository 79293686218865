/**
 * @module Dashboard
 */

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DashboardLayoutCellClass } from '@class/dashboardLayoutCell.class';
import { UID } from '@models/uid.model';

@Component({
  selector: 'app-dashboard-cell-remove',
  templateUrl: './dashboard-cell-remove.component.html',
  styleUrls: ['./dashboard-cell-remove.component.css']
})
export class DashboardCellRemoveComponent  {

  @Input() cell: DashboardLayoutCellClass;
  @Output() sendRemove: EventEmitter<UID>;

  constructor() {
    this.sendRemove = new EventEmitter<UID>();
  }

  onRemove() {
    this.sendRemove.emit(this.cell.uid.value);
  }

}
