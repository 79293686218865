/**
 * @module Server
 */

import { Component, ViewChild, OnDestroy } from '@angular/core';
import { EditorComponent } from '@components/forms/editor/editor.component';
import { ItemColor, ItemIcon, ItemLabel } from '@functions/item.functions';
import { ServerClass } from '@class/server.class';
import { LanguageType } from '@models/language.model';
import { Subscription } from 'rxjs';
import { ServerService } from '@services/server/server.service';
import { AuthService } from '@services/auth/auth.service';
import { MessagesService } from '@services/messages/messages.service';
import { UID } from '@models/uid.model';
import { BrowserFrameComponent } from '@components/browser/browser-frame/browser-frame.component';
import { ServerUrlModel } from '@models/server.model';

@Component({
  selector: 'app-servers',
  templateUrl: './servers.component.html',
  styleUrls: ['./servers.component.css']
})
export class ServersComponent implements OnDestroy {

  @ViewChild('editor', { static: true }) editor: EditorComponent;
  @ViewChild('frameModal', { static: true }) frameModal: BrowserFrameComponent;

  label = ItemLabel('servers');
  color = ItemColor('servers');
  icon = ItemIcon('servers');
  current: ServerClass;
  language: LanguageType;
  language$sub: Subscription;
  editorIndex: number;
  editorIndexMax: number;
  servers: ServerClass[];
  servers$sub: Subscription;
  uidMain: UID;
  searchLabel: string;

  constructor(
    private $servers: ServerService,
    private $auth: AuthService,
    private $messages: MessagesService
  ) {
    
    if(this.servers$sub) this.servers$sub.unsubscribe();
    this.servers$sub = this.$servers.list$().subscribe({
      next: (servers: ServerClass[]) => {
        this.servers = servers;
      }
    });

    this.language$sub = this.$auth.language$.subscribe({
      next: (language: LanguageType) => {
        this.language = language;
      }
    });

    this.current = this.$servers.create();
    this.editorIndex = 0;
    this.editorIndexMax = 2;

    this.$auth.server$.subscribe({
      next: (server: ServerUrlModel) => {
        this.uidMain = server.uid;
      }
    });
    
  }

  ngOnDestroy() {
    this.language$sub.unsubscribe();
    this.servers$sub.unsubscribe();
  }

  public show() {
    this.frameModal.show();
  }

  trackByUid(index: number, server: ServerClass) {
    return server.uid.value;
  }

  receiveCreate() {
    if(this.current.uid.isRegistered) this.current = this.$servers.create();
    this.editor.show();
  }

  receiveEdit(uid: UID) {
    this.current = this.servers.filter((server: ServerClass) => {
      return server.uid.value === uid;
    })[0];
    this.editor.show();
  }

  receiveSearch(searchLabel: string) {
    this.searchLabel = searchLabel;
  }

  receiveRestore() {
    this.current.reset();
  }

  receiveDelete() {
    this.editorIndex = NaN;
  }

  receiveDeleteConfirmation() {
    this.$servers.remove(this.current.uid.value)
    .then(() => {
      this.editor.hide();
      this.editorIndex = 0;
      this.$messages.success({ 
        title: { plain: this.current.labels.value(this.language) }, 
        text: { code: 'SUCCESSFULLY_REMOVED/TEXT' } 
      });
    })
    .catch((error) => {
      this.$messages.error({ 
        title: { plain: this.current.labels.value(this.language) }, 
        text: { 
          code: 'REMOVE_FAILED/TEXT',
          params: { error: error.code }
        } 
      });
    });
  }

  receiveNext(isNext: boolean) {
    this.editorIndex = this.editorIndex + (isNext ? 1 : -1);
    if(this.editorIndex < 0) this.editorIndex = this.editorIndexMax;
    if(this.editorIndex > this.editorIndexMax) this.editorIndex = 0;
  }  
  
  receiveSave() {
    this.$servers.set(this.current)
    .then(() => {
      if(!this.current.uid.isRegistered) this.current = this.$servers.create();
      this.editor.hide();
      this.$messages.success({ 
        title: { plain: this.current.labels.value(this.language) }, 
        text: { code: 'SUCCESSFULLY_SAVED/TEXT' } 
      });
    })
    .catch((error) => {
      this.$messages.error({ 
        title: { plain: this.current.labels.value(this.language) }, 
        text: { 
          code: 'SAVE_FAILED/TEXT',
          params: { error: error.code }
        } 
      });
    });
  }

}
