/**
 * @module Backend
 */

import { Injectable } from '@angular/core';
import { TokenService } from '@services/token/token.service';
import { AuthService } from '@services/auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { ServerUrlModel, ServerResponseModel } from '@models/server.model';
import { throwError } from 'rxjs';
import { HttpOptions } from '@functions/server.functions';
import { take, map } from 'rxjs/operators';
import { HandleErrors } from '@functions/errors.functions';
import { UID } from '@models/uid.model';
import { ENVIRONMENT } from '@consts/params.const';

export const CHUNCK_IMPORT_FRAME_SIZE = 1000;
const ROUTE = 'indexes';

@Injectable({
  providedIn: 'root'
})
export class BackendIndexesService {

  constructor(
    private $token: TokenService,
    private $auth: AuthService,
    private $http: HttpClient,
  ) {}

  /*async post(uidIndicator: UID, mainDate: string, indexes: string[], server: ServerUrlModel): Promise<ServerResponseModel> {
        
    let tokenData = await this.$token.get(server).catch((error) => { 
      return throwError('OBJECT_NOT_FOUND/SERVER').toPromise();
    });
    if(tokenData) {
      return this.$http.post<ServerResponseModel>(`https://${server.url}/${ROUTE}`,
        { collection: uidIndicator, mainDate: mainDate, indexes: indexes }, 
        HttpOptions(this.$auth.token$.value.token, tokenData.token)
      ).pipe(
        take(1),
        map((response: ServerResponseModel) => {
          if(ENVIRONMENT.type !== 'production') {
            if(response.success) {
              console.log(`Backend POST ${ROUTE} [${uidIndicator}] ['success'] ${response.message} indexes`);
            }
            else {
              console.log(`Backend POST ${ROUTE} [${uidIndicator}] ['error'] ${response.message}`);
            }
          }
          return response;
        }),
        HandleErrors()
      ).toPromise();
    }
  }*/

  /*async get(uidIndicator: UID, server: ServerUrlModel): Promise<ServerResponseModel> {
    
    let tokenData = await this.$token.get(server).catch((error) => { 
      return throwError('OBJECT_NOT_FOUND/SERVER').toPromise();
    });
    if(tokenData) {
      return this.$http.get<ServerResponseModel>(`https://${server.url}/${ROUTE}`,
        HttpOptions(this.$auth.token$.value.token, tokenData.token, { collection: uidIndicator })
      ).pipe(
        take(1),
        map((response: ServerResponseModel) => {
          if(ENVIRONMENT.type !== 'production') {
            if(response.success) {
              console.groupCollapsed(`Backend GET ${ROUTE} [${uidIndicator}] ['success'] ${response.message.length} indexes`);
              console.log(response.message);
              console.groupEnd();
            }
            else {
              console.log(`Backend GET ${ROUTE} [${uidIndicator}] ['error'] ${response.message}`);
            }
          }
          return response;
        }),
        HandleErrors()
      ).toPromise();
    }
  }*/

  /*async delete(uidIndicator: UID, server: ServerUrlModel): Promise<ServerResponseModel> {
    
    let tokenData = await this.$token.get(server).catch((error) => { 
      return throwError('OBJECT_NOT_FOUND/SERVER').toPromise();
    });
    if(tokenData) {
      return this.$http.delete<ServerResponseModel>(`https://${server.url}/${ROUTE}`,
        HttpOptions(this.$auth.token$.value.token, tokenData.token, { collection: uidIndicator })
      ).pipe(
        take(1),
        map((response: ServerResponseModel) => {
          if(ENVIRONMENT.type !== 'production') {
            if(response.success) console.log(`Backend DELETE ${ROUTE} [${uidIndicator}] ['success'] ${response.message} indexes`);
            else console.log(`Backend DELETE ${ROUTE} [${uidIndicator}] ['error'] ${response.message}`);
          }
          return response;
        }),
        HandleErrors()
      ).toPromise();
    }
  }*/
}