<div class="d-flex align-items-center justify-content-between mt-3" style="height: 31px;">
  <div class="blue-grey-text font-weight-bold d-flex align-items-center">
    <i class="fa-lg fa-fw far fa-analytics {{color}}-text mr-1"></i>
    <div class="mx-1">{{ 'SERIE' | translate }}</div>
    <mdb-badge info="true" class="z-depth-0">#&nbsp;{{ current.index }}</mdb-badge>
  </div>
  <div class="btn-group" mdbDropdown>
    <button mdbDropdownToggle type="button" size="sm" mdbBtn color="light" class="blue-grey-text z-depth-0 dropdown-toggle waves-light d-flex align-items-center px-3" mdbWavesEffect>
      <i class="far fa-bars"></i>&nbsp;
    </button>
  
    <div class="dropdown-menu dropdown-dark dropdown-menu-right">

      <a class="dropdown-item z-depth-0" (click)="editorIndex = 0">{{ 'POINT' | translate }}</a>
      <a class="dropdown-item z-depth-0" (click)="editorIndex = 1">{{ 'POINT_HOVER' | translate }}</a>
      <a class="dropdown-item z-depth-0" (click)="editorIndex = 2">{{ 'SERIE' | translate }}</a>
      <a class="dropdown-item z-depth-0" (click)="editorIndex = 3">{{ 'SERIE_HOVER' | translate }}</a>
      <a class="dropdown-item z-depth-0" (click)="editorIndex = 4">{{ 'SERIE_BORDER' | translate }}</a>
      <a class="dropdown-item z-depth-0" (click)="editorIndex = 5">{{ 'SERIE_LINE' | translate }}</a>
      <a class="dropdown-item z-depth-0" (click)="editorIndex = 6">{{ 'ICON' | translate }}</a>

    </div>
  </div>
</div>

<hr>

<div [ngSwitch]="editorIndex">
  <div *ngSwitchCase="0">
    <app-editor-section-title [title]="'POINT'" (sendNext)="receiveNext($event)"></app-editor-section-title>

    <app-theme-option-elements-point-style [themeOptions]="themeOptions" [serieIndex]="current.index"></app-theme-option-elements-point-style>
    <app-theme-option-elements-point-rotation [themeOptions]="themeOptions" [serieIndex]="current.index"></app-theme-option-elements-point-rotation>

    <app-theme-option-elements-point-radius [themeOptions]="themeOptions" [serieIndex]="current.index"></app-theme-option-elements-point-radius>
    <app-theme-option-elements-point-background-color [themeOptions]="themeOptions" [serieIndex]="current.index"></app-theme-option-elements-point-background-color>
    <app-theme-option-elements-point-border-width [themeOptions]="themeOptions" [serieIndex]="current.index"></app-theme-option-elements-point-border-width>
    <app-theme-option-elements-point-border-color [themeOptions]="themeOptions" [serieIndex]="current.index"></app-theme-option-elements-point-border-color>

  </div>
  <div *ngSwitchCase="1">
    <app-editor-section-title [title]="'POINT_HOVER'" (sendNext)="receiveNext($event)"></app-editor-section-title>

    <app-theme-option-elements-point-hover-radius [themeOptions]="themeOptions" [serieIndex]="current.index"></app-theme-option-elements-point-hover-radius>
    <app-theme-option-elements-point-hover-background-color [themeOptions]="themeOptions" [serieIndex]="current.index"></app-theme-option-elements-point-hover-background-color>
    <app-theme-option-elements-point-hover-border-width [themeOptions]="themeOptions" [serieIndex]="current.index"></app-theme-option-elements-point-hover-border-width>
    <app-theme-option-elements-point-hover-border-color [themeOptions]="themeOptions" [serieIndex]="current.index"></app-theme-option-elements-point-hover-border-color>

  </div>
  <div *ngSwitchCase="2">
    <app-editor-section-title [title]="'SERIE'" (sendNext)="receiveNext($event)"></app-editor-section-title>

    <!-- MARCHE PAS : app-theme-option-elements-hide-in-legend-and-tooltip [themeOptions]="themeOptions" [serieIndex]="current.index"></app-theme-option-elements-hide-in-legend-and-tooltip -->
    <app-theme-option-elements-datalabels-id [themeOptions]="themeOptions" [serieIndex]="current.index"></app-theme-option-elements-datalabels-id>
    <app-theme-option-elements-xaxis-id [themeOptions]="themeOptions" [serieIndex]="current.index"></app-theme-option-elements-xaxis-id>
    <app-theme-option-elements-yaxis-id [themeOptions]="themeOptions" [serieIndex]="current.index"></app-theme-option-elements-yaxis-id>

    <app-theme-option-elements-weight [themeOptions]="themeOptions" [serieIndex]="current.index"></app-theme-option-elements-weight>
    <app-theme-option-elements-hidden [themeOptions]="themeOptions" [serieIndex]="current.index"></app-theme-option-elements-hidden>
  
    <app-theme-option-elements-background-color [themeOptions]="themeOptions" [serieIndex]="current.index"></app-theme-option-elements-background-color>
    <app-theme-option-elements-background-multi-gradiant [themeOptions]="themeOptions" [serieIndex]="current.index"></app-theme-option-elements-background-multi-gradiant>

  </div>
  <div *ngSwitchCase="3">
    <app-editor-section-title [title]="'SERIE_HOVER'" (sendNext)="receiveNext($event)"></app-editor-section-title>

    <!-- MARCHE PAS : app-theme-option-elements-hover-border-dash [themeOptions]="themeOptions" [serieIndex]="current.index"></app-theme-option-elements-hover-border-dash -->
    <app-theme-option-elements-hover-background-color [themeOptions]="themeOptions" [serieIndex]="current.index"></app-theme-option-elements-hover-background-color>
    <app-theme-option-elements-hover-border-width [themeOptions]="themeOptions" [serieIndex]="current.index"></app-theme-option-elements-hover-border-width>
    <app-theme-option-elements-hover-border-color [themeOptions]="themeOptions" [serieIndex]="current.index"></app-theme-option-elements-hover-border-color>
    
  </div>
  <div *ngSwitchCase="4">
    <app-editor-section-title [title]="'SERIE_BORDER'" (sendNext)="receiveNext($event)"></app-editor-section-title>

    <app-theme-option-elements-border-width [themeOptions]="themeOptions" [serieIndex]="current.index"></app-theme-option-elements-border-width>
    <app-theme-option-elements-border-color [themeOptions]="themeOptions" [serieIndex]="current.index"></app-theme-option-elements-border-color>
    <app-theme-option-elements-border-multi-gradiant [themeOptions]="themeOptions" [serieIndex]="current.index"></app-theme-option-elements-border-multi-gradiant>
    <app-theme-option-elements-border-align [themeOptions]="themeOptions" [serieIndex]="current.index"></app-theme-option-elements-border-align>
    <app-theme-option-elements-border-dash [themeOptions]="themeOptions" [serieIndex]="current.index"></app-theme-option-elements-border-dash>
    <app-theme-option-elements-border-cap-style [themeOptions]="themeOptions" [serieIndex]="current.index"></app-theme-option-elements-border-cap-style>
    <app-theme-option-elements-border-join-style [themeOptions]="themeOptions" [serieIndex]="current.index"></app-theme-option-elements-border-join-style>

  </div>
  <div *ngSwitchCase="5">
    <app-editor-section-title [title]="'SERIE_LINE'" (sendNext)="receiveNext($event)"></app-editor-section-title>

    <app-theme-option-elements-span-gaps [themeOptions]="themeOptions" [serieIndex]="current.index"></app-theme-option-elements-span-gaps>
    <app-theme-option-elements-fill [themeOptions]="themeOptions" [serieIndex]="current.index"></app-theme-option-elements-fill>
    <app-theme-option-elements-line-tension [themeOptions]="themeOptions" [serieIndex]="current.index"></app-theme-option-elements-line-tension>
    <app-theme-option-elements-stepped-line [themeOptions]="themeOptions" [serieIndex]="current.index"></app-theme-option-elements-stepped-line>

  </div>
  <div *ngSwitchCase="6">
    <app-editor-section-title [title]="'ICON'" (sendNext)="receiveNext($event)"></app-editor-section-title>

    <app-theme-option-elements-icon-frame [themeOptions]="themeOptions" [serieIndex]="current.index"></app-theme-option-elements-icon-frame>

  </div>
</div>