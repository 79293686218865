<app-browser-navbar [showFrames]="false" [showBack]="true">

  <li app-browser-navbar-content class="nav-item sm-ml-2 ml-1" (click)="setSlides()">
    <a class="nav-link waves-light" mdbWavesEffect><i class="fa-fw fa-presentation fad {{ slides ? color : '' }}-text"></i>
    <span class="ml-1 d-sm-none d-inline">{{ 'SLIDES' | translate }}</span></a>
  </li>
  <li app-browser-navbar-content class="nav-item sm-ml-2 ml-1" (click)="setFrame('dashboard')" *ngIf="isSmallWidth">
    <a class="nav-link waves-light" mdbWavesEffect><i class="fa-fw {{ icon }} {{ show.dashboard ? color : '' }}-text"></i>
    <span class="ml-1 d-sm-none d-inline">{{ 'DASHBOARD' | translate }}</span></a>
  </li>
  <li app-browser-navbar-content class="nav-item sm-ml-2 ml-1" (click)="setFrame('params')">
    <a class="nav-link waves-light" mdbWavesEffect><i class="fa-fw fad fa-window-maximize fa-rotate-90 {{ show.params ? color : '' }}-text"></i>
    <span class="ml-1 d-sm-none d-inline">{{ 'SETTINGS' | translate }}</span></a>
  </li>

  <ng-content select="[app-dashboard-display-frames-browser-navbar-content]"></ng-content>

</app-browser-navbar>
 
<div class="d-flex app-dashboard-display-frames-frame-container">

  <div class="app-dashboard-display-frames-frame-item white flex-grow-1" *ngIf="show.dashboard">
    <ng-content select="[app-dashboard-display-frames-item-content]"></ng-content>
  </div>

  <div class="app-dashboard-display-frames-frame-sub p-3 white" *ngIf="show.params">
    <ng-content select="[app-dashboard-display-frames-sub-content]"></ng-content>
  </div>
</div>