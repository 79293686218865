/**
 * @module Widget
 */

import { PivotFiltersModel } from '@models/widgetLayers.model';
import { UidModel } from '@models/uid.model';
import { ModificationClass, ModificationStateClass } from '@class/modification.class';
import { UidClass } from '@class/uid.class';
import { DeepCopy } from '@functions/copy.functions';
import { BehaviorSubject } from 'rxjs';

export class PivotFiltersClass extends ModificationClass<PivotFiltersModel> {
  
  private _uid: UidClass;

  protected _parent: PivotFiltersModel;
  protected _inital: PivotFiltersModel;
  protected _attributes: string[];

  constructor(widgetFilters: PivotFiltersModel, state: ModificationStateClass, modifications$: BehaviorSubject<ModificationStateClass>, lastModification$: BehaviorSubject<string>) {

    super();

    this._parent = widgetFilters;
    this._state = state;
    this._modifications$ = modifications$;
    this._lastModification$ = lastModification$

    if(this._parent === undefined) this._parent = {} as PivotFiltersModel;

    if(this._parent.include === undefined) this._parent.include = true;
    if(this._parent.values === undefined) this._parent.values = [];

    this._reset(this._parent);

    this._attributes = this.attributes.slice();
    this._attributes.push(...this._uid.attributes);

  }

  private _reset(widgetFilters: PivotFiltersModel) {
    this._uid = new UidClass(widgetFilters as UidModel, true);
    
    this.attributes.forEach((attribute: string) => {
      this._parent[attribute] = widgetFilters[attribute];
    });

    this._inital = DeepCopy(widgetFilters);

    this._state.flush();
    this._modifications$.next(this._state);
  }

  reset() {
    this._state.flush();
    this._reset(this._inital);
  }

  get attributes(): Readonly<string[]> {
    return ['include', 'values'];
  }

  get class(): string {
    return 'widgetFilters';
  }

  get uid(): UidClass {
    return this._uid;
  }

  get include(): Readonly<boolean> {
    return this._parent.include;
  }

  get values(): Readonly<string[]> {
    return this._parent.values;
  }

  flush() {
    this._parent.values = [];
    this.emit(['reload', 'filters']);
    this._lastModification$.next('filters');
    this._lastModification$.next('reload');
  }

  reload() {
    this.emit(['reload', 'filters']);
    this._lastModification$.next('filters');
    this._lastModification$.next('reload');
  }

  setValue(value: string) {
    const index = this._parent.values.indexOf(value);
    if(index === -1) this._parent.values.push(value);
    else this._parent.values.splice(index, 1);
    this.emit(['reload', 'filters']);
    this._lastModification$.next('filters');
    this._lastModification$.next('reload');
  }

  setInclude(include: boolean) {
    this._parent.include = include;
    if(this._parent.values.length === 0) {
      //pas de reload nécéssaire si pas de valeurs
      this.emit(['filters']);
    }
    else {
      this.emit(['reload', 'filters']);
      this._lastModification$.next('reload');
    }
    this._lastModification$.next('filters');
  }

  has(value: string): Readonly<boolean> {
    return this._parent.values.indexOf(value) !== -1;
  }

}