/**
 * @module Theme
 */

import { Component, Input } from '@angular/core';
import { ItemColor } from '@functions/item.functions';
import { ThemeOptionsClass } from '@class/themeOptions.class';

@Component({
  selector: 'app-theme-options-tooltips',
  templateUrl: './theme-options-tooltips.component.html',
  styleUrls: ['./theme-options-tooltips.component.css']
})
export class ThemeOptionsTooltipsComponent {

  @Input() themeOptions: ThemeOptionsClass;
  
  color = ItemColor('themes');

  editorIndex: number;
  editorIndexMax: number;

  constructor() {
    this.editorIndex = 0;
    this.editorIndexMax = 7;
  }

  receiveNext(isNext: boolean) {
    this.editorIndex = this.editorIndex + (isNext ? 1 : -1);
    if(this.editorIndex < 0) this.editorIndex = this.editorIndexMax;
    if(this.editorIndex > this.editorIndexMax) this.editorIndex = 0;
  } 

}
