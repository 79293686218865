/**
 * @module Server
 */

import { Component, OnInit, Input, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { ServerClass } from '@class/server.class';
import { UID } from '@models/uid.model';
import { ModificationStateClass } from '@class/modification.class';
import { Subscription } from 'rxjs';
import { ServerUrlModel } from '@models/server.model';
import { AuthService } from '@services/auth/auth.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit, OnChanges, OnDestroy {
  
  @Input() server: ServerClass;

  main: boolean;
  uid: UID;
  modifications$sub: Subscription;

  constructor(
    private $auth: AuthService
  ) {}

  ngOnInit() {
    this.$auth.server$.subscribe({
      next: (server: ServerUrlModel) => {
        this.uid = server.uid;
        if(!!this.uid) this.main = this.server.uid.value === this.uid;
        else this.main = this.server.main;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.server !== undefined && !changes.server.firstChange) {
      if(this.modifications$sub) this.modifications$sub.unsubscribe();
      this.modifications$sub = this.server.modifications$.subscribe({
        next: (state: ModificationStateClass) => {
          if(!state.isModified) {
            if(!!this.uid) this.main = this.server.uid.value === this.uid;
            else this.main = this.server.main;
          }
        }
      });
    }
  }

  ngOnDestroy() {
    if(this.modifications$sub) this.modifications$sub.unsubscribe();
  }

  onMain() {
    this.main = true;
    this.server.change({ main: true });
  }

}