/**
 * @module Helper
 */

import { Component, ViewChild } from '@angular/core';
import { HelperComponent } from '@components/helpers/helper/helper.component';

@Component({
  selector: 'app-helper-date-format',
  templateUrl: './helper-date-format.component.html',
  styleUrls: ['./helper-date-format.component.css']
})
export class HelperDateFormatComponent {

  @ViewChild('helper', { static: true }) modal: HelperComponent;

  constructor() {}

  public show() {
    this.modal.show();
  }

  public hide() {
    this.modal.hide();
  }

}
