/** 
 * @module Color
 */

export default [
  { 
    "label": "MAIN/COLOR",
    "list": [
      { "label": "BLACK/COLOR", "hexa": "#000000" },
      { "label": "WHITE/COLOR", "hexa": "#ffffff" },
      { "label": "RED/COLOR", "hexa": "#f44336" },
      { "label": "PINK/COLOR", "hexa": "#e91e63" },
      { "label": "PURPLE/COLOR", "hexa": "#9c27b0" },
      { "label": "DARK_PURPLE/COLOR", "hexa": "#673ab7" },
      { "label": "INDIGO/COLOR", "hexa": "#3f51b5" },
      { "label": "DEEP_BLUE/COLOR", "hexa": "#033485" },
      { "label": "BLUE/COLOR", "hexa": "#2196f3" },
      { "label": "LIGHT_BLUE/COLOR", "hexa": "#03a9f4" },
      { "label": "CYAN/COLOR", "hexa": "#00bcd4" },
      { "label": "BLUE_DUCK/COLOR", "hexa": "#009688" },
      { "label": "GREEN/COLOR", "hexa": "#4caf50" },
      { "label": "LIGHT_GREEN/COLOR", "hexa": "#8bc34a" },
      { "label": "LEMON/COLOR", "hexa": "#cddc39" },
      { "label": "YELLOW/COLOR", "hexa": "#ffeb3b" },
      { "label": "AMBER/COLOR", "hexa": "#ffc107" },
      { "label": "ORANGE/COLOR", "hexa": "#ff9800" },
      { "label": "DARK_ORANGE/COLOR", "hexa": "#ff5722" },
      { "label": "BROWN/COLOR", "hexa": "#795548" },
      { "label": "GREY/COLOR", "hexa": "#9e9e9e" },
      { "label": "GRAY_BLUE/COLOR", "hexa": "#607d8b" },
    ]
  },
  { 
    "label": "SWAPP",
    "list": [
      { "label": "900", "hexa": "#156b88" },
      { "label": "700", "hexa": "#1c8eb6" },
      { "label": "500", "hexa": "#23b2e3" },
      { "label": "300", "hexa": "#4fc1e9" },
      { "label": "100", "hexa": "#7bd1ee" },
      { "label": "900", "hexa": "#1c8275" },
      { "label": "700", "hexa": "#25ad9c" },
      { "label": "500", "hexa": "#2ed8c3" },
      { "label": "300", "hexa": "#58e0cf" },
      { "label": "100", "hexa": "#82e8db" },
      { "label": "900", "hexa": "#1a7c4a" },
      { "label": "700", "hexa": "#22a563" },
      { "label": "500", "hexa": "#2bce7c" },
      { "label": "300", "hexa": "#55d896" },
      { "label": "100", "hexa": "#80e2b0" }
    ]
  },
  { 
    "label": "COURRIER",
    "list": [
      { "label": "700", "hexa": "#076599" },
      { "label": "900", "hexa": "#0b88cc" },
      { "label": "700", "hexa": "#64c8f3" },
      { "label": "300", "hexa": "#e5f3fa" },
      { "label": "500", "hexa": "#2b784c" },
      { "label": "500", "hexa": "#4ab276" },
      { "label": "300", "hexa": "#7cc6b2" },
      { "label": "100", "hexa": "#766999" },
      { "label": "900", "hexa": "#9d8ec4" },
      { "label": "100", "hexa": "#95201e" },
      { "label": "700", "hexa": "#ce3634" },
      { "label": "500", "hexa": "#f39305" },
      { "label": "300", "hexa": "#f8b10b" },
      { "label": "100", "hexa": "#5c6970" },
      { "label": "900", "hexa": "#9ba9b0" }
    ]
  },
  { 
    "label": "EXPERT",
    "list": [
      { "label": "900", "hexa": "#FBBA00" },
      { "label": "700", "hexa": "#d49718" },
      { "label": "500", "hexa": "#B6CE2F" },
      { "label": "300", "hexa": "#92ac36" },
      { "label": "100", "hexa": "#5EA8C4" },
      { "label": "900", "hexa": "#3e8da2" },
      { "label": "100", "hexa": "#20294a" },
      { "label": "700", "hexa": "#B164A5" },
      { "label": "500", "hexa": "#7a3a70" },
      { "label": "300", "hexa": "#CF2C27" },
      { "label": "100", "hexa": "#8c1a1c" },
      { "label": "900", "hexa": "#676362" },
      { "label": "700", "hexa": "#A2A0A1" },
      { "label": "500", "hexa": "#fb5723" },
      { "label": "300", "hexa": "#ff9800" }
    ]
  },
  { 
    "label": "SUMMER",
    "list": [
      { "label": "900", "hexa": "#172a32" },
      { "label": "700", "hexa": "#1e3842" },
      { "label": "500", "hexa": "#264653" },
      { "label": "300", "hexa": "#516b75" },
      { "label": "100", "hexa": "#7d9098" },
      { "label": "900", "hexa": "#195e56" },
      { "label": "700", "hexa": "#227e72" },
      { "label": "500", "hexa": "#2a9d8f" },
      { "label": "300", "hexa": "#55b1a5" },
      { "label": "100", "hexa": "#7fc4bc" },
      { "label": "900", "hexa": "#8c7640" },
      { "label": "700", "hexa": "#ba9d55" },
      { "label": "500", "hexa": "#e9c46a" },
      { "label": "300", "hexa": "#edd088" },
      { "label": "100", "hexa": "#f2dca6" }
    ]
  },
  { 
    "label": "CANDY",
    "list": [
      { "label": "900", "hexa": "#256887" },
      { "label": "700", "hexa": "#318bb4" },
      { "label": "500", "hexa": "#3daee1" },
      { "label": "300", "hexa": "#64bee7" },
      { "label": "100", "hexa": "#8bceed" },
      { "label": "900", "hexa": "#195e56" },
      { "label": "700", "hexa": "#963b53" },
      { "label": "500", "hexa": "#fa628a" },
      { "label": "300", "hexa": "#fb81a1" },
      { "label": "100", "hexa": "#fca1b9" },
      { "label": "900", "hexa": "#257a64" },
      { "label": "700", "hexa": "#32a286" },
      { "label": "500", "hexa": "#3ecba7" },
      { "label": "300", "hexa": "#65d5b9" },
      { "label": "100", "hexa": "#8be0ca" }
    ]
  },
  { 
    "label": "RED/COLOR",
    "list": [
      { "label": "50", "hexa": "#ffebee" },
      { "label": "100", "hexa": "#ffcdd2" },
      { "label": "200", "hexa": "#ef9a9a" },
      { "label": "300", "hexa": "#e57373" },
      { "label": "400", "hexa": "#ef5350" },
      { "label": "500", "hexa": "#f44336" },
      { "label": "600", "hexa": "#e53935" },
      { "label": "700", "hexa": "#d32f2f" },
      { "label": "800", "hexa": "#c62828" },
      { "label": "900", "hexa": "#b71c1c" },
      { "label": "a50", "hexa": "#fde0dd" },
      { "label": "a100", "hexa": "#fccecb" },
      { "label": "a150", "hexa": "#fbbdb8" },
      { "label": "a200", "hexa": "#faaba6" },
      { "label": "a250", "hexa": "#f99a93" }
    ]
  },
  {
    "label": "PINK/COLOR", 
    "list": [
      { "label": "50", "hexa": "#fce4ec" },
      { "label": "100", "hexa": "#f8bbd0" },
      { "label": "200", "hexa": "#f48fb1" },
      { "label": "300", "hexa": "#f06292" },
      { "label": "400", "hexa": "#ec407a" },
      { "label": "500", "hexa": "#e91e63" },
      { "label": "600", "hexa": "#d81b60" },
      { "label": "700", "hexa": "#c2185b" },
      { "label": "800", "hexa": "#ad1457" },
      { "label": "900", "hexa": "#880e4f" },
      { "label": "a50", "hexa": "#fce3eb" },
      { "label": "a100", "hexa": "#fad1df" },
      { "label": "a150", "hexa": "#f9bfd2" },
      { "label": "a200", "hexa": "#f7adc6" },
      { "label": "a250", "hexa": "#f59bba" }
    ]
  },
  {
    "label": "PURPLE/COLOR",
    "list": [
      { "label": "50", "hexa": "#f3e5f5" },
      { "label": "100", "hexa": "#e1bee7" },
      { "label": "200", "hexa": "#ce93d8" },
      { "label": "300", "hexa": "#ba68c8" },
      { "label": "400", "hexa": "#ab47bc" },
      { "label": "500", "hexa": "#9c27b0" },
      { "label": "600", "hexa": "#8e24aa" },
      { "label": "700", "hexa": "#7b1fa2" },
      { "label": "800", "hexa": "#6a1b9a" },
      { "label": "900", "hexa": "#4a148c" },
      { "label": "a50", "hexa": "#f9eefb" },
      { "label": "a100", "hexa": "#f4def8" },
      { "label": "a150", "hexa": "#efcef4" },
      { "label": "a200", "hexa": "#e9bef1" },
      { "label": "a250", "hexa": "#e4aeed" }
    ]
  },
  {
    "label": "DARK_PURPLE/COLOR", 
    "list": [
      { "label": "50", "hexa": "#ede7f6" },
      { "label": "100", "hexa": "#d1c4e9" },
      { "label": "200", "hexa": "#b39ddb" },
      { "label": "300", "hexa": "#9575cd" },
      { "label": "400", "hexa": "#7e57c2" },
      { "label": "500", "hexa": "#673ab7" },
      { "label": "600", "hexa": "#5e35b1" },
      { "label": "700", "hexa": "#512da8" },
      { "label": "800", "hexa": "#4527a0" },
      { "label": "900", "hexa": "#311b92" },
      { "label": "a50", "hexa": "#ece5f7" },
      { "label": "a100", "hexa": "#e1d7f2" },
      { "label": "a150", "hexa": "#d5c8ed" },
      { "label": "a200", "hexa": "#cab9e9" },
      { "label": "a250", "hexa": "#bfaae4" }
    ]
  },
  {
    "label": "INDIGO/COLOR",
    "list": [
      { "label": "50", "hexa": "#e8eaf6" },
      { "label": "100", "hexa": "#c5cae9" },
      { "label": "200", "hexa": "#9fa8da" },
      { "label": "300", "hexa": "#7986cb" },
      { "label": "400", "hexa": "#5c6bc0" },
      { "label": "500", "hexa": "#3f51b5" },
      { "label": "600", "hexa": "#3949ab" },
      { "label": "700", "hexa": "#303f9f" },
      { "label": "800", "hexa": "#283593" },
      { "label": "900", "hexa": "#1a237e" },
      { "label": "a50", "hexa": "#e8ebf7" },
      { "label": "a100", "hexa": "#daddf2" },
      { "label": "a150", "hexa": "#cbd0ed" },
      { "label": "a200", "hexa": "#bdc3e8" },
      { "label": "a250", "hexa": "#aeb6e3" }
    ]
  },
  {
    "label": "DEEP_BLUE/COLOR",
    "list": [
      { "label": "50", "hexa": "#e6ebf3" },
      { "label": "100", "hexa": "#cdd6e7" },
      { "label": "200", "hexa": "#9aaece" },
      { "label": "300", "hexa": "#6885b6" },
      { "label": "400", "hexa": "#355d9d" },
      { "label": "500", "hexa": "#033485" },
      { "label": "600", "hexa": "#022a6a" },
      { "label": "700", "hexa": "#021f50" },
      { "label": "800", "hexa": "#011535" },
      { "label": "900", "hexa": "#010a1b" },
      { "label": "a50", "hexa": "#d8dfec" },
      { "label": "a100", "hexa": "#b3c2da" },
      { "label": "a150", "hexa": "#819ac2" },
      { "label": "a200", "hexa": "#4f71aa" },
      { "label": "a250", "hexa": "#1c4891" }
    ]
  },
  {
    "label": "BLUE/COLOR",
    "list": [
      { "label": "50", "hexa": "#e3f2fd" },
      { "label": "100", "hexa": "#bbdefb" },
      { "label": "200", "hexa": "#90caf9" },
      { "label": "300", "hexa": "#64b5f6" },
      { "label": "400", "hexa": "#42a5f5" },
      { "label": "500", "hexa": "#2196f3" },
      { "label": "600", "hexa": "#1e88e5" },
      { "label": "700", "hexa": "#1976d2" },
      { "label": "800", "hexa": "#1565c0" },
      { "label": "900", "hexa": "#0d47a1" },
      { "label": "a50", "hexa": "#dbeefd" },
      { "label": "a100", "hexa": "#c8e5fc" },
      { "label": "a150", "hexa": "#b6dcfb" },
      { "label": "a200", "hexa": "#a3d4fa" },
      { "label": "a250", "hexa": "#91cbf9" }
    ]
  },
  {
    "label": "LIGHT_BLUE/COLOR",
    "list": [
      { "label": "50", "hexa": "#e1f5fe" },
      { "label": "100", "hexa": "#b3e5fc" },
      { "label": "200", "hexa": "#81d4fa" },
      { "label": "300", "hexa": "#4fc3f7" },
      { "label": "400", "hexa": "#29b6f6" },
      { "label": "500", "hexa": "#03a9f4" },
      { "label": "600", "hexa": "#039be5" },
      { "label": "700", "hexa": "#0288d1" },
      { "label": "800", "hexa": "#0277bd" },
      { "label": "900", "hexa": "#01579b" },
      { "label": "a50", "hexa": "#e4f6ff" },
      { "label": "a100", "hexa": "#d0f0fe" },
      { "label": "a150", "hexa": "#bdeafe" },
      { "label": "a200", "hexa": "#aae4fe" },
      { "label": "a250", "hexa": "#96defe" }
    ]
  },
  {
    "label": "CYAN/COLOR",
    "list": [
      { "label": "50", "hexa": "#e0f7fa" },
      { "label": "100", "hexa": "#b2ebf2" },
      { "label": "200", "hexa": "#80deea" },
      { "label": "300", "hexa": "#4dd0e1" },
      { "label": "400", "hexa": "#26c6da" },
      { "label": "500", "hexa": "#00bcd4" },
      { "label": "600", "hexa": "#00acc1" },
      { "label": "700", "hexa": "#0097a7" },
      { "label": "800", "hexa": "#00838f" },
      { "label": "900", "hexa": "#006064" },
      { "label": "a50", "hexa": "#e8fcff" },
      { "label": "a100", "hexa": "#d4faff" },
      { "label": "a150", "hexa": "#c0f8ff" },
      { "label": "a200", "hexa": "#adf6ff" },
      { "label": "a250", "hexa": "#99f3ff" }
    ]
  },
  {
    "label": "BLUE_DUCK/COLOR",
    "list": [
      { "label": "50", "hexa": "#e0f2f1" },
      { "label": "100", "hexa": "#b2dfdb" },
      { "label": "200", "hexa": "#80cbc4" },
      { "label": "300", "hexa": "#4db6ac" },
      { "label": "400", "hexa": "#26a69a" },
      { "label": "500", "hexa": "#009688" },
      { "label": "600", "hexa": "#00897b" },
      { "label": "700", "hexa": "#00796b" },
      { "label": "800", "hexa": "#00695c" },
      { "label": "900", "hexa": "#004d40" },
      { "label": "a50", "hexa": "#e4fffd" },
      { "label": "a100", "hexa": "#d1fffb" },
      { "label": "a150", "hexa": "#bdfff9" },
      { "label": "a200", "hexa": "#aafff7" },
      { "label": "a250", "hexa": "#96fff5" }
    ]
  },
  {
    "label": "GREEN/COLOR",
    "list": [
      { "label": "50", "hexa": "#e8f5e9" },
      { "label": "100", "hexa": "#c8e6c9" },
      { "label": "200", "hexa": "#a5d6a7" },
      { "label": "300", "hexa": "#81c784" },
      { "label": "400", "hexa": "#66bb6a" },
      { "label": "500", "hexa": "#4caf50" },
      { "label": "600", "hexa": "#43a047" },
      { "label": "700", "hexa": "#388e3c" },
      { "label": "800", "hexa": "#2e7d32" },
      { "label": "900", "hexa": "#1b5e20" },
      { "label": "a50", "hexa": "#eff8ef" },
      { "label": "a100", "hexa": "#e1f2e2" },
      { "label": "a150", "hexa": "#d3ecd4" },
      { "label": "a200", "hexa": "#c6e6c7" },
      { "label": "a250", "hexa": "#b8e0b9" }
    ]
  },
  {
    "label": "LIGHT_GREEN/COLOR",
    "list": [
      { "label": "50", "hexa": "#f1f8e9" },
      { "label": "100", "hexa": "#dcedc8" },
      { "label": "200", "hexa": "#c5e1a5" },
      { "label": "300", "hexa": "#aed581" },
      { "label": "400", "hexa": "#9ccc65" },
      { "label": "500", "hexa": "#8bc34a" },
      { "label": "600", "hexa": "#7cb342" },
      { "label": "700", "hexa": "#689f38" },
      { "label": "800", "hexa": "#558b2f" },
      { "label": "900", "hexa": "#33691e" },
      { "label": "a50", "hexa": "#f3f9ec" },
      { "label": "a100", "hexa": "#e9f4dd" },
      { "label": "a150", "hexa": "#e0efcf" },
      { "label": "a200", "hexa": "#d7eac0" },
      { "label": "a250", "hexa": "#cde5b1" }
    ]
  },
  {
    "label": "LEMON/COLOR",
    "list": [
      { "label": "50", "hexa": "#f9fbe7" },
      { "label": "100", "hexa": "#f0f4c3" },
      { "label": "200", "hexa": "#e6ee9c" },
      { "label": "300", "hexa": "#dce775" },
      { "label": "400", "hexa": "#d4e157" },
      { "label": "500", "hexa": "#cddc39" },
      { "label": "600", "hexa": "#c0ca33" },
      { "label": "700", "hexa": "#afb42b" },
      { "label": "800", "hexa": "#9e9d24" },
      { "label": "900", "hexa": "#827717" },
      { "label": "a50", "hexa": "#f7f9e0" },
      { "label": "a100", "hexa": "#f3f7cf" },
      { "label": "a150", "hexa": "#eff4be" },
      { "label": "a200", "hexa": "#eaf1ae" },
      { "label": "a250", "hexa": "#e6ee9d" }
    ]
  },
  {
    "label": "YELLOW/COLOR",
    "list": [
      { "label": "50", "hexa": "#fffde7" },
      { "label": "100", "hexa": "#fff9c4" },
      { "label": "200", "hexa": "#fff59d" },
      { "label": "300", "hexa": "#fff176" },
      { "label": "400", "hexa": "#ffee58" },
      { "label": "500", "hexa": "#ffeb3b" },
      { "label": "600", "hexa": "#fdd835" },
      { "label": "700", "hexa": "#fbc02d" },
      { "label": "800", "hexa": "#f9a825" },
      { "label": "900", "hexa": "#f57f17" },
      { "label": "a50", "hexa": "#fffbd8" },
      { "label": "a100", "hexa": "#fbf6c8" },
      { "label": "a150", "hexa": "#fff7b1" },
      { "label": "a200", "hexa": "#fff5a0" },
      { "label": "a250", "hexa": "#fff389" }
    ]
  },
  {
    "label": "AMBER/COLOR",
    "list": [
      { "label": "50", "hexa": "#fff8e1" },
      { "label": "100", "hexa": "#ffecb3" },
      { "label": "200", "hexa": "#ffe082" },
      { "label": "300", "hexa": "#ffd54f" },
      { "label": "400", "hexa": "#ffca28" },
      { "label": "500", "hexa": "#ffc107" },
      { "label": "600", "hexa": "#ffb300" },
      { "label": "700", "hexa": "#ffa000" },
      { "label": "800", "hexa": "#ff8f00" },
      { "label": "900", "hexa": "#ff6f00" },
      { "label": "a50", "hexa": "#fff7df" },
      { "label": "a100", "hexa": "#fff2cb" },
      { "label": "a150", "hexa": "#ffedb8" },
      { "label": "a200", "hexa": "#ffe8a4" },
      { "label": "a250", "hexa": "#ffe390" }
    ]
  },
  {
    "label": "ORANGE/COLOR",
    "list": [
      { "label": "50", "hexa": "#fff3e0" },
      { "label": "100", "hexa": "#ffe0b2" },
      { "label": "200", "hexa": "#ffcc80" },
      { "label": "300", "hexa": "#ffb74d" },
      { "label": "400", "hexa": "#ffa726" },
      { "label": "500", "hexa": "#ff9800" },
      { "label": "600", "hexa": "#fb8c00" },
      { "label": "700", "hexa": "#f57c00" },
      { "label": "800", "hexa": "#ef6c00" },
      { "label": "900", "hexa": "#e65100" },
      { "label": "a50", "hexa": "#fff7eb" },
      { "label": "a100", "hexa": "#ffefd8" },
      { "label": "a150", "hexa": "#ffe7c4" },
      { "label": "a200", "hexa": "#ffdfb1" },
      { "label": "a250", "hexa": "#ffd79d" }
    ]
  },
  {
    "label": "DARK_ORANGE/COLOR",
    "list": [
      { "label": "50", "hexa": "#fbe9e7" },
      { "label": "100", "hexa": "#ffccbc" },
      { "label": "200", "hexa": "#ffab91" },
      { "label": "300", "hexa": "#ff8a65" },
      { "label": "400", "hexa": "#ff7043" },
      { "label": "500", "hexa": "#ff5722" },
      { "label": "600", "hexa": "#f4511e" },
      { "label": "700", "hexa": "#e64a19" },
      { "label": "800", "hexa": "#d84315" },
      { "label": "900", "hexa": "#bf360c" },
      { "label": "a50", "hexa": "#ffece6" },
      { "label": "a100", "hexa": "#ffddd3" },
      { "label": "a150", "hexa": "#ffcebf" },
      { "label": "a200", "hexa": "#ffbfab" },
      { "label": "a250", "hexa": "#ffb098" }
    ]
  },
  {
    "label": "BROWN/COLOR",
    "list": [
      { "label": "50", "hexa": "#efebe9" },
      { "label": "100", "hexa": "#d7ccc8" },
      { "label": "200", "hexa": "#bcaaa4" },
      { "label": "300", "hexa": "#a1887f" },
      { "label": "400", "hexa": "#8d6e63" },
      { "label": "500", "hexa": "#795548" },
      { "label": "600", "hexa": "#6d4c41" },
      { "label": "700", "hexa": "#5d4037" },
      { "label": "800", "hexa": "#4e342e" },
      { "label": "900", "hexa": "#3e2723" },
      { "label": "a50", "hexa": "#f7f2f1" },
      { "label": "a100", "hexa": "#efe7e4" },
      { "label": "a150", "hexa": "#e8dcd8" },
      { "label": "a200", "hexa": "#e1d1cc" },
      { "label": "a250", "hexa": "#d9c6c0" }
    ]
  },
  {
    "label": "GREY/COLOR",
    "list": [
      { "label": "50", "hexa": "#fafafa" },
      { "label": "100", "hexa": "#f5f5f5" },
      { "label": "200", "hexa": "#eeeeee" },
      { "label": "300", "hexa": "#e0e0e0" },
      { "label": "400", "hexa": "#bdbdbd" },
      { "label": "500", "hexa": "#9e9e9e" },
      { "label": "600", "hexa": "#757575" },
      { "label": "700", "hexa": "#616161" },
      { "label": "800", "hexa": "#424242" },
      { "label": "900", "hexa": "#212121" },
      { "label": "a50", "hexa": "#ececec" },
      { "label": "a100", "hexa": "#e3e3e3" },
      { "label": "a150", "hexa": "#d9d9d9" },
      { "label": "a200", "hexa": "#cfcfcf" },
      { "label": "a250", "hexa": "#c5c5c5" }
    ]
  },
  {
    "label": "GRAY_BLUE/COLOR",
    "list": [
      { "label": "50", "hexa": "#eceff1" },
      { "label": "100", "hexa": "#cfd8dc" },
      { "label": "200", "hexa": "#b0bec5" },
      { "label": "300", "hexa": "#90a4ae" },
      { "label": "400", "hexa": "#78909c" },
      { "label": "500", "hexa": "#607d8b" },
      { "label": "600", "hexa": "#546e7a" },
      { "label": "700", "hexa": "#455a64" },
      { "label": "800", "hexa": "#37474f" },
      { "label": "900", "hexa": "#263238" },
      { "label": "a50", "hexa": "#f3f6f7" },
      { "label": "a100", "hexa": "#e8ecef" },
      { "label": "a150", "hexa": "#dce3e7" },
      { "label": "a200", "hexa": "#d0dadf" },
      { "label": "a250", "hexa": "#c5d1d7" }
    ]
  }
]