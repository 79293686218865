/**
 * @module DataFrame
 */

import { DataFrameModel, DataFrameTypesModel, DataFrameValueModel, DataFrameDetectValuesModel, DataFrameDetectValuesSortByType, DataFrameAggregationsModel } from '@models/dataFrame.model';
import { DataFrame } from 'data-forge';
import { Is } from '@functions/is.functions';
import { LanguageType } from '@models/language.model';
import { LanguageCode } from '@functions/language.functions';
import { ParseDate } from '@functions/moment.functions';
import { CreateRandomUid } from '@functions/uid.functions';
import { UID } from '@models/uid.model';
import { ServerUrlModel } from '@models/server.model';
import { DeepCopy } from '@functions/copy.functions';
import { BehaviorSubject } from 'rxjs';

export class DataFrameClass {

  protected _inital: DataFrame;
  protected _server: ServerUrlModel
  protected _serverInitial: ServerUrlModel
  protected _dataFrame: DataFrameModel;
  protected _isErrors: { [key: string]: boolean };
  protected _uids: { [key: string]: UID }; //liens avec les objets firebase

  valuesChanged$: BehaviorSubject<boolean>;

  constructor(dataFrameModel: DataFrameModel, server: ServerUrlModel) {
    dataFrameModel.dataFrame = dataFrameModel.dataFrame.generateSeries({ '$$errors': () => { return {}; } }).bake() as DataFrame;
    dataFrameModel.dataFrame = dataFrameModel.dataFrame.generateSeries({ '$$uid': () => { return CreateRandomUid(); } }).bake() as DataFrame;
    dataFrameModel.dataFrame = dataFrameModel.dataFrame.setIndex('$$uid') as DataFrame;
    dataFrameModel.dataFrame = dataFrameModel.dataFrame.bake() as DataFrame;

    this._dataFrame = this.parseValues(dataFrameModel);
    this._server = server;
    this._isErrors = {};
    this._uids = {};
    this._dataFrame.dataFrame.getColumnNames().forEach((key: string) => {
      this._uids[key] = undefined;
    });
    this.setErrors();
    this._serverInitial = DeepCopy(server);
    this._inital = dataFrameModel.dataFrame.bake() as DataFrame;
    this.valuesChanged$ = new BehaviorSubject<boolean>(false);
  }

  reset() {
    this._dataFrame.dataFrame = this._inital.bake() as DataFrame;
    this._server = DeepCopy(this._serverInitial);
  }

  get server(): Readonly<ServerUrlModel> {
    return this._server;
  }

  get group(): Readonly<string> {
    return this._dataFrame.group;
  }

  get label(): Readonly<string> {
    return this._dataFrame.label;
  }

  get keyNumber(): Readonly<string> {
    let key: string;
    Object.values(this._dataFrame.types).forEach((type: DataFrameTypesModel, index: number) => {
      if(type.type === 'number') key = Object.keys(this._dataFrame.types)[index];
    });
    return key;
  }

  get keysString(): Readonly<string[]> {
    let keys: string[] = [];
    Object.values(this._dataFrame.types).forEach((type: DataFrameTypesModel, index: number) => {
      if(type.type === 'string') {
        const key = Object.keys(this._dataFrame.types)[index]
        if(key !== '$$uid') keys.push(Object.keys(this._dataFrame.types)[index]);
      }
    });
    return keys;
  }

  get keysDate(): Readonly<string[]> {
    let keys: string[] = [];
    Object.values(this._dataFrame.types).forEach((type: DataFrameTypesModel, index: number) => {
      if(type.type === 'date') keys.push(Object.keys(this._dataFrame.types)[index]);
    });
    return keys;
  }

  keyForUid(uid: UID): Readonly<string> {
    return Object.keys(this._uids)[Object.values(this._uids).indexOf(uid)];
  }

  uidForKey(key: string): Readonly<UID> {
    return this._uids[key];
  }

  setUidForKey(key: string, uid: UID) {
    this._uids[key] = uid;
  }

  types(key: string): Readonly<DataFrameTypesModel> {
    return this._dataFrame.types[key];
  }

  getFormat(key: string): string {
    return this._dataFrame.types[key].format;
  }

  setFormat(key: string, format: string) {
    if(this._dataFrame.types[key].type === 'date') {
      this._dataFrame.types[key].format = format;
      this.values = this._dataFrame.dataFrame.toArray();
    }
  }

  get isErrors(): Readonly<boolean> {
    return Object.values(this._isErrors).filter((isErrors: boolean) => { return isErrors === true }).length > 0;
  }

  isErrorsForKey(key: string): Readonly<boolean> {
    return this._isErrors[key];
  }

  get emptyValue(): any {
    let value = {} as DataFrameValueModel;
    const columns = this._dataFrame.dataFrame.getColumnNames();
    columns.splice(columns.indexOf('$$errors'), 1);
    columns.splice(columns.indexOf('$$uid'), 1);
    for(const column in columns) {
      switch(this.types(column).type) {
        case 'number': 
          value[column] = 0;
          break;
        case 'string': 
        case 'date': 
          value[column] = '';
          break;
      }
    }
    value.$$errors = {};
    value.$$uid = CreateRandomUid();
    return value;
  }

  get keys(): string[] {
    return this._dataFrame.dataFrame.getColumnNames();
  }

  get values(): any[] {
    return this._dataFrame.dataFrame.orderByDescending((row: DataFrameValueModel) => { return row.$$uid; }).toArray();
  }

  valuesForKey(key: string): any[] {
    return this._dataFrame.dataFrame.getSeries(key).toArray();
  }

  detectValuesForKey(key: string, sortBy: DataFrameDetectValuesSortByType = 'frequency', reverse: boolean = false): DataFrameDetectValuesModel[] {
    return this._dataFrame.dataFrame.getSeries(key).detectValues().toArray().map((dv: any) => {
      return { value: dv.Value, frequency: dv.Frequency };
    }).sort((dvA: DataFrameDetectValuesModel, dvB: DataFrameDetectValuesModel) => {
      if(sortBy === 'frequency') return (reverse ? -1 : 1) * (dvA.frequency - dvB.frequency);
      else return (reverse ? -1 : 1) * (dvA.value.localeCompare(dvB.value));
    });
  }

  setDetectValueForKey(key: string, search: string, replace: string) {
    let rows = this._dataFrame.dataFrame.where(row => row[key] === search).bake().toArray();
    rows = rows.map((row: DataFrameValueModel) => {
      return Object.assign(row, { [key]: replace });
    });
    if(rows.length > 0) this.values = rows;
  }

  aggregations(key: string): DataFrameAggregationsModel {
    const serie = this._dataFrame.dataFrame.getSeries(key);
    const sum = serie.sum();
    return {
      average: serie.average(),
      min: serie.min(),
      max: serie.max(),
      sum: isNaN(sum) ? NaN : sum,
      count: serie.count(),
      median: serie.median(),
      distinct: serie.distinct().count()
    }
  }

  get errors(): any[] {
    return this._dataFrame.dataFrame.where(row => Object.keys(row.$$errors).length > 0 ).toArray();
  }

  get valids(): any[] {
    return this._dataFrame.dataFrame.where(row => Object.keys(row.$$errors).length === 0 ).toArray();
  }

  removeValues(uids: UID[]) {
    this._dataFrame.dataFrame = this._dataFrame.dataFrame.where((row: DataFrameValueModel) => {
      return !uids.includes(row.$$uid);
    }).bake() as DataFrame;
    this.setErrors();
    this.valuesChanged$.next(true);
  }

  removeDetectValue(key: string, value: string) {
    this._dataFrame.dataFrame = this._dataFrame.dataFrame.where((row: DataFrameValueModel) => {
      return row[key] !== value;
    }).bake() as DataFrame;
    this.setErrors();
    this.valuesChanged$.next(true);
  }

  set values(values: any[]) {
    const dataFrame = new DataFrame(values).setIndex('$$uid').bake() as DataFrame;
    this.parseValues({
      group: this.group,
      label: this.label,
      types: this._dataFrame.types,
      dataFrame: dataFrame
    } as DataFrameModel);
    this._dataFrame.dataFrame = DataFrame.merge([this._dataFrame.dataFrame, dataFrame]).bake() as DataFrame;
    this.setErrors();
    this.valuesChanged$.next(true);
  }

  private setErrors() {
    this.keys.forEach((key: string) => {
      this._isErrors[key] = this._dataFrame.dataFrame.where(row => Object.keys(row.$$errors).length > 0 && row.$$errors[key] !== undefined).toArray().length > 0;
    });
  }

  isValid(key: string, value: any): boolean {
    let isValid = true;
    switch(this.types(key).type) {
      case 'number': {
        if(typeof value === 'string') {
          const result = this.parseLocaleNumber(value);
          if(result.isNumber) {
            value = result.parsed;
          }
        }
        if(!(Is().number(value))) {
          isValid = false;
        }
        break;
      }
      case 'date': {
        const result = ParseDate(value,this.types(key).format);
        if(!result.isValid) {
          isValid = false;
        }
        break;
      }
    }
    return isValid;
  }

  private parseValues(dataFrameModel: DataFrameModel): DataFrameModel {
    dataFrameModel.dataFrame.forEach(row => {
      const columns = Object.keys(row);
      columns.splice(columns.indexOf('$$errors'), 1);
      columns.splice(columns.indexOf('$$uid'), 1);
      columns.forEach((key, index) => {
        switch(dataFrameModel.types[key].type) {
          case 'number': {
            if(typeof row[key] === 'string') {
              const result = this.parseLocaleNumber(row[key]);
              if(result.isNumber) {
                row[key] = result.parsed;
              }
            }
            if(!(Is().number(row[key]))) {
              row.$$errors[key] = true;
            }
            else {
              delete row.$$errors[key];
            }
            break;
          }
          case 'date': {
            const result = ParseDate(row[key], dataFrameModel.types[key].format);
            if(result.isValid) {
              delete row.$$errors[key];
            }
            else {
              row.$$errors[key] = true;
            }
            break;
          }
        }
      });
    });
    return dataFrameModel;
  }

  private parseLocaleNumber(value: string): { isNumber: boolean, parsed: number, initial: string } {

    const _value = value.replace(' ', '');
    const isNumber = new RegExp(/^[0-9.,]*$/).test(_value);

    if(isNumber) {

      const dotIndex = _value.indexOf('.');
      const commaIndex = _value.indexOf(',');
    
      let locale: LanguageType;
      
      if(commaIndex <= dotIndex) locale = 'en';
      else if(commaIndex > dotIndex) locale = 'fr';
    
      const thousandSeparator = new Intl.NumberFormat(LanguageCode(locale)).format(1111).replace(/1/g, '');
      const decimalSeparator = new Intl.NumberFormat(LanguageCode(locale)).format(1.1).replace(/1/g, '');
    
      const parsed = parseFloat(_value
        .replace(new RegExp('\\' + thousandSeparator, 'g'), '')
        .replace(new RegExp('\\' + decimalSeparator), '.'));

      return {
        isNumber: !isNaN(parsed),
        parsed: isNaN(parsed) ? undefined : parsed,
        initial: _value
      }
    }
    else {
      return {
        isNumber: false,
        parsed: undefined,
        initial: _value
      }
    }
  }
    

}