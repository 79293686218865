<div class="app-company-logo-swapp animated slideInLeft"></div>

<ng-template #noCompany>
  <div class="md-form md-outline mb-3 mt-0">
    <input type="text" placeholder="{{ 'YOUR_COMPANY' | translate }}" id="companyId" class="form-control" mdbInput [ngModel]="label" (ngModelChange)="onCompany($event)">
    <small id="form-company-help" class="form-text text-muted d-flex align-items-center">
      <span [ngClass]="{ 'app-opacity-0': label?.length === 0 }">{{ 'COMPANY_ERROR/TEXT' | translate }}</span>
    </small>
  </div>
</ng-template>

<div mdbModal #authModal="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="authModal" aria-hidden="true"
  [config]="{ignoreBackdropClick: true, keyboard: false, show: true}">
  <div class="modal-dialog modal-sm modal-dialog-centered modal-dialog-scrollable" role="document">
    <div class="modal-content">

      <div class="modal-body p-0">
        <div class="d-flex flex-column">
            <div class="px-3 pt-3 d-flex justify-content-center flex-column col-12">
                <img style="height: 140px;" class="animated fadeInUp" src="assets/images/logo.svg">
      
                <span class="mt-3">
                  <mdb-card-title class="mb-0">
                    <h3 class="text-elegant text-center">
                      <strong>{{ 'WELCOME' | translate }}</strong>
                    </h3>
                  </mdb-card-title>
        
                  <h6 class="mb-0 text-muted text-center">
                    <strong>Swapp.Dash</strong>
                  </h6>
                </span>
              </div>
          <div class="px-4 py-1 pt-4 col-12 app-company-height">
            <div *ngIf="code != undefined; else noCompany">
              <div class="alert alert-info text-center mb-0 mt-5" role="alert">
                <i class="fas fa-circle-notch fa-spin"></i><br>
                {{ 'AUTO_COMPANY/TEXT' | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</div>

<app-version></app-version>