<mdb-navbar SideClass="navbar navbar-expand-sm navbar-dark elegant-color-dark sticky-top" [containerInside]="false">

  <ul class="navbar-nav">
    <li class="nav-item avatar btn-group" mdbDropdown>
      <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" style="overflow: visible;" mdbWavesEffect>
        <img [src]="user.avatar.url" alt="" class="img-fluid rounded-circle z-depth-0">
        <div class="app-browser-navbar-connected">
          <i *ngIf="connected$.value"  class="animated fadeInDown fas fa-circle text-success"></i>
          <i *ngIf="!connected$.value"  class="animated fadeInDown fas fa-circle text-danger"></i>
        </div>
      </a>
      <div *dropdownMenu class="dropdown-menu dropdown-dark" role="menu">
        <h6 class="dropdown-header">{{ user.id.fullname }}</h6>
        <a (click)="onProfil()" class="dropdown-item z-depth-0"><i class="far fa-user-circle fa-fw mr-2 blue-text"></i>{{ 'MY_ACCOUNT' | translate }}</a>
        <a (click)="onSignOut()" class="dropdown-item z-depth-0"><i class="far fa-sign-out-alt fa-fw mr-2 text-danger"></i>{{ 'LOGOUT' | translate }}</a>
        <div *ngIf="frames.length > 0" class="divider dropdown-divider"></div>
        <a *ngFor="let frame of frames" (click)="onFrame(frame)" class="dropdown-item z-depth-0"><i class="far fa-{{icons[frame]}} fa-fw mr-2 {{colors[frame]}}-text"></i>{{ labels[frame] | translate }}</a>
        <div class="divider dropdown-divider"></div>
        <a *ngIf="connected$.value" class="dropdown-item disabled text-muted"><i class="fas fa-circle mr-2 fa-fw text-success"></i>{{ 'ONLINE' | translate }}</a>
        <a *ngIf="!connected$.value" class="dropdown-item disabled text-muted"><i class="fas fa-circle mr-2 fa-fw text-danger"></i>{{ 'OFFLINE' | translate }}</a>
        <a *ngIf="!isProduction" class="dropdown-item disabled text-muted"><i class="far fa-code mr-2 fa-fw"></i>{{ version }}</a>
      </div>
    </li>
  </ul>
  <links>
    <ul class="navbar-nav ml-auto">

      <!-- CONTENT -->
      <ng-content select="[app-browser-navbar-content]"></ng-content>
      <!-- ./CONTENT -->

      <li *ngIf="showBack" app-browser-navbar-content class="nav-item sm-ml-2 ml-1" (click)="onBack()">
        <a class="nav-link waves-light" mdbWavesEffect><i class="fa-fw fad fa-house-return"></i>
        <span class="ml-1 d-sm-none d-inline">{{ 'HOME' | translate }}</span></a>
      </li>

      <li class="nav-item sm-ml-2 ml-1" (click)="onPresence()">
      <a class="nav-link waves-light" mdbWavesEffect><i class="fa-users-class fad mr-1 text-{{ countUsers > 1 ? '' : 'elegant' }}"></i>
        <span class="ml-1 d-sm-none d-inline">{{ 'LOCAL_PRESENCES/TEXT' | translate }}</span></a>
      </li>
    </ul>
  </links>
</mdb-navbar>

<app-presences #presences (sendPresencesCount)="receivePresencesCount($event)"></app-presences>
<app-profil #profil></app-profil>

<app-themes *ngIf="frame == 'themes'" #themesModal></app-themes>
<app-rights *ngIf="frame == 'rights'" #rightsModal></app-rights>
<app-users *ngIf="frame == 'users'" #usersModal></app-users>
<app-tags *ngIf="frame == 'tags'" #tagsModal></app-tags>
<app-servers *ngIf="frame == 'servers'" #serversModal></app-servers>
<app-pivots *ngIf="frame == 'pivots'" #pivotsModal></app-pivots>
<app-dates *ngIf="frame == 'dates'" #datesModal></app-dates>