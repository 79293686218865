/**
 * @module Browser
 */

import { Component, Output, EventEmitter, Input } from '@angular/core';
import { AuthService } from '@services/auth/auth.service';
import { UserClass } from '@class/user.class';
import { Subscription } from 'rxjs';
import { RoleType } from '@models/role.model';

@Component({
  selector: 'app-browser-panel',
  templateUrl: './browser-panel.component.html',
  styleUrls: ['./browser-panel.component.css']
})
export class BrowserPanelComponent {

  @Input() role: RoleType;
  @Input() loading: boolean;
  @Input() empty: boolean;
  @Input() color: string;
  @Output() sendCreate: EventEmitter<void>;
  @Output() sendSearch: EventEmitter<void>;
  @Output() sendBookmark: EventEmitter<void>;

  @Input() title: string;
  @Input() text: string;
  @Input() action: string;
  @Input() icon: string;
  @Output() sendAction: EventEmitter<void>;

  user: UserClass;
  user$sub: Subscription;

  constructor(
    private $auth: AuthService
  ) {
    this.sendCreate = new EventEmitter<void>();
    this.sendSearch = new EventEmitter<void>();
    this.sendAction = new EventEmitter<void>();
    this.sendBookmark = new EventEmitter<void>();

    this.$auth.user$.subscribe((user: UserClass) => {
      if(!!user) this.user = user;
    });
  }

  onCreate() {
    this.sendCreate.emit();
  }

  onSearch() {
    this.sendSearch.emit();
  }

  receiveAction() {
    this.sendAction.emit();
  }

  onBookmark() {
    this.sendBookmark.emit();
  }

}
