/**
 * @module Chart
 */

import { ChartType, ChartMixedType } from '@models/chart.model';

export function ChartsTypes(exclude: ChartType[] = []): ChartType[] {
  return (['sticker', 'gauge', 'bar', 'horizontalBar', 'line', 'doughnut', 'pie', 'polarArea', 'radar', 'bubble', 'scatter'] as ChartType[]).filter((c: ChartType) => { return exclude.indexOf(c) === -1; });
}

export function ChartsMixedTypes(): ChartMixedType[] {
  return ['bar', 'line'];
}

export function ChartsIcons(): { [key in ChartType]: string } {
  return {
    'bar': 'fa-chart-bar', 
    'horizontalBar': 'fa-chart-bar fa-rotate-90', 
    'bubble': 'fa-chart-scatter', 
    'doughnut': 'fa-chart-pie-alt', 
    'line': 'fa-chart-line', 
    'pie': 'fa-chart-pie', 
    'polarArea': 'fa-chart-network', 
    'radar': 'fa-chart-network', 
    'scatter': 'fa-chart-scatter',
    'sticker': 'fa-sticky-note',
    'gauge': 'fa-tachometer'
  };
}

export function ChartIcon(chart: ChartType): string {
  return ChartsIcons()[chart];
}