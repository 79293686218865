/**
 * @module Theme
 */

import { Injectable } from '@angular/core';
import { ThemeClass } from '@class/theme.class';
import { Observable, BehaviorSubject } from 'rxjs';
import { ThemeModel } from '@models/theme.model';
import { map} from 'rxjs/operators';
import { FirestoreService } from '@services/firestore/firestore.service';
import { AuthService } from '@services/auth/auth.service';
import { FirebaseService } from '@services/firebase/firebase.service';
import { UidService } from '@services/uid/uid.service';
import { ItemType } from '@models/item.model';
import { LinksModelConstructor } from '@functions/link.functions';
import { ThemeOptionsClass } from '@class/themeOptions.class';
import { UID } from '@models/uid.model';
import { ThemeOptionsModel } from '@models/themeOptions.model';

@Injectable({
  providedIn: 'root'
})
export class ThemeService extends FirestoreService<ThemeModel> {

  /**
   * @description type de document manipulé par le service
   * @protected
   * @type {ItemType}
   * @memberof ThemeService
   */
  protected _itemType: ItemType = 'themes';

  /**
   * @description true si l'accès à ce type de document peut nécessiter des habilitations
   * @protected
   * @type {boolean}
   * @memberof ThemeService
   */
  protected _restricted: boolean = false;

  /**
   * @description Creates an instance of ThemeService
   * @param {UidService} $uid
   * @param {AuthService} $auth
   * @param {FirebaseService} $firebase
   * @memberof ThemeService
   */
  constructor(
    private $uid: UidService,
    protected $auth: AuthService,
    protected $firebase: FirebaseService
  ) {
    super($firebase, $auth);
  }

  /**
   * @description Observateur de la liste des objects autorisés pour l'utilisateur courant
   * @returns {BehaviorSubject<ThemeClass[]>}
   * @memberof ThemeService
   */
  list$(): BehaviorSubject<ThemeClass[]> {
    return super.full$().pipe(
      map((themes: ThemeModel[]) => {
        if(!!themes) {
          let ds = themes.map<ThemeClass>((theme: ThemeModel) => {
            return new ThemeClass(theme, true);
          });
          return ds;
        }
      })
    ) as BehaviorSubject<ThemeClass[]>;
  }

  /**
   * @description Observateur d'un document dont l'uid est passé en paramètre
   * @param {string} uid
   * @returns {Observable<ThemeClass>}
   * @memberof ThemeService
   */
  get$(uid: string): Observable<ThemeClass> {
    return super.doc$(uid).pipe(
      map((theme: ThemeModel) => {
        return new ThemeClass(theme, true);
      })
    );
  }

  /**
   * @description Observateur d'un document d'une sous-collection dont l'uid est passé en paramètre
   * @param {string} uid
   * @returns {Observable<ThemeClass>}
   * @memberof ThemeService
   */
  getOptions$(uid: string): Observable<ThemeOptionsClass> {
    return super.docSub$(uid, 'options').pipe(
      map((theme: ThemeOptionsModel) => {
        if(!!theme) return new ThemeOptionsClass(theme);
        else return undefined;
      })
    );
  }

  /**
   * @description Observateur de la liste des objects (données partielles) autorisés pour l'utilisateur courant
   * @returns {Observable<ThemeClass[]>}
   * @memberof ThemeService
   */
  listPartials$(): Observable<ThemeClass[]> {
    return super.fullPartial$().pipe(
      map((themes: ThemeModel[]) => {
        if(!!themes) {
          let ds = themes.map<ThemeClass>((theme: ThemeModel) => {
            return new ThemeClass(theme, true);
          });
          return ds;
        }
      })
    );
  }

  /**
   * @description Observateur d'un document (données partielles) dont l'uid est passé en paramètre
   * @param {string} uid
   * @returns {Observable<ThemeClass>}
   * @memberof ThemeService
   */
  getPartial$(uid: string): Observable<ThemeClass> {
    return super.partial$(uid).pipe(
      map((theme: ThemeModel) => {
        if(theme !== null) return new ThemeClass(theme, true);
      })
    );
  }

  /**
   * @description Créé un nouveau document avec les valeurs par défaut
   * @returns {ThemeClass}
   * @memberof ThemeService
   */
  create(): ThemeClass {
    return new ThemeClass({ 
      uid: this.$uid.create(),
      links: LinksModelConstructor().links,
      logs: {},
      tags: {}
    } as ThemeModel, false);
  }

  /**
   * @description Créé ou met à jour le document passé en paramètre
   * @param {ThemeClass} theme
   * @returns {Promise<void>}
   * @memberof ThemeService
   */
  async set(theme: ThemeClass): Promise<void> {
    return super.update(theme.uid.value, theme.model);
  }

  /**
   * @description Créé ou met à jour le sous-document à partir de l'uid du document passé en paramètre
   * @param {ThemeClass} theme
   * @returns {Promise<void>}
   * @memberof ThemeService
   */
  async setOptions(uid: UID, theme: ThemeOptionsClass): Promise<void> {
    return super.updateSub(uid, theme.model, 'options', false);
  }

  /**
   * @description Supprime le document passé en paramètre
   * @param {string} uid
   * @returns {Promise<void>}
   * @memberof ThemeService
   */
  async remove(uid: string): Promise<void> {
    return super.delete(uid);
  }

}
