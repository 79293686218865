/**
 * @module Server
 */

import { HttpHeaders, HttpParams } from '@angular/common/http';

export function HttpOptions(tokenAuth: string, tokenData: string, params: { [key: string]: any } = {}): { headers: HttpHeaders, params: HttpParams } {
  const httpParams = new HttpParams({ 
    fromObject: params
  })
  const httpHeaders = new HttpHeaders({ 
    'Content-Type': 'application/json',
    'Authorization': tokenAuth,
    'x-restriction': tokenData
  });
  return { headers: httpHeaders, params: httpParams };
}