/**
 * @module Indicator
 */

import { Component, ViewChild, OnDestroy } from '@angular/core';
import { ItemColor } from '@functions/item.functions';
import { IndicatorClass } from '@class/indicator.class';
import { IndicatorService } from '@services/indicator/indicator.service';
import { AuthService } from '@services/auth/auth.service';
import { LanguageType } from '@models/language.model';
import { Subscription } from 'rxjs';
import { UID } from '@models/uid.model';
import { Router } from '@angular/router';
import { ItemInfoComponent } from '@components/items/item-info/item-info.component';
import { IndicatorEditorComponent } from '@components/indicators/indicator-editor/indicator-editor.component';
import { UserClass } from '@class/user.class';
import { BrowserFiltersComponent } from '@components/browser/browser-filters/browser-filters.component';

@Component({
  selector: 'app-indicators',
  templateUrl: './indicators.component.html',
  styleUrls: ['./indicators.component.css']
})
export class IndicatorsComponent implements OnDestroy {

  @ViewChild('editor', { static: true }) editor: IndicatorEditorComponent;
  @ViewChild('info', { static: true }) info: ItemInfoComponent; 
  @ViewChild('filtersModal', { static: true }) filtersModal: BrowserFiltersComponent; 

  color = ItemColor('indicators');
  current: IndicatorClass;
  language: LanguageType;
  language$sub: Subscription;
  user: UserClass;
  user$sub: Subscription;
  indicators: IndicatorClass[];
  indicators$sub: Subscription;

  hasFilters: boolean;
  onlyBookmarks: boolean;
  bookmarks: UID[];
  tagsList: UID[];
  tags: UID[];
  searchLabel: string;

  constructor(
    private $indicators: IndicatorService,
    private $auth: AuthService,
    private $router: Router
  ) {
    this.hasFilters = false;
    this.onlyBookmarks = false;
    this.bookmarks = [];
    this.tags = [];
    this.searchLabel = '';

    this.language = this.$auth.language$.value;
    this.language$sub = this.$auth.language$.subscribe({
      next: (language: LanguageType) => {
        this.language = language;
      }
    });

    this.indicators$sub = this.$indicators.list$().subscribe({
      next: (indicators: IndicatorClass[]) => {
        if(!!indicators) {
          this.indicators = indicators.sort((a : IndicatorClass, b: IndicatorClass) => { return a.labels.value(this.language).localeCompare(b.labels.value(this.language)) });
          this.tagsList = indicators.length === 0 ? [] : indicators.map(i => i.tags.list as string[])
            .reduce((a, b) => [...a, ...b])
            .filter((item, pos, array) => {
              return array.indexOf(item) == pos;
            });
        }
      }
    });

    this.user$sub = this.$auth.user$.subscribe({
      next: (user: UserClass) => {
        this.user = user;
      }
    });


    this.current = this.$indicators.create();

  }

  ngOnDestroy() {
    this.indicators$sub.unsubscribe();
    this.language$sub.unsubscribe();
    this.user$sub.unsubscribe();
  }

  trackByUid(index: number, indicator: IndicatorClass) {
    return indicator.uid.value;
  }

  onImport() {
    const panel = this.$router.parseUrl(this.$router.url).queryParams.panel;
    this.$router.navigate([`/import-studio`], { queryParams: { from: 'browser', panel: panel } });
  }

  receiveCreate() {
    if(this.current.uid.isRegistered || this.current.serverLink.uid.length === 0) this.current = this.$indicators.create();
    this.editor.show();
  }

  receiveEdit(uid: UID) {
    this.current = this.indicators.filter((indicator: IndicatorClass) => {
      return indicator.uid.value === uid;
    })[0];
    this.editor.show();
  }

  receiveInfo(uid: UID) {
    this.current = this.indicators.filter((indicator: IndicatorClass) => {
      return indicator.uid.value === uid;
    })[0];
    this.info.show();
  }

  receiveSudio(uid: UID) {
    const panel = this.$router.parseUrl(this.$router.url).queryParams.panel;
    this.$router.navigate([`/indicator-studio`], { queryParams: { from: 'browser', panel: panel, uid: uid } });
  }

  receiveSearch() {
    this.filtersModal.show();
  }

  receiveBookmark(onlyBookmarks?: boolean) {
    this.onlyBookmarks = onlyBookmarks === undefined ? !this.onlyBookmarks : onlyBookmarks;
    if(this.onlyBookmarks) {
      this.bookmarks = this.user.bookmarks.list('indicators') as UID[];
    }
    else {
      this.bookmarks = [];
    }
    this.setHasFilters();
  }

  receiveTags(tags: UID[]) {
    this.tags = tags;
    this.setHasFilters();
  }

  receiveSearchLabel(searchLabel: string) {
    this.searchLabel = searchLabel;
    this.setHasFilters();
  }

  onRemoveFilters() {
    this.filtersModal.removeFilters();
    this.bookmarks = [];
    this.onlyBookmarks = false;
    this.tags = [];
    this.searchLabel = '';
    this.setHasFilters();
  }

  setHasFilters() {
    this.hasFilters = this.searchLabel.length > 0
    || this.tags.length > 0
    || this.onlyBookmarks;
  }

}
