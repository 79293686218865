<div *ngIf="widgetLayers?.indicators?.length > 0">

  <hr>

  <!-- INDICATORS -->
  <div class="mt-3">
    <app-widget-indicators-list [selected]="selectedIndicator" [widgetLayers]="widgetLayers" [current]="currentIndicator" (sendCurrent)="receiveCurrentIndicator($event)"></app-widget-indicators-list>
  </div>
  <!-- ./INDICATORS -->

  <hr>

  <!-- LAYERS -->
  <div class="mt-3">
    <app-widget-layers-list [selected]="selectedLayer" [widgetLayers]="widgetLayers" (sendCurrent)="receiveCurrentLayer($event)"></app-widget-layers-list>
  </div>
  <!-- ./LAYERS -->
</div>