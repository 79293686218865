/**
 * @module Share
 */

import { ShareModel, ShareType } from '@models/share.model';
import { ModificationClass, ModificationStateClass } from '@class/modification.class';
import { DeepCopy } from '@functions/copy.functions';

export class ShareClass extends ModificationClass<ShareModel> {

  protected _parent: ShareModel;
  protected _inital: ShareModel;
  protected _attributes: string[];
  
  constructor(share: ShareModel, state: ModificationStateClass) {

    super();
    
    this._parent = share;
    this._attributes = this.attributes;
    this._state = state;

    this._inital = DeepCopy({ share: this._parent.share });

  }

  get value(): Readonly<ShareType> {
    return this._parent.share;
  }

  get class(): Readonly<string> {
    return 'share';
  }

  get attributes(): string[] {
    return ['share'];
  }

}