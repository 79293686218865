/**
 * @module Backend
 */

import { Injectable } from '@angular/core';
import { TokenService } from '@services/token/token.service';
import { AuthService } from '@services/auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { ServerUrlModel, ServerResponseModel } from '@models/server.model';
import { throwError } from 'rxjs';
import { HttpOptions } from '@functions/server.functions';
import { take, map } from 'rxjs/operators';
import { HandleErrors } from '@functions/errors.functions';
import { UID } from '@models/uid.model';
import { ENVIRONMENT } from '@consts/params.const';
import { FirebaseService } from '@services/firebase/firebase.service';

const ROUTE = 'collections';

@Injectable({
  providedIn: 'root'
})
export class BackendCollectionsService {

  constructor(
    private $token: TokenService,
    private $auth: AuthService,
    private $http: HttpClient,
    private $firebase: FirebaseService
  ) {}

  async delete(uidIndicator: UID, server: ServerUrlModel): Promise<ServerResponseModel> {

    return this.$firebase.angularFireFunctions.httpsCallable('deleteCollections')({
      dataset: server.uid,
      table: uidIndicator,
      token: this.$auth.token$.value.token 
    }).toPromise()
    .then(result => {
      return result;
    })
    .catch(error => {
      console.log(error);
    });

/*
    let tokenData = await this.$token.get(server).catch((error) => { 
      return throwError('OBJECT_NOT_FOUND/SERVER').toPromise();
    });
    if(tokenData) {
      return this.$http.delete<ServerResponseModel>(`https://${server.url}/${ROUTE}`,
        HttpOptions(this.$auth.token$.value.token, tokenData.token, 
          { 
            collection: uidIndicator
          })
      ).pipe(
        take(1),
        map((response: ServerResponseModel) => {
          if(ENVIRONMENT.type !== 'production') {
            if(response.success) {
              console.groupCollapsed(`Backend DELETE ${ROUTE} [${uidIndicator}] ['success'] ${response.message} collections`);
              console.log(response.message);
              console.groupEnd();
            }
            else {
              console.log(`Backend DELETE ${ROUTE} [${uidIndicator}] ['error'] ${response.message}`);
            }
          }
          return response;
        }),
        HandleErrors()
      ).toPromise();
    }*/
  }

}