/**
 * @module Import
 */

import { Component, Input } from '@angular/core';
import { ImportFrameWorker } from '@models/worker.model';
import { ItemColor } from '@functions/item.functions';

@Component({
  selector: 'app-import-progress',
  templateUrl: './import-progress.component.html',
  styleUrls: ['./import-progress.component.css']
})
export class ImportProgressComponent {

  @Input() worker: ImportFrameWorker;

  titles = {
    'wReadFile': 'WORKER_READ_FILE', 
    'wDetectTypes': 'WORKER_DETECT_TYPE', 
    'wSelectScope': '', 
    'wSetupFrames': 'WORKER_SETUP_FRAMES', 
    'wSetupLinks': 'WORKER_SETUP_LINKS', 
    'wManageFrames': '', 
    'wPutServer': 'WORKER_PUT_SERVER'
  };

  color = ItemColor('indicators');

  constructor() {}

}
