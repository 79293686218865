/**
 * @module Right
 */

import { RightsModel, RightModel, GroupsModel } from '@models/right.model';
import { UID, UidModel } from '@models/uid.model';
import { MapClass } from '@class/map.class';
import { ModificationClass, ModificationStateClass } from '@class/modification.class';
import { UidClass } from '@class/uid.class';
import { LabelsClass } from '@class/labels.class';
import { DescriptionsClass } from '@class/descriptions.class';
import { LinksClass } from '@class/links.class';
import { LogsClass } from '@class/logs.class';
import { TagsClass } from '@class/tag.class';
import { LabelsModel } from '@models/labels.model';
import { DescriptionsModel } from '@models/descriptions.model';
import { LinksModel } from '@models/links.model';
import { LogsModel } from '@models/logs.model';
import { TagsModel } from '@models/tag.model';
import { DeepCopy } from '@functions/copy.functions';

export class RightsClass extends ModificationClass<RightsModel> {

  protected _parent: RightsModel;
  protected _inital: RightsModel;
  protected _attributes: string[];

  private _readers: MapClass<boolean>;

  private _writers: MapClass<boolean>;

  constructor(rights: RightsModel, state: ModificationStateClass) {

    super();
    
    this._parent = rights;
    this._attributes = this.attributes;
    this._state = state;

    if(this._parent.readers === undefined) this._parent.readers = {};
    if(this._parent.writers === undefined) this._parent.writers = {};

    this._readers = new MapClass(this._parent.readers);
    this._writers = new MapClass(this._parent.writers);

    this._inital = DeepCopy({ 
      readers: this._parent.readers, 
      writers: this._parent.writers 
    });

  }

  get class(): Readonly<string> {
    return 'rights';
  }

  get attributes(): string[] {
    return ['readers', 'writers'];
  }

  get readers(): Readonly<UID[]> {
    return this.list();
  }

  get writers(): Readonly<UID[]> {
    return this.list(true);
  }

  list(writer: boolean = false): Readonly<UID[]> {
    if(writer) return this._writers.keys;
    else return this._readers.keys;
  }

  add(uid: UID, writer: boolean = false) {
    this.remove(uid);
    if(writer) this._writers.add(uid, true);
    else this._readers.add(uid, true);
    this.emit(this.attributes);
  }

  remove(uid: UID) {
    this._writers.remove(uid);
    this._readers.remove(uid);
    this.emit(this.attributes);
  }

  has(uid: UID, writer: boolean = false): Readonly<boolean> {
    if(writer) return this._writers.has(uid);
    else return this._readers.has(uid);
  }

}

export class RightClass extends ModificationClass<RightModel> {
  
  private _uid: UidClass;
  private _labels: LabelsClass;
  private _descriptions: DescriptionsClass;
  private _links: LinksClass;
  private _logs: LogsClass;
  private _tags: TagsClass;

  protected _parent: RightModel;
  protected _inital: RightModel;
  protected _attributes: string[];

  private _isRegistered: boolean;

  constructor(right: RightModel, isRegistered: boolean = true) {

    super();

    this._parent = right;
    this._isRegistered = isRegistered;

    this._reset(this._parent);

    this._attributes = this.attributes.slice();
    this._attributes.push(...this._uid.attributes);
    this._attributes.push(...this._labels.attributes);
    this._attributes.push(...this._descriptions.attributes);
    this._attributes.push(...this._links.attributes);
    this._attributes.push(...this._logs.attributes);
    this._attributes.push(...this._tags.attributes);

  }

  private _reset(right: RightModel) {
    this._uid = new UidClass(right as UidModel, this._isRegistered);
    this._labels = new LabelsClass(right as LabelsModel, this._form, this._requirements, this._state);
    this._descriptions = new DescriptionsClass(right as DescriptionsModel, this._form, this._requirements, this._state);
    this._links = new LinksClass(right as LinksModel, this._state);
    this._logs = new LogsClass(right as LogsModel, this._state);
    this._tags = new TagsClass(right as TagsModel, this._state);

    this.attributes.forEach((attribute: string) => {
      this._parent[attribute] = right[attribute];
    });

    this._inital = DeepCopy(right);

    this._state.flush();
    this._modifications$.next(this._state);
  }

  reset() {
    this._state.flush();
    this._reset(this._inital);
  }

  get attributes(): Readonly<string[]> {
    return [];
  }

  get class(): string {
    return 'right';
  }

  get uid(): UidClass {
    return this._uid;
  }

  get labels(): LabelsClass {
    return this._labels;
  }

  get descriptions(): DescriptionsClass {
    return this._descriptions;
  }

  get links(): LinksClass {
    return this._links;
  }

  get logs(): LogsClass {
    return this._logs;
  }

  get tags(): TagsClass {
    return this._tags;
  }

}

export class GroupsClass extends ModificationClass<GroupsModel> {

  protected _parent: GroupsModel;
  protected _inital: GroupsModel;
  protected _attributes: string[];

  private _groups: MapClass<boolean>;

  constructor(groups: GroupsModel, state: ModificationStateClass) {

    super();
    
    this._parent = groups;
    this._attributes = this.attributes;
    this._state = state;

    if(this._parent.groups === undefined) this._parent.groups = {};

    this._groups = new MapClass(this._parent.groups);

    this._inital = DeepCopy({ groups: this._parent.groups });

  }

  get class(): Readonly<string> {
    return 'groups';
  }

  get attributes(): string[] {
    return ['groups'];
  }

  get list(): Readonly<UID[]> {
    return this._groups.keys;
  }

  add(uid: UID) {
    this._groups.add(uid, true);
    this.emit(this.attributes);
  }

  remove(uid: UID) {
    this._groups.remove(uid);
    this.emit(this.attributes);
  }

  has(uid: UID): Readonly<boolean> {
    return this._groups.has(uid);
  }

  hasAny(uids: UID[]): Readonly<boolean> {
    return this._groups.hasAny(uids);
  }

}