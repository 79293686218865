<div class="m-2 app-item-tile rounded app-box-shadow-depth-1 animated {{animation}}">
  <div class="app-item-tile-header app-tile-back {{color}} p-3 d-flex align-items-center justify-content-center" [ngStyle]="{ 'background-color': color }">
    <div class="d-flex align-items-center justify-content-center app-item-tile-icon white rounded-circle">
      <i class="fa-fw fa-4x {{icon}} {{color}}-text" [ngStyle]="{ 'color': color }"></i>
    </div>
  </div>
  <div class="white p-3">
    <div class="font-small font-weight-bold text-center text-elegant app-item-tile-label">
      {{labels.value(language)}}
    </div>
    <div *ngIf="uid != undefined && hasRole && isWriter" class="d-none d-sm-block text-black-25 text-monospace font-extra-small text-center mt-2">{{ uid.value }}</div>
    <button *ngIf="isDisplayable" (click)="onDisplay()" type="button" block="true" mdbBtn color="light" class="z-depth-0 blue-grey-text mt-2 d-flex px-4 align-items-center" mdbWavesEffect>
      <div>{{ 'TO_DISPLAY' | translate }}</div><i class="ml-auto far fa-arrow-right"></i>
    </button>
    <button *ngIf="isSlides" (click)="onSlides()" type="button" block="true" mdbBtn color="light" class="z-depth-0 blue-grey-text mt-1 d-flex px-4 align-items-center" mdbWavesEffect>
      <div>{{ 'SLIDES' | translate }}</div><i class="ml-auto far fa-presentation"></i>
    </button>
    <button (click)="onStudio()" *ngIf="isStudio && hasRole && isWriter" type="button" block="true" mdbBtn color="light" class="z-depth-0 blue-grey-text mt-1 d-flex px-4 align-items-center" mdbWavesEffect>
      <div>{{ 'STUDIO' | translate }}</div><i class="ml-auto far fa-drafting-compass"></i>
    </button>
    <button (click)="onEdit()" *ngIf="hasRole && isWriter && !isModified" type="button" block="true" mdbBtn color="light" class="z-depth-0 blue-grey-text mt-1 d-flex px-4 align-items-center" mdbWavesEffect>
      <div>{{ 'SETTINGS' | translate }}</div><i class="ml-auto far fa-sliders-h"></i>
    </button>
    <button (click)="onEdit()" *ngIf="hasRole && isWriter && isModified" type="button" block="true" mdbBtn color="warning" class="z-depth-0 mt-1 d-flex px-4 align-items-center" mdbWavesEffect>
      <div>{{ 'UNSAVED' | translate }}</div><i class="ml-auto far fa-sliders-h"></i>
    </button>
  </div>
  <div class="elegant-color-dark p-3">
    <div class="d-flex">

      <button [disabled]="!isInfo" (click)="onInformations()" type="button" mdbBtn size="sm" color="info" class="app-box-shadow-depth-1 my-0 ml-0 mr-1 px-2" mdbWavesEffect>
        <i class="fas fa-info fa-fw"></i>
      </button>

      <button *ngIf="!isBookmark" (click)="onBookmark()" type="button" mdbBtn size="sm" color="elegant" class="app-box-shadow-depth-1 my-0 ml-0 mr-2 px-2" mdbWavesEffect>
        <i class="far fa-bookmark fa-fw"></i>
      </button>

      <button *ngIf="isBookmark" (click)="onBookmark()" type="button" mdbBtn size="sm" color="purple" class="app-box-shadow-depth-1 my-0 ml-0 mr-2 px-2" mdbWavesEffect>
        <i class="fas fa-bookmark fa-fw"></i>
      </button>
      
      <app-tags-icons-list [tags]="tags" [limit]="(hasRole && isWriter) ? 4 : 7"></app-tags-icons-list>

      <app-links-item-option class="ml-auto" *ngIf="hasRole && isWriter" [shadow]="true" [symbol]="'glasses'" [selected]="rights.readers.length > 0" [selectable]="false"></app-links-item-option>
      <app-links-item-option class="app-item-tile-writers" *ngIf="hasRole && isWriter" [shadow]="true" [symbol]="'pencil'" [selected]="rights.writers.length > 0" [selectable]="false"></app-links-item-option>
    </div>
  </div>
</div>