<app-links-item [bottomMargin]="!isLast" [color]="color" [icon]="icon" [labels]="indicator.labels" [uid]="indicator.uid">
  <div app-links-item-content-description>
    <div [mdbTooltip]="'FORMULA_ID' | translate" placement="bottom" class="float-left">
      <i class="fas fa-flask mr-1 fa-swap-opacity font-small"></i>
      <span class="font-very-small text-monospace mr-1">{{ indicator.formulaUID }}</span>
      <mdb-badge info="true" class="z-depth-0" *ngIf="indicator.isFormula">{{ 'FORMULA' | translate }}</mdb-badge>
    </div>
  </div>
  <div app-links-item-content-option class="d-flex align-items-center">
    <app-links-item-option class="d-block mx-1" [symbol]="'table'" [selected]="indicator.inTable" (sendSelected)="receiveInTable()"></app-links-item-option>
    <app-links-item-option class="d-block mx-1" [symbol]="'eye'" [selected]="indicator.display" (sendSelected)="receiveDisplay()"></app-links-item-option>
    <button *ngIf="selected" (click)="onEdit()" type="button" mdbBtn [color]="color" size="sm" class="z-depth-0 d-flex px-2 align-items-center my-0 mr-0 ml-2" style="width: 30px; height: 31px;" mdbWavesEffect>
      <i class="fas fa-fw fa-sliders-h-square"></i>
    </button>
    <button *ngIf="!selected" (click)="onEdit()" type="button" mdbBtn color="blue-grey" size="sm" class="z-depth-0 d-flex px-2 align-items-center my-0 mr-0 ml-2" style="width: 30px; height: 31px;" mdbWavesEffect>
      <i class="fas fa-fw fa-sliders-h-square"></i>
    </button>
  </div>
</app-links-item>