<div class="d-flex flex-column align-items-center rounded blue-grey lighten-{{ dark }} p-3 mb-1">
  
  <div class="d-flex align-items-center w-100">

    <div class="flex-grow-1 blue-grey-text font-weight-bolder font-small d-flex flex-column">
      <div>{{ label | uppercase | translate }}</div>
      <div class="font-extra-small app-widget-option-category">
        <span *ngFor="let category of categories ; let isLast = last">{{ category | translate | lowercase }}<span *ngIf="!isLast">&nbsp;-&nbsp;</span></span>
        <span *ngIf="information">&nbsp;-&nbsp;{{ information | translate | lowercase }}</span>
      </div>
      <div *ngIf="limitedTo.length > 0" class="app-cursor-pointer mr-auto mt-2 font-extra-small"
        [mdbPopover]="LIMITEDTO"
        placement="top"
        triggers="focus"
        mdbPopoverHeader="{{ 'LIMITED_TO_CHART' | translate }}">
        <i class="fad fa-info-square mr-1"></i>{{ 'CHARTS' | translate }}
      </div>
    </div>

    <!-- CONTENT -->
    <ng-content select="[app-widget-option-content]"></ng-content>
    <!-- ./CONTENT -->

  </div>

  <div *ngIf="sublabel != undefined" class="font-very-small font-weight-bolder rounded d-flex align-items-center w-100 mt-2 px-2 app-widget-option-subcontent">
    <div class="blue-grey-text mr-auto">{{ sublabel | translate }}<span *ngIf="subinformation">&nbsp;-&nbsp;{{ subinformation | translate | lowercase }}</span></div>
    <!-- SUBCONTENT -->
    <ng-content select="[app-widget-option-subcontent]" class="d-block w-100"></ng-content>
    <!-- ./SUBCONTENT -->
  </div>

</div>

<ng-template #LIMITEDTO>
  <div *ngFor="let chart of limitedTo">{{ chart | chart | uppercase | translate }}</div>
</ng-template>