/**
 * @module Server
 */

import { Injectable } from '@angular/core';
import { ServerClass } from '@class/server.class';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { ServerModel, ServerUrlModel } from '@models/server.model';
import { map} from 'rxjs/operators';
import { FirestoreService } from '@services/firestore/firestore.service';
import { AuthService } from '@services/auth/auth.service';
import { FirebaseService } from '@services/firebase/firebase.service';
import { UidService } from '@services/uid/uid.service';
import { ItemType } from '@models/item.model';
import { LinksModelConstructor } from '@functions/link.functions';

@Injectable({
  providedIn: 'root'
})
export class ServerService extends FirestoreService<ServerModel> {

  /**
   * @description type de document manipulé par le service
   * @protected
   * @type {ItemType}
   * @memberof ServerService
   */
  protected _itemType: ItemType = 'servers';

  /**
   * @description true si l'accès à ce type de document peut nécessiter des habilitations
   * @protected
   * @type {boolean}
   * @memberof ServerService
   */
  protected _restricted: boolean = false;

  /**
   * @description Creates an instance of ServerService
   * @param {UidService} $uid
   * @param {AuthService} $auth
   * @param {FirebaseService} $firebase
   * @memberof ServerService
   */
  constructor(
    private $uid: UidService,
    protected $auth: AuthService,
    protected $firebase: FirebaseService
  ) {
    super($firebase, $auth);
  }

  /**
   * @description Observateur de la liste des objects autorisés pour l'utilisateur courant
   * @returns {BehaviorSubject<ServerClass[]>}
   * @memberof ServerService
   */
  list$(): BehaviorSubject<ServerClass[]> {
    return super.full$().pipe(
      map((servers: ServerModel[]) => {
        if(!!servers) {
          let ds = servers.map<ServerClass>((server: ServerModel) => {
            return new ServerClass(server, true);
          });
          return ds;
        }
      })
    ) as BehaviorSubject<ServerClass[]>;
  }

  /**
   * @description Observateur d'un document dont l'uid est passé en paramètre
   * @param {string} uid
   * @returns {Observable<ServerClass>}
   * @memberof ServerService
   */
  get$(uid: string): Observable<ServerClass> {
    return super.doc$(uid).pipe(
      map((server: ServerModel) => {
        return new ServerClass(server, true);
      })
    );
  }

  /**
   * @description Observateur de la liste des objects (données partielles) autorisés pour l'utilisateur courant
   * @returns {Observable<ServerClass[]>}
   * @memberof ServerService
   */
  listPartials$(): Observable<ServerClass[]> {
    return super.fullPartial$().pipe(
      map((servers: ServerModel[]) => {
        if(!!servers) {
          let ds = servers.map<ServerClass>((server: ServerModel) => {
            return new ServerClass(server, true);
          });
          return ds;
        }
      })
    );
  }

  /**
   * @description Observateur d'un document (données partielles) dont l'uid est passé en paramètre
   * @param {string} uid
   * @returns {Observable<ServerClass>}
   * @memberof ServerService
   */
  getPartial$(uid: string): Observable<ServerClass> {
    return super.partial$(uid).pipe(
      map((server: ServerModel) => {
        if(server !== null) return new ServerClass(server, true);
      })
    );
  }

  /**
   * @description Créé un nouveau document avec les valeurs par défaut
   * @returns {ServerClass}
   * @memberof ServerService
   */
  create(): ServerClass {
    return new ServerClass({ 
      uid: this.$uid.create(),
      links: LinksModelConstructor().links,
      logs: {},
      tags: {},
      share: 's',
      url: '',
      main: false
    } as ServerModel, false);
  }

  /**
   * @description Créé ou met à jour le document passé en paramètre
   * @param {ServerClass} server
   * @returns {Promise<void>}
   * @memberof ServerService
   */
  async set(server: ServerClass): Promise<void> {
    return super.update(server.uid.value, server.model);
  }

  /**
   * @description Supprime le document passé en paramètre
   * @param {string} uid
   * @returns {Promise<void>}
   * @memberof ServerService
   */
  async remove(uid: string): Promise<void> {
    return super.delete(uid);
  }

}
