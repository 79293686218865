/**
 * @module Moment
 */

import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-moment',
  templateUrl: './moment.component.html',
  styleUrls: ['./moment.component.css']
})
export class MomentComponent {

  @Input() value: string;
  @Input() label: string = 'DATE_FORMAT';
  @Input() color: string;
  @Output() sendValue: EventEmitter<string>;

  constructor() {
    this.sendValue = new EventEmitter<string>();
  }

  onInput(value: string) {
    this.sendValue.emit(value.length === 0 ? null : value);
  }

}
