/**
 * @module Widget
 */

import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { WidgetIndicatorClass } from '@class/widgetIndicator.class';
import { IndicatorService } from '@services/indicator/indicator.service';
import { IndicatorClass } from '@class/indicator.class';
import { take } from 'rxjs/operators';
import { PivotService } from '@services/pivot/pivot.service';
import { PivotClass } from '@class/pivot.class';
import { UID } from '@models/uid.model';
import { ItemsColors, ItemsIcons } from '@functions/item.functions';
import { LanguageType } from '@models/language.model';
import { Subscription } from 'rxjs';
import { AuthService } from '@services/auth/auth.service';

@Component({
  selector: 'app-widget-indicators-filters',
  templateUrl: './widget-indicators-filters.component.html',
  styleUrls: ['./widget-indicators-filters.component.css']
})
export class WidgetIndicatorsFiltersComponent implements OnInit, OnDestroy {

  @Input() indicator: WidgetIndicatorClass;

  pivots: PivotClass[] = [];
  loading: boolean;
  current: UID;

  language: LanguageType;
  language$sub: Subscription;

  ngOnDestroy() {
    this.language$sub.unsubscribe();
  }

  colors = ItemsColors(true);
  icons = ItemsIcons();

  constructor(
    private $indicators: IndicatorService,
    private $pivots: PivotService,
    private $auth: AuthService
  ) {
    this.loading = true;
    this.language$sub = this.$auth.language$.subscribe({
      next: (language: LanguageType) => {
        this.language = language;
      }
    });
  }

  ngOnInit() {
    this.$indicators.getPartial$(this.indicator.uid.value)
    .pipe(take(1))
    .subscribe((indicator: IndicatorClass) => {
      if(!!indicator) {
        indicator.links.list('pivots').forEach((uid: UID) => {
          this.$pivots.getPartial$(uid).pipe(take(1)).subscribe((p: PivotClass) => {
            this.pivots.push(p);
            this.loading = false;
            this.pivots.sort((a, b) => {
              if(a.labels.value(this.language) < b.labels.value(this.language)) { return -1; }
              if(a.labels.value(this.language) > b.labels.value(this.language)) { return 1; }
              return 0;
            });
          });
        });
      }
    });
  }

  onEdit(uid: UID) {
    this.current = this.current === uid ? undefined : uid;
  }

  receiveSelect(uid: UID) {
    this.onEdit(uid);
  }

}
