<div class="app-password-logo-swapp animated slideInLeft"></div>

<div mdbModal #authModal="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="authModal" aria-hidden="true"
  [config]="{ignoreBackdropClick: true, keyboard: false, show: true}">
  <div class="modal-dialog modal-sm modal-dialog-centered modal-dialog-scrollable" role="document">
    <div class="modal-content">

      <div class="modal-body p-0">
        <div class="d-flex flex-column">
            <div class="px-3 pt-3 d-flex justify-content-center flex-column col-12">
                <img style="height: 140px;" class="animated fadeInUp" src="assets/images/logo.svg">
      
                <span class="mt-3">
                  <mdb-card-title class="mb-0">
                    <h3 class="text-elegant text-center">
                      <strong>{{ 'RESET_PASSWORD' | translate }}</strong>
                    </h3>
                  </mdb-card-title>
        
                  <h6 class="mb-0 text-muted text-center">
                    <strong>Swapp.Dash</strong>
                  </h6>
                </span>
              </div>
          <div class="p-4 col-12 app-password-height">
            <div class="md-form md-outline my-0">
              <input type="password" placeholder="{{ 'YOUR_NEW_PASSWORD' | translate }}" id="passwordResetId" class="form-control" mdbInput [(ngModel)]="password">
            </div>

            <div *ngIf="password.length < 6" class="mb-2 alert alert-warning font-small text-center animated fadeIn" role="alert">
              {{ 'PASSWORD_ERROR/TEXT' | translate:{ length: 6 } }}
            </div>

            <div *ngIf="password.length >= 6" class="mb-2 alert alert-success font-small text-center animated fadeIn" role="alert">
              {{ 'PASSWORD_VALID/TEXT' | translate }}
            </div>
        
            <button [disabled]="password.length < 6" block="true" type="button" class="z-depth-0" mdbBtn color="primary" (click)="setPassword()" mdbWavesEffect>
              <i *ngIf="isWaiting" class="fas fa-circle-notch fa-spin mr-1"></i>
              {{ (isWaiting ? '' : 'SAVE') | translate }}
            </button>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</div>

<app-version></app-version>