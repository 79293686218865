/**
 * @module User
 */

import { Component, Input } from '@angular/core';
import { UserIdClass } from '@class/user.class';
import { ItemColor } from '@functions/item.functions';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.css']
})
export class EmailComponent {

  @Input() userId: UserIdClass;
  @Input() color: string = ItemColor('users');

  constructor() {}

  onEmail(email: string) {
    this.userId.change({ email: email });
  }

}
