/**
 * @module Tag
 */

import { Component, Input, OnDestroy } from '@angular/core';
import { TagsClass, TagClass } from '@class/tag.class';
import { Subscription } from 'rxjs';
import { UID } from '@models/uid.model';
import { TagService } from '@services/tag/tag.service';
import { LanguageType } from '@models/language.model';
import { AuthService } from '@services/auth/auth.service';
import { ItemColor } from '@functions/item.functions';

@Component({
  selector: 'app-tags-links-list',
  templateUrl: './tags-links-list.component.html',
  styleUrls: ['./tags-links-list.component.css']
})
export class TagsLinksListComponent implements OnDestroy {

  @Input() tags: TagsClass;

  color = ItemColor('tags');
  tags$sub: Subscription;
  list: TagClass[];
  searchLabel: string;
  language: LanguageType;
  language$sub: Subscription;

  constructor(
    private $tags: TagService,
    private $auth: AuthService
  ) {
    this.tags$sub = this.$tags.listPartials$().subscribe({
      next: (tags: TagClass[]) => {
        this.list = tags;
      }
    });

    this.language$sub = this.$auth.language$.subscribe({
      next: (language: LanguageType) => {
        this.language = language;
      }
    });
  }

  receiveTextValue(searchLabel: string) {
    this.searchLabel = searchLabel;
  }

  ngOnDestroy() {
    this.tags$sub.unsubscribe();
  }

  isSelected(uid: UID): boolean {
    return this.tags.has(uid);
  }

  receiveSelected(uid: UID) {
    if(this.tags.has(uid)) this.tags.remove(uid);
    else this.tags.add(uid);
  }

}
