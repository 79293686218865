<div class="mt-3">
  <app-input [marginTop]="false" [label]="'SEARCH'" [color]="color" [value]="searchLabel" (sendValue)="receiveTextValue($event)"></app-input>

  <app-pivots-links-item *ngFor="let uid of uids" [uid]="uid" [searchLabel]="searchLabel"
  [links]="links"
  (sendSelected)="receiveSelected($event)"
  (sendLinkChange)="receiveLinkChange($event)"
  ></app-pivots-links-item>

</div>

<app-empty app-browser-frame-content-list [action]="false" [type]="'pivots'" *ngIf="uids != undefined && uids.length == 0"></app-empty>
<app-loading app-browser-frame-content-list *ngIf="uids == undefined"></app-loading>