<div class="d-flex align-items-center justify-content-between mt-3 app-widget-studio-sub-indicators-title">
  <div class="blue-grey-text font-weight-bold">
    <i class="fa-lg {{icon}} {{color}}-text mr-1"></i>
    {{ widgetLayers.indicators[current].labels.value(language) | translate }}
  </div>
  <div class="btn-group" mdbDropdown>
    <button mdbDropdownToggle type="button" size="sm" mdbBtn color="light" class="blue-grey-text z-depth-0 dropdown-toggle waves-light d-flex align-items-center px-3" mdbWavesEffect>
      <i class="far fa-bars"></i>&nbsp;
    </button>
  
    <div class="dropdown-menu dropdown-dark dropdown-menu-right">

      <a class="dropdown-item z-depth-0" (click)="editorIndex = 0">{{ 'SETTINGS' | translate }}</a>
      <a class="dropdown-item z-depth-0" (click)="editorIndex = 1">{{ 'DISPLAY' | translate }}</a>
      <a class="dropdown-item z-depth-0" (click)="editorIndex = 2">{{ 'DATALABELS' | translate }}</a>
      <a class="dropdown-item z-depth-0" (click)="editorIndex = 3">{{ 'FILTERS' | translate }}</a>

    </div>
  </div>
</div>

<hr>

<div [ngSwitch]="editorIndex">
  <div *ngSwitchCase="0">
    <app-editor-section-title [title]="'SETTINGS'" (sendNext)="receiveNext($event)"></app-editor-section-title>
    <hr class="mt-2">
    <app-widget-indicators-clone [widgetLayers]="widgetLayers" [current]="current"></app-widget-indicators-clone>
    <app-widget-indicators-remove (sendRemove)="receiveDelete()" [widgetLayers]="widgetLayers" [current]="current"></app-widget-indicators-remove>
    <app-widget-indicators-display [widgetLayers]="widgetLayers" [current]="current"></app-widget-indicators-display>
    <app-widget-indicators-formula [widgetLayers]="widgetLayers" [current]="current" class="d-block mb-2"></app-widget-indicators-formula>
    <app-widget-indicators-timeline [widgetLayers]="widgetLayers" [current]="current"></app-widget-indicators-timeline>
    <app-widget-indicator-cumulative [widgetLayers]="widgetLayers" [current]="current"></app-widget-indicator-cumulative>
    <app-widget-indicators-label [widgetLayers]="widgetLayers" [current]="current"></app-widget-indicators-label>
  </div>
  <div *ngSwitchCase="1">
    <app-editor-section-title [title]="'DISPLAY'" (sendNext)="receiveNext($event)"></app-editor-section-title>
    <hr class="mt-2">
    <app-widget-indicators-serie [widgetLayers]="widgetLayers" [themeOptions]="themeOptions" [current]="current"></app-widget-indicators-serie>
    <app-widget-indicators-axis [widgetLayers]="widgetLayers" [current]="current"></app-widget-indicators-axis>
    <app-widget-indicators-stack [widgetLayers]="widgetLayers" [current]="current"></app-widget-indicators-stack>
    <app-widget-indicators-type [widgetLayers]="widgetLayers" [current]="current"></app-widget-indicators-type>
    <app-widget-indicators-icon [widgetLayers]="widgetLayers" [current]="current"></app-widget-indicators-icon>
  </div>
  <div *ngSwitchCase="2">
    <app-editor-section-title [title]="'DATALABELS'" (sendNext)="receiveNext($event)"></app-editor-section-title>
    <hr class="mt-2">
    <app-widget-indicators-datalabels [widgetLayers]="widgetLayers" [indicator]="widgetLayers.indicators[current]" [current]="current"></app-widget-indicators-datalabels>
  </div>
  <div *ngSwitchCase="3">
    <app-editor-section-title [title]="'FILTERS'" (sendNext)="receiveNext($event)"></app-editor-section-title>
    <hr class="mt-2">
    <app-widget-indicators-filters [indicator]="widgetLayers.indicators[current]"></app-widget-indicators-filters>
  </div>
</div>
