<div class="d-flex w-100">
  <button (click)="filtersModal.show()" size="sm" type="button" mdbBtn color="light" class="z-depth-0 flex-grow-1 m-0 blue-grey-text px-3" mdbWavesEffect>
    <i class="fas fa-filter mr-1"></i>
    <span class="mr-1">{{ 'FILTER' | translate }}</span>
    <mdb-badge info="true" class="z-depth-0" [ngClass]="{ 'app-opacity-50': count == 0 }">{{ count }}</mdb-badge>
  </button>
  <button (click)="onFlush()" size="sm" type="button" mdbBtn color="default" class="z-depth-0 my-0 mr-0 ml-1 px-3" mdbWavesEffect>
    <i class="fas fa-tra150sh"></i>
  </button>
</div>

<div mdbModal #filtersModal="mdbModal" class="modal fade" style="overflow-y: scroll" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header d-flex align-items-center flex-column">

        <i class="animated rotateIn blue-grey-text far fa-filter fa-4x d-none d-sm-inline"></i>
        <i class="animated rotateIn blue-grey-text far fa-filter fa-2x d-sm-none"></i>

        <h6 class="modal-title w-100 text-center">
          <div class="font-weight-bold text-elegant mt-1">&nbsp;{{ label }}&nbsp;</div>
        </h6>

      </div>
      <div class="modal-header">

        <button type="button" size="sm" mdbBtn color="secondary" class="waves-light z-depth-0 m-0 px-2 app-close-modal" (click)="filtersModal.hide()" mdbWavesEffect>
          <i class="fas fa-times fa-fw"></i>
        </button>
        
      </div>
      <div class="modal-body overflow-hidden">

      </div>
    </div>
  </div>
</div>