/**
 * @module Dashboard
 */

import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { DashboardLayoutCellClass } from '@class/dashboardLayoutCell.class';
import { ItemColor } from '@functions/item.functions';
import { UID } from '@models/uid.model';
import { WidgetLayersClass } from '@class/widgetLayers.class';
import { WidgetService } from '@services/widget/widget.service';
import { ThemeService } from '@services/theme/theme.service';
import { WidgetClass } from '@class/widget.class';
import { ThemeOptionsClass } from '@class/themeOptions.class';
import { Subscription } from 'rxjs';
import { DashboardDisplayService } from '@services/dashboardDisplay/dashboard-display.service';
import { DashboardLayoutClass } from '@class/dashboardLayout.class';
import { PivotFiltersClass } from '@class/widgetFilters.class';

@Component({
  selector: 'app-dashboard-cell',
  templateUrl: './dashboard-cell.component.html',
  styleUrls: ['./dashboard-cell.component.css']
})
export class DashboardCellComponent implements OnInit, OnDestroy {

  @Input() dashboardLayout: DashboardLayoutClass;
  @Input() cell: DashboardLayoutCellClass;
  @Input() fullWidth: boolean = false;

  color = ItemColor('dashboards');

  widgetLayers: WidgetLayersClass;
  widgetLayersFilters: WidgetLayersClass;
  widgetNotFound: boolean;

  widget$sub: Subscription;
  theme$sub: Subscription;
  layers$sub: Subscription;
  filters$sub: Subscription;
  display$sub: Subscription;

  constructor(
    private $widgets: WidgetService,
    private $themes: ThemeService,
    private $display: DashboardDisplayService
  ) {
    this.widgetNotFound = false;
  }

  ngOnInit() {
    if(this.cell.uidWidget.length > 0) this.setWidgetLayers();
    this.cell.lastModification$.subscribe((attribute: string) => {
      if(attribute === 'uidWidget' && this.cell.uidWidget.length > 0) this.setWidgetLayers();
    });
  }

  setFilters() {
    if(this.display$sub) this.display$sub.unsubscribe();
    this.display$sub = this.$display.widgetLayers$(this.dashboardLayout.uid.value).subscribe((widgetLayers: WidgetLayersClass) => {
      if(!!widgetLayers) {
        this.widgetLayersFilters = widgetLayers;
        
        if(this.filters$sub) this.filters$sub.unsubscribe();
        this.filters$sub = this.widgetLayersFilters.lastModification$.subscribe((attribute: string) => {
          if(attribute === 'datesBounds' || attribute === 'filters' || attribute === 'dateFormat') {
            this.widgetLayersFilters.filters.forEach((filters: PivotFiltersClass) => {
              this.widgetLayers.filter(filters.uid.value).flush();
              this.widgetLayers.filter(filters.uid.value).setInclude(filters.include);
              filters.values.forEach(v => this.widgetLayers.filter(filters.uid.value).setValue(v));
            });
            Object.keys(this.widgetLayersFilters.bounds).forEach((uid: UID) => {
              const bound = this.widgetLayersFilters.bounds[uid];
              if(this.widgetLayers.bounds[uid] !== undefined) {
                this.widgetLayers.bounds[uid].flush();
                this.widgetLayers.bounds[uid].set('greater', bound.greater.equivalent, bound.greater.value);
                this.widgetLayers.bounds[uid].set('less', bound.less.equivalent, bound.less.value);
              }
            });
            this.widgetLayers.dateFormat = this.widgetLayersFilters.dateFormat;
            this.widgetLayers.emit(['reload']);
          }
        });
      }
    });
  }

  ngOnDestroy() {
    if(this.widget$sub) this.widget$sub.unsubscribe();
    if(this.theme$sub) this.theme$sub.unsubscribe();
    if(this.layers$sub) this.layers$sub.unsubscribe();
    if(this.filters$sub) this.filters$sub.unsubscribe();
    if(this.display$sub) this.display$sub.unsubscribe();
  }

  setWidgetLayers() {
    if(this.widget$sub) this.widget$sub.unsubscribe();
    this.widget$sub = this.$widgets.getPartial$(this.cell.uidWidget).subscribe(
      (widget: WidgetClass) => {
        if(widget !== undefined) {
          this.widgetNotFound = false;
          if(this.theme$sub) this.theme$sub.unsubscribe();
          this.theme$sub = this.$themes.getOptions$(widget.uidTheme).subscribe(
            (theme: ThemeOptionsClass) => {
              if(this.layers$sub) this.layers$sub.unsubscribe();
              this.layers$sub = this.$widgets.getLayers$(widget, theme).subscribe(
                (widgetLayers: WidgetLayersClass) => {
                  this.widgetLayers = widgetLayers;
                  this.$display.add(this.dashboardLayout.uid.value, widgetLayers);
                  this.setFilters();
                }
              )
            }
          )
        }
        else {
          this.widgetNotFound = true;
        }
      }
    )
  }

}
