<div *ngIf="main == true" class="d-flex justify-content-center flex-column mt-3">
  <div class="text-center alert alert-info mb-0 p-3" role="alert">
    <i class="fad fa-lock-alt fa-2x animated rotateIn"></i><br>
    <div class="mt-3">{{ 'DEFAULT_SERVER_LOCKED' | translate }}</div>
  </div>
</div>

<app-form-strip class="d-block mt-3">
  <div app-form-strip-content-left>
    <i *ngIf="main == true" class="fad fa-lg mt-1 fa-fw fa-check animated rotateIn text-success"></i>
  </div>

  <div app-form-strip-content-middle>
    {{ (main == true ? 'DEFAULT_SERVER' : 'NOT_DEFAULT_SERVER') | translate }}
  </div>

  <div app-form-strip-content-right>
    <i *ngIf="main == undefined" class="fa-2x fas fa-circle-notch fa-spin blue-grey-text"></i>
    <label *ngIf="main != undefined && main == false" class="mb-0 w-100 d-flex justify-content-between align-items-center">
      <input type="checkbox" [ngModel]="main" (change)="onMain()">
      <span class="lever mr-0"></span>
    </label>
  </div>
</app-form-strip>