/**
 * @module Theme
 */

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ThemeOptionsClass } from '@class/themeOptions.class';
import { UID } from '@models/uid.model';
import { ThemeOptionsDefault } from '@functions/theme.functions';

@Injectable({
  providedIn: 'root'
})
export class ThemeStudioService {

  private _options: { [key in UID]: ThemeOptionsClass } = {};

  data$: BehaviorSubject<{ labels: string[], series: { data: any[], label: string }[] }>;
  dataBubbles$: BehaviorSubject<{ labels: string[], series: { data: any[], label: string }[] }>;

  private _valuesPerSeries: number = 5;
  private labels: string[];

  constructor(
    private $translate: TranslateService
  ) { 
    this.data$ = new BehaviorSubject<{ labels: string[], series: { data: any[], label: string }[] }>(undefined);
    this.dataBubbles$ = new BehaviorSubject<{ labels: string[], series: { data: any[], label: string }[] }>(undefined);
    this.valuesPerSeries = 5;
  }

  themeOptions(uid: UID, themeOption?: ThemeOptionsClass): ThemeOptionsClass {
    if(this._options[uid] === undefined) {
      //on a ouvert un theme non précédement ouvert dans cette session dans le studio
      if(!!themeOption && !!themeOption.model) this._options[uid] = themeOption;
      else this._options[uid] = new ThemeOptionsClass(ThemeOptionsDefault());
    }
    this.setSeries(this._options[uid].seriesCount());
    return this._options[uid];
  }

  themeDefault(): ThemeOptionsClass {
    return new ThemeOptionsClass(ThemeOptionsDefault());
  }

  get valuesPerSeries(): number {
    return this._valuesPerSeries;
  }

  set valuesPerSeries(valuesPerSeries: number) {
    this._valuesPerSeries = valuesPerSeries;
    this.labels = [...Array(this.valuesPerSeries).fill(this.$translate.instant('LABEL'))].map((label: string, index: number) => `${label} ${index}`);
    this.setSeries(!!this.data$.value ? this.data$.value.series.length : 1);
  }

  setSeries(length: number) {
    this.data$.next({
      labels: this.labels,
      series: [...Array(length).fill(this.$translate.instant('SERIE'))].map((label: string, index: number) => {
        return { data: this.getRandomValues(), label: `${label} ${index}` }
      })
    });
    this.dataBubbles$.next({
      labels: this.labels,
      series: [...Array(length).fill(this.$translate.instant('SERIE'))].map((label: string, index: number) => {
        return { data: this.getRandomValues(true), label: `${label} ${index}` }
      })
    });
  }

  private getRandomValues(forBubbles: boolean = false): any[] {
    if(!forBubbles) return [...Array(this.labels.length)].map(() => Math.floor(Math.random() * 100));
    else return [...Array(this.labels.length)].map(() => { return { x: Math.floor(Math.random() * 100), y: Math.floor(Math.random() * 100), r: Math.floor(Math.random() * 100) } });
  } 

}
