<app-theme-option [categories]="themeOptions.optionsConfiguration[optionType].categories" [label]="themeOptions.optionsConfiguration[optionType].label" [limitedTo]="themeOptions.limitedTo(optionType)">
  <div app-theme-option-content>
    <app-show-details-option [showDetails]="showDetails" (sendShowDetails)="receiveShowDetails($event)">
      <div app-show-details-option-content>
        <app-check [color]="color" [value]="themeOptions.series(serieIndex).backgroundMultiGradiant" (sendValue)="receiveValue($event)"></app-check>
      </div>
    </app-show-details-option>
  </div>
</app-theme-option>

<div *ngIf="showDetails">
  <app-theme-option [categories]="themeOptions.optionsConfiguration[optionType].categoriesAndLabel" [label]="type | chart" [dark]="4" *ngFor="let type of themeOptions.limitedTo(optionType)">
    <div app-theme-option-content>
      <app-check [color]="color" [value]="themeOptions.series(serieIndex).backgroundMultiGradiant" (sendValue)="receiveValue($event, type)"></app-check>        
    </div>
  </app-theme-option>
</div>