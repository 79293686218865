/**
 * @module Widget
 */

import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { WidgetLayersClass } from '@class/widgetLayers.class';
import { UID } from '@models/uid.model';
import { ItemColor } from '@functions/item.functions';
import { WidgetSortTypes } from '@functions/widget.functions';
import { WidgetSortType } from '@models/widgetLayers.model';
import { LanguageType } from '@models/language.model';
import { Subscription } from 'rxjs';
import { AuthService } from '@services/auth/auth.service';
import { LabelsClass } from '@class/labels.class';

@Component({
  selector: 'app-widget-layers-sort',
  templateUrl: './widget-layers-sort.component.html',
  styleUrls: ['./widget-layers-sort.component.css']
})
export class WidgetLayersSortComponent implements OnInit, OnChanges {

  @Input() widgetLayers: WidgetLayersClass;
  @Input() current: UID;

  color = ItemColor('widgets');
  sortTypes: WidgetSortType[];
  showIndicators: boolean;
  sortType: WidgetSortType;

  currentLabel: LabelsClass;
  currentUID: UID;

  language: LanguageType;
  language$sub: Subscription;

  constructor(
    private $auth: AuthService
  ) {
    this.sortTypes = WidgetSortTypes();

    this.language$sub = this.$auth.language$.subscribe({
      next: (language: LanguageType) => {
        this.language = language;
      }
    });
  }

  ngOnInit() {
    this.setParams();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.current !== undefined && !changes.current.firstChange) {
      this.setParams();
    }
  }

  setParams() {
    this.showIndicators = ['value_asc', 'value_desc'].includes(this.widgetLayers.layer(this.current).sort);
    if(this.showIndicators) {
      this.currentUID = this.widgetLayers.layer(this.current).sortUID || this.widgetLayers.indicatorsUID[0];
      this.currentLabel = this.widgetLayers.indicator(this.currentUID).labels;
      this.sortType = this.widgetLayers.layer(this.current).sort;
    }
  }

  onValue(sortType: WidgetSortType) {
    switch (sortType) {
      case 'label_asc':
      case 'label_desc':
        this.showIndicators = false;
        this.onSortLabel(sortType);
        break;
      case 'value_asc':
      case 'value_desc':
        this.sortType = sortType;
        this.showIndicators = true;
        this.onSortValue(this.widgetLayers.layer(this.current).sortUID || this.widgetLayers.indicatorsUID[0]);
        break;
    }
  }

  onSortLabel(sortType: WidgetSortType) {
    this.widgetLayers.layer(this.current).change({ sort: sortType });
    if(this.widgetLayers.uid.value === this.current) this.widgetLayers.duplicateLayersOptions({ sort: sortType }, this.current);
  }

  onSortValue(uid: UID) {
    this.currentUID = uid;
    this.currentLabel = this.widgetLayers.indicator(this.currentUID).labels;
    this.widgetLayers.layer(this.current).change({ sort: this.sortType, sortUID: this.currentUID });
    if(this.widgetLayers.uid.value === this.current) this.widgetLayers.duplicateLayersOptions({ sort: this.sortType, sortUID: this.currentUID }, this.current);
  }

}
