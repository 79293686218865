/**
 * @module User
 */

import { Component, Input } from '@angular/core';
import { UserClass } from '@class/user.class';
import { AuthService } from '@services/auth/auth.service';
import { MessagesService } from '@services/messages/messages.service';

@Component({
  selector: 'app-email-reset',
  templateUrl: './email-reset.component.html',
  styleUrls: ['./email-reset.component.css']
})
export class EmailResetComponent {

  @Input() user: UserClass;

  constructor(
    private $auth: AuthService,
    private $messages: MessagesService
  ) {}

  onReset() {
    this.$auth.sendPasswordResetEmail(this.user.id.email, this.user.language)
    .then(() => {
      this.$messages.success({ title: { plain: this.user.id.fullname }, text: { code: 'RESET_PASSWORD_SEND/TEXT' } });
    })
    .catch((error) => {
      this.$messages.error({ title: { code: 'RESET_PASSWORD_SEND/ERROR' }, text: { code: error.code } });
    });
  }

}
