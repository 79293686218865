/**
 * @module DownloadFileType
 */

import { DownloadFileTypeModel } from '@models/downloadFileType.model';

export function DownloadFileTypes(): Readonly<DownloadFileTypeModel[]> {
  return [
    { main: true, bookType: 'xlsx',	ext:'.xlsx', description: 'Excel 2007+ XML Format' },
    { main: true, bookType: 'csv',	ext:'.csv', description: 'Comma Separated Values' },
    { main: true, bookType: 'txt',	ext:'.txt', description: 'UTF-16 Unicode Text (TXT)' },
    { main: true, bookType: 'biff8',	ext:'.xls', description: 'Excel 97-2004 Workbook Format' },
    { main: false, bookType: 'xlsm',	ext:'.xlsm', description: 'Excel 2007+ Macro XML Format' },
    { main: false, bookType: 'xlsb',	ext:'.xlsb', description: 'Excel 2007+ Binary Format' },
    { main: false, bookType: 'biff5',	ext:'.xls', description: 'Excel 5.0/95 Workbook Format' },
    { main: false, bookType: 'biff2',	ext:'.xls', description: 'Excel 2.0 Worksheet Format' },
    { main: false, bookType: 'xlml',	ext:'.xls', description: 'Excel 2003-2004 (SpreadsheetML)' },
    { main: false, bookType: 'ods',	ext:'.ods', description: 'OpenDocument Spreadsheet' },
    { main: false, bookType: 'fods',	ext:'.fods', description: 'Flat OpenDocument Spreadsheet' },
    { main: false, bookType: 'sylk',	ext:'.sylk', description: 'Symbolic Link (SYLK)' },
    { main: false, bookType: 'html',	ext:'.html', description: 'HTML Document' },
    { main: false, bookType: 'dif',	ext:'.dif', description: 'Data Interchange Format (DIF)' },
    { main: false, bookType: 'rtf',	ext:'.rtf', description: 'Rich Text Format (RTF)' },
    { main: false, bookType: 'prn',	ext:'.prn', description: 'Lotus Formatted Text' },
    { main: false, bookType: 'eth',	ext:'.eth', description: 'Ethercalc Record Format (ETH)' }
  ];
};