/**
 * @module DateFormat
 */

import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { DateFormatsClass } from '@class/dateFormats.class';
import { MaterialChipsComponent } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-date-formats',
  templateUrl: './date-formats.component.html',
  styleUrls: ['./date-formats.component.css']
})
export class DateFormatsComponent implements OnInit, OnChanges {

  @Input() dateFormats: DateFormatsClass;

  @ViewChild('chips', { static: true }) chips: MaterialChipsComponent;

  previous: string[];
  values: string[];

  constructor() {}

  ngOnInit() {
    this.previous = this.dateFormats.list().slice();
    this.values = this.dateFormats.list().slice();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.dateFormats !== undefined && !changes.dateFormats.firstChange) {
      this.previous = this.dateFormats.list().slice();
      this.values = this.dateFormats.list().slice();
    }
  }

  receiveValues(values: string[]) {
    values.forEach((value: string) => {
      this.dateFormats.add(value);
    });
    this.previous.forEach((value: string) => {
      if(!values.includes(value)) this.dateFormats.remove(value);
    });
    this.previous = values.slice();
  }

  onAdd() {
    this.chips.addValue(this.chips.labelToAdd, new Event('add'));
  }

}
