<div class="rounded blue-grey lighten-5 p-sm-3 p-2 d-flex align-items-center flex-column" [ngClass]="{ 'mb-1': bottomMargin }">
  <div class="align-items-center d-flex w-100">
    <div *ngIf="url == undefined && icon !== undefined" [ngStyle]="{ 'background-color': color }" class="rounded-circle mr-sm-3 mr-2 {{color}} d-flex justify-content-center align-items-center app-links-item-icon">
      <i class="fa-lg {{icon}} white-text"></i>
    </div>
    <div *ngIf="url != undefined" class="rounded-circle mr-3 app-links-item-icon">
      <img class="rounded-circle img-fluid" [src]="url">
    </div>
    <div>
      <div *ngIf="labels != undefined || label != undefined" class="blue-grey-text font-weight-bold font-small">{{ labels != undefined ? labels.value(language) : (label | translate) }}</div>
      <div *ngIf="uid != undefined" class="text-black-25 text-monospace font-extra-small d-none d-sm-block">{{ uid.value }}</div>

      <!-- CONTENT DESCRIPTION -->
      <div class="text-muted d-none d-sm-block">
        <ng-content select="[app-links-item-content-description]"></ng-content>
      </div>
      <!-- ./CONTENT DESCRIPTION -->

    </div>
    <!-- CONTENT OPTIONS -->
    <div class="d-flex flex-columns ml-auto">
      <ng-content select="[app-links-item-content-option]"></ng-content>
    </div>
    <!-- ./CONTENT OPTIONS -->
  </div>

  <!-- CONTENT SUB -->
  <div class="text-muted w-100">
    <ng-content select="[app-links-item-content-sub]"></ng-content>
  </div>
  <!-- ./CONTENT SUB -->
</div>