/**
 * @module Backend
 */

import { Injectable } from '@angular/core';
import { TokenService } from '@services/token/token.service';
import { AuthService } from '@services/auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { UID } from '@models/uid.model';
import { ServerUrlModel, ServerResponseModel } from '@models/server.model';
import { throwError } from 'rxjs';
import { HttpOptions } from '@functions/server.functions';
import { take, map } from 'rxjs/operators';
import { ENVIRONMENT } from '@consts/params.const';
import { HandleErrors } from '@functions/errors.functions';
import { FirebaseService } from '@services/firebase/firebase.service';

const ROUTE = 'pivots';

@Injectable({
  providedIn: 'root'
})
export class BackendPivotsService {

  constructor(
    private $token: TokenService,
    private $auth: AuthService,
    private $http: HttpClient,
    private $firebase: FirebaseService
  ) {}

  async get(uidIndicator: UID, server: ServerUrlModel, pivot: UID, applyFilters: boolean = true): Promise<ServerResponseModel> {

    let match: { values: string[], condition: string, column: string }[] = [];
    if(applyFilters) {
      match = this.$auth.user$.value.filters.map(f => {
        return {
          values: f.values as string[],
          condition: f.include ? 'IN' : 'NOT IN',
          column: f.uid.value
        }
      });
    }

    return this.$firebase.angularFireFunctions.httpsCallable('getPivots')({
      dataset: server.uid,
      table: uidIndicator,
      pivot: pivot, 
      match: match,
      applyfilter: applyFilters && match.length > 0 ? "yes" : "no",
      token: this.$auth.token$.value.token 
    }).toPromise()
    .then(result => {
      if(result && result.message && result.message.values && result.message.values.length > 0) result.message.values = result.message.values.filter(r => r !== null);
      return result;
    })
    .catch(error => {
      console.log(error);
    });

    /*
    let tokenData = await this.$token.get(server).catch((error) => { 
      return throwError('OBJECT_NOT_FOUND/SERVER').toPromise();
    });
    if(tokenData) {
      return this.$http.get<ServerResponseModel>(`https://${server.url}/${ROUTE}`,
        HttpOptions(this.$auth.token$.value.token, tokenData.token, 
          { 
            collection: uidIndicator, 
            pivot: pivot,
            applyFilters: applyFilters
          })
      ).pipe(
        take(1),
        map((response: ServerResponseModel) => {
          if(ENVIRONMENT.type !== 'production') {
            if(response.success) {
              console.groupCollapsed(`Backend GET ${ROUTE} [${uidIndicator}] ['success'] ${response.message.values.length} pivots`);
              console.log(response.message);
              console.groupEnd();
            }
            else {
              console.log(`Backend GET ${ROUTE} [${uidIndicator}] ['error'] ${response.message}`);
            }
          }
          return response;
        }),
        HandleErrors()
      ).toPromise();
    }*/
  }

  async post(uidIndicator: UID, server: ServerUrlModel, pivotsAndDates: { [key in UID] : string }): Promise<ServerResponseModel> {
    
    return this.$firebase.angularFireFunctions.httpsCallable('postPivots')({
      dataset: server.uid,
      table: uidIndicator,
      pivots: pivotsAndDates, 
      token: this.$auth.token$.value.token 
    }).toPromise()
    .then(result => {
      return result;
    })
    .catch(error => {
      console.log(error);
    });

    /*
    let tokenData = await this.$token.get(server).catch((error) => { 
      return throwError('OBJECT_NOT_FOUND/SERVER').toPromise();
    });
    if(tokenData) {
      return this.$http.post<ServerResponseModel>(`https://${server.url}/${ROUTE}`, 
      { 
        collection: uidIndicator,
        fields: JSON.stringify(pivotsAndDates)
      },
        HttpOptions(this.$auth.token$.value.token, tokenData.token)
      ).pipe(
        take(1),
        map((response: ServerResponseModel) => {
          if(ENVIRONMENT.type !== 'production') {
            if(response.success) console.log(`Backend POST ${ROUTE} [${uidIndicator}] ['success'] ${response.message.values} documents`);
            else console.log(`Backend POST ${ROUTE} [${uidIndicator}] ['error'] ${response.message}`);
          }
          return response;
        }),
        HandleErrors()
      ).toPromise();
    }*/
  }

  async delete(uidIndicator: UID, server: ServerUrlModel, pivotsAndDates: { [key in UID] : string }): Promise<ServerResponseModel> {
    console.log(pivotsAndDates)

    return this.$firebase.angularFireFunctions.httpsCallable('deletePivots')({
      dataset: server.uid,
      table: uidIndicator,
      pivots: pivotsAndDates, 
      token: this.$auth.token$.value.token 
    }).toPromise()
    .then(result => {
      return result;
    })
    .catch(error => {
      console.log(error);
    });
    /*
    let tokenData = await this.$token.get(server).catch((error) => { 
      return throwError('OBJECT_NOT_FOUND/SERVER').toPromise();
    });
    if(tokenData) {
      return this.$http.delete<ServerResponseModel>(`https://${server.url}/${ROUTE}`,
        HttpOptions(this.$auth.token$.value.token, tokenData.token, { 
          collection: uidIndicator,
          fields: JSON.stringify(pivotsAndDates)
        })
      ).pipe(
        take(1),
        map((response: ServerResponseModel) => {
          if(ENVIRONMENT.type !== 'production') {
            if(response.success) console.log(`Backend DELETE ${ROUTE} [${uidIndicator}] ['success'] ${response.message.values} documents`);
            else console.log(`Backend DELETE ${ROUTE} [${uidIndicator}] ['error'] ${response.message}`);
          }
          return response;
        }),
        HandleErrors()
      ).toPromise();
    }*/
  }

}