/**
 * @module Numbro
 */

import numbro from 'numbro';
import languages from 'numbro/dist/languages.min.js';
import { LanguageType } from '@models/language.model';
import { LanguageCode } from '@functions/language.functions';
import { NumbroFormatType, NumbroFormatModel, NumbroNegativeType, NumbroForceAverageType, NumbroOutputType, NumbroCurrencyPosition } from '@models/numbro.model';
import { isDefined } from '@angular/compiler/src/util';

/**
 * @description Accès à la librairie avec chargement du language en paramètre
 * @export
 * @param {LanguageType} [language]
 * @returns
 */
export function Numbro(language?: LanguageType) {
  if(language !== undefined) {
    const code = LanguageCode(language);
    if(numbro.languages()[code] === undefined) {
      Object.values(languages).forEach(l => numbro.registerLanguage(l as any));
    }
    numbro.setLanguage(code);
  }
}

/**
 * @description renvoie une chaîne au format prédéfini (type) ou au format personnalisé (format) à partir de la valeur (value)
 * @export
 * @param {number} value
 * @param {NumbroFormatType} [type]
 * @param {NumbroFormatModel} [format]
 * @returns {string}
 */
export function NumbroApplyFormat(value: number, type?: NumbroFormatType, format?: NumbroFormatModel): string {
  if(typeof value != 'number') return '';
  else return numbro(value).format(type === undefined ? {} : type === 'personalized' ? format : NumbroFormat(type));
}

/**
 * @description renvoie le format prédéfini pour le type demandé en paramètre
 * @export
 * @param {NumbroFormatType} type
 * @returns {NumbroFormatModel}
 */
export function NumbroFormat(type: NumbroFormatType): NumbroFormatModel {
  switch (type) {
    case 'number':
      return { thousandSeparated: true, mantissa: 2, trimMantissa: true };
    case 'numberRounded':
      return { thousandSeparated: true, mantissa: 0 };
    case 'numberAverage':
      return { thousandSeparated: true, mantissa: 2, average: true, trimMantissa: true };
    case 'percentageRounded':
      return { mantissa: 0, output: 'percent' };
    case 'percentage':
      return { mantissa: 2, output: 'percent', trimMantissa: true };
    case 'accounting':
      return { thousandSeparated: true, mantissa: 2, negative: 'parenthesis' }; //attention doit être utilisé avec formatCurrency()
    case 'accountingAverage':
      return { thousandSeparated: true, mantissa: 2, average: true, negative: 'parenthesis' }; //attention doit être utilisé avec formatCurrency()
    case 'ordinal':
      return { output: 'ordinal' };
    case 'personalized':
      return {};
    default:
      return undefined;
  }
}

export function NumbroForceAverageOptions(): string[] {
  return ['inactive', 'automatic'].concat(NumbroForceAverageTypes() as string[]);
}

export function NumbroForceAverageTypes(): NumbroForceAverageType[] {
  return ['thousand', 'million', 'billion', 'trillion'];
}

export function NumbroNegativeTypes(): NumbroNegativeType[] {
  return ['sign', 'parenthesis'];
}

export function NumbroOutputTypes(): NumbroOutputType[] {
  return ['number', 'percent', 'currency', 'ordinal'];
}

export function NumbroCurrencyPositions(): NumbroCurrencyPosition[] {
  return ['prefix', 'infix', 'postfix'];
}

export function NumbroFormatDefault(): NumbroFormatModel {
  return {
    thousandSeparated: true,
    mantissa: 0,
    forceSign: false,
    trimMantissa: false,
    negative: 'sign',
    average: false,
    spaceSeparated: false,
    output: 'number',
    prefix: '',
    postfix: '',
    currencyPosition: 'postfix',
    currencySymbol: ''
  }
}

export function NumbroFormatTypes():  NumbroFormatType[] {
  return [
  'personalized',
  'number',
  'numberRounded',
  'numberAverage',
  'percentage',
  'percentageRounded',
  'accounting',
  'accountingAverage', 
  'ordinal'];
}