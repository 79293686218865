<app-browser-frame #frameModal [isCreate]="false" (sendSearch)="receiveSearch($event)" [color]="color" [icon]="icon" [label]="label">
  
  <div app-browser-frame-content-options>

  </div>

  <app-empty app-browser-frame-content-list [actionText]="'IMPORT'" [actionIcon]="'fa-file-import'" [type]="'dates'" (sendAction)="receiveImport()" *ngIf="dates != undefined && dates.length == 0"></app-empty>
  <app-loading app-browser-frame-content-list *ngIf="dates == undefined"></app-loading>
  
  <div app-browser-frame-content-list *ngFor="let date of dates | searchLabels:searchLabel:language ; trackBy: trackByUid">
    <app-item-strip
    [labels]="date.labels"
    [tags]="date.tags"
    [type]="'dates'"
    [uid]="date.uid"
    [icon]="icon"
    [color]="color"
    [isModified]="date.modifications$.value.isModified"
    (sendEdit)="receiveEdit($event)"
    ></app-item-strip>
  </div>
</app-browser-frame>

<app-date-editor #editor [current]="current"></app-date-editor>