/**
 * @module Dashboard
 */

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UID } from '@models/uid.model';
import { WidgetService } from '@services/widget/widget.service';
import { Subscription } from 'rxjs';
import { WidgetClass } from '@class/widget.class';
import { AuthService } from '@services/auth/auth.service';
import { LanguageType } from '@models/language.model';
import { WidgetLayersClass } from '@class/widgetLayers.class';
import { ThemeService } from '@services/theme/theme.service';
import { ThemeOptionsClass } from '@class/themeOptions.class';

@Component({
  selector: 'app-dashboard-layout-summary-line',
  templateUrl: './dashboard-layout-summary-line.component.html',
  styleUrls: ['./dashboard-layout-summary-line.component.css']
})
export class DashboardLayoutSummaryLineComponent implements OnInit {

  @Input() index: number;
  @Input() uidWidget: UID;
  @Output() sendCurrent: EventEmitter<number>;

  widgetLayers: WidgetLayersClass;
  widget$sub: Subscription;
  layers$sub: Subscription;
  theme$sub: Subscription;
  language: LanguageType;
  language$sub: Subscription;
  widgetNotFound: boolean;

  constructor(
    private $widgets: WidgetService,
    private $themes: ThemeService,
    private $auth: AuthService
  ) {

    this.language$sub = this.$auth.language$.subscribe({
      next: (language: LanguageType) => {
        this.language = language;
      }
    });
    
    this.sendCurrent = new EventEmitter<number>();
  }

  ngOnInit() {
    this.setWidgetLayers();
  }

  ngOnDestroy() {
    if(this.widget$sub) this.widget$sub.unsubscribe();
    if(this.theme$sub) this.theme$sub.unsubscribe();
    if(this.layers$sub) this.layers$sub.unsubscribe();
  }

  setWidgetLayers() {
    if(this.widget$sub) this.widget$sub.unsubscribe();
    this.widget$sub = this.$widgets.getPartial$(this.uidWidget).subscribe(
      (widget: WidgetClass) => {
        if(widget !== undefined) {
          this.widgetNotFound = false;
          if(this.theme$sub) this.theme$sub.unsubscribe();
          this.theme$sub = this.$themes.getOptions$(widget.uidTheme).subscribe(
            (theme: ThemeOptionsClass) => {
              if(this.layers$sub) this.layers$sub.unsubscribe();
              this.layers$sub = this.$widgets.getLayers$(widget, theme).subscribe(
                (widgetLayers: WidgetLayersClass) => {
                  this.widgetLayers = widgetLayers;
                }
              )
            }
          )
        }
        else {
          this.widgetNotFound = true;
        }
      }
    );
  }

  setCurrent() {
    this.sendCurrent.emit(this.index);
  }

}
