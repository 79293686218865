/**
 * @module Shared
 */

import { Injectable } from '@angular/core';
import { ToastService, IndividualConfig } from 'ng-uikit-pro-standard';
import { TranslateService } from '@ngx-translate/core';
import { MessageModel, MessageType } from '@models/message.model';
import { TranslationInModel } from '@models/translation.model';
import { Observable, of } from 'rxjs';
import { take, timeout, catchError, tap } from 'rxjs/operators';
import { DeepMerge } from '@functions/copy.functions';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {

  /**
   * @description Creates an instance of MessagesService.
   * @param {ToastService} $toast
   * @param {TranslateService} $translate
   * @memberof MessagesService
   */
  constructor(
    private $toast: ToastService,
    private $translate: TranslateService
  ) {}

  /**
   * @description Renvoie la couleur à utiliser en fonction du type du message
   * @private
   * @param {MessageType} type
   * @returns {string}
   * @memberof MessagesService
   */
  private typeColor(type: MessageType): string {
    switch(type) {
      case 'error': return 'danger-color';
      case 'info': return 'info-color';
      case 'success': return 'success-color';
      case 'warning': return 'warning-color';
    }
  }

  /**
   * @description Affiche une popup avec un bouton d'action (les couleurs et l'icone sont fonction du type de message) et renvoi une promesse du click sur l'action ou se ferme passé 20 secondes
   * @param {MessageModel} message
   * @param {MessageType} type
   * @param {string} buttonLabel
   * @returns {Promise<boolean>}
   * @memberof MessagesService
   */
  question(message: MessageModel, type: MessageType, buttonLabel: string): Promise<boolean> {
    const duration = 10000;
    const options = { 
      opacity: 1,
      closeButton: true,
      timeOut: duration,
      extendedTimeOut: duration / 2,
      progressBar: true,
      toastClass: 'elegant-color',
      actionButton: this.$translate.instant(buttonLabel), 
      actionButtonClass: `z-depth-0 mt-3 mb-0 btn-sm ${this.typeColor(type)}`
    } as IndividualConfig;
    return new Promise<boolean>((resolve, reject) => { 
      this.toast(message, type, options).pipe(
        take(1),
        tap(() => resolve(true)),
        timeout(duration),
        catchError(() => of(resolve(false)))
      ).subscribe();
    });
  }

  /**
   * @description Affiche une popup de succès avec le message passé en paramètre
   * @param {MessageModel} message
   * @memberof MessagesService
   */
  success(message: MessageModel) {
    this.toast(message, 'success');
  }

  /**
   * @description Affiche une popup d'erreur avec le message passé en paramètre
   * @param {MessageModel} message
   * @memberof MessagesService
   */
  error(message: MessageModel) {
    this.toast(message, 'error');
  }

  /**
   * @description Affiche une popup d'alerte avec le message passé en paramètre
   * @param {MessageModel} message
   * @memberof MessagesService
   */
  warning(message: MessageModel) {
    this.toast(message, 'warning');
  }

  /**
   * @description Affiche une popup d'information avec le message passé en paramètre
   * @param {MessageModel} message
   * @memberof MessagesService
   */
  info(message: MessageModel) {
    this.toast(message, 'info');
  }

  /**
   * @description Collecte la traduction des éléments du message et affiche une popup dont le type (success | error | warning | info) est précisé en paramètre avec le message passé en paramètre
   * @private
   * @param {MessageModel} message
   * @param {MessageType} type
   * @param {IndividualConfig} [options={}]
   * @memberof MessagesService
   */
  private toast(message: MessageModel, type: MessageType, options: IndividualConfig = {}): Observable<void> {
    if(message.text !== undefined && message.text.plain !== undefined && message.title !== undefined && message.title.plain !== undefined) {
      return this.printToast(type, message.text.plain, message.title.plain, options);
    }
    else if(message.text !== undefined && message.text.plain !== undefined && message.title.code !== undefined) {
      const title = this.getTranslation(message.title as TranslationInModel);
      return this.printToast(type, message.text.plain, title, options);
    }
    else if(message.text !== undefined && message.text.code !== undefined && message.title !== undefined && message.title.plain !== undefined) {
      const text = this.getTranslation(message.text as TranslationInModel);
      return this.printToast(type, text, message.title.plain, options);
    }
    else if(message.title === undefined) {
      const text = this.getTranslation(message.text as TranslationInModel);
      return this.printToast(type, text, undefined, options);
    }
    else {
      const text = this.getTranslation(message.text as TranslationInModel);
      const title = this.getTranslation(message.title as TranslationInModel);
      return this.printToast(type, text, title, options);
    }
  }

  /**
   * @description Envoie la demande d'affichage de la popup au service Toast
   * @private
   * @param {MessageType} type
   * @param {string} text
   * @param {string} title
   * @param {IndividualConfig} options
   * @returns {Observable<void>}
   * @memberof MessagesService
   */
  private printToast(type: MessageType, text: string, title: string, options: IndividualConfig): Observable<void> {
    options = DeepMerge(options, { opacity: 1 });
    if(type === 'success') return this.$toast.success(text, title, options).onAction;
    else if(type === 'error') return this.$toast.error(text, title, options).onAction;
    else if(type === 'warning') return this.$toast.warning(text, title, options).onAction;
    else if(type === 'info') return this.$toast.info(text, title, options).onAction;
  }

  /**
   * @description Collecte la traduction des éléments passés en paramètre
   * @private
   * @param {TranslationInModel} translation
   * @returns {Promise<string>}
   * @memberof MessagesService
   */
  private getTranslation(translation: TranslationInModel): string {
    if(translation === undefined) return undefined;
    return this.$translate.instant(translation.code.toUpperCase(), translation.params);
  }

}
