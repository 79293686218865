<app-helper #helper [title]="'DATE_FORMATS'">

  <div app-helper-content>
    <table class="table table-striped table-bordered font-small blue-grey-text">
      <tbody>
        <tr>
          <th></th>
          <th>Token</th>
          <th>Output</th>
        </tr>
        <tr>
          <td><b>Month</b></td>
          <td>M</td>
          <td>1 2 ... 11 12</td>
        </tr>
        <tr>
          <td></td>
          <td>Mo</td>
          <td>1st 2nd ... 11th 12th</td>
        </tr>
        <tr>
          <td></td>
          <td>MM</td>
          <td>01 02 ... 11 12</td>
        </tr>
        <tr>
          <td></td>
          <td>MMM</td>
          <td>Jan Feb ... Nov Dec</td>
        </tr>
        <tr>
          <td></td>
          <td>MMMM</td>
          <td>January February ... November December</td>
        </tr>
        <tr>
          <td><b>Quarter</b></td>
          <td>Q</td>
          <td>1 2 3 4</td>
        </tr>
        <tr>
          <td></td>
          <td>Qo</td>
          <td>1st 2nd 3rd 4th</td>
        </tr>
        <tr>
          <td><b>Day of Month</b></td>
          <td>D</td>
          <td>1 2 ... 30 31</td>
        </tr>
        <tr>
          <td></td>
          <td>Do</td>
          <td>1st 2nd ... 30th 31st</td>
        </tr>
        <tr>
          <td></td>
          <td>DD</td>
          <td>01 02 ... 30 31</td>
        </tr>
        <tr>
          <td><b>Day of Year</b></td>
          <td>DDD</td>
          <td>1 2 ... 364 365</td>
        </tr>
        <tr>
          <td></td>
          <td>DDDo</td>
          <td>1st 2nd ... 364th 365th</td>
        </tr>
        <tr>
          <td></td>
          <td>DDDD</td>
          <td>001 002 ... 364 365</td>
        </tr>
        <tr>
          <td><b>Day of Week</b></td>
          <td>d</td>
          <td>0 1 ... 5 6</td>
        </tr>
        <tr>
          <td></td>
          <td>do</td>
          <td>0th 1st ... 5th 6th</td>
        </tr>
        <tr>
          <td></td>
          <td>dd</td>
          <td>Su Mo ... Fr Sa</td>
        </tr>
        <tr>
          <td></td>
          <td>ddd</td>
          <td>Sun Mon ... Fri Sat</td>
        </tr>
        <tr>
          <td></td>
          <td>dddd</td>
          <td>Sunday Monday ... Friday Saturday</td>
        </tr>
        <tr>
          <td><b>Day of Week (Locale)</b></td>
          <td>e</td>
          <td>0 1 ... 5 6</td>
        </tr>
        <tr>
          <td><b>Day of Week (ISO)</b></td>
          <td>E</td>
          <td>1 2 ... 6 7</td>
        </tr>
        <tr>
          <td><b>Week of Year</b></td>
          <td>w</td>
          <td>1 2 ... 52 53</td>
        </tr>
        <tr>
          <td></td>
          <td>wo</td>
          <td>1st 2nd ... 52nd 53rd</td>
        </tr>
        <tr>
          <td></td>
          <td>ww</td>
          <td>01 02 ... 52 53</td>
        </tr>
        <tr>
          <td><b>Week of Year (ISO)</b></td>
          <td>W</td>
          <td>1 2 ... 52 53</td>
        </tr>
        <tr>
          <td></td>
          <td>Wo</td>
          <td>1st 2nd ... 52nd 53rd</td>
        </tr>
        <tr>
          <td></td>
          <td>WW</td>
          <td>01 02 ... 52 53</td>
        </tr>
        <tr>
          <td><b>Year</b></td>
          <td>YY</td>
          <td>70 71 ... 29 30</td>
        </tr>
        <tr>
          <td></td>
          <td>YYYY</td>
          <td>1970 1971 ... 2029 2030</td>
        </tr>
          <tr>
          <td></td>
          <td>Y</td>
          <td>1970 1971 ... 9999 +10000 +10001
            <br>
            <b>Note:</b> This complies with the ISO 8601 standard for dates past the year 9999
          </td>
        </tr>
        <tr>
          <td><b>Week Year</b></td>
          <td>gg</td>
          <td>70 71 ... 29 30</td>
        </tr>
        <tr>
          <td></td>
          <td>gggg</td>
          <td>1970 1971 ... 2029 2030</td>
        </tr>
        <tr>
          <td><b>Week Year (ISO)</b></td>
          <td>GG</td>
          <td>70 71 ... 29 30</td>
        </tr>
        <tr>
          <td></td>
          <td>GGGG</td>
          <td>1970 1971 ... 2029 2030</td>
        </tr>
        <tr>
          <td><b>Month numeral, day of month, year</b></td>
          <td>L</td>
          <td>09/04/1986</td>
        </tr>
        <tr>
          <td></td>
          <td>l</td>
          <td>9/4/1986</td>
        </tr>
        <tr>
          <td><b>Month name, day of month, year</b></td>
          <td>LL</td>
          <td>September 4, 1986</td>
        </tr>
        <tr>
          <td></td>
          <td>ll</td>
          <td>Sep 4, 1986</td>
        </tr>
      </tbody>
    </table>
  </div>
  
</app-helper>