/**
 * @module Widget
 */

import { Component, Input } from '@angular/core';
import { WidgetLayersClass } from '@class/widgetLayers.class';
import { UID } from '@models/uid.model';
import { ItemColor } from '@functions/item.functions';
import { NumbroModel } from '@models/numbro.model';

@Component({
  selector: 'app-widget-layers-axes-numbro',
  templateUrl: './widget-layers-axes-numbro.component.html',
  styleUrls: ['./widget-layers-axes-numbro.component.css']
})
export class WidgetLayersAxesNumbroComponent {

  @Input() widgetLayers: WidgetLayersClass;
  @Input() current: UID;

  color = ItemColor('widgets');

  constructor() {}

  receiveNumbro(numbro: NumbroModel, axis: 'x' | 'y', index: number) {
    if(axis === 'x') {
      this.widgetLayers.layer(this.current).x0Numbro.change({ type: numbro.type, format: numbro.format });
      if(this.widgetLayers.uid.value === this.current) this.widgetLayers.duplicateLayersOptions({ x0Numbro: { type: numbro.type, format: numbro.format } }, this.current);
    }
    else if(axis === 'y' && index === 0) {
      this.widgetLayers.layer(this.current).y0Numbro.change({ type: numbro.type, format: numbro.format });
      if(this.widgetLayers.uid.value === this.current) this.widgetLayers.duplicateLayersOptions({ y0Numbro: { type: numbro.type, format: numbro.format } }, this.current);
    }
    else if(axis === 'y' && index === 1) {
      this.widgetLayers.layer(this.current).y1Numbro.change({ type: numbro.type, format: numbro.format });
      if(this.widgetLayers.uid.value === this.current) this.widgetLayers.duplicateLayersOptions({ y1Numbro: { type: numbro.type, format: numbro.format } }, this.current);
    }   
  }
}