/**
 * @module Theme
 */

import { Component, Input } from '@angular/core';
import { ItemColor } from '@functions/item.functions';
import { ThemeOptionsClass } from '@class/themeOptions.class';
import { ChartType } from '@models/chart.model';
import { ThemeOptionType } from '@models/themeStudio.model';

@Component({
  selector: 'app-theme-option-axes-title-font-color',
  templateUrl: './theme-option-axes-title-font-color.component.html',
  styleUrls: ['./theme-option-axes-title-font-color.component.css']
})
export class ThemeOptionAxesTitleFontColorComponent {

  @Input() themeOptions: ThemeOptionsClass;
  @Input() axesIndex: number;
  @Input() axesXY: 'x' | 'y';

  color = ItemColor('themes');
  optionType: ThemeOptionType = 'axes_title_fontColor';
  showDetails: boolean;

  constructor() {}

  receiveShowDetails(showDetails: boolean) {
    this.showDetails = showDetails;
  }

  receiveValue(value: string, type?: ChartType) {
    this.themeOptions.setAxes(this.optionType, value, this.axesXY, this.axesIndex, type ? [type] : undefined);
  }

}
