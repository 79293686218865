<app-theme-option [categories]="themeOptions.optionsConfiguration[optionType].categories" [label]="themeOptions.optionsConfiguration[optionType].label" [information]="(value | number:'1.0-0') + '%'">
  <div app-theme-option-content>
    <app-show-details-option [showDetails]="showDetails" (sendShowDetails)="receiveShowDetails($event)">
      <div app-show-details-option-content>
        <app-slider [value]="value" (sendValue)="receiveValue($event)" [color]="color"></app-slider>
      </div>
    </app-show-details-option>
  </div>
</app-theme-option>

<div *ngIf="showDetails">
  <app-theme-option [categories]="themeOptions.optionsConfiguration[optionType].categoriesAndLabel" [label]="type | chart" [dark]="4" *ngFor="let type of themeOptions.limitedTo(optionType)" [information]="(values[type] | number:'1.0-0') + '%'">
    <div app-theme-option-content>
      <app-slider [value]="values[type]" (sendValue)="receiveValue($event, type)" [color]="color"></app-slider>
    </div>
  </app-theme-option>
</div>